import { types } from 'mobx-state-tree';
import ConnectedApp from './ConnectedApp';

const ConnectedAppsSection = types
  .model('ConnectedAppsSection', {
    id: types.frozen(),
    title: types.maybeNull(types.string),
    apps: types.array(ConnectedApp),
  })
  .views(self => ({
    get appsList() {
      return self.apps.slice();
    },
    get sortKey() {
      // prioritize enabled and built-in apps, then alphabetically
      if (self.id === 'enabledApps') {
        return '0';
      }
      if (self.id === 'builtInApps') {
        return '1';
      }
      return '10' + self.title;
    },
  }));

export default ConnectedAppsSection;
