import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { View, SafeAreaView } from 'react-native';
import { sizes, colors } from '/common/config/styles';
import { BrandContext } from '/common/config/branding';
import { RoundButton } from '/common/components';
/**
 * The top line of the typical tracker cell. Typically shows the icon, name, and value
 * when it is not focused, then just the icon and name when focused.
 */
const WebLogEntryFooter = observer(
  ({
    tracker,
    onPressSave,
    onPressCancel,
    containerStyle,
    isEditing,
    canEdit,
    onPressEdit,
    onPressDelete,
  }) => {
    const branding = useContext(BrandContext);
    const highlightColor = tracker.palettesId
      ? branding.trackerColors[tracker.palettesId]
      : colors.darkText;

    const { t } = useTranslation();

    let buttons = null;

    if (isEditing) {
      buttons = (
        <>
          <RoundButton
            title={t('LOG:ENTRY:CANCEL')}
            theme="darkOnLight"
            buttonStyle={{
              flex: 1,
            }}
            color={highlightColor}
            onPress={onPressCancel}
          />
          <View style={{ width: 15 }} />
          <RoundButton
            title={t('GENERAL:SAVE')}
            theme="lightOnDark"
            buttonStyle={{
              flex: 1,
            }}
            color={highlightColor}
            onPress={onPressSave}
          />
        </>
      );
    } else if (canEdit) {
      buttons = (
        <>
          <RoundButton
            title={t('GENERAL:EDIT')}
            theme="darkOnLight"
            buttonStyle={{
              flex: 1,
            }}
            color={highlightColor}
            onPress={onPressEdit}
          />
          <View style={{ width: 15 }} />
          <RoundButton
            title={t('LOG:ENTRY:DELETE')}
            theme="lightOnDark"
            buttonStyle={{
              flex: 1,
            }}
            color={colors.error}
            onPress={onPressDelete}
            hideBorder
          />
        </>
      );
    }

    return (
      <SafeAreaView>
        <View
          style={[
            {
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              paddingHorizontal: sizes.large,
              paddingVertical: sizes.small,
              backgroundColor: colors.bg0,
            },
            containerStyle,
          ]}>
          {buttons}
        </View>
      </SafeAreaView>
    );
  }
);

WebLogEntryFooter.propTypes = {
  tracker: PropTypes.object.isRequired,
};

export default WebLogEntryFooter;
