import React from 'react';
import { View, Platform } from 'react-native';
import { WebView } from 'react-native-webview';
import { inject, observer } from 'mobx-react/native';
import { useMediaQueryInfo, useDeviceDimensions } from '/common/config/styles';
import { getUrlHandlerInfo } from '/common/lib/urls';

// weird vimeo stuff todo:
// android: clicking on vimeo logo goes to vimeo app, but then when you come back there's an extra toolbar that hides play button
// might need to add a buffer around webview to provide extra space

const getEmbedHtml = ({ url, provider, meta }) => {
  const handlerInfo = getUrlHandlerInfo({ url, provider, meta });
  const videoIFrame =
    handlerInfo.source === 'youtube'
      ? `<iframe
          class="iframe"
          allowfullscreen="1"
          frameborder="0" 
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          src="https://www.youtube.com/embed/${handlerInfo.videoId}?controls=1&showinfo=0&showsearch=0&modestbranding=1&rel=0&autoplay=1">
        </iframe>`
      : handlerInfo.embedHtml; // modified embed incorporates exact ID from server with modifications to fit screen
  // `<iframe title="vimeo-player" src="https://player.vimeo.com/video/${handlerInfo.videoId}?autoplay=1" width="100%" height="100%" frameborder="0" allowfullscreen></iframe>`;
  return `
<html>
  <head>
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <style type="text/css">
      body {
        margin: 0;
        background-color: transparent !important;
      }
      .video-container {
        ${Platform.OS === 'web' ? '' : 'position: relative;'}
        width: 100%;
        height: 100%;
      }
      .iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border: 0;
      }
    </style>
  </head>
  <body>
    <div class="video-container">
      ${videoIFrame}
    </div>
  </body>
</html>
`;
};

const VideoPlayer = inject('Linking')(
  observer(function({ url, provider, meta, leftButton, Linking }) {
    const { innerWindowWidth, windowHeight } = useMediaQueryInfo({ alwaysUseWindowWidth: true });
    const { statusBarHeight, statusBarAndNavbarHeight } = useDeviceDimensions();
    const videoWidth = innerWindowWidth;
    const handlerInfo = getUrlHandlerInfo({ url, provider, meta });
    return (
      <View
        style={[
          {
            flex: 1,
            backgroundColor: 'white',
            paddingTop:
              Platform.OS === 'ios'
                ? statusBarHeight
                : 10 /* something about Modal makes this weird on android */,
          },
        ]}>
        {leftButton}
        {Platform.OS !== 'web' && (
          <View
            style={{
              height: windowHeight - statusBarAndNavbarHeight,
              paddingTop: 40,
              paddingBottom: 40,
              width: videoWidth /* I don't know why this works, but it seems to center the video and not cut anything off */,
            }}>
            <WebView
              style={{
                backgroundColor: 'transparent',
              }}
              source={{
                html: getEmbedHtml({ url, provider, meta }),
                baseUrl: '' /* Galaxy S will not render page without this! */,
              }}
              originWhitelist={['*']}
              scrollEnabled={false}
              allowsFullscreenVideo
              startInLoadingState
              onNavigationStateChange={e => {
                if (e.url.includes('http')) {
                  // opens other youtube videos in separate window
                  // or vimeo app view
                  Linking.openURL(url);
                  // stops webview from going to different videos
                  //this.webview.stopLoading();
                }
              }}
              userAgent={
                handlerInfo.source === 'youtube'
                  ? /* helps with youtube formatting on android and autoplay on iOS, see: https://github.com/react-native-community/react-native-webview/issues/859 */ 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/77.0.3865.90 Safari/537.36'
                  : undefined
              }
              mediaPlaybackRequiresUserAction={false}
            />
          </View>
        )}
        {Platform.OS === 'web' && (
          <div dangerouslySetInnerHTML={{ __html: getEmbedHtml({ url, provider, meta }) }} />
        )}
      </View>
    );
  })
);

export default VideoPlayer;
