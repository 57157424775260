import * as Linking from 'expo-linking';
import { Platform, Alert } from 'react-native';
import * as ExpoImagePicker from 'expo-image-picker';
import * as ImageManipulator from 'expo-image-manipulator';

const permissionDeniedTitle = "Can't access camera/ photo roll";
const permissionDeniedMessage =
  'The permissions for this app to access the camera and photo roll have been previously denied. If you would like to upload a photo, please go to Settings and enable the permissions.';

const permissionDeniedAlertDefaults = {
  camera: {
    title: permissionDeniedTitle,
    message: permissionDeniedMessage,
  },
  photoRoll: {
    title: permissionDeniedTitle,
    message: permissionDeniedMessage,
  },
  dismissButtonText: 'Cancel',
  openSettingsButtonText: 'Settings',
};

const showPermissionDeniedAlert = (options, type) => {
  return new Promise(resolve => {
    Alert.alert(
      options[type].title,
      options[type].message,
      [
        {
          text: options.openSettingsButtonText,
          style: 'default',
          onPress: () => {
            Linking.openURL('app-settings:');
            resolve();
          },
        },
        {
          text: options.dismissButtonText,
          onPress: () => {
            resolve();
          },
          style: 'cancel',
        },
      ],
      { cancelable: true }
    );
  });
};

export default class ImagePicker {
  /**
   * Resize image into standard 640*640 (or less) that is ready for Nudge API's
   */
  static async resizeImage(pickedImage) {
    const operations = [];

    let resizeObj = {};

    // bug open for this: https://github.com/expo/expo/issues/8416
    if (
      Platform.OS === 'android' &&
      // this showed up again in Expo 36?
      pickedImage.exif &&
      pickedImage.exif.Orientation > 1 &&
      (pickedImage.exif.Orientation === 6 /* portrait up */ ||
        pickedImage.exif.Orientation === 8) /* portrait reversed */ &&
      pickedImage.width > pickedImage.height
    ) {
      // portrait seems to reverse height and width just for the ImageManipulator, only on portrait
      resizeObj = { height: 640 };
    } else {
      // add resize op as last op
      if (pickedImage.height > pickedImage.width) {
        resizeObj = { height: 640 };
      } else {
        resizeObj = { width: 640 };
      }
    }
    operations.push({ resize: resizeObj });
    let manipResult = await ImageManipulator.manipulateAsync(pickedImage.uri, operations, {
      format: 'jpeg',
      base64: false,
    });

    // see https://github.com/expo/expo/issues/9984
    // this only works because we always resize picked images
    if (Platform.OS === 'web') {
      const res = await fetch(manipResult.uri);
      const blob = await res.blob();
      return blob;
    }

    return {
      uri: manipResult.uri,
      name: 'photo.jpg',
      type: 'image/jpeg',
      width: manipResult.width,
      height: manipResult.height,
    };
  }

  /**
   * Pick Image from library. Automatically ask for permissions if needed.
   * if options.resizeImage is true, automatically resizes to Nudge API dimensions.
   *
   * If the user has never been asked for permission
   * their status should be undetermined return early and don't ask again
   */
  static async pickImageFromLibrary(options) {
    const hasAskedBefore = await ExpoImagePicker.getMediaLibraryPermissionsAsync();
    const { status } = await ExpoImagePicker.requestMediaLibraryPermissionsAsync();

    if (hasAskedBefore.status === 'undetermined' && status !== 'granted') {
      return Promise.resolve({ isCancelled: true });
    }
    if (status === 'granted') {
      const result = await ExpoImagePicker.launchImageLibraryAsync({
        mediaTypes: ExpoImagePicker.MediaTypeOptions.Images,
        exif: true,
      });
      if (result.canceled) {
        return Promise.resolve({ isCancelled: true });
      }
      if (options && options.resizeImage) {
        return await ImagePicker.resizeImage(result);
      }
      return result;
    } else {
      await showPermissionDeniedAlert(
        options && options.permissionsDeniedAlertOptions
          ? options.permissionsDeniedAlertOptions
          : permissionDeniedAlertDefaults,
        'photoRoll'
      );
      return Promise.resolve({ isCancelled: true });
    }
  }

  /**
   * Pick Image from camera. Automatically ask for permissions if needed.
   * if options.resizeImage is true, automatically resizes to Nudge API dimensions.
   *
   * If the user has never been asked for permission
   * their status should be undetermined return early and don't ask again
   */
  static async pickImageFromCamera(options) {
    const hasAskedBefore = await ExpoImagePicker.getCameraPermissionsAsync();
    let permissionsResult = await ExpoImagePicker.requestCameraPermissionsAsync();

    if (hasAskedBefore.status === 'undetermined' && permissionsResult.status !== 'granted') {
      return Promise.resolve({ isCancelled: true });
    }
    if (permissionsResult.status === 'granted') {
      permissionsResult = await ExpoImagePicker.requestMediaLibraryPermissionsAsync();
      if (permissionsResult.status === 'granted') {
        const result = await ExpoImagePicker.launchCameraAsync({ exif: true });
        console.log('camera!');
        console.log(result);
        if (result.canceled) {
          return Promise.resolve({ isCancelled: true });
        }
        if (options && options.resizeImage) {
          return await ImagePicker.resizeImage(result);
        }
        return result;
      } else {
        await showPermissionDeniedAlert(
          options && options.permissionsDeniedAlertOptions
            ? options.permissionsDeniedAlertOptions
            : permissionDeniedAlertDefaults,
          'photoRoll'
        );
        return Promise.resolve({ isCancelled: true });
      }
    } else {
      await showPermissionDeniedAlert(
        options && options.permissionsDeniedAlertOptions
          ? options.permissionsDeniedAlertOptions
          : permissionDeniedAlertDefaults,
        'camera'
      );
      return Promise.resolve({ isCancelled: true });
    }
  }
}
