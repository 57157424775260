import Clipboard from '/common/lib/clipboard/';

class ModerationWorkflow {
  // dependencies
  _t;
  _showActionSheetWithOptions;
  _Alert;
  _Platform;
  _Keyboard;
  _onFilterTopic;

  constructor({
    t,
    showActionSheetWithOptions,
    alert,
    Platform,
    Keyboard,
    onFilterTopic = () => {},
  }) {
    this._t = t;
    this._showActionSheetWithOptions = showActionSheetWithOptions;
    this._alert = alert;
    this._Platform = Platform;
    this._Keyboard = Keyboard;
    this._onFilterTopic = onFilterTopic;
  }

  _showHideAllMessagesConfirmation = ({ message, parentComment, socialFeed }) => {
    const t = this._t;
    const Keyboard = this._Keyboard;
    const Platform = this._Platform;
    const alert = this._alert;
    const showActionSheetWithOptions = this._showActionSheetWithOptions;

    const options = [t('COMMUNITY:MODERATION_OPTIONS:HIDE_ALL_MESSAGES'), t('GENERAL:CANCEL')];
    const destructiveButtonIndex = 0;
    const cancelButtonIndex = options.length - 1;
    if (Platform.OS === 'android') {
      Keyboard.dismiss();
    }
    showActionSheetWithOptions(
      {
        title: t('COMMUNITY:MODERATION_OPTIONS:HIDE_ALL_MESSAGES_FROM_USER_TITLE', {
          username: message.author.firstName,
        }),
        message: t('COMMUNITY:MODERATION_OPTIONS:HIDE_ALL_MESSAGES_FROM_USER_MESSAGE'),
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
        showSeparators: false,
      },
      async buttonIndex => {
        if (buttonIndex === 0) {
          await socialFeed.filterPostsByUser({ userId: message.userId });
          if (parentComment && parentComment.wasFiltered) {
            this._onFilterTopic();
          }
          alert(
            t('COMMUNITY:MODERATION_OPTIONS:HIDE_ALL_MESSAGES_FROM_USER_CONFIRMATION_TITLE'),
            t('COMMUNITY:MODERATION_OPTIONS:HIDE_ALL_MESSAGES_FROM_USER_CONFIRMATION_MESSAGE', {
              username: message.author.firstName,
            })
          );
        }
      }
    );
  };

  _showReportAbuseOptions = ({ message, parentComment, socialFeed }) => {
    const t = this._t;
    const Keyboard = this._Keyboard;
    const Platform = this._Platform;
    const showActionSheetWithOptions = this._showActionSheetWithOptions;

    const options = [
      t('COMMUNITY:MODERATION_OPTIONS:ITS_SPAM'),
      t('COMMUNITY:MODERATION_OPTIONS:ITS_OFFENSIVE'),
      t('COMMUNITY:MODERATION_OPTIONS:OTHER_ABUSE_OPTION'),
      t('GENERAL:CANCEL'),
    ];
    const destructiveButtonIndex = 0;
    const cancelButtonIndex = options.length - 1;
    if (Platform.OS === 'android') {
      Keyboard.dismiss();
    }
    showActionSheetWithOptions(
      {
        title: t('COMMUNITY:MODERATION_OPTIONS:WHY_WAS_THIS_ABUSIVE'),
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
        showSeparators: false,
      },
      buttonIndex => {
        let reason;
        if (buttonIndex === 3) {
          return;
        }

        if (buttonIndex === 0) {
          reason = t('COMMUNITY:MODERATION_OPTIONS:ITS_SPAM');
        }
        if (buttonIndex === 1) {
          reason = t('COMMUNITY:MODERATION_OPTIONS:ITS_OFFENSIVE');
        }
        if (buttonIndex === 2) {
          reason = t('COMMUNITY:MODERATION_OPTIONS:OTHER_ABUSE_OPTION');
        }
        socialFeed.reportAbuse({ messageId: message.id, reason });

        setTimeout(() => {
          this._showHideAllMessagesConfirmation({ message, parentComment, socialFeed });
        }, 500);
      }
    );
  };

  startModerationWorkflow = ({
    message /* this is originatingMessage (MST model), not the generic chat message, not sure why I did this, sorry! */,
    parentComment,
    socialFeed,
  }) => {
    const t = this._t;
    const Keyboard = this._Keyboard;
    const Platform = this._Platform;
    const onFilterTopic = this._onFilterTopic;
    const showActionSheetWithOptions = this._showActionSheetWithOptions;

    let options = [
      t('COMMUNITY:MODERATION_OPTIONS:HIDE_THIS_MESSAGE'),
      t('COMMUNITY:MODERATION_OPTIONS:HIDE_ALL_MESSAGES_FROM_USER'),
      t('COMMUNITY:MODERATION_OPTIONS:REPORT_ABUSE'),
    ];

    let optionFns = [
      async () => {
        await socialFeed.filterComment({ messageId: message.id });
      },
      () => {
        setTimeout(() => {
          this._showHideAllMessagesConfirmation({ message, parentComment, socialFeed });
        }, 500);
      },
      () => {
        setTimeout(() => {
          this._showReportAbuseOptions({ message, parentComment, socialFeed });
        }, 500);
      },
    ];

    // not really a moderation thing, but we have one menu workflow for all of this, so it goes here
    if (message.comment && message.comment !== '') {
      options.push(t('MESSAGING:BUTTONS:COPY_TEXT'));
      optionFns.push(() => {
        Clipboard.setString(message.comment);
      });
    }

    options.push(t('GENERAL:CANCEL'));
    optionFns.push(() => {});

    const destructiveButtonIndex = 1;
    const cancelButtonIndex = options.length - 1;
    if (Platform.OS === 'android') {
      Keyboard.dismiss();
    }
    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
        showSeparators: false,
      },
      async buttonIndex => {
        await optionFns[buttonIndex]();
        if (parentComment && parentComment.wasFiltered) {
          onFilterTopic();
        }
      }
    );
  };
}

export default ModerationWorkflow;
