import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import * as WebBrowser from 'expo-web-browser';
import { EnterNameEmailAndPassword } from '../../components/sign-up';
import { SimpleNavWrapper, HeaderIconButton } from '../../components/navigation';
import { BackIcon } from '../../config/common-icons';
import { LoadingShadeWrapper } from '../../components/common';
import { BrandContext } from '../../config/branding';

import { containerStyles } from '../../config/common-styles';
import { withKeyboardAvoidance, ScreenError } from '../common';

@inject('rootStore')
@observer
class EnterNameEmailAndPasswordScreen extends Component {
  static contextType = BrandContext;

  state = {
    email: '',
    password: '',
    firstName: '',
    lastName: '',
  };

  _onPressTermsOfService = () => {
    const branding = this.context;

    if (branding.links.tos) {
      WebBrowser.openBrowserAsync(`${branding.links.tos}`);
    } else {
      WebBrowser.openBrowserAsync('https://nudgecoach.com/tos');
    }
  };

  _onPressBack = () => {
    const { navigation } = this.props;
    navigation.goBack();
  };

  _onGoToNextStep = async () => {
    const { navigation, route, rootStore } = this.props;
    const { firstName, lastName, email, password } = this.state;
    await rootStore.registerAccount({
      firstName,
      lastName,
      email,
      password,
      inviteId: route.params ? route.params.inviteId : null,
      clientUserId: route.params ? route.params.clientUserId : null,
    });
    // no longer need to navigate, since protected route will change
    /*if (rootStore.signupActionState.isDone) {
      navigation.navigate();
    }*/
  };

  render() {
    const { rootStore, route } = this.props;
    const { email, password, firstName, lastName } = this.state;

    return (
      <SimpleNavWrapper
        leftButton={
          <HeaderIconButton
            cavyId="CreateAccountScreen.Back"
            icon={<BackIcon />}
            onPress={this._onPressBack}
          />
        }>
        <LoadingShadeWrapper
          coversOnboardingScreen
          isLoading={rootStore.signupActionState.isPending}
          style={containerStyles.fillAvailableSpace}>
          <ScreenError state={rootStore.signupActionState} ignoreFormErrors />
          <EnterNameEmailAndPassword
            onPressSubmit={this._onGoToNextStep}
            email={email}
            onChangeEmail={email => this.setState({ email })}
            password={password}
            onChangePassword={password => this.setState({ password })}
            firstName={firstName}
            lastName={lastName}
            onChangeFirstName={firstName => this.setState({ firstName })}
            onChangeLastName={lastName => this.setState({ lastName })}
            formErrors={rootStore.signupActionState.formErrors}
            onPressTermsOfService={this._onPressTermsOfService}
          />
        </LoadingShadeWrapper>
      </SimpleNavWrapper>
    );
  }
}

EnterNameEmailAndPasswordScreen.propTypes = {
  navigation: PropTypes.object,
  t: PropTypes.any.isRequired,
};

export default withTranslation()(
  withKeyboardAvoidance(EnterNameEmailAndPasswordScreen, { enabledForIos: false })
);
