import React, { useContext } from 'react';
import { View, Text, Platform } from 'react-native';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { RoundButton } from '../common';
import { sizes, textStyles, useMediaQueryInfo } from '/common/config/styles';
import SignUpField from './SignUpField';
import { KeyboardAwareScrollView } from '/common/components';
import { BrandContext } from '../../config/branding';

const PromptForFieldDuringSignup = observer(function({
  onPressContinue,
  onPressSkip,
  value,
  onChangeValue,
  prompt,
  textInputPlaceholder,
}) {
  const branding = useContext(BrandContext);
  const { t } = useTranslation();
  const { innerWindowWidth } = useMediaQueryInfo();

  return (
    <View style={{ flex: 1 }}>
      <KeyboardAwareScrollView
        scrollViewProps={{
          showsVerticalScrollIndicator: false,
          contentContainerStyle: {
            width: innerWindowWidth,
            alignSelf: 'center',
            flexGrow: 1,
          },
        }}
        textInputOffsetFromKeyboard={Platform.OS === 'android' ? 35 + 110 : 100}>
        <View
          style={{
            marginHorizontal: sizes.large,
            paddingTop: sizes.large * 2,
          }}>
          <Text style={[textStyles.centered, textStyles.bold.dark, { marginBottom: sizes.large }]}>
            {prompt}
          </Text>
          <SignUpField
            text={value}
            onChangeText={onChangeValue}
            placeholder={textInputPlaceholder}
            focus
            onSubmitEditing={onPressContinue}
          />
          {!value || value.trim() === '' ? null : (
            <RoundButton
              buttonStyle={{ marginTop: sizes.large, marginBottom: sizes.large }}
              theme="lightOnDark"
              isDisabled={!value || value.trim().length === 0}
              onPress={onPressContinue}
              title={t('SIGN_UP:BUTTONS:CONTINUE').toLowerCase()}
            />
          )}
          {!value || value.trim() === '' ? (
            <RoundButton
              buttonStyle={{ marginTop: sizes.large, marginBottom: sizes.large }}
              onPress={onPressSkip}
              title={
                branding.signUp.continueWithoutInviteIdPrompt ||
                t('SIGN_UP:BUTTONS:NO_CONTINUE_WITH_SIGN_UP')
              }
              hideBorder
            />
          ) : null}
        </View>
      </KeyboardAwareScrollView>
    </View>
  );
});

PromptForFieldDuringSignup.propTypes = {
  onPressContinue: PropTypes.func.isRequired,
  onPressSkip: PropTypes.func.isRequired,
  value: PropTypes.string,
  onChangeValue: PropTypes.func.isRequired,
  prompt: PropTypes.string,
  textInputPlaceholder: PropTypes.string,
};

PromptForFieldDuringSignup.defaultProps = {
  value: '',
  prompt: 'Enter information.',
  textInputPlaceholder: 'client information',
};

export default PromptForFieldDuringSignup;
