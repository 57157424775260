import { types, flow, getEnv } from 'mobx-state-tree';
import { DateTime } from 'luxon';
import { uniqBy, groupBy, keys, sortBy, take, filter } from 'lodash';
import { jsDateToUserTime, toDateTime } from 'nudge-client-common/lib/dates';
import {
  ReadOnlyLogStore,
  LoadingState,
  pageableStoreFactory,
  uiPageStoreFactory,
} from 'nudge-client-common/stores';
import TagPage from './TagPage';
import { validateLogFieldValue, hasOverlappingTimeIntervals } from './logValidationUtils';

const WriteableProgramStore = types
  .model('WriteableProgramStore', {
    saveState: types.optional(LoadingState, {}),
    updateOnDemandAppsState: types.optional(LoadingState, {}),
    loadStackHistoryState: types.optional(LoadingState, {}),
  })
  .views(self => ({
    // workaround for card paging issues! We check how many stacks there are before deciding if we can allow loading anymore
    // this is to prevent runaway queries
    get stackCount() {
      const eventsFiltered = uniqBy(
        self.programEventsSorted,
        p => /*p.share.cardId*/ p.clientFeedUniquenessKey
      );
      return eventsFiltered.length;
    },
  }))
  .views(self => ({
    // events grouped by date, with repeating events consolidated as stacks showing the most recent date
    // used by client
    get programFeedUniqueItemsGroupedByDate() {
      const eventsFiltered = uniqBy(
        self.mainFeedProgramEventsSorted,
        p => p.clientFeedUniquenessKey
      );
      const groupsByKey = groupBy(eventsFiltered, a => a.headerSortKey);
      const finalGroups = [];
      keys(groupsByKey).forEach(groupKey => {
        const groupItems = groupsByKey[groupKey];
        if (groupItems.length) {
          finalGroups.push({
            headerInfo: groupItems[0].headerInfo,
            items: sortBy(groupItems, g => g.itemSortKey).reverse(), // show latest shared card first
          });
        }
      });
      return finalGroups;
    },
    // used by card detail from feed
    focusableEventsForClientUniqueKey(uniqueKey) {
      return take(
        filter(self.programEventsSorted, e => e.clientFeedUniquenessKey === uniqueKey),
        28
      );
    },
    // used by card detail from tags
    focusableEventsForStackId(stackId) {
      return take(
        filter(self.programEventsSorted, e => e.programCard.stackId === stackId),
        28
      );
    },
  }))
  .actions(self => {
    // *** PRIVATE ***
    const updateLog = flow(function* updateLog(log) {
      try {
        self.saveState.setPending();
        yield getEnv(self).logRepository.updateLog({
          log,
          trackerId: log.trackerId,
        });
        log.clearDirtyBit();
        self.saveState.setDone();
      } catch (error) {
        self.saveState.setFailed(error);
      }
    });

    const addNewLog = flow(function* addNewLog(log) {
      try {
        self.saveState.setPending();
        const logWithId = yield getEnv(self).logRepository.addLog(log);
        log.updateServerId(logWithId.serverId);
        log.clearDirtyBit();
        self.saveState.setDone();
      } catch (error) {
        self.saveState.setFailed(error);
      }
    });

    const startNewLogFor = ({ trackerId, date }) => {
      // compute default time based on provided date and current time
      let givenDateTime = DateTime.fromSQL(date);
      const now = DateTime.local();
      // 2) add local time
      givenDateTime = givenDateTime.plus({
        hours: now.hour,
        minutes: now.minute,
        seconds: now.second,
      });
      const newLogTime = givenDateTime.toJSDate();

      const tracker = self.trackerFor(trackerId);

      const definition = tracker.clientMeta;

      const newLog = {
        source: 'nudge',
        userTime: jsDateToUserTime(newLogTime),
        activityId: definition.isActivityTypeRequired ? definition.defaultActivityType : null,
      };

      tracker.logs.push(newLog);

      return tracker.logs.find(l => l.id === newLog.id);
    };

    // *** PUBLIC ***

    /**
     * Mark a program event as read
     */
    const markProgramEventAsRead = flow(function* markProgramEventAsRead(programEvent) {
      programEvent.wasOpened = true;
      try {
        getEnv(self).logRepository.addNewCardReadStatus({
          stackId: programEvent.programCard.stackId,
          deliveredOn: programEvent.date,
        });
      } catch (error) {
        // we swallow this because we wouldn't want to fail loudly if we failed to mark as read
      }
    });

    /**
     * Update an existing log or add a new one
     * If logId is set, then will update
     */
    const saveLog = flow(function* saveLog({ trackerId, logId, date, logData, useMetricUnits }) {
      self.saveState.reset();
      const tracker = self.trackerFor(trackerId);

      // map to duration or hiDuration depending on tracker type
      let durationFieldName = tracker.clientMeta.primaryField;

      let startTime;

      // pre-process and validate
      const finalValues = [];
      const formErrors = [];
      for (let i = 0; i < tracker.clientMeta.entryFields.length; i++) {
        const field = tracker.clientMeta.entryFields[i];
        // calculate duration using startTime and endTime
        if (field.modelField === 'startTime') {
          startTime = logData[field.modelField];
        } else if (field.modelField === 'endTime') {
          const startDateTime = toDateTime(startTime);
          const endDateTime = toDateTime(logData[field.modelField]);
          // must do validation here since it incorporates both start and end time
          if (endDateTime <= startDateTime || endDateTime.diff(startDateTime).as('hours') > 24) {
            formErrors.push({ field: field.modelField, type: 'invalid' });
          } else if (
            hasOverlappingTimeIntervals({
              logs: tracker.logs.filter(l => l.isManualEntry), // TODO: filter by 48 hour window. Maybe...
              startDateTime,
              endDateTime,
              existingLogId: logId,
            })
          ) {
            formErrors.push({ field: field.modelField, type: 'overlapping' });
          } else {
            const durationInSeconds = endDateTime.diff(startDateTime).toMillis() / 1000;
            finalValues.push({ fieldName: durationFieldName, value: durationInSeconds });
            finalValues.push({ fieldName: 'jsUserTime', value: endDateTime.toJSDate() });
          }
        } else if (field.editor === 'divider') {
          // do nothing
        } else {
          const validationResult = validateLogFieldValue({
            value: logData[field.modelField],
            field,
            useMetricUnits,
          });
          if (validationResult.isValid) {
            finalValues.push({ fieldName: field.modelField, value: validationResult.value });
          } else {
            formErrors.push({ field: field.modelField, type: 'invalid' });
          }
        }
      }

      if (formErrors.length) {
        self.saveState.setFailed(new Error(), formErrors);
        return;
      }

      // add/ update model after validation is successful

      // start a new log if one doesn't already exist
      let logToAddOrUpdate;
      if (!logId) {
        startNewLogFor({
          trackerId,
          date,
        });
        logToAddOrUpdate = tracker.newLog;
      } else {
        logToAddOrUpdate = tracker.logFor(logId);
      }

      // cycle through each validated/ parsed value, set on model
      finalValues.forEach(finalValue => {
        if (finalValue.fieldName === 'jsUserTime') {
          logToAddOrUpdate.setUserTime(finalValue.value);
        } else {
          logToAddOrUpdate.setField({
            fieldName: finalValue.fieldName,
            value: finalValue.value,
          });
        }
      });
      logToAddOrUpdate.cleanStrings();

      // save to API
      if (logId) {
        yield updateLog(logToAddOrUpdate);
      } else {
        yield addNewLog(logToAddOrUpdate);
      }
    });

    const deleteLog = flow(function* deleteLog({ trackerId, logId }) {
      const tracker = self.trackerFor(trackerId);
      if (!tracker) {
        return;
      }
      const log = tracker.logFor(logId);
      if (!log) {
        return;
      }
      if (log.isNew) {
        const tracker = self.trackerFor(log.trackerId);
        tracker.removeLog(log);
        return;
      }
      try {
        self.saveState.setPending();
        yield getEnv(self).logRepository.deleteLog({
          logId: log.serverId,
          trackerId: log.trackerId,
        });
        const tracker = self.trackerFor(log.trackerId);
        tracker.removeLog(log);
        self.saveState.setDone();
      } catch (error) {
        self.saveState.setFailed(error);
      }
    });

    const updateOnDemandApps = flow(function* updateOnDemandApps(date) {
      self.updateOnDemandAppsState.setPending();
      try {
        yield getEnv(self).logRepository.requestUpdateOnDemandApps(date);
        self.updateOnDemandAppsState.setDone();
      } catch (error) {
        self.updateOnDemandAppsState.setFailed(error);
      }
    });

    /**
     * Load a card stack relative to the latest date it was delivered.
     * This is used when accessing a card from a tag, where the card may or may not be loaded with the main feed
     */
    const loadStackHistory = flow(function* loadStackHistory({ relativeToEvent }) {
      // NOTE: we used to abort this load if the event was from today, since presumably that would already be loaded with the feed.
      // THAT'S NOT ALWAYS TRUE. If you access the tag without the feed refreshing first, and a card was added to the tag, it will not
      // be there unless we reload!
      self.loadStackHistoryState.setPending();
      // load events for just the section neede to populate the card
      yield self.loadNewestProgramEvents({
        date: relativeToEvent.date,
        pageForwardMainFeed: false,
        automaticallyLoadNextRange: false,
      });
      self.loadStackHistoryState.copyLoadingStateFrom(self.loadProgramDataState);
    });

    return {
      deleteLog,
      saveLog,
      markProgramEventAsRead,
      updateOnDemandApps,
      loadStackHistory,
    };
  });

const ClientTag = types
  .model({
    name: types.string,
  })
  .views(self => ({
    get id() {
      return self.name;
    },
  }));

const TagsStore = pageableStoreFactory({
  friendlyObjectPluralName: 'Tags',
  friendlyObjectSingularName: 'Tag',
  ObjectClass: ClientTag,
  repositoryName: 'logRepository',
  repositoryFetchFunctionName: 'getTags',
  sortKeyGeneratorFunction: tag => tag.name,
  objectClassIdPropName: 'name',
  pagingMode: 'modern', // options v3, none
});

const TagsPageStore = uiPageStoreFactory({
  friendlyObjectPluralName: 'TagPages',
  friendlyObjectSingularName: 'TagPage',
  PageObjectClass: TagPage,
});

const LogStore = types
  .compose(ReadOnlyLogStore, WriteableProgramStore, TagsStore, TagsPageStore)
  .named('LogStore');

export default LogStore;
