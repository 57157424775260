import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { withScreenBehaviors } from '/common/navigation/';
import { ScreenFirstLoadWrapper } from '../../../common';
import { rootStore } from '../../../../stores/instances';
import SingleGroupView from './SingleGroupView';
import MultipleGroupsView from './MultipleGroupsView';
import NoGroupsView from './NoGroupsView';

@inject('rootStore')
@observer
class GroupsTab extends Component {
  _onPressRefresh = () => {
    this.props.rootStore.socialStore.loadClubs();
  };

  render() {
    const { rootStore, navigation, route } = this.props;
    const { socialStore } = rootStore;

    let innerView;

    if (socialStore.clubs.length === 1) {
      innerView = <SingleGroupView rootStore={rootStore} navigation={navigation} route={route} />;
    } else if (socialStore.clubs.length > 1) {
      innerView = (
        <MultipleGroupsView rootStore={rootStore} navigation={navigation} route={route} />
      );
    } else if (socialStore.clubs.length === 0 && socialStore.loadClubsState.isDone) {
      innerView = <NoGroupsView rootStore={rootStore} navigation={navigation} />;
    }

    return (
      <ScreenFirstLoadWrapper
        state={socialStore.loadClubsState}
        hasData={socialStore.clubs.length}
        onPressRefresh={this._onPressRefresh}>
        {innerView}
      </ScreenFirstLoadWrapper>
    );
  }
}

GroupsTab.propTypes = {
  rootStore: PropTypes.object,
};

export default withScreenBehaviors({
  onFocus: () => {
    rootStore.setActiveFrame('groups');
    rootStore.clearSocialUnreadCount();
    rootStore.socialStore.loadClubs({ loadOnlyClub: true });
  },
  onActive: () => {
    rootStore.clearSocialUnreadCount();
    rootStore.socialStore.loadClubs({ loadOnlyClub: true });
  },
})(GroupsTab);
