import React, { Component } from 'react';
import { TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react/native';
import { sizes } from '/common/config/styles';
import { BackIcon } from '/common/config/icons';
import VideoPlayer from './VideoPlayer';

@inject('Linking')
class VideoPlayerScreen extends Component {
  render() {
    const { navigation, route } = this.props;
    const { url, provider, meta } = route.params;
    return (
      <VideoPlayer
        url={url}
        provider={provider}
        meta={meta}
        leftButton={
          <TouchableOpacity
            onPress={() => navigation.goBack()}
            style={{ marginLeft: sizes.medium }}>
            <BackIcon />
          </TouchableOpacity>
        }
      />
    );
  }
}

VideoPlayerScreen.propTypes = {
  navigation: PropTypes.object,
};

export default VideoPlayerScreen;
