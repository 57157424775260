import { DateTime } from 'luxon';

/**
 * Takes an image URL and api instance and creates an image source that can be
 * used with RN Image components, including the URL and headers.
 * This way, all components accepting images can accept a source
 * and work with both secured, non-secured, and local images.
 * @param {*} url
 * @param {*} apiInstance
 */
const imageUrlToSource = (url, apiInstance) => {
  if (!url) {
    return null;
  }
  return {
    uri: url,
    headers: {
      'X-Requested-With': apiInstance.defaults.headers.common['X-Requested-With'],
      'X-API-TOKEN': apiInstance.defaults.headers.common['X-API-TOKEN'],
      'X-API-KEY': apiInstance.defaults.headers.common['X-API-KEY'],
    },
  };
};

/**
 * Takes an image (or attachment) DTO from the server and api instance and
 * creates an image source that can be
 * used with RN Image components, including the URL and headers.
 * This way, all components accepting images can accept a source
 * and work with both secured, non-secured, and local images.
 * @param {*} url
 * @param {*} apiInstance
 */
const imageDtoToSource = (dto, apiInstance) => {
  if (!dto) {
    return null;
  }
  return {
    url: dto.url,
    uri: dto.url,
    headers: {
      'X-Requested-With': apiInstance.defaults.headers.common['X-Requested-With'],
      'X-API-TOKEN': apiInstance.defaults.headers.common['X-API-TOKEN'],
      'X-API-KEY': apiInstance.defaults.headers.common['X-API-KEY'],
    },
    height: dto.height,
    width: dto.width,
    // helpful for PDF's and other attachments in the future
    id: dto.id,
    sourceFilename: dto.source_filename || dto.sourceFilename,
    mime: dto.mime || 'image/jpeg',
    // sometimes we use camelcaseKeys right now and sometimes we don't, so need to check for both
    sourceExtension: dto.source_extension
      ? dto.source_extension.toLowerCase()
      : dto.sourceExtension
      ? dto.sourceExtension.toLowerCase()
      : null,
  };
};

const dateTimeFormat = 'yyyy-MM-dd HH:mm:00';

/**
 * Convert a timezone-less time into JS Date (local time)
 */
const userTimeToJsDate = userTime => {
  return DateTime.fromSQL(userTime).toJSDate();
};

const jsDateToUserTime = jsDate => {
  return DateTime.fromJSDate(jsDate).toFormat(dateTimeFormat);
};

/**
 * V4 timestamps are SQL date times in America/New_York timezone
 */
const v4TimestampToJsDate = v4timestamp => {
  return DateTime.fromSQL(v4timestamp, { zone: 'America/New_York' }).toJSDate();
};

/**
 * v3 timestamp is in epoch seconds
 */
const v3TimestampToJsDate = v3timestamp => {
  return new Date(v3timestamp * 1000);
};

export {
  imageUrlToSource,
  imageDtoToSource,
  userTimeToJsDate,
  jsDateToUserTime,
  v4TimestampToJsDate,
  v3TimestampToJsDate,
};
