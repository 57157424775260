import * as SentryBase from 'sentry-expo';
import * as SentryReact from '@sentry/react';
import { Platform } from 'react-native';
import getVersion from '../getVersion';
import getAppId from '../getAppId';

const version = getVersion();

// normalize Sentry per new API
// https://docs.expo.io/guides/using-sentry/
const Sentry = Platform.OS === 'web' ? SentryBase.Browser : SentryBase.Native;

if (!__DEV__) {
  console.log('registered sentry');
  SentryBase.init({
    dsn: 'https://c9a5d35ab6c44445b8278d7b1cda1110@sentry.io/1257886',
    //enabled: true,
    enableInExpoDevelopment: false,
  });

  Sentry.setTag('bundleId', getAppId());
  Sentry.setTag('nativeBuildNumber', version.buildNumber);
  Sentry.setTag('jsBuildNumber', version.jsBuild);
}

const SentryErrorBoundary = SentryReact.ErrorBoundary;

export { Sentry, SentryErrorBoundary };
