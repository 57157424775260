import React, { Component } from 'react';
import { View, SafeAreaView, StatusBar, TouchableOpacity, Text, Dimensions } from 'react-native';
import PropTypes from 'prop-types';
import { colors, sizes } from '../config/styles';
import { BrandContext } from '../config/branding';
import { SecurableImage } from '../components';
import { CancelIcon, ConfirmIcon } from '../config/icons';

// *** ONLY USED WHEN CHOOSING A NEW PROFILE IMAGE ***
// could be replaced most definitely
// Also uses old image component that isn't compatible with session token handling for images (doesn't matter because it only shows local images)

// *** OLD CODE FROM MOBILE COMMON ***
// could be refactored

/**
 * Image with a fixed height or variable height and width varies based on screen width.
 * Should eventually support variable height as well.
 * Set the height prop to fix the height.
 * Handles rotation.
 * This seems to better with fullscreen images than FlexibleThumbnail, especially when it comes
 * to squaring the image and putting a circle around it for profile photo previews.
 *
 * @export
 * @class ResponsiveImage
 * @extends {Component}
 */
class ResponsiveImage extends Component {
  constructor() {
    super();
    const { width, height } = Dimensions.get('window');
    this.state = {
      screenWidth: width,
      screenHeight: height,
    };
  }

  componentDidMount() {
    this.dimensionsHandler = Dimensions.addEventListener('change', this.handleDimensionsChange);
  }

  componentWillUnmount() {
    this.dimensionsHandler.remove();
  }

  _dimensionsChangeHandler = () => {
    const { width, height } = Dimensions.get('window');
    this.setState({
      screenWidth: width,
      screenHeight: height,
    });
  };

  _renderRectangle = () => {
    const { screenWidth, screenHeight } = this.state;
    const { source, height, horizontalPadding, verticalPadding, style } = this.props;
    return (
      <SecurableImage
        source={source}
        resizeMode="contain"
        style={[
          {
            width: screenWidth - horizontalPadding,
            height: height || screenHeight - verticalPadding,
          },
          style,
        ]}
      />
    );
  };

  _renderCircle = () => {
    const { screenWidth, screenHeight } = this.state;
    const { source, height, horizontalPadding, verticalPadding, style } = this.props;

    // figure out smallest dimension and base diameter on that
    const finalWidth = screenWidth - horizontalPadding;
    const finalHeight = height || screenHeight - verticalPadding;

    let finalDiameter = finalWidth;

    if (finalWidth > finalHeight) {
      finalDiameter = finalHeight;
    }
    return (
      <SecurableImage
        style={[
          { width: finalDiameter, height: finalDiameter, borderRadius: finalDiameter / 2 },
          style,
        ]}
        source={source}
      />
    );
  };

  render() {
    const { containToCircle } = this.props;
    return containToCircle ? this._renderCircle() : this._renderRectangle();
  }
}

ResponsiveImage.propTypes = {
  source: PropTypes.any.isRequired,
  height: PropTypes.number,
  horizontalPadding: PropTypes.number,
  verticalPadding: PropTypes.number,
  style: PropTypes.any,
  containToCircle: PropTypes.bool,
};

ResponsiveImage.defaultProps = {
  height: null,
  horizontalPadding: 0,
  verticalPadding: 0,
  style: null,
  containToCircle: false,
};

const buttonBarStyle = {
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
  height: 44,
  paddingBottom: 15,
};

const imageStyle = {
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
};

/**
 * Component for a fullscreen image preview modal UI.
 * Each button can only be pressed once (to prevent duplicate actions without a clumsy
 * disabling prop).
 */
class ImagePreview extends Component {
  constructor() {
    super();
    this.state = {
      areButtonsDisabled: false,
    };
  }

  /* public */

  /**
   * Re-enable the buttons. Intended to be called as a reference function.
   * Shouldn't generally need this, as usually this component should be unmounted
   * after a button press (as part of a popped modal), but it's here if you need it.
   */
  reenableButtons = () => {
    this.setState({ areButtonsDisabled: false });
  };

  _onPressConfirm = () => {
    this.setState({ areButtonsDisabled: true });
    this.props.onPressConfirm();
  };

  _onPressCancel = () => {
    this.setState({ areButtonsDisabled: true });
    this.props.onPressCancel();
  };

  render() {
    const {
      CancelButtonComponent,
      ConfirmButtonComponent,
      source,
      style,
      containToCircle,
      horizontalPadding,
      verticalPadding,
    } = this.props;
    const { areButtonsDisabled } = this.state;
    return (
      <SafeAreaView style={[{ flex: 1, backgroundColor: 'black' }, style]}>
        <StatusBar hidden />
        <View style={imageStyle}>
          <ResponsiveImage
            containToCircle={containToCircle}
            source={source}
            horizontalPadding={horizontalPadding}
            verticalPadding={verticalPadding}
          />
        </View>
        <View style={buttonBarStyle}>
          <TouchableOpacity disabled={areButtonsDisabled} onPress={this._onPressCancel}>
            <CancelButtonComponent />
          </TouchableOpacity>
          <TouchableOpacity disabled={areButtonsDisabled} onPress={this._onPressConfirm}>
            <ConfirmButtonComponent />
          </TouchableOpacity>
        </View>
      </SafeAreaView>
    );
  }
}

ImagePreview.propTypes = {
  source: PropTypes.object.isRequired,
  onPressConfirm: PropTypes.func.isRequired,
  onPressCancel: PropTypes.func.isRequired,
  ConfirmButtonComponent: PropTypes.func,
  CancelButtonComponent: PropTypes.func,
  style: PropTypes.any,
  containToCircle: PropTypes.bool,
  horizontalPadding: PropTypes.number,
  verticalPadding: PropTypes.number,
};

ImagePreview.defaultProps = {
  containToCircle: false,
  horizontalPadding: 7,
  verticalPadding: 7,
  ConfirmButtonComponent: props => (
    <Text style={{ fontSize: 20, color: 'white' }} {...props}>
      Confirm
    </Text>
  ),
  CancelButtonComponent: props => (
    <Text style={{ fontSize: 20, color: 'white' }} {...props}>
      Cancel
    </Text>
  ),
  style: null,
};

// *** END OLD CODE FROM MOBILE COMMON ***

class PreviewImageModal extends Component {
  static contextType = BrandContext;

  render() {
    const { navigation, route } = this.props;
    const branding = this.context;

    const { source, onPressConfirm, containToCircle } = route.params;

    const cancelIconStyle = {
      color: colors.darkText,
      fontSize: 24,
    };

    const confirmIconStyle = {
      color: branding.highlightedColor,
      fontSize: 28,
    };
    return (
      <ImagePreview
        style={{ backgroundColor: colors.bg0 }}
        source={source}
        onPressCancel={() => {
          navigation.goBack();
        }}
        onPressConfirm={() => {
          navigation.goBack();
          onPressConfirm();
        }}
        containToCircle={containToCircle}
        CancelButtonComponent={() => <CancelIcon style={cancelIconStyle} />}
        ConfirmButtonComponent={() => <ConfirmIcon style={confirmIconStyle} />}
        horizontalPadding={sizes.small}
        verticalPadding={sizes.large * 3}
      />
    );
  }
}

PreviewImageModal.propTypes = {
  navigation: PropTypes.object,
};

export default PreviewImageModal;
