import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useIsFocused } from '@react-navigation/native';
import { connectAlertBar } from './AlertBarProvider';
import ScreenErrorBase from './ScreenErrorBase';
import ErrorAlertBar from './ErrorAlertBar';

/**
 * A component that can be added anywhere in the screen that will display an error when the
 * provided state's isFailed is true.
 * Note that this may not work if state is originally null. Still need to check into that.
 * This is because we start the listener on componentDidMount().
 */
function ScreenError(props) {
  const { t, networkErrorMessage, accessedRemovedErrorMessage, title } = props;
  const isFocused = useIsFocused();
  return (
    <ScreenErrorBase
      {...props}
      isFocused={isFocused}
      networkErrorMessage={networkErrorMessage || t('GENERAL:ERRORS:NETWORK_ERROR_MESSAGE')}
      accessedRemovedErrorMessage={
        accessedRemovedErrorMessage || t('GENERAL:ERRORS:ALERT_ACCESS_REMOVED_ERROR_MESSAGE')
      }
      serverUnavailableErrorMessage={t('GENERAL:ERRORS:SERVER_UNAVAILABLE_ERROR_MESSAGE')}
      unexpectedErrorMessage={t('GENERAL:ERRORS:ALERT_UNEXPECTED_ERROR_MESSAGE')}
      ErrorAlertBarComponent={ErrorAlertBar}
      title={title || t('GENERAL:ERRORS:ALERT_UNEXPECTED_ERROR_TITLE')}
    />
  );
}

ScreenError.propTypes = {
  state: PropTypes.shape({ isFailed: PropTypes.bool, error: PropTypes.any }),
  type: PropTypes.string, // alert, messageBar
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  networkErrorMessage: PropTypes.string,
  accessedRemovedErrorMessage: PropTypes.string,
  t: PropTypes.any.isRequired,
  throttleMs: PropTypes.number,
  ignoreNetworkErrors: PropTypes.bool,
};

ScreenError.defaultProps = {
  state: null,
  type: 'alert',
  title: 'Error',
  message: null,
  networkErrorMessage: null,
  accessedRemovedErrorMessage: null,
  throttleMs: 0,
  ignoreNetworkErrors: false,
};

// non-decorated version for testing
export { ScreenError };

export default withTranslation()(connectAlertBar(ScreenError));
