import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { ProgramCardDetail } from '/common/components/program';
import { useScreenBehaviors } from '/common/navigation';
import { NavbarlessScreen } from '/common/components/navigation';
import { useCardDetailBehaviors, resolveProgramEventFromRoute } from '../common';

/**
 * Version of the Card detail screen specifically for showing cards from the tag list.
 * Will show a timeline history regardless of whether the or not the card repeats
 */
const CardFromTagDetailScreen = inject(
  'rootStore',
  'Linking'
)(
  observer(({ rootStore, Linking, navigation, route }) => {
    const { logStore } = rootStore;

    const programEvent = resolveProgramEventFromRoute({ route, rootStore });

    const {
      onPressBack,
      onPressEvent,
      onPressLink,
      onPressViewTracker,
      onTrackOpenVideo,
      onPressJoinGroup,
      onPressPdf,
      onPressAddLog,
    } = useCardDetailBehaviors({
      rootStore,
      Linking,
      navigation,
      programEvent,
      cardOpenedSource: 'tags',
    });

    useScreenBehaviors({
      onDidMount: () => {
        const tagPage = logStore.getTagPage(route.params.tagId);
        logStore.loadStackHistory({
          relativeToEvent: tagPage.eventsForTagSorted.find(
            e => e.id === route.params.programEventId
          ),
        });
      },
    });

    return (
      <NavbarlessScreen>
        <ProgramCardDetail
          onPressBackOrConfirm={onPressBack}
          focusableEvents={
            programEvent
              ? logStore.focusableEventsForStackId(programEvent.programCard.stackId)
              : [] /* could be empty since program is still loading */
          }
          programEvent={programEvent}
          onPressLink={onPressLink}
          onTrackOpenVideo={onTrackOpenVideo}
          onPressJoinGroup={onPressJoinGroup}
          onPressPdf={onPressPdf}
          trackers={logStore.trackersSorted}
          onPressViewTracker={onPressViewTracker}
          onPressAddLog={onPressAddLog}
          loadState={logStore.loadStackHistoryState}
          onPressEvent={onPressEvent}
          showDateOnBackButton={false}
        />
      </NavbarlessScreen>
    );
  })
);

CardFromTagDetailScreen.propTypes = {
  rootStore: PropTypes.object,
  navigation: PropTypes.object,
};

export default CardFromTagDetailScreen;
