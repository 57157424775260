import { types, flow, getEnv, getParent } from 'mobx-state-tree';
import { DateTimeV3 } from '/common/stores/types';

const Author = types.model('Author', {
  id: types.number, // avoid the ID because there may be dupes across the comment feed
  firstName: types.string,
  lastName: types.string,
  photoUrl: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
});

const Post = types.model('CommentBase', {
  id: types.identifier, // usually server ID, but may be UUID for pending posts
  serverId: types.maybeNull(types.number),
  comment: types.maybeNull(types.string),
  attachment: types.frozen(), // TODO: make an official attachment model, make custom Image component that takes header via static func
  createdAt: DateTimeV3,
  wasFiltered: types.optional(types.boolean, false),
  isPending: types.optional(types.boolean, false),
  author: Author,
  authorType: types.enumeration(['user', 'coach', 'company']),
  redacted: types.optional(types.boolean, false),
});

const WithReplies = types
  .model('WithReplies', {
    replies: types.optional(types.array(Post), []),
  })
  .views(self => ({
    get repliesSorted() {
      return self.replies.slice(); // TODO: sort
    },
  }));

const ParentComment = types.compose(Post, WithReplies).named('ParentComment');

export default ParentComment;
