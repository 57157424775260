import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextInput, Text } from 'react-native';
import RowEditorControl from './RowEditorControl';

class RowTextInput extends Component {
  render() {
    const {
      label,
      onChangeText,
      value,
      containerStyle,
      labelStyle,
      textInputStyle,
      textInputProps,
      validationError,
      validationErrorTextStyle,
    } = this.props;

    const defaultTextInputStyle = {
      flex: 1,
    };

    return (
      <RowEditorControl
        containerStyle={[{ flex: 1 }, containerStyle]}
        labelStyle={labelStyle}
        label={label}
        editor={
          <TextInput
            style={[defaultTextInputStyle, textInputStyle]}
            value={value}
            onChangeText={onChangeText}
            underlineColorAndroid="transparent"
            {...textInputProps}
          />
        }
        validationError={
          validationError ? (
            <Text style={[{ color: 'red', textAlign: 'center' }, validationErrorTextStyle]}>
              {validationError}
            </Text>
          ) : null
        }
      />
    );
  }
}

RowTextInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChangeText: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  containerStyle: PropTypes.any,
  labelStyle: PropTypes.any,
  textInputStyle: PropTypes.any,
  textInputProps: PropTypes.object,
  validationError: PropTypes.string,
  validationErrorTextStyle: PropTypes.any,
};

RowTextInput.defaultProps = {
  containerStyle: null,
  labelStyle: null,
  textInputStyle: null,
  validationErrorTextStyle: null,
  textInputProps: null,
  validationError: null,
};

export default RowTextInput;
