import React, { Component } from 'react';
import { View, I18nManager, Platform } from 'react-native';
import { NativeModulesProxy } from 'expo-modules-core';
//import * as NavigationBar from 'expo-navigation-bar';
import AppLoading from 'expo-app-loading';
import { Asset } from 'expo-asset';
import { debounce } from 'lodash';
import Constants from 'expo-constants';
import * as Font from 'expo-font';
import { StatusBar } from 'expo-status-bar';
import * as Updates from 'expo-updates';
import { observer, Provider } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { getLocales } from 'expo-localization';
import { enableScreens } from 'react-native-screens';
import {
  rootStore,
  Linking,
  BadgeHandler,
  AuthWebBrowser,
  brandingStore,
  setNavigation,
  getNavigation,
} from './stores/instances';
import { SentryErrorBoundary } from './lib/sentry';
import { containerStyles } from './config/common-styles';
import { ActionSheetProvider } from '/common/navigation';
import { configureInterceptors } from './stores/api';
import i18n from './lib/i18n';
import Storyboard from './storyboard';
import { getAllFonts, getAllAssets } from '/common/assets/assets-directory';
import { navigationRoutes } from './config/constants';
import { BrandContext } from './config/branding';
import { delay } from '/common/lib';
import { ScreenFirstLoadWrapper } from './storyboard/common';
import { shouldDisableCardTransitionsDueToHardwareIssues } from '/common/config/feature-support';

if (
  Platform.OS === 'android' &&
  NativeModulesProxy.ExpoNavigationBar /* not available in early 4400 builds, remove once all are updated */
) {
  // removing for now because when dark mode is enabled and the 3 button toolbar is used,
  // you can't see the white buttons behind the white backdrop.
  // AND, if you set the button style to dark, it makes the status bar icons light
  // might be related to the API restrictions that interact with the status bar API
  // https://docs.expo.dev/versions/v44.0.0/sdk/navigation-bar/
  //NavigationBar.setBackgroundColorAsync(colors.bg0);
  //NavigationBar.setButtonStyleAsync('dark');
  // one option might be to set this in relation to dark mode (as not setting it looks pretty good in dark mode)
}

// going to try this to fix the dissappearing card content issue on some phones
if (!shouldDisableCardTransitionsDueToHardwareIssues()) {
  enableScreens();
}

console.log(Constants.deviceName);

//import { enableLogging } from 'mobx-logger';
//enableLogging({ reaction: true });

try {
  console.log('Disable RTL because weird stuff happens on Android.'); // https://github.com/facebook/react-native/issues/10204
  I18nManager.allowRTL(false);
  I18nManager.forceRTL(false);
} catch (e) {
  console.log('Error applying RTL disable:');
  console.log(e);
}

// may not be available while debugging bare
/*if (Constants.manifest) {
  Sentry.setRelease(Constants.manifest.revisionId);
}*/

//TODO: move this to after login
// Testing push notification code. Need to move this until after login
// Disabling push notification logic because it makes Expo freak
/**/
// enable firebase analytics
//firebase.analytics();
// need to look into this bug more before enabling additional analytics
// https://forums.expo.io/t/expo-firebase-auth-app-eject-and-sdk-32/18098/7
//firebase.analytics().setAnalyticsCollectionEnabled(true);

const rerouteToMainTabs = async () => {
  if (rootStore.isLoggedIn) {
    console.log('403/ 404 reroute');
    // set a delay to allow other error handling to take effect. This will clear out a bunch of data
    await delay(1000);
    const navigation = await getNavigation();
    // used to reroute to Coach/ Connect tab, but now that there's clients who don't have a coach tab...
    // this is safer
    navigation.navigate(navigationRoutes.stacks.main.settingsTab);
    //rootStore.reloadCriticalData(); // yikes! infinite loop!
  }
};

// failing to debounce this could result in multiple requests triggering multiple navigations and messing up nav!
const rerouteToMainTabs_debounced = debounce(rerouteToMainTabs, 1000);

const rerouteToLogin = async () => {
  await rootStore.logout();
  // no more navigation, logged out is a protected route
};

const rerouteToLogin_debounced = debounce(rerouteToLogin, 1000);

// Moved this away from stuff that's more likely to get rerendered to see if it mitigates this error
// https://sentry.io/organizations/nudge-pt/issues/2190768105/?project=1257886&query=is%3Aunresolved
// Still weird, still shouldn't be here at all.
const getBadgeNumber = () => rootStore.tabBadges.messages;

// Wrapping a stack with translation hoc asserts we get new render on language change
// the hoc is set to only trigger rerender on languageChanged
class I18nWrappedStoryboard extends Component {
  componentDidMount() {
    // TODO: move closer to stores
    configureInterceptors({
      on401: rerouteToLogin_debounced,
      onRecognized404: rerouteToMainTabs_debounced,
      on403: rerouteToMainTabs_debounced,
    });
  }

  render() {
    return (
      <View style={containerStyles.fillAvailableSpace}>
        <BadgeHandler getBadgeNumber={getBadgeNumber} />
        <ActionSheetProvider>
          <Storyboard
            forwardRef={navigatorRef => {
              // See https://reactnavigation.org/docs/en/4.x/navigating-without-navigation-prop.html
              this.navigatorRef = navigatorRef;
              setNavigation(this.navigatorRef);
            }}
            screenProps={{ t: i18n.getFixedT() }}
          />
        </ActionSheetProvider>
      </View>
    );
  }
}

const ReloadStoryboardOnLanguageChange = withTranslation('common', {
  bindI18n: 'languageChanged',
  bindStore: false,
})(I18nWrappedStoryboard);

@observer
class Index extends Component {
  constructor() {
    super();
    this.state = {
      assetsAreLoaded: false,
      error: null,
    };
  }
  componentDidMount() {
    this._componentDidMountAsync();
  }

  async _componentDidMountAsync() {
    await this._loadAssetsAsync();
    await brandingStore.loadBrand();
    if (brandingStore?.branding?.brandKey !== 'nudge') {
      // add language localization as a whitelabeled feature
      i18n.changeLanguage(getLocales()[0].languageCode);
    }
    // loadBrand sets the brand-specific API URL, so must be called first!
    await rootStore.init();
  }

  async _loadAssetsAsync() {
    try {
      await Font.loadAsync(getAllFonts());
      await Asset.loadAsync(getAllAssets());
      this.setState({ assetsAreLoaded: true });
    } catch (e) {
      // In this case, you might want to report the error to your error
      // reporting service, for example Sentry
      console.log(
        'There was an error caching assets (see: App.js), perhaps due to a ' +
          'network timeout, so we skipped caching. Reload the app to try again.'
      );
      console.log(e);
      this.setState({ assetsAreLoaded: true });
    }
  }

  render() {
    return (
      <SentryErrorBoundary
        fallback={() => (
          <ScreenFirstLoadWrapper
            state={{ isFailed: true }}
            onPressRefresh={Updates.reloadAsync /* use full reload to also apply updates */}
            errorTitle="Sorry, something happened!"
            errorMessage="If this happens again, tap the Help button in Settings to let us know."
          />
        )}>
        <View style={containerStyles.fillAvailableSpace}>
          <BrandContext.Provider value={brandingStore.branding}>
            {this.state.assetsAreLoaded && brandingStore.loadBrandState.isDone ? (
              <Provider rootStore={rootStore} AuthWebBrowser={AuthWebBrowser} Linking={Linking}>
                <View style={containerStyles.fillAvailableSpace}>
                  <StatusBar hidden={false} translucent style="dark" />
                  <ReloadStoryboardOnLanguageChange />
                </View>
              </Provider>
            ) : (
              <AppLoading />
            )}
          </BrandContext.Provider>
        </View>
      </SentryErrorBoundary>
    );
  }
}

export default Index;

/* <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
              <Text>{`Are assets loaded? ${this.state.assetsAreLoaded}\n Is brand loaded? ${brandingStore.loadBrandState.isDone}\n Brand error: ${brandingStore.loadBrandState.error}`}</Text>
            </View> */
