import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { PushNotificationInfo } from '../../components/sign-up';
import { SimpleNavWrapper, SimpleNavButton } from '../../components/navigation';
import { LoadingShadeWrapper } from '../../components/common';
import { ScreenError } from '../common';
import { navigationRoutes } from '../../config/constants';
import { containerStyles } from '../../config/common-styles';

@inject('rootStore')
@observer
class PushNotificationInfoScreen extends Component {
  _onPressNext = async () => {
    const { rootStore } = this.props;

    await rootStore.registerForPushNotifications();
    if (rootStore.onboardingActionState.isDone) {
      await rootStore.markOnboardingStepComplete(
        navigationRoutes.stacks.onboarding.pushNotifications
      );
    }
  };

  render() {
    const { rootStore, t } = this.props;

    return (
      <SimpleNavWrapper
        rightButton={
          <SimpleNavButton title={'continue'} onPress={this._onPressNext} isPrimaryAction />
        }>
        <LoadingShadeWrapper
          coversOnboardingScreen
          isLoading={rootStore.onboardingActionState.isPending}
          style={containerStyles.fillAvailableSpace}>
          <ScreenError
            state={rootStore.onboardingActionState}
            title={t('ONBOARDING:ERRORS:PUSH_NOTIFICATION_REGISTRATION')}
          />
          <PushNotificationInfo />
        </LoadingShadeWrapper>
      </SimpleNavWrapper>
    );
  }
}

PushNotificationInfoScreen.propTypes = {
  navigation: PropTypes.object,
  t: PropTypes.any.isRequired,
};

export default withTranslation()(PushNotificationInfoScreen);
