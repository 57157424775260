import React, { useRef, useEffect } from 'react';
import { Platform, View, Text, TextInput } from 'react-native';
import * as Localization from 'expo-localization';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { unitsForValueAndUom } from 'nudge-client-common/lib/strings';
import { AutoresizableTextInput } from '/common/components';
import FieldContainer from './FieldContainer';
import { fonts, sizes, colors, textStyles } from '/common/config/styles';
import shouldApplyOldAndroidTextInputHack from './shouldApplyOldAndroidTextInputHack';
import { predictiveTextCausesFreeze } from '/common/config/feature-support';

// we don't need the other textinput on web, and this helps the ref work on mobile without any extra work
const MyTextInput = Platform.OS === 'web' ? AutoresizableTextInput : TextInput;

const shouldUseDefaultKeyboardForNumbers =
  shouldApplyOldAndroidTextInputHack() && Localization.decimalSeparator === ',';

const numberEditorTextInputProps = {
  keyboardType: shouldUseDefaultKeyboardForNumbers ? 'default' : 'decimal-pad',
  autoCapitalize: shouldApplyOldAndroidTextInputHack() ? undefined : 'words', // not sure if this helps non-LG, Samsung
};

const stringEditorTextInputProps = {
  keyboardType: 'default',
};

const TextInputNew = observer(function({
  canEdit,
  value,
  uom,
  title,
  autoFocus,
  onChangeValue,
  multiline = false,
  placeholder,
  formError,
  validator,
  maxLength,
  decimals,
  zeroAllowed,
  editor,
  showUomInline,
  isRequired,
  tracker,
}) {
  const { t } = useTranslation();

  const textInputRef = useRef();

  useEffect(() => {
    // delaying focus a bit keeps the previous view from being
    if (Platform.OS !== 'ios' && autoFocus) {
      setTimeout(() => {
        if (textInputRef.current) {
          textInputRef.current.focus();
        }
      }, 300);
    }
  }, []);

  let newValue;

  if (value) {
    // make sure numbers are converted to strings
    newValue = value.toString();
  }

  let validationError;

  if (formError) {
    if (validator === 'string') {
      validationError = t('LOG:ENTRY_ERRORS:CANNOT_BE_BLANK');
    } else if (validator === 'percent') {
      validationError = t('LOG:ENTRY_ERRORS:MUST_BE_VALID_PERCENT');
    } else if (validator === 'minutes') {
      validationError = t('LOG:ENTRY_ERRORS:MUST_BE_VALID_MINUTES');
    } else {
      // number
      if (decimals === 0) {
        validationError = t('LOG:ENTRY_ERRORS.MUST_BE_INTEGER_GREATER_THAN_ZERO');
      } else {
        if (zeroAllowed) {
          validationError = t('LOG:ENTRY_ERRORS.MUST_BE_NUMBER_GREATER_THAN_OR_EQUAL_TO_ZERO');
        } else {
          validationError = t('LOG:ENTRY_ERRORS.MUST_BE_NUMBER_GREATER_THAN_ZERO');
        }
      }
    }
  }

  let displayUnits;
  if (showUomInline && uom) {
    const floatValue = parseFloat(value);
    displayUnits = unitsForValueAndUom(
      isNaN(floatValue) ? 2 /* assume plural for invalid value */ : floatValue,
      uom,
      true
    );
  }

  return (
    <FieldContainer
      title={title}
      isRequired={isRequired}
      validationError={validationError}
      canEdit={canEdit}>
      <View
        style={{
          flexGrow: 1,
          flexDirection: 'row',
          alignItems: 'center',
          borderWidth: isRequired && canEdit ? sizes.hairlineWidth : undefined,
          borderColor: formError ? colors.error : colors.lightText,
          borderRadius: sizes.borderRadius,
          backgroundColor: canEdit && isRequired ? undefined : colors.bg1,
        }}>
        <View style={{ flex: 1 }}>
          <MyTextInput
            ref={textInputRef}
            style={[
              {
                fontSize: fonts.sizes.standard,
                textAlign: 'left',
                padding: 10,
                paddingTop: 10, // not sure why but without paddingTop it doesn't register on multiline
                //lineHeight: fonts.sizes.standard * 1.5,
                width: '100%',
                minHeight: 40,
                color: canEdit ? colors.darkText : colors.lightText,
              },
              {
                minWidth: Platform.OS === 'android' ? 12 : undefined, // this needs to be big enough or else deleting text will unfocus the text input
              },
            ]}
            editable={canEdit}
            value={newValue}
            onChangeText={onChangeValue}
            autoFocus={Platform.OS === 'ios' && autoFocus}
            multiline={multiline}
            scrollEnabled={false}
            {...(editor === 'number' ? numberEditorTextInputProps : stringEditorTextInputProps)}
            returnKeyType={undefined}
            maxLength={
              editor === 'number'
                ? tracker?.clientMeta?.type?.includes('custom')
                  ? 13
                  : 8
                : maxLength
            }
            placeholder={isRequired ? placeholder : title}
            placeholderTextColor={isRequired ? colors.placeholderText : colors.lightText}
            autoCorrect={!predictiveTextCausesFreeze()}
          />
        </View>
        {displayUnits ? (
          <Text style={[{ paddingHorizontal: 10 }, textStyles.standard.light]}>
            {displayUnits} {!isRequired && newValue && `of ${title.toLowerCase()}`}
          </Text>
        ) : null}
      </View>
    </FieldContainer>
  );
});

TextInputNew.propTypes = {
  setField: PropTypes.func,
  title: PropTypes.string,
  log: PropTypes.object,
  rank: PropTypes.string,
  isEditable: PropTypes.bool,
  value: PropTypes.any,
  uom: PropTypes.string,
  fieldName: PropTypes.string,
};

export default TextInputNew;
