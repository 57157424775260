import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  validationErrorWrapper: {
    borderTopWidth: 1,
    paddingTop: 3,
    paddingBottom: 7,
  },
});

const ValidationError = ({ text, textStyle, containerStyle, errorColor }) => (
  <View style={[styles.validationErrorWrapper, { borderTopColor: errorColor }, containerStyle]}>
    <Text style={[{ color: errorColor }, textStyle]}>{text}</Text>
  </View>
);

ValidationError.propTypes = {
  text: PropTypes.string.isRequired,
  containerStyle: PropTypes.any,
  textStyle: PropTypes.any,
  errorColor: PropTypes.string,
};

ValidationError.defaultProps = {
  errorColor: '#FC423E',
  textStyle: null,
  containerStyle: null,
};

export default ValidationError;
