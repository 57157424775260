import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { BrandContext } from '../../../../config/branding';
import { ConnectTabIcon, CustomTabIcon } from '/common/config/icons';

@inject('rootStore')
@observer
class ConnectTabBadgeIcon extends Component {
  static contextType = BrandContext;

  render() {
    const { color, ...rest } = this.props;
    const branding = this.context;

    if (branding.tabs.connect.image) {
      return <CustomTabIcon source={branding.tabs.connect.image} tintColor={color} {...rest} />;
    } else {
      return <ConnectTabIcon tintColor={color} {...rest} />;
    }
  }
}

export default ConnectTabBadgeIcon;
