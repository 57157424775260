import * as Notifications from 'expo-notifications';
import idx from 'idx';
import { AppState, Platform } from 'react-native';
import { debounce } from 'lodash';

//Pusher.logToConsole = true;

// turn string ID's into ints for better comparisons downstream
const cleanUpId = (data, prop) => {
  if (data[prop] && !isNaN(data[prop])) {
    data[prop] = parseInt(data[prop], 10);
  }
};

export default class PushNotificationListener {
  // private vars
  _isListening = false;
  _appState = 'active'; // it doesn't get set to the initial state, only set on change

  // debug var
  lastSelectedNotificationPayload = {
    info: 'none selected yet',
  };

  lastReceivedInForegroundNotificationPayload = {
    info: 'none received yet',
  };

  // events to hook into

  // app not open, notification tapped in tray
  onNotificationSelected = () => {};

  // notification received while app is open
  onNotificationReceivedInForeground = () => {};

  _onNotificationReceived = notification => {
    console.log('received push in foreground');
    if (!this._isListening) {
      return;
    }

    const myData =
      idx(notification, _ => _.request.content.data) ||
      idx(notification, _ => _.request.trigger.payload); // iOS suddenly prefers the latter, nobody knows why

    this.lastReceivedInForegroundNotificationPayload = myData;

    if (!myData) {
      this.lastReceivedInForegroundNotificationPayload = {
        notes:
          'request.content.data is not found, showing raw payload as backup. Notifications actions probably are not working as a result.',
        notification,
      };
      return;
    }

    // we add body to the flattened notification data because this object is complex and we don't want to redo everything right now
    // this will let us use the message on foreground notifications
    const myContent = idx(notification, _ => _.request.content);
    myData.body = myContent.body;

    console.log(myData);

    // let's clean up stringified ID's
    cleanUpId(myData, 'coach_id');
    cleanUpId(myData, 'club_id');
    cleanUpId(myData, 'parent_id');
    cleanUpId(myData, 'author_id');
    cleanUpId(myData, 'card_id');

    // TODO make this one event
    if (this._appState === 'active') {
      this.onNotificationReceivedInForeground(myData);
    }
  };

  _onNotificationSelected = notification => {
    console.log('selected push from background');
    // data location
    // notification.request.content.data.body.coach_id
    if (!this._isListening) {
      return;
    }

    const myData =
      idx(notification, _ => _.notification.request.content.data) ||
      idx(notification, _ => _.notification.request.trigger.payload); // iOS suddenly prefers the latter, nobody knows why

    this.lastSelectedNotificationPayload = myData;

    if (!myData) {
      this.lastSelectedNotificationPayload = {
        notes:
          'notification.request.content.data is not found, showing raw payload as backup. Notifications actions probably are not working as a result.',
        notification,
      };
      return;
    }

    // we add body to the flattened notification data because this object is complex and we don't want to redo everything right now
    // this will let us use the message on foreground notifications
    const myContent = idx(notification, _ => _.notification.request.content);
    myData.body = myContent.body;

    console.log(myData);

    // let's clean up stringified ID's
    cleanUpId(myData, 'coach_id');
    cleanUpId(myData, 'club_id');
    cleanUpId(myData, 'parent_id');
    cleanUpId(myData, 'author_id');
    cleanUpId(myData, 'card_id');

    this.onNotificationSelected(myData);
  };

  _onNotificationReceived_debounced = debounce(this._onNotificationReceived, 1000);

  _onNotificationSelected_debounced = debounce(this._onNotificationSelected, 1000);

  constructor() {
    // you REALLY don't want this to initialize more than once
    Notifications.addNotificationReceivedListener(this._onNotificationReceived_debounced);
    Notifications.addNotificationResponseReceivedListener(this._onNotificationSelected_debounced);
    // TODO: update with new api https://docs.expo.io/versions/latest/sdk/notifications/#notification
    Notifications.addNotificationReceivedListener(() => {
      // separate listener so its not debounced, preventing Android notifications from being seen in the tray while app is open
      if (this._appState === 'active') {
        if (Platform.OS === 'android') {
          try {
            Notifications.dismissAllNotificationsAsync();
          } catch (error) {
            // https://sentry.io/organizations/nudge-pt/issues/2303912630/?project=1257886&query=is%3Aunresolved&statsPeriod=14d
            // avoid sentry error that is possibly just due to the notification no longer being there
          }
        }
      }
    });

    console.log('register notifications');

    this._appStateListenerDisposer = AppState.addEventListener('change', appState => {
      this._appState = appState;
    });
  }

  startListening() {
    this._isListening = true;
  }

  stopListening() {
    this._isListening = false;
  }

  /**
   * Allow reporting of notifications from props.exp in the root component because iOS at least doesn't
   * seem to report those through the listener
   *
   * @static
   * @memberof PushNotificationListener
   */
  static reportApplicationStartedBySelectingNotification() {
    // doesn't do anything in Expo 38+
  }
}
