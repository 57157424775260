import React, { Component } from 'react';
import { Platform } from 'react-native';
import { observer, inject } from 'mobx-react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import * as Linking from 'expo-linking';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { AlertProvider } from '/common/navigation';
import { navigationRoutes } from '../config/constants';
import LoginStack from './login-stack';
import OnboardingStack from './onboarding-stack';
import InitialLoadScreen from './InitialLoadScreen';
import MainStack from './main-stack';
import {
  EditConnectedAppsModal,
  CommunityNotifications,
  IndividualCommunityNotifications,
  EnterInviteIdModal,
  ChangePasswordModal,
  DeleteAccountModal,
  CoachSettingsModal,
  SyncBluetoothDeviceModal,
  EditConnectedPeripheralsModal,
} from './modals';
import AddLogScreen from './main-stack/AddLogScreen';
import EditLogScreen from './main-stack/EditLogScreen';
import { DownloadMobileApp } from '../components/sign-up';
import { getUrlParam } from '../lib/web';

// Linking.createURL is available as of expo@40.0.1 and expo-linking@2.0.1. If
// you are using older versions, you can upgrade or use Linking.makeUrl instead,
// but note that your deep links in standalone apps will be in the format
// scheme:/// rather than scheme:// if you use makeUrl.
let linking;
if (Platform.OS === 'web') {
  const prefix = Linking.createURL('/');

  // TODO: routing is cool but we really need to do some stuff with it to make the routes not look weird. See https://reactnavigation.org/docs/5.x/configuring-links
  linking = {
    prefixes: [prefix],
    config: {
      [navigationRoutes.stacks.login.welcome]: 'welcome',
      [navigationRoutes.stacks.main.root]: 'app',
      [navigationRoutes.stacks.main.tabs]: 'home',
      [navigationRoutes.stacks.main.programsTab]: 'program',
      [navigationRoutes.stacks.main.feedSubTab]: 'feed',
    },
  };
}

const Stack = createStackNavigator();

const LoggedInRoot = () => (
  <Stack.Navigator
    initialRouteName={navigationRoutes.stacks.main.root}
    options={{ gestureEnabled: false }}
    headerMode="none">
    <Stack.Screen name={navigationRoutes.stacks.main.root} component={MainStack} />
    <Stack.Screen
      name={navigationRoutes.modals.editConnectedApps}
      component={EditConnectedAppsModal}
    />
    <Stack.Screen
      name={navigationRoutes.modals.communityNotifications}
      component={CommunityNotifications}
    />
    <Stack.Screen
      name={navigationRoutes.modals.individualCommunityNotifications}
      component={IndividualCommunityNotifications}
    />
    <Stack.Screen
      name={navigationRoutes.modals.editConnectedPeripherals}
      component={EditConnectedPeripheralsModal}
    />
    <Stack.Screen
      name={navigationRoutes.modals.syncBluetoothDevice}
      component={SyncBluetoothDeviceModal}
    />
    <Stack.Screen name={navigationRoutes.modals.enterPromoCode} component={EnterInviteIdModal} />
    <Stack.Screen name={navigationRoutes.modals.changePassword} component={ChangePasswordModal} />
    <Stack.Screen name={navigationRoutes.modals.coachSettings} component={CoachSettingsModal} />
    <Stack.Screen name={navigationRoutes.modals.deleteAccount} component={DeleteAccountModal} />
    <Stack.Screen name={navigationRoutes.stacks.main.addLog_modal} component={AddLogScreen} />
    <Stack.Screen name={navigationRoutes.stacks.main.editLog_modal} component={EditLogScreen} />
  </Stack.Navigator>
);

@inject('rootStore')
@observer
class NavigationRoot extends Component {
  render() {
    const { forwardRef, rootStore } = this.props;
    console.log(rootStore.baseProtectedRoute);

    // special web download routing
    // using this in case our download page doesn't work out
    /*if (Platform.OS === 'web' && getUrlParam('goto') === 'download-app') {
      return <DownloadMobileApp />;
    }*/

    return (
      <SafeAreaProvider>
        <AlertProvider>
          <NavigationContainer
            documentTitle={{
              enabled: false, // currently, we set this in BrandingStore
              formatter: (options, route) => `Nudge for Clients`,
            }}
            linking={
              Platform.OS === 'web'
                ? /*linking*/ undefined
                : undefined /* avoid a feature we don't care about on mobile yet */
            }
            ref={forwardRef}>
            {rootStore.baseProtectedRoute === 'loggedOut' ? <LoginStack /> : null}
            {rootStore.baseProtectedRoute === 'loggedIn' ? <LoggedInRoot /> : null}
            {rootStore.baseProtectedRoute === 'onboarding' ? <OnboardingStack /> : null}
            {rootStore.baseProtectedRoute === 'init' ? (
              <Stack.Navigator initialRouteName={navigationRoutes.initialLoad} headerMode="none">
                <Stack.Screen name={navigationRoutes.initialLoad} component={InitialLoadScreen} />
              </Stack.Navigator>
            ) : null}
          </NavigationContainer>
        </AlertProvider>
      </SafeAreaProvider>
    );
  }
}

export default NavigationRoot;
