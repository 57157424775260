import React, { Component } from 'react';
import { Alert, Platform } from 'react-native';
import { withTranslation } from 'react-i18next';
import { reaction } from 'mobx';
import { inject } from 'mobx-react';

function showAlertWithDelay(title, message) {
  // add a delay since Android sometimes delays showing this until we switch screens
  if (Platform.OS === 'android') {
    setTimeout(() => {
      Alert.alert(title, message);
    }, 300);
  } else {
    Alert.alert(title, message);
  }
}

/**
 * pop-down bar notifying of new message (while in app), which only appears if the user is not already in chat
 */
@inject('rootStore')
class AddCodeAlert extends Component {
  componentDidMount() {
    const { rootStore, t } = this.props;
    this._onAddCodeReactionDisposer = reaction(
      () => rootStore.appLinkProcessor.processLinkState.isPending,
      isPending => {
        // if finished (success or fail)
        if (!isPending) {
          // if Branch
          if (
            rootStore.appLinkProcessor.processLinkResult &&
            rootStore.appLinkProcessor.processLinkResult.type === 'branch'
          ) {
            // TODO: get this into the storyboard and i18n it
            if (rootStore.appLinkProcessor.processLinkState.isFailed) {
              const formErrors = rootStore.appLinkProcessor.processLinkState.formErrors.filter(
                f => f.field === 'inviteId'
              );

              if (formErrors.find(f => f.type === 'membershipLimitExceeded')) {
                showAlertWithDelay(
                  'Connect failed',
                  t('SIGN_UP:ERRORS:INVALID_INVITE_ID_MEMBERSHIP_LIMIT_EXCEEDED')
                );
              } else if (formErrors.length) {
                showAlertWithDelay(
                  'Connect failed',
                  'Your signup link appears to be invalid. Please contact support by using the Help button on the Settings tab.'
                );
              }
            }
            if (rootStore.appLinkProcessor.processLinkState.isDone) {
              showAlertWithDelay(
                'Connect successful!',
                rootStore.appLinkProcessor.processLinkResult.message
              );
            }
          }
        }
      }
    );
  }

  componentWillUnmount() {
    if (this._onAddCodeReactionDisposer) {
      this._onAddCodeReactionDisposer();
    }
  }

  render() {
    return null;
  }
}

export default withTranslation()(function(props) {
  return <AddCodeAlert {...props} />;
});
