import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { LoadingShadeWrapper } from '../../components/common';
import { ProgramCardDetail } from '/common/components/program';
import { useScreenBehaviors } from '/common/navigation';
import { NavbarlessScreen } from '/common/components/navigation';
import {
  ScreenFirstLoadWrapper,
  useCardDetailBehaviors,
  resolveProgramEventFromRoute,
  resolveCardSourceFromRoute,
} from '../common';

const ProgramCardDetailScreen = inject(
  'rootStore',
  'Linking'
)(
  observer(({ rootStore, Linking, navigation, route }) => {
    const programEvent = resolveProgramEventFromRoute({ route, rootStore });

    const {
      onPressBack,
      onPressEvent,
      onPressLink,
      onPressViewTracker,
      onTrackOpenVideo,
      onPressJoinGroup,
      onPressPdf,
      onPressAddLog,
    } = useCardDetailBehaviors({
      rootStore,
      Linking,
      navigation,
      programEvent,
      cardOpenedSource: resolveCardSourceFromRoute(route),
    });

    // enables screen breadcrumbs
    useScreenBehaviors({});

    const { logStore } = rootStore;

    return (
      <NavbarlessScreen>
        <ScreenFirstLoadWrapper state={logStore.loadProgramDataState} hasData={!!programEvent}>
          <LoadingShadeWrapper
            style={{ flex: 1 }}
            isLoading={rootStore.logStore.saveState.isPending}>
            <ProgramCardDetail
              onPressBackOrConfirm={onPressBack}
              focusableEvents={
                programEvent
                  ? logStore.focusableEventsForClientUniqueKey(programEvent.clientFeedUniquenessKey)
                  : [] /* could be empty since program is still loading */
              }
              programEvent={programEvent}
              onPressLink={onPressLink}
              onTrackOpenVideo={onTrackOpenVideo}
              onPressPdf={onPressPdf}
              onPressJoinGroup={onPressJoinGroup}
              onPressAddLog={onPressAddLog}
              trackers={logStore.trackersSorted}
              onPressViewTracker={onPressViewTracker}
              loadState={logStore.loadProgramDataState}
              onPressEvent={onPressEvent}
            />
          </LoadingShadeWrapper>
        </ScreenFirstLoadWrapper>
      </NavbarlessScreen>
    );
  })
);

ProgramCardDetailScreen.propTypes = {
  rootStore: PropTypes.object,
  navigation: PropTypes.object,
};

export default ProgramCardDetailScreen;
