import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import { colors, textStyles, containerStyles } from '../../config/common-styles';

const Pager = observer(({ onPressLeft, onPressRight, title, style }) => (
  <View style={[containerStyles.horizontalChildrenMaxSpaceBetween, style]}>
    <TouchableOpacity onPress={onPressLeft}>
      <Image
        source={require('../../assets/icons/ui/arrow-left-white.png')}
        style={{
          width: 36,
          height: 36,
          tintColor: colors.lightText,
          marginLeft: -9,
        }}
      />
    </TouchableOpacity>
    <Text style={[textStyles.small.light, { fontWeight: '700', textTransform: 'uppercase' }]}>
      {title}
    </Text>
    <TouchableOpacity onPress={onPressRight}>
      <Image
        source={require('../../assets/icons/ui/arrow-right-white.png')}
        style={{
          width: 36,
          height: 36,
          tintColor: colors.lightText,
          marginRight: -9,
        }}
      />
    </TouchableOpacity>
  </View>
));

Pager.propTypes = {
  onPressLeft: PropTypes.func.isRequired,
  onPressRight: PropTypes.func.isRequired,
  style: PropTypes.any,
  title: PropTypes.string.isRequired,
};

Pager.defaultProps = {
  style: null,
};

export default Pager;
