import React, { Component } from 'react';
import { Alert } from 'react-native';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { withAlert } from '/common/navigation/';
import { NavbarWrapper } from '../../components/navigation';
import { LoadingShadeWrapper } from '../../components/common';
import { CoachConfig } from '../../components/messaging';
import { containerStyles } from '../../config/common-styles';
import { navigationRoutes } from '../../config/constants';

@inject('rootStore')
@observer
class CoachSettingsModal extends Component {
  componentDidMount() {
    this.props.rootStore.settingsStore.loadCoaches();
  }

  _onPressEnterPromoCode = () => {
    this.props.navigation.navigate(navigationRoutes.modals.enterPromoCode);
  };

  _onPressRemoveCoach = coachId => {
    this.props.alert(
      'Remove Coach?',
      'You will no longer recieve messages from this coach. Are you sure?',
      [
        {
          text: 'Remove Coach',
          style: 'default',
          onPress: () => {
            this.props.rootStore.settingsStore.removeCoach(coachId).then(() => {
              // leave screen if that was the last coach
              // wait on this - it doesn't work like this because the user/ coaches is reloaded
              // after the removal, so the coach is still there for the moment
              /*if (this.props.rootStore.settingsStore.user.coachesSorted.length === 0) {
                this.props.navigation.goBack();
              }*/
            });
          },
        },
        { text: 'Cancel', onPress: () => {}, style: 'cancel' },
      ]
    );
  };

  render() {
    const { navigation, rootStore, t } = this.props;
    return (
      <LoadingShadeWrapper
        style={containerStyles.fillAvailableSpace}
        isLoading={
          rootStore.settingsStore.removeCoachState.isPending ||
          rootStore.settingsStore.loadCoachesState.isPending
        }>
        <NavbarWrapper
          title={t('PROFILE:BUTTONS:COACH_SETTINGS')}
          hasBackButton={!rootStore.settingsStore.removeCoachState.isPending}
          navigation={navigation}>
          <CoachConfig
            coaches={rootStore.settingsStore.coachesSorted}
            onPressEnterPromoCode={this._onPressEnterPromoCode}
            onPressCancelCoach={this._onPressRemoveCoach}
          />
        </NavbarWrapper>
      </LoadingShadeWrapper>
    );
  }
}

CoachSettingsModal.propTypes = {
  rootStore: PropTypes.object,
  t: PropTypes.any.isRequired,
};

export default withTranslation()(withAlert(CoachSettingsModal));
