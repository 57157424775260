import React, { Component } from 'react';
import { View } from 'react-native';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { StatusBar } from 'expo-status-bar';
import { ScreenFirstLoadWrapper } from './common';
import { containerStyles, colors } from '../config/common-styles';

/**
 * Screen that appears while app is loading intially.
 * Doesn't account for navbar as it just shows a spinner and there's no risk of overlapping.
 */
@inject('rootStore')
@observer
class InitialLoadScreen extends Component {
  render() {
    const { rootStore, t } = this.props;
    return (
      <View style={[containerStyles.fillAvailableSpace, { backgroundColor: colors.bg0 }]}>
        <StatusBar hidden={false} translucent style="dark" />
        <ScreenFirstLoadWrapper
          state={rootStore.initState}
          onPressRefresh={rootStore.init}
          errorTitle={t('GENERAL:ERRORS:APP_FIRST_LOAD_TITLE')}
        />
      </View>
    );
  }
}

export default withTranslation()(InitialLoadScreen);
