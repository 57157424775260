import React, { useContext } from 'react';
import { View, SafeAreaView, Platform } from 'react-native';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import TrackerGraph from '/common/components/program/TrackerGraph';
import { colors, sizes, useMediaQueryInfo, useDeviceDimensions } from '/common/config/styles';
import { BrandContext } from '/common/config/branding';
import { TrackerStatGraphFooter } from '/common/components/program';
import { PlusIcon } from '/common/config/icons';
import RoundButton from '/common/components/RoundButton';
import { withTranslation } from 'react-i18next';

const LogEntry = observer(function({
  date,
  tracker,
  useMetricUnits,
  sliceStartDate,
  sliceEndDate,
  onPageGraphForward,
  onPageGraphBackward,
  timeframe,
  onChangeTimeframe,
  onChangeDate,
  onPressAdd,
  onPressManageEntries,
  dailyGoal,
  topOffset,
  onPressLog,
  disableAddLog,
  t,
}) {
  const branding = useContext(BrandContext);
  const highlightColor = tracker.palettesId
    ? branding.trackerColors[tracker.palettesId]
    : colors.darkText;
  const { innerWindowWidth, innerWindowHeight, isTabletOrDesktop } = useMediaQueryInfo();
  const { statusBarAndNavbarHeight } = useDeviceDimensions();

  const disableLogging =
    branding.logging.disableAddLog || disableAddLog || tracker.clientMeta.isReadOnly;

  const graphSlice = tracker.summaryGraphSlice({
    startDate: sliceStartDate,
    endDate: sliceEndDate,
    timeframe,
  });

  const collapseGraph = tracker.clientMeta.editor === 'freeform';

  const innerViewMargin = isTabletOrDesktop ? sizes.webInnerViewMargin : 0;
  const topTabPickerHeight = 50;
  const trackerStatsHeight = 115;

  const buttonAreaHeight =
    collapseGraph || disableLogging
      ? isTabletOrDesktop
        ? 105
        : 100
      : isTabletOrDesktop
      ? 105
      : 155;

  const datePickerBarHeight = 58;

  let graphHeight = collapseGraph
    ? 70
    : innerWindowHeight -
      statusBarAndNavbarHeight -
      topTabPickerHeight -
      trackerStatsHeight -
      buttonAreaHeight -
      topOffset -
      datePickerBarHeight -
      sizes.homeBarPadding -
      innerViewMargin * 2 -
      (Platform.isPad ? 200 : 0); /* provide some breathing room, don't make the lines so tall */

  // keeps the graph from looking too squished if you shrink the web window down too much
  // just make it dissappear
  if (!collapseGraph && graphHeight < 110) {
    graphHeight = 0;
  }

  let buttonArea;

  const buttonStyle = isTabletOrDesktop
    ? { flexGrow: 1, margin: 10 }
    : Platform.isPad
    ? { marginHorizontal: sizes.large * 4 }
    : {};
  if (tracker.clientMeta.multipleValuesDisabled) {
    const logsForDate = tracker.logsForDateSorted(date);

    buttonArea = (
      <>
        {logsForDate.length ? (
          <RoundButton
            theme="darkOnLight"
            onPress={onPressManageEntries}
            title={t('LOG:BUTTONS:DETAILS')}
            color={highlightColor}
            buttonStyle={buttonStyle}
          />
        ) : !disableLogging ? (
          <RoundButton
            theme="lightOnDark"
            onPress={onPressAdd}
            color={highlightColor}
            contents={<PlusIcon color={colors.bg0} size={25} />}
            buttonStyle={buttonStyle}
            hideBorder
          />
        ) : null}
      </>
    );
  } else {
    const hideNewEntryButton = tracker.clientMeta.isReadOnly || disableLogging;
    buttonArea = (
      <>
        {hideNewEntryButton ? null : (
          <RoundButton
            theme="lightOnDark"
            onPress={onPressAdd}
            color={highlightColor}
            contents={<PlusIcon color={colors.bg0} size={25} />}
            hideBorder
            buttonStyle={buttonStyle}
          />
        )}
        {!hideNewEntryButton ? <View style={{ height: 15 }} /> : null}
        <RoundButton
          theme="darkOnLight"
          onPress={onPressManageEntries}
          title={t('LOG:BUTTONS:DETAILS')}
          color={highlightColor}
          buttonStyle={buttonStyle}
        />
      </>
    );
  }

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: colors.bg1,
      }}>
      <View
        style={{
          flex: 1,
          width: innerWindowWidth,
          alignSelf: 'center',
          backgroundColor: colors.bg0,
          marginBottom: innerViewMargin,
          borderBottomLeftRadius: isTabletOrDesktop ? sizes.webBorderRadius : 0,
          borderBottomRightRadius: isTabletOrDesktop ? sizes.webBorderRadius : 0,
          overflow: 'hidden',
        }}>
        <TrackerGraph
          tracker={tracker}
          graphSlice={graphSlice}
          useMetricUnits={useMetricUnits}
          onPressPageLeft={onPageGraphBackward}
          onPressPageRight={onPageGraphForward}
          onChangeTimeframe={onChangeTimeframe}
          onPressDate={onChangeDate}
          timeframe={timeframe}
          selectedDate={date}
          graphHeight={graphHeight}
          customTarget={dailyGoal}
          datePickerBarHeight={datePickerBarHeight}
          topTabPickerHeight={topTabPickerHeight}
          style={{ minHeight: 200, flex: collapseGraph ? 0 : 1 }}
        />
        <TrackerStatGraphFooter
          tracker={tracker}
          graphSlice={graphSlice}
          date={date}
          style={{
            flexGrow: collapseGraph ? 1 : 0,
            height: collapseGraph ? 'auto' : trackerStatsHeight,
            justifyContent: collapseGraph ? 'flex-start' : 'center',
            borderTopWidth: sizes.hairlineWidth,
            borderTopColor: colors.bg2,
            borderBottomWidth: sizes.hairlineWidth,
            borderBottomColor: colors.bg2,
          }}
          onPressLog={onPressLog}
        />
        <SafeAreaView
          style={{
            flexDirection: isTabletOrDesktop ? 'row' : 'column',
            justifyContent: 'center',
            alignItems: isTabletOrDesktop ? 'center' : undefined,
            marginHorizontal: sizes.large,
            height: buttonAreaHeight + sizes.homeBarPadding,
            paddingBottom: sizes.homeBarPadding,
          }}>
          {buttonArea}
        </SafeAreaView>
      </View>
    </View>
  );
});

LogEntry.propTypes = {
  date: PropTypes.string.isRequired,
  tracker: PropTypes.object.isRequired,
  useMetricUnits: PropTypes.bool,
  sliceStartDate: PropTypes.string.isRequired,
  sliceEndDate: PropTypes.string.isRequired,
  onPageGraphForward: PropTypes.func.isRequired,
  onPageGraphBackward: PropTypes.func.isRequired,
  timeframe: PropTypes.string.isRequired,
  onChangeTimeframe: PropTypes.func.isRequired,
  onChangeDate: PropTypes.func.isRequired,
  dailyGoal: PropTypes.number,
  topOffset: PropTypes.number,
  onPressLog: PropTypes.func.isRequired,
  disableAddLog: PropTypes.bool, // used for coach view
};

LogEntry.defaultProps = {
  useMetricUnits: false,
  hideGraph: false,
  dailyGoal: null,
  isPendingSave: false,
  topOffset: 0,
  disableAddLog: false,
};

export default withTranslation()(LogEntry);
