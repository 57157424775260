import numeral from 'numeral';

const hasOverlappingTimeIntervals = ({ logs, startDateTime, endDateTime, existingLogId }) => {
  const newDateStartMs = startDateTime.toJSDate().getTime();
  const newDateEndMs = endDateTime.toJSDate().getTime();
  return !!logs.find(log => {
    // don't count if we're editing that log
    if (log.id === existingLogId) {
      return false;
    }
    const logDateStartMs = log.startTime.getTime();
    const logDateEndMs = log.endTime.getTime();

    // does not have overlap when new date ends before existing date starts
    // OR when new date starts after existing date ends
    // Therefore, we test the opposite
    return !(newDateEndMs < logDateStartMs) && !(newDateStartMs > logDateEndMs);
  });
};

// parse value and determine if it's a valid number
const parseValidNumber = ({ value, isRequired }) => {
  let trimmedValue;
  if (typeof value === 'string') {
    trimmedValue = value.trim();
  } else if (value === null || value === undefined) {
    trimmedValue = '';
  } else {
    // possibly a number?
    trimmedValue = value;
  }

  const preliminaryParsedValue = parseFloat(trimmedValue); // preliminary parse that will return NaN
  let isValid;
  if (isRequired) {
    isValid = !isNaN(preliminaryParsedValue);
  } else {
    isValid = !isNaN(preliminaryParsedValue) || trimmedValue === '';
  }
  return {
    isValid,
    value: numeral(value).value() /* parse in a locale-specific manner */,
    isEmpty: trimmedValue === '',
  };
};

const validateLogFieldValue = ({ value, field, useMetricUnits }) => {
  if (field.validator === 'number') {
    let parsedResult = parseValidNumber({ value, isRequired: field.isRequired });
    if (!parsedResult.isValid) {
      return {
        isValid: false,
      };
    }
    // don't process valid empty values further
    if (parsedResult.isEmpty) {
      return {
        isValid: true,
        value: null,
      };
    }
    // apply zero rules
    let finalValue = parsedResult.value;
    if ((field.zeroAllowed && finalValue >= 0) || (!field.zeroAllowed && finalValue > 0)) {
      // first apply metric conversions if needed
      if (useMetricUnits && field.uom_metric) {
        if (field.uom_metric === 'kg') {
          // convert to lbs
          finalValue = finalValue * 2.20462;
        }
        if (field.uom_metric === 'km') {
          finalValue = finalValue * 0.621371;
        }
      }
      return {
        value: field.decimals > 0 ? finalValue : Math.trunc(finalValue),
        isValid: true,
      };
    } else {
      return {
        isValid: false,
      };
    }
  }

  if (field.validator === 'percent') {
    let parsedResult = parseValidNumber({ value, isRequired: field.isRequired });
    if (!parsedResult.isValid || parsedResult.value < 0 || parsedResult.value > 100) {
      return {
        isValid: false,
      };
    }
    // don't process valid empty values further
    if (parsedResult.isEmpty) {
      return {
        isValid: true,
        value: null,
      };
    }
    return {
      isValid: true,
      value: parsedResult.value / 100,
    };
  }

  if (field.validator === 'minutes') {
    let parsedResult = parseValidNumber({ value, isRequired: field.isRequired });
    if (!parsedResult.isValid || parsedResult.value < 0 || parsedResult.value > 1440) {
      return {
        isValid: false,
      };
    }
    // don't process valid empty values further
    if (parsedResult.isEmpty) {
      return {
        isValid: true,
        value: null,
      };
    }
    return {
      isValid: true,
      value: parsedResult.value * 60,
    };
  }

  if (field.validator === 'string' && field.isRequired) {
    if (!value || value.trim().length === 0) {
      return {
        isValid: false,
      };
    }
  }
  // no validation required
  // we don't validate jsUserTime since it goes through the time picker
  return {
    value,
    isValid: true,
  };
};

export { hasOverlappingTimeIntervals, validateLogFieldValue, parseValidNumber };
