import Constants from 'expo-constants';
import { Platform, Dimensions } from 'react-native';
const deviceWidth = Dimensions.get('screen').width;

const isFirstGenIphoneSe = () => Platform.OS === 'ios' && deviceWidth <= 320; // iPhone SE

const predictiveTextCausesFreeze = () => {
  // see https://github.com/facebook/react-native/issues/33139
  return (
    Platform.OS === 'android' && Platform.Version === 31 && Constants.deviceName.startsWith('SM')
  );
};

const isTinyPhone = () => {
  if (isFirstGenIphoneSe()) {
    return true;
  }
  if (Platform.OS === 'android' && Dimensions.get('screen').height <= 640) {
    // typical 720ish phone, like my old Moto g4 play
    return true;
  }
  return false;
};

const supportsComplianceCircles = () => {
  // makes sure it works when taking screenshots
  if (Constants.appOwnership === 'expo') {
    return true;
  }

  return !(Platform.OS === 'android' && Constants.deviceYearClass < 2015);
};

const isOldPhone = () => {
  return Platform.OS === 'android' && Constants.deviceYearClass < 2015;
};

const isPhoneThatCrapsOutOnHugeListItems = () => {
  // background colors don't render on huge list items on these phones
  // can't find this documented anywhere
  // this makes coach community messages blank sometimes if we don't do something about it
  // there's surely more phones that do this
  // might all be Cortex-A53 phones
  // https://secure.helpscout.net/conversation/1408182176/8747/
  return (
    Platform.OS === 'android' &&
    (Constants.deviceName === 'Moto G Play' || Constants.deviceName === 'I4312') /* Xperia L3? */
  );
};

const shouldDisableCardTransitionsDueToHardwareIssues = () => {
  // This is a huawei where certain elements are dissappearing on a certain card when it's opened
  // https://secure.helpscout.net/conversation/1421098700/9177?folderId=3545058
  return Constants.deviceName === 'VOG-L09';
};

const shouldRemoveAnimationsAndScrollTransitionsForSomeAndroidPhones = () => {
  // These phones are Androids that report disappearing content
  // This might be related to the animation that fades away the header on the card detail
  // So we'll serve them a simplified version of programCardDetail
  // https://secure.helpscout.net/conversation/1584310022/11490?folderId=4544371
  return (
    Constants.deviceName === 'VOG-L09' ||
    Constants.deviceName === 'ONEPLUS A6003' ||
    Constants.deviceName === 'Redmi K20 Pro' ||
    Constants.deviceName === 'ELE-L29'
  );
};

function shouldForceOnGroupTabs(branding) {
  return branding.bundleConfig.id === 'com.nudgeyourself.pivot';
}

export {
  supportsComplianceCircles,
  isFirstGenIphoneSe,
  isOldPhone,
  isTinyPhone,
  isPhoneThatCrapsOutOnHugeListItems,
  shouldDisableCardTransitionsDueToHardwareIssues,
  shouldRemoveAnimationsAndScrollTransitionsForSomeAndroidPhones,
  shouldForceOnGroupTabs,
  predictiveTextCausesFreeze,
};
