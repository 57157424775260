import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, Observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { RowTextInput } from '/common/components';
import UpdateProfileItemList from './UpdateProfileItemList';

/**
 * We return the state with all the props anytime something changes.
 * What is changing doesn't map perfectly to what is in the object (first name, last name vs name),
 * So it's easier this way. I guess. I hate forms...
 */
@observer
class UpdateName extends Component {
  render() {
    const { firstName, lastName, onChangeFirstName, onChangeLastName, t, formErrors } = this.props;
    const sections = [];

    sections.push({
      title: '',
      data: [
        () => (
          <Observer>
            {() => (
              <RowTextInput
                value={firstName}
                label={t('PROFILE:FIELDS:FIRST_NAME')}
                onChangeText={onChangeFirstName}
                textInputProps={{ autoFocus: true }}
                validationError={
                  formErrors.find(f => f.field === 'firstName')
                    ? t('PROFILE:ERRORS:CANNOT_BE_BLANK')
                    : null
                }
              />
            )}
          </Observer>
        ),
        () => (
          <Observer>
            {() => (
              <RowTextInput
                value={lastName}
                label={t('PROFILE:FIELDS:LAST_NAME')}
                onChangeText={onChangeLastName}
                validationError={
                  formErrors.find(f => f.field === 'lastName')
                    ? t('PROFILE:ERRORS:CANNOT_BE_BLANK')
                    : null
                }
              />
            )}
          </Observer>
        ),
      ],
    });

    return <UpdateProfileItemList sections={sections} />;
  }
}

UpdateName.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  onChangeFirstName: PropTypes.func.isRequired,
  onChangeLastName: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  formErrors: PropTypes.arrayOf(
    PropTypes.shape({ field: PropTypes.string, type: PropTypes.string })
  ),
};

UpdateName.defaultProps = {
  formErrors: [],
};

export default withTranslation()(UpdateName);
