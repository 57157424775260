import React, { useCallback } from 'react';
import { Alert } from 'react-native';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useScreenBehaviors } from '/common/navigation';
import { SimpleNavButton, SimpleNavWrapper } from '../../components/navigation';
import { navigationRoutes } from '../../config/constants';
import { BluetoothPeripheralsList } from '../../components/bluetooth';
import { ScreenFirstLoadWrapper } from '../common';

const ConnectedBluetoothPeripheralsScreen = inject(
  'rootStore',
  'Linking'
)(
  observer(function({ navigation, rootStore, Linking }) {
    const { settingsStore } = rootStore;

    useScreenBehaviors({
      onFocus: settingsStore.loadBluetoothPeripherals,
    });

    const onPressNext = useCallback(() => {
      const onContinue = () => {
        rootStore.markOnboardingStepComplete(
          navigationRoutes.stacks.onboarding.connectedBluetoothPeripherals
        );
      };
      if (
        rootStore.settingsStore.supportedBluetoothPeripherals.length &&
        !rootStore.settingsStore.supportedBluetoothPeripherals.find(p => p.isPaired)
      ) {
        Alert.alert('Are you sure?', 'You can connect your devices at any time in Settings', [
          {
            text: 'Go back',
            style: 'cancel',
            onPress: () => {},
          },
          {
            text: 'Continue',
            onPress: () => {
              onContinue();
            },
          },
        ]);
      } else {
        onContinue();
      }
    }, [rootStore]);

    const onPressPeripheral = useCallback(peripheral => {
      navigation.navigate(navigationRoutes.stacks.onboarding.syncBluetoothDevice, {
        peripheralId: peripheral.peripheralId,
      });
    });

    const { t } = useTranslation();

    return (
      <SimpleNavWrapper
        rightButton={<SimpleNavButton title={'continue'} onPress={onPressNext} isPrimaryAction />}
        navigation={navigation}>
        <ScreenFirstLoadWrapper
          state={settingsStore.loadBluetoothPeripheralsState}
          hasData={settingsStore.supportedBluetoothPeripherals.length}
          onPressRefresh={settingsStore.loadBluetoothPeripherals}>
          <BluetoothPeripheralsList
            bluetoothPeripherals={settingsStore.supportedBluetoothPeripherals}
            onPressPeripheral={onPressPeripheral}
            showTitle
            showAdditionalAdvice
            onPressUrl={Linking.openURL}
          />
        </ScreenFirstLoadWrapper>
      </SimpleNavWrapper>
    );
  })
);

export default ConnectedBluetoothPeripheralsScreen;
