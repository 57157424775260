import { Component } from 'react';
import { AppState, Platform } from 'react-native';
import * as Notifications from 'expo-notifications';

export default class BadgeHandler extends Component {
  componentDidMount() {
    if (Platform.OS === 'android') {
      Notifications.dismissAllNotificationsAsync();
    }
    this.appStateChange = AppState.addEventListener('change', this._handleAppStateChange);
  }

  componentWillUnmount() {
    this.appStateChange.remove();
  }

  _handleAppStateChange = nextAppState => {
    // handling for Android
    // dismiss notifications because you probably just read the messages
    if (Platform.OS === 'android') {
      Notifications.dismissAllNotificationsAsync();
    }

    // handling for everyone
    if (Platform.OS === 'ios' && nextAppState === 'inactive') {
      Notifications.setBadgeCountAsync(this.props.getBadgeNumber());
    }
  };

  render() {
    return null;
  }
}
