import React, { useContext } from 'react';
import { Text } from 'react-native';
import ParsedText from 'react-native-parsed-text';
import { observer } from 'mobx-react';
import { textStyles } from '/common/config/styles';
import { BrandContext } from '/common/config/branding';
import cardConstants from '../cardConstants';

function renderParsedText(matchingString) {
  if (!matchingString) {
    return '';
  }
  if (matchingString.length > 60) {
    return matchingString.substring(0, 57) + '...';
  }
  return matchingString;
}

const CardTextComponent = observer(function({ onPressLink, headlineMode, component }) {
  const branding = useContext(BrandContext);
  const parseTypes = [
    {
      type: 'url',
      style: [textStyles.link, { color: branding.highlightedColor }],
      onPress: onPressLink,
      renderText: renderParsedText,
    },
  ];
  return (
    <Text
      key={component.id.toString()}
      ellipsizeMode={'tail'}
      numberOfLines={headlineMode === 'summary' ? 2 : null}
      style={[
        {
          marginTop: headlineMode === 'summary' ? -10 : 0,
          marginBottom: headlineMode === 'summary' ? 5 : cardConstants.elementSpacing,
          lineHeight: 22,
        },
      ]}>
      <ParsedText parse={parseTypes} style={textStyles.standard.light}>
        {component.body}
      </ParsedText>
    </Text>
  );
});

export default CardTextComponent;