import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { BackIcon } from '/common/config/icons';
import { useAlert } from '/common/navigation';
import { SimpleNavWrapper, HeaderIconButton } from '../../components/navigation';
import { LoadingShadeWrapper } from '../../components/common';
import { LoginForm } from '../../components/login';
import { withKeyboardAvoidance } from '../common';
import { navigationRoutes } from '../../config/constants';
import { colors } from '/common/config/styles';

const EnterCredentialsScreen = inject('rootStore')(
  observer(function({ navigation, rootStore }) {
    const loginFormRef = useRef();

    const alert = useAlert();

    const { t } = useTranslation();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const onPressForgotPassword = useCallback(() => {
      navigation.navigate(navigationRoutes.stacks.login.requestPasswordReset, {
        username,
      });
    }, [navigation, username]);

    const onPressLogin = useCallback(() => {
      async function doAsync() {
        await rootStore.login({ username, password });
        if (rootStore.loginState.isFailed) {
          const error = rootStore.loginState.error;
          let errorDescription;
          if (error.response && (error.response.status === 401 || error.response.status === 422)) {
            errorDescription = t(`LOGIN:ERRORS:INCORRECT_USERNAME_PASSWORD`);
          } else if (error.response && error.response.status === 503) {
            errorDescription = t(`GENERAL:ERRORS:SERVER_UNAVAILABLE_ERROR_MESSAGE`);
          } else {
            errorDescription = t(`GENERAL:ERRORS:ALERT_UNEXPECTED_ERROR_MESSAGE`);
          }
          alert(t('LOGIN:ERRORS:GENERIC_TITLE'), errorDescription, [
            {
              text: t('GENERAL:OK'),
              style: 'default',
              onPress: () => {
                loginFormRef.current && loginFormRef.current.setFocus();
              },
            },
          ]);
        }
      }
      doAsync();
    }, [username, password, rootStore, t]);

    return (
      <SimpleNavWrapper
        containerStyle={{ backgroundColor: colors.bg0 }}
        title={t('LOGIN:BUTTONS:SIGN_IN')}
        leftButton={<HeaderIconButton icon={<BackIcon />} onPress={navigation.goBack} />}>
        <LoadingShadeWrapper style={{ flex: 1 }} isLoading={rootStore.loginState.isPending}>
          <LoginForm
            ref={loginFormRef}
            username={username}
            onChangeUsername={setUsername}
            password={password}
            onChangePassword={setPassword}
            onPressLogin={onPressLogin}
            onPressForgotPassword={onPressForgotPassword}
          />
        </LoadingShadeWrapper>
      </SimpleNavWrapper>
    );
  })
);

EnterCredentialsScreen.propTypes = {
  rootStore: PropTypes.object,
};

export default withKeyboardAvoidance(EnterCredentialsScreen);
