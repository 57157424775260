import camelcaseKeys from 'camelcase-keys';
import { padStart } from 'lodash';
import { dateStringToJsDate, jsDateToDateString } from 'nudge-client-common/lib/dates';
import { TrackerMetadataProvider } from 'nudge-client-common/data/trackers';
import { imageDtoToSource } from './utils';

export default class LogRepository {
  _apiV5;
  _userRepository;

  /**
   *
   * @param {Object} params - all parameters
   * @param {Object} params.apiV5 - Axios object pointing to API v5 by default
   */
  constructor({ apiV5, userRepository }) {
    this._apiV5 = apiV5;
    this._userRepository = userRepository;
  }

  getTags = async ({ page = 1 }) => {
    const response = await this._apiV5.get('/users/me/feed/collections', {
      params: {
        limit: 20,
        page,
      },
    });

    return camelcaseKeys(response.data, { deep: true });
  };

  /**
   * Returns a similar object structure as getProgramCardsAndEvents, except filtered on cards assigned to the
   * provided tag, and with just the latest events for each matching card.
   */
  getCardEventsForTag = async ({ tag, endDate }) => {
    const response = await this._apiV5.get(`users/me/feed/collections/cards`, {
      params: {
        tag,
        end_at: endDate,
      },
    });

    const finalData = {
      cards: camelcaseKeys(response.data.cards, { deep: true }),
      events: [],
    };

    finalData.cards = finalData.cards.map(this._cardDtoToModel);

    response.data.dates.forEach(dateObj => {
      const date = dateObj.date;
      const sharesForDate = dateObj.shares;
      sharesForDate.forEach(share => {
        finalData.events.push({
          share: camelcaseKeys(share),
          date,
          id: `${date}|${padStart(share.card_id, 8, '0')}`,
        });
      });
    });

    return finalData;
  };

  /**
   * Returns program cards and the dates they were delivered ("events") for the date range specified.
   * The nextAt date will be the next time unique (non-repeating) cards are returned, such that loading
   * additional items will load more cards in the feed (and not just add to the end of repeating stacks)
   */
  getProgramCardsAndEvents = async ({ startDate, endDate }) => {
    // client ID is ignored here because we're always in the current client context
    const response = await this._apiV5.get(`/users/me/feed/cards`, {
      params: {
        with_trackers: true,
        filter_trackers: 1,
        start_at: startDate,
        end_at: endDate,
      },
    });
    const finalData = {
      cards: camelcaseKeys(response.data.cards, { deep: true }),
      events: [],
      /* returns next unique stack instead of next card delivery.
      This is a workaround for repeating stacks, in order to prevent runaway queries for additional cards
      that don't actually show any additional items in the list
      */
      nextAt: response.data.compressed_next_at
        ? { date: jsDateToDateString(dateStringToJsDate(response.data.compressed_next_at.date)) }
        : null,
      // We are now fetching trackers from this endpoint
      // and making myData tab a seperate call to minimize load time on log in
      trackers: this.formatTrackers(response.data.trackers),
    };

    finalData.cards = finalData.cards.map(this._cardDtoToModel);

    response.data.dates.forEach(dateObj => {
      const date = dateObj.date;
      const sharesForDate = dateObj.shares;
      const opensForDate = dateObj.card_opens ? dateObj.card_opens : [];
      sharesForDate.forEach(share => {
        finalData.events.push({
          share: camelcaseKeys(share),
          date,
          id: `${date}|${padStart(share.card_id, 8, '0')}`,
          showCardOpenedStatus: opensForDate
            ? !!opensForDate.find(i => i.card_id === share.card_id && i.show_card_opened_status)
            : false,
          wasOpened: opensForDate
            ? !!opensForDate.find(i => i.card_id === share.card_id && i.card_opened)
            : false,
        });
      });
    });
    return finalData;
  };

  _cardDtoToModel = card => {
    const cardModel = { ...card };
    cardModel.serverId = card.id;
    cardModel.id = card.id.toString();
    cardModel.upload = imageDtoToSource(card.upload, this._apiV5);
    cardModel.background = card.background
      ? camelcaseKeys(JSON.parse(card.background), { deep: true })
      : null;
    cardModel.components.forEach(component => {
      component.serverId = component.id;
      component.id = component.id.toString();
      component.meta = component.meta
        ? camelcaseKeys(JSON.parse(component.meta), { deep: true })
        : null;
      component.upload = imageDtoToSource(component.upload, this._apiV5);
    });

    return cardModel;
  };

  formatTrackers = trackersParam => {
    let trackers = trackersParam;
    // check for that weird issue we see in sentry
    if (!Array.isArray(trackers)) {
      let errorMessage = 'Response is not an array! It is actually a ' + typeof trackers;
      if (typeof trackers === 'string') {
        errorMessage = errorMessage + trackers;
      }
      throw new Error(errorMessage);
    }

    let metadataProvider = new TrackerMetadataProvider();

    // turn into JS format
    trackers = camelcaseKeys(trackers, { deep: true });
    metadataProvider.processTrackers(trackers);

    trackers.forEach(t => {
      t.serverId = t.id;
      t.id = t.id.toString();
      // in the long run, we should treat the metadata separate in the models, but easier to put it here for now
      // at least now we're using the metadata provider.
      const trackerMetadata = metadataProvider.getTrackerClientMetadata({ tracker: t });
      t.clientMeta = trackerMetadata; // attach metadata for use in defining how to display and validate trackers

      t.user.logs = t.user.logs
        ? t.user.logs.map(l =>
            this._logDtoToLogModel({
              trackerId: t.id,
              trackerType: trackerMetadata.type,
              logDto: l,
              metadataProvider,
            })
          )
        : []; /* null can still happen on this when there's no logs for a tracker... but not always? Weird ... */
    });

    return trackers;
  };

  /**
   * useCache is used by TrendsStore because it generally needs whatever LogStore just fetched
   * getHead gets the first record for each tracker *after* the range. Used for drawing lines "off the page"
   * of a graph.
   */
  async getDailyLogs({ clientId, startDate, endDate, getHead = false }) {
    // client ID is ignored here because we're always in the current client context
    const response = await this._apiV5.get(`/users/me/trackers`, {
      params: {
        log_date_from: startDate,
        log_date_to: endDate,
        tail: true,
        head: getHead,
        is_graphable: 1,
      },
    });

    return this.formatTrackers(response.data);
  }

  async getDailyLogsForTracker({
    clientId,
    trackerId,
    trackerType,
    startDate,
    endDate,
    tail = 1,
    head = 1,
  }) {
    const response = await this._apiV5.get(`/trackers/${trackerId}/logs`, {
      params: {
        log_date_from: startDate,
        log_date_to: endDate,
        limit: 1000000,
        users_id: clientId,
        tail,
        head,
      },
      // this lead to caching of month slices on the trends scene, showing invalid data after the data was refreshed
      //cache: this._logCache,
    });

    let logs = response.data.data;

    let metadataProvider = new TrackerMetadataProvider();

    // turn into JS format
    logs = camelcaseKeys(logs, { deep: true });

    metadataProvider.processLogs({ logs, trackerType, trackerId });

    const logModels = logs.map(l =>
      this._logDtoToLogModel({
        trackerId,
        trackerType,
        logDto: l,
        metadataProvider,
      })
    );

    return logModels;
  }

  _logDtoToLogModel = ({ trackerType, logDto, metadataProvider, trackerId }) => {
    const logModel = Object.assign({}, logDto);
    logModel.id = logDto.id.toString();
    logModel.serverId = logDto.id;
    logModel.clientMeta = metadataProvider.getLogClientMetadata({
      trackerType,
      log: logModel,
      trackerId,
    });

    return logModel;
  };

  async addLog(log) {
    const logDto = Object.assign({}, log);
    logDto.users_id = this._userRepository.userDto.id;
    logDto.user_time = logDto.userTime;
    logDto.hi_duration = logDto.hiDuration;
    logDto.fat_ratio = logDto.fatRatio;
    logDto.blood_glucose = logDto.bloodGlucose;
    logDto.heart_rate = logDto.heartRate;
    logDto.activity_id = logDto.activityId;
    // prevent possible bug with null response - we allow updates to an empty response via add API
    // see https://github.com/nudgeyourself/nudge-api/issues/83
    if (log.trackerType.startsWith('custom-question')) {
      logDto.response = logDto.response ? logDto.response : '';
    }
    // it's bad if we pass this and shouldn't be and its a steps/ cardio record
    logDto.type = undefined;
    const response = await this._apiV5.post(`/trackers/${log.trackerId}/logs`, logDto);
    const metadataProvider = new TrackerMetadataProvider();
    metadataProvider.processLogs({
      logs: [],
      trackerType: log.trackerType,
      trackerId: log.trackerId,
    });
    return this._logDtoToLogModel({
      logDto: response.data,
      trackerId: log.trackerId,
      trackerType: log.trackerType,
      metadataProvider,
    });
  }

  async deleteLog({ trackerId, logId }) {
    const response = await this._apiV5.delete(`/trackers/${trackerId}/logs/${logId}`);
    return response.data;
  }

  async updateLog({ trackerId, log }) {
    const logDto = Object.assign({}, log);
    logDto.id = log.serverId;
    logDto.users_id = this._userRepository.userDto.id;
    logDto.user_time = logDto.userTime;
    logDto.hi_duration = logDto.hiDuration;
    logDto.fat_ratio = logDto.fatRatio;
    logDto.blood_glucose = logDto.bloodGlucose;
    logDto.heart_rate = logDto.heartRate;
    logDto.activity_id = logDto.activityId;
    // it's bad if we pass this and shouldn't be and its a steps/ cardio record
    logDto.type = undefined;
    // prevent dead log parent access issue in case of a quick update then delete
    const trackerType = log.trackerType;
    const response = await this._apiV5.put(`/trackers/${trackerId}/logs/${log.serverId}`, logDto);
    const metadataProvider = new TrackerMetadataProvider();
    metadataProvider.processLogs({ logs: [], trackerType, trackerId });
    return this._logDtoToLogModel({
      logDto: response.data,
      trackerId: log.trackerId,
      trackerType: log.trackerType,
      metadataProvider,
    });
  }

  async addNewCardReadStatus({ stackId, deliveredOn }) {
    await this._apiV5.post(`/users/${this._userRepository.userDto.id}/card-opened`, {
      stack_id: stackId,
      delivered_on: deliveredOn,
    });
  }

  async requestUpdateOnDemandApps(date) {
    if (!this._userRepository.userDto) {
      return;
    }

    if (!this._userRepository.userDto.pollingConnectedAppsRequired) {
      return;
    }

    const statuses = await this._apiV5.get('/users/me/integrations/sync', { date });
    // simulating a typical API error so we can provide a decent error message
    // can return null if didn't sync due to too many syncs close together

    // SSO is returning as an actual integration on labels that use SSO, and can return false
    const filteredServices = statuses.data.filter(
      s => s.type !== 'sso' && s.type !== 'validicInform' && s.type !== 'validic'
    );
    if (filteredServices.find(s => s.success === false)) {
      const error = new Error('Error syncing data with one or more services');
      error.additionalClientData = {
        servicesWithErrors: filteredServices
          .filter(s => !s.success)
          .map(s => ({
            name: s.type,
          })),
      };
      throw error;
    }
  }
}
