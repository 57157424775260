import env from '../../config/env';

/**
 * Temporary home for consolidating logo/ background/ theme determination.
 * The actual theme used is dictated by the presence of a white label config or
 * a theme on the user object (off-white label). White label config wins.
 * The goal would be to put this into React context once we have Reacdt 16.6 and the
 * API isn't super-awkward.
 * Also, brand color should be moved here, too, so we don't have this incredible chain
 * of imports whenever using any style (unit tests will thank us)
 */
const getTheme = ({ rootStore, branding }) => {
  // white label wins
  // NOTE: some labels don't have either of these assets, so we check against name (not the best check, but...)
  // We do this in order to suppress any cobrand that was possibly added to the account
  if (
    branding.assets.logo ||
    (branding.appName !== 'Nudge for clients' && branding.appName !== 'Nudge')
    // this should say "Nudge" - this is a way to suppress cobrands on white labels
    // however, with brand bundle, the internal app name was accidentally changed to Nudge for clients
    // this needs to be fixed with a binary update eventually
  ) {
    return {
      logo: branding.assets.logo,
      backgroundColor: branding.darkThemeBackground,
      background: null,
      useDarkTheme: branding.useDarkTheme,
    };
  }
  // next try co-brand
  if (rootStore.settingsStore.user && rootStore.settingsStore.user.theme && !env.disableCobrands) {
    const theme = rootStore.settingsStore.user.theme;
    return {
      logo: theme.logoSource,
      backgroundColor: null,
      background: theme.backgroundSource,
      useDarkTheme: theme.useDarkTheme,
    };
  }

  // otherwise use defaults
  return {
    logo: null,
    backgroundColor: null,
    background: null, // probably not needed anymore because it was for nudge score?
    useDarkTheme: false,
  };
};

export { getTheme };
