import React, { useContext, useState, useEffect, useRef, useCallback } from 'react';
import { View, Text, Platform } from 'react-native';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { RoundButton } from '../common';
import { sizes, textStyles, useMediaQueryInfo } from '/common/config/styles';
import SignUpField from './SignUpField';
import { KeyboardAwareScrollView } from '/common/components';
import { BrandContext } from '../../config/branding';

const EnterNameEmailAndPassword = observer(
  ({
    firstName,
    lastName,
    email,
    password,
    onChangeEmail,
    onChangePassword,
    onChangeFirstName,
    onChangeLastName,
    onPressSubmit,
    onPressTermsOfService,
    formErrors,
  }) => {
    const scrollViewRef = useRef(null);

    const delayScrollToFocusedInput = useCallback(() => {
      scrollViewRef.current && scrollViewRef.current.androidTrackToFocusedTextInput();
    }, [scrollViewRef]);

    const branding = useContext(BrandContext);

    const { t } = useTranslation();

    const [focus, setFocus] = useState(null);

    useEffect(() => {
      setTimeout(() => {
        setFocus('firstName');
      }, 500);
    }, []);

    const { innerWindowWidth } = useMediaQueryInfo();

    let firstNameValidationErrorText = null;
    let nameErrors = formErrors.filter(e => e.field === 'name');
    if (nameErrors.length) {
      if (nameErrors.find(e => e.type === 'blank')) {
        firstNameValidationErrorText = t('SIGN_UP:ERRORS:NAME_BLANK');
      }
    }

    // add invite ID errors to name error field
    if (formErrors.find(e => e.field === 'inviteId' && e.type === 'invalid')) {
      firstNameValidationErrorText = t('SIGN_UP:ERRORS:INVALID_INVITE_ID', {
        inviteIdName: branding.inviteIdDisplay.name,
      });
    } else if (
      formErrors.find(e => e.field === 'inviteId' && e.type === 'membershipLimitExceeded')
    ) {
      firstNameValidationErrorText = t(
        'SIGN_UP:ERRORS:INVALID_INVITE_ID_MEMBERSHIP_LIMIT_EXCEEDED',
        {
          inviteIdName: branding.inviteIdDisplay.name,
        }
      );
    }

    let emailValidationErrorText = null;
    const emailErrors = formErrors.filter(e => e.field === 'email');
    if (emailErrors.length) {
      if (emailErrors.find(e => e.type === 'alreadyUsed')) {
        emailValidationErrorText = t('SIGN_UP:ERRORS:EMAIL_ALREADY_IN_USE');
      }
      if (emailErrors.find(e => e.type === 'invalid')) {
        emailValidationErrorText = t('SIGN_UP:ERRORS:EMAIL_INVALID');
      }
      if (emailErrors.find(e => e.type === 'blank')) {
        emailValidationErrorText = t('SIGN_UP:ERRORS:EMAIL_BLANK');
      }
    }

    let passwordValidationErrorText;
    if (formErrors.find(e => e.field === 'password' && e.type === 'notMinimumLength')) {
      passwordValidationErrorText = t('SIGN_UP:ERRORS:PASSWORD_NOT_MINIMUM_LENGTH');
    }
    if (formErrors.find(e => e.field === 'password' && e.type === 'invalid')) {
      passwordValidationErrorText = t('SIGN_UP:ERRORS:PASSWORD_INVALID');
    }

    return (
      <View style={{ flex: 1 }}>
        <KeyboardAwareScrollView
          scrollViewProps={{
            showsVerticalScrollIndicator: false,
            keyboardShouldPersistTaps: 'handled',
            contentContainerStyle: {
              width: innerWindowWidth,
              alignSelf: 'center',
              flexGrow: 1,
            },
          }}
          ref={scrollViewRef}
          textInputOffsetFromKeyboard={
            Platform.OS === 'android' ? 30 + 35 + 110 + 90 : 100 + 80 /* extra space for TOC */
          }>
          <View
            style={{
              marginHorizontal: sizes.large,
              paddingTop: sizes.large * 2,
            }}>
            <Text
              style={[textStyles.centered, textStyles.bold.dark, { marginBottom: sizes.large }]}>
              {t('SIGN_UP:CREATE_YOUR_ACCOUNT')}
            </Text>
            <SignUpField
              text={firstName}
              onChangeText={onChangeFirstName}
              placeholder={'first name'}
              focus={focus === 'firstName'}
              onSubmitEditing={() => {
                setFocus('lastName');
              }}
              onFocus={() => {
                setFocus('firstName');
                delayScrollToFocusedInput();
              }}
              validationError={firstNameValidationErrorText}
              autoCompleteType="name"
              autoCapitalize="words"
              blurOnSubmit={false}
              returnKeyType="next"
            />
            <SignUpField
              text={lastName}
              onChangeText={onChangeLastName}
              placeholder={'last name'}
              focus={focus === 'lastName'}
              onSubmitEditing={() => {
                setFocus('email');
              }}
              onFocus={() => {
                setFocus('lastName');
                delayScrollToFocusedInput();
              }}
              autoCompleteType="name"
              autoCapitalize="words"
              blurOnSubmit={false}
              returnKeyType="next"
            />
            <SignUpField
              text={email}
              onChangeText={onChangeEmail}
              placeholder={'email'}
              focus={focus === 'email'}
              onSubmitEditing={() => {
                setFocus('password');
              }}
              onFocus={() => {
                setFocus('email');
                delayScrollToFocusedInput();
              }}
              validationError={emailValidationErrorText}
              keyboardType="email-address"
              autoCompleteType="email"
              blurOnSubmit={false}
              returnKeyType="next"
            />
            <SignUpField
              text={password}
              onChangeText={onChangePassword}
              placeholder="password"
              isSecure
              returnKeyType="done"
              focus={focus === 'password'}
              onFocus={() => {
                setFocus('password');
                delayScrollToFocusedInput();
              }}
              onSubmitEditing={onPressSubmit}
              validationError={passwordValidationErrorText}
            />
            <RoundButton
              buttonStyle={{ marginTop: sizes.large, marginBottom: sizes.large }}
              theme="lightOnDark"
              onPress={onPressSubmit}
              isDisabled={
                !firstName ||
                firstName.trim().length === 0 ||
                !email ||
                email.trim().length === 0 ||
                !password ||
                password.trim().length === 0
              }
              title={t('SIGN_UP:BUTTONS:SUBMIT').toLowerCase()}
            />
            <Text
              style={[
                textStyles.centered,
                textStyles.small.light,
                { marginTop: sizes.medium, marginBottom: sizes.medium },
              ]}>
              {t('SIGN_UP:TERMS_OF_SERVICE:DESCRIPTION', { appName: branding.appName })}{' '}
              <Text onPress={onPressTermsOfService} style={{ color: branding.highlightedColor }}>
                {t('SIGN_UP:TERMS_OF_SERVICE:LINK_NAME')}
              </Text>
              {'.'}
            </Text>
          </View>
        </KeyboardAwareScrollView>
      </View>
    );
  }
);

EnterNameEmailAndPassword.propTypes = {
  onPressSubmit: PropTypes.func.isRequired,
  onChangeEmail: PropTypes.func.isRequired,
  onPressTermsOfService: PropTypes.func.isRequired,
  email: PropTypes.string,
  onChangePassword: PropTypes.func.isRequired,
  password: PropTypes.string,
  onChangeFirstName: PropTypes.func.isRequired,
  firstName: PropTypes.string,
  onChangeLastName: PropTypes.func.isRequired,
  lastName: PropTypes.string,
  formErrors: PropTypes.array,
};

EnterNameEmailAndPassword.defaultProps = {
  email: '',
  formErrors: [],
  firstName: '',
  lastName: '',
};

export default EnterNameEmailAndPassword;
