import React, {
  useContext,
  useEffect,
  useCallback,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import {
  View,
  TextInput,
  SafeAreaView,
  TouchableOpacity,
  Text,
  Keyboard,
  Platform,
} from 'react-native';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { RoundButton } from '/common/components';
import { sizes, colors, textStyles, useMediaQueryInfo } from '/common/config/styles';
import { BrandContext } from '../../../config/branding';
import Styles from './styles';

const LoginForm = observer(
  forwardRef(function(
    { onPressLogin, username, onChangeUsername, password, onChangePassword, onPressForgotPassword },
    ref
  ) {
    const branding = useContext(BrandContext);

    const { t } = useTranslation();

    const usernameTextBoxRef = useRef();

    const passwordTextBoxRef = useRef();

    const { innerWindowWidth } = useMediaQueryInfo();

    useImperativeHandle(ref, () => ({
      setFocus: () => {
        passwordTextBoxRef.current && passwordTextBoxRef.current.focus();
      },
    }));

    const onPressLoginCallback = useCallback(() => {
      onPressLogin();
      Keyboard.dismiss();
    }, [onPressLogin]);

    // workaround for focus not working right on web
    useEffect(() => {
      if (Platform.OS === 'web' && usernameTextBoxRef.current) {
        setTimeout(() => {
          usernameTextBoxRef.current.focus();
        }, 500);
      }
    }, []);

    return (
      <SafeAreaView>
        <View style={[Styles.wrapper, { width: innerWindowWidth, alignSelf: 'center' }]}>
          <TextInput
            ref={usernameTextBoxRef}
            onChangeText={onChangeUsername}
            value={username}
            textContentType="username"
            importantForAutofill="yes"
            placeholder={t('LOGIN:FIELDS:EMAIL')}
            placeholderTextColor={colors.lightText}
            autoCorrect={false}
            autoCapitalize="none"
            style={[
              {
                height: 44,
                borderBottomColor: colors.border0,
                borderBottomWidth: 1,
              },
              textStyles.standard.dark,
            ]}
            onSubmitEditing={() => passwordTextBoxRef.current && passwordTextBoxRef.current.focus()}
            returnKeyType="next"
            keyboardType="email-address"
            autoFocus
          />
          <TextInput
            ref={passwordTextBoxRef}
            importantForAutofill="yes"
            textContentType="password"
            onChangeText={onChangePassword}
            value={password}
            placeholder={t('LOGIN:FIELDS:PASSWORD')}
            placeholderTextColor={colors.lightText}
            style={[{ height: 44, marginBottom: sizes.medium }, textStyles.standard.dark]}
            autoCapitalize="none"
            secureTextEntry
            returnKeyType="go"
            onSubmitEditing={onPressLogin}
          />
          <RoundButton title={t('LOGIN:BUTTONS:SIGN_IN')} onPress={onPressLoginCallback} />
          <View
            style={{
              marginTop: sizes.medium,
              height: 20 /* not sure why I need a height here, but it doesn't show up if I don't do that */,
            }}>
            <TouchableOpacity onPress={onPressForgotPassword}>
              <View>
                <Text
                  style={[
                    textStyles.small.dark,
                    {
                      color: branding.highlightedColor,
                    },
                  ]}>
                  {t('LOGIN:BUTTONS:FORGOT_PASSWORD')}
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
      </SafeAreaView>
    );
  })
);

LoginForm.propTypes = {
  onChangePassword: PropTypes.func.isRequired,
  onChangeUsername: PropTypes.func.isRequired,
  onPressLogin: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  onPressForgotPassword: PropTypes.func.isRequired,
};

export default LoginForm;
