import React from 'react';
import { View } from 'react-native';
import { RoundButton } from '/common/components';
import { observer } from 'mobx-react';
import cardConstants from '../cardConstants';

const CardButtonComponent = observer(function({ onPressLink, component }) {
  return (
    <View key={component.id.toString()} style={{ marginBottom: cardConstants.elementSpacing }}>
      <RoundButton onPress={onPressLink} title={component.body} />
    </View>
  );
});

export default CardButtonComponent;
