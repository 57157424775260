import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import { containerStyles } from '../../config/styles';

const NavbarlessScreen = ({ children, style }) => {
  return <View style={[containerStyles.fillAvailableSpace, style]}>{children}</View>;
};

NavbarlessScreen.propTypes = {
  children: PropTypes.any.isRequired,
};

export default NavbarlessScreen;
