import React from 'react';
import { TouchableWithoutFeedback } from 'react-native';
import { SecurableImage } from '/common/components';
import { observer } from 'mobx-react';
import { useMediaQueryInfo } from '/common/config/styles';
import cardConstants from '../cardConstants';

const CardImageComponent = observer(function({ onPressImage, component }) {
  const { innerWindowWidth } = useMediaQueryInfo();
  const imageWidth = innerWindowWidth - cardConstants.elementSpacing * 2;

  const scaleHeight = (source, desiredWidth) => {
    if (source.height && source.width) {
      const { width, height } = source; /* any server asset has height and width */
      return (desiredWidth / width) * height;
    } else {
      return (imageWidth * 9) / 16;
    }
  };

  return (
    <TouchableWithoutFeedback onPress={onPressImage}>
      <SecurableImage
        key={component.id.toString()}
        source={component.image}
        style={{
          width: imageWidth,
          height: component.image ? scaleHeight(component.image, imageWidth) : 0,
          marginBottom: cardConstants.elementSpacing,
          borderRadius: 4,
        }}
      />
    </TouchableWithoutFeedback>
  );
});

export default CardImageComponent;
