import React, { useState, useImperativeHandle, forwardRef, useCallback } from 'react';
import { Platform } from 'react-native';
import PropTypes from 'prop-types';
import { useNavigation } from '@react-navigation/native';
import Modal from 'react-native-modal';
import { observer } from 'mobx-react';
import FullscreenImage from './FullscreenImage';
import { colors, sizes } from '../config/styles';
import { CloseModalIcon } from '../config/icons';

const FullscreenImageModal = observer(
  forwardRef(function({ extraBottomNavigationButton }, ref) {
    const [isModalVisible, setModalVisible] = useState(false);
    const [source, setSource] = useState(null);
    const [sourceMeta, setSourceMeta] = useState(null);

    const navigation = useNavigation();

    useImperativeHandle(ref, () => ({
      // show the image and optionally pass metadata about the source
      // for group messaging, this will be the current message
      // and will be used for navigating to the individual topic
      show: (source, sourceMeta) => {
        setSource(source);
        setSourceMeta(sourceMeta);
        setModalVisible(true);
      },
    }));

    const onPressClose = useCallback(() => {
      setModalVisible(false);
    });

    return (
      <Modal
        isVisible={isModalVisible}
        style={{
          margin: 0,
        }}
        animationIn="fadeIn"
        animationOut="fadeOut"
        hasBackdrop={false}
        hideModalContentWhileAnimating>
        <FullscreenImage
          CloseButtonComponent={() => <CloseModalIcon />}
          source={source}
          onPressClose={onPressClose}
          navigation={navigation}
          extraBottomButton={
            extraBottomNavigationButton
              ? {
                  title: extraBottomNavigationButton.title,
                  onPress: () => {
                    extraBottomNavigationButton.onPress(sourceMeta);
                    setModalVisible(false);
                  },
                }
              : null
          }
        />
      </Modal>
    );
  })
);

export default FullscreenImageModal;
