import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Keyboard, Platform } from 'react-native';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { ImagePicker } from '/common/screens';
import { connectAlertBar, commonNavigationRoutes, withActionSheet } from '/common/navigation';
import { LoadingShadeWrapper } from '../../components/common';
import { NavbarWrapper, HeaderIconButton } from '../../components/navigation';
import { EditProfile } from '../../components/settings';
import { containerStyles } from '../../config/common-styles';
import { BackIcon } from '../../config/common-icons';
import { navigationRoutes } from '../../config/constants';
import { SuccessAlertBar, ErrorAlertBar } from '../common';
import env from '../../config/env';

@inject('rootStore')
@connectAlertBar
@observer
class EditProfileScreen extends Component {
  _onPressChangePassword = () => {
    this.props.navigation.navigate(navigationRoutes.modals.changePassword);
  };

  _onPressLogout = () => {
    this.props.rootStore.logout(true);
  };

  _onPressCoachSettings = () => {
    this.props.navigation.navigate(navigationRoutes.modals.coachSettings);
  };

  _onConfirmUpdatePhoto = imageSource => {
    const { rootStore, showAlertBar } = this.props;
    const { settingsStore } = rootStore;
    settingsStore.updateUserPhoto({ image: imageSource }).then(() => {
      if (settingsStore.updateUserPhotoState.isDone) {
        showAlertBar({ view: <SuccessAlertBar title="Photo updated" /> });
      }
      if (settingsStore.updateUserPhotoState.isFailed) {
        showAlertBar({ view: <ErrorAlertBar title="Photo upload failed" /> });
      }
    });
  };

  _onPressSetProfilePhoto = () => {
    const { navigation, t } = this.props;

    const options = [
      t('GENERAL:PHOTO_PICKER:CHOOSE_FROM_LIBRARY'),
      t('GENERAL:PHOTO_PICKER:TAKE_PICTURE_WITH_CAMERA'),
      t('GENERAL:CANCEL'),
    ];

    const cancelButtonIndex = options.length - 1;

    if (Platform.OS === 'android') {
      Keyboard.dismiss();
    }

    if (Platform.OS === 'web') {
      ImagePicker.pickImageFromLibrary().then(imageSource => {
        if (imageSource.canceled) {
          return;
        }
        navigation.navigate(commonNavigationRoutes.previewImage, {
          source: imageSource.assets[0],
          onPressConfirm: () => this._onConfirmUpdatePhoto(imageSource.assets[0]),
          containToCircle: true,
        });
      });
      return;
    }

    this.props.showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        showSeparators: false,
      },
      buttonIndex => {
        if (buttonIndex === 0) {
          ImagePicker.pickImageFromLibrary().then(imageSource => {
            if (imageSource.canceled) {
              return;
            }
            navigation.navigate(commonNavigationRoutes.previewImage, {
              source: imageSource.assets[0],
              onPressConfirm: () => this._onConfirmUpdatePhoto(imageSource.assets[0]),
              containToCircle: true,
            });
          });
        } else if (buttonIndex === 1) {
          ImagePicker.pickImageFromCamera().then(imageSource => {
            if (imageSource.canceled) {
              return;
            }
            navigation.navigate(commonNavigationRoutes.previewImage, {
              source: imageSource.assets[0],
              onPressConfirm: () => this._onConfirmUpdatePhoto(imageSource.assets[0]),
              containToCircle: true,
            });
          });
        }
      }
    );
  };

  _onPressDeleteAccount = () => {
    this.props.navigation.navigate(navigationRoutes.modals.deleteAccount);
  };

  _onPressName = () => {
    this.props.navigation.navigate(navigationRoutes.stacks.main.updateName);
  };

  _onPressEmail = () => {
    this.props.navigation.navigate(navigationRoutes.stacks.main.updateEmail);
  };

  _onPressGender = () => {
    this.props.navigation.navigate(navigationRoutes.stacks.main.updateGender);
  };

  _onPressDateOfBirth = () => {
    this.props.navigation.navigate(navigationRoutes.stacks.main.updateDateOfBirth);
  };

  _onPressHeight = () => {
    this.props.navigation.navigate(navigationRoutes.stacks.main.updateHeight);
  };

  _onPressUnits = () => {
    this.props.navigation.navigate(navigationRoutes.stacks.main.updateUnits);
  };

  _onPressUpdateTrackingConsent = () => {
    this.props.navigation.navigate(navigationRoutes.stacks.main.updateTrackingConsent);
  };

  // Developer mode only
  _onRequestTestNotification = ({ dataType, notificationType }) => {
    setTimeout(() => {
      if (dataType === 'conversation') {
        this.props.rootStore.conversationsStore.requestTestNotification(notificationType);
      } else if (dataType === 'club') {
        this.props.rootStore.socialStore.requestTestNotification(notificationType);
      } else {
        this.props.rootStore.requestTestNotification({ dataType, notificationType });
      }
    }, 5000);
  };

  // Developer mode only
  _onDoDeveloperModeAction = action => {
    const { rootStore } = this.props;
    if (action === 'finishSsoSignup') {
      rootStore.initOnboarding({ action: 'signup', source: 'sso' });
    }
    if (action === 'finishSignup') {
      rootStore.initOnboarding({ action: 'signup', source: 'nudge' });
    }
    if (action === 'unAckUserHints') {
      rootStore.unAckUserHints();
    }
  };

  render() {
    const { rootStore, navigation, t, route } = this.props;
    const { settingsStore } = rootStore;
    return (
      <NavbarWrapper
        title={t('PROFILE:TITLE')}
        leftButton={
          <HeaderIconButton
            disabled={route.params && route.params.isModalCloseButtonDisabled}
            icon={<BackIcon />}
            onPress={() => {
              navigation.pop();
            }}
          />
        }
        navigation={navigation}>
        <LoadingShadeWrapper
          isLoading={
            settingsStore.updateUserState.isPending ||
            settingsStore.updateUserPhotoState.isPending ||
            rootStore.logoutState.isPending
          }
          style={containerStyles.fillAvailableSpace}>
          <EditProfile
            user={settingsStore.user}
            onPressName={this._onPressName}
            onPressEmail={this._onPressEmail}
            onPressGender={this._onPressGender}
            onPressBirthDate={this._onPressDateOfBirth}
            onPressUnits={this._onPressUnits}
            onPressHeight={this._onPressHeight}
            onPressChangePassword={this._onPressChangePassword}
            onPressSetProfilePhoto={this._onPressSetProfilePhoto}
            onPressDeleteAccount={this._onPressDeleteAccount}
            onRequestTestNotification={this._onRequestTestNotification}
            onPressUpdateTrackingConsent={this._onPressUpdateTrackingConsent}
            onPressLogout={this._onPressLogout}
            onPressCoachSettings={this._onPressCoachSettings}
            onDoDeveloperModeAction={this._onDoDeveloperModeAction}
            onGetDeveloperModeDebugInfo={rootStore.getDebugInfo}
            devToolsEnabled={env.showDevTools || settingsStore.isKonamiCodeEnabled}
            onSendErrorReportingMessage={rootStore.logErrorReportingMessage}
          />
        </LoadingShadeWrapper>
      </NavbarWrapper>
    );
  }
}

EditProfileScreen.propTypes = {
  rootStore: PropTypes.object,
};

EditProfileScreen.displayName = 'EditProfileScreen';

export default withTranslation()(withActionSheet(EditProfileScreen));
