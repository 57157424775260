import { types } from 'mobx-state-tree';
import { keys } from 'lodash';
import Theme from './Theme';

const EnabledDataState = types.model('EnabledDataState', {
  tracking: types.optional(types.boolean, false),
  programs: types.optional(types.boolean, false),
  collections: types.optional(types.boolean, false),
  groups: types.optional(types.boolean, false),
  groupCount: types.optional(types.number, 0),
  coaches: types.optional(types.boolean, false),
  coachCount: types.optional(types.number, 0),
  features: types.optional(types.array(types.frozen()), []),
});

const User = types
  .model('User', {
    id: types.identifierNumber,
    email: types.maybeNull(types.string),
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    gender: types.maybeNull(types.string),
    photoSource: types.frozen(),
    height: types.number,
    units: types.string,
    birthday: types.maybeNull(types.string),
    theme: types.maybeNull(Theme),
    enabledData: types.optional(EnabledDataState, {}),
    pollingConnectedAppsRequired: types.optional(types.boolean, false),
    // named this differently to keep consistent with the more roll-off-your-tounge feature name "client user ID", as it appears in white label config
    // could regret deviating from the database here
    clientUserId: types.maybeNull(types.string),
    // return coach invites
    invites: types.frozen(),
    invitesList: types.frozen(),
  })
  .actions(self => {
    // Quick hack for a quirk of how we composed this object
    // GET /users/me/profile returns the core user profile with polling apps, enabled data, client user ID, etc. (aka "components")
    // PUT /users/me just returns the profile
    // Use this method to update the core profile fields without overwriting the other fields after a PUT
    // This should be safe even if we add/ remove fields from either set, because it iterates through the updated fields and only
    // applies the changes if the fields exist on the updated object
    function overwriteUpdatedFields(updatedFields) {
      const updatedFieldKeys = keys(updatedFields);
      updatedFieldKeys.forEach(key => {
        self[key] = updatedFields[key];
      });
    }
    function subtractPending(inviteId) {
      // decrement invites by one
      self.invites = {
        pending: self.invites.pending - 1,
        resource: self.invites.resource,
      };
      // remove invite id from inviteList array
      self.invitesList = self.invitesList.filter(function(obj) {
        return obj.id !== inviteId;
      });
    }

    return {
      overwriteUpdatedFields,
      subtractPending,
    };
  });

export default User;
