import React from 'react';
import { FlatList, Platform } from 'react-native';
import { useMediaQueryInfo } from '/common/config/styles';

function NudgeFlatList({ contentContainerStyle, ...rest }) {
  const { innerWindowWidth } = useMediaQueryInfo();
  return (
    <FlatList
      contentContainerStyle={[
        { width: innerWindowWidth, alignSelf: 'center' },
        contentContainerStyle,
      ]}
      showsVerticalScrollIndicator={
        Platform.OS !==
        'web' /* sometimes in some situations on some browsers, these shift the content over. So weird! */
      }
      {...rest}
    />
  );
}

export default NudgeFlatList;
