import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react/native';
import { View, ActivityIndicator, Text } from 'react-native';
import { colors, textStyles, sizes } from '../config/styles';
import { BrandContext } from '../config/branding';

/**
 * Wraps a component and covers it in a shade when isLoading is true
 */
const LoadingShadeWrapper = observer(({ isLoading, children, style, message }) => (
  <View style={[{ backgroundColor: colors.bg0 }, style]}>
    {children}
    {isLoading ? (
      <View
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          opacity: 0.5,
          backgroundColor: 'white',
        }}
      />
    ) : null}
    {isLoading ? (
      <View
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {message ? (
          <Text style={[textStyles.medium.dark, { marginBottom: sizes.large }]}>{message}</Text>
        ) : null}
        <BrandContext.Consumer>
          {branding => <ActivityIndicator color={branding.highlightedColor} size="large" />}
        </BrandContext.Consumer>
      </View>
    ) : null}
  </View>
));

LoadingShadeWrapper.propTypes = {
  children: PropTypes.any,
  isLoading: PropTypes.bool,
  style: PropTypes.any,
  // optional message above the loading indicator
  message: PropTypes.string,
};

LoadingShadeWrapper.defaultProps = {
  isLoading: false,
  children: null,
  style: null,
  message: null,
};

export default LoadingShadeWrapper;
