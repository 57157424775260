import React, { Component, useEffect, useRef } from 'react';
import { keys } from 'lodash';
import { View, TouchableWithoutFeedback, Animated } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import GestureRecognizer from 'react-native-swipe-gestures';

export const AlertBarContext = React.createContext({
  // takes params:
  // isVisible: if true, accessory view is visible
  // view: JSX children to show inside accessory view if it is visibile
  // Note that this view has to be explicitly shown and hidden. It does not respond to keyboard events
  // (other than to adjust the offset for showing the view)
  showAlertBar: () => {},
});

export function connectAlertBar(WrappedComponent) {
  const ConnectedAlertBar = props => {
    return (
      <AlertBarContext.Consumer>
        {({ showAlertBar }) => <WrappedComponent {...props} showAlertBar={showAlertBar} />}
      </AlertBarContext.Consumer>
    );
  };

  return ConnectedAlertBar;
}

/**
 * Allows overlaying of an accessory input just above the keyboard.
 * Avoids bugs in iOS InputAccessoryView and enables use of an input accessory view on Android.
 */
export default class AlertBarProvider extends Component {
  // KVP's of suppressors and their functions
  // Used to suppress an alert based on identification keys
  static suppressors = {};

  state = {
    // kind of pointless variable now - we just always leave it just out of view
    isVisible: true,
    view: null,
    onPress: () => {},
    top: new Animated.Value(-250),
  };

  showAlertBar = ({ view, msToShow = 5000, onPress = () => {}, idPayload = null }) => {
    // suppress if the alert has idPayload AND there's an active suppressor
    // suppression is only available where idPayload have been provided
    if (idPayload) {
      let suppressThisAlert = false;
      keys(AlertBarProvider.suppressors).forEach(suppressorComponentId => {
        const suppressor = AlertBarProvider.suppressors[suppressorComponentId];
        // don't show alert if there's a suppressor and it's suppressWhen condition matches based on the identification keys
        if (suppressor && suppressor(idPayload)) {
          console.log('alert bar suppressed with payload:');
          console.log(idPayload);
          suppressThisAlert = true;
        }
      });
      if (suppressThisAlert) {
        return;
      }
    }
    this.setState({ view, onPress });
    // need this to occur after render cycle so there's no chance the user sees a previous notifications view
    setTimeout(() => {
      Animated.spring(this.state.top, { toValue: 0 }).start();
    });
    setTimeout(() => {
      Animated.spring(this.state.top, { toValue: -250 }).start();
      //this.setState({ isVisible: false });
    }, msToShow);
    // TODO: how to handle multiple notifications coming in within window
  };

  _onPress = () => {
    // TODO: debounce me
    Animated.spring(this.state.top, { toValue: -250, useNativeDriver: false }).start();
    this.state.onPress();
  };

  _onSwipeUp = () => {
    Animated.spring(this.state.top, { toValue: -250, useNativeDriver: false }).start();
  };

  render() {
    return (
      <AlertBarContext.Provider value={{ showAlertBar: this.showAlertBar }}>
        <View style={{ flex: 1 }}>
          {this.props.children}
          {this.state.isVisible && (
            <Animated.View
              useNativeDriver
              style={{ position: 'absolute', left: 0, right: 0, top: this.state.top }}>
              <GestureRecognizer onSwipeUp={this._onSwipeUp}>
                <TouchableWithoutFeedback onPress={this._onPress}>
                  <View style={{ width: '100%' }}>{this.state.view}</View>
                </TouchableWithoutFeedback>
                <View
                  style={{
                    height: 20,
                    width: '100%',
                    backgroundColor:
                      'transparent' /* a little extra space for swiping up to dismiss */,
                  }}
                />
              </GestureRecognizer>
            </Animated.View>
          )}
        </View>
      </AlertBarContext.Provider>
    );
  }
}

// generate unique ID for components that can be used when registering with the alert bar provider

let uniqueId = 0;
const getUniqueId = () => uniqueId++;

function useComponentId() {
  const idRef = useRef(null);
  if (idRef.current === null) {
    idRef.current = getUniqueId();
  }
  return idRef.current;
}

/**
 * Place this on a screen to suppress a notifications with the specified key on that screen only.
 * Used to keep a notification from appearing when that screen is focused AND the notification matches a particular key.
 * The use case is not showing a notification for a new message when you're already on the screen that displays the new message
 */
export function AlertBarSuppressor({ suppressWhen }) {
  const isFocused = useIsFocused();
  const componentId = useComponentId();
  useEffect(() => {
    AlertBarProvider.suppressors[componentId] = isFocused ? suppressWhen : undefined;
    return () => (AlertBarProvider.suppressors[componentId] = undefined);
  }, [isFocused]);
  return null;
}
