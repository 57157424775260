import React, { useContext, useCallback } from 'react';
import { View, Text, Image, TouchableOpacity, Platform } from 'react-native';
import PropTypes from 'prop-types';
import { getUniversalSvgComponents } from 'nudge-client-common/common-svg';
import { observer } from 'mobx-react';
import { CompletionGraph } from 'nudge-client-common/graphs';
import { BrandContext } from '../../config/branding';
import { colors, fonts, sizes, textStyles, containerStyles } from '/common/config/styles';
import cardConstants from './internal/cardConstants';
import getFeatureImageSource from './internal/getFeatureImageSource';

/**
 * Card displayed on Coach mobile feed
 * Includes a thumbnail image and coach (internal) title, similar to Coach Web.
 */
const CoachSummaryCard = observer(({ programEvent, onPressCard }) => {
  const branding = useContext(BrandContext);
  const onPress = useCallback(() => {
    onPressCard(programEvent.id);
  }, [onPressCard]);

  const programCard = programEvent.programCard;
  const featureImageSource = getFeatureImageSource(programCard, branding);

  const cardInner = (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginHorizontal: cardConstants.margin,
        borderRadius: sizes.borderRadius,
        shadowColor: '#BABABA',
        backgroundColor: colors.bg0,
        shadowOpacity: 0.5,
        shadowRadius: sizes.borderRadius,
        shadowOffset: { width: 0, height: 2 },
      }}>
      <View
        style={{
          height: 80,
          width: 80,
          borderTopLeftRadius: sizes.borderRadius,
          borderBottomLeftRadius: sizes.borderRadius,
        }}>
        <Image
          source={featureImageSource || require('../../assets/icons/card-placeholder.png')}
          resizeMode={'cover'}
          style={{
            width: 80,
            height: 80,
            backgroundColor: colors.bg0,
            borderTopLeftRadius: sizes.borderRadius,
            borderBottomLeftRadius: sizes.borderRadius,
          }}
        />
        <View
          style={{
            width: 80,
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          {programCard.hasTrackers && (
            <CompletionGraph
              TextComponent={Text}
              ViewComponent={View}
              svg={getUniversalSvgComponents(Platform.OS)}
              diameter={cardConstants.completionGraphDiameter}
              strokeWidth={cardConstants.completionGraphStrokeWidth}
              percentComplete={programEvent.goalCompletionStats.percentComplete}
              numGoalsComplete={programEvent.goalCompletionStats.numComplete}
              totalGoals={programEvent.goalCompletionStats.total}
              completedColor={branding.highlightedColor}
              bgColor={featureImageSource ? colors.bg0 : colors.bg1}
              check={programEvent.goalCompletionStats.percentComplete === 1}
            />
          )}
        </View>
      </View>
      <Text
        style={[
          textStyles.standard.dark,
          {
            fontWeight: fonts.weights.bold,
            fontSize: cardConstants.headlineFontSize,
            alignSelf: 'center',
            marginLeft: sizes.medium,
            flex: 1,
            marginRight: sizes.medium,
          },
        ]}>
        {programCard.name}
      </Text>
    </View>
  );

  return (
    <View style={containerStyles.fillAvailableSpace}>
      <TouchableOpacity style={{ flex: 1 }} activeOpacity={1} onPress={onPress}>
        {cardInner}
      </TouchableOpacity>
    </View>
  );
});

CoachSummaryCard.propTypes = {
  programEvent: PropTypes.object.isRequired,
};

export default CoachSummaryCard;
