import { types, flow, getEnv, applySnapshot, getRoot } from 'mobx-state-tree';
import { sortBy, uniqBy, remove, find, keys } from 'lodash';
import { LoadingState } from 'nudge-client-common/stores';
import { getLogCatalog } from 'nudge-client-common/data/trackers';
import { Log } from 'nudge-client-common/stores/log-store/Tracker';
import Comment from './Comment';
import Leaderboard from './Leaderboard';

const TopicFeed = types
  .model('SocialFeed', {
    clubId: types.identifierNumber,
    // we load the leaderboard off the topic feed because we want them at the same time
    // even though you can have a topic feed without a leaderboard and such.
    hasLeaderboard: types.optional(types.boolean, false),
    hasArchivedLeaderboard: types.optional(types.boolean, false),
    // other shared props from group so we can display a topic independently of the groups list
    isLeaderboardJoined: types.optional(types.boolean, false),
    forumsUploadsEnabled: types.optional(types.boolean, false),
    title: types.maybeNull(types.string),
    sortMode: types.optional(types.string, 'created_at'), // sort mode copied from social store root
    comments: types.optional(types.array(Comment), []),
    leaderboard: types.maybeNull(Leaderboard),
    archivedleaderboard: types.maybeNull(Leaderboard),
    loadCommentsState: types.optional(LoadingState, {}),
    loadAdditionalCommentsState: types.optional(LoadingState, {}),
    loadCommentState: types.optional(LoadingState, {}),
    sendCommentState: types.optional(LoadingState, {}),
    updateCommentState: types.optional(LoadingState, {}),
    deleteCommentState: types.optional(LoadingState, {}),
    moderateCommentState: types.optional(LoadingState, {}),
    // tracks next page to query when scrolling through topics. If null, there is no next page (at the end of history)
    commentsNextPage: types.maybeNull(types.number),
    // TODO: these are specific to client app, don't need them here, may implement as separate model and compose
    // updateMembershipState: types.optional(LoadingState, {}),
    pendingComment: types.frozen(),
    // leaderboard client functionality
    updateMembershipState: types.optional(LoadingState, {}),
    // additional logs to populate the leaderboard with the user's latest totals
    // only used for steps and activity
    // activity tracker ID = 405 (labgreen) / 8026 (prod)
    // steps tracker ID = 407 (labgreen) / 8028 (prod)
    upToTheMinuteLeaderboardLogs: types.optional(types.array(Log), []),
  })
  .views(self => ({
    get commentsSorted() {
      // Comment sorting is determined server-side (via the sort_mode param) - just reverse our comments array
      const sortedComments = self.comments.slice().reverse();
      // add non-reply pending comment
      if (self.pendingComment && !self.pendingComment.parentCommentId) {
        sortedComments.push(self.pendingComment);
      }
      return sortedComments;
    },
    repliesSortedFor(parentCommentId) {
      /* fuzzy match due to needing string for pending ID, should make a wrapper to do these matches for consistency/ reliability */
      const comment = self.comments.find(c => c.id == parentCommentId);
      if (comment) {
        const sortedReplies = sortBy(comment.replies.slice(), c => c.createdAt);
        if (self.pendingComment && self.pendingComment.parentCommentId == parentCommentId) {
          sortedReplies.push(self.pendingComment);
        }
        return sortedReplies;
      }
      return [];
    },
    get isAtEndOfCommentHistory() {
      return self.commentsNextPage === null;
    },
    commentForId(commentId) {
      return find(
        self.comments,
        t =>
          t.id ==
          commentId /* fuzzy match due to needing string for pending ID, should make a wrapper to do these matches for consistency/ reliability */
      );
    },
    get upToTheMinuteTotalsForUser() {
      if (self.leaderboard && self.upToTheMinuteLeaderboardLogs.length) {
        if (self.leaderboard.usesUpToMinuteStats) {
          const catalog = getLogCatalog({
            logs: self.upToTheMinuteLeaderboardLogs,
            aggregateType: 'total',
            // primary field, deduplication rules
            ...self.leaderboard.upToTheMinuteTrackerDetails,
          });
          let total = 0;
          keys(catalog.dates).forEach(key => {
            const catalogForDate = catalog.dates[key];
            // tail bug in API causes it to return dates out of range sometime
            if (
              key >= self.leaderboard.startsAt.dateWithoutTime &&
              key <= self.leaderboard.endsAt.dateWithoutTime
            ) {
              total += catalogForDate.total;
            }
          });
          return total;
        }
      }
      return self.leaderboard && self.leaderboard.position ? self.leaderboard.position.total : 0;
    },
  }))
  .actions(self => {
    const refreshComments = updatedComments => {
      // TODO: possible conflict with pending comment?
      self.comments.replace(uniqBy(self.comments.concat(updatedComments), m => m.id));
    };
    /**
     * A groups isn't much of a group if we don't load initial comments and
     * the leaderboard (if it exists)
     * If resetFeed option is overridden to false, instead of replacing all comments with the most recent, any other loaded comments will be
     * left in place, with the most recent 20 refreshed. This will keep any weird scrolling stuff from happening if someone has
     * worked their way back in the feed history.
     */
    const loadComments = flow(function* loadComments(options) {
      const myOptions = { resetFeed: true, ...options };
      self.loadCommentsState.setPending();
      try {
        // load initial comments
        const mostRecentComments = yield getEnv(self).clubsRepository.getComments({
          clubId: self.clubId,
          sortMode: self.sortMode,
        });

        // load leaderboard
        if (self.hasLeaderboard) {
          // It's possible for this to fail because the leaderboard was removed.
          // Pretty unavoidable timing issue, so let's not error
          console.log('club id:', self.clubId);
          try {
            self.leaderboard = yield getEnv(self).clubsRepository.getLeaderboard({
              clubId: self.clubId,
              limit: getEnv(self).getBranding().leaderboardLength,
            });
          } catch (error) {
            self.hasLeaderboard = false;
            // disable leaderboard until we can refresh group and see that it exists
          }
        }
        if (self.hasArchivedLeaderboard) {
          try {
            self.archivedleaderboard = yield getEnv(self).clubsRepository.getLeaderboard({
              clubId: self.clubId,
              limit: getEnv(self).getBranding().leaderboardLength,
              archived: true,
            });
          } catch (error) {
            self.hasArchivedLeaderboard = false;
            console.log(error, 'hasArchivedLeaderboard error');
          }
        }
        // move populating of topics feed to here so leaderboard and topics populate at the same time
        // we respect cancellation so we don't accidentally unload any topics that were loaded since this request started
        // we still want to completely replace topics if not cancelled so we can hide anything that was since hidden/ deleted
        if (!self.loadCommentsState.isCancelRequested) {
          // kind of would rather refresh, but that prevents deletes and moderation from coming through right away
          //refreshTopics(topics);
          if (myOptions.resetFeed || self.comments.length === 0) {
            // if true, reset page and replace all comments (this will work in any deletes)
            if (mostRecentComments.length < 20) {
              self.commentsNextPage = null;
            } else {
              self.commentsNextPage = 2;
            }
            self.comments.replace(mostRecentComments);
          } else {
            // otherwise, merge in latest messages, keeping any other loaded message intact
            mostRecentComments.forEach(comment => {
              const existingComment = self.comments.find(t => t.id === comment.id);
              if (!existingComment) {
                self.comments.push(comment);
              } else {
                applySnapshot(existingComment, comment); // overwrites comments on topic
              }
            });
          }
        }
        self.loadCommentsState.setDone();
        if (getRoot(self).perspective === 'client') {
          self.fillInUpToTheMinuteLeaderboardTotals();
        }
      } catch (error) {
        self.loadCommentsState.setFailed(error);
      }
    });

    const loadNewComments = flow(function* loadNewComments() {
      yield self.loadComments({ resetFeed: true });
    });

    const loadAdditionalComments = flow(function* loadAdditionalComments() {
      if (!self.loadAdditionalCommentsState.isPending && self.commentsNextPage) {
        // request cancel so these messages don't get deleted by a pending re-init
        self.loadCommentsState.requestCancel();
        self.loadAdditionalCommentsState.setPending();
        try {
          const additionalComments = yield getEnv(self).clubsRepository.getComments({
            clubId: self.clubId,
            page: self.commentsNextPage,
            sortMode: self.sortMode,
          });
          if (additionalComments.length < 20) {
            self.commentsNextPage = null;
          } else {
            self.commentsNextPage = self.commentsNextPage + 1;
          }
          refreshComments(additionalComments);
          self.loadAdditionalCommentsState.setDone();
        } catch (error) {
          self.loadAdditionalCommentsState.setFailed(error);
        }
      }
    });

    const loadComment = flow(function* loadComment(commentId) {
      // request cancel in case there's a pending load about to delete everything,
      // because that could cause this topic to be unloaded
      self.loadCommentsState.requestCancel();
      self.loadCommentState.setPending();
      try {
        const comment = yield getEnv(self).clubsRepository.getComment({
          clubId: self.clubId,
          commentId,
        });
        if (comment) {
          refreshComments([comment]);
        }
        self.loadCommentState.setDone();
      } catch (error) {
        self.loadCommentState.setFailed(error);
      }
    });

    const sendComment = flow(function* sendComment({
      text,
      image,
      parentCommentId,
      notify = false,
    }) {
      const user = getEnv(self).getCurrentUser();
      self.pendingComment = {
        id: 'new', // TODO: make this a UUID
        userId: user.id,
        comment: text,
        createdAt: new Date(),
        author: user,
        attachment: image,
        isPending: true,
        parentCommentId,
        repliesSorted: [], // prevent errors accessing this prop. TODO: this is messy
      };
      self.sendCommentState.setPending();
      try {
        const result = yield getEnv(self).clubsRepository.postComment({
          clubId: self.clubId,
          comment: text,
          attachment: image,
          parentCommentId,
          notify,
        });
        if (parentCommentId) {
          const parentComment = self.comments.find(c => c.id === parentCommentId);
          if (parentComment) {
            parentComment.replies.push(result);
          }
        } else {
          self.comments.unshift(result);
        }
        self.sendCommentState.setDone();
      } catch (error) {
        self.sendCommentState.setFailed(error);
      } finally {
        self.pendingComment = null;
      }
    });

    const updateComment = flow(function* updateComment({
      text,
      image,
      parentCommentId,
      messageId, // conforming to SendableStore
    }) {
      const commentId = messageId;
      self.updateCommentState.setPending();
      try {
        yield getEnv(self).clubsRepository.updateComment({
          clubId: self.clubId,
          comment: text,
          attachment: image,
          parentCommentId,
          commentId,
        });
        if (parentCommentId) {
          const parentComment = self.comments.find(c => c.id === parentCommentId);
          if (parentComment) {
            const updatedMessage = parentComment.replies.find(m => m.id === commentId);
            if (updatedMessage) {
              updatedMessage.comment = text;
              updatedMessage.attachment = image;
            }
          }
        } else {
          const updatedMessage = self.comments.find(m => m.id === commentId);
          if (updatedMessage) {
            updatedMessage.comment = text;
            updatedMessage.attachment = image;
          }
        }
        self.updateCommentState.setDone();
      } catch (error) {
        console.log(error);
        self.updateCommentState.setFailed(error);
      }
    });

    const deleteComment = flow(function* deleteComment(
      { messageId, parentCommentId } // conforming to SendableStore
    ) {
      const commentId = messageId;
      self.deleteCommentState.setPending();
      try {
        const result = yield getEnv(self).clubsRepository.deleteComment({
          clubId: self.clubId,
          commentId,
        });
        if (parentCommentId) {
          const parentComment = self.comments.find(c => c.id === parentCommentId);
          if (parentComment) {
            if (result.deletionType === 'deleted') {
              const messagesCopy = parentComment.replies.slice();
              remove(messagesCopy, m => m.id === messageId);
              parentComment.replies = messagesCopy;
            } else {
              const message = find(parentComment.replies, m => m.id === messageId);
              if (message) {
                message.redacted = true;
              }
            }
          }
        } else {
          if (result.deletionType === 'deleted') {
            const messagesCopy = self.comments.slice();
            remove(messagesCopy, m => m.id === messageId);
            self.comments = messagesCopy;
          } else {
            const message = find(self.comments, m => m.id === messageId);
            if (message) {
              message.redacted = true;
            }
          }
        }
        self.deleteCommentState.setDone();
      } catch (error) {
        console.log(error);
        self.deleteCommentState.setFailed(error);
      }
    });

    // *** leaderboard join/ leave - client-only ***

    const joinLeaderboard = flow(function* joinLeaderboard() {
      self.updateMembershipState.setPending();
      try {
        yield getEnv(self).clubsRepository.joinLeaderboard(self.clubId);
        self.isLeaderboardJoined = true;
        self.updateMembershipState.setDone();
      } catch (error) {
        self.updateMembershipState.setFailed(error);
      }
    });

    const leaveLeaderboard = flow(function* leaveLeaderboard() {
      self.updateMembershipState.setPending();
      try {
        yield getEnv(self).clubsRepository.leaveLeaderboard(self.clubId);
        self.isLeaderboardJoined = false;
        self.updateMembershipState.setDone();
      } catch (error) {
        self.updateMembershipState.setFailed(error);
      }
    });

    const changeNotificationLevel = flow(function* changeNotificationLevel(notificationLevelId) {
      self.updateMembershipState.setPending();
      try {
        yield getEnv(self).clubsRepository.changeNotificationLevel(
          self.clubId,
          notificationLevelId
        );
        self.updateMembershipState.setDone();
      } catch (error) {
        self.updateMembershipState.setFailed(error);
      }
    });

    const fillInUpToTheMinuteLeaderboardTotals = flow(
      function* fillInUpToTheMinuteLeaderboardTotals() {
        // fill in up to minute personal stats
        if (self.leaderboard && self.leaderboard.usesUpToMinuteStats) {
          try {
            // this is all a hack and is pretty gross
            self.upToTheMinuteLeaderboardLogs = yield getEnv(
              self
            ).logRepository.getDailyLogsForTracker({
              clientId: getRoot(self).logStore.id, // especially this
              trackerType: self.leaderboard.upToTheMinuteTrackerDetails.type,
              trackerId: self.leaderboard.upToTheMinuteTrackerDetails.id,
              startDate: self.leaderboard.startsAt.dateWithoutTime,
              endDate: self.leaderboard.endsAt.dateWithoutTime,
              tail: 0,
              head: 0,
            });
            console.log(self.upToTheMinuteLeaderboardLogs);
          } catch (error) {
            // do nothing, just don't update steps
            console.log(error);
          }
        }
      }
    );

    // *** moderation - client-only ***

    const filterOutModeratedComments = ({ messageId, userId }) => {
      self.comments.forEach(comment => {
        if (comment.id === messageId) {
          // we'd basically have to remove the entire topic, so we just need to notify
          // consumers so they can remove the content from the screen
          // this should trigger a refresh via the ModerationWorkflow
          comment.wasFiltered = true;
        }
        if (comment.author.id === userId) {
          comment.wasFiltered = true;
        }
        comment.replies.forEach(reply => {
          if (reply.id === messageId) {
            reply.wasFiltered = true;
          }
          if (reply.author.id === userId) {
            reply.wasFiltered = true;
          }
        });
        // remove filtered replies entirely
        comment.replies.replace(comment.replies.filter(r => !r.wasFiltered));
      });
    };

    const filterComment = flow(function* filterComment({ messageId }) {
      self.moderateCommentState.setPending();
      try {
        yield getEnv(self).clubsRepository.filterComment(messageId);
        filterOutModeratedComments({ messageId });
        self.moderateCommentState.setDone();
      } catch (error) {
        self.moderateCommentState.setFailed(error);
      }
    });

    const filterCommentsByUser = flow(function* filterCommentsByUser({ userId }) {
      self.moderateCommentState.setPending();
      try {
        yield getEnv(self).clubsRepository.filterCommentsByUser(userId);
        filterOutModeratedComments({ userId });
        self.moderateCommentState.setDone();
      } catch (error) {
        self.moderateCommentState.setFailed(error);
      }
    });

    const reportAbuse = flow(function* reportAbuse({ messageId, reason }) {
      self.moderateCommentState.setPending();
      try {
        yield getEnv(self).clubsRepository.reportAbuse({ commentId: messageId, reason });
        self.moderateCommentState.setDone();
      } catch (error) {
        self.moderateCommentState.setFailed(error);
      }
    });

    return {
      loadComments,
      loadNewComments,
      loadInitial: loadComments, // backwards compat with old stores
      loadComment,
      loadAdditionalComments,
      sendComment,
      updateComment,
      deleteComment,
      // moderation
      filterComment,
      filterCommentsByUser,
      reportAbuse,
      // leaderboard
      joinLeaderboard,
      leaveLeaderboard,
      changeNotificationLevel,
      fillInUpToTheMinuteLeaderboardTotals,
    };
  });

export default TopicFeed;
