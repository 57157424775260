import React, { useMemo, useEffect, useState, forwardRef, useRef } from 'react';
import { keys } from 'lodash';
import { Image as ReactNativeImage } from 'react-native';

const imageCache = {};

const normalizeSource = source => {
  let newSource;
  if (source) {
    if (typeof source === 'string' || source instanceof String) {
      newSource = { uri: source };
    } else if (source.uri) {
      newSource = { ...source };
    } else if (source.url) {
      newSource = { ...source };
      newSource.uri = source.url;
    } else {
      return source; // local image
    }
    if (Image.httpHeaders) {
      newSource.headers = Image.httpHeaders;
    }
  }
  return newSource;
};

async function fetchWithAuthentication(url, additionalHeaders) {
  const headers = new Headers();
  keys(additionalHeaders).forEach(headerKey => {
    headers.set(headerKey, additionalHeaders[headerKey]);
  });
  try {
    return await fetch(url, { headers });
  } catch (error) {
    console.log(error);
    return null;
  }
}

/**
 * Passthrough for image component that can normalize a variety of different source variables into
 * a compatible source, including:
 * - just an image url
 * - object with url prop (usually from api)
 * - object with uri prop (usually local image)
 *
 * Also adds header info with custom user tokens so protected images can be accesed.
 */
const Image = forwardRef((props, ref) => {
  const { source, ...rest } = props;

  const persistentSource = useRef(normalizeSource(source));
  const updatedSource = useMemo(() => normalizeSource(source), [source]);

  const [fetchedSource, setFetchedSource] = useState(
    persistentSource.current ? imageCache[persistentSource.current.uri] : null
  );

  useEffect(() => {
    async function getImageAsync() {
      if (!updatedSource) {
        return;
      }
      // only update image if the uri changes
      // the object might change on reload, but that doesn't mean it's a different image
      // this can prevent some blinking, and apparently a lot of blinking on the web side.
      if (
        updatedSource === null ||
        updatedSource.uri !== persistentSource.current.uri ||
        !fetchedSource
      ) {
        persistentSource.current = updatedSource;
        // TODO: what if this fails? Definitely needs hardening
        const response = await fetchWithAuthentication(
          persistentSource.current.uri,
          Image.httpHeaders
        );
        if (!response) {
          return;
        }
        const blob = await response.blob();
        if (blob) {
          const objectUrl = URL.createObjectURL(blob);
          setFetchedSource(objectUrl);
          imageCache[persistentSource.current.uri] = objectUrl;
        }
      }
    }
    getImageAsync();
  }, [updatedSource]);

  return <ReactNativeImage ref={ref} {...rest} source={fetchedSource} />;
});

// forward static function over (used by FlexibleThumbnail)
Image.getSize = ReactNativeImage.getSize;

Image.httpHeaders = {};

export default Image;
