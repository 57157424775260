const images = {
  // banners
  outdoorSportsThumb: require('./outdoor_sports_thumb.jpg'),
  cookingThumb: require('./cooking_thumb.jpg'),
  strengthTrainingThumb: require('./strength_training_thumb.jpg'),
  cyclingThumb: require('./cycling_thumb.jpg'),
  fitnessClassThumb: require('./fitness_class_thumb.jpg'),
  nudgeThumb: require('./nudge_thumb.jpg'),
  companyThumb: require('./groups-default-thumb.jpg'),
  stressReductionThumb: require('./stress_reduction_thumb.jpg'),
  healthAppsThumb: require('./health_apps_thumb.jpg'),
  weightLossThumb: require('./weight_loss_thumb.jpg'),
  runningThumb: require('./running_thumb.jpg'),
  // thumbs
  outdoorSportsBanner: require('./outdoor_sports.jpg'),
  cookingBanner: require('./cooking.jpg'),
  strengthTrainingBanner: require('./strength_training.jpg'),
  cyclingBanner: require('./cycling.jpg'),
  fitnessClassBanner: require('./fitness_class.jpg'),
  nudgeBanner: require('./nudge.jpg'),
  companyBanner: require('./company.jpg'),
  stressReductionBanner: require('./stress_reduction.jpg'),
  healthAppsBanner: require('./health_apps.jpg'),
  weightLossBanner: require('./weight_loss.jpg'),
  runningBanner: require('./running.jpg'),
};

const urlToThumbMap = {
  company: require('./company_thumb.jpg'),
  cooking: require('./cooking_thumb.jpg'),
  cycling: require('./cycling_thumb.jpg'),
  fitness_class: require('./fitness_class_thumb.jpg'),
  gardening: require('./gardening_thumb.jpg'),
  health_apps: require('./health_apps_thumb.jpg'),
  lewis_ginter: require('./lewis_ginter_thumb.jpg'),
  nudge: require('./nudge_thumb.jpg'),
  outdoor_sports: require('./outdoor_sports_thumb.jpg'),
  running: require('./running_thumb.jpg'),
  strength_training: require('./strength_training_thumb.jpg'),
  stress_reduction: require('./stress_reduction_thumb.jpg'),
  team_sports: require('./team_sports_thumb.jpg'),
  walking: require('./walking_thumb.jpg'),
  weight_loss: require('./weight_loss_thumb.jpg'),
};

const urlToThumb = url => {
  const relevantPart = url.replace('images/clubs/', '').replace('.jpg', '');
  return urlToThumbMap[relevantPart];
};

export default images;
export { urlToThumb };
