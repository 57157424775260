export default {
  GENERAL: {
    SUCCESS: 'Éxito',
    SAVE: 'Guardar',
    EDIT: 'Editar',
    CANCEL: 'Cancelar',
    OK: 'OK',
    REFRESH: 'Actualizar',
    TRY_AGAIN: 'Intentar de nuevo',
    LOAD_MORE: 'Cargar más',
    ACCEPT: 'Aceptar',
    DECLINE: 'Rechazar',
    PHOTO_PICKER: {
      CHOOSE_FROM_LIBRARY: 'Elegir de la biblioteca',
      TAKE_PICTURE_WITH_CAMERA: 'Tomar foto con la cámara',
    },
    ERRORS: {
      APP_FIRST_LOAD_TITLE: 'Lo sentimos, estamos teniendo problemas para iniciar...',
      FIRST_LOAD_TITLE: 'Lo sentimos, estamos teniendo algunos problemas...',
      FIRST_LOAD_MESSAGE: 'No parece que podamos conectar en este momento.',
      PLEASE_REFRESH_ACTION: 'Actualiza para intentarlo de nuevo.',
      LOAD_EARLIER_MESSAGES_FAILED:
        'Error al cargar mensajes anteriores. Por favor, verifica tu conexión e intenta de nuevo',
      NETWORK_ERROR_MESSAGE: 'Por favor, verifica tu conexión de red',
      SERVER_UNAVAILABLE_ERROR_MESSAGE: `Disculpas por las molestias, estamos realizando mantenimiento en este momento. ¡Volveremos pronto!`,
      ALERT_UNEXPECTED_ERROR_MESSAGE: 'Por favor, inténtalo de nuevo más tarde.',
      ALERT_UNEXPECTED_ERROR_TITLE: 'Lo sentimos, estamos teniendo problemas con eso...',
      ALERT_ACCESS_REMOVED_ERROR_MESSAGE:
        'Ya no tienes acceso a este recurso. Es posible que te hayan eliminado de un grupo.',
      CANNOT_OPEN_FILE: 'Error al abrir el archivo',
    },
    DATES: {
      TODAY: 'Hoy',
      YESTERDAY: 'Ayer',
    },
  },
  SIGN_UP: {
    INVITE_ID_DISPLAY_NAME: 'ID de Invitación',
    INVITE_ID_DISPLAY_SHORTNAME: 'ID',
    INVITE_ID_DISPLAY_ARTICLE: 'un',
    CLIPBOARD_DISCLAIMER:
      'Esta aplicación recopila datos del portapapeles para habilitar el pegado de tu {{inviteIdName}}',
    PLEASE_ENTER_INVITE_ID: 'Por favor, ingresa tu {{inviteIdName}}',
    PLEASE_ENTER_CLIENT_USER_ID: 'Por favor, ingresa tu {{clientUserIdName}} si tienes uno.',
    ENTER_YOUR_EMAIL_ADDRESS: 'Ingresa tu dirección de correo electrónico',
    ID_LIKE_TO: 'Me gustaría',
    GREAT: '¡Genial!',
    SECURE_YOUR_ACCOUNT_WITH_A_PASSWORD:
      'Hemos confirmado tu cuenta. Ahora, asegúrala con tu contraseña.',
    COMPLETE_YOUR_PROFILE: 'Completa tu perfil',
    THIS_WILL_HELP_US_VERIFY_YOUR_RESULTS: 'Esto nos ayudará a verificar tus resultados',
    CREATE_AN_ACCOUNT: 'Crear una cuenta',
    CREATE_YOUR_ACCOUNT: 'Crea tu cuenta.',
    ENTER_A_NEW_EMAIL_ADDRESS: 'Ingresa una nueva dirección de correo electrónico.',
    TERMS_OF_SERVICE: {
      DESCRIPTION: 'Al registrarte en {{appName}}, debes tener al menos 13 años y aceptar los',
      LINK_NAME: 'Términos del Servicio',
    },
    CONFIRMATION_CODE: 'Código de confirmación',
    WE_JUST_SENT_AN_EMAIL_TO: 'Acabamos de enviar un correo electrónico a',
    WE_PREVIOUSLY_SENT_AN_EMAIL_TO: 'Anteriormente enviamos un correo electrónico a',
    CONFIRM_YOUR_ACCOUNT: 'Verifica tu correo electrónico.',
    FINISH_SETTING_UP_YOUR_ACCOUNT: 'Termina de configurar tu cuenta',
    USE_THIS_LINK_TO_CONFIRM_YOUR_EMAIL_ADDRESS:
      'Usa este enlace para verificar tu dirección de correo electrónico.',
    ERRORS: {
      INVALID_INVITE_ID: `Lo sentimos, no podemos encontrar este {{inviteIdName}}`,
      INVALID_INVITE_ID_MEMBERSHIP_LIMIT_EXCEEDED: `Lo sentimos, este entrenador no puede agregar más clientes.`,
      NETWORK_ERROR: `¡No parece que podamos conectarnos! Por favor, verifica tu conexión.`,
      UNKNOWN_ERROR: `Lo sentimos, estamos teniendo problemas para conectarnos en este momento.`,
      EMAIL_ALREADY_IN_USE: `Este correo electrónico ya está en uso`,
      EMAIL_BLANK: `Por favor, ingresa una dirección de correo electrónico`,
      EMAIL_INVALID: `Dirección de correo electrónico no válida`,
      NAME_BLANK: `Por favor, ingresa un nombre`,
      FIRST_NAME_BLANK: `Por favor, ingresa un nombre de pila`,
      LAST_NAME_BLANK: `Por favor, ingresa un apellido`,
      PASSWORD_NOT_MINIMUM_LENGTH: 'La contraseña debe tener 6 caracteres o más',
      PASSWORD_INVALID: 'Contraseña no válida (máximo 30 caracteres)',
      BIRTH_DATE_BLANK: 'Debes proporcionar una fecha de nacimiento',
      INVALID_EMAIL_CONFIRMATION_CODE:
        'Lo siento, ese no es un código de confirmación de correo electrónico válido.',
    },
    BUTTONS: {
      CONTINUE: 'Continuar',
      NO_CONTINUE_WITH_SIGN_UP: 'No, continuar con el registro.',
      CONINUE_WITH_SELF_GUIDED_PROGRAM: 'Continuar con un programa autoguiado',
      SUBMIT: 'Enviar',
      ACCEPT: 'Aceptar',
      DECLINE: 'Rechazar',
    },
    REQUEST_EMAIL_CODE_DIALOG: {
      TITLE: '¡Listo!',
      MESSAGE:
        'Si tienes problemas para encontrar tu correo electrónico de confirmación, verifica tu carpeta de correo no deseado/spam.',
    },
  },
  ONBOARDING: {
    ERRORS: {
      PUSH_NOTIFICATION_REGISTRATION: 'Error al registrar las notificaciones push.',
      VIEW_TUTORIAL: 'Lo siento, no podemos abrir el tutorial en este momento',
    },
    CONTINUE: 'Continuar',
  },
  LOGIN: {
    BUTTONS: {
      IM_NEW: 'Soy Nuevo',
      SIGN_IN: 'Iniciar sesión',
      NEXT: 'Siguiente',
      FORGOT_PASSWORD: '¿Olvidaste tu contraseña?',
      REQUEST_PASSWORD_RESET: 'Restablecer contraseña',
      LOGIN_AGAIN: 'Volver a iniciar sesión',
    },
    PASSWORD_RESET_CONFIRMATION:
      'Se ha enviado un enlace para restablecer tu contraseña a tu correo electrónico.',
    PASSWORD_RESET_TROUBLESHOOTING_TIPS_TITLE: 'Consejos de solución de problemas',
    PASSWORD_RESET_TROUBLESHOOTING_TIPS_DETAIL1: `• A veces, los correos electrónicos tardan varios minutos en aparecer`,
    PASSWORD_RESET_TROUBLESHOOTING_TIPS_DETAIL2: `• Verifica tu carpeta de spam después de unos minutos`,
    PASSWORD_RESET_TROUBLESHOOTING_TIPS_DETAIL3: `• Asegúrate de estar revisando la bandeja de entrada correcta (hey, sucede 🤷)`,
    HEADERS: {
      CREATE_AN_ACCOUNT: 'Crear una cuenta',
      SIGN_IN: 'Iniciar sesión',
    },
    FIELDS: {
      NAME: 'Nombre',
      EMAIL: 'Correo electrónico',
      PASSWORD: 'Contraseña',
    },
    ERRORS: {
      GENERIC_TITLE: `Lo sentimos, estamos teniendo problemas para iniciar sesión`,
      INCORRECT_USERNAME_PASSWORD:
        'El correo electrónico o la contraseña no coinciden con ningún usuario.',
      SSO: {
        RESPONSE_MALFORMED:
          'La respuesta del proveedor de inicio de sesión único está malformada. Puede haber un problema con el servidor de inicio de sesión. Por favor, ponte en contacto con el soporte del producto.',
        NO_AUTHORIZATION_CODE:
          'La respuesta del proveedor de inicio de sesión único no incluyó un código de autorización. Si hubo un error durante el proceso de inicio de sesión, inténtalo de nuevo o ponte en contacto con el soporte del producto.',
        UNRECOGNIZED_URL:
          'La respuesta del proveedor de inicio de sesión único incluyó una URL que no reconocemos. Por favor, ponte en contacto con el soporte del producto.',
        UNEXPECTED:
          'Ocurrió un error inesperado durante el inicio de sesión. Por favor, inténtalo de nuevo más tarde o ponte en contacto con el soporte del producto.',
        VALIDIC_USER_NOT_PROVISIONED: 'Parece que no estás registrado en {{appName}}.',
      },
    },
  },
  BUTTONS: {
    CANCEL: 'Cancelar',
  },
  MESSAGING: {
    ERRORS: {
      SEND_MESSAGE: 'Error al enviar el mensaje.',
      DELETE_MESSAGE: 'Error al eliminar el mensaje.',
      LOADING_PREVIOUS_MESSAGES: 'Error al cargar mensajes anteriores.',
    },
    BUTTONS: {
      COPY_TEXT: 'Copiar texto',
      DELETE_MESSAGE: 'Eliminar mensaje',
    },
    NO_MESSAGES_MESSAGE: {
      LINE1: '¡Está tranquilo aquí!',
      LINE2: '¿Por qué no iniciar una conversación?',
    },
    LOAD_EARLIER_MESSAGES: 'Cargar mensajes anteriores',
  },
  PROFILE: {
    TITLE: 'Perfil',
    FIELDS: {
      FULL_NAME: 'Nombre completo',
      NAME: 'Nombre',
      FIRST_NAME: 'Nombre de pila',
      LAST_NAME: 'Apellido',
      EMAIL: 'Correo electrónico',
      GENDER: 'Género',
      DATE_OF_BIRTH: 'Fecha de nacimiento',
      UNITS: 'Unidades',
      HEIGHT: 'Altura',
      PHOTO: 'Foto',
    },
    BUTTONS: {
      CHANGE_PASSWORD: 'Cambiar contraseña',
      SET_PROFILE_PHOTO: 'Establecer foto de perfil',
      ENTER_A_CODE: 'Ingresar un ID de invitación',
      HELP: 'Ayuda',
      MALE: 'Masculino',
      FEMALE: 'Femenino',
      NOT_SPECIFIED_OTHER: 'No especificado',
      IMPERIAL: 'Imperial',
      METRIC: 'Métrico',
      LOG_OUT: 'Cerrar sesión',
      SYNCED_APPS_NOT_SYNCING: 'Conectar aplicaciones y dispositivos portátiles',
      SYNCED_APPS_SYNCING: 'Aplicaciones conectadas',
      EDIT: 'Editar',
      SHARE: 'Compartir',
      NO_BIRTHDATE_SET: 'Seleccionar...',
      DELETE_ACCOUNT: 'Eliminar cuenta',
      COACH_SETTINGS: 'Configuración del entrenador',
      SUBMIT: 'Enviar',
    },
    LABELS: {
      COMPLETE_YOUR_PROFILE: {
        TITLE: 'COMPLETA TU PERFIL',
        DESCRIPTION:
          'Intenta registrar algunas de tus actividades favoritas para un perfil que refleje tu estilo de vida activo.',
      },
      ACTIVITIES: {
        TITLE: 'ACTIVIDADES',
        LATEST: 'ÚLTIMAS',
        FAVORITE: 'FAVORITAS',
      },
    },
    ERRORS: {
      UPDATE_FAILED: 'Error al actualizar',
      EMAIL_UPDATE_FAILED_MESSAGE: 'Asegúrate de usar un correo electrónico válido',
      CANNOT_BE_BLANK: 'No puede estar en blanco',
      EMAIL_ALREADY_USED: 'Correo electrónico ya en uso',
      INVALID_EMAIL: 'Correo electrónico no válido',
      TRACKING_CONSENT_UPDATE_FAILED_MESSAGE:
        'No se pudo actualizar tu preferencia de consentimiento para el seguimiento. Por favor, inténtalo de nuevo',
    },
    EDIT: {
      UNSAVED_CHANGES: 'Cambios no guardados',
      WHAT_WOULD_YOU_LIKE_TO_DO: '¿Qué te gustaría hacer?',
      SAVE_CHANGES: 'Guardar cambios',
      DISCARD_CHANGES: 'Descartar cambios',
    },
  },
  SYNCED_APPS: {
    TITLE: 'Aplicaciones sincronizadas',
    DESCRIPTION: 'Toca y sincroniza tu(s) aplicación(es) favorita(s)',
    CONFIRMATION:
      'Ahora, {{appName}} está sincronizando con tu cuenta de {{syncedAppName}}. Ten en cuenta que puede tardar hasta 24 horas en que los datos sincronizados aparezcan en la pantalla de registro de {{appName}}.',
    STOP_APP_CONFIRM_TITLE: '¿Detener sincronización de la aplicación?',
    STOP_APP_CONFIRM_MESSAGE:
      '¿Estás seguro de que deseas dejar de sincronizar datos de {{syncedAppName}} a {{appName}}?',
    NO_KEEP_SYNCING: 'No, seguir sincronizando',
    YES_STOP_SYNCING: 'Sí, dejar de sincronizar con {{syncedAppName}}',
    CHECK_APPLE_HEALTH_SOURCES:
      'Consulta la pestaña Fuentes en la aplicación Salud para habilitar más tipos de datos para la sincronización.',
  },
  PROGRAMS: {
    EMPTY_FEED: {
      TITLE: 'Los próximos pasos de tu\nprograma se cargarán aquí.',
      MESSAGE: 'Vuelve pronto',
    },
    EMPTY_TAG: {
      TITLE: 'No hay tarjetas aquí en este momento.',
      MESSAGE: 'Es posible que esta etiqueta se haya eliminado.',
    },
    ERRORS: {
      REFRESH_FAILED: 'Lo siento, no se puede actualizar en este momento...',
    },
    TRACKER_PLACEHOLDERS: {
      NUMBER: 'Ingresa un número...',
      DURATION: 'Registra tu tiempo...',
      PERCENT: 'Ingresa tu %...',
      BLOODPRESSURE: 'Registra tu presión arterial...',
      FREEFORM: 'Escribe tu respuesta...',
      MULTIPLECHOICE: 'Elige uno...',
      HEARTRATE: 'Ver tu frecuencia cardíaca...',
    },
    SEGMENTED_CONTROL: {
      FEED: 'Secuencia',
      TAGS: 'Etiquetas',
    },
    LOAD_MORE: 'Carga Más',
  },
  LOG: {
    BUTTONS: {
      OPTIONS: 'Opciones',
      DETAILS: 'Detalles',
      SELECTED: 'Seleccionado',
    },
    EMPTY_TRACKER_LIST: {
      TITLE: 'Aún no hay rastreadores',
      MESSAGE: 'Si has sincronizado datos de dispositivos, tus datos aparecerán aquí pronto.',
    },
    TRACKERS: {
      CARDIO: 'Actividades',
      WATER: 'Agua',
      PEDOMETER: 'Pasos',
      WEIGHT: 'Peso',
      'BLOOD-PRESSURE': 'Presión Arterial',
      'BLOOD-GLUCOSE': 'Glucosa en Sangre',
      CALORIES: 'Calorías',
      'BODY-FAT': 'Grasa Corporal',
      SLEEP: 'Sueño',
    },
    ENTRY_ERRORS: {
      MUST_SELECT_ONE: 'Debes seleccionar uno',
      MUST_BE_NUMBER_GREATER_THAN_ZERO: 'Debe ser un número > 0',
      MUST_BE_INTEGER_GREATER_THAN_ZERO: 'Debe ser un número entero > 0',
      MUST_BE_NUMBER_GREATER_THAN_OR_EQUAL_TO_ZERO: 'Debe ser un número >= 0',
      MUST_BE_VALID_MINUTES: 'Debe estar entre 0 minutos y 24 horas',
      MUST_BE_VALID_PERCENT: 'Debe estar entre 0 y 100',
      CANNOT_BE_BLANK: 'No puede estar en blanco',
      TIME_MUST_BE_LESS_THAN_24_HOURS: 'El tiempo debe estar entre 1 minuto y 24 horas',
      TIMES_CANNOT_OVERLAP: 'El tiempo no puede coincidir con otro registro',
    },
    ERRORS: {
      APP_FAILED_TO_SYNC: `Tenemos problemas para sincronizar tus datos de {{syncedAppName}}. Por favor, inténtalo más tarde.`,
      APP_FAILED_TO_SYNC_GENERAL: `Tenemos problemas para sincronizar tus datos. Por favor, inténtalo más tarde.`,
      SYNC_UPDATES_ERROR_TITLE: `Tenemos problemas para sincronizar tus últimas actualizaciones en este momento.`,
      SYNC_UPDATES_UNKNOWN_ERROR_MESSAGE: `Inténtalo de nuevo.`,
      SYNC_UPDATES_NEED_TO_UPDATE_LOG: `Tu programa se ha actualizado. Refresca para obtener la última versión.`,
      REFRESH_FAILED: 'Lo siento, no se puede actualizar en este momento...',
      SAVE_FAILED: 'Lo siento, no se puede guardar en este momento',
    },
    FIELDS: {
      NOTES: 'Comentarios',
      SYSTOLIC: 'Sistólica',
      DIASTOLIC: 'Diastólica',
      TIME: 'Hora',
      CARBOHYDRATES: 'Carbohidratos',
      SODIUM: 'Sodio',
      CALORIES: 'Calorías',
      TOTAL_FAT: 'Grasa Total',
      DIETARY_FIBER: 'Fibra Dietética',
      PROTEIN: 'Proteína',
      START_TIME: 'Hora de Inicio',
      END_TIME: 'Hora de Fin',
      DURATION: 'Duración',
      BLOOD_GLUCOSE: 'Glucosa en Sangre',
      ACTIVITY: 'Actividad',
      DISTANCE: 'Distancia',
      SOURCE: 'Fuente',
      WRITE_SOMETHING: 'Escribe algo...',
      SELECT_ONE: 'Selecciona uno...',
      HEART_RATE: 'Frecuencia Cardíaca',
      AVERAGE_HEART_RATE: 'Frecuencia Cardíaca Promedio',
      MINIMUM_HEART_RATE: 'Frecuencia Cardíaca Mínima',
      MAXIMUM_HEART_RATE: 'Frecuencia Cardíaca Máxima',
    },
    FIELD_ABBREVIATIONS: {
      SYSTOLIC: 'sis',
      DIASTOLIC: 'dia',
      MINHEARTRATE: 'min',
      MAXHEARTRATE: 'máx',
      AVGHEARTRATE: 'prom',
      HEARTRATE: 'ppm',
    },
    ENTRY: {
      DELETE: 'Eliminar',
      CANCEL: 'Cancelar',
      DELETE_LOG: 'Eliminar Registro',
      ARE_YOU_SURE: '¿Estás seguro de que quieres eliminar este registro?',
      UNSAVED_CHANGES: 'Tienes cambios no guardados',
      KEEP_EDITING: 'Seguir editando',
      DISCARD_CHANGES: 'Descartar cambios',
    },
  },
  TRENDS: {
    DETAILS: {
      DAILY_AVERAGE: 'Promedio Diario',
      DAILY_TOTAL: 'Total Diario',
      DAILY: 'Diario',
      MONTH: 'Mes',
      WEEK: 'Semana',
      '90DAY': '90 Días',
      YEAR: 'Año',
    },
    SUMMARY: {
      NO_RECENT_DATA: 'SIN DATOS RECIENTES',
      LAST_30_DAYS: 'ÚLTIMOS 30 DÍAS',
      LAST_7_DAYS: 'ÚLTIMOS 7 DÍAS',
    },
    ERRORS: {
      LOADING_OF_GRAPH_FAILED:
        'Fallo al cargar el gráfico. Por favor, actualiza para intentarlo de nuevo',
    },
  },
  COMMUNITY: {
    TITLE: 'Comunidad',
    LEADERBOARD: {
      TITLE: 'Tablero de líderes',
      JOIN_LEADERBOARD: 'Unirse al Tablero de líderes',
      JOIN: 'Unirse',
      LEAVE: 'Salir',
      LEAVE_CONFIRMATION_DIALOG_TITLE: '¿Salir del Tablero de líderes del grupo?',
      LEAVE_CONFIRMATION_DIALOG_MESSAGE:
        'Puedes unirte a este tablero de líderes nuevamente en cualquier momento',
      JOIN_CONFIRMATION_DIALOG_TITLE: '¿Unirse al Tablero de líderes del grupo?',
      JOIN_CONFIRMATION_DIALOG_MESSAGE:
        'Las personas en este grupo podrán ver tus {{leaderboardType}}',
      LEADERBOARD_RESETS_AT: 'El tablero de líderes se reinicia el {{date}}',
      RESULTS_RECORDED_ON: 'Resultados registrados el {{date}}',
      WEEKLY_TOTAL: 'Total Semanal',
      MONTHLY_TOTAL: 'Total Mensual',
      NO_LEADERS: '¡Sé el primero en unirte al tablero de líderes!',
      CHECK_SOON_FOR_LEADERS: '¡Vuelve pronto para ver actualizaciones del tablero de líderes!',
      PERSON_LEFT_GROUP: 'Esta persona abandonó el grupo.',
      ACTIVE: ' Activo ',
      PREVIOUS: 'Anterior',
    },
    LATEST_POSTS: {
      TITLE: 'ÚLTIMOS MENSAJES',
      NO_POSTS_SINGLE_DESCRIPTION:
        'Esta comunidad aún no tiene mensajes. ¿Por qué no iniciar una conversación?',
    },
    BUTTONS: {
      LEAVE: 'Salir',
      VIEW_LEADERBOARD: 'Ver Tablero de líderes',
    },
    ERRORS: {
      POST_MESSAGE: 'Error al publicar un nuevo mensaje.',
      DELETE_MESSAGE: 'Error al eliminar el mensaje.',
      POST_REPLY: 'Error al publicar una nueva respuesta.',
      JOIN_LEADERBOARD: 'Error al unirse al tablero de líderes.',
      LEAVE_LEADERBOARD: 'Error al abandonar el tablero de líderes',
      LOADING_PREVIOUS_MESSAGES: 'Error al cargar mensajes anteriores',
    },
    MODERATION_OPTIONS: {
      DELETE: 'Eliminar',
      HIDE_THIS_MESSAGE: 'Ocultar este mensaje',
      HIDE_ALL_MESSAGES_FROM_USER: 'Bloquear a este usuario',
      REPORT_ABUSE: 'Reportar abuso',
      ITS_SPAM: 'Es spam',
      ITS_OFFENSIVE: 'Es ofensivo',
      OTHER_ABUSE_OPTION: 'Otro',
      WHY_WAS_THIS_ABUSIVE: '¿Por qué fue abusivo este mensaje?',
      HIDE_ALL_MESSAGES: 'Bloquear usuario',
      HIDE_ALL_MESSAGES_FROM_USER_TITLE: '¿Bloquear/ocultar todos los mensajes de {{username}}?',
      HIDE_ALL_MESSAGES_FROM_USER_MESSAGE: 'Ya no verás ningún mensaje de este usuario',
      HIDE_ALL_MESSAGES_FROM_USER_CONFIRMATION_TITLE: 'OK',
      HIDE_ALL_MESSAGES_FROM_USER_CONFIRMATION_MESSAGE:
        'Ya no verás ningún mensaje de {{username}}.',
    },
    NO_POSTS_MESSAGE: {
      LINE1: '¡Bienvenido!',
      LINE2: '¿Por qué no iniciar una conversación?',
    },
    DIALOGS: {
      JOIN_LEADERBOARD_CONFIRMATION_TITLE: '¡Te uniste!',
      JOIN_LEADERBOARD_CONFIRMATION_MESSAGE:
        'Puede llevar unas horas que tus estadísticas aparezcan.',
    },
    GROUP_FEED: {
      MESSAGE_GROUP: 'Mensaje a {{groupName}}.',
      REPLY: 'Responder',
      REPLIES: 'Respuestas',
      ADD_A_REPLY: 'Agregar una respuesta',
    },
  },
  TABS: {
    MESSAGES: 'Mensajes',
    CONNECT: 'Conectar',
    DAILY: 'Diario',
    TRENDS: 'Tendencias',
    SETTINGS: 'Configuración',
    COMMUNITY: 'Grupos',
    COACHES: 'Entrenadores',
    GROUP_FEED: 'Secuencia de Grupos',
    LOG: 'Mis Datos',
    BOTTOM_TABS: {
      PROGRAM: 'Programa',
      GROUPS: 'Comunidades',
      ACCOUNT: 'Cuenta',
      MESSAGES: 'Mensajes',
      CONNECT: 'Conectar',
      LOG: 'Mis Datos',
    },
  },

  SETTINGS: {
    EDIT_CONNECTED_APPS: 'Editar aplicaciones conectadas',
    CONNECTED_APPS_AND_TRACKERS: 'Aplicaciones y rastreadores conectados',
    CONNECT_BLUETOOTH_DEVICES: 'Conectar dispositivos Bluetooth',
    ENTER_AN_INVITE_ID: 'Ingresar {{article}} {{inviteIdName}}',
    ENTER_A_CLIENT_USER_ID: 'Ingresar {{article}} {{clientUserIdName}}',
    CLIENT_USER_ID_DISPLAY: 'ID de usuario del cliente',
    CLIENT_USER_ID_ARTICLE: 'un',
    ENTER_YOUR_PATIENT_ID: 'Ingresar tu ID de paciente',
    ENTER_EMAIL_CONFIRMATION_CODE: 'Ingresar el código de confirmación por correo electrónico',
    PATIENT_ID: 'ID de Paciente',
    ENTER_YOUR_ID: 'Ingresar tu {{inviteIdShortName}}',
    PATIENT_ID_ADDED_TITLE: '¡Éxito!',
    PATIENT_ID_ADDED_MESSAGE:
      'Los resultados de laboratorio ahora aparecerán para este ID de paciente a medida que estén listos',
    HELP: 'Ayuda',
    PRIVACY_POLICY: 'Política de Privacidad',
    TERMS_OF_SERVICE: 'Términos de Servicio',
    LOG_OUT: 'Cerrar sesión',
    LOAD_FAILURE:
      'Falló la carga de la configuración del Coach. Desliza hacia abajo para actualizar e intenta nuevamente.',
    RATE_APP: 'Calificar la aplicación',
    EMAIL_CONFIRMED: 'Gracias por verificar tu dirección de correo electrónico.',
    CONFIRM_EMAIL_ADDRESS: 'Verifica tu dirección de correo electrónico',
    DELETE_ACCOUNT: {
      BUTTON_TITLE: 'Eliminar',
      COMPARATOR: 'ELIMINAR',
      DESCRIPTION:
        'Esto eliminará tu cuenta y todos los datos asociados de nuestro sistema. No podrás reactivar tu cuenta ni recuperar ningún dato que hayas registrado o sincronizado.',
      INSTRUCTIONS:
        'Si deseas eliminar permanentemente tu cuenta, escribe ELIMINAR (en MAYÚSCULAS) y luego toca Eliminar',
      ERROR_MESSAGE: 'Escribe ELIMINAR (en MAYÚSCULAS)',
      ERROR_DELETING_ACCOUNT: 'Lo siento, no podemos eliminar tu cuenta en este momento.',
      CONFIRMATION_TITLE: 'Cuenta eliminada',
      CONFIRMATION_MESSAGE: `Estamos eliminando tu cuenta y todos los datos asociados de nuestro sistema. Esto puede tomar algún tiempo.`,
    },
    ERRORS: {
      SUPPORT_EMAIL_FAILED_TITLE:
        'No se puede abrir el correo electrónico para la solicitud de soporte',
      SUPPORT_EMAIL_FAILED_MESSAGE:
        'Por favor, verifica que tengas una cuenta de correo electrónico habilitada en tu dispositivo.',
      ADD_INVITE_ID_FAILED_TITLE: 'No se puede agregar {{inviteIdName}}',
      ADD_PATIENT_ID_FAILED_TITLE: 'No se puede agregar el ID de paciente',
      INVALID_PATIENT_ID_MESSAGE:
        'El ID de paciente, nombre y fecha de nacimiento no coinciden con un pedido de laboratorio existente.',
      ENTER_EMAIL_CONFIRMATION_CODE_FAILED_TITLE: 'Falló la verificación por correo electrónico',
      CURRENT_PASSWORD_NOT_CORRECT: 'La contraseña actual no es correcta',
      NEW_PASSWORD_DOES_NOT_MATCH: 'La nueva contraseña y la confirmación no coinciden',
    },
    BLUETOOTH_LOCATION_PERMISSION_PROMPT: {
      PRIVACY_POLICY_URL_DESCRIPTION: 'Ver política de privacidad',
      TITLE: '¿Permitir acceso a la ubicación?',
      EXPLANATION:
        'Se requiere acceso a la ubicación para que {{appName}} pueda encontrar tu dispositivo Bluetooth y mantenerse conectado a él. No recopilamos ni almacenamos información sobre tu ubicación.', //Texto genérico siempre en la parte superior de la pantalla de consentimiento de ubicación que explica por qué estamos haciendo esto
      SETTINGS_INFO: {
        IOS:
          'Puedes actualizar tus permisos de ubicación en la configuración de tu aplicación. Para obtener la mejor experiencia, asegúrate de que el acceso a la ubicación esté configurado como "Siempre".',
        ANDROID:
          'Puedes actualizar tus permisos de ubicación en la configuración de tu aplicación. Para obtener la mejor experiencia, asegúrate de que el acceso a la ubicación esté configurado como "Permitir siempre".',
      }, //algo que aparece justo encima del botón que te lleva a la configuración. Mostramos ese botón siempre que la pregunta de ubicación ya haya sido respondida, para que un usuario pueda aceptar permisos que rechazaron, o desactivar permisos que aceptaron
      ACCEPTED: 'El acceso a la ubicación está activado. ¡Listo!', //Mostramos esto debajo de la explicación para indicar que el usuario ya aceptó los permisos y no necesita hacer nada más.
      REJECTED:
        'El acceso a la ubicación no está activado. Debe habilitarse para conectar dispositivos Bluetooth.', //Mostramos esto si rechazaron algún/ todos los permisos de ubicación, justo encima del botón de configuración que pueden usar para solucionarlo
      REJECTED_ALWAYS:
        'El acceso a la ubicación en segundo plano está desactivado. Esto es necesario para leer datos de tus dispositivos Bluetooth cuando la aplicación está cerrada.', //Mostramos esto solo en iOS, si el usuario aceptó los permisos 'Mientras la aplicación está abierta' pero rechazó los permisos 'Siempre'. Ten en cuenta que la aceptación inicial siempre es provisional-siempre, y luego se le pide al usuario que siga rastreando en segundo plano más tarde. Pero el usuario no verá este mensaje hasta que rechace el seguimiento siempre activado
      ANDROID_11_BACKGROUND_LOCATION_PROMPT:
        "Habilita la ubicación 'Siempre' para completar la configuración.", //Esto aparece en el aviso requerido por el sistema operativo que realmente establece los permisos de ubicación
      NOT_ANSWERED: '',
    },
    NOTIFICATIONS: {
      NOTIFICATIONS: 'Notificaciones',
      COMMUNITIES: 'COMUNIDADES',
      NO_COMMUNITIES: 'No eres miembro de ninguna comunidad',
      NOTIFICATION_SETTING: 'Configuración de notificaciones',
      COMMUNITY: 'Comunidad',
      ALL_POSTS: 'Todos los mensajes',
      ADMIN_POSTS_REPLIES_THREADS:
        'Mensajes del administrador y todas las respuestas en los hilos en los que he contribuido',
      ADMIN_POSTS_REPLIES: 'Mensajes del administrador y respuestas a mis mensajes',
      ADMIN_POSTS: 'Solo mensajes del administrador',
      TURN_OFF: 'Desactivar notificaciones',
    },
    OTA: {
      NEW_VERSION_AVAILIBLE: 'Una nueva versión de la aplicación está disponible.',
      UPGRADE_TO_LATEST: 'Actualiza a la última versión',
      NEW_VERSION_AVAILIBLE_TRY_AGAIN:
        'Hay una nueva versión de la aplicación disponible, pero no podemos descargarla en este momento. No te preocupes, lo intentaremos nuevamente más tarde.',
      NETWORK_MAY_BE_BLOCKING:
        'Si ves esto con frecuencia, es posible que tu red esté bloqueando las actualizaciones. Cambiar a una red wifi diferente podría ayudar.',
      ERROR_MESSAGE: 'Mensaje de error:',
    },
  },
  UNITS: {
    SVG: 'svg',
    SVGS: 'svgs',
    MINUTE: 'minuto',
    MINUTES: 'minutos',
    HOUR: 'hora',
    HOURS: 'horas',
    SYSTOLIC: 'sistólica',
    DIASTOLIC: 'diastólica',
    MINHEARTRATE: 'mín',
    MAXHEARTRATE: 'máx',
    AVGHEARTRATE: 'prom',
  },
};
