import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
//import { spy } from 'mobx';
import { observer, inject } from 'mobx-react';
import { useScreenBehaviors } from '/common/navigation';
import { CardsList } from '/common/components/program/';
import { NavbarWrapper } from '/common/components/navigation';
import { ScreenFirstLoadWrapper } from '../common';
import { navigationRoutes } from '../../config/constants';

/**
 * Tab that contains a header that allows navigating between the seven days of the week,
 * or can expand to show a calendar to navigate to any day/ week.
 * Under the header is a carousel that can swipe between the seven active days,
 * and can be reloaded to show a different week.
 */
const ProgramCardsListScreen = inject(
  'rootStore',
  'Linking'
)(
  observer(({ navigation, route, rootStore }) => {
    const { logStore } = rootStore;

    const { tagId } = route.params;

    const tagPage = logStore.getTagPage(tagId);

    const tag = logStore.tagForId(tagId);

    useScreenBehaviors({
      onFocus: () => {
        const { logStore } = rootStore;
        const tagPage = logStore.getTagPage(tagId);
        tagPage.loadTagEvents();
      },
    });

    const onPressCard = useCallback(
      programEventId => {
        navigation.navigate(navigationRoutes.stacks.main.cardFromTagDetailScreen, {
          programEventId,
          tagId: route.params.tagId,
        });
      },
      [navigation, route]
    );

    return (
      <View style={{ flex: 1 }}>
        <ScreenFirstLoadWrapper
          state={tagPage.loadTagEventsState}
          hasData={tagPage.programEvents.length}
          onPressRefresh={tagPage.loadTagEvents}
          NavbarWrapperComponent={NavbarWrapper}
          navbarWrapperProps={{
            title: tag ? tag.name : '' /* could be removed on a refresh */,
            navigation,
            hasBackButton: true,
          }}>
          <CardsList onPressCard={onPressCard} programEvents={tagPage.eventsForTagSorted} />
        </ScreenFirstLoadWrapper>
      </View>
    );
  })
);

ProgramCardsListScreen.propTypes = {
  rootStore: PropTypes.object,
};

export default ProgramCardsListScreen;
