import React from 'react';
import { View, Text, Image } from 'react-native';
import { CircleThumbnail } from '/common/components';
import { textStyles, sizes } from '/common/config/styles';
import AlertBar from './AlertBar';

/**
 * The actual bar that will display with an in-app notification.
 * Includes, a message and optionally a thumbnail.
 */
const InAppNotificationBar = ({ message, thumbnailSource, thumbnailAltTitle, textStyle }) => (
  <AlertBar>
    <View
      style={{
        minHeight: 30,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
      {thumbnailSource || thumbnailAltTitle ? (
        <View
          style={{
            height: sizes.notificationThumbnail,
            width: sizes.notificationThumbnail,
            marginRight: sizes.small * 1.8,
          }}>
          <CircleThumbnail
            diameter={sizes.notificationThumbnail}
            source={thumbnailSource}
            altTitle={thumbnailAltTitle}
            makeASquareInstead
          />
          <Image
            defaultSource={require('../assets/bubble-cover.png')}
            source={require('../assets/bubble-cover.png')}
            style={{
              height: sizes.notificationThumbnail,
              width: sizes.notificationThumbnail,
              backgroundColor: 'transparent',
              position: 'absolute',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
            }}
          />
        </View>
      ) : null}
      <Text
        numberOfLines={2}
        ellipsizeMode="tail"
        style={[
          textStyles.standard.semibold,
          {
            textAlign: 'left',
            flex: 1,
          },
          textStyle,
        ]}>
        {message}
      </Text>
    </View>
  </AlertBar>
);

export default InAppNotificationBar;
