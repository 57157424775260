import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useScreenBehaviors } from '/common/navigation';
import { SimpleNavWrapper, NavbarlessScreen } from '/common/components/navigation';
import { HeaderIconButton, HeaderTextButton } from '../../components/navigation';
import { LoadingShadeWrapper } from '../../components/common';
import { WebEditLogEntryFooter } from '../../components/tracking';
import { LogEntryHeader, LogEntryForm } from '/common/components/tracking';
import { CancelIcon, BackIcon } from '/common/config/icons';
import { withKeyboardAvoidance, ScreenError, useLogEntryBehaviors } from '../common';
import { BrandContext } from '/common/config/branding';
import { colors, useMediaQueryInfo } from '/common/config/styles';

const EditLogScreen = inject('rootStore')(
  observer(function({ navigation, rootStore, route }) {
    const { t } = useTranslation();
    const { logStore } = rootStore;
    const branding = useContext(BrandContext);
    const { isWideScreen } = useMediaQueryInfo();

    // enables screen breadcrumbs
    useScreenBehaviors({});

    const NavWrapper = isWideScreen ? NavbarlessScreen : SimpleNavWrapper;

    const {
      onPressCancel,
      onPressSave,
      onPressDelete,
      editingLog,
      savedLog,
      tracker,
      isEditModeEnabled,
      onToggleEditMode,
    } = useLogEntryBehaviors({ route, navigation, logStore: rootStore.logStore });

    // if null, this record is being deleted and we're probably about to hav backwards anyway
    const isEditing = !!(savedLog && savedLog.canEdit && isEditModeEnabled);

    return (
      <NavWrapper
        leftButton={
          isEditing ? (
            <HeaderIconButton
              icon={<CancelIcon />}
              onPress={onPressCancel}
              disabled={logStore.saveState.isPending}
            />
          ) : (
            <HeaderIconButton icon={<BackIcon />} onPress={navigation.goBack} isDestructive />
          )
        }
        rightButton={
          isEditing ? (
            <HeaderTextButton
              text={t('GENERAL:SAVE')}
              disabled={logStore.saveState.isPending}
              onPress={onPressSave}
              textStyle={
                tracker.palettesId ? { color: branding.trackerColors[tracker.palettesId] } : null
              }
            />
          ) : savedLog && savedLog.canEdit ? (
            <HeaderTextButton
              text={t('GENERAL:EDIT')}
              disabled={logStore.saveState.isPending}
              onPress={onToggleEditMode}
              textStyle={
                tracker.palettesId ? { color: branding.trackerColors[tracker.palettesId] } : null
              }
            />
          ) : null
        }>
        <ScreenError
          state={logStore.saveState}
          title={t('LOG:ERRORS:SAVE_FAILED')}
          type="messageBar"
          ignoreFormErrors
        />
        <LoadingShadeWrapper
          style={{ flex: 1, backgroundColor: colors.bg1 }}
          isLoading={logStore.saveState.isPending}>
          {editingLog && (
            <LogEntryForm
              header={
                <LogEntryHeader
                  tracker={tracker}
                  button={
                    isWideScreen && isEditing ? (
                      <HeaderIconButton
                        icon={<CancelIcon />}
                        onPress={onPressCancel}
                        disabled={logStore.saveState.isPending}
                      />
                    ) : null
                  }
                  text={
                    tracker.clientMeta.entryHeader ? tracker.clientMeta.entryHeader(savedLog) : null
                  }
                />
              }
              date={route.params.date}
              tracker={tracker}
              log={editingLog}
              canEdit={savedLog && savedLog.canEdit}
              isEditing={isEditing}
              formErrors={logStore.saveState.formErrors}
              onPressDelete={onPressDelete}
              webLogEntryFooter={
                <WebEditLogEntryFooter
                  tracker={tracker}
                  onPressCancel={onPressCancel}
                  onPressSave={onPressSave}
                  onPressEdit={onToggleEditMode}
                  isEditing={isEditing}
                  onPressDelete={onPressDelete}
                  canEdit={savedLog && savedLog.canEdit}
                />
              }
            />
          )}
        </LoadingShadeWrapper>
      </NavWrapper>
    );
  })
);

EditLogScreen.propTypes = {
  rootStore: PropTypes.object,
  navigation: PropTypes.object,
};

export default withKeyboardAvoidance(EditLogScreen, { enabledForIos: false });
