import { Alert, Linking as RNLinking, Keyboard, Platform } from 'react-native';
import * as ExpoLinking from 'expo-linking';

import * as WebBrowser from 'expo-web-browser';

const WWW_URL_PATTERN = /^www\./i;

// weird stuff happens with the UI when we open links while the keyboard is still up
function dismissKeyboardOnAndroid() {
  if (Platform.OS === 'android') {
    Keyboard.dismiss();
  }
}

export default class Linking {
  // callback to override
  static onOpenURL = () => {};

  // TODO: i18n this
  static openURLErrorTitle = 'Cannot open link';
  static noEmailClientErrorMessage =
    'Please check that you have an email account enabled on your device.';
  static genericCannotOpenLinkErrorMessage =
    'This link cannot be opened on your device. Please check that you have an application installed that can handle this link.';

  static async openURL(url, options) {
    const myOptions = {
      forceHttpOnNoProtocol: false,
      useInAppBrowser: false,
      reportLinkAccess: true,
      ...options,
    };
    // apply http:// if it is not there for a www link
    if (WWW_URL_PATTERN.test(url)) {
      Linking.openURL(`http://${url}`, myOptions);
      return;
    }

    // for http prefix for links that don't have protocol and don't have www
    if (myOptions && myOptions.forceHttpOnNoProtocol && !url.includes('://')) {
      Linking.openURL(`http://${url}`, myOptions);
      return;
    }

    dismissKeyboardOnAndroid();
    if (Platform.OS === 'web') {
      window.open(url, '_blank');
    } else if (myOptions && myOptions.useInAppBrowser) {
      WebBrowser.openBrowserAsync(url);
      if (myOptions.reportLinkAccess) {
        Linking.onOpenURL();
      }
    } else {
      const supported = await ExpoLinking.canOpenURL(url);
      // Android 11 doesn't work with a lot of this due to: https://github.com/expo/expo/issues/12680
      if (supported || (Platform.OS === 'android' && Platform.Version >= 30)) {
        // This can generate very intermittant cannot open errors when used repeatedly
        // So we swallow it.
        // https://mcculloughwebservices.com/2017/05/01/fixing-unable-open-url-telprompt-error/
        try {
          ExpoLinking.openURL(url);
        } catch (error) {
          console.log(error);
        }
        if (myOptions.reportLinkAccess) {
          Linking.onOpenURL();
        }
      } else {
        // TODO: this will not work on web
        Alert.alert(Linking.openURLErrorTitle, Linking.genericCannotOpenLinkErrorMessage);
      }
    }
  }

  static addEventListener(...params) {
    ExpoLinking.addEventListener(...params);
  }

  static parse(...params) {
    return ExpoLinking.parse(...params);
  }

  static removeEventListener(...params) {
    ExpoLinking.removeEventListener(...params);
  }

  static getInitialURL() {
    // Expo's version returns a parsed object with URL parts (and no domains)
    return RNLinking.getInitialURL();
  }
}
