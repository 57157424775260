import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import {
  CircleThumbnail,
  SecurableImage,
  EmptyListPlaceholder,
  AestheticallyPleasingListFooter,
  NudgeFlatList as FlatList,
  NudgeTouchable as Touchable,
  ListSeparatorLine,
} from '/common/components';
import { View, Text } from 'react-native';
import { tableStyles, textStyles, sizes, fonts } from '/common/config/styles';
import { BrandContext } from '../../config/branding';
import { fullName } from '/common/config/strings';

const Club = observer(({ club, onPress, feedSortMode }) => {
  return (
    <Touchable onPress={onPress} isTouchableOpacity useHoverStyle>
      <View
        style={[
          {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            paddingVertical: sizes.small,
            paddingHorizontal: sizes.small,
            flex: 1,
          },
        ]}>
        <BrandContext.Consumer>
          {branding => (
            <View style={{ marginTop: 11, marginLeft: 7 }}>
              <SecurableImage
                style={{
                  width: 60,
                  height: 38,
                  borderRadius: 4,
                  overflow: 'hidden',
                }}
                source={
                  // the source could be a string if we're supposed to do a mapping to a local image
                  // instead of using the image from the server
                  club.thumbLogoImageSource === 'clubThumb'
                    ? branding.assets.clubThumb ||
                      require('../../assets/icons/groups-default-thumb.jpg')
                    : club.thumbLogoImageSource
                }
              />
            </View>
          )}
        </BrandContext.Consumer>
        <View
          style={{
            flex: 1,
            paddingLeft: 15,
            paddingVertical: 7,
            borderRadius: 4,
            overflow: 'hidden',
          }}>
          <View style={{ flexDirection: 'row', justifyContent: 'flex-start', flex: 1 }}>
            <Text
              numberOfLines={1}
              ellipsizeMode="tail"
              style={[
                textStyles.standard.dark,
                { fontWeight: fonts.weights.medium, flexShrink: 1 },
              ]}>
              {club.title}
            </Text>
            {club.unreadComments > 0 /* might only be available on client app? */ ? (
              <BrandContext.Consumer>
                {branding => (
                  <View
                    style={{
                      marginLeft: sizes.small,
                      width: sizes.small,
                      height: sizes.small,
                      alignSelf: 'center',
                      borderRadius: sizes.small / 2,
                      backgroundColor: branding.highlightedColor,
                    }}
                  />
                )}
              </BrandContext.Consumer>
            ) : null}
          </View>
          {(typeof feedSortMode === 'undefined' || feedSortMode === 'created_at') &&
            (club.recentCommentsSorted.length ? (
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}>
                <CircleThumbnail
                  source={club.recentCommentsSorted[0].author.photoUrl}
                  diameter={sizes.thumbnail / 2}
                  altTitle={fullName(club.recentCommentsSorted[0].author)}
                />
                <Text
                  numberOfLines={1}
                  ellipsizeMode="tail"
                  style={[textStyles.standard.light, { flex: 1, marginLeft: sizes.small }]}>
                  {club.recentCommentsSorted[0].comment}
                </Text>
              </View>
            ) : club.recentCommentsNotAvailable ? null : (
              <Text style={textStyles.standard.light}>
                Nothing new lately. Why don't you start a conversation?
              </Text>
            ))}
        </View>
      </View>
    </Touchable>
  );
});

const ClubsList = observer(
  ({ clubs, isRefreshing, onPressClub, onRefresh, perspective, feedSortMode }) => (
    <FlatList
      style={tableStyles.table}
      contentContainerStyle={{ flexGrow: 1 }}
      data={clubs}
      refreshing={isRefreshing}
      onRefresh={onRefresh}
      keyExtractor={i => i.id.toString()}
      renderItem={({ item }) => (
        <Club club={item} onPress={() => onPressClub(item)} feedSortMode={feedSortMode} />
      )}
      ItemSeparatorComponent={ListSeparatorLine}
      ListFooterComponent={AestheticallyPleasingListFooter}
      ListEmptyComponent={
        perspective && (
          <EmptyListPlaceholder
            title="You don't have any communities yet"
            message="Head on over to nudgecoach.com to create your first one"
          />
        )
      }
    />
  )
);

ClubsList.propTypes = {
  clubs: PropTypes.arrayOf(PropTypes.object).isRequired,
  isRefreshing: PropTypes.bool,
  onPressClub: PropTypes.func.isRequired,
  onRefresh: PropTypes.func,
  listBottomPadding: PropTypes.number,
  perspective: PropTypes.string.isRequired,
};

ClubsList.defaultProps = {
  isRefreshing: false,
  onRefresh: null,
  listBottomPadding: 0,
};

export default ClubsList;
