import React, { useContext, useCallback, useMemo, useRef, useState } from 'react';
import { View, TouchableWithoutFeedback, Text, FlatList, Platform } from 'react-native';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { take, drop, debounce } from 'lodash';
import { getUniversalSvgComponents } from 'nudge-client-common/common-svg';
import { toDateTime } from 'nudge-client-common/lib/dates';
import { CompletionGraph } from 'nudge-client-common/graphs';
import { BrandContext } from '/common/config/branding';
import { sizes, colors, textStyles, useMediaQueryInfo } from '/common/config/styles';
import { isOldPhone } from '/common/config/feature-support';
import { descriptiveDateWithoutYear } from '/common/config/strings';
import cardConstants from './cardConstants';
import { RightChevronIcon, LeftChevronIcon } from '/common/config/icons';

const getDateString = ({ date, repeatingType, isFocused }) => {
  const dateTime = toDateTime(date);
  if (repeatingType === 'DAILY') {
    return dateTime.toFormat('EEE').substr(0, 1);
  }
  if (isFocused) {
    return descriptiveDateWithoutYear(date, { dontShowRelativeDate: true });
  }
  return dateTime.toFormat('d');
};

/**
 * The line behind the completion graphs. May be partially highlighted or gray based on contiguous goals met.
 */
const BackingLine = observer(({ programEvent, previousEvent, nextEvent }) => {
  const branding = useContext(BrandContext);
  const thickness = 3;

  const isThisEventCompleted =
    programEvent && programEvent.goalCompletionStats.percentComplete === 1;
  const isNextEventCompleted = nextEvent && nextEvent.goalCompletionStats.percentComplete === 1;
  const isPreviousEventCompleted =
    previousEvent && previousEvent.goalCompletionStats.percentComplete === 1;

  return (
    <View
      style={{
        position: 'absolute',
        bottom: 28, // not sure why this number works, best guess is that it's about half of the 55 below
        left: 0,
        right: 0,
      }}>
      <View
        style={{
          flexDirection: 'row',
        }}>
        <View
          style={{
            flex: 1,
            height: thickness,
            backgroundColor: !previousEvent
              ? 'transparent'
              : isThisEventCompleted && isPreviousEventCompleted
              ? branding.highlightedColor
              : colors.bg1,
          }}
        />
        {nextEvent ? (
          <View
            style={{
              flex: 1,
              height: thickness,
              backgroundColor:
                isThisEventCompleted && isNextEventCompleted
                  ? branding.highlightedColor
                  : colors.bg1,
            }}
          />
        ) : (
          <View style={{ flex: 1 }} />
        )}
      </View>
    </View>
  );
});

/**
 * Individual item in the timeline.
 */
const RepeatingCardTimelineItem = observer(
  ({
    currentProgramEvent,
    currentProgramEventIndex,
    isFocused,
    onPressEvent,
    // animation props - shared among all item so they can coordinate their animations
    peWidth,
    nextEvent,
    prevEvent,
  }) => {
    const branding = useContext(BrandContext);

    const programEvent = currentProgramEvent;

    const onPress = useCallback(() => {
      onPressEvent(programEvent);
    }, [currentProgramEvent, currentProgramEventIndex, onPressEvent, peWidth, isFocused]);

    return (
      <TouchableWithoutFeedback key={programEvent.id.toString()} onPress={onPress}>
        <View
          style={{
            paddingTop: sizes.medium,
            paddingBottom: sizes.small,
            //paddingHorizontal: sizes.large, // no padding here to allow text to expand
            height: 100,
            width: peWidth,
            alignItems: 'center',
          }}>
          <View
            style={{
              backgroundColor: isFocused ? colors.bg2 : 'transparent',
              height: 24,
              minWidth: 24,
              borderRadius: 24,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Text
              style={[
                isFocused ? textStyles.small.dark : textStyles.small.light,
                {
                  paddingHorizontal: 6,
                  textAlign: 'center',
                },
              ]}
              allowFontScaling={false}
              numberOfLines={1}
              ellipsizeMode="clip">
              {getDateString({
                date: programEvent.date,
                repeatingType: programEvent.share.repeatingType,
                isFocused,
              })}
            </Text>
          </View>
          <BackingLine
            programEvent={programEvent}
            nextEvent={nextEvent}
            previousEvent={prevEvent}
          />
          <View
            style={{
              alignSelf: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              height: 55,
            }}>
            <CompletionGraph
              TextComponent={Text}
              ViewComponent={View}
              svg={getUniversalSvgComponents(Platform.OS)}
              diameter={cardConstants.completionGraphDiameter}
              strokeWidth={cardConstants.completionGraphStrokeWidth}
              percentComplete={programEvent.goalCompletionStats.percentComplete}
              numGoalsComplete={programEvent.goalCompletionStats.numComplete}
              totalGoals={programEvent.goalCompletionStats.total}
              completedColor={branding.highlightedColor}
              bgColor={
                programEvent.goalCompletionStats.percentComplete === 1
                  ? branding.highlightedColor
                  : isFocused
                  ? colors.bg2
                  : colors.bg1
              }
              contentsColor={
                programEvent.goalCompletionStats.percentComplete === 1
                  ? colors.bg0
                  : colors.darkText
              }
              check={programEvent.goalCompletionStats.percentComplete === 1}
              showText={isFocused}
            />
          </View>
        </View>
      </TouchableWithoutFeedback>
    );
  }
);

const defaultPageSize = 7;

const RepeatingCardTimelineItems = observer(
  ({ programEvents, focusedEvent, onPressEvent, pageSize }) => {
    const [page, setPage] = useState(0);
    const pages = Math.ceil(programEvents.length / pageSize);
    const pageNumbers = Array(pages)
      .fill()
      .map((_, i) => i);
    const { innerWindowWidth } = useMediaQueryInfo();
    const screenWidth = innerWindowWidth;
    const { isWeb } = useMediaQueryInfo();
    const margin = 60;
    const peWidth = (screenWidth - (isWeb ? margin * 2 : 0)) / pageSize;

    const getItemLayout = useCallback(
      (data, index) => ({
        length: screenWidth,
        offset: screenWidth * index,
        index,
      }),
      [innerWindowWidth]
    );

    const renderItem = useCallback(
      ({ item }) => {
        const filteredEvents = take(drop(programEvents, pageSize * item), pageSize).reverse();
        return (
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-end',
              width: screenWidth,
              cursor: 'pointer',
            }}>
            {filteredEvents.map((programEvent, index) => (
              <RepeatingCardTimelineItem
                key={programEvent.id.toString()}
                currentProgramEvent={programEvent}
                currentProgramEventIndex={index}
                onPressEvent={onPressEvent}
                isFocused={focusedEvent.id === programEvent.id}
                peWidth={peWidth}
                nextEvent={
                  programEvent === programEvents[0]
                    ? null
                    : programEvents[programEvents.indexOf(programEvent) - 1]
                }
                prevEvent={
                  programEvent === programEvents[programEvents.length - 1]
                    ? null
                    : programEvents[programEvents.indexOf(programEvent) + 1]
                }
              />
            ))}
          </View>
        );
      },
      [focusedEvent, programEvents, onPressEvent, innerWindowWidth]
    );

    const onPressBack = useCallback(
      debounce(
        () => {
          if (page < pageNumbers.length - 1) {
            flatListRef.current.scrollToIndex({ index: page + 1, animated: true });
            setPage(page + 1);
          }
        },
        100,
        { leading: true }
      ),
      [flatListRef, setPage, page]
    );

    const onPressForward = useCallback(
      debounce(
        () => {
          if (page > 0) {
            flatListRef.current.scrollToIndex({ index: page - 1, animated: true });
            setPage(page - 1);
          }
        },
        100,
        { leading: true }
      ),
      [flatListRef, setPage, page]
    );

    const keyExtractor = useCallback(item => item.toString(), []);
    const flatListRef = useRef();
    const pageButtonStyle = {
      width: margin,
      height: margin,
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      marginTop: 43,
    };

    return (
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        {isWeb && (
          <TouchableWithoutFeedback onPress={onPressBack}>
            <View style={pageButtonStyle}>
              <LeftChevronIcon />
            </View>
          </TouchableWithoutFeedback>
        )}
        <FlatList
          ref={flatListRef}
          bounces={false}
          getItemLayout={getItemLayout}
          showsHorizontalScrollIndicator={false}
          pagingEnabled
          horizontal
          inverted
          style={{ flex: 1 }}
          data={pageNumbers}
          initialNumToRender={1}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
        />
        {isWeb && (
          <TouchableWithoutFeedback style={{ width: 30 }} onPress={onPressForward}>
            <View style={pageButtonStyle}>
              <RightChevronIcon />
            </View>
          </TouchableWithoutFeedback>
        )}
      </View>
    );
  }
);

/**
 * Timeline showing up to four program events, with the currently focused event showing a selected state.
 */
const RepeatingCardTimeline = observer(({ programEvents, focusedEvent, onPressEvent }) => {
  const myProgramEvents = useMemo(() => {
    if (isOldPhone()) {
      return take(programEvents, 14);
    }
    return programEvents;
  });
  return (
    <View style={{ flex: 1 }}>
      <Text style={[textStyles.light, { marginTop: 9, marginLeft: cardConstants.textPadding }]}>
        {toDateTime(focusedEvent.date).toFormat('EEEE, MMMM d')}
      </Text>
      <RepeatingCardTimelineItems
        programEvents={myProgramEvents}
        focusedEvent={focusedEvent}
        onPressEvent={onPressEvent}
        pageSize={programEvents.length < 7 ? programEvents.length : defaultPageSize}
      />
    </View>
  );
});

RepeatingCardTimeline.propTypes = {
  programEvents: PropTypes.array.isRequired, // list of events to show on timeline. MAX = 4 (will generate out of index errors otherwise)
  focusedEvent: PropTypes.object.isRequired, // event the focused state
  onPressEvent: PropTypes.func.isRequired, // function that takes event ID, called when pressed
};

export default RepeatingCardTimeline;
