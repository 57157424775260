export default {
  showFullscreenImage: 'ShowFullscreenImage',
  showFullscreenImage_fade: 'ShowFullscreenImage_fade',
  showFullscreenVideo: 'ShowFullscreenVideo',
  showFullscreenVideo_fade: 'ShowFullscreenVideo_fade',
  previewImage: 'PreviewImage',
  changePassword: 'ChangePassword',
  clubs: {
    socialFeed: 'ClubSocialFeed',
    reply: 'ClubSocialFeedReply',
    leaderboard: 'ClubSocialFeedLeaderboard',
    settings: 'ClubsSocialFeedSettings',
  },
  messages: {
    viewMessageDetail: 'ScheduledMessageRoot',
  },
  program: {
    cardDetail: 'ProgramCardDetail',
    trackerHistory: 'TrackerHistory',
    logEntries: 'LogEntries',
    editLog: 'EditLog',
  },
};
