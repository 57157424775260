import React, { Component } from 'react';
import { View, YellowBox, Platform } from 'react-native';
import { Asset } from 'expo-asset';
import { Tester, TestHookStore } from 'cavy';
import { getDisabledYellowBoxWarnings } from '/common/lib';
/* eslint-disable */
// This file is not checked in, needs to be set via yarn run set-nudge-config.
import appConfig from './current-config/nudge-config.json';
/* eslint-enable */
import Index from './app/index';
import tests from './app/lib/ui-testing/tests';

// not included in 4300+ builds, so can only enable on web
if (Platform.OS === 'web') {
  //require('@expo/match-media'); // just needs to be imported at root for media queries to work)
}

// transform-remove-console isn't actually working! See https://github.com/babel/minify/issues/950
// We're taking the easy way out for now so we don't break our babel config
if (!__DEV__) {
  console.log = () => {};
}

// this is needed to make cached/ downloaded asset stuff work
Asset;

console.warn = function() {};

const disabledYellowBoxWarnings = getDisabledYellowBoxWarnings();

const testHookStore = new TestHookStore();
let parentRef;
let testSpecs;
if (appConfig.uiTest && appConfig.uiTest.enabled) {
  testSpecs = tests[appConfig.uiTest.test]({
    data: appConfig.uiTest.data,
    getViewRef: () => parentRef,
    options: appConfig.uiTest.options,
  });
  // not sure why this comes up sometimes
  disabledYellowBoxWarnings.push(`Can't call setState`);
  disabledYellowBoxWarnings.push(
    `Warning: Can't perform a React state update on an unmounted component.`
  );
  disabledYellowBoxWarnings.push(`Can't perform a React state update`);
  disabledYellowBoxWarnings.push(`Constants.manifest`);
  // suppress warnings so they don't slow down the console
  console.warn = function() {};
}

YellowBox.ignoreWarnings(disabledYellowBoxWarnings);

export default class App extends Component {
  render() {
    if (appConfig.uiTest && appConfig.uiTest.enabled) {
      return (
        <View
          ref={ref => {
            parentRef = ref;
          }}
          style={{ flex: 1 }}>
          <Tester specs={testSpecs} store={testHookStore} waitTime={4000}>
            <Index />
          </Tester>
        </View>
      );
    }

    return <Index />;
  }
}
