import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import ReadOnlyField from './ReadOnlyField';
import { textStyles } from '/common/config/styles';

@observer
class SourceField extends Component {
  render() {
    const { log, t } = this.props;
    return log.isSynced ? (
      <ReadOnlyField
        title={t('LOG:FIELDS:SOURCE')}
        value={log.displaySource}
        textStyle={textStyles.standard.light}
      />
    ) : null;
  }
}

SourceField.propTypes = {
  log: PropTypes.object,
  t: PropTypes.any.isRequired,
};

export default withTranslation()(SourceField);
