import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
//import { spy } from 'mobx';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { sourceToDisplaySource } from 'nudge-client-common/data/trackers';
import { getToday } from 'nudge-client-common/lib/dates';
import idx from 'idx';
import { withScreenBehaviors } from '/common/navigation/';
import { FadeWrapper } from '../../../../components/common';
import { NavbarWrapper } from '../../../../components/navigation';
import { colors, sizes } from '../../../../config/common-styles';
import { TrackersList } from '/common/components/tracking';
import { rootStore } from '../../../../stores/instances';
import { ScreenError, ScreenFirstLoadWrapper } from '../../../common';
import { BrandContext } from '../../../../config/branding';
import { getTheme } from '../../../../lib/theming';
import { navigationRoutes } from '../../../../config/constants';

/*spy(event => {
  if (event.type === 'reaction') {
    console.log(`${event.name} with args: ${event.arguments}`);
  }
});*/

/**
 * Tab that contains a header that allows navigating between the seven days of the week,
 * or can expand to show a calendar to navigate to any day/ week.
 * Under the header is a carousel that can swipe between the seven active days,
 * and can be reloaded to show a different week.
 */
@inject('rootStore', 'Linking')
@observer
class LogTab extends Component {
  static contextType = BrandContext;

  handleFadeableViewRef = ref => (this.fadeableView = ref);

  _onPressLink = url => {
    this.props.Linking.openURL(url, { forceHttpOnNoProtocol: true });
  };

  _onPressTracker = trackerId => {
    const { navigation, rootStore, isSidecar } = this.props;
    const { logStore } = rootStore;
    const tracker = logStore.trackerFor(trackerId);

    // determine most recent day for data, navigate to that
    const stats = tracker.statsFor({
      interval: 'day',
      aggregateType: 'latestHistorical',
    });

    const navigationFn = isSidecar ? 'replace' : 'navigate';

    navigation[navigationFn](navigationRoutes.stacks.main.trackerHistory, {
      trackerId,
      date: stats.latestLog ? stats.latestLog.date : getToday(),
      timeframe: tracker.clientMeta.graphConfig.summaryTimeframe,
    });
  };

  _onPressLink = url => {
    this.props.Linking.openURL(url);
  };

  render() {
    const { rootStore, t, onPullToRefresh, isPullToRefreshInProgress, isSidecar } = this.props;
    const { logStore } = rootStore;
    const branding = this.context;

    const theme = getTheme({ rootStore, branding });

    return (
      <View
        style={{
          flex: 1,
          backgroundColor: colors.bg0,
        }}>
        <NavbarWrapper title={t('TABS:LOG')} theme={theme} isSidecar={isSidecar}>
          <ScreenError
            state={rootStore.updateOnDemandAppsState}
            title={'Error syncing data'}
            message={error => {
              if (idx(error, _ => _.additionalClientData.servicesWithErrors.length)) {
                return t('LOG:ERRORS:APP_FAILED_TO_SYNC', {
                  syncedAppName: sourceToDisplaySource(
                    error.additionalClientData.servicesWithErrors[0].name
                  ),
                });
              }
              return t('LOG:ERRORS:APP_FAILED_TO_SYNC_GENERAL');
            }}
            type="messageBar"
            throttleMs={1000 * 60 * 5}
            ignoreNetworkErrors
          />
          <ScreenFirstLoadWrapper
            state={logStore.loadStackHistoryState}
            hasData={logStore.trackers.length}
            errorContainerStyle={{ paddingTop: sizes.large }}
            onPressRefresh={logStore.refreshTodaysLogs}>
            <ScreenError
              state={
                logStore.loadProgramDataState /* tricky! - only happens on subsequent refreshes because it's inside first load wrapper */
              }
              title={t('LOG:ERRORS:REFRESH_FAILED')}
              type="messageBar"
            />
            <FadeWrapper ref={this.handleFadeableViewRef}>
              <TrackersList
                trackers={logStore.trackersSortedByMostRecentEntry(true)}
                onPressTracker={this._onPressTracker}
                onPressLink={this._onPressLink}
                onPullToRefresh={onPullToRefresh}
                isPullToRefreshInProgress={isPullToRefreshInProgress}
              />
            </FadeWrapper>
          </ScreenFirstLoadWrapper>
        </NavbarWrapper>
      </View>
    );
  }
}

LogTab.propTypes = {
  rootStore: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default withScreenBehaviors({
  onFocus: () => {
    rootStore.setActiveFrame('myData');
    rootStore.logStore.refreshTodaysLogs(); // good to refresh this in case of synced data
  },
  onActive: () => {
    // TODO: experimenting with only reloading when app becomes active
  },
  onManualRefresh: async () => {
    await rootStore.logStore.refreshTodaysLogs();
  },
})(withTranslation()(LogTab));
