import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { navigationRoutes } from '../../../config/constants';
import {
  ProfileTabIcon,
  CustomTabIcon,
  AccountTabIcon,
  ProgramTabIcon,
} from '/common/config/icons';
import LogTab from './log-tab';
import ProgramTab from './program-tab';
import CoachTab from './coach-tab';
import ConnectTabBadgeIcon from './coach-tab/ConnectTabBadgeicon';
import MessagesTabBadgeIcon from './coach-tab/MessagesTabBadgeIcon';
import GroupsTab from './groups-tab';
import GroupsTabBadgeIcon from './groups-tab/GroupsTabBadgeIcon';
import SettingsTab from './SettingsTab';
import { shouldForceOnGroupTabs } from '/common/config/feature-support';
import { BrandContext } from '/common/config/branding';
import { rootStore } from '../../../stores/instances';

const useTabBarConfig = () => {
  const branding = useContext(BrandContext);
  const { t } = useTranslation();
  const myDataTabEnabled = branding.tabs.trends.forceOnTab || !!rootStore.enabledData.tracking;
  const programsTabEnabled = !!rootStore.enabledData.programs;
  const groupsTabEnabled =
    shouldForceOnGroupTabs(branding) ||
    (branding.tabs.groups.enabled && rootStore.enabledData.groups);
  const messagesTabEnabled =
    branding.tabs && branding.tabs.messages && branding.tabs.messages.enabled === false
      ? false
      : branding.tabs.connect.forceOnTab || !!rootStore.enabledData.coaches;
  const connectTabEnabled =
    branding.tabs.connect.forceOnTab ||
    rootStore.enabledData.features?.find(features => features.tag === 'CUSTOM_CONNECT_TAB') !==
      undefined;

  // coach tab name
  const coachCount = rootStore.enabledData.coaches;
  let coachTabTitle = branding.tabs.connect.name || t('TABS:BOTTOM_TABS:CONNECT');
  if (coachCount) {
    coachTabTitle = branding.tabs.messages.name || t('TABS:BOTTOM_TABS:MESSAGES');
  }

  const enabledTabs = [];

  if (programsTabEnabled) {
    enabledTabs.push({
      name: navigationRoutes.stacks.main.programsTab,
      component: ProgramTab,
      options: {
        tabBarIcon: props => <ProgramTabIcon {...props} />,
        title: branding.tabs.daily.name || t('TABS:BOTTOM_TABS:PROGRAM'),
      },
      frame: 'programs',
    });
  }

  if (myDataTabEnabled) {
    enabledTabs.push({
      name: navigationRoutes.stacks.main.logTab,
      component: LogTab,
      options: {
        tabBarIcon: props =>
          branding.tabs.trends.image ? (
            <CustomTabIcon source={branding.tabs.trends.image} {...props} />
          ) : (
            <ProfileTabIcon {...props} />
          ),
        title: branding.tabs.trends.name || t('TABS:BOTTOM_TABS:LOG'),
      },
      frame: 'myData',
    });
  }

  // check if whitelabel has Custom No Coach Page enabled and follow logic if does/doesnt
  if (connectTabEnabled) {
    enabledTabs.push({
      name: navigationRoutes.stacks.main.coachTab,
      component: CoachTab,
      options: {
        tabBarIcon: props =>
          messagesTabEnabled ? (
            branding.tabs.messages.enabled ? (
              <MessagesTabBadgeIcon {...props} />
            ) : null
          ) : (
            <ConnectTabBadgeIcon {...props} />
          ),
        title: coachTabTitle,
      },
      frame: 'coach',
    });
  } else {
    if (messagesTabEnabled)
      enabledTabs.push({
        name: navigationRoutes.stacks.main.coachTab,
        component: CoachTab,
        options: {
          tabBarIcon: props => <MessagesTabBadgeIcon {...props} />,
          title: coachTabTitle,
        },
        frame: 'coach',
      });
  }

  if (groupsTabEnabled) {
    enabledTabs.push({
      name: navigationRoutes.stacks.main.groupsTab,
      component: GroupsTab,
      options: {
        tabBarIcon: props => <GroupsTabBadgeIcon {...props} />,
        title: branding.tabs.groups.name || t('TABS:BOTTOM_TABS:GROUPS'),
      },
      frame: 'groups',
    });
  }

  enabledTabs.push({
    name: navigationRoutes.stacks.main.settingsTab,
    component: SettingsTab,
    options: {
      tabBarIcon: props => <AccountTabIcon {...props} />,
      title: t('TABS:BOTTOM_TABS:ACCOUNT'),
    },
    frame: 'settings',
  });

  return enabledTabs;
};

export default useTabBarConfig;
