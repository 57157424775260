// apply overrides to the brand config
// these can be done OTA and don't require a recompile of the binary because they are part of the app
// the output props must match the format in getBrandingDefaults()
function getOtaBrandOverrides(brandProps) {
  // it would be preferable to use brandKey for all of these, but brandKey doesn't exist yet
  // in production brand bundles

  // override Nudge app store name and sync properties
  // the latter prevents the sync prompt during sign up
  /*if (brandProps.bundleConfig.subDomain === 'nudge') {
    return {
      appName: 'Nudge',
      sync: { appsEnabled: true, bluetoothDevicesEnabled: false },
      signUp: { showConnectedAppsStep: false },
      assets: {
        clubThumb: null,
      }
    };
  }*/

  // dark theme color fix
  // these used to be done with nudge score backgrounds, but then we removed those and forgot to put them back for these three
  if (brandProps.bundleConfig.subDomain === 'proformance') {
    return {
      darkThemeBackground: '#58D74F',
    };
  }

  if (brandProps.bundleConfig.subDomain === 'sano') {
    return {
      darkThemeBackground: '#38414A',
    };
  }

  if (brandProps.bundleConfig.subDomain === 'vitalityhealth') {
    return {
      darkThemeBackground: '#009BB5',
    };
  }

  // I'd rather use brandKey, I thought we fixed it a while ago, but it doesn't show up in the Expo app...
  // We need to test this more
  if (brandProps.bundleConfig.subDomain === 'pushme') {
    return {
      signUp: {
        showTutorial: false,
      },
    };
  }

  // cheesy way of applying something to only the test build
  /*if (brandProps.brandKey === 'nudge' && brandProps.belowIconName === 'LABGREEN') {
    return {
      signUp: {
        showClientUserIdStep: true,
      },
      settings: {
        showClientUserId: true,
      }
    }
  }*/

  // I think we fixed brandKey now!
  if (brandProps.bundleConfig.brandKey === 'swiftconnect') {
    return {
      settings: {
        supportEmailAddress: 'swiftconnect@nudgecoach.com',
      },
    };
  }

  return {};
}

export default getOtaBrandOverrides;
