import * as Updates from 'expo-updates';
import { Platform, Alert } from 'react-native';

export default class OtaUpdateListener {
  // private vars
  _listener;
  _currentStatus;
  _forceOtaUpdateWhenAvailable = false;

  constructor({ forceOtaUpdateWhenAvailable }) {
    this._forceOtaUpdateWhenAvailable = forceOtaUpdateWhenAvailable;
  }

  // override this to receive events
  onOtaUpdateEventReceived = () => {};

  _onOtaUpdateEventReceivedInner(payload) {
    this._currentStatus = payload.status;
    this.onOtaUpdateEventReceived(payload);
    // if labgreen, etc., force the tester to update
    if (
      this._forceOtaUpdateWhenAvailable &&
      payload.status === Updates.UpdateEventType.UPDATE_AVAILABLE
    ) {
      Alert.alert('OTA update ready', 'The update will be applied immediately', [
        {
          text: 'OK',
          onPress: () => {
            Updates.reloadAsync();
          },
        },
      ]);
    }
  }

  startListening() {
    this._listener = Updates.addListener(event => {
      this._onOtaUpdateEventReceivedInner({
        status: event.type,
        errorMessage: event.message,
      });
    });
    if (Platform.OS === 'android') {
      this.forceCheckAndUpdate(); // doesn't seem to register the initial event on android, so let's add an extra check
      // also missing "app is up to date! event on iOS, so maybe need this too"
    }
  }

  stopListening() {
    if (this._listener) {
      this._listener.remove();
    }
  }

  /**
   * Reload if we've previously received confirmation that an update is availabe.
   * Otherwise, do nothing.
   */
  async reloadIfUpdateAvailable() {
    if (this._currentStatus === Updates.UpdateEventType.UPDATE_AVAILABLE) {
      Updates.reloadAsync();
    }
  }

  async forceCheckAndUpdate() {
    try {
      const result = await Updates.checkForUpdateAsync();
      if (result.isAvailable) {
        const fetchResult = await Updates.fetchUpdateAsync();
        if (fetchResult.isNew) {
          // manual check doesn't seem to call event listener, so we update our status here
          this._onOtaUpdateEventReceivedInner({
            status: Updates.UpdateEventType.UPDATE_AVAILABLE,
          });
        } else {
          this._onOtaUpdateEventReceivedInner({
            status: Updates.UpdateEventType.NO_UPDATE_AVAILABLE,
          });
        }
      } else {
        this._onOtaUpdateEventReceivedInner({
          status: Updates.UpdateEventType.NO_UPDATE_AVAILABLE,
        });
      }
    } catch (error) {
      this._onOtaUpdateEventReceivedInner({
        status: Updates.UpdateEventType.ERROR,
        errorMessage: error.message,
      });
    }
  }
}
