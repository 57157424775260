import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import {
  NudgeFlatList as FlatList,
  AestheticallyPleasingListFooter,
  SeparatorLine,
} from '/common/components';
import ConversationSummary from './ConversationSummary';
import { tableStyles } from '../../config/common-styles';

@observer
export default class ConversationSummaryList extends Component {
  _renderItem = ({ item }) => {
    return (
      <ConversationSummary
        conversationSummary={item}
        onPress={() => this.props.onPressConversationSummary(item.id)}
      />
    );
  };
  render() {
    const { conversationSummaries, isRefreshing, onRefresh } = this.props;
    return (
      <FlatList
        style={[tableStyles.table]}
        data={conversationSummaries}
        refreshing={isRefreshing}
        onRefresh={onRefresh}
        keyExtractor={i => i.id.toString()}
        renderItem={this._renderItem}
        ItemSeparatorComponent={SeparatorLine}
        ListFooterComponent={AestheticallyPleasingListFooter}
      />
    );
  }
}

ConversationSummaryList.propTypes = {
  conversationSummaries: PropTypes.arrayOf(PropTypes.object).isRequired,
  isRefreshing: PropTypes.bool,
  onPressConversationSummary: PropTypes.func.isRequired,
  onRefresh: PropTypes.func,
};

ConversationSummaryList.defaultProps = {
  isRefreshing: false,
  onRefresh: null,
};
