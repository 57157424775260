import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { View, SafeAreaView } from 'react-native';
import { getFocusedRouteNameFromRoute, useNavigation, useRoute } from '@react-navigation/native';
//import { spy } from 'mobx';
import { observer } from 'mobx-react';
import { ProgramFeedSegmentedControl } from '/common/components/program';
import { sizes, colors, useDeviceDimensions } from '/common/config/styles';
import { navigationRoutes } from '../../../../config/constants';

const getCurrentTabRoute = route => {
  return getFocusedRouteNameFromRoute(route) || navigationRoutes.stacks.main.feedSubTab;
};

const ProgramTopTabs = observer(({ forceSelectedOption }) => {
  const navigation = useNavigation();
  const route = useRoute();
  const { statusBarHeight } = useDeviceDimensions();

  const onChooseOption = useCallback(
    option => {
      if (option === 0) {
        navigation.navigate(
          navigationRoutes.stacks.main.feedSubTab,
          route.params /* copying this seems to fix an issue with the top tab bar dissappearing after switching from feed to tabs and back that shows up with React Navigation 6*/
        );
      }
      if (option === 1) {
        navigation.navigate(navigationRoutes.stacks.main.tagsSubTab, route.params);
      }
    },
    [navigation, route]
  );
  return (
    <SafeAreaView
      style={{
        paddingTop: statusBarHeight,
        backgroundColor: colors.bg1,
        borderBottomWidth: sizes.hairlineWidth,
        borderColor: colors.border1,
      }}>
      <View
        style={{
          height: sizes.navbarHeight,
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <ProgramFeedSegmentedControl
          selectedOption={
            forceSelectedOption ||
            (getCurrentTabRoute(route) === navigationRoutes.stacks.main.feedSubTab ? 0 : 1)
          }
          onChooseOption={onChooseOption}
        />
      </View>
    </SafeAreaView>
  );
});

ProgramTopTabs.propTypes = {
  forceSelectedOption: PropTypes.number, // if set, always set option to a particular number in the sequence (good for copying the tab to multiple routes)
};

ProgramTopTabs.defaultProps = {
  forceSelectedOption: 0,
};

export default ProgramTopTabs;
