import PropTypes from 'prop-types';
import React from 'react';
import { observer } from 'mobx-react/native';
import { StyleSheet, Text, View } from 'react-native';
import moment from 'moment';

/**
 * Get the date and time if before today and just the time if it's today
 * @param {*} date
 */
const getChatDate = date => {
  let formattedDate = '';

  const today = moment();
  const messageMoment = moment(date);

  if (messageMoment.isSame(today, 'd')) {
    formattedDate = messageMoment.format('LT');
  } else {
    formattedDate = messageMoment.format('M/D/YYYY LT');
  }

  return formattedDate;
};

@observer
export default class Day extends React.Component {
  render() {
    const { currentMessage, previousMessageCreatedAt } = this.props;
    const currentMoment = moment(currentMessage.createdAt);
    const previousMoment = moment(previousMessageCreatedAt);
    const now = new Date();

    if (
      (currentMoment.isSame(now, 'd') &&
        currentMoment.isAfter(previousMoment.add(15, 'minutes'))) ||
      !currentMoment.isSame(previousMoment, 'd')
    ) {
      return (
        <View style={[styles.container, this.props.containerStyle]}>
          <View style={[styles.wrapper, this.props.wrapperStyle]}>
            <Text style={[styles.text, this.props.textStyle]}>
              {getChatDate(currentMessage.createdAt).toUpperCase()}
            </Text>
          </View>
        </View>
      );
    }
    return null;
  }
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 5,
    marginBottom: 10,
  },
  text: {
    backgroundColor: 'transparent',
  },
});

Day.contextTypes = {
  getLocale: PropTypes.func,
};

Day.defaultProps = {
  currentMessage: {
    // TODO test if crash when createdAt === null
    createdAt: null,
  },
  containerStyle: {},
  wrapperStyle: {},
  textStyle: {},
};

Day.propTypes = {
  currentMessage: PropTypes.object,
  previousMessageCreatedAt: PropTypes.any,
  containerStyle: PropTypes.any,
  wrapperStyle: PropTypes.any,
  textStyle: PropTypes.any,
};
