import React, { Component } from 'react';
import { View } from 'react-native';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { getStandardErrorMessage } from '../lib/errors';
import RefreshWarningBar from './RefreshWarningBar';
import { useMediaQueryInfo } from '/common/config/styles';

const WrapWithNavbarIfNeeded = observer(
  ({ NavbarWrapperComponent, navbarWrapperProps, hideOnWebWideScreen = false, children }) => {
    const { isWideScreen } = useMediaQueryInfo();
    const shouldHide = isWideScreen && hideOnWebWideScreen;
    if (NavbarWrapperComponent && !shouldHide) {
      return <NavbarWrapperComponent {...navbarWrapperProps}>{children}</NavbarWrapperComponent>;
    }
    return <View style={{ flex: 1 }}>{children}</View>;
  }
);

/**
 * Shows a spinner for first load and optionally an error replacing the screen if that load fails.
 * Optionally can also show an additional error if subsequent data loads fail without hiding the main
 * view. The hasData prop makes this component sensitive to whether a load failure occurs on the screen
 * with or without data loaded, so it can choose not to hide data already loaded.
 */
@observer
class ScreenFirstLoadWrapper extends Component {
  render() {
    const {
      children,
      state,
      hasData,
      onPressRefresh,
      errorTitle,
      errorMessage,
      networkErrorMessage,
      serverUnavailableErrorMessage,
      actionMessage,
      errorContainerStyle,
      LoadingFailedPlaceholderComponent,
      LoadingWrapperComponent,
      // ability to include navbar wrapper inside first load wrapper so it can be hidden on web
      NavbarWrapperComponent,
      navbarWrapperProps,
      style,
      showRefreshFailedErrorOnSubsequentLoads,
    } = this.props;

    if (state.isFailed && !hasData) {
      let finalErrorMessage = getStandardErrorMessage({
        error: state.error,
        serverUnavailableErrorMessage,
        networkErrorMessage,
        defaultErrorMessage: errorMessage,
      });
      return (
        <WrapWithNavbarIfNeeded
          NavbarWrapperComponent={NavbarWrapperComponent}
          navbarWrapperProps={navbarWrapperProps}>
          <View style={[{ flex: 1 }, style]}>
            <LoadingFailedPlaceholderComponent
              onPressRefresh={onPressRefresh}
              title={errorTitle}
              message={finalErrorMessage}
              actionMessage={actionMessage}
              containerStyle={errorContainerStyle}
            />
          </View>
        </WrapWithNavbarIfNeeded>
      );
    }

    // children may flash briefly if we don't also show this view before loading is started,
    // As pending is often kicked off based on a didFocus nav event, which fires just a wee bit
    // later.
    // very occasionally something may have data prior to its first load- for instance, if we carry over a cached object
    // (group message boards do this)
    if (state.isPendingFirstLoad && !hasData) {
      return (
        <WrapWithNavbarIfNeeded
          hideOnWebWideScreen
          NavbarWrapperComponent={NavbarWrapperComponent}
          navbarWrapperProps={navbarWrapperProps}>
          <LoadingWrapperComponent style={[{ flex: 1 }, style]} isLoading />
        </WrapWithNavbarIfNeeded>
      );
    }

    // Attempt to fix a strange error where this screen sometimes never gets out of loading state
    // If it was cleared and reloaded at an inopportune time.
    // The theory is that an explicit ref to isDone (which always flips from false to true)
    // may provide the hint that mobx needs to leave this screen
    // NOPE: seems like this was an issue with withNavigationFocus instead
    /*if (state.isDone) {
      return <View style={{ flex: 1 }}>{children}</View>;
    }*/

    let refreshWarning;

    if (showRefreshFailedErrorOnSubsequentLoads) {
      refreshWarning = (
        <RefreshWarningBar
          loadingState={state}
          errorTitle={errorTitle}
          onPressRefresh={onPressRefresh}
          actionMessage={actionMessage}
          errorMessage={errorMessage}
          networkErrorMessage={networkErrorMessage}
          serverUnavailableErrorMessage={serverUnavailableErrorMessage}
        />
      );
    }

    return (
      <WrapWithNavbarIfNeeded
        NavbarWrapperComponent={NavbarWrapperComponent}
        navbarWrapperProps={navbarWrapperProps}>
        <View style={[{ flex: 1 }, style]}>
          {children}
          {refreshWarning}
        </View>
      </WrapWithNavbarIfNeeded>
    );
  }
}

ScreenFirstLoadWrapper.propTypes = {
  state: PropTypes.shape({
    isFailed: PropTypes.bool,
    isDone: PropTypes.bool,
    isPendingFirstLoad: PropTypes.bool,
  }),
  onPressRefresh: PropTypes.func,
  actionMessage: PropTypes.string.isRequired,
  errorTitle: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  networkErrorMessage: PropTypes.string.isRequired,
  serverUnavailableErrorMessage: PropTypes.string.isRequired,
  hasData: PropTypes.any,
  children: PropTypes.any,
  errorContainerStyle: PropTypes.any,
  LoadingFailedPlaceholderComponent: PropTypes.any.isRequired,
  LoadingWrapperComponent: PropTypes.any.isRequired,
  showRefreshFailedErrorOnSubsequentLoads: PropTypes.bool,
};

ScreenFirstLoadWrapper.defaultProps = {
  state: null,
  hasData: false,
  children: null,
  onPressRefresh: null,
  errorContainerStyle: null,
  showRefreshFailedErrorOnSubsequentLoads: false,
};

export default ScreenFirstLoadWrapper;
