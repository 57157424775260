import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity, Text } from 'react-native';
import { observer } from 'mobx-react';
import { navigationStyles, textStyles } from '../../config/common-styles';
import { BrandContext } from '../../config/branding';

@observer
class HeaderTextButton extends Component {
  static contextType = BrandContext;

  render() {
    const branding = this.context;

    return (
      <TouchableOpacity
        style={navigationStyles.navbarButton}
        disabled={this.props.disabled}
        onPress={this.props.onPress}>
        <Text
          style={[
            textStyles.standard.semibold,
            { color: branding.highlightedColor },
            this.props.textStyle,
          ]}>
          {this.props.text}
        </Text>
      </TouchableOpacity>
    );
  }
}

HeaderTextButton.propTypes = {
  disabled: PropTypes.bool,
  onPress: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  textStyle: PropTypes.any,
};

HeaderTextButton.defaultProps = {
  disabled: false,
  textStyle: null,
};

export default HeaderTextButton;
