import React, { Component } from 'react';
import { View, Platform } from 'react-native';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import numeral from 'numeral';
import { colord } from 'colord';
import { last } from 'lodash';
import {
  DurationGraph,
  BarGraph,
  CaloriesGraph,
  LineGraph,
  MinMaxBarGraph,
  FreeformQuestionTrackerGraph,
  MultipleChoiceQuestionTrackerGraph,
  MultiLineGraph,
  ExerboticsGraph,
} from 'nudge-client-common/graphs';
import { getUniversalSvgComponents } from 'nudge-client-common/common-svg';
import { sizes, colors, fonts } from '/common/config/styles';
import { unitsForValueAndUom } from '/common/config/strings';
import { BrandContext } from '/common/config/branding';

@observer
class GraphData extends Component {
  static contextType = BrandContext;
  render() {
    const props = this.props;
    const branding = this.context;
    const additionalHorizontalPadding = props.additionalHorizontalPadding || 0;
    const netWidth = props.width - additionalHorizontalPadding * 2;

    // *** determine which graph component to use ***
    let GraphComponent;
    let additionalProps = {};
    // most benefit from the extra space, except those with spaces in them (blood pressure/ glucose)
    let spacePerTooltipCharacter = 13;
    const { tracker, isSummary, useMetricUnits, t } = props;
    const trackerColor = branding.trackerColors[tracker.palettesId];

    const { clientMeta } = tracker;
    const metadata = clientMeta;

    const counterTrackerFormatToolTipValue = value => numeral(value).format('0,0.[0]');
    const additionalPropsForCounterTrackers = {
      formatToolTipValue: counterTrackerFormatToolTipValue,
      formatToolTipUnits: value =>
        `${unitsForValueAndUom(value, tracker.primaryFieldUnits({ useMetricUnits }))}`,
    };

    if (!tracker) {
      GraphComponent = () => <View />;
    } else if (metadata.type === 'water') {
      GraphComponent = BarGraph;
      additionalProps = additionalPropsForCounterTrackers;
    } else if (metadata.type === 'pedometer') {
      GraphComponent = BarGraph;
      additionalProps = {
        formatToolTipValue: val => numeral(val).format('0,0'),
        formatYAxisValue: val => numeral(val).format('0a'),
        getToolTipValueWidth: val => numeral(val).format('0,0').length * spacePerTooltipCharacter,
      };
    } else if (metadata.type === 'number') {
      GraphComponent = BarGraph;
    } else if (metadata.type === 'cardio') {
      GraphComponent = BarGraph;
      additionalProps = {
        formatToolTipValue: seconds => numeral(seconds / 60).format('0,0') + ' m',
        formatYAxisValue: seconds => numeral(seconds / 60).format('0,0'),
      };
    } else if (metadata.type === 'calories') {
      GraphComponent = CaloriesGraph;
      additionalProps = {
        caloriesColor: tracker.palettesId
          ? branding.trackerColors[tracker.palettesId]
          : colors.darkText,
        carbsColor: branding.trackerColors['graphs-calories-carbs'],
        fatsColor: branding.trackerColors['graphs-calories-fats'],
        proteinsColor: branding.trackerColors['graphs-calories-proteins'],
        target: tracker.clientMeta.graphConfig.greenTarget,
        workaroundClipPathBug: Platform.OS === 'android',
      };
    } else if (metadata.type === 'blood-glucose') {
      GraphComponent = MinMaxBarGraph;
      spacePerTooltipCharacter = 11;
      additionalProps = {
        yAxisLineProps: {
          axisMarkerIndent: sizes.small,
        },
      };
    } else if (metadata.type === 'body-fat') {
      additionalProps = {
        bottomCaptionText: isSummary && !Platform.isPad ? t('TRENDS:SUMMARY:LAST_30_DAYS') : null,
        formatToolTipValue: val => numeral(val * 100).format('0[.]0[0]'),
        formatToolTipUnits: () => '%',
        formatYAxisValue: val => numeral(val * 100).format('0') + '%',
        minDelta: 0.03,
      };
      GraphComponent = LineGraph;
    } else if (metadata.type === 'weight') {
      GraphComponent = LineGraph;
      additionalProps = {
        bottomCaptionText: isSummary && !Platform.isPad ? t('TRENDS:SUMMARY:LAST_30_DAYS') : null,
        minDelta: 20,
        ...additionalPropsForCounterTrackers,
        ...(useMetricUnits
          ? {
              formatToolTipValue: value => numeral(value * 0.453592).format('0,0.[0]'),
              formatYAxisValue: val => numeral(val * 0.453592).format('0,0'),
              formatToolTipUnits: value =>
                unitsForValueAndUom(value, tracker.primaryFieldUnits({ useMetricUnits })),
            }
          : null),
      };
    } else if (metadata.type === 'sleep' || metadata.type === 'custom-duration') {
      GraphComponent = DurationGraph;
    } else if (metadata.type === 'custom-counter') {
      GraphComponent = BarGraph;
      additionalProps = additionalPropsForCounterTrackers;
    } else if (metadata.type === 'custom-question-freeform') {
      GraphComponent = FreeformQuestionTrackerGraph;
      additionalProps = {
        trackerColor: props.color,
        noAnswerColor: colors.bg2,
      };
    } else if (metadata.type === 'custom-question-multiple-choice') {
      GraphComponent = MultipleChoiceQuestionTrackerGraph;
      additionalProps = {
        noAnswerColor: colors.bg2,
        trackerColor: props.color,
        possibleAnswers: tracker.options,
        pieGraphCircleStrokeWidth: props.showDetailView ? 14 : 8,
        // needs more space on Android in particular
        pieGraphDiameter: props.showDetailView
          ? netWidth / (Platform.OS === 'ios' ? 5 : 5.5)
          : undefined,
      };
    } else if (metadata.type === 'servings') {
      GraphComponent = BarGraph;
      additionalProps = additionalPropsForCounterTrackers;
    } else if (metadata.type === 'blood-pressure') {
      GraphComponent = MultiLineGraph;
      spacePerTooltipCharacter = 11;
      additionalProps = {
        lineDefinitions: tracker.clientMeta.graphConfig.lineDefinitions.map(ld => ({
          ...ld,
          color: branding.trackerColors[`trackers-standard-blood-pressure`],
        })),
      };
    } else if (metadata.type === 'heart-rate') {
      GraphComponent = MultiLineGraph;
      additionalProps = {
        yAxisLineProps: { reverseLabels: true, bumpPixels: 1.5, axisMarkerIndent: sizes.small },
        lineDefinitions: tracker.clientMeta.graphConfig.lineDefinitions.map(ld => ({
          ...ld,
          color: branding.trackerColors[`trackers-standard-heart-rate`],
        })),
        graphLeftOffset: 30,
        graphRightOffset: 30,
      };
    } else if (metadata.type === 'exerbotics') {
      GraphComponent = ExerboticsGraph;
      additionalProps = {
        baselineColor: colord(trackerColor)
          .lighten(0.2)
          .toHex(),
        overBaselineColor: colord(trackerColor)
          .lighten(0.1)
          .toHex(),
        selectionBoxFillColor: 'black',
        baseline: tracker.logs.length ? last(tracker.logs).baseline : 0, // should only be one value across all logs
        graphLeftOffset: 30,
        graphRightOffset: 30,
      };
    } else if (
      ['total-revenue', 'total-gross-profit', 'total-gross-profit-margin', 'net-income'].includes(
        metadata.type
      )
    ) {
      GraphComponent = BarGraph;
      additionalProps = {
        xAxisLabelType: 'none',
        formatYAxisValue:
          metadata.type === 'total-gross-profit-margin'
            ? value => numeral(value * 100).format('0') + '%'
            : value =>
                value > 1000
                  ? `${numeral(value / 1000).format('$0,0')}k`
                  : numeral(value).format('$0,0'),
      };
    } else if (metadata.type === 'cash-flow') {
      GraphComponent = LineGraph;
      additionalProps = {
        xAxisLabelType: 'none',
        formatYAxisValue: value =>
          value > 1000 ? `${numeral(value / 1000).format('$0,0')}k` : numeral(value).format('$0,0'),
      };
    } else {
      // user should never see this if we properly filtered out invalid graphs in GraphSummary
      GraphComponent = () => <View />;
    }

    // generic tooltip size approximization if none else exists
    if (!additionalProps.getToolTipValueWidth) {
      additionalProps.getToolTipValueWidth = value =>
        GraphComponent.defaultProps.formatToolTipValue(value).length * spacePerTooltipCharacter;
    }

    additionalProps.axisMarkerFontSize = fonts.sizes.small;
    additionalProps.axisMarkerTextColor = colors.lightText;
    additionalProps.xAxisMarkerTextColor = colors.darkText;
    additionalProps.graphLeftMinOffset = 30;
    additionalProps.graphRightMinOffset = 30;
    additionalProps = {
      xAxisLabelType: props.timeframe === 'week' ? 'weekdays' : 'none',
      paddingLeft: sizes.medium * (Platform.isPad ? 5 : 2.3),
      paddingRight: sizes.medium * (Platform.isPad ? 5 : 2.3),
      paddingBottom: sizes.medium,
      paddingTop: sizes.medium,
      selectionBoxFillColor: colors.bg1,
      selectionBoxStrokeColor: colors.bg1,
      selectionBoxCornerRadius: sizes.borderRadius,
      showMidpointLines: true,
      yAxisLineProps: { bumpPixels: 1.5, axisMarkerIndent: sizes.small }, // graph line is off-center from axis lines, just on mobile, not sure why
      ...additionalProps,
    };

    return (
      <View
        style={{
          alignItems: 'center',
          paddingHorizontal: additionalHorizontalPadding,
        }}>
        <GraphComponent
          {...props}
          width={netWidth}
          svg={getUniversalSvgComponents(Platform.OS)}
          xAxisLabelSpace={sizes.large}
          {...additionalProps}
        />
      </View>
    );
  }
}

GraphData.displayName = 'GraphData';

// NOTE: iPad optimizations are directly in TrackerGraphDetail / SummaryGraphCell

export default withTranslation()(GraphData);
