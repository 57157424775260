import React, { useCallback } from 'react';
import { View, Text, Image } from 'react-native';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import {
  EmptyListPlaceholder,
  RowChevronButton,
  ListSeparatorLine,
  NudgeFlatList as FlatList,
  AestheticallyPleasingListFooter,
} from '/common/components';
//import { BrandContext } from '../../config/branding';
import {
  colors,
  sizes,
  containerStyles,
  textStyles,
  useMediaQueryInfo,
} from '/common/config/styles';

const buildTagComponent = title => () => (
  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
    <Image
      source={require('../../assets/icons/tag.png')}
      style={{ height: 18, width: 18, marginRight: sizes.medium }}
    />
    <Text style={[textStyles.standard.dark]}>{title}</Text>
  </View>
);

const Tag = observer(({ tag, onPressTag }) => {
  const onPress = useCallback(() => {
    onPressTag(tag);
  }, [tag, onPressTag]);
  return (
    <RowChevronButton
      style={{ paddingVertical: sizes.large }}
      onPress={onPress}
      LabelComponent={buildTagComponent(tag.name)}
    />
  );
});

const TagsList = observer(
  ({
    onPressTag,
    tags,
    TabBarComponent,
    FirstLoadComponent,
    isPullToRefreshInProgress,
    onPullToRefresh,
    onLoadAdditionalItems,
  }) => {
    const { t } = useTranslation();

    const { isWideScreen } = useMediaQueryInfo();

    // *** callbacks ***

    const renderItem = useCallback(
      ({ item }) => {
        return <Tag tag={item} onPressTag={onPressTag} />;
      },
      [onPressTag]
    );

    const keyExtractor = useCallback(item => item.toString());

    // *** end callbacks ***

    // *** memoized grouped items ***

    // NOTE: using default section list behavior of stickySectionHeadersEnabled for iOS, but not Android
    // This is because the android animation gets real weird once you scroll down the list
    // The other option is to keep sticky section headers for all but disable the animation on Android

    return (
      <View
        style={[
          containerStyles.fillAvailableSpace,
          { backgroundColor: colors.bg1, paddingTop: 0 },
        ]}>
        {TabBarComponent}
        <FirstLoadComponent>
          <FlatList
            contentContainerStyle={{ flexGrow: 1 }}
            data={tags}
            keyExtractor={keyExtractor}
            renderItem={renderItem}
            onEndReached={onLoadAdditionalItems}
            onEndReachedThreshold={0.9}
            ListEmptyComponent={
              <EmptyListPlaceholder
                title={t('PROGRAMS:EMPTY_FEED:TITLE')}
                message={t('PROGRAMS:EMPTY_FEED:MESSAGE')}
              />
            }
            ListHeaderComponent={
              isWideScreen ? (
                <View
                  style={{
                    marginTop: sizes.webInnerViewMargin, // need to simulate navbar header web changes due to tab bar
                    height: sizes.webBorderRadius,
                    borderTopLeftRadius: sizes.webBorderRadius,
                    borderTopRightRadius: sizes.webBorderRadius,
                    backgroundColor: colors.bg0,
                  }}
                />
              ) : null
            }
            ListFooterComponent={AestheticallyPleasingListFooter}
            ItemSeparatorComponent={ListSeparatorLine}
            refreshing={isPullToRefreshInProgress}
            onRefresh={onPullToRefresh}
          />
        </FirstLoadComponent>
      </View>
    );
  }
);

TagsList.propTypes = {};

export default TagsList;
