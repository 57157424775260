import { types } from 'mobx-state-tree';
import Comment from './Comment';

const Club = types
  .model('Club', {
    id: types.identifierNumber,
    title: types.string,
    isLeaderboardJoined: types.optional(types.boolean, false),
    leaderboardsId: types.maybeNull(types.number),
    archivedLeaderboardId: types.maybeNull(types.number),
    isLeaderboardPublic: types.optional(types.boolean, false),
    thumbLogoImageSource: types.frozen(),
    recentComments: types.optional(types.array(Comment), []),
    recentCommentsNotAvailable: types.optional(types.boolean, false),
    forumsUploadsEnabled: types.optional(types.boolean, false),
    unreadComments: types.optional(types.number, 0),
    notificationLevel: types.frozen(),
  })
  .views(self => ({
    get hasLeaderboard() {
      return !!self.leaderboardsId;
    },
    get hasArchivedLeaderboard() {
      return !!self.archivedLeaderboardId;
    },
    get recentCommentsSorted() {
      //return sortBy(self.replies.slice(), c => c.createdAt);
      // sorting by default because something is messed up with timestamps
      return self.recentComments.slice();
    },
  }));

export default Club;
