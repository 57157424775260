import { Platform } from 'react-native';
import {
  ValidicBluetoothEvents,
  NativeEventEmitter,
  ValidicBluetooth,
} from 'react-native-validic-bluetooth';
import idx from 'idx';
import AsyncStorage from '@react-native-async-storage/async-storage';

const PairedPeripheralsKey = 'pairedPeripherals';
const ValidicBluetoothSessionInfoKey = 'validicBluetoothSession';

function setErrorReportingScope({ scope, eventType, event }) {
  scope.setFingerprint(['{{ default }}', eventType]);
  scope.setTag('validicEvent', eventType);
  scope.setTag('validicErrorCode', event.errorCode);
  scope.setTag('peripheralId', event.peripheralID);
}

function tryParseJson(str) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return false;
  }
}

export default class BluetoothSyncRepository {
  // stash info after request for logging
  _validicUserDetails;

  // created after init
  _validicEventEmitter;
  _nativeEventEmitter;

  // dependencies
  _getBranding;
  _errorReporter;
  _apiV5;

  constructor({ errorReporter, getBranding, apiV5 }) {
    this._errorReporter = errorReporter;
    this._getBranding = getBranding;
    this._apiV5 = apiV5;

    if (ValidicBluetooth) {
      this._nativeEventEmitter = new NativeEventEmitter(ValidicBluetooth);
      this._nativeEventEmitter.addListener(
        ValidicBluetoothEvents.onBluetoothPeripheralDidPair,
        event => {
          if (event && event.hasOwnProperty('peripheralID')) {
            (async () => {
              await this.setPeripheralIsPaired(event.peripheralID);
            })();
          }
        }
      );

      this._nativeEventEmitter.addListener(ValidicBluetoothEvents.onVerboseLog, event => {
        if (!this) {
          console.log('this is undefined');
        }
        console.log('Validic verbose log:');
        console.log(event);
        let finalMessage = event.message;
        const messageIfJson = tryParseJson(finalMessage);
        if (messageIfJson) {
          if (idx(messageIfJson, _ => _.weight.weight)) {
            messageIfJson.weight.weight = '[redacted]';
          }
          if (idx(messageIfJson, _ => _.biometrics.systolic)) {
            messageIfJson.biometrics.systolic = '[redacted]';
          }
          if (idx(messageIfJson, _ => _.biometrics.diastolic)) {
            messageIfJson.biometrics.diastolic = '[redacted]';
          }
          if (idx(messageIfJson, _ => _.biometrics.resting_heartrate)) {
            messageIfJson.biometrics.diastolic = '[redacted]';
          }
          finalMessage = JSON.stringify(messageIfJson);
          console.log('successfully redacted biometrics');
        }
        errorReporter.addBreadcrumb({
          category: `validicLog:${event.sdkTag}`,
          message: finalMessage,
          level: errorReporter.Severity.Debug,
        });
      });

      // Add Sentry error events for debugging. Could add more...

      this._nativeEventEmitter.addListener(
        ValidicBluetoothEvents.onBluetoothPeripheralDidNotPair,
        event => {
          errorReporter.withScope(scope => {
            setErrorReportingScope({
              scope,
              eventType: ValidicBluetoothEvents.onBluetoothPeripheralDidNotPair,
              event,
            });
            errorReporter.captureException(new Error(event.error));
          });
        }
      );

      this._nativeEventEmitter.addListener(
        ValidicBluetoothEvents.onBluetoothPassiveDidFail,
        event => {
          errorReporter.withScope(scope => {
            setErrorReportingScope({
              scope,
              eventType: ValidicBluetoothEvents.onBluetoothPassiveDidFail,
              event,
            });
            errorReporter.captureException(new Error(event.error));
          });
        }
      );
    }
  }

  startSession = async () => {
    console.log('start validic session');
    if (ValidicBluetooth) {
      const result = await ValidicBluetooth.isSessionActive();
      console.log(`bluetooth session is isActive is ${result.isActive}`);
      this._errorReporter.addBreadcrumb({
        category: `nudgeValidicBluetoothInfo`,
        message: result.isActive
          ? 'Validic Session is already active, not restarting'
          : 'restarting Validic session',
        level: this._errorReporter.Severity.Debug,
      });
      // always get validic user details so they can be stashed for error handling
      const validicDetails = await this.getValidicDetails();
      if (!result.isActive) {
        console.log('starting new validic session');
        console.log(validicDetails);
        await ValidicBluetooth.startSession(
          validicDetails.externalUsersId,
          validicDetails.serviceClientId,
          validicDetails.accessToken
        );
        // store validic details so we can detect a change of user on login later
        await AsyncStorage.setItem(ValidicBluetoothSessionInfoKey, JSON.stringify(validicDetails));
      }
    }

    if (!ValidicBluetooth) {
      console.warn(
        'WARNING: Not using react-native-validic bluetooth! This is OK if you are running in the Expo client. Very bad if not!'
      );
    }
  };

  // only called when user explicitly logs out
  async endSession() {
    if (ValidicBluetooth) {
      await ValidicBluetooth.stopPassiveReading(); // remove all devices from pairing list
      await ValidicBluetooth.endSession(); // end the validic session
      console.log('end validic bluetooth session');
    }
  }

  getValidicDetails = async () => {
    const response = await this._apiV5.get('users/me/integrations/validic');
    const details = response.data;
    // set these so they are on all Sentry errors
    this._errorReporter.setTag('validicUserId', details.external_users_id);
    this._errorReporter.setTag('validicOrgId', details.service_client_id);
    return {
      externalUsersId: details.external_users_id,
      serviceClientId: details.service_client_id,
      accessToken: details.access_token,
      isEnabled: details.enabled,
    };
  };

  async resumeBluetoothServices() {
    if (ValidicBluetooth) {
      if (Platform.OS === 'android') {
        ValidicBluetooth.startAndroidBackgroundNotification();
      }
      await this.startBluetoothPassiveReading();
    }
  }

  async startBluetoothPassiveReading() {
    const pairedIds = await this.getPairedPeripheralIds();
    if (ValidicBluetooth) {
      console.log('STARTING PASSIVE BLUETOOTH READING FOR PERIPHERAL IDS', pairedIds);
      try {
        await ValidicBluetooth.startPassiveReading(pairedIds);
      } catch (e) {
        console.log(e);
      }
    }
  }

  async stopBluetoothPassiveReading() {
    if (ValidicBluetooth) {
      await ValidicBluetooth.stopPassiveReading();
    }
  }

  async setPeripheralIsPaired(peripheralId) {
    console.log('set paired!');
    let devices = await AsyncStorage.getItem(PairedPeripheralsKey);
    if (devices === null) {
      devices = {};
    } else {
      devices = JSON.parse(devices);
    }
    devices[peripheralId] = { paired: true };
    await AsyncStorage.setItem(PairedPeripheralsKey, JSON.stringify(devices));
    this.startBluetoothPassiveReading(); // update device list
  }

  async unpairBluetoothPeripheral(peripheralId) {
    if (!ValidicBluetooth) {
      return;
    }
    let devices = await AsyncStorage.getItem(PairedPeripheralsKey);
    if (devices === null) {
      devices = {};
    } else {
      devices = JSON.parse(devices);
    }
    devices[peripheralId] = { paired: false };
    await await AsyncStorage.setItem(PairedPeripheralsKey, JSON.stringify(devices));
    this.startBluetoothPassiveReading(); // update device list
  }

  async unpairBluetoothPeripherals() {
    if (!ValidicBluetooth) {
      return;
    }
    await await AsyncStorage.setItem(PairedPeripheralsKey, JSON.stringify({}));
    this.stopBluetoothPassiveReading(); // sets paired devices to nil
  }

  async getAvailableBluetoothPeripherals() {
    if (ValidicBluetooth) {
      const peripherals = await ValidicBluetooth.getSupportedPeripherals();
      let paired = await AsyncStorage.getItem(PairedPeripheralsKey);
      paired = paired === null ? {} : JSON.parse(paired);
      return peripherals.map(p => ({
        ...p,
        peripheralId: p.peripheralID,
        imageUrl: p.imageURL, // normalize names
        requiresPairing: true,
        pairingStatus:
          paired[p.peripheralID] && paired[p.peripheralID].paired ? 'paired' : 'unpaired',
      }));
    } else {
      return [];
    }
  }

  async getPairedPeripheralIds() {
    let pairedIds = await this.getAvailableBluetoothPeripherals();
    pairedIds = pairedIds.filter(p => p.pairingStatus === 'paired').map(p => p.peripheralId);
    return pairedIds;
  }

  pairBluetoothPeripheral = async peripheral => {
    console.log('PAIRING', peripheral);
    try {
      await ValidicBluetooth.pairPeripheral(peripheral.peripheralId);
      // this might be triggering an error right after pairing (see didPassiveReadFail)
      // might just be a blood pressure cuff thing
    } catch (e) {
      //Pairing failed to *initialize* for some reason
      //(this is different from the didNotPair event)
      console.log(e);
    }
  };

  readBluetoothPeripheral = async peripheral => {
    console.log('READING', peripheral);
    try {
      await ValidicBluetooth.read(peripheral.peripheralID);
    } catch (e) {
      //Reading failed to *initialize* for some reason
      //(this is different from the readingFailed event)
      console.log(e);
    }
  };
}
