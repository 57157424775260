import branfingDefaultsBase from './brandingDefaultsBase';
// eventually we need to refactor all default props into json so we can use them at run or buildtime
// JSON props are the only ones that can be used at build time.

export default () => ({
  appName: 'Nudge',
  appStoreId: null,
  // not used
  // changes how we display the coach background for the connect screen, so, instead of streching
  // like a fullscreen background, it's more of a logo positioned at the top.
  // A little clumsy. Not sure if anyone uses this other than Conway.
  coachBackgroundAsLogo: false, // may not be needed anymore
  assets: {
    welcomeScreen: null,
    coachBackground: null, // may not be needed anymore
    logo: null,
    yesterdayCard: require('./yesterday-splash.jpg'),
    todayCard: require('./today-splash.jpg'),
  },
  // show/ hide stuff in settings
  settings: {
    showHeight: true,
    showUnits: true,
    showChangeProfilePhoto: true,
    showEmail: true,
    showBirthDate: true,
    showGender: true,
    showClientUserId: false,
    supportEmailAddress: 'support@nudgecoach.com',
  },
  locationTracking: {
    enabled: false,
    apiKey: null,
  },
  // inviteIdDisplay and clientUserIdDisplay are already in the translation files, just need to work them in
  inviteIdDisplay: {
    name: 'Invite ID',
    article: 'an',
    shortName: 'ID',
  },
  clientUserIdDisplay: {
    name: null,
    article: null,
  },
  useDarkTheme: false,
  darkThemeBackground: null, // legacy brands only; we used to do this with the nudge score background
  highlightedColor: '#4675E8',
  iPad: {
    welcomeScreenBackground: 'white',
  },
  // this is the one prop that isn't a straight copy over, but we might do that in the future
  tabs: {
    daily: { name: null, image: null },
    trends: { name: null, image: null, forceOnTab: false, showScore: false },
    messages: {
      name: null,
      image: null,
      enabled: true /* disabling messages tab will disable connect, too! */,
    },
    connect: { name: null, image: null, useInAppBrowserForLinks: true }, // only shows when a coach isn't connected
    groups: { name: null, image: null, enabled: true },
  },
  links: {
    privacyPolicy: 'http://nudgecoach.com/privacy',
    tos: 'http://nudgecoach.com/tos',
  },
  leaderboardLength: 10,
  language: 'en',
  trackerColors: {
    'trackers-standard-water': '#1DA1F2',
    'trackers-standard-sleep': '#842CF4',
    'trackers-standard-weight': '#3746FF',
    'trackers-standard-pedometer': '#4675E8',
    'trackers-standard-cardio': '#FB8E30',
    'trackers-standard-calories': '#3746FF',
    'trackers-standard-body-fat': '#3746FF',
    'trackers-standard-blood-pressure': '#DA3228',
    'trackers-standard-blood-glucose': '#DA3228',
    'trackers-standard-heart-rate': '#DA3228',
    'trackers-finance-total-revenue': '#4675E8',
    'trackers-finance-total-gross-profit': '#4675E8',
    'trackers-finance-total-gross-profit-margin': '#4675E8',
    'trackers-finance-net-income': '#4675E8',
    'trackers-finance-cash-flow': '#4675E8',
    'trackers-custom-001': '#000000',
    'trackers-custom-002': '#5983F0',
    'trackers-custom-003': '#08D28F',
    'trackers-custom-004': '#20D420',
    'trackers-custom-005': '#37AD52',
    'trackers-custom-006': '#418265',
    'trackers-custom-007': '#1DA1F2',
    'trackers-custom-008': '#3746FF',
    'trackers-custom-009': '#FB8E30',
    'trackers-custom-010': '#E5A451',
    'trackers-custom-011': '#F1BA15',
    'trackers-custom-012': '#8B6139',
    'trackers-custom-013': '#24A2BB',
    'trackers-custom-014': '#F89887',
    'trackers-custom-015': '#FE4677',
    'trackers-custom-016': '#DA3228',
    'trackers-custom-017': '#842CF4',
    'trackers-custom-018': '#531A55',
    'graphs-calories-carbs': '#1DA1F2',
    'graphs-calories-fats': '#FF4444',
    'graphs-calories-proteins': '#20D420',
    'graphs-heart-rate-avg': '#DA3228',
    'graphs-heart-rate-min': '#DA3228',
    'graphs-heart-rate-max': '#DA3228',
    'trackers-servings-alcohol': '#FE4677',
    'trackers-servings-carbs': '#3746FF',
    'trackers-servings-cigarettes': '#FE4677',
    'trackers-servings-coffee': '#8B6139',
    'trackers-servings-dairy': '#E5A451',
    'trackers-servings-energy-drinks': '#FE4677',
    'trackers-servings-fatty': '#DA3228',
    'trackers-servings-fried': '#FE4677',
    'trackers-servings-fruit': '#37AD52',
    'trackers-servings-grains': '#8B6139',
    'trackers-servings-healthy-fats': '#DA3228',
    'trackers-servings-high-carb': '#3746FF',
    'trackers-servings-juice': '#FE4677',
    'trackers-servings-legumes': '#E5A451',
    'trackers-servings-milk': '#E5A451',
    'trackers-servings-nuts': '#E5A451',
    'trackers-servings-proteins': '#37AD52',
    'trackers-servings-salty': '#FE4677',
    'trackers-servings-soda': '#FE4677',
    'trackers-servings-starches': '#3746FF',
    'trackers-servings-sweets': '#FE4677',
    'trackers-servings-tea': '#418265',
    'trackers-servings-tobacco': '#FE4677',
    'trackers-servings-veggies': '#37AD52',
  },
  ...branfingDefaultsBase,
});
