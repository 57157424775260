import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, Observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { RowTextInput } from '/common/components';
import { BrandContext } from '/common/config/branding';
import UpdateProfileItemList from './UpdateProfileItemList';

/**
 * We return the state with all the props anytime something changes.
 * What is changing doesn't map perfectly to what is in the object (first name, last name vs name),
 * So it's easier this way. I guess. I hate forms...
 */
@observer
class UpdateClientUserId extends Component {
  static contextType = BrandContext;

  render() {
    const { clientUserId, onChangeClientUserId, t } = this.props;
    const branding = this.context;
    const sections = [];

    sections.push({
      title: '',
      data: [
        () => (
          <Observer>
            {() => (
              <RowTextInput
                value={clientUserId}
                label={branding.clientUserIdDisplay.name || t('SETTINGS:CLIENT_USER_ID_DISPLAY')}
                onChangeText={onChangeClientUserId}
                textInputProps={{
                  autoFocus: true,
                  autoCapitalize: 'none',
                }}
              />
            )}
          </Observer>
        ),
      ],
    });

    return <UpdateProfileItemList sections={sections} />;
  }
}

UpdateClientUserId.propTypes = {
  clientUserId: PropTypes.string.isRequired,
  onChangeClientUserId: PropTypes.func.isRequired,
  t: PropTypes.any,
};

export default withTranslation()(UpdateClientUserId);
