export default {
  initialLoad: 'InitialLoad',
  modals: {
    graphDetail: 'GraphDetail',
    editConnectedApps: 'EditConnectedApps',
    editConnectedPeripherals: 'EditConnectedPeripherals',
    showFullscreenImage: 'ShowFullscreenImage',
    previewImage: 'PreviewImage',
    enterPromoCode: 'EnterPromoCode',
    changePassword: 'ChangePassword',
    deleteAccount: 'DeleteAccount',
    coachSettings: 'CoachSettings',
    onboarding: 'Onboarding',
    pushNotificationInfo: 'PushNotificationInfoModal',
    showFullscreenVideo: 'PlayVideo',
    syncBluetoothDevice: 'SyncBluetoothDevice',
    communityNotifications: 'CommunityNotifications',
    individualCommunityNotifications: 'IndividualCommunityNotifications',
    acceptDecline: 'AcceptDecline',
  },
  stacks: {
    login: {
      root: 'Login',
      enterCredentials: 'EnterCredentials',
      welcome: 'Welcome',
      inviteId: 'EnterPromoCodeForNewAccount',
      clientUserId: 'EnterClientUserId',
      enterNameEmailAndPassword: 'EnterEmailAndPasswordScreen',
      requestPasswordReset: 'RequestPasswordReset',
    },
    // TODO: move all other post-login stacks inside here
    loggedIn: {
      root: 'LoggedIn',
    },
    main: {
      root: 'Main',
      tabs: 'MainTabs',
      logTab: 'LogTab',
      programsTab: 'ProgramsTab',
      feedSubTab: 'FeedSubTab',
      tagsSubTab: 'TagsSubTab',
      coachTab: 'CoachTab',
      groupsTab: 'GroupsTab',
      settingsTab: 'SettingsTab',
      group: 'Community',
      topic: 'Topic',
      conversation: 'Conversation',
      addLog: 'AddLog',
      editLog: 'EditLog',
      addLog_modal: 'AddLogModal',
      editLog_modal: 'EditLogModal',
      logEntries: 'LogEntries',
      programCardDetail: 'ProgramCardDetail',
      cardFromTagDetailScreen: 'CardFromTagDetailScreen', // open card from the card list from a tag
      trackerHistory: 'TrackerHistory',
      leaderboard: 'Leaderboard',
      editProfile: 'EditProfile',
      updateName: 'EditProfileUpdateName',
      updateEmail: 'EditProfileUpdateEmail',
      updateGender: 'EditProfileUpdateGender',
      updateDateOfBirth: 'EditProfileUpdateDateOfBirth',
      updateHeight: 'EditProfileUpdateHeight',
      updateUnits: 'EditProfileUpdateUnits',
      updateTrackingConsent: 'UpdateTrackingConsent',
      updateClientUserId: 'UpdateClientUserId',
      programCardsList: 'ProgramCardsList',
      acceptDecline: 'AcceptDeclineScreen',
    },
    onboarding: {
      inviteId: 'OnboardingInviteId',
      pushNotifications: 'OnboardingPushNotifications',
      connectedApps: 'OnboardingConnectedApps',
      connectedBluetoothPeripherals: 'OnboardingConnectedBluetoothPeripherals',
      updateTrackingConsent: 'UpdateTrackingConsent_Onboarding',
      tutorial: 'Tutorial',
      syncBluetoothDevice: 'SyncBluetoothDevice',
      downloadMobileApp: 'downloadMobileApp',
      wrapUp: 'wrapUpOnboarding',
    },
  },
};
