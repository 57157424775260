import React, { useContext } from 'react';
import { Text, View } from 'react-native';
import { observer } from 'mobx-react';
import { textStyles } from '/common/config/styles';
import { BrandContext } from '/common/config/branding';
import cardConstants from '../cardConstants';

const CardQuoteComponent = observer(function({ component }) {
  const branding = useContext(BrandContext);

  return (
    <View
      style={{
        padding: 20,
        borderLeftWidth: 4,
        borderLeftColor: branding.highlightedColor,
        marginBottom: cardConstants.elementSpacing,
      }}>
      <Text
        key={component.id.toString()}
        ellipsizeMode={'tail'}
        style={[
          textStyles.standard.light,
          {
            lineHeight: 22,
            fontStyle: 'italic',
          },
        ]}>
        {component.body}
      </Text>
    </View>
  );
});

export default CardQuoteComponent;
