import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import numeral from 'numeral';
import DurationValue from './DurationValue';
import SingleValue from './SingleValue';
import { UserOptionsContext } from '/common/config/personalization';

const LeaderboardValue = observer(({ leader, uom, valueStyle, unitsStyle, isInFeed }) => {
  const formatFn = value => {
    let valueFormatted = value;
    if (isInFeed) {
      if (value < 10000) {
        valueFormatted = numeral(value).format('0,0[.]0');
      } else {
        valueFormatted = numeral(value).format('0.0a');
      }
    } else {
      valueFormatted = numeral(value).format('0,0[.]0');
    }
    return valueFormatted;
  };
  const { useMetricUnits } = useContext(UserOptionsContext);

  // keep steps out for spacing on in feed leaderboard/ use km where appropriate
  let finalUom = uom;
  if (isInFeed && uom === 'steps') {
    finalUom = '';
  } else if (useMetricUnits && uom === 'miles') {
    finalUom = 'km';
  }

  let value = leader.effectiveTotal;
  if (uom === 'seconds') {
    return <DurationValue value={value} valueStyle={valueStyle} unitsStyle={unitsStyle} />;
  } else if (finalUom === 'km') {
    value = value * 1.60934;
  }

  const twoPercentOfValOrOne = Math.max(Math.floor(0.0005 * value), 1); // keeps animated number as int without being too slow

  return (
    <SingleValue
      value={value}
      formatter={formatFn}
      uom={finalUom}
      valueStyle={valueStyle}
      unitsStyle={unitsStyle}
      countBy={isInFeed ? undefined : twoPercentOfValOrOne}
    />
  );
});

export default LeaderboardValue;
