import React, { Component } from 'react';
import ReactNative, {
  ScrollView,
  Platform,
  Keyboard,
  TextInput,
  findNodeHandle,
  UIManager,
  View,
  Dimensions,
} from 'react-native';
import { ifIphoneX } from 'react-native-iphone-x-helper';
import PropTypes from 'prop-types';

const screenHeight = Dimensions.get('screen').height;

const iPhoneXBottomPadding = 34;

function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * NOTE: So far keyboard tracking only works when the keyboard initially appears,
 * not when tracking to each input.
 * That might be OK.
 * TODO: really want to get rid of this. But don't want to break signup at this point
 */
class KeyboardAwareScrollView extends Component {
  state = {
    keyboardSpace: this.props.defaultTabBarHeight,
    isKeyboardVisible: false,
  };

  _measureRefAsync = ref => {
    return new Promise(resolve => {
      ref.measure((x, y, width, height, pageX, pageY) => {
        resolve({ x, y, width, height, pageX, pageY });
      });
    });
  };

  _measureLayoutAsync = (descendantRef, containerRef) => {
    return new Promise(resolve => {
      descendantRef.measureLayout(
        ReactNative.findNodeHandle(containerRef),
        (xPos, yPos, width, height) => {
          resolve({ xPos, yPos, width, height });
        }
      );
    });
  };

  scrollToMakeRefVisibile = async ({
    ref,
    bottomClearance,
    topOffset = 180,
    bottomOffset = 60,
    androidCheat = 50,
    doOnlyIfKeyboardIsNotHandling = false,
  }) => {
    // wait for things to be in the right place
    await timeout(Platform.OS === 'ios' ? 250 : 500);

    // proceed only if keyboard hasn't changed position
    // This is used to move things along if a bluetooth keyboard is used on iOS
    if (doOnlyIfKeyboardIsNotHandling && this.state.isKeyboardVisible) {
      return;
    }

    const descendantRef = ref;
    if (!descendantRef) {
      return;
    }

    // measure ref
    const descendantRefMeasurements = await this._measureRefAsync(descendantRef);
    console.log(`descendant measurements:`);
    console.log(descendantRefMeasurements);

    // get thresholds
    let nonScrollableSpaceOnBottom = bottomOffset + ifIphoneX(iPhoneXBottomPadding, 0);
    let moveUpThreshold = nonScrollableSpaceOnBottom;
    if (bottomClearance === 'keyboardHeight') {
      // seems like we're counting something twice somehow.
      // This looks bad on SE, and is still too much on X.
      // We'll cheat by removing the additional offset
      moveUpThreshold =
        this.state.keyboardSpace +
        ifIphoneX(iPhoneXBottomPadding, 0) -
        this.props.textInputOffsetFromKeyboard;
    } else if (bottomClearance) {
      moveUpThreshold =
        /*nonScrollableSpaceOnBottom +*/ bottomClearance + ifIphoneX(iPhoneXBottomPadding, 0);
    }
    console.log(`move up threshold: ${moveUpThreshold}`);

    // calculate if thresholds met
    const bottomOfTracker = descendantRefMeasurements.pageY + descendantRefMeasurements.height;
    const proximityToBottom = screenHeight - bottomOfTracker;
    console.log(`proximity to bottom: ${proximityToBottom}`);
    const spaceNeededToClear =
      moveUpThreshold - proximityToBottom + (Platform.OS === 'android' ? androidCheat : 0);
    console.log(`space needed to clear: ${spaceNeededToClear}`);
    if (spaceNeededToClear > 0) {
      const layoutMeasurements = await this._measureLayoutAsync(descendantRef, this._scrollViewRef);
      console.log(`layout measurements:`);
      console.log(layoutMeasurements);
      //const scrollUpBy = descendantRefMeasurements.pageY - spaceNeededToClear - moveUpThreshold;
      //console.log(`scroll up by: ${scrollUpBy}`);
      const topScrollOffset =
        layoutMeasurements.yPos - (descendantRefMeasurements.pageY - topOffset);
      console.log(`top scroll offset: ${topScrollOffset}`);
      const scrollingTo = topScrollOffset + spaceNeededToClear;
      console.log(`scrollingTo: ${scrollingTo}`);
      // scroll
      this._scrollViewRef.scrollTo({
        y: scrollingTo,
      });
    }
  };

  componentDidMount() {
    // Keyboard events
    if (Platform.OS === 'ios') {
      this.keyboardWillShowEvent = Keyboard.addListener(
        'keyboardWillShow',
        this._updateKeyboardSpace
      );
      this.keyboardWillHideEvent = Keyboard.addListener(
        'keyboardWillHide',
        this._resetKeyboardSpace
      );
    } else if (Platform.OS === 'android') {
      this.keyboardWillShowEvent = Keyboard.addListener(
        'keyboardDidShow',
        this._updateKeyboardSpace
      );
      this.keyboardWillHideEvent = Keyboard.addListener(
        'keyboardDidHide',
        this._resetKeyboardSpace
      );
    }
  }

  componentWillUnmount() {
    this.keyboardWillShowEvent && this.keyboardWillShowEvent.remove();
    this.keyboardWillHideEvent && this.keyboardWillHideEvent.remove();
  }

  /**
   * Adds a content inset that will move the scrollview into a position where the focused input is
   * above the keyboard if iOS.
   * Calls Android keyboard tracking if not.
   *
   * @memberof KeyboardAwareScrollView
   */
  _updateKeyboardSpace = frames => {
    this.setState({ isKeyboardVisible: true });
    if (Platform.OS === 'ios') {
      // this only works on iOS because content insets only work there
      let keyboardSpace = frames.endCoordinates.height;
      keyboardSpace -= this.props.defaultTabBarHeight;
      keyboardSpace += this.props.textInputOffsetFromKeyboard;
      this.setState({ keyboardSpace });
    } else {
      this._androidTrackToFocusedTextInput();
    }
  };

  _resetKeyboardSpace = () => {
    const keyboardSpace = this.props.defaultTabBarHeight;
    this.setState({ keyboardSpace, isKeyboardVisible: false });
  };

  /**
   * New API for future users. Need to update previous uses
   *
   * @memberof KeyboardAwareScrollView
   */
  androidTrackToFocusedTextInput = () => {
    if (Platform.OS === 'android') {
      this._androidTrackToFocusedTextInput();
    }
  };

  /**
   * Moves the scrollview to the position of the focused text input, +/- some offset
   *
   * @memberof KeyboardAwareScrollView
   */
  _androidTrackToFocusedTextInput = () => {
    const currentlyFocusedField = TextInput.State.currentlyFocusedField();
    const scrollResponder = this._scrollViewRef && this._scrollViewRef.getScrollResponder();
    // obviously there could be no currently focused field
    // Forget why there sometimes isn't a scroll responder.
    // Could be due to popping a child view in navigation.
    if (currentlyFocusedField && scrollResponder) {
      UIManager.viewIsDescendantOf(
        currentlyFocusedField,
        scrollResponder.getInnerViewNode(),
        isDescendant => {
          // Do not try to scroll if this text input isn't even within this scrollview
          // (e.g., maybe we pushed another screen that also scrolls and has text inputs)
          if (!isDescendant) {
            return;
          }
          // Async so scroll responder could have gone away in meantime
          const scrollResponder = this._scrollViewRef && this._scrollViewRef.getScrollResponder();
          if (scrollResponder) {
            setTimeout(() => {
              const nodeHandle = findNodeHandle(currentlyFocusedField);
              if (!nodeHandle) {
                return;
                // trying a safe attempt to fix this
                // https://sentry.io/organizations/nudge-pt/issues/2196166229/?project=1257886&query=is%3Aunresolved
              }
              scrollResponder.scrollResponderScrollNativeHandleToKeyboard(
                nodeHandle,
                this.props.textInputOffsetFromKeyboard,
                // This should prevent scrolling down to meet text input
                // We only want to scroll up
                true
              );
            }, this.props.scrollTrackDelay);
          }
        }
      );
    }
  };

  render() {
    const { children, defaultTabBarHeight, scrollViewProps, scrollViewRef, style } = this.props;

    if (Platform.OS === 'android') {
      return (
        <View
          ref={ref => {
            this._parentContainerRef = ref;
          }}
          style={{ flex: 1 }}>
          <ScrollView
            ref={ref => {
              this._scrollViewRef = ref;
            }}
            style={[{ flex: 1 }, style]}
            keyboardShouldPersistTaps="always"
            onScroll={this._handleScroll}
            contentInset={{ bottom: this.state.keyboardSpace }}
            automaticallyAdjustContentInsets={false}
            {...scrollViewProps}>
            {children}
          </ScrollView>
        </View>
      );
    }

    if (Platform.OS === 'web') {
      return (
        <ScrollView style={[{ flex: 1 }, style]} {...scrollViewProps}>
          {children}
        </ScrollView>
      );
    }

    return (
      <ScrollView
        ref={ref => {
          scrollViewRef(ref);
          this._scrollViewRef = ref;
        }}
        style={[{ flex: 1 }, style]}
        keyboardShouldPersistTaps="handled"
        contentInset={{ bottom: this.state.keyboardSpace }}
        automaticallyAdjustContentInsets={false}
        {...scrollViewProps}>
        {children}
      </ScrollView>
    );
  }
}

KeyboardAwareScrollView.propTypes = {
  children: PropTypes.any.isRequired,
  defaultTabBarHeight: PropTypes.number,
  scrollViewProps: PropTypes.object,
  scrollViewRef: PropTypes.func,
  style: PropTypes.any,
  scrollTrackDelay: PropTypes.number,
  textInputOffsetFromKeyboard: PropTypes.number,
};

KeyboardAwareScrollView.defaultProps = {
  defaultTabBarHeight: 0,
  scrollViewProps: {},
  scrollViewRef: () => {},
  style: null,
  scrollTrackDelay: Platform.OS === 'android' ? 0 : 0,
  // I have no idea what this number is relative to
  // WAIT! I think I know. If the scrollview doesn't go the entire height of the screen,
  // this number has to go up to account for that space (e.g., our 110 px header)
  textInputOffsetFromKeyboard: Platform.OS === 'android' ? 35 + 110 + 60 : 65,
};

export default KeyboardAwareScrollView;
