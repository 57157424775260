import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { createPortal } from 'react-dom';
import { observer } from 'mobx-react';
import { View } from 'react-native';
import DatePicker from 'react-datepicker';
import { containerStyles, sizes, colors } from '/common/config/styles';
import '/common/config/styles/TimeInput.css';

const PopperContainer = ({ children }) => createPortal(children, document.body);

const TimeInput = observer(({ value, onChangeValue, canEdit, minDate, maxDate }) => {
  const containerStyle = [
    {
      //borderWidth: sizes.hairlineWidth,
      //borderColor: colors.lightText,
      borderRadius: sizes.borderRadius,
      paddingHorizontal: 10,
      flexGrow: 1,
      backgroundColor: canEdit ? colors.bg1 : colors.bg1,
      height: 40,
    },
    containerStyles.horizontalChildrenMaxSpaceBetween,
  ];

  //const date = dates.descriptiveDateWithoutYear(value);
  //const time = dates.time(value);

  return (
    <View style={{ width: '100%' }}>
      <View style={{ flexDirection: 'row' }}>
        <View style={containerStyle}>
          <DatePicker
            selected={value}
            onChange={onChangeValue}
            popperContainer={PopperContainer}
            minDate={minDate}
            maxDate={maxDate}
            disabled={!canEdit}
          />
        </View>
        <View style={{ width: sizes.small }} />
        <View style={[containerStyle]}>
          <DatePicker
            selected={value}
            onChange={onChangeValue}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
            popperContainer={PopperContainer}
            minTime={undefined /* not working, not sure why, not super helpful */}
            maxTime={undefined}
            disabled={!canEdit}
          />
        </View>
      </View>
    </View>
  );
});

TimeInput.propTypes = {
  isEditable: PropTypes.bool,
  showsBorder: PropTypes.bool,
  onChangeValue: PropTypes.func.isRequired,
  style: PropTypes.any,
  textStyle: PropTypes.any,
  value: PropTypes.instanceOf(Date).isRequired,
};

TimeInput.defaultProps = {
  isEditable: false,
  showsBorder: false,
  style: null,
  textStyle: null,
  minDate: moment()
    .subtract(1, 'years')
    .toDate(),
  maxDate: moment()
    .add(10, 'years')
    .toDate(),
};

export default TimeInput;
