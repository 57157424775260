import React, { useCallback, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { BrandContext } from '/common/config/branding';
import { PromptForFieldDuringSignup } from '../../components/sign-up';
import { SimpleNavWrapper, HeaderIconButton } from '../../components/navigation';
import { LoadingShadeWrapper } from '../../components/common';
import { containerStyles } from '../../config/common-styles';
import { BackIcon } from '../../config/common-icons';
import { withKeyboardAvoidance, ScreenError } from '../common';
import useNextSignupRoute from './useNextSignupRoute';

const EnterClientUserIdScreen = inject('rootStore')(
  observer(function({ rootStore, navigation, route }) {
    const branding = useContext(BrandContext);
    const { t } = useTranslation();
    const [clientUserId, setClientUserId] = useState();
    const nextRoute = useNextSignupRoute(route);

    const clientUserIdDisplayName =
      branding.clientUserIdDisplay.name || t('SETTINGS:CLIENT_USER_ID_DISPLAY');

    const onPressContinue = useCallback(() => {
      navigation.navigate(nextRoute, {
        clientUserId,
        ...route.params, // pass params from previous steps (like invite ID)
      });
    }, [navigation, route, clientUserId, nextRoute]);

    const onPressSkip = useCallback(() => {
      navigation.navigate(nextRoute, {
        clientUserId: null,
        ...route.params, // pass params from previous steps (like invite ID)
      });
    }, [navigation, nextRoute]);

    const onPressBack = useCallback(() => {
      navigation.goBack();
    }, [navigation]);

    return (
      <SimpleNavWrapper
        leftButton={
          <HeaderIconButton
            cavyId="EnterInviteIdScreen.Back"
            icon={<BackIcon />}
            onPress={onPressBack}
          />
        }>
        <LoadingShadeWrapper
          isLoading={rootStore.signupActionState.isPending}
          style={containerStyles.fillAvailableSpace}>
          <ScreenError state={rootStore.signupActionState} ignoreFormErrors />
          <PromptForFieldDuringSignup
            onPressContinue={onPressContinue}
            onPressSkip={onPressSkip}
            value={clientUserId}
            onChangeValue={value => setClientUserId(value)}
            prompt={t('SIGN_UP:PLEASE_ENTER_CLIENT_USER_ID', {
              clientUserIdName: clientUserIdDisplayName,
            })}
            textInputPlaceholder={clientUserIdDisplayName}
          />
        </LoadingShadeWrapper>
      </SimpleNavWrapper>
    );
  })
);

EnterClientUserIdScreen.propTypes = {
  navigation: PropTypes.object,
};

export default withKeyboardAvoidance(EnterClientUserIdScreen, {
  enabledForIos: false,
});
