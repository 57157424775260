import { DateTime } from 'luxon';
import moment from 'moment';
import { getToday } from 'nudge-client-common/lib/dates';

const formatDateTimeToTime = dateTime => {
  return dateTime.toFormat('h:mm a').toLowerCase();
};

const toDateTime = date => {
  if (date instanceof Date) {
    return DateTime.fromJSDate(date);
  }
  return DateTime.fromSQL(date);
};

export default {
  timeForTodayOrDateForYesterday: date => {
    const dateInQuestion = toDateTime(date);
    const today = DateTime.local();
    if (today.hasSame(dateInQuestion, 'day')) {
      return formatDateTimeToTime(dateInQuestion);
    }
    return dateInQuestion.toLocaleString(DateTime.DATE_SHORT);
  },
  dateRange: (startDate, endDate, showYear = true, showMonthOnSecondDate = true) => {
    if (startDate && endDate) {
      const startDateTime = toDateTime(startDate);
      const endDateTime = toDateTime(endDate);
      if (showYear) {
        return `${startDateTime.toFormat('LLL dd')} - ${endDateTime.toFormat(
          'LLL dd'
        )}, ${endDateTime.toFormat('yyyy')}`;
      } else {
        if (!showMonthOnSecondDate && startDateTime.month === endDateTime.month) {
          return `${startDateTime.toFormat('LLL d')} - ${endDateTime.toFormat('d')}`;
        }
        return `${startDateTime.toFormat('LLL d')} - ${endDateTime.toFormat('LLL d')}`;
      }
    }
    // TODO: suboptimal
    return '...';
  },
  shortDate: date => {
    const dateTime = toDateTime(date);
    return dateTime.toLocaleString(DateTime.DATE_SHORT);
  },
  shortDateWitoutYear: date => {
    const dateTime = toDateTime(date);
    return dateTime.toFormat('MMM d');
  },
  shortDateTwoDigitYear: date => {
    const dateTime = toDateTime(date);
    return dateTime.toFormat('d/M/yy');
  },
  birthdate: date => {
    const dateTime = toDateTime(date);
    return dateTime.toLocaleString(DateTime.DATE_MED);
  },
  relativeToAnyTimeDate: date => {
    return moment(date).fromNow();
  },
  relativeDate: (date, referenceDate) => {
    const dateTime = toDateTime(date);

    const today = toDateTime(referenceDate);
    if (today.hasSame(dateTime, 'day')) {
      return 'Today';
    }

    const yesterday = toDateTime(referenceDate).minus({
      days: 1,
    });
    if (yesterday.hasSame(dateTime, 'day')) {
      return 'Yesterday';
    }
    return dateTime.toFormat('ccc, LLL d');
  },
  time: date => {
    return formatDateTimeToTime(toDateTime(date));
  },
  // breaks hours and minutes and am / pm into separate segments for easier use in separate text components
  timeSegmented: date => {
    return [toDateTime(date).toFormat('h:mm'), toDateTime(date).toFormat('a')];
  },
  fullDateTimeWithZone: date => {
    // TODO: make exact format we want for leaderboard
    // https://moment.github.io/luxon/docs/manual/formatting.html
    const dateTime = toDateTime(date);
    return dateTime.toLocaleString(DateTime.DATETIME_FULL);
  },
  descriptiveDateWithoutYear: date => {
    const dateTime = toDateTime(date);
    const today = toDateTime(getToday());
    if (today.hasSame(dateTime, 'day')) {
      return 'Today';
    }

    const yesterday = today.minus({
      days: 1,
    });
    if (yesterday.hasSame(dateTime, 'day')) {
      return 'Yesterday';
    }

    return dateTime.toFormat('MMMM d');
  },
};
