import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { View, Text, TouchableOpacity } from 'react-native';
import { withTranslation } from 'react-i18next';
import {
  RowCenteredButton,
  CircleThumbnail,
  NudgeSectionList as SectionList,
  AestheticallyPleasingListFooter,
  SectionHeader,
} from '/common/components';
import { SeparatorLine } from '../../components/common';
import {
  containerStyles,
  sizes,
  colors,
  textStyles,
  tableStyles,
} from '../../config/common-styles';
import { common } from '../../config/strings';
import { BrandContext } from '../../config/branding';

const horizontalLayoutStyle = {
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
};

const rightSideVerticalLayoutStyle = {
  flex: 1,
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingLeft: sizes.medium,
};

const Coach = observer(({ coach, onPressCancelCoach, t }) => (
  <View style={[tableStyles.variableHeightCell, horizontalLayoutStyle]}>
    <CircleThumbnail
      source={coach.photoSource}
      diameter={sizes.thumbnail}
      altTitle={common.fullName(coach)}
    />
    <View style={rightSideVerticalLayoutStyle}>
      <Text
        numberOfLines={1}
        ellipsizeMode="tail"
        style={[textStyles.standard.dark, { flex: 1, marginRight: sizes.small }]}>
        {common.fullName(coach)}
      </Text>
      <TouchableOpacity onPress={() => onPressCancelCoach(coach.id)}>
        <View
          style={{
            borderRadius: sizes.borderRadius,
            padding: 4,
            backgroundColor: 'gray',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Text style={{ color: 'white' }}>{t('GENERAL:CANCEL')}</Text>
        </View>
      </TouchableOpacity>
    </View>
  </View>
));

const CoachConfig = observer(({ coaches, onPressEnterPromoCode, onPressCancelCoach, t }) => {
  const sections = [];

  sections.push({
    title: '',
    data: [{}],
    renderItem: () => (
      <BrandContext.Consumer>
        {branding => (
          <RowCenteredButton
            onPress={onPressEnterPromoCode}
            title={t('SETTINGS:ENTER_AN_INVITE_ID', {
              inviteIdName: branding.inviteIdDisplay.name,
              article: branding.inviteIdDisplay.article,
            })}
          />
        )}
      </BrandContext.Consumer>
    ),
  });

  sections.push({
    title: '',
    data: coaches,
    renderItem: ({ item }) => <Coach coach={item} t={t} onPressCancelCoach={onPressCancelCoach} />,
  });

  return (
    <SectionList
      sections={sections}
      keyExtractor={index => index}
      renderSectionHeader={({ section }) => (
        <SectionHeader title={section.title} style={{ backgroundColor: colors.bg0 }} />
      )}
      ItemSeparatorComponent={SeparatorLine}
      style={[containerStyles.fillAvailableSpace, { backgroundColor: colors.bg1 }]}
      ListFooterComponent={AestheticallyPleasingListFooter}
    />
  );
});

CoachConfig.propTypes = {
  coaches: PropTypes.arrayOf(PropTypes.object).isRequired,
  onPressEnterPromoCode: PropTypes.func.isRequired,
  onPressCancelCoach: PropTypes.func.isRequired,
  t: PropTypes.any.isRequired,
};

export default withTranslation()(CoachConfig);
