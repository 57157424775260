import React, { useCallback } from 'react';
import { View } from 'react-native';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { containerStyles, colors } from '/common/config/styles';
import { TrackerSummary } from '/common/components/program';
import {
  EmptyListPlaceholder,
  NudgeFlatList as FlatList,
  AestheticallyPleasingListFooter,
  SeparatorLine,
} from '/common/components';

// This seems optimized, but I still think TrackerSummary renders too much

/**
 * Lays out a list of programs with a list of trackers underneath.
 * Handles anything outside of a tracker itself (like borders between trackers, headers, etc.).
 * Also enforces a consistent style on each tracker's container.
 * KeyboardAwareScrollView automatically detects focused TextInputs and scrolls to make them
 * visible.
 */
const TrackerList = observer(
  ({ trackers, date, onPullToRefresh, isPullToRefreshInProgress, onPressTracker }) => {
    const { t } = useTranslation();
    // *** callbacks ***

    const renderItem = useCallback(
      ({ item }) => {
        return <TrackerSummary tracker={item} date={date} onPress={onPressTracker} />;
      },
      [onPressTracker, date]
    );

    const keyExtractor = useCallback(item => item.id.toString());

    // *** end callbacks ***

    /**
     * Having issues getting the refresh control to show under the top tabs.
     * If I wrap it in a view with a top padding, the app crashes.
     * So, moved the top offset view outside of the scroll area but offset it a bit
     * so the view appears to go under the top tabs as you scroll up.
     * It'd be ideal to get the refresh control to just appear lower.
     * Though this also has a nice side-effect of preventing the scroll view contents
     * from staying under the top tabs after collapsing a tracker.
     */

    return (
      <View
        style={[
          containerStyles.fillAvailableSpace,
          {
            backgroundColor: colors.bg1,
          },
        ]}>
        <FlatList
          data={trackers}
          contentContainerStyle={{
            flexGrow: 1,
          }}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          showsVerticalScrollIndicator={false}
          refreshing={isPullToRefreshInProgress}
          onRefresh={onPullToRefresh}
          ListEmptyComponent={
            <EmptyListPlaceholder
              title={t('LOG:EMPTY_TRACKER_LIST:TITLE')}
              message={t('LOG:EMPTY_TRACKER_LIST:MESSAGE')}
            />
          }
          ItemSeparatorComponent={SeparatorLine}
          ListFooterComponent={AestheticallyPleasingListFooter}
        />
      </View>
    );
  }
);

TrackerList.propTypes = {
  onPressTracker: PropTypes.func.isRequired,
  trackers: PropTypes.arrayOf(PropTypes.object).isRequired,
  topOffset: PropTypes.number,
};

TrackerList.defaultProps = {
  topOffset: 0,
};

export default TrackerList;
