import React, { Component } from 'react';
import { Platform } from 'react-native';
import { observer, inject } from 'mobx-react';
import { CustomTabIcon, CommunityTabIcon } from '/common/config/icons';
import { BadgeIcon } from '/common/components';
import { sizes } from '../../../../config/common-styles';
import { BrandContext } from '../../../../config/branding';

@inject('rootStore')
@observer
class GroupsTabBadgeIcon extends Component {
  static contextType = BrandContext;

  render() {
    const { color, rootStore, ...rest } = this.props;
    const branding = this.context;

    const icon = branding.tabs.groups.image ? (
      <CustomTabIcon source={branding.tabs.groups.image} tintColor={color} {...rest} />
    ) : (
      <CommunityTabIcon tintColor={color} {...rest} />
    );
    return (
      <BadgeIcon
        icon={icon}
        anchor={Platform.isPad ? 'left' : 'right'}
        badgeColor={branding.highlightedColor}
        badgeText={rootStore.tabBadges.social}
        badgeDiameter={sizes.medium}
        hideNumber
      />
    );
  }
}

export default GroupsTabBadgeIcon;
