import React from 'react';
import { View } from 'react-native';
import PropTypes, { instanceOf } from 'prop-types';
import MaterialInitials from 'react-native-material-initials/native';
import { createImageProgress } from 'react-native-image-progress';
import SecurableImage from './SecurableImage';

// This weirdness is courtesy of https://github.com/oblador/react-native-image-progress/issues/61
// Basically, react-native-image-progress is now wrapped in a container, but it doesn't support
// passing props directly to the image component.
// But it does support creating a component that shows progress out of another Image component.
const ImageComponent = props => {
  const { diameter, makeASquareInstead, source, loadingBackgroundColor, style, ...rest } = props;
  return (
    <View
      style={{
        width: diameter,
        height: diameter,
        shadowColor: '#000',
        borderRadius: makeASquareInstead ? undefined : diameter / 2,
        shadowOffset: makeASquareInstead ? undefined : { width: 0.5, height: 0.5 },
        shadowOpacity: makeASquareInstead ? undefined : 0.1,
        shadowRadius: makeASquareInstead ? undefined : 1,
        elevation: makeASquareInstead ? undefined : 1,
        backgroundColor: loadingBackgroundColor,
      }}>
      <SecurableImage
        style={{
          width: diameter,
          height: diameter,
          borderRadius: makeASquareInstead ? undefined : diameter / 2,
        }}
        source={source}
        {...rest}
      />
    </View>
  );
};

// We embed the shadow in this component so it only shows when the progress indicator is hidden after image loading
const ImageWithProgressIndicatorAndShadow = createImageProgress(ImageComponent);

/**
 * We like to show an initial avatar instead of a profile photo if a user doesn't have a profile
 * photo, but all users have a profile photo- if they don't set one, they get the default
 * assigned by the server. Until that no longer happens, if we want to show something else
 * for the default, we need to check for the default photo.
 * Hopefully this url doesn't change...
 */
const isUrlForDefaultProfilePic = source =>
  (source &&
    source.uri &&
      (source.uri.includes('temp_profile_pic') || source.uri.includes('profile-default'))) ||
  (typeof source === 'string' &&
    (source.includes('temp_profile_pic') || source.includes('profile-default')));

// TODO: it's questionable if defaultSource works on iOS, and it doesn't work on Android.
// https://github.com/facebook/react-native/issues/5017
const CircleThumbnail = ({
  source,
  diameter,
  loadingIndicatorColor,
  loadingBackgroundColor,
  altTitle,
  makeASquareInstead,
}) => {
  // check for default pic and swap it out for the circle initials view
  const hasDefaultOrThumbnail = !source || isUrlForDefaultProfilePic(source);
  if (altTitle && hasDefaultOrThumbnail) {
    // TODO make this less hacky
    // something quick and dirty to change the colors
    const colors = [
      '#1abc9c',
      '#2ecc71',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#16a085',
      '#27ae60',
      '#2980b9',
      '#8e44ad',
      '#2c3e50',
      '#f1c40f',
      '#e67e22',
      '#e74c3c',
      '#95a5a6',
      '#f39c12',
      '#d35400',
      '#c0392b',
      '#bdc3c7',
      '#7f8c8d',
    ];

    const charIndex = altTitle.charCodeAt(0) - 65;
    const colorIndex = Math.abs(charIndex % 19);
    return (
      <View
        style={{
          width: diameter,
          height: diameter,
          backgroundColor: loadingBackgroundColor,
          shadowColor: '#000',
          borderRadius: diameter / 2,
          shadowOffset: { width: 0.5, height: 0.5 },
          shadowOpacity: 0.1,
          shadowRadius: 1,
          elevation: 1,
        }}>
        <MaterialInitials
          size={diameter}
          color="white"
          backgroundColor={colors[colorIndex]}
          single={false}
          text={altTitle}
        />
      </View>
    );
  }
  // recent switch from size to width/ height props is undocumented
  return (
    <View>
      <ImageWithProgressIndicatorAndShadow
        loadingBackgroundColor={loadingBackgroundColor}
        diameter={diameter}
        source={source}
        makeASquareInstead={makeASquareInstead}
        indicatorProps={{ color: loadingIndicatorColor }}
      />
    </View>
  );
};

CircleThumbnail.propTypes = {
  altTitle: PropTypes.string,
  diameter: PropTypes.number.isRequired,
  source: PropTypes.any, // could be a number for a local source
  loadingIndicatorColor: PropTypes.string,
  // set this to match the background of the screen
  // transparent backgrounds and shadows don't mix
  // so, while you can make it transparent, it's not a good idea
  loadingBackgroundColor: PropTypes.string,
  // use when adding overlays over the thumbnail - only works when there's an actual thumbnail image, currently
  makeASquareInstead: PropTypes.bool,
};

CircleThumbnail.defaultProps = {
  altTitle: null,
  source: null,
  loadingIndicatorColor: 'blue',
  loadingBackgroundColor: 'white',
  makeASquareInstead: false,
};

export default CircleThumbnail;
