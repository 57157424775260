import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Text, Platform } from 'react-native';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { getToday } from 'nudge-client-common/lib/dates';
import { rootStore } from '../../stores/instances';
import { withScreenBehaviors } from '/common/navigation/';
import { TrackerHistory } from '/common/components/tracking';
import { LoadingShadeWrapper } from '../../components/common';

import {
  NavbarWrapper,
  HeaderIconButton,
  HeaderTextButton,
} from '../../../common/components/navigation';
import { ScreenFirstLoadWrapper, ScreenError } from '../common';
import { navigationRoutes } from '../../config/constants';
import { sizes, textStyles, useMediaQueryInfo, colors } from '/common/config/styles';
import { BackIcon } from '../../config/common-icons';
import { BrandContext } from '/common/config/branding';

const LargeTitle = observer(({ title, tracker, largeTitleHeight }) => {
  const { innerWindowWidth } = useMediaQueryInfo();
  let myTitle = title;
  if (tracker.clientMeta.showExtraInfoInTitle) {
    if (tracker.clientMeta.extraInfoInTitle.find(e => e === 'exerbotics')) {
      myTitle = 'Strength Index\n' + myTitle + '\n' + tracker.meta.location.name;
    }
  }
  return (
    <View style={{ backgroundColor: colors.bg1 }}>
      <View
        style={{
          height: largeTitleHeight,
          justifyContent: 'center',
          alignItems: 'center',
          paddingHorizontal: sizes.small,
          alignSelf: 'center',
          width: innerWindowWidth,
          backgroundColor: colors.bg0,
        }}>
        <Text
          allowFontScaling={false}
          style={[
            textStyles.standard.semibold,
            { paddingHorizontal: sizes.large, textAlign: 'center', textTransform: 'capitalize' },
          ]}>
          {myTitle}
        </Text>
      </View>
    </View>
  );
});

@inject('rootStore')
@observer
class TrackerHistoryScreen extends Component {
  static contextType = BrandContext;
  /**
   * Saves the log, returns true if transaction was successful. Used when both changing dates and pressing done.
   * Return value is used to determine how the UI should respond
   */

  _onChangeDate = async date => {
    const { navigation, route, rootStore } = this.props;
    const { logStore } = rootStore;
    const { timeframe, trackerId } = route.params;

    navigation.setParams({ date });

    // if date isn't in current page
    await logStore.loadLogsAroundDate({ date, timeframe, trackerId });
  };

  // override the timeframe if the graphs are disabled, so paging happens on a per-day basis
  _resolveTimeframe = () => {
    const { route, rootStore } = this.props;
    const { logStore } = rootStore;
    const { timeframe, trackerId } = route.params;
    const branding = this.context;
    if (
      branding.logging.disableGraphsForTrackerTypes.find(
        type => type === logStore.trackerFor(trackerId).type
      )
    ) {
      return 'day';
    }
    return timeframe;
  };

  _onPageGraphForward = async () => {
    const { navigation, route, rootStore } = this.props;
    const { logStore } = rootStore;
    const { timeframe, date, trackerId } = route.params;
    let nextDate = logStore.pageForwardDateFor({ date, timeframe: this._resolveTimeframe() });
    //If we're not interpolating dates the max date that can be selected is the latestHistorical we have access to
    const tracker = logStore.trackerFor(trackerId);
    if (!tracker.clientMeta.interpolateDates) {
      const stats = tracker.statsFor({ interval: 'day', aggregateType: 'latestHistorical' });
      if (stats.latestLog && stats.latestLog.userTime < nextDate) {
        nextDate = stats.latestLog.userTime.slice(0, 10);
      }
    }

    if (nextDate) {
      navigation.setParams({ date: nextDate });
      logStore.loadLogsAroundDate({ date: nextDate, timeframe, trackerId });
    }
  };

  _onPageGraphBackward = async () => {
    const { navigation, route, rootStore } = this.props;
    const { logStore } = rootStore;
    const { timeframe, date, trackerId } = route.params;
    const nextDate = logStore.pageBackwardDateFor({ date, timeframe: this._resolveTimeframe() });

    if (nextDate) {
      navigation.setParams({ date: nextDate });
      logStore.loadLogsAroundDate({ date: nextDate, timeframe, trackerId });
    }
  };

  _onChangeGraphTimeframe = async newTimeframe => {
    const { navigation, route, rootStore } = this.props;
    const { logStore } = rootStore;
    const { timeframe, trackerId } = route.params;

    if (timeframe !== newTimeframe) {
      // reset graph date so we don't just end up with empty data
      // determine most recent day for data, navigate to that
      const stats = logStore.trackerFor(trackerId).statsFor({
        interval: 'day',
        aggregateType: 'latestHistorical',
      });
      const newTargetDate = stats.latestLog ? stats.latestLog.date : getToday();
      navigation.setParams({ timeframe: newTimeframe, date: newTargetDate });
      logStore.loadLogsAroundDate({
        date: newTargetDate,
        timeframe: newTimeframe,
        trackerId,
      });
    }
  };

  _onPressAddLog = async () => {
    const { route } = this.props;

    this.props.navigation.navigate(
      Platform.OS === 'web'
        ? navigationRoutes.stacks.main
            .addLog_modal /* hack alert! Should technically be isWideScreen, but we're not hook-ready yet here */
        : navigationRoutes.stacks.main.addLog,
      {
        date: route.params.date,
        trackerId: route.params.trackerId,
      }
    );
  };

  _onPressManageEntries = () => {
    const { navigation, rootStore, route } = this.props;
    const { logStore } = rootStore;
    const { date, trackerId, timeframe } = route.params;
    const tracker = logStore.trackerFor(trackerId);
    if (tracker.clientMeta.multipleValuesDisabled) {
      const logs = tracker.logsForDateSorted(date);
      if (logs.length) {
        navigation.navigate(navigationRoutes.stacks.main.editLog, {
          trackerId,
          logId: logs[0].id,
        });
      }
      return;
    }
    navigation.navigate(navigationRoutes.stacks.main.logEntries, { date, trackerId, timeframe });
  };

  _onPressBack = async () => {
    const { navigation } = this.props;
    navigation.goBack();
  };

  _onPressToday = async () => {
    const { navigation, route, rootStore } = this.props;
    const { logStore } = rootStore;
    const { timeframe, trackerId } = route.params;
    const today = getToday();

    navigation.setParams({ date: today });
    logStore.loadLogsAroundDate({ date: today, timeframe, trackerId });
  };

  _onPressLog = logId => {
    const { navigation, route } = this.props;
    const { trackerId } = route.params;
    navigation.navigate(navigationRoutes.stacks.main.editLog, {
      logId,
      trackerId,
    });
  };

  render() {
    const { route, rootStore, t } = this.props;
    const { date, trackerId, timeframe, dailyGoal } = route.params;
    const { logStore, settingsStore } = rootStore;
    const tracker = logStore.trackerFor(trackerId);

    const sliceDateRange = logStore.sliceDateRangeFor({ date, timeframe });
    const title = `${t(`LOG:TRACKERS:${tracker.type.toUpperCase()}`, tracker.name)}`;
    const largeTitle = title.length > 20 || tracker.clientMeta.showExtraInfoInTitle;
    const largeTitleHeight = tracker.clientMeta.showExtraInfoInTitle ? 65 : 50;

    return (
      <NavbarWrapper
        title={largeTitle ? '' : title}
        disableBottomBorder
        leftButton={<HeaderIconButton icon={<BackIcon />} onPress={this._onPressBack} />}
        rightButton={
          tracker.clientMeta.interpolateDates && date !== getToday() && !tracker.newLog ? (
            <HeaderTextButton text={t('GENERAL:DATES:TODAY')} onPress={this._onPressToday} />
          ) : null
        }>
        <ScreenError
          state={logStore.saveState}
          title={t('LOG:ERRORS:SAVE_FAILED')}
          type="messageBar"
        />
        <LoadingShadeWrapper
          style={{ flex: 1 }}
          isLoading={
            // checking for more than one log is a hacky way of avoiding a spinner when more data is loading but a tail record may already exist
            rootStore.logStore.saveState
              .isPending /*||
            (rootStore.logStore.isLoadingDate(date) && tracker.logsForDateSorted(date).length <= 1)*/
            // this was never working because isLoadingDate was never triggering, but when we fixed it, it was too aggressive,
            // often showing the spinner during entry of recent dates.
            // we need to overhaul the behavior that detects when we're loading old, empty data
          }>
          <ScreenFirstLoadWrapper
            state={logStore.loadProgramDataState}
            hasData={logStore.trackersSorted.length}
            onPressRefresh={
              logStore.loadToday /* TODO: bug if this fails and is on really old date, maybe should switch to today and then reload */
            }>
            {largeTitle && <LargeTitle title={title} tracker={tracker} />}
            <TrackerHistory
              onPressAdd={this._onPressAddLog}
              onPressManageEntries={this._onPressManageEntries}
              date={date}
              tracker={tracker}
              topOffset={largeTitle ? largeTitleHeight : 0}
              sliceStartDate={sliceDateRange.startDate}
              sliceEndDate={sliceDateRange.endDate}
              timeframe={timeframe}
              onChangeTimeframe={this._onChangeGraphTimeframe}
              onChangeDate={this._onChangeDate}
              onPageGraphForward={this._onPageGraphForward}
              onPageGraphBackward={this._onPageGraphBackward}
              useMetricUnits={settingsStore.useMetricUnits}
              dailyGoal={dailyGoal}
              onPressLog={this._onPressLog}
            />
          </ScreenFirstLoadWrapper>
        </LoadingShadeWrapper>
      </NavbarWrapper>
    );
  }
}

TrackerHistoryScreen.propTypes = {
  rootStore: PropTypes.object,
  navigation: PropTypes.object,
  route: PropTypes.shape({
    params: PropTypes.shape({
      date: PropTypes.string.isRequired,
      logId: PropTypes.any,
      timeframe: PropTypes.string,
      trackerId: PropTypes.string.isRequired,
      startNewLog: PropTypes.bool,
      originProgramEventMeta: PropTypes.any,
      goBackOnSave: PropTypes.bool,
      dailyGoal: PropTypes.any,
    }),
  }),
  t: PropTypes.any,
};

export default withScreenBehaviors({
  onFocus: props => {
    // this will reload the data surrounding the initial date if it wasn't already loaded last
    const { navigation, route } = props;
    const { logStore } = rootStore;
    const { timeframe, trackerId, date } = route.params;
    // if date isn't in current page
    navigation.setParams({ date });
    logStore.loadLogsAroundDate({ date, timeframe, trackerId });
  },
  onActive: () => {},
})(withTranslation()(TrackerHistoryScreen));
