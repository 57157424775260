import { types } from 'mobx-state-tree';

const pairingStatuses = {
  unpaired: 'unpaired',
  pairing: 'pairing',
  paired: 'paired',
  didFailToPair: 'didFailToPair',
};

const readingStatuses = {
  notStarted: 'notStarted',
  isReadyToRead: 'isReadyToRead',
  didRead: 'didRead',
  didFailToRead: 'didFailToRead',
};

const BluetoothPeripheral = types
  .model('BluetoothPeripheral', {
    peripheralId: types.identifierNumber,
    name: types.string,
    imageUrl: types.maybeNull(types.string),
    instructions: types.maybeNull(types.string),
    pairingInstructions: types.maybeNull(types.string),
    readingInstructions: types.maybeNull(types.string),
    requiresPairing: types.boolean,
    pairingStatus: types.optional(types.enumeration(Object.values(pairingStatuses)), 'unpaired'),
    pairingError: types.maybeNull(types.frozen()),
    readingStatus: types.optional(types.enumeration(Object.values(readingStatuses)), 'notStarted'),
    readingError: types.maybeNull(types.frozen()),
    lastReadTimestamp: types.maybeNull(types.Date),
    lastReadValue: types.frozen(),
  })
  .views(self => ({
    get pairingStatuses() {
      return pairingStatuses;
    },
    get readingStatuses() {
      return readingStatuses;
    },
    get isPaired() {
      return self.pairingStatus === pairingStatuses.paired;
    },
    get isPairing() {
      return self.pairingStatus === pairingStatuses.pairing;
    },
    get didFailToPair() {
      return self.pairingStatus === pairingStatuses.didFailToPair;
    },
  }))
  .actions(self => ({
    setPairingStatus: pairingStatus => {
      self.pairingStatus = pairingStatus;
    },
    setReadingStatus: readingStatus => (self.readingStatus = readingStatus),
    setPairingError: error => (self.pairingError = error),
    setLastReadTimestamp: timestamp => (self.lastReadTimestamp = timestamp),
    setLastReadValue: value => (self.lastReadValue = value),
  }));

export default BluetoothPeripheral;
