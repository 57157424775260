import idx from 'idx';
import { isNetworkError } from '/common/lib/errors';

// If true, we should report error to Sentry
const shouldReportError = error => {
  // a null error would be interesting, probably an error in stores logic
  if (!error) {
    return true;
  }
  const status = idx(error, _ => _.response.status);
  const url = idx(error, _ => _.config.url);
  // special validic user ID not found error we want to keep track of
  if (status && status === 404 && url && url.includes('users/me/integrations/validic')) {
    return true;
  }
  if (status && status < 500) {
    return false;
  }
  if (status && status === 503) {
    return false;
  }
  // should we be capturing 500's here?
  // We could just capture non-API errors here
  // Server could catch 500's
  if (isNetworkError(error)) {
    return false;
  }
  // LogRepository sync errors are messy, just need to check the string right now
  if (error.message === 'Error syncing data with one or more services') {
    return false;
  }
  return true;
};

export default shouldReportError;
