import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Platform } from 'react-native';
import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';
import { observer, inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { NavbarWrapper } from '/common/components/navigation';
import { LoadingWrapper } from '/common/components';
import { useScreenBehaviors } from '/common/navigation';
import { BrandContext } from '/common/config/branding';
import { SyncBluetoothPeripheral } from '../../components/bluetooth';
import { UpdateTrackingConsent, UpdateBluetoothPermissions } from '../../components/settings';

const SyncBluetoothDeviceModal = inject('rootStore')(
  observer(({ rootStore, route, navigation }) => {
    const { settingsStore } = rootStore;
    const { peripheralId } = route.params;
    const peripheral = settingsStore.bluetoothPeripheralForId(peripheralId);

    const branding = useContext(BrandContext);

    const { t } = useTranslation();

    useScreenBehaviors({
      onDidMount: settingsStore.getBluetoothPermissionsReadyStatus,
      onFocus: settingsStore.getBluetoothPermissionsReadyStatus,
      onActive: settingsStore.getBluetoothPermissionsReadyStatus,
    });

    // android default: wait for other permissions
    let displayMode = 'spinner';

    if (Platform.OS === 'ios') {
      // no need to wait for other permissions
      displayMode = 'peripheral';
    } else if (Platform.OS === 'android' && settingsStore.bluetoothPermissionsReadyStatus) {
      if (settingsStore.bluetoothPermissionsReadyStatus.bluetoothPermissionsReady) {
        displayMode = 'peripheral';
      } else if (Platform.Version >= 31) {
        if (!settingsStore.bluetoothPermissionsReadyStatus.locationPermissionsReady) {
          displayMode = 'locationPermissions';
        } else {
          displayMode = 'nearbyDevicesPermissions';
        }
      } else {
        displayMode = 'locationPermissions';
      }
    }

    return (
      <NavbarWrapper navigation={navigation} hasBackButton title={peripheral.name}>
        {displayMode === 'peripheral' ? (
          <SyncBluetoothPeripheral
            bluetoothPeripheral={peripheral}
            onPressConnectPeripheral={() => settingsStore.pairBluetoothPeripheral(peripheralId)}
            onPressDisconnectPeripheral={() =>
              settingsStore.unpairBluetoothPeripheral(peripheralId)
            }
            lastReadValue={settingsStore.lastReadValueForPeripheralId(peripheral.peripheralId)}
          />
        ) : displayMode === 'locationPermissions' ? (
          <UpdateTrackingConsent
            onPressPrivacyPolicy={() => {
              WebBrowser.openBrowserAsync('https://nudgecoach.com/privacy/');
            }}
            onPressAcceptForegroundTracking={() => {
              settingsStore.updateTrackingConsent({ background: false });
            }}
            onPressAcceptBackgroundTracking={() => {
              settingsStore.updateTrackingConsent({ background: true });
            }}
            onPressOpenSettings={() => {
              Linking.openSettings();
            }}
            trackingConsentStatus={
              settingsStore.bluetoothPermissionsReadyStatus.locationPermissionsStatus
            }
            showAcceptTrackingButton
            consentScreenStrings={{
              title: t('SETTINGS:BLUETOOTH_LOCATION_PERMISSION_PROMPT:TITLE'),
              explanation: t('SETTINGS:BLUETOOTH_LOCATION_PERMISSION_PROMPT:EXPLANATION', {
                appName: branding.appName,
              }),
              privacyPolicyUrlDescription: t(
                'SETTINGS:BLUETOOTH_LOCATION_PERMISSION_PROMPT:PRIVACY_POLICY_URL_DESCRIPTION'
              ),
              settingsInfo: {
                ios: t('SETTINGS:BLUETOOTH_LOCATION_PERMISSION_PROMPT:SETTINGS_INFO:IOS'),
                android: t('SETTINGS:BLUETOOTH_LOCATION_PERMISSION_PROMPT:SETTINGS_INFO:ANDROID'),
              },
              android11BackgroundLocationPrompt: t(
                'SETTINGS:BLUETOOTH_LOCATION_PERMISSION_PROMPT:ANDROID_11_BACKGROUND_LOCATION_PROMPT'
              ),
              accepted: t('SETTINGS:BLUETOOTH_LOCATION_PERMISSION_PROMPT:ACCEPTED'),
              rejected: t('SETTINGS:BLUETOOTH_LOCATION_PERMISSION_PROMPT:REJECTED'),
              rejectedAlways: t('SETTINGS:BLUETOOTH_LOCATION_PERMISSION_PROMPT:REJECTED_ALWAYS'),
              notAnswered: t('SETTINGS:BLUETOOTH_LOCATION_PERMISSION_PROMPT:NOT_ANSWERED'),
            }}
          />
        ) : displayMode === 'nearbyDevicesPermissions' ? (
          <UpdateBluetoothPermissions
            onPressPrivacyPolicy={() => {
              WebBrowser.openBrowserAsync('https://nudgecoach.com/privacy/');
            }}
            onPressEnableBluetooth={() => {
              settingsStore.updateBluetoothPermissionsConsent();
            }}
            onPressOpenSettings={() => {
              Linking.openSettings();
            }}
            bluetoothPermissionsReadyStatus={settingsStore.bluetoothPermissionsReadyStatus}
          />
        ) : (
          <LoadingWrapper style={{ flex: 1 }} isLoading />
        )}
      </NavbarWrapper>
    );
  })
);

SyncBluetoothDeviceModal.propTypes = {
  navigation: PropTypes.shape({
    addListener: PropTypes.func,
  }),
  route: PropTypes.shape({
    params: PropTypes.shape({
      peripheralID: PropTypes.number,
    }),
  }),
  rootStore: PropTypes.shape({
    settingsStore: PropTypes.shape({
      pairBluetoothPeripheral: PropTypes.func,
    }),
  }),
};

export default SyncBluetoothDeviceModal;
