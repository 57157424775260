import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity } from 'react-native';
import { debounce } from 'lodash';

/**
 * TouchableOpacity with a short (by default) leading debounce, should be just enough
 * to prevent a double-press just about anywhere
 */
const DebouncedTouchableOpacity = ({ onPress, wait = 100, ...rest }) => {
  const debouncedOnPress = useCallback(
    debounce(onPress, wait, { leading: true, trailing: false }),
    [onPress, wait]
  );
  return <TouchableOpacity {...rest} onPress={debouncedOnPress} />;
};

DebouncedTouchableOpacity.propTypes = {
  onPress: PropTypes.func.isRequired,
  wait: PropTypes.number, // ms for debounce
};

DebouncedTouchableOpacity.defaultProps = {
  onPress: () => {},
};

export default DebouncedTouchableOpacity;
