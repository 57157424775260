import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { navigationRoutes } from '../../config/constants';
import EnterCredentialsScreen from './EnterCredentialsScreen';
import WelcomeScreen from './WelcomeScreen';
import EnterInviteIdScreen from './EnterInviteIdScreen';
import EnterNameEmailAndPasswordScreen from './EnterNameEmailAndPasswordScreen';
import RequestPasswordResetScreen from './RequestPasswordResetScreen';
import EnterClientUserIdScreen from './EnterClientUserIdScreen';

const Stack = createStackNavigator();

const LoginStack = () => (
  <Stack.Navigator
    initialRouteName={navigationRoutes.stacks.login.welcome}
    options={{ gestureEnabled: false }}
    headerMode="none">
    <Stack.Screen name={navigationRoutes.stacks.login.welcome} component={WelcomeScreen} />
    <Stack.Screen
      name={navigationRoutes.stacks.login.enterCredentials}
      component={EnterCredentialsScreen}
    />
    <Stack.Screen name={navigationRoutes.stacks.login.inviteId} component={EnterInviteIdScreen} />
    <Stack.Screen
      name={navigationRoutes.stacks.login.clientUserId}
      component={EnterClientUserIdScreen}
    />
    <Stack.Screen
      name={navigationRoutes.stacks.login.enterNameEmailAndPassword}
      component={EnterNameEmailAndPasswordScreen}
    />
    <Stack.Screen
      name={navigationRoutes.stacks.login.requestPasswordReset}
      component={RequestPasswordResetScreen}
    />
  </Stack.Navigator>
);

export default LoginStack;
