import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reaction } from 'mobx';
import { observer, inject } from 'mobx-react';
import { EnterInviteId } from '../../components/sign-up';
import { SimpleNavWrapper, HeaderIconButton } from '../../components/navigation';
import { LoadingShadeWrapper } from '../../components/common';
import { containerStyles } from '../../config/common-styles';
import { BackIcon } from '../../config/common-icons';
import { withKeyboardAvoidance, ScreenError } from '../common';
import { navigationRoutes } from '../../config/constants';
import { BrandContext } from '../../config/branding';

@inject('rootStore')
@observer
class EnterInviteIdScreen extends Component {
  static contextType = BrandContext;

  state = {
    inviteId: '',
  };

  componentDidMount() {
    const { rootStore } = this.props;
    reaction(
      () => rootStore.copiedInviteId,
      copiedInviteId => {
        this.setState({ inviteId: copiedInviteId });
      },
      { fireImmediately: true }
    );
  }

  _onPressJoin = async () => {
    const { rootStore } = this.props;
    const { settingsStore } = rootStore;

    await settingsStore.addCode({ inviteId: this.state.inviteId, source: 'signup' });
    if (rootStore.addCodeState.isDone) {
      await rootStore.markOnboardingStepComplete(navigationRoutes.stacks.onboarding.inviteId);
    }
  };

  _onPressNo = () => {
    const { rootStore } = this.props;

    rootStore.markOnboardingStepComplete(navigationRoutes.stacks.onboarding.inviteId);
  };

  _onPressBack = () => {
    const { navigation } = this.props;
    navigation.goBack();
  };

  render() {
    const { rootStore } = this.props;
    const { settingsStore } = rootStore;
    const { inviteId } = this.state;

    return (
      <SimpleNavWrapper
        leftButton={
          <HeaderIconButton
            cavyId="EnterInviteIdScreen.Back"
            icon={<BackIcon />}
            onPress={this._onPressBack}
          />
        }>
        <LoadingShadeWrapper
          coversOnboardingScreen
          isLoading={settingsStore.addCodeState.isPending}
          style={containerStyles.fillAvailableSpace}>
          <ScreenError state={settingsStore.addCodeState} ignoreFormErrors />
          <EnterInviteId
            isImportedInviteId={!!rootStore.copiedInviteId}
            importedInviteId={rootStore.copiedInviteId}
            canSkipInviteId
            onPressContinue={this._onPressJoin}
            onPressNo={this._onPressNo}
            inviteId={inviteId}
            onChangeInviteId={inviteId => this.setState({ inviteId })}
            formErrors={settingsStore.addCodeState.formErrors}
          />
        </LoadingShadeWrapper>
      </SimpleNavWrapper>
    );
  }
}

EnterInviteIdScreen.propTypes = {
  navigation: PropTypes.object,
};

export default withKeyboardAvoidance(EnterInviteIdScreen, {
  enabledForIos: false,
});
