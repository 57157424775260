import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { View, Text, Image } from 'react-native';
import { CircleThumbnail, NudgeTouchable as Touchable } from '/common/components';
import { textStyles, sizes, colors } from '../../config/common-styles';
import { common } from '../../config/strings';

const horizontalLayoutStyle = {
  flexDirection: 'row',
  justifyContent: 'flex-start',
  flex: 1,
  alignItems: 'center',
};

const rightSideVerticalLayoutStyle = {
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'center',
  paddingLeft: sizes.medium,
};

@observer
export default class ConversationSummary extends Component {
  render() {
    const { conversationSummary, onPress } = this.props;
    const summaryText = conversationSummary.lastMessageIsPhoto
      ? 'Picture message'
      : conversationSummary.lastMessageIsFileAttachment
      ? 'File Attachment'
      : conversationSummary.lastMessageIsMeeting
      ? 'Zoom Meeting'
      : conversationSummary.lastMessageText;
    return (
      <Touchable onPress={onPress} isTouchableOpacity useHoverStyle>
        <View style={[horizontalLayoutStyle, { padding: sizes.medium, alignItems: 'flex-start' }]}>
          <CircleThumbnail
            source={conversationSummary.photoSource}
            diameter={sizes.thumbnail}
            altTitle={common.fullName(conversationSummary)}
          />
          <View style={horizontalLayoutStyle}>
            <View style={rightSideVerticalLayoutStyle}>
              <Text
                numberOfLines={1}
                ellipsizeMode="tail"
                style={[textStyles.standard.semibold, { marginRight: sizes.small }]}>
                {common.fullName(conversationSummary)}
              </Text>
              {summaryText && summaryText.length && (
                <Text
                  numberOfLines={3}
                  ellipsizeMode="tail"
                  style={[textStyles.standard.light, { marginTop: 3 }]}>
                  {summaryText}
                </Text>
              )}
            </View>
            <View style={{ paddingLeft: 5 }}>
              <Image
                source={require('../../../common/assets/icons/chevron-right.png')}
                style={{
                  width: 20,
                  height: 20,
                  tintColor: colors.lightTextColor,
                }}
              />
            </View>
          </View>
        </View>
      </Touchable>
    );
  }
}

ConversationSummary.propTypes = {
  conversationSummary: PropTypes.object.isRequired,
  onPress: PropTypes.func.isRequired,
};
