import React, { useContext } from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import { observer, MobXProviderContext } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { CommentThread } from '../components/social';
import { NavbarWrapper } from '../components/navigation';
import {
  ScreenError,
  ScreenFirstLoadWrapper,
  withMessagingState,
  useScreenBehaviors,
  TriggerActionOnUpdateWhenFocused,
  AlertBarSuppressor,
} from '/common/navigation';

const SocialFeedThreadScreen = observer(
  ({
    navigation,
    route,
    t, // props from withMessagingState
    Linking,
    currentMessageText,
    currentMessageImage,
    currentMessageNotifyFlag,
    onSubmitMessage,
    onCurrentMessageTextChanged,
    onChooseImage,
    onRemoveImage,
    onOpenAttachment,
    onLongPressMessage,
    editModeEnabled,
    onCancelEditing,
    toggleNotifyFlag,
    showNotifyFlagToggle,
  }) => {
    // TODO: if accessed directly via notification, some errors may fail silently!
    // See comments in ScreenError for how to fix!

    const { rootStore } = useContext(MobXProviderContext);
    const { socialStore } = rootStore;

    // --- hooks ---

    useScreenBehaviors(
      {
        onFocus: () => socialStore.socialFeedForClub(route.params.clubId).loadComments(),
        onActive: () => socialStore.socialFeedForClub(route.params.clubId).loadComments(),
      },
      [route]
    );

    const clubId = route.params.clubId;
    const commentId = route.params.commentId;
    const perspective = rootStore.perspective;

    const socialFeed = socialStore.socialFeedForClub(clubId);
    const comment = socialFeed.commentForId(commentId);

    return (
      <NavbarWrapper title={socialFeed.title} hasBackButton navigation={navigation}>
        <AlertBarSuppressor
          suppressWhen={idPayload =>
            idPayload.parentId === clubId && idPayload.commentId === commentId
          }
        />
        <TriggerActionOnUpdateWhenFocused
          getWatchValue={() => rootStore.socialStore.latestSocialFeedUpdate}
          triggerWhen={update => update.clubId === clubId}
          onTrigger={() => socialFeed.loadComment(commentId)}
        />
        <ScreenFirstLoadWrapper state={socialFeed.loadCommentState} hasData={!!comment}>
          {comment ? (
            <View style={{ flex: 1 }}>
              <ScreenError
                state={socialFeed.sendCommentState}
                title={t('COMMUNITY:ERRORS:POST_REPLY')}
              />
              <ScreenError
                state={socialFeed.sendCommentState}
                title={t('COMMUNITY:ERRORS:DELETE_MESSAGE')}
              />
              <ScreenError
                state={socialFeed.updateCommentState}
                title={t('COMMUNITY:ERRORS:UPDATE_MESSAGE')}
              />
              <CommentThread
                showChooseImageButton={perspective === 'coach' || socialFeed.forumsUploadsEnabled}
                comment={comment}
                replies={
                  socialFeed.repliesSortedFor(commentId) /* use this to weave in pending replies */
                }
                onCurrentMessageTextChanged={onCurrentMessageTextChanged}
                onPressLink={Linking.openURL}
                currentMessage={{
                  text: currentMessageText,
                  image: currentMessageImage,
                  notifyFlag: currentMessageNotifyFlag,
                }}
                onPostReply={onSubmitMessage}
                onLongPressComment={onLongPressMessage}
                isOperationPending={
                  socialFeed.sendCommentState.isPending ||
                  socialFeed.updateCommentState.isPending ||
                  socialFeed.deleteCommentState.isPending
                }
                onPressAttachment={onOpenAttachment}
                onPressChooseImage={onChooseImage}
                onPressRemoveImage={onRemoveImage}
                editModeEnabled={editModeEnabled}
                onCancelEditing={onCancelEditing}
                toggleNotifyFlag={toggleNotifyFlag}
                showNotifyFlagToggle={showNotifyFlagToggle}
              />
            </View>
          ) : null}
        </ScreenFirstLoadWrapper>
      </NavbarWrapper>
    );
  }
);

SocialFeedThreadScreen.propTypes = {
  navigation: PropTypes.object,
  Linking: PropTypes.any,
  t: PropTypes.any,
};

const WrappedTopicScreen = withTranslation()(
  withMessagingState(SocialFeedThreadScreen, {
    getSendableStore: ({ route, rootStore }) => {
      let clubId; // derive from either only club, or params
      const { socialStore } = rootStore;

      // clearly from a pushed screen
      if (route.params) {
        clubId = route.params.clubId;
      } else {
        clubId = socialStore.clubs[0].id;
      }
      const socialFeed = socialStore.socialFeedForClub(clubId);
      return {
        sendMessage: async params =>
          await socialFeed.sendComment({ ...params, parentCommentId: route.params.commentId }),
        sendMessageState: socialFeed.sendCommentState,
        updateMessageState: socialFeed.updateCommentState,
        deleteMessageState: socialFeed.deleteCommentState,
        updateMessage: async params =>
          await socialFeed.updateComment({ ...params, parentCommentId: route.params.commentId }),
        deleteMessage: async params =>
          await socialFeed.deleteComment({ ...params, parentCommentId: route.params.commentId }),
      };
    },
    capabilities: ['edit', 'delete', 'deleteAll', 'notifyFlag'],
    getCurrentUser: ({ rootStore }) => rootStore.coachProfile,
  })
);

export default WrappedTopicScreen;
