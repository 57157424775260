import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react/native';
import { View, ActivityIndicator } from 'react-native';
import Styles from './styles';
import { colors } from '../../config/styles';
import { BrandContext } from '../../config/branding';
import ListErrorView from '../ListErrorView';

const LoadingWrapper = observer(
  ({ isLoading, isFailed, children, style, onPressRetry, failureMessage }) => (
    <View style={[style]}>
      {isLoading ? (
        <BrandContext.Consumer>
          {branding => (
            <ActivityIndicator
              size="large"
              style={Styles.spinner}
              color={branding.highlightedColor}
            />
          )}
        </BrandContext.Consumer>
      ) : null}
      {isFailed && !isLoading ? (
        <ListErrorView failureMessage={failureMessage} onPressRetry={onPressRetry} />
      ) : null}
      {!isLoading && !isFailed ? children : null}
    </View>
  )
);

LoadingWrapper.propTypes = {
  children: PropTypes.any,
  failureMessage: PropTypes.string,
  isFailed: PropTypes.bool,
  isLoading: PropTypes.bool,
  onPressRetry: PropTypes.func,
  style: PropTypes.any,
};

LoadingWrapper.defaultProps = {
  isLoading: false,
  isFailed: false,
  failureMessage: 'Loading failed',
  children: null,
  onPressRetry: null,
  style: null,
};

export default LoadingWrapper;
