import React, { Component } from 'react';
import { reaction } from 'mobx';
import { inject } from 'mobx-react';
import { useNavigation } from '@react-navigation/native';
import { InAppNotificationBar, connectAlertBar } from '/common/navigation';
import { navigateToCoach } from '../../lib/navigation-utils';
import { common } from '../../config/strings';

const showNotification = ({
  shouldShowMessageBar,
  onPressMessageBar,
  getNotificationMessage,
  getThumbnailSource = () => null,
  getThumbnailAltTitle = () => null,
  navigation,
  showAlertBar,
  idPayload,
}) => {
  if (shouldShowMessageBar()) {
    showAlertBar({
      view: (
        <InAppNotificationBar
          message={getNotificationMessage()}
          thumbnailAltTitle={getThumbnailAltTitle()}
          thumbnailSource={getThumbnailSource()}
        />
      ),
      onPress: () => onPressMessageBar(navigation),
      idPayload,
    });
  }
};

/**
 * pop-down bar notifying of new message (while in app), which only appears if the user is not already in chat
 */
@inject('rootStore')
@connectAlertBar
class NewMessageBar extends Component {
  componentDidMount() {
    const { navigation, rootStore, showAlertBar } = this.props;

    // New coach message
    this.onNewMessageReactionDisposer = reaction(
      () => rootStore.conversationsStore.latestUpdateFromCoach,
      notification => {
        const coach = rootStore.conversationsStore.coachForLatestNewMessage;
        if (notification && coach) {
          showNotification({
            navigation,
            showAlertBar,
            shouldShowMessageBar: () => notification && notification.isNewMessage && coach,
            getNotificationMessage: () => `New message from ${common.fullName(coach)}.`,
            getThumbnailAltTitle: () => common.fullName(coach),
            getThumbnailSource: () => coach.photoSource,
            onPressMessageBar: navigation => {
              // careful before we migrate this! This notification payload is from pusher!
              // We could probably just make it from a push notification
              navigateToCoach({
                navigation,
                coachCount: rootStore.settingsStore.coachCount,
                onInitConversation: () => rootStore.conversationsStore.initConversation(coach.id),
                // might not be needed anymore since message bar only exists in logged in protected route
                isReadyToNavigateFn: () => rootStore.baseProtectedRoute === 'loggedIn',
              });
            },
            idPayload: { senderId: coach.id },
          });
        }
      }
    );

    // new group message from coach
    this.onNewGroupMessageReactionDisposer = reaction(
      () => rootStore.socialStore.latestNotification,
      notification => {
        const group = rootStore.socialStore.clubForLatestNotification;
        const coach = rootStore.conversationsStore.coaches.find(
          c => c.id === notification.author_id
        );
        showNotification({
          navigation,
          showAlertBar,
          shouldShowMessageBar: () => notification,
          getNotificationMessage: () => {
            const senderName = notification.sender_name;
            if (!senderName) {
              return `A new ${notification.parent_id ? 'reply' : 'post'} was just shared in ${
                group.title
              }`;
            }
            if (group) {
              if (notification.parent_id) {
                return `${senderName} just replied to a post in ${group.title}.`;
              }
              return `${senderName} just shared a post in ${group.title}.`;
            }
            return `${senderName} just shared a post in your group.`;
          },
          getThumbnailAltTitle: () => (notification.sender_name ? notification.sender_name : null),
          getThumbnailSource: () => (coach ? coach.photoSource : null),
          onPressMessageBar: () => {
            rootStore.appLinkProcessor.processLink({
              link: { source: 'notification', data: notification },
            });
          },
          idPayload: notification,
        });
      }
    );

    // New other notifications (new card for now)
    this.onForegroundNotificationDisposer = reaction(
      () => rootStore.foregroundNotification,
      notification => {
        console.log('show the notification')
        showNotification({
          navigation,
          showAlertBar,
          shouldShowMessageBar: () => notification,
          getNotificationMessage: () => {
            return notification.body;
          },
          getThumbnailAltTitle: () => null,
          getThumbnailSource: () => null,
          onPressMessageBar: () => {
            rootStore.appLinkProcessor.processLink({
              link: { source: 'notification', data: notification },
            });
          },
          idPayload: notification,
        });
      }
    );
  }

  componentWillUnmount() {
    this.onNewMessageReactionDisposer && this.onNewMessageReactionDisposer();
  }

  render() {
    return null;
  }
}

export default function(props) {
  const navigation = useNavigation();

  return <NewMessageBar {...props} navigation={navigation} />;
}
