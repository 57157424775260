import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FlatList, View, Text, TouchableWithoutFeedback, Image } from 'react-native';
import { observer } from 'mobx-react';
import { isIphoneX } from 'react-native-iphone-x-helper';
import { useTranslation } from 'react-i18next';
import { sizes, textStyles, colors } from '/common/config/styles';
import { TextWithLinks } from '/common/components';
import { UpdateIcon } from '/common/config/icons';
import { SeparatorLine } from '../../components/common';
import { BrandContext } from '/common/config/branding';

const headerStyle = {
  marginHorizontal: sizes.large,
  marginTop: sizes.large,
};

const rightSideVerticalLayoutStyle = {
  flex: 1,
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
};

const BluetoothPeripheral = observer(({ peripheral, onPressPeripheral }) => {
  return (
    <TouchableWithoutFeedback onPress={() => onPressPeripheral(peripheral)}>
      <View
        style={{
          marginHorizontal: sizes.large,
        }}>
        <View
          style={[
            {
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginVertical: sizes.medium,
            },
          ]}>
          <View style={rightSideVerticalLayoutStyle}>
            <Image
              style={{
                height: sizes.thumbnail,
                width: sizes.thumbnail,
                marginRight: sizes.medium,
              }}
              source={{ uri: peripheral.imageUrl }}
            />
            <Text
              numberOfLines={1}
              ellipsizeMode="tail"
              style={[
                peripheral.isPaired ? textStyles.standard.semibold : textStyles.standard.dark,
                { flex: 1, marginRight: sizes.medium },
              ]}>
              {peripheral.name}
            </Text>
          </View>
          {peripheral.isPaired && <UpdateIcon size={sizes.large + 2} />}
          {!peripheral.isPaired && (
            <View
              style={{
                height: sizes.large,
                width: sizes.large,
                borderRadius: sizes.large / 2,
                borderColor: colors.lightText,
                borderWidth: 1,
              }}
            />
          )}
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
});

const BluetoothPeripheralsList = observer(
  ({ bluetoothPeripherals, onPressPeripheral, showTitle, onPressUrl }) => {
    const { t } = useTranslation();
    const branding = useContext(BrandContext);
    return (
      <FlatList
        data={bluetoothPeripherals}
        style={{ backgroundColor: colors.bg0 }}
        keyExtractor={i => i.peripheralId.toString()}
        renderItem={({ item }) => (
          <BluetoothPeripheral peripheral={item} onPressPeripheral={onPressPeripheral} />
        )}
        ItemSeparatorComponent={() => <SeparatorLine style={{ marginHorizontal: sizes.large }} />}
        ListHeaderComponent={
          <View style={headerStyle}>
            {showTitle ? (
              <>
                <Text style={textStyles.bold.dark}>Connect Devices</Text>
                <View style={{ height: sizes.small }} />
              </>
            ) : null}
            <Text style={textStyles.standard.light}>
              Select a product name to connect the device.
            </Text>
            {branding.signUp.connectBluetoothPeripheralsAdditionalAdvice ? (
              <TextWithLinks style={textStyles.standard.light} onPressUrl={onPressUrl}>
                {branding.signUp.connectBluetoothPeripheralsAdditionalAdvice}
              </TextWithLinks>
            ) : null}
          </View>
        }
        ListFooterComponent={
          isIphoneX() ? <View style={{ height: sizes.iphoneXBottomPadding }} /> : null
        }
      />
    );
  }
);

BluetoothPeripheralsList.propTypes = {
  bluetoothPeripherals: PropTypes.arrayOf(PropTypes.object).isRequired,
  onPressPeripheral: PropTypes.func.isRequired,
  showTitle: PropTypes.bool,
  showAdditionalAdvice: PropTypes.bool,
  onPressUrl: PropTypes.func,
};

BluetoothPeripheralsList.defaultProps = {
  showTitle: false,
  showAdditionalAdvice: false,
  onPressUrl: () => {},
};

export default BluetoothPeripheralsList;
