import Constants from 'expo-constants';
import { Platform } from 'react-native';
const DEVICE_NAME = Constants.deviceName;

/* see issue with LG phones: https://github.com/facebook/react-native/issues/17474 */
/* yet the only thing that worked on our G6 was this: https://github.com/facebook/react-native/issues/12988#issuecomment-407550185 */
// We saw this with some old android 6.x Samsung phones
export default () => {
  return (
    Platform.OS === 'android' &&
    DEVICE_NAME &&
    (DEVICE_NAME.startsWith('LG') || DEVICE_NAME.startsWith('SM'))
  );
};
