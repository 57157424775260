import React, { Component } from 'react';
import { Keyboard } from 'react-native';
import PropTypes from 'prop-types';
import { throttle } from 'lodash';
import { reaction } from 'mobx';
import { withAlert } from './useAlert';
import {
  isNetworkError,
  isAccessedRemovedError,
  isInvalidSessionError,
  isServerUnavailableError,
} from '../lib/errors';

/**
 * A component that can be added anywhere in the screen that will display an error when the
 * provided state's isFailed is true.
 * Note that this may not work if state is originally null. Still need to check into that.
 * This is because we start the listener on componentDidMount().
 */
class ScreenError extends Component {
  constructor(props) {
    super(props);
    this._throttledDisplayError = throttle(this._displayError, this.props.throttleMs, {
      leading: true,
    });
  }
  componentDidMount() {
    const { state } = this.props;
    this.onLogErrorReactionDisposer = reaction(() => state.isFailed, this._throttledDisplayError);
  }

  componentWillUnmount() {
    if (this.onLogErrorReactionDisposer) {
      this.onLogErrorReactionDisposer();
    }
  }

  _displayError = isFailed => {
    const {
      type,
      title,
      message,
      isFocused,
      networkErrorMessage,
      accessedRemovedErrorMessage,
      serverUnavailableErrorMessage,
      unexpectedErrorMessage,
      state,
      ignoreNetworkErrors,
      ignoreFormErrors,
      ErrorAlertBarComponent,
      showAlertBar,
      alert,
    } = this.props;
    let finalErrorMessage;
    // don't show anything if session is no longer valid
    if (isInvalidSessionError(state.error)) {
      return;
    }
    if (typeof message === 'function') {
      // pass state as well so handlers can tap into state.formErrors
      finalErrorMessage = message(state.error, state);
    } else {
      finalErrorMessage = message;
    }

    if (ignoreFormErrors && state.formErrors && state.formErrors.length) {
      return;
    }

    // override custom error message with generic error message based on common scenarios
    if (isNetworkError(state.error)) {
      // option to ignore network errors because sometimes we show error messages for non-critical processes
      // that will be executed later when the network is available (like syncing)
      if (ignoreNetworkErrors) {
        return;
      }
      finalErrorMessage = networkErrorMessage;
    } else if (isAccessedRemovedError(state.error)) {
      finalErrorMessage = accessedRemovedErrorMessage;
    } else if (isServerUnavailableError(state.error)) {
      finalErrorMessage = serverUnavailableErrorMessage;
    } else {
      finalErrorMessage = finalErrorMessage || unexpectedErrorMessage;
    }
    const finalTitle = title;
    if (isFocused && isFailed) {
      if (type === 'messageBar') {
        showAlertBar({
          view: <ErrorAlertBarComponent title={finalTitle} message={finalErrorMessage} />,
        });
      } else {
        // Avoid keyboard dismiss wierdness with this: https://github.com/facebook/react-native/issues/17356
        Keyboard.dismiss();
        setTimeout(() => {
          alert(finalTitle, finalErrorMessage);
        }, 150);
      }
    }
  };

  _throttledDisplayError = () => {};

  render() {
    return null;
  }
}

ScreenError.propTypes = {
  state: PropTypes.shape({ isFailed: PropTypes.bool, error: PropTypes.any }),
  isFocused: PropTypes.bool.isRequired,
  type: PropTypes.string, // alert, messageBar
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  networkErrorMessage: PropTypes.string,
  accessedRemovedErrorMessage: PropTypes.string,
  serverUnavailableErrorMessage: PropTypes.string,
  unexpectedErrorMessage: PropTypes.string,
  throttleMs: PropTypes.number,
  ignoreNetworkErrors: PropTypes.bool,
  // Ignore errors on LoadingState where formErrors prop is populated.
  // These are usually added when the error is related to validating a particular field
  // and we may want to show the error next to the field.
  ignoreFormErrors: PropTypes.bool,
  ErrorAlertBarComponent: PropTypes.any.isRequired,
  showAlertBar: PropTypes.func,
};

ScreenError.defaultProps = {
  state: null,
  type: 'alert',
  title: 'Error',
  message: null,
  networkErrorMessage: null,
  accessedRemovedErrorMessage: null,
  serverUnavailableErrorMessage: null,
  unexpectedErrorMessage: null,
  throttleMs: 0,
  ignoreNetworkErrors: false,
  ignoreFormErrors: false,
  showAlertBar: () => {},
};

export default withAlert(ScreenError);
