import { debounce } from 'lodash';
import { Linking } from '/common/stores';

export default class LinkListener {
  onReceiveLink = () => {};

  _onLink = link => {
    console.log('Recieved link!');
    console.log(link);
    // listener returns object, getIntialURL() returns string. I don't get it!
    this.onReceiveLink(link.url ? { source: 'url', url: link.url } : { source: 'url', url: link });
  };

  // debounce due to bug where native branch keeps multiple events if JavaScript is reloaded without reloading binary
  _onLink_debounced = debounce(this._onLink, 1000);

  async startListening() {
    const link = await Linking.getInitialURL();
    if (link) {
      this._onLink_debounced(link);
    }
    Linking.addEventListener('url', this._onLink_debounced);
  }
}
