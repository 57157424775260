import React from 'react';
import { View, Text } from 'react-native';
import { sizes, textStyles } from '/common/config/styles';

/**
 * If any image attribution for the card is required per the Unsplash TOS, displays said attribution
 * Supports multiple images/ creators
 */
const ImageAttribution = ({ programCard, onPressLink }) => {
  return programCard.imageAttributionSummary.authors.length ? (
    <View
      style={{
        paddingTop: sizes.large * 2,
        paddingBottom: sizes.medium,
        paddingHorizontal: sizes.medium,
      }}>
      <Text style={[textStyles.small.light, { textAlign: 'center' }]}>
        {programCard.imageAttributionSummary.images.length > 1 ? 'Photos' : 'Photo'} from{' '}
        <Text>
          {programCard.imageAttributionSummary.authors.map((author, index) => (
            <Text key={author.authorName}>
              <Text
                onPress={() =>
                  onPressLink(author.authorUrl + '/?utm_source=Nudge%20Coach&utm_medium=referral')
                }
                style={textStyles.link}>
                {author.authorName}
              </Text>
              {index !== programCard.imageAttributionSummary.authors.length - 1 ? ', ' : ''}
            </Text>
          ))}
        </Text>{' '}
        on{' '}
        <Text
          onPress={() =>
            onPressLink('https://unsplash.com/?utm_source=Nudge%20Coach&utm_medium=referral')
          }
          style={textStyles.link}>
          Unsplash
        </Text>
      </Text>
    </View>
  ) : null;
};

export default ImageAttribution;
