import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SectionList, Text, View } from 'react-native';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { withAlert } from '/common/navigation/';
import { RowChevronButton, ListSeparatorLine, SectionHeader } from '/common/components';
import { NavbarWrapper, HeaderIconButton } from '../../app/components/navigation';
import { LoadingWrapper } from '../../app/components/common/';
import { BackIcon } from '../../app/config/common-icons';
import { colors, containerStyles, textStyles } from '/common/config/styles';
import { navigationRoutes } from '../../app/config/constants';

@inject('rootStore')
@observer
class CommunityNotifications extends Component {
  componentDidMount() {
    // on coach-mobile loadClubs is not loaded on login so this needs to be called
    if (!this.props.rootStore.socialStore.loadClubsState.isDone) {
      this.props.rootStore.socialStore.loadClubs();
    }
  }
  render() {
    const { rootStore, navigation, t } = this.props;

    const { socialStore } = rootStore;

    const buildTextComponent = title => () => <Text style={textStyles.standard.dark}>{title}</Text>;

    const sections = [];

    const communitiesObject = {
      title: t('SETTINGS:NOTIFICATIONS:COMMUNITIES'),
      data: socialStore.clubs,
      renderItem: ({ item }) => (
        <RowChevronButton
          onPress={() =>
            navigation.navigate(navigationRoutes.modals.individualCommunityNotifications, {
              communityName: item.title,
              communityId: item.id,
              communityNotificationLevel: item.notificationLevel,
            })
          }
          LabelComponent={buildTextComponent(item.title)}
        />
      ),
    };

    sections.push(communitiesObject);

    return (
      <NavbarWrapper
        title={t('SETTINGS:NOTIFICATIONS:NOTIFICATIONS')}
        leftButton={
          <HeaderIconButton
            icon={<BackIcon />}
            onPress={() => {
              navigation.pop();
            }}
          />
        }
        navigation={navigation}>
        <LoadingWrapper style={{ flex: 1 }} isLoading={!socialStore.loadClubsState.isDone}>
          {socialStore.clubs.length > 0 ? (
            <SectionList
              failureMessage={t('SETTINGS:LOAD_FAILURE')}
              sections={sections}
              keyExtractor={index => index}
              renderSectionHeader={({ section }) => (
                <SectionHeader
                  title={section.title}
                  style={{
                    backgroundColor:
                      //isTabletOrDesktop ? colors.bg0 :
                      colors.bg1,
                  }}
                />
              )}
              ItemSeparatorComponent={ListSeparatorLine}
              style={[containerStyles.fillAvailableSpace, { backgroundColor: colors.bg1 }]}
            />
          ) : (
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
              <Text style={textStyles.small.dark}>
                {t('SETTINGS:NOTIFICATIONS:NO_COMMUNITIES')}
              </Text>
            </View>
          )}
        </LoadingWrapper>
      </NavbarWrapper>
    );
  }
}

CommunityNotifications.propTypes = {
  rootStore: PropTypes.object,
  t: PropTypes.func.isRequired,
  navigation: PropTypes.object,
};

export default withTranslation()(withAlert(CommunityNotifications));
