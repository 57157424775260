import React, { Component } from 'react';
import { Platform, KeyboardAvoidingView } from 'react-native';

// provides keyboard avoidance by adjusting bottom padding of the view to match the size of the keyboard.
// We allow ios to be disabled because for many scroll views, we'll use the inset
// For better avoidance.
const withKeyboardAvoidance = (
  WrappedComponent,
  options = { enabledForIos: true, androidBehavior: 'padding' }
) => {
  // disabling because adjustResize is working
  if (Platform.OS === 'android') {
    return WrappedComponent;
  }
  // this breaks when there's more than one flag
  if (options && !options.enabledForIos && Platform.OS === 'ios') {
    return WrappedComponent;
  }
  // see: https://github.com/facebook/react-native/issues/27526 for padding/ height issue
  // BUT: height poses other issues, see https://github.com/facebook/react-native/issues/28004
  // So, we use padding on the log screen
  const NewComponent = class extends Component {
    render() {
      return (
        <KeyboardAvoidingView
          style={{ flex: 1 }}
          behavior={Platform.select({
            android: options && options.androidBehavior ? options.androidBehavior : 'height',
            ios: 'padding',
          })}>
          <WrappedComponent {...this.props} />
        </KeyboardAvoidingView>
      );
    }
  };

  NewComponent.displayName = 'withKeyboardAvoidance';

  return NewComponent;
};

// provides keyboard avoidance by adjusting bottom padding of the view to match the size of the keyboard.
// We allow ios to be disabled because for many scroll views, we'll use the inset
// For better avoidance.
const theRightKeyboardAvoidance = withKeyboardAvoidance;

export default theRightKeyboardAvoidance;
