import { types, flow, getEnv } from 'mobx-state-tree';
import { findIndex } from 'lodash';
import { LoadingState } from 'nudge-client-common/stores';
import SocialStore from './social';

const TabBadgeData = types.model('TabBadgeData', {
  messages: types.optional(types.number, 0),
  social: types.optional(types.number, 0),
});

const OnboardingStep = types.model('OnboardingStep', {
  route: types.string,
  isStepComplete: types.optional(types.boolean, false),
});

/**
 * Base root store shared between all apps. Currently only contains:
 * - open file services
 * - some onboarding handling
 * - some tab badge handling
 * - base protected route logic
 *
 * Should be able to share a lot more login logic someday
 */
const RootStore = types
  .model('RootStoreBase', {
    isLoggedIn: types.optional(types.boolean, false),
    initState: types.optional(LoadingState, {}),
    onboardingActionState: types.optional(LoadingState, {}),
    // central services
    openShareableFileInOsViewerState: types.optional(LoadingState, {}),
    // queue of steps required for post-login / sign-up onboarding. Login will populate these based on the
    // status of the user, and then the app will kick over to the onboarding screens
    onboardingScreens: types.optional(types.array(OnboardingStep), []),
    tabBadges: types.optional(TabBadgeData, {}),
    // social store is common to everyone
    socialStore: types.optional(SocialStore, {}),
    perspective: types.enumeration(['client', 'coach']),
    // OTA update status
    otaUpdateStatus: types.frozen(),
  })
  .views(self => ({
    // the app is divided into one of three protected routes:
    // 1) init - we don't know what state we're in, so throw up a spinner while we figure it out
    // 2) loggedOut - not logged in, haven't created an account yet
    // 3) loggedIn - logged in, navigate the app as normal
    // 4) onboarding - loggedIn, but there's required configuration steps that are needed
    get baseProtectedRoute() {
      if (
        !self.initState.isDone &&
        !self.initState.isNotStarted /* gets restarted when rootStore state is reset */
      ) {
        return 'init';
      }
      if (self.isLoggedIn && self.onboardingScreens.length) {
        return 'onboarding';
      }
      if (self.isLoggedIn) {
        return 'loggedIn';
      }
      return 'loggedOut';
    },
  }))
  .actions(self => {
    // -- end private --

    // --- init / login / logout ---
    // This may be called anytime we need to reinitialize who is logged in
    // (e.g., after a new user registration)
    const init = flow(function* init() {});

    // -- badges --

    const clearSocialUnreadCount = () => {
      self.tabBadges.social = 0;
    };

    const clearMessagesUnreadCount = () => {
      self.tabBadges.messages = 0;
    };

    // -- central services --

    const openShareableFileInOsViewer = flow(function* openShareableFileInOsViewer(fileId) {
      const { IntentLauncher, WebBrowser, Platform } = getEnv(self);
      self.openShareableFileInOsViewerState.setPending();
      try {
        const url = yield getEnv(self).commonRepository.getShareableUrl(fileId);
        self.openShareableFileInOsViewerState.setDone();
        if (Platform.OS === 'ios') {
          WebBrowser.dismissBrowser();
          WebBrowser.openBrowserAsync(url);
        } else if (Platform.OS === 'android') {
          yield IntentLauncher.startActivityAsync('android.intent.action.VIEW', {
            type: 'application/pdf',
            data: url,
          });
        } else if (Platform.OS === 'web') {
          window.open(url, '_blank');
        }
      } catch (error) {
        self.openShareableFileInOsViewerState.setFailed(error);
      }
    });

    // -- onboarding --

    const markOnboardingStepComplete = flow(function* markOnboardingStepComplete(route) {
      const screenIndex = findIndex(self.onboardingScreens, os => os.route === route);
      // if last screen, remove all onboarding steps, which causes onboarding to finish
      if (screenIndex === self.onboardingScreens.length - 1) {
        self.onboardingScreens = [];
      } else if (screenIndex > -1) {
        // move ahead to next screen
        const navigation = yield getEnv(self).getNavigation();
        navigation.navigate(self.onboardingScreens[screenIndex + 1].route);
      }
    });

     // --- OTA Updates ---
     const setOtaUpdateStatus = status => {
      self.otaUpdateStatus = status;
    };

    const reloadIfOtaUpdateAvailable = flow(function* reloadIfOtaUpdateAvailable() {
      yield getEnv(self).otaUpdateListener.reloadIfUpdateAvailable();
    });

    const checkForOtaUpdate = flow(function* checkForOtaUpdate() {
      yield getEnv(self).otaUpdateListener.forceCheckAndUpdate();
    });

    // event logging

    /**
     * matches web's amplitude.getInstance().logEventAsync()
     * Could also directly call getEnv(self).Amplitude.logEventAsync() or logEventWithPropertiesAsync() from within
     * another MST store.
     * Properties are now passed as a function so accessing invalid properties does not crash anything!
     */
     const logEvent = (eventName, propertiesFn) => {
      const Amplitude = getEnv(self).Amplitude;
      if (!propertiesFn) {
        Amplitude.logEventAsync(eventName);
      } else {
        try {
          const properties = propertiesFn();
          Amplitude.logEventWithPropertiesAsync(eventName, properties);
        } catch (error) {
          console.warn(
            `OH NO, your Amplitude event called ${eventName} errored out. Is something wrong with your props?`
          );
        }
      }
    };

    // basically log a Sentry error without being a Sentry
    const logErrorReportingMessage = message => {
      getEnv(self).errorReporter.captureMessage(message);
    };

    // log a breadcrumb, which will show up in an error if an error occurs
    const logErrorReportingBreadcrumb = ({ category, message }) => {
      const errorReporter = getEnv(self).errorReporter;
      errorReporter.addBreadcrumb({
        category,
        message,
        level: 'debug',
      });
    };

    return {
      init,
      openShareableFileInOsViewer,
      markOnboardingStepComplete,
      clearSocialUnreadCount,
      clearMessagesUnreadCount,
      // OTA updates,
      setOtaUpdateStatus,
      reloadIfOtaUpdateAvailable,
      checkForOtaUpdate,
      // diagnostics
      logEvent,
      logErrorReportingMessage,
      logErrorReportingBreadcrumb,
    };
  });

export default RootStore;
