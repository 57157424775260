import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Text, View, TouchableWithoutFeedback } from 'react-native';
import { textStyles, sizes, containerStyles, colors } from '/common/config/styles';

/**
 * A generic detail that has a title, an editor component, and a read-only component.
 * Provides a ref to the editor so it can be focused, etc.
 */
@observer
class FieldContainer extends Component {
  render() {
    const {
      title,
      style,
      childrenContainerStyle,
      children,
      onPress,
      validationError,
      isRequired,
      canEdit,
    } = this.props;

    const insideOnPress = (
      <View style={[{ paddingVertical: isRequired ? sizes.medium : 0 }, style]}>
        {title && isRequired && (
          <Text
            style={[
              isRequired && canEdit ? textStyles.standard.dark : textStyles.standard.light,
              { textTransform: 'capitalize' },
            ]}>
            {title}
          </Text>
        )}
        <View style={[containerStyles.fillAvailableSpace]}>
          <View
            style={[
              containerStyles.horizontalChildrenMaxSpaceBetween,
              { paddingTop: title ? sizes.small : 0 },
              childrenContainerStyle,
            ]}>
            {children}
          </View>
        </View>
        {validationError && (
          <Text
            allowFontScaling={false}
            style={[
              textStyles.standard.dark,
              { color: colors.error, marginTop: sizes.small / 2, textAlign: 'left' },
            ]}>
            {validationError}
          </Text>
        )}
      </View>
    );
    if (!onPress) {
      return insideOnPress;
    }
    return <TouchableWithoutFeedback onPress={onPress}>{insideOnPress}</TouchableWithoutFeedback>;
  }
}

FieldContainer.propTypes = {
  style: PropTypes.any,
  title: PropTypes.string,
  children: PropTypes.any.isRequired,
  onPress: PropTypes.func,
  isRequired: PropTypes.bool,
};

FieldContainer.defaultProps = {
  style: null,
  title: null,
  onPress: null,
};

export default FieldContainer;
