import React, { useEffect } from 'react';
import { View, Text, TouchableOpacity, ActivityIndicator } from 'react-native';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import { useTimer } from 'use-timer';
import { sizes, colors, textStyles } from '/common/config/styles';
import { getStandardErrorMessage } from '../lib/errors';

const BarWrapper = ({ children, style }) => {
  return (
    <View style={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
      <View
        style={[
          {
            backgroundColor: colors.bg2,
            paddingVertical: sizes.small / 2,
            paddingHorizontal: sizes.medium,
          },
          style,
        ]}>
        {children}
      </View>
    </View>
  );
};

const RefreshWarningBar = observer(function({
  onPressRefresh,
  loadingState,
  //errorTitle,
  errorMessage,
  networkErrorMessage,
  serverUnavailableErrorMessage,
  actionMessage,
  //errorContainerStyle,
}) {
  const { time, start, reset, status } = useTimer();
  useEffect(() => {
    const disposer = reaction(
      () => loadingState.isPending,
      value => {
        // just started loading
        if (value) {
          start();
        }
        // just stopped loading
        if (!value) {
          reset();
        }
      }
    );
    return disposer;
  }, []);

  // last load has an error
  if (loadingState.isFailed) {
    const finalErrorMessage = getStandardErrorMessage({
      error: loadingState.error,
      serverUnavailableErrorMessage,
      networkErrorMessage,
      defaultErrorMessage: errorMessage,
    });
    return (
      <BarWrapper
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: colors.warning,
        }}>
        <TouchableOpacity
          onPress={onPressRefresh}
          style={{ flex: 1 }}
          hitSlop={{
            top: sizes.small / 2,
            bottom: sizes.small,
            left: sizes.medium,
            right: sizes.medium,
          }}>
          <View>
            <Text style={textStyles.small.dark}>{finalErrorMessage}</Text>
            <Text style={textStyles.small.light}>Tap to refresh to try again</Text>
          </View>
        </TouchableOpacity>
      </BarWrapper>
    );
  }
  // last load had an error, but we're trying to load again
  if (loadingState.isPending && loadingState.isInFirstPendingStateSinceError) {
    return (
      <BarWrapper
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <View>
          <Text style={textStyles.small.dark}>Refreshing...</Text>
        </View>
        <ActivityIndicator size="small" color={colors.darkText} />
      </BarWrapper>
    );
  }

  // no error yet, but taking a long time
  if (loadingState.isPending && time > 2) {
    return (
      <BarWrapper
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <View>
          <Text style={textStyles.small.dark}>Still refreshing...</Text>
        </View>
        <ActivityIndicator size="small" color={colors.darkText} />
      </BarWrapper>
    );
  }
  return null;
});

export default RefreshWarningBar;
