import React, { useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { View, TextInput, StyleSheet } from 'react-native';
import { useTranslation } from 'react-i18next';
import {
  textStyles,
  containerStyles,
  colors,
  fonts,
  sizes,
  useMediaQueryInfo,
} from '/common/config/styles';
import { RoundButton, AestheticallyPleasingListFooter } from '/common/components';

/**
 * Intended for any kind of code that needs to be entered to enable functionality
 * Could be an invite ID or something else at this point
 */
const EnterCodeForm = observer(({ codeFieldPlaceholder, onSubmit }) => {
  const { t } = useTranslation();
  const [code, setCode] = useState('');
  const onSubmitCallback = useCallback(() => {
    onSubmit(code);
  }, [code, onSubmit]);

  const { innerWindowWidth } = useMediaQueryInfo();
  return (
    <View
      style={{
        backgroundColor: colors.bg1,
        flex: 1,
        alignItems: 'center',
      }}>
      <View
        style={[
          containerStyles.fillAvailableSpace,
          {
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: colors.bg0,
            width: innerWindowWidth,
          },
        ]}>
        <TextInput
          style={[
            textStyles.standard.dark,
            {
              fontSize: fonts.sizes.medium,
              width: '60%',
              textAlign: 'center',
              padding: 10,
              borderRadius: sizes.borderRadius,
              borderColor: colors.border0,
              borderWidth: StyleSheet.hairlineWidth,
              backgroundColor: colors.bg1,
            },
          ]}
          placeholder={codeFieldPlaceholder}
          onChangeText={setCode}
          value={code}
          autoFocus
          autoCorrect={false}
          autoCapitalize="none"
          blurOnSubmit={false}
          onSubmitEditing={onSubmitCallback}
        />
        <View style={{ paddingTop: 25 }}>
          <RoundButton title={t('PROFILE:BUTTONS:SUBMIT')} onPress={onSubmitCallback} />
        </View>
      </View>
      <AestheticallyPleasingListFooter />
    </View>
  );
});

EnterCodeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  // the name of the ID we're accepting
  codeFieldPlaceholder: PropTypes.string.isRequired,
};

export default EnterCodeForm;
