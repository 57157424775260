import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableOpacity } from 'react-native';
import { sizes, colors, textStyles } from '/common/config/styles';
import { BrandContext } from '../config/branding';

const TopTabStylePicker = ({ buttons, onPressButton, selectedKey, style, selectedTabColor }) => {
  const branding = useContext(BrandContext);
  const mySelectedTabColor = selectedTabColor || branding.highlightedColor;
  return (
    <View
      style={[
        {
          width: '100%',
          flexDirection: 'row',
          borderBottomWidth: 1,
          borderColor: colors.bg1,
        },
        style,
      ]}>
      {buttons.map(button => (
        <TouchableOpacity
          key={button.key}
          onPress={() => onPressButton(button.key)}
          style={{
            flexGrow: 1,
            borderBottomWidth: 2,
            borderBottomColor: selectedKey === button.key ? mySelectedTabColor : 'transparent',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Text
            allowFontScaling={false}
            style={[
              textStyles.small.light,
              {
                textAlign: 'center',
                fontWeight: 'bold',
                color: selectedKey === button.key ? colors.darkText : colors.lightText,
              },
            ]}>{`${button.title.toUpperCase()}`}</Text>
        </TouchableOpacity>
      ))}
    </View>
  );
};

TopTabStylePicker.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.any.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  onPressButton: PropTypes.func.isRequired,
  selectedKey: PropTypes.any.isRequired,
  style: PropTypes.any,
};

TopTabStylePicker.defaultProps = {
  style: null,
};

export default TopTabStylePicker;
