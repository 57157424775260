import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './localization/en';
import es from './localization/es';
import fr from './localization/fr';
import he from './localization/he';

// creating a language detection plugin using expo
// http://i18next.com/docs/ownplugin/#languagedetector

const languageDetector = {
  type: 'languageDetector',
  async: false, // flags below detection to be async
  detect: () => {
    'en';
    // TODO: turn back on language detection, except by referencing branding context or async branding
    //return branding.language; /* Expo.Util.getCurrentLocaleAsync().then(lng => { callback(lng.replace('_', '-')); }) */
  },
  init: () => {},
  cacheUserLanguage: () => {},
};

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    react: {
      wait: false,
      withRef: false,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default',
    },

    resources: {
      en,
      es,
      fr,
      he,
    },

    // have a common namespace used around the full app
    ns: ['common'],
    defaultNS: 'common',

    debug: false,

    // cache: {
    //   enabled: true
    // },

    interpolation: {
      escapeValue: false, // not needed for react as it does escape per default to prevent xss!
    },
  });

export default i18n;
