import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react/native';
import { WebView } from 'react-native-webview';
import { withTranslation } from 'react-i18next';
import { SimpleNavWrapper, SimpleNavButton } from '../../components/navigation';
import { LoadingShadeWrapper } from '../../components/common';
import { ScreenError } from '../common';
import { containerStyles } from '../../config/common-styles';
import { navigationRoutes } from '../../config/constants';

const injectedJavaScript = `
  window.openURL = function(url) {
    var payload = { action: 'openUrl', data: url};
    window.postMessage(JSON.stringify(payload));
  }
`;

@inject('Linking', 'rootStore')
@observer
class TutorialScreen extends Component {
  componentDidMount() {
    this.props.rootStore.getOnboardingTutorial();
  }

  _onPressNext = async () => {
    const { rootStore } = this.props;

    rootStore.markOnboardingStepComplete(navigationRoutes.stacks.onboarding.tutorial);
  };

  _onMessageFromBrowser = event => {
    const messageObj = JSON.parse(event.nativeEvent.data);
    if (messageObj.action === 'openUrl') {
      this.props.Linking.openURL(messageObj.data);
    }
  };

  render() {
    const { t, rootStore } = this.props;
    const overrideOpenUrl = true; // think this always applies, can't remember
    return (
      <SimpleNavWrapper
        rightButton={
          <SimpleNavButton
            title={t('ONBOARDING:CONTINUE').toLowerCase()}
            onPress={this._onPressNext}
            isPrimaryAction
          />
        }>
        <LoadingShadeWrapper
        coversOnboardingScreen
          isLoading={rootStore.onboardingActionState.isPending}
          style={containerStyles.fillAvailableSpace}>
          <ScreenError
            state={rootStore.onboardingActionState}
            title={t('ONBOARDING:ERRORS:VIEW_TUTORIAL')}
          />
          {rootStore.onboardingTutorial ? (
            <WebView
              source={{
                html: rootStore.onboardingTutorial,
                baseUrl: '' /* Galaxy S will not render page without this! */,
              }}
              originWhitelist={['*']}
              injectedJavaScript={overrideOpenUrl ? injectedJavaScript : undefined}
              onMessage={overrideOpenUrl ? this._onMessageFromBrowser : undefined}
            />
          ) : null}
        </LoadingShadeWrapper>
      </SimpleNavWrapper>
    );
  }
}

TutorialScreen.propTypes = {
  navigation: PropTypes.object,
};

export default withTranslation()(TutorialScreen);
