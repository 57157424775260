import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FullscreenImage from './FullscreenImage';
import { colors } from '../config/styles';
import { BackIcon } from '../config/icons';

class FullscreenImageScreen extends Component {
  render() {
    const { navigation, route } = this.props;
    const { source, extraBottomNavigationButton } = route.params;
    return (
      <FullscreenImage
        style={{ backgroundColor: colors.bg0 }}
        CloseButtonComponent={() => <BackIcon />}
        source={source}
        onPressClose={() => {
          navigation.goBack();
        }}
        navigation={navigation}
        extraBottomButton={
          extraBottomNavigationButton
            ? {
                title: extraBottomNavigationButton.title,
                onPress: () =>
                  navigation.replace(extraBottomNavigationButton.navigationRoute, route.params),
              }
            : null
        }
      />
    );
  }
}

FullscreenImageScreen.propTypes = {
  navigation: PropTypes.object,
};

export default FullscreenImageScreen;
