import React, { useCallback } from 'react';
import { Platform } from 'react-native';
import PropTypes from 'prop-types';
import ParsedText from 'react-native-parsed-text';
import Communications from 'react-native-communications';
import { connectActionSheet } from '@expo/react-native-action-sheet';

const linkTextStyle = { textDecorationLine: 'underline' };

/**
 * Extracted from MessageText (from Chat)
 * Creates pressable links with underlines for phone numbers, emails, urls
 * Must provide your own URL handler.
 * Use just like a Text component
 */

const TextWithLinks = connectActionSheet(
  ({ onPressUrl, children, showActionSheetWithOptions, ...rest }) => {
    const onPressPhoneNumber = useCallback(phoneNumber => {
      const options = ['Call', 'Text', 'Cancel'];
      const cancelButtonIndex = options.length - 1;
      showActionSheetWithOptions(
        {
          options,
          cancelButtonIndex,
        },
        buttonIndex => {
          switch (buttonIndex) {
            case 0:
              Communications.phonecall(phoneNumber, true);
              break;
            case 1:
              Communications.text(phoneNumber);
              break;
            default:
              break;
          }
        }
      );
    });

    const onPressEmailAddress = useCallback(emailAddress => {
      Communications.email([emailAddress], null, null, null, null);
    });

    const parsedTypes = [
      { type: 'url', style: linkTextStyle, onPress: onPressUrl },
      { type: 'email', style: linkTextStyle, onPress: onPressEmailAddress },
    ];

    if (Platform.OS !== 'web') {
      parsedTypes.push({ type: 'phone', style: linkTextStyle, onPress: onPressPhoneNumber });
    }

    return (
      <ParsedText parse={parsedTypes} {...rest}>
        {children}
      </ParsedText>
    );
  }
);

TextWithLinks.propTypes = {
  style: PropTypes.any,
  children: PropTypes.any,
  onPressUrl: PropTypes.func.isRequired,
};

TextWithLinks.defaultProps = {
  style: null,
  onPressUrl: null,
};

export default TextWithLinks;
