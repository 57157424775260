import { StyleSheet } from 'react-native';
import { sizes } from '../../../config/common-styles';

export default StyleSheet.create({
  wrapper: {
    flex: 1,
    padding: sizes.large,
  },
  loginInput: {
    padding: sizes.medium,
  },
});
