import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, Observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { RowTextInput } from '/common/components';
import UpdateProfileItemList from './UpdateProfileItemList';

/**
 * We return the state with all the props anytime something changes.
 * What is changing doesn't map perfectly to what is in the object (first name, last name vs name),
 * So it's easier this way. I guess. I hate forms...
 */
@observer
class UpdateEmail extends Component {
  render() {
    const { email, onChangeEmail, t, formErrors } = this.props;
    const sections = [];

    sections.push({
      title: '',
      data: [
        () => (
          <Observer>
            {() => (
              <RowTextInput
                value={email}
                label={t('PROFILE:FIELDS:EMAIL')}
                onChangeText={onChangeEmail}
                textInputProps={{
                  autoFocus: true,
                  autoCapitalize: 'none',
                  autoCompleteType: 'email',
                }}
                validationError={
                  formErrors.find(f => f.field === 'email' && f.type === 'invalid')
                    ? t('PROFILE:ERRORS:INVALID_EMAIL')
                    : formErrors.find(f => f.field === 'email' && f.type === 'alreadyUsed')
                    ? t('PROFILE:ERRORS:EMAIL_ALREADY_USED')
                    : null
                }
              />
            )}
          </Observer>
        ),
      ],
    });

    return <UpdateProfileItemList sections={sections} />;
  }
}

UpdateEmail.propTypes = {
  email: PropTypes.string.isRequired,
  onChangeEmail: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  formErrors: PropTypes.arrayOf(
    PropTypes.shape({ field: PropTypes.string, type: PropTypes.string })
  ),
};

UpdateEmail.defaultProps = {
  formErrors: [],
};

export default withTranslation()(UpdateEmail);
