import React, { useState, useCallback } from 'react';
import { View, Text, TouchableOpacity, Alert } from 'react-native';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { sizes, colors, textStyles } from '/common/config/styles';

const Button = ({ onPress, title, containerStyle }) => (
  <TouchableOpacity style={{ flex: 1, containerStyle }} onPress={onPress}>
    <View
      style={[
        {
          borderRadius: sizes.borderRadius,
          borderWidth: 1,
          borderColor: colors.lightText,
          paddingVertical: sizes.small,
          paddingHorizontal: sizes.medium,
          flex: 1,
          alignItems: 'center',
        },
        containerStyle,
      ]}>
      <Text style={textStyles.standard.dark}>{title}</Text>
    </View>
  </TouchableOpacity>
);

const SyncHint = observer(({ onPressDismiss, onPressSyncNow }) => {
  const onPressDismissedWrapped = useCallback(() => {
    Alert.alert('Sync any time.', 'You can sync apps at any time in Settings.', [
      { text: 'OK', onPress: onPressDismiss, style: 'default' },
    ]);
  }, [onPressDismiss]);

  return (
    <View
      style={{
        marginTop: 35,
        backgroundColor: colors.bg1,
        borderRadius: sizes.borderRadius,
      }}>
      <View
        style={{
          marginHorizontal: sizes.medium,

          borderWidth: 2,
          borderColor: colors.bg2,
          borderRadius: sizes.cardBorderRadius,
          padding: sizes.medium,
        }}>
        <Text style={[textStyles.medium.bold, { marginBottom: sizes.medium }]}>
          Sync your favorite apps.
        </Text>
        <Text style={textStyles.standard.dark}>
          Some trackers in your program can be automatically tracked by syncing your apps and
          wearables.
        </Text>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: sizes.medium,
          }}>
          <Button
            containerStyle={{ marginRight: sizes.small }}
            onPress={onPressDismissedWrapped}
            title="Dismiss"
          />
          <Button
            containerStyle={{ marginLeft: sizes.small }}
            onPress={onPressSyncNow}
            title="Sync Now"
          />
        </View>
      </View>
    </View>
  );
});

export default SyncHint;
