import React, { useCallback } from 'react';
import { observer, inject } from 'mobx-react';
import { Platform } from 'react-native';
import { withTranslation } from 'react-i18next';
import { useScreenBehaviors } from '/common/navigation';
import { NavbarWrapper } from '../../../common/components/navigation';
import { LogsList } from '/common/components/tracking';
import { navigationRoutes } from '../../config/constants';

const LogEntriesScreen = inject('rootStore')(
  observer(function({ rootStore, navigation, route, t }) {
    const { logStore } = rootStore;

    // just for screen tracking
    useScreenBehaviors({});

    const { trackerId, date, timeframe } = route.params;

    const onPressLog = useCallback(
      logId => {
        navigation.navigate(
          Platform.OS ===
            'web' /* hack alert! Should technically be isWideScreen, but we're not hook-ready yet here */
            ? navigationRoutes.stacks.main.editLog_modal
            : navigationRoutes.stacks.main.editLog,
          {
            logId,
            trackerId,
          }
        );
      },
      [route, rootStore]
    );

    const sliceDateRange = logStore.sliceDateRangeFor({ date, timeframe });

    const tracker = logStore.trackerFor(trackerId);

    return (
      <NavbarWrapper navigation={navigation} hasBackButton title={t('LOG:BUTTONS:DETAILS')}>
        <LogsList
          tracker={tracker}
          onPressLog={onPressLog}
          logs={tracker.logsForDateRangeSorted(sliceDateRange.startDate, sliceDateRange.endDate)}
        />
      </NavbarWrapper>
    );
  })
);

LogEntriesScreen.displayName = 'LogEntriesScreen';

export default withTranslation()(LogEntriesScreen);
