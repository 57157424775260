import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Keyboard } from 'react-native';
import { useLocalStore } from 'mobx-react';
import { DateTime } from 'luxon';
import { formatLogValueForDisplay } from 'nudge-client-common/trackers';
import { UserOptionsContext } from '/common/config/personalization';
import { useAlert } from './useAlert';

function copyExistingLogToEditingLog({ tracker, existingLog, editingLog, useMetricUnits }) {
  tracker.clientMeta.entryFields.forEach(field => {
    editingLog[field.modelField] = formatLogValueForDisplay({
      value: existingLog[field.modelField],
      fieldName: field.modelField,
      useMetricUnits,
      editor: field.editor,
      decimalPlaces: field.decimals,
    });
  });
  // kind of hacky, easy way to show certain data
  editingLog.isSynced = existingLog.isSynced;
  editingLog.displaySource = existingLog.displaySource;
  // add dirty bit to reflect if changes have occurred since the model was created, to see if we need to save
  editingLog.isDirty = false;
}

/**
 * Determine the program event based on the route. If it's not loaded, return null;
 */
const useLogEntryBehaviors = ({ route, navigation, logStore }) => {
  const alert = useAlert();
  const { t } = useTranslation();
  const userOptions = useContext(UserOptionsContext);
  const { trackerId, logId, date, isEditModeEnabled } = route.params;
  const tracker = logStore.trackerFor(trackerId);

  let existingLog = tracker.logFor(logId);

  const editingLog = useLocalStore(() => {
    const dataModel = {};

    if (existingLog && existingLog.serverId) {
      copyExistingLogToEditingLog({
        tracker,
        existingLog,
        editingLog: dataModel,
        useMetricUnits: userOptions.useMetricUnits,
      });
    } else {
      tracker.clientMeta.entryFields.forEach(field => {
        // not really a field
        if (field.editor === 'divider') {
          return;
        }
        if (field.modelField === 'activityId') {
          dataModel[field.modelField] = tracker.clientMeta.defaultActivityType;
        } else if (
          field.modelField === 'startTime' ||
          field.modelField === 'endTime' ||
          field.modelField === 'jsUserTime'
        ) {
          let givenDateTime = DateTime.fromSQL(date);
          const now = DateTime.local();
          // 2) add local time
          givenDateTime = givenDateTime.plus({
            hours: now.hour,
            minutes: now.minute,
            seconds: now.second,
          });
          const newLogTime = givenDateTime.toJSDate();
          dataModel[field.modelField] = newLogTime;
        } else {
          dataModel[field.modelField] = null;
        }
      });
    }
    dataModel.setField = function({ fieldName, value }) {
      this[fieldName] = value;
      this.isDirty = true;
    };
    dataModel.reset = function() {
      copyExistingLogToEditingLog({ tracker, existingLog, editingLog: this });
    };

    return dataModel;
  });

  const onPressSave = useCallback(() => {
    async function doAsync() {
      Keyboard.dismiss();
      await logStore.saveLog({
        trackerId,
        logId,
        date,
        logData: editingLog,
        useMetricUnits: userOptions.useMetricUnits,
      });

      if (logStore.saveState.isDone) {
        navigation.setParams({ isEditModeEnabled: false });
        navigation.goBack();
      }
    }
    doAsync();
  }, [logStore, route, navigation]);

  const onPressDelete = useCallback(() => {
    async function doAsync() {
      alert(t('LOG:ENTRY:DELETE_LOG'), t('LOG:ENTRY:ARE_YOU_SURE'), [
        {
          text: t('LOG:ENTRY:CANCEL'),
          style: 'cancel',
          onPress: () => {},
        },
        {
          text: t('LOG:ENTRY:DELETE'),
          onPress: async () => {
            await logStore.deleteLog({ trackerId, logId });
            navigation.setParams({ isEditModeEnabled: false });
            if (logStore.saveState.isDone) {
              Keyboard.dismiss();
              navigation.goBack();
            }
          },
          style: 'destructive',
        },
      ]);
    }
    doAsync();
  }, [route, logStore, alert, navigation]);

  const onPressCancel = useCallback(() => {
    function onConfirmCancel() {
      if (isEditModeEnabled) {
        editingLog.reset();
        navigation.setParams({ isEditModeEnabled: false });
      } else {
        setTimeout(() => {
          navigation.goBack();
        }, 250);
      }
    }
    async function doAsync() {
      Keyboard.dismiss();
      logStore.saveState.reset();
      if (editingLog.isDirty) {
        alert(t('LOG:ENTRY:UNSAVED_CHANGES'), '', [
          {
            text: t('LOG:ENTRY:KEEP_EDITING'),
            onPress: () => {},
          },
          {
            text: t('LOG:ENTRY:DISCARD_CHANGES'),
            onPress: onConfirmCancel,
            style: 'destructive',
          },
        ]);
      } else {
        onConfirmCancel();
      }
    }
    doAsync();
  }, [logStore, navigation, route]);

  const onToggleEditMode = useCallback(() => {
    if (existingLog.canEdit) {
      navigation.setParams({ isEditModeEnabled: !isEditModeEnabled });
    }
  }, [navigation]);

  return {
    onPressCancel,
    onPressSave,
    onPressDelete,
    editingLog,
    savedLog: existingLog,
    tracker,
    isEditModeEnabled,
    onToggleEditMode,
  };
};

export default useLogEntryBehaviors;
