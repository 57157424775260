import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { View, Text } from 'react-native';
import { CircleThumbnail } from '/common/components';
import { textStyles, sizes, fonts, colors } from '../../../../config/styles';
import { fullName } from '../../../../config/strings';
import LeaderboardValue from './LeaderboardValue';
import missingUser from '../../../../assets/icons/missing-user.png';

/**
 * The reply to a topic or the first message in the topic itself
 */

const Leader = ({ leader, rankOverride, uom, isUser, t }) => {
  const userLeftGroup = leader.firstName === null && leader.lastName === null;

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingLeft: sizes.medium,
        paddingRight: sizes.large,
        paddingVertical: 13,
        backgroundColor: colors.bg0,
      }}>
      <Text
        style={[
          isUser ? textStyles.standard.dark : textStyles.standard.light,
          {
            marginRight: 15,
            fontWeight: isUser ? fonts.weights.medium : fonts.weights.standard,
            fontFamily: isUser ? fonts.families.medium : fonts.families.standard,
            color: isUser ? colors.darkText : colors.lightText,
          },
        ]}>
        {rankOverride ? rankOverride : leader.rank}
      </Text>
      <CircleThumbnail
        source={userLeftGroup ? missingUser : leader.photoUrl}
        diameter={40}
        altTitle={fullName(leader)}
      />
      <Text
        style={[
          userLeftGroup ? textStyles.standard.light : textStyles.standard.dark,
          {
            flex: 1,
            marginLeft: 13,
            marginRight: sizes.small,
            fontWeight: isUser ? fonts.weights.medium : fonts.weights.standard,
            fontFamily: isUser ? fonts.families.medium : fonts.families.standard,
          },
        ]}>
        {userLeftGroup ? t('COMMUNITY:LEADERBOARD:PERSON_LEFT_GROUP') : fullName(leader)}
      </Text>
      {!userLeftGroup ? (
        <LeaderboardValue leader={leader} uom={uom} valueStyle={[textStyles.standard.dark]} />
      ) : null}
    </View>
  );
};

Leader.propTypes = {
  leader: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  uom: PropTypes.string.isRequired,
  isUser: PropTypes.bool,
};

export default withTranslation()(Leader);
