import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Text } from 'react-native';
import { FlexibleThumbnail } from '/common/components';
import { textStyles, sizes } from '../../config/styles';

class HeaderTitle extends Component {
  render() {
    const { imageSource, title, style } = this.props;

    // resizeMode = cover seems to fix blinking on Android without affecting display of image
    // The workaround here does work, but need to figure out how to size the image correctly.
    // https://github.com/facebook/react-native/issues/981
    if (imageSource) {
      return (
        <FlexibleThumbnail
          maxHeight={sizes.navbarLogoHeight}
          resizeMode="cover"
          source={imageSource}
          isStaticImage={!imageSource.uri}
        />
      );
    }

    return (
      <Text
        numberOfLines={1}
        ellipsizeMode="tail"
        allowFontScaling={false}
        style={[
          textStyles.standard.dark,
          { fontWeight: '500', marginHorizontal: sizes.small },
          style,
        ]}>
        {title}
      </Text>
    );
  }
}

HeaderTitle.propTypes = {
  imageSource: PropTypes.any,
  title: PropTypes.string.isRequired,
  style: PropTypes.any,
};

HeaderTitle.defaultProps = {
  imageSource: null,
  style: null,
};

export default HeaderTitle;
