import React from 'react';
import { View } from 'react-native';
import { useMediaQueryInfo, sizes, colors } from '/common/config/styles';

/**
 * Implements a nice bit of rounding for the web version, in particular
 */
export default function({ style, expandsToBottomOfScreen }) {
  const { innerWindowWidth, isTabletOrDesktop } = useMediaQueryInfo();
  return isTabletOrDesktop ? (
    <View
      style={[
        {
          height: sizes.webBorderRadius,
          borderBottomRightRadius: sizes.webBorderRadius,
          borderBottomLeftRadius: sizes.webBorderRadius,
          width: innerWindowWidth,
          backgroundColor: colors.bg0,
          marginBottom: sizes.webInnerViewMargin,
        },
        style,
      ]}
    />
  ) : expandsToBottomOfScreen ? (
    <View style={{ height: sizes.homeBarPadding }} />
  ) : null;
}
