export default {
  GENERAL: {
    SUCCESS: 'Success',
    SAVE: 'Save',
    EDIT: 'Edit',
    CANCEL: 'Cancel',
    OK: 'OK',
    REFRESH: 'Refresh',
    TRY_AGAIN: 'Try Again',
    LOAD_MORE: 'Load More',
    ACCEPT: 'Accept',
    DECLINE: 'Decline',
    PHOTO_PICKER: {
      CHOOSE_FROM_LIBRARY: 'Choose from Library',
      TAKE_PICTURE_WITH_CAMERA: 'Take Picture with Camera',
    },
    ERRORS: {
      APP_FIRST_LOAD_TITLE: "Sorry, we're having trouble starting things up...",
      FIRST_LOAD_TITLE: "Sorry, we're having some trouble...",
      FIRST_LOAD_MESSAGE: "We can't seem to connect at the moment.",
      PLEASE_REFRESH_ACTION: 'Refresh to try again.',
      // this may no longer be in use
      LOAD_EARLIER_MESSAGES_FAILED:
        'Failed to load previous messages. Please check your connection and try again',
      NETWORK_ERROR_MESSAGE: 'Please check your network connection',
      SERVER_UNAVAILABLE_ERROR_MESSAGE: `Apologies for the inconvenience - we're doing some maintenance right now. We'll be back soon!`,
      ALERT_UNEXPECTED_ERROR_MESSAGE: 'Please try again later.',
      ALERT_UNEXPECTED_ERROR_TITLE: "Sorry, we're having trouble with that...",
      ALERT_ACCESS_REMOVED_ERROR_MESSAGE:
        'You no longer have access to this resource. You may have been removed from a group.',
      CANNOT_OPEN_FILE: 'Error opening file',
    },
    DATES: {
      TODAY: 'Today',
      YESTERDAY: 'Yesterday',
    },
  },
  SIGN_UP: {
    INVITE_ID_DISPLAY_NAME: 'Invite ID',
    INVITE_ID_DISPLAY_SHORTNAME: 'ID',
    INVITE_ID_DISPLAY_ARTICLE: 'an',
    CLIPBOARD_DISCLAIMER:
      'This app collects clipboard data to enable pasting your {{inviteIdName}} in order to connect with your coach',
    PLEASE_ENTER_INVITE_ID: 'Please enter your {{inviteIdName}}',
    PLEASE_ENTER_CLIENT_USER_ID: 'Please enter your {{clientUserIdName}} if you have one.',
    ENTER_YOUR_EMAIL_ADDRESS: 'Enter your email address',
    ID_LIKE_TO: "I'd like to",
    GREAT: 'Great!',
    SECURE_YOUR_ACCOUNT_WITH_A_PASSWORD:
      "We've confirmed your account. Now secure it with your password.",
    COMPLETE_YOUR_PROFILE: 'Complete your profile',
    THIS_WILL_HELP_US_VERIFY_YOUR_RESULTS: 'This will help us verify your results',
    CREATE_AN_ACCOUNT: `Create an account`,
    CREATE_YOUR_ACCOUNT: `Create your account.`,
    ENTER_A_NEW_EMAIL_ADDRESS: 'Enter a new email address.',
    TERMS_OF_SERVICE: {
      DESCRIPTION:
        'By signing up for {{appName}}, you must be at least 13 years old and agree to the',
      LINK_NAME: 'Terms of Service',
    },
    CONFIRMATION_CODE: 'Confirmation Code',
    WE_JUST_SENT_AN_EMAIL_TO: 'We just sent an email to',
    WE_PREVIOUSLY_SENT_AN_EMAIL_TO: 'We previously sent an email to',
    CONFIRM_YOUR_ACCOUNT: 'Verify your email.',
    FINISH_SETTING_UP_YOUR_ACCOUNT: 'Finish setting up your account',
    USE_THIS_LINK_TO_CONFIRM_YOUR_EMAIL_ADDRESS: 'Use this link to verify your email address.',
    ERRORS: {
      INVALID_INVITE_ID: `Sorry, we can't find this {{inviteIdName}}`,
      INVALID_INVITE_ID_MEMBERSHIP_LIMIT_EXCEEDED: `Sorry, this coach cannot add any more clients.`,
      NETWORK_ERROR: `We can't seem to connect! Please check your connection.`,
      UNKNOWN_ERROR: `Sorry, we're having trouble connecting at the moment.`,
      EMAIL_ALREADY_IN_USE: `This email is already in use`,
      EMAIL_BLANK: `Please enter an email address`,
      EMAIL_INVALID: `Not a valid email address`,
      NAME_BLANK: `Please enter a name`,
      FIRST_NAME_BLANK: `Please enter a first name`,
      LAST_NAME_BLANK: `Please enter a last name`,
      PASSWORD_NOT_MINIMUM_LENGTH: 'Password must be 6 or more characters',
      PASSWORD_INVALID: 'Invalid password (30 characters max)',
      BIRTH_DATE_BLANK: 'Must provide a birth date',
      INVALID_EMAIL_CONFIRMATION_CODE: 'Sorry, that is not a valid email confirmation code.',
    },
    BUTTONS: {
      CONTINUE: 'Continue',
      NO_CONTINUE_WITH_SIGN_UP: 'No, continue with sign up.',
      CONINUE_WITH_SELF_GUIDED_PROGRAM: 'Continue with a self-guided program',
      SUBMIT: 'Submit',
      ACCEPT: 'Accept',
      DECLINE: 'Decline',
    },
    REQUEST_EMAIL_CODE_DIALOG: {
      TITLE: 'Done!',
      MESSAGE:
        "If you're having trouble finding your confirmation email, check your junk/ spam folder.",
    },
  },
  ONBOARDING: {
    ERRORS: {
      PUSH_NOTIFICATION_REGISTRATION: 'Error registering for push notifications.',
      VIEW_TUTORIAL: "Sorry, we can't open the tutorial right now",
    },
    CONTINUE: 'Continue',
  },
  LOGIN: {
    BUTTONS: {
      IM_NEW: "I'm New",
      SIGN_IN: 'Sign In',
      NEXT: 'Next',
      FORGOT_PASSWORD: 'Forgot your password?',
      REQUEST_PASSWORD_RESET: 'Reset password',
      LOGIN_AGAIN: 'Back to Sign-in',
    },
    PASSWORD_RESET_CONFIRMATION: 'A link to reset your password has been sent to your email.',
    PASSWORD_RESET_TROUBLESHOOTING_TIPS_TITLE: 'Troubleshooting Tips',
    PASSWORD_RESET_TROUBLESHOOTING_TIPS_DETAIL1: `• Emails sometimes take several minutes to show up`,
    PASSWORD_RESET_TROUBLESHOOTING_TIPS_DETAIL2: `• Check your spam folder after a few minutes`,
    PASSWORD_RESET_TROUBLESHOOTING_TIPS_DETAIL3: `• Make sure you're checking the right email inbox (hey, it happens 🤷)`,
    HEADERS: {
      CREATE_AN_ACCOUNT: 'Create An Account',
      SIGN_IN: 'Sign In',
    },
    FIELDS: {
      NAME: 'Name',
      EMAIL: 'Email',
      PASSWORD: 'Password',
    },
    ERRORS: {
      GENERIC_TITLE: `Sorry, we're having trouble signing in`,
      INCORRECT_USERNAME_PASSWORD: "The email or password doesn't match any user.",
      SSO: {
        RESPONSE_MALFORMED:
          'The response from the single-sign on provider is malformed. There may be an issue with the sign-on server. Please contact product support.',
        NO_AUTHORIZATION_CODE:
          'The response from the single-sign on provider did not include an authorization code. If there was an error during the sign in process, please try again, or contact product support.',
        UNRECOGNIZED_URL:
          'The response from the single-sign on provider included a URL we did not recognize. Please contact product support.',
        UNEXPECTED:
          'An unexpected error occurred during sign on. Please try again later, or contact product support.',
        VALIDIC_USER_NOT_PROVISIONED: 'It looks like you are not registered for {{appName}}.',
      },
    },
  },
  BUTTONS: {
    CANCEL: 'Cancel',
  },
  MESSAGING: {
    ERRORS: {
      SEND_MESSAGE: 'Error sending message.',
      DELETE_MESSAGE: 'Error deleting message.',
      LOADING_PREVIOUS_MESSAGES: 'Error loading previous messages.',
    },
    BUTTONS: {
      COPY_TEXT: 'Copy Text',
      DELETE_MESSAGE: 'Delete Message',
    },
    NO_MESSAGES_MESSAGE: {
      LINE1: "It's quiet here!",
      LINE2: 'Why not start a conversation?',
    },
    LOAD_EARLIER_MESSAGES: 'Load earlier messages',
  },
  PROFILE: {
    TITLE: 'Profile',
    FIELDS: {
      FULL_NAME: 'Full Name',
      NAME: 'Name',
      FIRST_NAME: 'First Name',
      LAST_NAME: 'Last Name',
      EMAIL: 'Email',
      GENDER: 'Gender',
      DATE_OF_BIRTH: 'Date of Birth',
      UNITS: 'Units',
      HEIGHT: 'Height',
      PHOTO: 'Photo',
    },
    BUTTONS: {
      CHANGE_PASSWORD: 'Change Password',
      SET_PROFILE_PHOTO: 'Set Profile Photo',
      ENTER_A_CODE: 'Enter an Invite ID',
      HELP: 'Help',
      MALE: 'Male',
      FEMALE: 'Female',
      NOT_SPECIFIED_OTHER: 'Not Specified',
      IMPERIAL: 'Imperial',
      METRIC: 'Metric',
      LOG_OUT: 'Log Out',
      SYNCED_APPS_NOT_SYNCING: 'Connect Apps & Wearable Trackers',
      SYNCED_APPS_SYNCING: 'Connected Apps',
      EDIT: 'Edit',
      SHARE: 'Share',
      NO_BIRTHDATE_SET: 'Select...',
      DELETE_ACCOUNT: 'Delete Account',
      COACH_SETTINGS: 'Coach Settings',
      SUBMIT: 'Submit',
    },
    LABELS: {
      COMPLETE_YOUR_PROFILE: {
        TITLE: 'COMPLETE YOUR PROFILE',
        DESCRIPTION:
          'Try logging some of your favorite activities for a profile that reflects your active lifestyle.',
      },
      ACTIVITIES: {
        TITLE: 'ACTIVITIES',
        LATEST: 'LATEST',
        FAVORITE: 'FAVORITE',
      },
    },
    ERRORS: {
      UPDATE_FAILED: 'Update failed',
      EMAIL_UPDATE_FAILED_MESSAGE: 'Be sure to use a valid email',
      CANNOT_BE_BLANK: 'Cannot be blank',
      EMAIL_ALREADY_USED: 'Email already in use',
      INVALID_EMAIL: 'Not a valid email',
      TRACKING_CONSENT_UPDATE_FAILED_MESSAGE:
        'Your tracking consent preference was not updated. Please try again',
    },
    EDIT: {
      UNSAVED_CHANGES: 'Unsaved changes',
      WHAT_WOULD_YOU_LIKE_TO_DO: 'What would you like to do?',
      SAVE_CHANGES: 'Save changes',
      DISCARD_CHANGES: 'Discard changes',
    },
  },
  SYNCED_APPS: {
    TITLE: 'Synced Apps',
    DESCRIPTION: 'Tap and sync your favorite app(s)',
    CONFIRMATION:
      '{{appName}} is now syncing with your {{syncedAppName}} account. Note that it may take up to 24 hours for synced data to appear on the {{appName}} log screen.',
    STOP_APP_CONFIRM_TITLE: 'Stop syncing app?',
    STOP_APP_CONFIRM_MESSAGE:
      'Are you sure you would like to stop syncing data from {{syncedAppName}} to {{appName}}?',
    NO_KEEP_SYNCING: 'No, keep syncing',
    YES_STOP_SYNCING: 'Yes, stop syncing {{syncedAppName}}',
    CHECK_APPLE_HEALTH_SOURCES:
      'Check the Sources tab in the Health app to enable more data types for syncing.',
  },
  PROGRAMS: {
    EMPTY_FEED: {
      TITLE: 'The next steps of your\nprogram will load right here.',
      MESSAGE: 'Check back soon',
    },
    EMPTY_TAG: {
      TITLE: 'No cards here right now.',
      MESSAGE: 'This tag may have been removed.',
    },
    ERRORS: {
      REFRESH_FAILED: "Sorry, can't refresh right now...",
    },
    TRACKER_PLACEHOLDERS: {
      /** These are based on tracker editor */
      NUMBER: 'Enter a number...',
      DURATION: 'Record your time...',
      PERCENT: 'Enter your %...',
      BLOODPRESSURE: 'Record your blood pressure...',
      FREEFORM: 'Write your answer...',
      MULTIPLECHOICE: 'Pick one...',
      HEARTRATE: 'View your heart rate...',
    },
    SEGMENTED_CONTROL: {
      FEED: 'Feed',
      TAGS: 'Tags',
    },
    LOAD_MORE: 'Load More',
  },
  LOG: {
    BUTTONS: {
      OPTIONS: 'Options',
      DETAILS: 'Details',
      SELECTED: 'Selected',
    },
    EMPTY_TRACKER_LIST: {
      TITLE: 'No trackers yet',
      MESSAGE: 'If you have synced data from devices, your data will appear here soon.',
    },
    TRACKERS: {
      CARDIO: 'Activities',
      WATER: 'Water',
      PEDOMETER: 'Steps',
      WEIGHT: 'Weight',
      'BLOOD-PRESSURE': 'Blood Pressure',
      'BLOOD-GLUCOSE': 'Blood Glucose',
      CALORIES: 'Calories',
      'BODY-FAT': 'Body Fat',
      SLEEP: 'Sleep',
    },
    ENTRY_ERRORS: {
      MUST_SELECT_ONE: 'Must select one',
      MUST_BE_NUMBER_GREATER_THAN_ZERO: 'Must be a number > 0',
      MUST_BE_INTEGER_GREATER_THAN_ZERO: 'Must be a whole number > 0',
      MUST_BE_NUMBER_GREATER_THAN_OR_EQUAL_TO_ZERO: 'Must be a number >= 0',
      MUST_BE_VALID_MINUTES: 'Must be between 0 minutes and 24 hours',
      MUST_BE_VALID_PERCENT: 'Must be between 0 and 100',
      CANNOT_BE_BLANK: 'Cannot be blank',
      TIME_MUST_BE_LESS_THAN_24_HOURS: 'Time must be between 1 minute and 24 hours',
      TIMES_CANNOT_OVERLAP: 'Time cannot overlap with another log',
    },
    ERRORS: {
      APP_FAILED_TO_SYNC: `We're having trouble syncing your {{syncedAppName}} data. Please try later.`,
      APP_FAILED_TO_SYNC_GENERAL: `We're having trouble syncing your data. Please try later.`,
      SYNC_UPDATES_ERROR_TITLE: `We're having trouble syncing your latest updates right now.`,
      SYNC_UPDATES_UNKNOWN_ERROR_MESSAGE: `Please try again.`,
      SYNC_UPDATES_NEED_TO_UPDATE_LOG: `Your program has been updated. Refresh to grab the latest.`,
      REFRESH_FAILED: "Sorry, can't refresh right now...",
      SAVE_FAILED: "Sorry, can't save right now",
    },
    FIELDS: {
      NOTES: 'Comments',
      SYSTOLIC: 'Systolic',
      DIASTOLIC: 'Diastolic',
      TIME: 'Time',
      CARBOHYDRATES: 'Carbohydrates',
      SODIUM: 'Sodium',
      CALORIES: 'Calories',
      TOTAL_FAT: 'Total Fat',
      DIETARY_FIBER: 'Dietary Fiber',
      PROTEIN: 'Protein',
      START_TIME: 'Start Time',
      END_TIME: 'End Time',
      DURATION: 'Duration',
      BLOOD_GLUCOSE: 'Blood Glucose',
      ACTIVITY: 'Activity',
      DISTANCE: 'Distance',
      SOURCE: 'Source',
      WRITE_SOMETHING: 'Write something...',
      SELECT_ONE: 'Select one...',
      HEART_RATE: 'Heart Rate',
      AVERAGE_HEART_RATE: 'Average Heart Rate',
      MINIMUM_HEART_RATE: 'Minimum Heart Rate',
      MAXIMUM_HEART_RATE: 'Maximum Heart Rate',
    },
    FIELD_ABBREVIATIONS: {
      SYSTOLIC: 'sys',
      DIASTOLIC: 'dia',
      MINHEARTRATE: 'min',
      MAXHEARTRATE: 'max',
      AVGHEARTRATE: 'avg',
      HEARTRATE: 'bpm',
    },
    ENTRY: {
      DELETE: 'Delete',
      CANCEL: 'Cancel',
      DELETE_LOG: 'Delete Log',
      ARE_YOU_SURE: 'Are you sure you would like to delete this log?',
      UNSAVED_CHANGES: 'You have unsaved changes',
      KEEP_EDITING: 'Keep editing',
      DISCARD_CHANGES: 'Discard changes',
    },
  },
  TRENDS: {
    DETAILS: {
      DAILY_AVERAGE: 'Daily Average',
      DAILY_TOTAL: 'Daily Total',
      DAILY: 'Daily',
      MONTH: 'Month',
      WEEK: 'Week',
      '90DAY': '90 Days',
      YEAR: 'Year',
    },
    SUMMARY: {
      NO_RECENT_DATA: 'NO RECENT DATA',
      LAST_30_DAYS: 'LAST 30 DAYS',
      LAST_7_DAYS: 'LAST 7 DAYS',
    },
    ERRORS: {
      LOADING_OF_GRAPH_FAILED: 'Loading of graph failed. Please refresh to try again',
    },
  },
  COMMUNITY: {
    TITLE: 'Community',
    LEADERBOARD: {
      TITLE: 'Leaderboard',
      JOIN_LEADERBOARD: 'Join Leaderboard',
      JOIN: 'Join',
      LEAVE: 'Leave',
      LEAVE_CONFIRMATION_DIALOG_TITLE: 'Leave the Group Leaderboard?',
      LEAVE_CONFIRMATION_DIALOG_MESSAGE: 'You can join this leaderboard again at any time',
      JOIN_CONFIRMATION_DIALOG_TITLE: 'Join the Group Leaderboard?',
      JOIN_CONFIRMATION_DIALOG_MESSAGE:
        'People in this group will be able to see your {{leaderboardType}}',
      LEADERBOARD_RESETS_AT: 'Leaderboard resets {{date}}',
      RESULTS_RECORDED_ON: 'Results Recorded on {{date}}',
      WEEKLY_TOTAL: 'Weekly Total',
      MONTHLY_TOTAL: 'Monthly Total',
      NO_LEADERS: 'Be the first one to join the leaderboard!',
      CHECK_SOON_FOR_LEADERS: 'Check back soon for leaderboard updates!',
      PERSON_LEFT_GROUP: 'This person left the group.',
      ACTIVE: ' Active ',
      PREVIOUS: 'Previous',
    },
    LATEST_POSTS: {
      TITLE: 'LATEST POSTS',
      NO_POSTS_SINGLE_DESCRIPTION:
        'This community doesn’t have any posts yet. Why not start a conversation?',
    },
    BUTTONS: {
      LEAVE: 'Leave',
      VIEW_LEADERBOARD: 'View Leaderboard',
    },
    ERRORS: {
      POST_MESSAGE: 'Error posting new message.',
      DELETE_MESSAGE: 'Error deleting message.',
      POST_REPLY: 'Error posting new message.',
      JOIN_LEADERBOARD: 'Error joining leaderboard.',
      LEAVE_LEADERBOARD: 'Error leaving leaderboard',
      LOADING_PREVIOUS_MESSAGES: 'Error loading previous messages',
    },
    MODERATION_OPTIONS: {
      DELETE: 'Delete',
      HIDE_THIS_MESSAGE: 'Hide this message',
      HIDE_ALL_MESSAGES_FROM_USER: 'Block this user',
      REPORT_ABUSE: 'Report abuse',
      ITS_SPAM: "It's spam",
      ITS_OFFENSIVE: "It's offensive",
      OTHER_ABUSE_OPTION: 'Other',
      WHY_WAS_THIS_ABUSIVE: 'Why was this post abusive?',
      HIDE_ALL_MESSAGES: 'Block user',
      HIDE_ALL_MESSAGES_FROM_USER_TITLE: 'Block/ hide all messages from {{username}}?',
      HIDE_ALL_MESSAGES_FROM_USER_MESSAGE: 'You will no longer see any messages from this user',
      HIDE_ALL_MESSAGES_FROM_USER_CONFIRMATION_TITLE: 'OK',
      HIDE_ALL_MESSAGES_FROM_USER_CONFIRMATION_MESSAGE:
        'You will no longer see any messages from {{username}}.',
    },
    NO_POSTS_MESSAGE: {
      LINE1: 'Welcome!',
      LINE2: 'Why not start a conversation?',
    },
    DIALOGS: {
      JOIN_LEADERBOARD_CONFIRMATION_TITLE: 'You joined!',
      JOIN_LEADERBOARD_CONFIRMATION_MESSAGE: 'It may take a few hours for your stats to appear.',
    },
    GROUP_FEED: {
      MESSAGE_GROUP: 'Message {{groupName}}.',
      REPLY: 'Reply',
      REPLIES: 'Replies',
      ADD_A_REPLY: 'Add a reply',
    },
  },
  TABS: {
    MESSAGES: 'Messages',
    CONNECT: 'Connect',
    DAILY: 'Daily',
    TRENDS: 'Trends',
    SETTINGS: 'Settings',
    COMMUNITY: 'Groups',
    COACHES: 'Coaches',
    GROUP_FEED: 'Group Feed',
    LOG: 'My Data',
    BOTTOM_TABS: {
      PROGRAM: 'Program',
      GROUPS: 'Communities',
      ACCOUNT: 'Account',
      MESSAGES: 'Messages',
      CONNECT: 'Connect',
      LOG: 'My Data',
    },
  },
  SETTINGS: {
    EDIT_CONNECTED_APPS: 'Edit Connected Apps',
    CONNECTED_APPS_AND_TRACKERS: 'Connected Apps and Trackers',
    CONNECT_BLUETOOTH_DEVICES: 'Connect Bluetooth Devices',
    ENTER_AN_INVITE_ID: 'Enter {{article}} {{inviteIdName}}',
    ENTER_A_CLIENT_USER_ID: 'Enter {{article}} {{clientUserIdName}}',
    CLIENT_USER_ID_DISPLAY: 'Client User ID',
    CLIENT_USER_ID_ARTICLE: 'a',
    ENTER_YOUR_PATIENT_ID: 'Enter your Patient ID',
    ENTER_EMAIL_CONFIRMATION_CODE: 'Enter email confirmation code',
    PATIENT_ID: 'Patient ID',
    ENTER_YOUR_ID: 'Enter your {{inviteIdShortName}}',
    PATIENT_ID_ADDED_TITLE: 'Success!',
    PATIENT_ID_ADDED_MESSAGE:
      'Lab results will now appear for this patient ID as they become ready',
    HELP: 'Help',
    PRIVACY_POLICY: 'Privacy Policy',
    TERMS_OF_SERVICE: 'Terms of Service',
    LOG_OUT: 'Log Out',
    LOAD_FAILURE: 'Loading of Coach settings has failed. Pull down to refresh and try again.',
    RATE_APP: 'Rate App',
    EMAIL_CONFIRMED: 'Thank you for verifying your email address.',
    CONFIRM_EMAIL_ADDRESS: 'Verify Your Email Address',
    DELETE_ACCOUNT: {
      BUTTON_TITLE: 'Delete',
      COMPARATOR: 'DELETE',
      DESCRIPTION:
        "This will delete your account and all associated data from our system. You will not be able to reactivate your account or recover any data you've logged or synced.",
      INSTRUCTIONS:
        'If you wish to permanently delete your account type DELETE (in ALL CAPS), then tap Delete',
      ERROR_MESSAGE: 'Type DELETE (in ALL CAPS)',
      ERROR_DELETING_ACCOUNT: 'Sorry, we cannot delete your account right now.',
      CONFIRMATION_TITLE: 'Account Deleted',
      CONFIRMATION_MESSAGE: `We're deleting your account and all associated data from our system. This may take some time.`,
    },
    ERRORS: {
      SUPPORT_EMAIL_FAILED_TITLE: 'Cannot open email for support request',
      SUPPORT_EMAIL_FAILED_MESSAGE:
        'Please check that you have an email account enabled on your device.',
      ADD_INVITE_ID_FAILED_TITLE: 'Cannot add {{inviteIdName}}',
      ADD_PATIENT_ID_FAILED_TITLE: 'Cannot add patient ID',
      INVALID_PATIENT_ID_MESSAGE:
        'Patient ID, name, and birthdate do not match an existing lab order.',
      ENTER_EMAIL_CONFIRMATION_CODE_FAILED_TITLE: 'Email verification failed',
      CURRENT_PASSWORD_NOT_CORRECT: 'Current password is not correct',
      NEW_PASSWORD_DOES_NOT_MATCH: 'New password and confirmation do not match',
    },
    BLUETOOTH_LOCATION_PERMISSION_PROMPT: {
      PRIVACY_POLICY_URL_DESCRIPTION: 'View privacy policy',
      TITLE: 'Allow access to location?',
      EXPLANATION:
        'Location access is required in order for {{appName}} to find your bluetooth device and stay connected to it. We do not collect or store information about your location.', //Some generic text always at the top of the location consent screen telling you about why we're doing this
      SETTINGS_INFO: {
        IOS:
          "You can update your location permissions in your app settings. For the best experience, make sure access to location is set to 'Always on'.",
        ANDROID:
          "You can update your location permissions in your app settings. For the best experience, make sure access to location is set to 'Allow all the time'.",
      }, //something that appears just above the button that takes you to settings. We show that button as long as the location question has been answered already, so a user can either accept permissions they rejected, or turn off permissions they accepted
      ACCEPTED: "Location access is turned on. You're all set!", //We show this below the explanation to indicate that the user accepted permissions already and doesn't need to do anything else.
      REJECTED:
        'Location access is not turned on. It must be enabled in order to connect to bluetooth devices.', //We show this if they rejected any/ all location permissions, right above the settings button that they can use to fix them
      REJECTED_ALWAYS:
        'Background location access is turned off. This is required in order to read data from your bluetooth devices when the app is closed.', //We show this on iOS only, if the user accepted 'While the app is open' permissions but rejected 'Always' permissions. Note that the initial acceptance is always a provisional-always, and then the user is prompted to keep tracking in the background later. But the user will not see this message until they reject always-on tracking
      ANDROID_11_BACKGROUND_LOCATION_PROMPT: "Enable 'All the time' location to complete setup.", //This shows up on the OS-required prompt that actually sets the location permissions
      NOT_ANSWERED: '',
    },
    NOTIFICATIONS: {
      NOTIFICATIONS: 'Notifications',
      COMMUNITIES: 'COMMUNITIES',
      NO_COMMUNITIES: "You're not a member of any communities",
      NOTIFICATION_SETTING: 'Notification Setting',
      COMMUNITY: 'Community',
      ALL_POSTS: 'All Posts',
      ADMIN_POSTS_REPLIES_THREADS: "Admin posts & all replies in threads I've contributed to",
      ADMIN_POSTS_REPLIES: 'Admin posts and replies to my posts',
      ADMIN_POSTS: 'Admin posts only',
      TURN_OFF: 'Turn off notifications',
    },
    OTA: {
      NEW_VERSION_AVAILIBLE: 'A new app version is available.',
      UPGRADE_TO_LATEST: 'Upgrade to the latest version',
      NEW_VERSION_AVAILIBLE_TRY_AGAIN:
        "A new app version is available, but we can't download it right now. Don't worry, we'll try again later.",
      NETWORK_MAY_BE_BLOCKING:
        'If you see this often, your network may be blocking updates. Switching to a different wifi network may help.',
      ERROR_MESSAGE: 'Error message:',
    },
  },
  UNITS: {
    SVG: 'svg',
    SVGS: 'svgs',
    MINUTE: 'minute',
    MINUTES: 'minutes',
    HOUR: 'hour',
    HOURS: 'hours',
    SYSTOLIC: 'systolic',
    DIASTOLIC: 'diastolic',
    MINHEARTRATE: 'min',
    MAXHEARTRATE: 'max',
    AVGHEARTRATE: 'avg',
  },
};
