import React, { Component } from 'react';
import { View, Text, TextInput } from 'react-native';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  RowCenteredButton,
  SeparatorLine,
  ValidationError,
  NudgeSectionList as SectionList,
} from '/common/components';
import { textStyles, sizes, colors } from '../../config/common-styles';

const sectionHeaderStyle = {
  backgroundColor: 'transparent',
  height: sizes.medium,
};

const rowStyle = {
  paddingHorizontal: sizes.medium,
  paddingVertical: sizes.medium,
  backgroundColor: colors.bg0,
};

const textStyle = [
  textStyles.standard.dark,
  {
    paddingHorizontal: sizes.medium,
    paddingVertical: sizes.medium,
  },
];

const descriptionTextStyle = [
  textStyles.standard.bold,
  {
    paddingHorizontal: sizes.medium,
    paddingTop: sizes.medium,
  },
];

class DeleteAccount extends Component {
  render() {
    const {
      t,
      deleteInput,
      hasValidationError,
      onChangeDeleteInput,
      onPressDeleteAccount,
    } = this.props;

    const renderListHeader = () => {
      return (
        <View>
          <Text style={descriptionTextStyle}>{t('SETTINGS:DELETE_ACCOUNT:DESCRIPTION')}</Text>
          <Text style={textStyle}>{t('SETTINGS:DELETE_ACCOUNT:INSTRUCTIONS')}</Text>
        </View>
      );
    };

    const sections = [];

    sections[0] = {
      title: '',
      data: [
        () => {
          return (
            <View style={rowStyle}>
              <TextInput
                value={deleteInput}
                style={textStyles.standard.dark}
                onChangeText={onChangeDeleteInput}
                autoCorrect={false}
                autoFocus
                underlineColorAndroid="transparent"
                autoCapitalize="none"
              />
              {hasValidationError && (
                <ValidationError text={t('SETTINGS:DELETE_ACCOUNT:ERROR_MESSAGE')} />
              )}
            </View>
          );
        },
      ],
    };

    sections[1] = {
      title: '',
      data: [
        () => (
          <RowCenteredButton
            onPress={onPressDeleteAccount}
            title={t('SETTINGS:DELETE_ACCOUNT:BUTTON_TITLE')}
            actionColor={colors.error}
          />
        ),
      ],
    };

    return (
      <View
        style={{
          flex: 1,
          backgroundColor: colors.bg1,
        }}>
        <SectionList
          renderItem={({ item }) => item()}
          sections={sections}
          keyExtractor={index => index}
          ListHeaderComponent={renderListHeader}
          renderSectionHeader={() => <View style={sectionHeaderStyle} />}
          ItemSeparatorComponent={SeparatorLine}
          SectionSeparatorComponent={SeparatorLine}
          keyboardShouldPersistTaps="always"
        />
      </View>
    );
  }
}

DeleteAccount.propTypes = {
  t: PropTypes.func.isRequired,
  onPressDeleteAccount: PropTypes.func.isRequired,
  deleteInput: PropTypes.string.isRequired,
  onChangeDeleteInput: PropTypes.func.isRequired,
  hasValidationError: PropTypes.bool,
};

export default withTranslation()(DeleteAccount);
