import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Pressable } from 'react-native';
import { debounce } from 'lodash';
import { colors } from '/common/config/styles';
import { colord } from 'colord';
/**
 * Touchable with convenient shortcuts for Pressable API
 */
const NudgeTouchable = ({
  onPress,
  isTouchableOpacity,
  useHoverStyle,
  style,
  styleForHover,
  defaultColor,
  hoverColor,
  isDebounced,
  wait = 100,
  ...rest
}) => {
  const myStyleForHover =
    styleForHover || (hovered => ({ backgroundColor: hovered ? hoverColor : defaultColor }));
  const debouncedOnPress = useCallback(
    isDebounced ? debounce(onPress, wait, { leading: true, trailing: false }) : onPress,
    [isDebounced, wait, onPress]
  );
  const styleForPressableStateProps = useCallback(
    ({ pressed, hovered }) => [
      { outlineColor: 'black' /* focus outline color */ },
      style,
      isTouchableOpacity ? { opacity: pressed ? 0.5 : 1 } : null,
      // make sure hover style doesn't override pressed style
      useHoverStyle ? myStyleForHover(hovered) : null,
    ],
    [style, isTouchableOpacity, styleForHover, hoverColor, defaultColor]
  );
  return <Pressable {...rest} style={styleForPressableStateProps} onPress={debouncedOnPress} />;
};

NudgeTouchable.propTypes = {
  onPress: PropTypes.func.isRequired,
  wait: PropTypes.number, // ms for debounce
  isTouchableOpacity: PropTypes.bool,
  style: PropTypes.any,
  styleForHover: PropTypes.func,
  isDebounced: PropTypes.bool,
  useHoverStyle: PropTypes.bool,
  defaultColor: PropTypes.string,
  hoverColor: PropTypes.string,
};

NudgeTouchable.defaultProps = {
  isTouchableOpacity: false,
  style: null,
  styleForHover: null,
  isDebounced: false,
  useHoverStyle: false,
  defaultColor: colors.bg0,
  hoverColor: colord(colors.bg0)
    .darken(0.025)
    .toHex(),
};

export default NudgeTouchable;
