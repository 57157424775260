import * as IntentLauncher from 'expo-intent-launcher';
import { Alert } from 'react-native';

export default class MyIntentLauncher {
  static async startActivityAsync(...params) {
    try {
      // this is just a guess because I'm having trouble pinning down this error
      if (!IntentLauncher) {
        Alert.alert(
          'Cannot open attachment',
          'This app requires an update. Get the latest version of the app on the Play Store to start viewing attachments'
        );
        return;
      }
      await IntentLauncher.startActivityAsync(...params);
    } catch (error) {
      throw error;
      // I can't seem to get a positive ID on this error
      /*Alert.alert(
        'Cannot open attachment',
        'This app requires an update. Get the latest version of the app on the Play Store to start viewing attachments'
      );*/
    }
  }
}
