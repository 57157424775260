import React, { Component } from 'react';
import { View, TextInput } from 'react-native';
import PropTypes from 'prop-types';
import {
  SeparatorLine,
  ValidationError,
  RowCenteredButton,
  NudgeSectionList as SectionList,
  AestheticallyPleasingListFooter,
} from '../components';
import { colors } from '/common/config/styles';

const defaultRowStyle = {
  paddingHorizontal: 15,
  minHeight: 45.5, // ensures other cells are same size as cell with a toggle
  backgroundColor: '#FFFFFF',
  justifyContent: 'center',
};

export default class ChangePassword extends Component {
  constructor() {
    super();
    this.state = {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    };
  }

  _onPressChangePassword = () => {
    const { currentPassword, newPassword, confirmNewPassword } = this.state;
    this.props.onPressChangePassword({ currentPassword, newPassword, confirmNewPassword });
  };

  _renderTextInput = (
    field,
    fieldName,
    refName,
    onChangeText,
    onSubmitEditing,
    returnKeyType,
    autoFocus,
    showError,
    validationErrorMessage
  ) => {
    return (
      <View style={[defaultRowStyle, this.props.rowStyle]}>
        <TextInput
          ref={ref => {
            this[refName] = ref;
          }}
          value={field}
          style={[{ height: 40, width: '100%' }, this.props.textInputStyle]}
          placeholder={fieldName}
          onChangeText={onChangeText}
          secureTextEntry
          returnKeyType={returnKeyType}
          onSubmitEditing={() => onSubmitEditing && onSubmitEditing()}
          autoCorrect={false}
          autoFocus={autoFocus}
          underlineColorAndroid="transparent"
          autoCapitalize="none"
        />
        {showError && validationErrorMessage.length > 0 && (
          <ValidationError text={validationErrorMessage} />
        )}
      </View>
    );
  };

  render() {
    const {
      validationErrorMessage,
      spaceBetweenSections,
      listStyle,
      buttonStyle,
      buttonTitle,
      buttonTextColor,
      currentPasswordPlaceholder,
      newPasswordPlaceholder,
      confirmNewPasswordPlaceholder,
      RowSeparatorComponent,
    } = this.props;

    const { currentPassword, newPassword, confirmNewPassword } = this.state;

    const sections = [];

    sections.push({
      title: '',
      data: [
        () => {
          return this._renderTextInput(
            currentPassword,
            currentPasswordPlaceholder,
            'currentPasswordRef',
            text => this.setState({ currentPassword: text }),
            () => this.newPasswordRef.focus(),
            'next',
            true
          );
        },
        () => {
          return this._renderTextInput(
            newPassword,
            newPasswordPlaceholder,
            'newPasswordRef',
            text => this.setState({ newPassword: text }),
            () => this.confirmNewPasswordRef.focus(),
            'next'
          );
        },
        () => {
          return this._renderTextInput(
            confirmNewPassword,
            confirmNewPasswordPlaceholder,
            'confirmNewPasswordRef',
            text => this.setState({ confirmNewPassword: text }),
            this._onPressChangePassword,
            'done',
            false,
            true,
            validationErrorMessage
          );
        },
      ],
    });

    sections.push({
      title: '',
      data: [
        () => (
          <RowCenteredButton
            onPress={this._onPressChangePassword}
            style={buttonStyle}
            title={buttonTitle}
            actionColor={buttonTextColor}
          />
        ),
      ],
    });

    // empty section just for formatting final item separator under button
    sections.push({
      title: '',
      data: [],
    });

    return (
      <View style={{ flex: 1 }}>
        <SectionList
          renderItem={({ item }) => item()}
          sections={sections}
          keyExtractor={index => index}
          renderSectionHeader={() => (
            <View style={{ backgroundColor: colors.bg0, height: spaceBetweenSections }} />
          )}
          ItemSeparatorComponent={RowSeparatorComponent}
          style={listStyle}
          keyboardShouldPersistTaps="always"
          ListFooterComponent={AestheticallyPleasingListFooter}
        />
      </View>
    );
  }
}

ChangePassword.propTypes = {
  spaceBetweenSections: PropTypes.number,
  listStyle: PropTypes.any,
  textInputStyle: PropTypes.any,
  rowStyle: PropTypes.any,
  onPressChangePassword: PropTypes.func.isRequired,
  validationErrorMessage: PropTypes.string,
  buttonStyle: PropTypes.any,
  buttonTextColor: PropTypes.string,
  buttonTitle: PropTypes.string,
  currentPasswordPlaceholder: PropTypes.string,
  newPasswordPlaceholder: PropTypes.string,
  confirmNewPasswordPlaceholder: PropTypes.string,
  RowSeparatorComponent: PropTypes.any,
};

ChangePassword.defaultProps = {
  spaceBetweenSections: 15,
  listStyle: null,
  textInputStyle: null,
  rowStyle: null,
  validationErrorMessage: '',
  buttonStyle: null,
  buttonTextColor: null,
  buttonTitle: 'Change Password',
  currentPasswordPlaceholder: 'Current Password',
  newPasswordPlaceholder: 'New Password',
  confirmNewPasswordPlaceholder: 'Confirm New Password',
  RowSeparatorComponent: SeparatorLine,
};
