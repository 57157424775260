import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  wrapper: {},
  spinner: {
    flex: 1,
    backgroundColor: 'transparent',
  },
  noItemsMessageText: {
    color: 'gray',
    textAlign: 'center',
  },
});
