import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Keyboard } from 'react-native';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { withAlert } from '/common/navigation/';
import { UpdateName } from '../../../components/settings';
import UpdateSettingsScreenWrapper from './UpdateSettingsScreenWrapper';

@inject('rootStore')
@observer
class UpdateNameScreen extends Component {
  constructor(props) {
    super(props);
    const { rootStore } = this.props;
    const { settingsStore } = rootStore;
    const { user } = settingsStore;
    this.state = {
      firstName: user.firstName,
      lastName: user.lastName,
    };
  }

  _onPressSave = async () => {
    Keyboard.dismiss();
    const { firstName, lastName } = this.state;
    const { rootStore, navigation } = this.props;
    const { settingsStore } = rootStore;
    await settingsStore.updateUserFields({ firstName, lastName });
    if (settingsStore.updateUserState.isDone) {
      navigation.pop();
    }
  };

  _clearErrors = () => {
    const { rootStore } = this.props;
    const { settingsStore } = rootStore;
    settingsStore.updateUserState.reset();
  };

  _onChangeFirstName = firstName => {
    this._clearErrors();
    this.setState({ firstName });
  };

  _onChangeLastName = lastName => {
    this._clearErrors();
    this.setState({ lastName });
  };

  _onPressBack = () => {
    this._clearErrors();
    Keyboard.dismiss();
    this.props.navigation.pop();
  };

  _onPressCancel = () => {
    const { alert, t } = this.props;

    alert(t('PROFILE:EDIT:UNSAVED_CHANGES'), t('PROFILE:EDIT:WHAT_WOULD_YOU_LIKE_TO_DO'), [
      {
        text: t('PROFILE:EDIT:SAVE_CHANGES'),
        onPress: () => {
          this._onPressSave();
        },
        style: 'default',
      },
      {
        text: t('PROFILE:EDIT:DISCARD_CHANGES'),
        onPress: () => {
          this._onPressBack();
        },
        style: 'destructive',
      },
    ]);
  };

  render() {
    const { rootStore, navigation, t } = this.props;
    const { settingsStore } = rootStore;
    const { firstName, lastName } = this.state;
    const { user } = settingsStore;
    return (
      <UpdateSettingsScreenWrapper
        navigation={navigation}
        isDirty={user.firstName !== firstName || user.lastName !== lastName}
        navbarTitle={t('PROFILE:FIELDS:NAME')}
        updateFailedMessage={t('PROFILE:ERRORS:UPDATE_FAILED_MESSAGE')}
        onPressCancel={this._onPressCancel}
        onPressBack={this._onPressBack}
        onPressSave={this._onPressSave}
        form={
          <UpdateName
            firstName={firstName}
            lastName={lastName}
            onChangeFirstName={this._onChangeFirstName}
            onChangeLastName={this._onChangeLastName}
            formErrors={settingsStore.updateUserState.formErrors}
          />
        }
      />
    );
  }
}

UpdateNameScreen.propTypes = {
  rootStore: PropTypes.object,
  navigation: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(withAlert(UpdateNameScreen));
