import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { observer, inject } from 'mobx-react';
import { navigationRoutes } from '../../config/constants';
import ConnectedAppsScreen from './ConnectedAppsScreen';
import PushNotificationInfoScreen from './PushNotificationInfoScreen';
import EnterInviteIdForSsoScreen from './EnterInviteIdForSsoScreen';
import UpdateTrackingConsentScreen from './UpdateTrackingConsentScreen';
import TutorialScreen from './TutorialScreen';
import ConnectedBluetoothPeripheralsScreen from './ConnectedBluetoothPeripheralsScreen';
import DownloadMobileAppHowtoScreen from './DownloadMobileAppHowtoScreen';
import WrapUpScreen from './WrapUpScreen';
import { SyncBluetoothDeviceModal } from '../modals';

const Stack = createStackNavigator();

const OnboardingStack = inject('rootStore')(
  observer(({ rootStore }) => (
    <Stack.Navigator
      initialRouteName={
        rootStore.onboardingScreens.length ? rootStore.onboardingScreens[0].route : null
      }
      options={{ gestureEnabled: false }}
      headerMode="none">
      <Stack.Screen
        name={navigationRoutes.stacks.onboarding.pushNotifications}
        component={PushNotificationInfoScreen}
      />
      <Stack.Screen
        name={navigationRoutes.stacks.onboarding.downloadMobileApp}
        component={DownloadMobileAppHowtoScreen}
      />
      <Stack.Screen
        name={navigationRoutes.stacks.onboarding.connectedApps}
        component={ConnectedAppsScreen}
      />
      <Stack.Screen
        name={navigationRoutes.stacks.onboarding.inviteId}
        component={EnterInviteIdForSsoScreen}
      />
      <Stack.Screen
        name={navigationRoutes.stacks.onboarding.updateTrackingConsent}
        component={UpdateTrackingConsentScreen}
      />
      <Stack.Screen
        name={navigationRoutes.stacks.onboarding.connectedBluetoothPeripherals}
        component={ConnectedBluetoothPeripheralsScreen}
      />
      <Stack.Screen
        name={navigationRoutes.stacks.onboarding.syncBluetoothDevice}
        component={SyncBluetoothDeviceModal}
      />
      <Stack.Screen name={navigationRoutes.stacks.onboarding.tutorial} component={TutorialScreen} />
      <Stack.Screen name={navigationRoutes.stacks.onboarding.wrapUp} component={WrapUpScreen} />
    </Stack.Navigator>
  ))
);

export default OnboardingStack;
