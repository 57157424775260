import { NativeModules } from 'react-native';
/* eslint-disable */
// This file is not checked in, needs to be set via yarn run set-nudge-config.
import appConfig from '../../current-config/nudge-config.json';
/* eslint-enable */

let DeviceInfo;

if (NativeModules.RNDeviceInfo) {
  DeviceInfo = require('react-native-device-info').default;
} else {
  console.warn(
    'WARNING: Not using react-native-device-info! This is OK if you are running in the Expo client. Very bad if not!'
  );
}

export default () => {
  if (DeviceInfo) {
    return DeviceInfo.getBundleId();
  } else {
    if (appConfig.simulatedBundleId) {
      return appConfig.simulatedBundleId;
    }
    return null;
  }
};
