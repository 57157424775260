import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import * as Linking from 'expo-linking';
import { withTranslation } from 'react-i18next';
import { LoadingShadeWrapper } from '../../../components/common';
import {
  SimpleNavWrapper,
  HeaderIconButton,
  SimpleNavButton,
} from '../../../components/navigation';
import { UpdateTrackingConsent } from '../../../components/settings';
import { containerStyles } from '../../../config/common-styles';
import { ScreenError, withKeyboardAvoidance } from '../../common';
import { BrandContext } from '../../../config/branding';
import { BackIcon } from '../../../config/common-icons';
import { navigationRoutes } from '../../../config/constants';

@inject('rootStore')
@observer
class UpdateTrackingConsentScreen extends Component {
  static contextType = BrandContext;

  _onPressBack = () => {
    const { navigation } = this.props;
    navigation.goBack();
  };

  _onPressNext = async () => {
    const { rootStore } = this.props;

    await rootStore.markOnboardingStepComplete(
      navigationRoutes.stacks.onboarding.updateTrackingConsent
    );
  };

  componentDidMount() {
    const { rootStore } = this.props;
    const { settingsStore } = rootStore;
    settingsStore.getTrackingConsentStatus();
    // we need to check continuously because:
    // a) Radar's permission granting function doesn't finish async, and
    // b) this permission could get updated at any time, including via the settings app
    // c) AND, this is actually how it works for granding background location in Android
    this._locationCheckTimerId = setInterval(async () => {
      settingsStore.getTrackingConsentStatus();
    }, 3000);
  }

  componentWillUnmount() {
    if (this._locationCheckTimerId) {
      clearInterval(this._locationCheckTimerId);
    }
  }

  render() {
    const { rootStore, t, isDuringOnboarding } = this.props;
    const { settingsStore } = rootStore;

    const branding = this.context;
    const consentScreenStrings = branding.locationTracking.consentScreen;

    let leftNavButton = null;
    let rightNavButton = null;

    // show next button
    if (isDuringOnboarding) {
      rightNavButton =
        settingsStore.trackingConsentStatus && settingsStore.trackingConsentStatus.canAsk ? null : (
          <SimpleNavButton title={'continue'} onPress={this._onPressNext} isPrimaryAction />
        );
    } else {
      // show back button
      leftNavButton = <HeaderIconButton icon={<BackIcon />} onPress={this._onPressBack} />;
    }

    return (
      <SimpleNavWrapper leftButton={leftNavButton} rightButton={rightNavButton}>
        <ScreenError
          state={settingsStore.trackingConsentActionState}
          title={t('PROFILE:ERRORS:UPDATE_FAILED')}
          message={t('PROFILE:ERRORS:TRACKING_CONSENT_UPDATE_FAILED_MESSAGE')}
        />
        <LoadingShadeWrapper isLoading={false} style={containerStyles.fillAvailableSpace}>
          <UpdateTrackingConsent
            onPressPrivacyPolicy={() => {
              Linking.openURL(consentScreenStrings.privacyPolicyUrl);
            }}
            onPressAcceptForegroundTracking={() => {
              settingsStore.updateTrackingConsent({ background: false });
            }}
            onPressAcceptBackgroundTracking={() => {
              settingsStore.updateTrackingConsent({ background: true });
            }}
            onPressOpenSettings={() => {
              Linking.openSettings();
            }}
            trackingConsentStatus={settingsStore.trackingConsentStatus}
            showAcceptTrackingButton
          />
        </LoadingShadeWrapper>
      </SimpleNavWrapper>
    );
  }
}

UpdateTrackingConsentScreen.propTypes = {
  rootStore: PropTypes.object,
};

export default withTranslation()(withKeyboardAvoidance(UpdateTrackingConsentScreen));
