function delay(t, v) {
  return new Promise(function(resolve) {
    setTimeout(resolve.bind(null, v), t);
  });
}

const uniquenessKeyHash = {};

function executeIfNotExecutedBeforeForKey({ executeFn, uniquenessKey }) {
  if (!uniquenessKeyHash[uniquenessKey]) {
    uniquenessKeyHash[uniquenessKey] = true;
    executeFn();
  }
}

const getRandomString = (length, chars) => {
  let result = '';
  for (let i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  return result;
};

const getAlphanumericKeyWithSize = size => {
  const availableCharacters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  return getRandomString(size, availableCharacters);
};

const getDisabledYellowBoxWarnings = () => [
  'Clipboard has been extracted',
  // isMounted warning ignored per https://github.com/react-navigation/react-navigation/issues/3956
  'Warning: isMounted(...) is deprecated',
  'Module RCTImageLoader',
  'Remote debugger',
  'Setting a timer',
  // https://forums.expo.io/t/bunch-of-yellowbox-warnings-when-running-detached-app-in-development/11771/3
  'Class EX',
  'Class RCT',
  // https://github.com/facebook/react-native/issues/18201
  'Class RCTCxxModule',
  // why is this appearing on iOS?
  '<InputAccessoryView> is not supported on Android yet',
  'WARNING: Not using expo-firebase-app',
  'WARNING: Not using react-native-validic',
  'WARNING: Not using react-native-device-info',
  'WARNING: Not using react-native-branch',
  'WARNING: Not registering for push',
  'Not registering for Android push',
  'Not registering for iOS push',
  "Unrecognized font family 'custom-icons",
  'Constants.manifest',
  'ViewPropTypes',
  'new NativeEventEmitter()', // error from BluetoothSyncRepository:47 - probably not the best, but better to hide for the moment
];

export {
  delay,
  executeIfNotExecutedBeforeForKey,
  getAlphanumericKeyWithSize,
  getDisabledYellowBoxWarnings,
};
