import React from 'react';
import { View, Platform } from 'react-native';
import { observer } from 'mobx-react';
import { colors, textStyles, sizes } from '/common/config/styles';

const ChatListItem = observer(
  ({
    DayComponent,
    MessageBubbleComponent,
    message,
    onPressAttachment,
    currentUserId,
    onLongPressMessage,
    onPressUrl,
    onPressImage,
    inverted,
    ...rest
  }) => {
    const item = message;
    let bubble = (
      <MessageBubbleComponent
        currentMessage={item}
        isMe={item.userId === currentUserId}
        onPressAttachment={onPressAttachment}
        onLongPressMessage={onLongPressMessage}
        onPressUrl={onPressUrl}
        onPressImage={onPressImage}
        {...rest}
      />
    );

    return (
      <View
        style={{
          backgroundColor: colors.bg0,
          marginVertical: Platform.OS === 'web' ? sizes.small : undefined,
          // flip message bubble using styles on android 13 to show upside down flatlist normally
          transform: [
            {
              rotate:
                inverted && Platform.OS === 'android' && Platform.Version >= 33 ? '180deg' : '0deg',
            },
          ],
        }}>
        <DayComponent
          textStyle={textStyles.small.light}
          previousMessageCreatedAt={item.previousMessageCreatedAt}
          currentMessage={item}
        />
        <View
          style={[
            { flexDirection: 'row' },
            Platform.isPad && {
              marginHorizontal: 100 /* extra breathing room just for iPad */,
            },
          ]}>
          {bubble}
        </View>
      </View>
    );
  }
);

export default ChatListItem;
