import { NativeEventEmitter, ValidicBluetooth } from 'react-native-validic-bluetooth';


export default class BluetoothListener {
  _nativeEventEmitter;

  // callbacks to execute when events occur - assign to these in order to bind specific actions to these events
  onPeripheralDidPair = () => {};
  onPeripheralDidNotPair = () => {};
  onPeripheralReadyToRead = () => {};
  onPeripheralPassiveDidRead = () => {};

  constructor() {
    if (ValidicBluetooth) {
      this._nativeEventEmitter = new NativeEventEmitter(ValidicBluetooth);
    }
  }

  startListening() {
    if (this._nativeEventEmitter) {
      this._nativeEventEmitter.addListener('ValidicOnBluetoothPeripheralDidPair', event => {
        console.log('did pair!');
        this.onPeripheralDidPair(this._mungeInboundEvent(event));
      });
      this._nativeEventEmitter.addListener('ValidicOnBluetoothPeripheralDidNotPair', event => {
        console.log('did not pair!');
        this.onPeripheralDidNotPair(this._mungeInboundEvent(event));
      });
      this._nativeEventEmitter.addListener('ValidicOnBluetoothPeripheralIsReadyToRead', event => {
        console.log('ready to read!');
        this.onPeripheralReadyToRead(this._mungeInboundEvent(event));
      });
      this._nativeEventEmitter.addListener('ValidicBluetoothOnPassiveDidRead', event => {
        console.log('did read!');
        this.onPeripheralPassiveDidRead(this._mungeInboundEvent(event));
      });
    }
  }

  stopListening() {
    if (this._nativeEventEmitter) {
      this._nativeEventEmitter.removeAllListeners();
    }
  }

  _mungeInboundEvent = event => {
    console.log(event);
    event.peripheralId = event.peripheralID;
    return event;
  };
}
