import React from 'react';
import { View, ActivityIndicator, Text, TouchableOpacity } from 'react-native';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { sizes, colors, textStyles } from '/common/config/styles';
//import { BrandContext } from '/common/config/branding';

/**
 * Generic indicator for loading additional items in a list. Designed as a "plugin" for a UI component, to be added at
 * the "screen" level, a lot like other loading wrappers (that way, we don't have to pass down LoadingState into the actual UI component)
 * TODO:
 * - add error state
 * - integrate into chat
 */
const LoadMoreIndicator = observer(({ loadingState, onPressLoadMore, canLoadMore, showButton }) => {
  //const branding = useContext(BrandContext);
  const { t } = useTranslation();

  if (!canLoadMore) {
    return (
      <View
        style={{
          height: sizes.medium * 2 /* make sure the last item doesn't hit the very bottom */,
        }}
      />
    );
  }

  if (loadingState.isPending) {
    return (
      <View style={{ padding: sizes.medium, paddingVertical: sizes.medium * 2 }}>
        <ActivityIndicator color={colors.lightText} size={'large'} />
      </View>
    );
  }

  let inside = null;
  if (showButton) {
    inside = (
      <TouchableOpacity onPress={onPressLoadMore} accessibilityTraits="button">
        <View
          style={{
            backgroundColor: colors.bg0,
            borderRadius: sizes.borderRadius,
            padding: sizes.medium,
          }}>
          <Text style={textStyles.standard.light}>{t('PROGRAMS:LOAD_MORE')}</Text>
        </View>
      </TouchableOpacity>
    );
  }

  return <View style={{ alignItems: 'center', paddingVertical: sizes.medium * 2 }}>{inside}</View>;
});

export default LoadMoreIndicator;
