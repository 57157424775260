/* eslint no-use-before-define: ["error", { "variables": false }] */

import PropTypes from 'prop-types';
import React from 'react';
import MessageFileAttachment from './MessageFileAttachment';
import MessageImage from './MessageImage';
import MessageMeetingLink from './MessageMeetingLink';

export default class MessageAttachmentSwitcher extends React.Component {
  _onLongPress = () => {
    if (!this.props.currentMessage.isPending) {
      this.props.onLongPressMessage(this.props.currentMessage);
    }
  };

  _onPressAttachment = () => {
    if (!this.props.currentMessage.isPending) {
      this.props.onPressAttachment(this.props.currentMessage);
    }
  };

  _onPressImage = () => {
    if (!this.props.currentMessage.isPending) {
      this.props.onPressImage(this.props.currentMessage);
    }
  };

  render() {
    const {
      currentMessage,
      ImageComponent,
      FileAttachmentComponent,
      MeetingLinkComponent,
    } = this.props;

    return currentMessage.hasImage ? (
      <ImageComponent
        {...this.props}
        onLongPress={this._onLongPress}
        onPress={this._onPressImage}
      />
    ) : currentMessage.hasFileAttachment ? (
      <FileAttachmentComponent
        {...this.props}
        onLongPress={this._onLongPress}
        onPress={this._onPressAttachment}
      />
    ) : currentMessage.hasMeetingLink ? (
      <MeetingLinkComponent
        {...this.props}
        onLongPress={this._onLongPress}
        onPress={this._onPressAttachment}
      />
    ) : null;
  }
}

MessageAttachmentSwitcher.propTypes = {
  ImageComponent: PropTypes.any,
  FileAttachmentComponent: PropTypes.any,
  MeetingLinkComponent: PropTypes.any,
};

MessageAttachmentSwitcher.defaultProps = {
  ImageComponent: MessageImage,
  FileAttachmentComponent: MessageFileAttachment,
  MeetingLinkComponent: MessageMeetingLink,
};
