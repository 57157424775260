import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Text, Platform } from 'react-native';
import { observer } from 'mobx-react';
import ParsedText from 'react-native-parsed-text';
import { CircleThumbnail } from '/common/components';
import Styles from './styles';
import MessageAttachmentSwitcher from '/common/components/chat/MessageAttachmentSwitcher';
import { textStyles, sizes, colors } from '/common/config/styles';
import { timeForTodayOrDateForYesterday, time, fullName } from '/common/config/strings';
import { BrandContext } from '/common/config/branding';
import { isPhoneThatCrapsOutOnHugeListItems } from '/common/config/feature-support';

/**
 * The reply to a topic or the first message in the topic itself
 */
@observer
class MessageBody extends Component {
  static contextType = BrandContext;

  render() {
    const branding = this.context;

    const {
      topicOrReply,
      onPressLink,
      dateType,
      currentMessage,
      onPressAttachment,
      onLongPressMessage,
      onPressImage,
    } = this.props;
    const isHighlightedMessage =
      (topicOrReply.authorType === 'coach' || topicOrReply.authorType === 'company') &&
      !isPhoneThatCrapsOutOnHugeListItems();
    const parseTypes = [{ type: 'url', style: textStyles.link, onPress: onPressLink }];
    return (
      <View style={[Styles.messageThumbnailAndBody]}>
        <CircleThumbnail
          source={
            topicOrReply.author.photoUrl === '/images/branding/nudge/app-icon.png'
              ? branding.assets
                  .appIcon /* this should be in every bundle now, but just in case- also, I don't think we have access to the non-branded app company icon in the client app yet, so not doing this for the nudge app */
                ? branding.assets.appIcon
                : branding.assets.clubThumb
              : topicOrReply.author.photoUrl
          }
          diameter={sizes.thumbnail}
          altTitle={fullName(topicOrReply.author)}
        />
        <View style={Styles.messageBody}>
          <View style={Styles.threadMessageTopLine}>
            <Text
              ellipsizeMode="tail"
              numberOfLines={1}
              style={[
                Styles.threadMessageNameText,
                textStyles.standard.semibold,
                { flex: 1 /*cursor: 'text' // can't select the text for some reason */ },
              ]}>
              {fullName(topicOrReply.author)}
            </Text>
            <Text style={[textStyles.small.light, { marginBottom: 1 }]}>
              {dateType === 'time'
                ? time(topicOrReply.createdAt)
                : timeForTodayOrDateForYesterday(topicOrReply.createdAt)}
            </Text>
          </View>
          {topicOrReply.redacted ? null : (
            <View style={{ alignSelf: 'flex-start', width: '80%' }}>
              <MessageAttachmentSwitcher
                containerStyle={{ marginTop: sizes.small / 2 }}
                currentMessage={currentMessage}
                onPressAttachment={onPressAttachment}
                onLongPressMessage={onLongPressMessage}
                onPressImage={onPressImage}
              />
            </View>
          )}
          <View
            style={[
              isHighlightedMessage
                ? {
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                  }
                : null,
            ]}>
            {currentMessage.hasText || topicOrReply.redacted ? (
              <View
                style={[
                  Platform.OS === 'web' ? { maxWidth: '100%' } : null,
                  isHighlightedMessage
                    ? {
                        backgroundColor: branding.highlightedColor,
                        marginTop: sizes.small,
                        padding: sizes.small,
                        borderRadius: 4,
                      }
                    : null,
                ]}>
                <ParsedText
                  parse={parseTypes}
                  style={[
                    Styles.threadMessageText,
                    textStyles.standard.dark,
                    Platform.OS === 'web'
                      ? {
                          wordBreak:
                            'break-all' /* required for web to keep long words from going off forever. See https://css-tricks.com/almanac/properties/w/word-break/ */,
                          //cursor: 'text', // can't select the text for some reason
                        }
                      : null,
                    isHighlightedMessage
                      ? {
                          color: colors.bg0,
                        }
                      : null,
                  ]}>
                  {topicOrReply.redacted ? '[deleted]' : topicOrReply.comment}
                </ParsedText>
              </View>
            ) : null}
          </View>
        </View>
      </View>
    );
  }
}

MessageBody.propTypes = {
  topicOrReply: PropTypes.oneOfType([PropTypes.object, PropTypes.object]).isRequired,
  onPressLink: PropTypes.func.isRequired,
  dateType: PropTypes.oneOf(['time', 'dateOrTime']),
};

export default MessageBody;
