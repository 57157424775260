// Forked version of https://github.com/anarchicknight/react-native-communications
// with onUrlPress outsourced to a prop so we can add our own linking handler with logging enabled.

/* eslint no-use-before-define: ["error", { "variables": false }] */
import PropTypes from 'prop-types';
import React from 'react';
import { View, Platform } from 'react-native';
import TextWithLinks from '../TextWithLinks';

export default class MessageText extends React.Component {
  shouldComponentUpdate(nextProps) {
    return this.props.currentMessage.text !== nextProps.currentMessage.text;
  }

  render() {
    const { onPressUrl, textStyle } = this.props;

    return (
      <View>
        <TextWithLinks
          style={[
            textStyle,
            Platform.OS === 'web'
              ? {
                  wordBreak:
                    'break-all' /* required for web to keep long words from going off forever. See https://css-tricks.com/almanac/properties/w/word-break/ */,
                  cursor: 'text',
                }
              : null,
          ]}
          onPressUrl={onPressUrl}>
          {this.props.currentMessage.text}
        </TextWithLinks>
      </View>
    );
  }
}

MessageText.propTypes = {
  currentMessage: PropTypes.object.isRequired,
  textStyle: PropTypes.any,
  linkTextStyle: PropTypes.any,
  onPressUrl: PropTypes.func.isRequired,
};

MessageText.defaultProps = {
  textStyle: null,
  linkTextStyle: null,
};
