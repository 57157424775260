import React from 'react';
import { View } from 'react-native';
import { sizes, colors } from '/common/config/styles';

const DividerField = () => {
  return (
    <View
      style={{
        width: '100%',
        height: sizes.hairlineWidth,
        backgroundColor: colors.border1,
        marginTop: sizes.medium,
        marginBottom: sizes.medium + 8,
      }}
    />
  );
};

export default DividerField;
