import React, { Component } from 'react';
import { View, Text } from 'react-native';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { withAlert, ScreenError } from '/common/navigation/';
import { RowRadioButton } from '/common/components';
import { NavbarWrapper, HeaderIconButton } from '../../app/components/navigation/';
import { BackIcon, BellIcon } from '../../app/config/common-icons/';
import { colors, textStyles, fonts } from '/common/config/styles';

@inject('rootStore')
@observer
class IndividualCommunityNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationLevel: this.props.route.params.communityNotificationLevel,
    };
  }
  render() {
    const { navigation, t, route, rootStore } = this.props;
    const { socialStore } = rootStore;
    const { communityName, communityId } = route.params;
    const socialFeed = socialStore.socialFeedForClub(communityId);

    const setNotificationLevel = level => {
      socialFeed.changeNotificationLevel(level);
      this.setState({
        notificationLevel: level,
      });
    };

    const notificationOptions = [
      t('SETTINGS:NOTIFICATIONS:ALL_POSTS'),
      t('SETTINGS:NOTIFICATIONS:ADMIN_POSTS_REPLIES_THREADS'),
      t('SETTINGS:NOTIFICATIONS:ADMIN_POSTS_REPLIES'),
      t('SETTINGS:NOTIFICATIONS:ADMIN_POSTS'),
      t('SETTINGS:NOTIFICATIONS:TURN_OFF'),
    ];

    return (
      <NavbarWrapper
        title={`${communityName} ${t('SETTINGS:NOTIFICATIONS:COMMUNITY')}`}
        leftButton={
          <HeaderIconButton
            icon={<BackIcon />}
            onPress={() => {
              navigation.pop();
              // re populate new club notification state when returning to notifications screen
              socialStore.loadClubs();
            }}
          />
        }
        navigation={navigation}>
        <View
          style={{
            flex: 1,
            backgroundColor: colors.bg0,
            overflow: 'hidden',
          }}>
          <ScreenError state={socialFeed.updateMembershipState} />
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              padding: '6%',
            }}>
            <BellIcon />
            <View
              //spacer
              style={{ width: 15 }}
            />
            <Text
              style={[
                textStyles.standard.dark,
                { fontSize: fonts.sizes.standard, fontWeight: '500' },
              ]}>
              {t('SETTINGS:NOTIFICATIONS:NOTIFICATION_SETTING')}
            </Text>
          </View>
          {notificationOptions.map((i, index) => (
            <RowRadioButton
              label={i}
              onPress={() => setNotificationLevel(index + 1)}
              isSelected={parseInt(this.state.notificationLevel) === index + 1}
              key={i}
            />
          ))}
        </View>
      </NavbarWrapper>
    );
  }
}

IndividualCommunityNotifications.propTypes = {
  navigation: PropTypes.object,
  route: PropTypes.shape({
    params: PropTypes.shape({
      communityName: PropTypes.string.isRequired,
      communityId: PropTypes.number.isRequired,
      communityNotificationLevel: PropTypes.string.isRequired,
    }),
  }),
  t: PropTypes.func.isRequired,
  rootStore: PropTypes.object,
};

export default withTranslation()(withAlert(IndividualCommunityNotifications));
