import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { connectAlertBar } from '/common/navigation';
import { ChangePassword } from '/common/screens';
import { LoadingShadeWrapper, SeparatorLine } from '../../components/common';
import { containerStyles, colors, textStyles } from '../../config/common-styles';
import buildModalWithNavHeader from './buildModalWithNavHeader';
import { SuccessAlertBar, withKeyboardAvoidance, ScreenError } from '../common';
import { BrandContext } from '../../config/branding';

@inject('rootStore')
@connectAlertBar
@observer
class ChangePasswordScreen extends Component {
  static contextType = BrandContext;

  componentDidMount() {
    // clear any form errors
    this.props.rootStore.settingsStore.changePasswordState.reset();
  }

  _onPressChangePassword = async ({ currentPassword, newPassword, confirmNewPassword }) => {
    const { rootStore, navigation, showAlertBar } = this.props;
    navigation.setParams({ isModalCloseButtonDisabled: true });
    await rootStore.settingsStore.changePassword({
      currentPassword,
      newPassword,
      confirmNewPassword,
    });
    navigation.setParams({ isModalCloseButtonDisabled: false });
    if (rootStore.settingsStore.changePasswordState.isDone) {
      navigation.pop();
      showAlertBar({ view: <SuccessAlertBar title="Password changed!" /> });
    }
  };

  render() {
    const { rootStore, t } = this.props;
    const { settingsStore } = rootStore;
    const branding = this.context;

    let passwordValidationErrorText;
    const formErrors = settingsStore.changePasswordState.formErrors;
    if (formErrors.find(e => e.field === 'newPassword' && e.type === 'notMinimumLength')) {
      passwordValidationErrorText = t('SIGN_UP:ERRORS:PASSWORD_NOT_MINIMUM_LENGTH');
    }

    if (formErrors.find(e => e.field === 'currentPassword' && e.type === 'invalid')) {
      passwordValidationErrorText = t('SETTINGS:ERRORS:CURRENT_PASSWORD_NOT_CORRECT');
    }

    if (formErrors.find(e => e.field === 'newPassword' && e.type === 'passwordsDoNotMatch')) {
      passwordValidationErrorText = t('SETTINGS:ERRORS:NEW_PASSWORD_DOES_NOT_MATCH');
    }

    // TODO: improve ChangePassword to slot errors in under any field, not just the last one

    return (
      <LoadingShadeWrapper
        isLoading={settingsStore.changePasswordState.isPending}
        style={containerStyles.fillAvailableSpace}>
        <ScreenError state={settingsStore.changePasswordState} ignoreFormErrors />
        <ChangePassword
          buttonTextColor={branding.highlightedColor}
          textInputStyle={textStyles.standard.dark}
          onPressChangePassword={this._onPressChangePassword}
          validationErrorMessage={passwordValidationErrorText}
          listStyle={{ backgroundColor: colors.bg1 }}
          RowSeparatorComponent={SeparatorLine}
        />
      </LoadingShadeWrapper>
    );
  }
}

ChangePasswordScreen.propTypes = {
  rootStore: PropTypes.object,
  navigation: PropTypes.object,
};

const ChangePasswordModal = buildModalWithNavHeader(ChangePasswordScreen, 'Change Password');

export default withKeyboardAvoidance(withTranslation()(ChangePasswordModal));
