import React from 'react';
import { View } from 'react-native';
import { observer } from 'mobx-react';
import { colors } from '/common/config/styles';
import cardConstants from '../cardConstants';

const CardDividerComponent = observer(function() {
  return (
    <View
      style={{
        marginBottom: cardConstants.elementSpacing,
        height: cardConstants.elementSpacing,
        justifyContent: 'center',
      }}>
      <View
        style={{
          borderTopWidth: 1,
          borderColor: colors.border,
        }}
      />
    </View>
  );
});

export default CardDividerComponent;
