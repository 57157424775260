import React, { useMemo, Component } from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { dateForBeginningOfDay } from 'nudge-client-common/lib/dates';
import { ChatMessage, Chat } from '../chat';
import Topic from './components/threads/Topic';
import DaySeparator from './components/DaySeparator';
import { fullName } from '../../config/strings';
import InFeedLeaderboard from './components/leaderboard/InFeedLeaderboard';

@observer
class TopicBubble extends Component {
  _onPressAttachment = () => {
    this.props.onPressAttachment(this.props.currentMessage);
  };

  render() {
    const props = this.props;

    const {
      onPressComment,
      onLongPressComment,
      onPressLink,
      leaderboard,
      onPressLeaderboard,
      isLeaderboardJoined,
      perspective,
      onPressImage,
    } = this.props;

    if (props.currentMessage.originatingMessage.isLeaderboard) {
      return (
        <InFeedLeaderboard
          leaderboard={props.currentMessage.originatingMessage.leaderboard}
          onPress={onPressLeaderboard}
          isLeaderboardJoined={isLeaderboardJoined}
          perspective={perspective}
        />
      );
    }

    return (
      <View
        style={{ opacity: props.currentMessage.originatingMessage.isPending ? 0.4 : 1, flex: 1 }}>
        <Topic
          topic={props.currentMessage.originatingMessage}
          currentMessage={props.currentMessage}
          onPressAttachment={this._onPressAttachment}
          onPressImage={onPressImage}
          onPress={onPressComment}
          onPressLink={onPressLink}
          onLongPress={onLongPressComment}
        />
      </View>
    );
  }
}

const SocialFeed = observer(
  ({
    leaderboard,
    topics,
    groupTitle,
    onLoadEarlier,
    isLoadEarlierEnabled,
    isLoadingEarlier,
    currentMessage,
    onPostMessage,
    onCurrentMessageTextChanged,
    isOperationPending,
    onPressChooseImage,
    onPressRemoveImage,
    onPressAttachment,
    showChooseImageButton,
    imageModalProps,
    ...rest
  }) => {
    const { t } = useTranslation();

    const finalMessages = useMemo(() => {
      // split things up so leaderboard shows up at the beginning of the current day
      // put together today's messages
      let threadMessages = topics
        .filter(m =>
          DateTime.fromJSDate(m.createdAt).hasSame(DateTime.fromJSDate(new Date()), 'day')
        )
        .map(
          m =>
            new ChatMessage({
              messageId: m.id.toString(),
              createdAt: m.createdAt,
              text: m.comment,
              userId: m.author.id,
              userName: fullName(m),
              userPhotoUrl: m.userPhotoUrl,
              attachmentSource: m.attachment,
              originatingMessage: m,
              isPending: m.isPending,
            })
        )
        .reverse();
      // then leaderboard
      if (leaderboard) {
        threadMessages.push(
          new ChatMessage({
            messageId: 'leaderboard',
            createdAt: dateForBeginningOfDay(new Date()),
            text: '',
            userId: '',
            userName: '',
            userPhotoUrl: '',
            originatingMessage: { isLeaderboard: true, leaderboard },
          })
        );
      }
      // then older messages
      threadMessages = threadMessages.concat(
        topics
          .filter(
            m => !DateTime.fromJSDate(m.createdAt).hasSame(DateTime.fromJSDate(new Date()), 'day')
          )
          .map(
            m =>
              new ChatMessage({
                messageId: m.id,
                createdAt: m.createdAt,
                text: m.comment,
                userId: m.author.id,
                userName: fullName(m),
                userPhotoUrl: m.userPhotoUrl,
                originatingMessage: m,
                attachmentSource: m.attachment,
                isPending: m.isPending,
              })
          )
          .reverse()
      );
      return threadMessages;
    }, [topics, leaderboard]);

    return (
      <Chat
        {...rest}
        onPressAttachment={onPressAttachment}
        placeholderText={t('COMMUNITY:GROUP_FEED:MESSAGE_GROUP', { groupName: groupTitle })}
        onCurrentMessageTextChanged={onCurrentMessageTextChanged}
        currentMessage={currentMessage}
        messages={finalMessages}
        onPressChooseImage={onPressChooseImage}
        onPressRemoveImage={onPressRemoveImage}
        currentUserId="na"
        onLoadEarlier={onLoadEarlier}
        isLoadEarlierEnabled={isLoadEarlierEnabled}
        isLoadingEarlier={isLoadingEarlier}
        onPressSend={onPostMessage}
        isOperationPending={isOperationPending}
        showEmptyListPlaceholder={!topics.length}
        hoverMenuDirection="right"
        MessageBubbleComponent={TopicBubble}
        DayComponent={DaySeparator}
        showChooseImageButton={showChooseImageButton}
        imageModalProps={imageModalProps}
      />
    );
  }
);

SocialFeed.propTypes = {
  onPressComment: PropTypes.func.isRequired,
  topics: PropTypes.arrayOf(PropTypes.object).isRequired,
  // additional padding when you scroll to the bottom of the list,
  // to keep the last cell a little higher
  groupTitle: PropTypes.string,
  bottomOffset: PropTypes.number,
  onLoadEarlier: PropTypes.func.isRequired,
  isLoadEarlierEnabled: PropTypes.bool.isRequired,
  isLoadingEarlier: PropTypes.bool.isRequired,
  currentMessage: PropTypes.object.isRequired,
  onPostMessage: PropTypes.func.isRequired,
  onCurrentMessageTextChanged: PropTypes.func.isRequired,
  isOperationPending: PropTypes.bool,
  onPressLeaderboard: PropTypes.func.isRequired,
  isLeaderboardJoined: PropTypes.bool.isRequired,
  leaderboard: PropTypes.object,
  onPressLink: PropTypes.func.isRequired,
  onLongPressComment: PropTypes.func.isRequired,
  perspective: PropTypes.string.isRequired,
  showChooseImageButton: PropTypes.bool,
};

SocialFeed.defaultProps = {
  bottomOffset: 0,
  leaderboard: null,
  groupTitle: '',
  isOperationPending: false,
  showChooseImageButton: true,
};

export default SocialFeed;
