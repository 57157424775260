/* eslint no-use-before-define: ["error", { "variables": false }] */

import PropTypes from 'prop-types';
import React from 'react';
import { TouchableWithoutFeedback, View, Platform } from 'react-native';
import { observer } from 'mobx-react/native';
import MessageText from './MessageText';
import MessageFileAttachment from './MessageFileAttachment';
import MessageImage from './MessageImage';
import MessageMeetingLink from './MessageMeetingLink';
import MessageAttachmentSwitcher from './MessageAttachmentSwitcher';
import { sizes, textStyles, colors } from '../../config/styles';
import { BrandContext } from '../../config/branding';
import WebMessageContextMenuButton from './WebMessageContextMenuButton';

// ensures consistent height and rounding
const baseBubbleStyle = { borderRadius: sizes.borderRadius, minHeight: 20 };
const baseLeftBubbleStyle = {
  marginRight: 80,
  justifyContent: 'flex-end',
  marginLeft: sizes.small,
};
const baseRightBubbleStyle = {
  marginLeft: 80,
  justifyContent: 'flex-start',
  marginRight: sizes.small,
};

@observer
export default class MessageBubble extends React.Component {
  static contextType = BrandContext;

  onLongPress = () => {
    if (!this.props.currentMessage.isPending) {
      this.props.onLongPressMessage(this.props.currentMessage);
    }
  };

  renderMessageText() {
    const { isMe, onPressUrl, currentMessage } = this.props;

    const position = isMe ? 'right' : 'left';

    return (
      <MessageText
        textStyle={[
          {
            lineHeight: 20,
            marginTop: sizes.small,
            marginBottom: sizes.small,
            marginLeft: sizes.small,
            marginRight: sizes.small,
          },
          position === 'left'
            ? textStyles.standard.dark
            : [textStyles.standard.light, { color: colors.bg0 }],
        ]}
        onPressUrl={onPressUrl}
        currentMessage={currentMessage}
        linkTextStyle={{ textDecorationLine: 'underline' }}
      />
    );
  }

  render() {
    const { pendingMessageStyle, stackMessageStyle, isMe, currentMessage } = this.props;

    const branding = this.context;

    const leftBubbleStyle = { backgroundColor: colors.bg1, marginLeft: sizes.small };
    const rightBubbleStyle = {
      backgroundColor: branding.highlightedColor,
      marginRight: sizes.small,
    };

    const position = isMe ? 'right' : 'left';

    const buttonInner = (
      <View
        style={{
          flex: 1,
          alignItems: position === 'left' ? 'flex-start' : 'flex-end',
          marginVertical: 2,
          opacity: currentMessage.isPending ? 0.4 : 1,
        }}>
        <MessageAttachmentSwitcher
          {...this.props}
          highlightedColor={branding.highlightedColor}
          containerStyle={position === 'left' ? baseLeftBubbleStyle : baseRightBubbleStyle}
        />
        {/* add extra padding to separate group messages from previous message */ currentMessage.showAsStack ? (
          <View style={{ height: 5 }} />
        ) : null}
        {currentMessage.hasText ? (
          <View>
            {currentMessage.showAsStack ? (
              <View style={{ flex: 1, alignItems: 'flex-end', position: 'absolute' }}>
                <View
                  style={[
                    baseBubbleStyle,
                    {
                      minHeight: 20,
                      justifyContent: 'flex-end',
                    },
                    position === 'left' ? baseLeftBubbleStyle : baseRightBubbleStyle,
                    position === 'left' ? leftBubbleStyle : rightBubbleStyle,
                  ]}>
                  {this.renderMessageText()}
                </View>
              </View>
            ) : null}
            <View
              style={[
                baseBubbleStyle,
                position === 'left' ? baseLeftBubbleStyle : baseRightBubbleStyle,
                currentMessage.showAsStack && {
                  borderWidth: Platform.OS === 'ios' ? 0 : 1,
                  borderColor: '#4A70D3',
                  left: -5,
                  top: -5,
                  shadowColor: 'black',
                  shadowRadius: 1,
                  shadowOffset: { width: 0.75, height: 0.75 },
                  shadowOpacity: 0.45,
                  // I can't remember what the two shadow styles are for
                },
                currentMessage.showAsStack && stackMessageStyle,
                position === 'left' ? leftBubbleStyle : rightBubbleStyle,
                currentMessage.isPending && pendingMessageStyle,
              ]}>
              <TouchableWithoutFeedback
                onLongPress={Platform.OS === 'web' ? undefined : this.onLongPress}
                accessibilityTraits="text">
                <View>{this.renderMessageText()}</View>
              </TouchableWithoutFeedback>
            </View>
          </View>
        ) : null}
      </View>
    );

    if (Platform.OS === 'web' && isMe) {
      return (
        <WebMessageContextMenuButton position={position} onPress={this.onLongPress}>
          {buttonInner}
        </WebMessageContextMenuButton>
      );
    }

    return buttonInner;
  }
}

MessageBubble.propTypes = {
  // additional styles for the faux-shadow that appears under bubbles when showAsStack is true for the message
  stackMessageStyle: PropTypes.any,
  pendingMessageStyle: PropTypes.any,
  leftBubbleStyle: PropTypes.any,
  rightBubbleStyle: PropTypes.any,
  leftBubbleTextStyle: PropTypes.any,
  rightBubbleTextStyle: PropTypes.any,
  leftBubbleLinkTextStyle: PropTypes.any,
  rightBubbleLinkTextStyle: PropTypes.any,
  onLongPress: PropTypes.func,
  onPressUrl: PropTypes.func,
  position: PropTypes.oneOf(['left', 'right']),
  currentMessage: PropTypes.object,
  //nextMessage: PropTypes.object,
  //previousMessage: PropTypes.object,
  // components for different message types
  ImageComponent: PropTypes.any,
  FileAttachmentComponent: PropTypes.any,
  MeetingLinkComponent: PropTypes.any,
};

MessageBubble.defaultProps = {
  onLongPress: null,
  onPressUrl: () => {},
  position: 'left',
  currentMessage: {
    text: null,
    createdAt: null,
    image: null,
  },
  ImageComponent: MessageImage,
  FileAttachmentComponent: MessageFileAttachment,
  MeetingLinkComponent: MessageMeetingLink,
  leftBubbleStyle: { backgroundColor: 'pink' },
  rightBubbleStyle: { backgroundColor: 'lightGray' },
  leftBubbleTextStyle: null,
  rightBubbleTextStyle: null,
};
