import React from 'react';
import { Text } from 'react-native';
import { Duration } from 'luxon';
import AnimateNumber from 'react-native-animate-number';
import { observer } from 'mobx-react';
import numeral from 'numeral';
import { textStyles } from '../../../../config/styles';

const secondsToHoursAndMinutes = seconds => {
  const timeString = Duration.fromMillis(seconds * 1000).toFormat('h:m');
  const timeStringParts = timeString.split(':');
  return {
    hours: timeStringParts[0],
    minutes: timeStringParts[1],
  };
};

/**
 * Separates hours and minutes into separate fields with h and m between then.
 */
const DurationValue = observer(({ value, valueStyle, unitsStyle }) => {
  if (!value) {
    return (
      <Text style={{ opacity: 0.4 }}>
        <Text style={[value ? textStyles.bold.dark : textStyles.bold.dark, valueStyle]}>0</Text>
        <Text style={[textStyles.small.dark, unitsStyle]}>{` m`}</Text>
      </Text>
    );
  }

  const timeParts = secondsToHoursAndMinutes(value);

  // do the smaller count by to make the hour not switch right away
  // also use a faster interval because sometimes the numbers go down, doesn't look that great slow
  const hoursPart =
    timeParts.hours > 0 ? (
      <Text>
        <Text style={[textStyles.bold.dark, valueStyle]}>
          <AnimateNumber
            initialValue={timeParts.hours}
            value={timeParts.hours}
            formatter={value => numeral(value).format('0')}
            timing="easeOut"
            interval={5}
            countBy={0.2}
          />
        </Text>
        <Text style={[textStyles.small.dark, unitsStyle]}>{` h`}</Text>
      </Text>
    ) : null;
  const minutesPart =
    timeParts.minutes > 0 ? (
      <Text>
        <Text style={[textStyles.bold.dark, valueStyle]}>
          <AnimateNumber
            initialValue={timeParts.minutes}
            value={timeParts.minutes}
            timing="easeOut"
            interval={5}
            countBy={1}
          />
        </Text>
        <Text style={[textStyles.small.dark, unitsStyle]}>{` m`}</Text>
      </Text>
    ) : null;

  return (
    <Text>
      {hoursPart}
      {hoursPart && minutesPart ? <Text>{` `}</Text> : null}
      {minutesPart}
    </Text>
  );
});

export default DurationValue;