import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react/native';
import { View, Text } from 'react-native';
import { withTranslation } from 'react-i18next';
import { colors } from '../config/styles';
import RoundButton from './RoundButton';

const ListErrorView = observer(({ style, onPressRetry, failureMessage, t }) => (
  <View
    style={[
      {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        paddingLeft: 40,
        paddingRight: 40,
      },
      style,
    ]}>
    <Text
      style={{
        color: colors.lightText,
        textAlign: 'center',
        paddingBottom: 30,
      }}>
      {failureMessage}
    </Text>
    {onPressRetry && <RoundButton title={t('GENERAL:REFRESH')} onPress={onPressRetry} />}
  </View>
));

ListErrorView.propTypes = {
  failureMessage: PropTypes.string,
  onPressRetry: PropTypes.func,
  style: PropTypes.any,
  t: PropTypes.any.isRequired,
};

ListErrorView.defaultProps = {
  failureMessage: 'Loading failed',
  onPressRetry: null,
  style: null,
};

export default withTranslation()(ListErrorView);
