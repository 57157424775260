import React, { Component } from 'react';
import { View, Text, Dimensions } from 'react-native';
import { FlexibleThumbnail } from '/common/components';
import { withTranslation } from 'react-i18next';
import { sizes, textStyles, colors } from '../../config/common-styles';
import { BrandContext } from '../../config/branding';

const screenWidth = Dimensions.get('window').width;

class PushNotificationInfo extends Component {
  static contextType = BrandContext;

  render() {
    const branding = this.context;

    return (
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <View style={{ width: '100%' }}>
          <FlexibleThumbnail
            maxWidth={screenWidth}
            source={require('../../assets/notifications.png')}
            isStaticImage
          />
          <View
            style={{
              width: '100%',
              borderBottomColor: colors.border0,
              borderBottomWidth: 2,
              marginBottom: sizes.medium,
            }}
          />
          <Text
            style={[
              textStyles.centered,
              textStyles.bold.dark,
              { marginBottom: sizes.small, marginHorizontal: sizes.medium },
            ]}>
            Don't miss a message!
          </Text>
          <Text
            style={[
              textStyles.centered,
              textStyles.standard.light,
              { marginBottom: sizes.medium, marginHorizontal: sizes.large },
            ]}>
            {`${branding.appName} uses push notifications to notify you when you receive new messages.`}
          </Text>
        </View>
      </View>
    );
  }
}

export default withTranslation()(PushNotificationInfo);
