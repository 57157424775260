import React from 'react';
import { observer, inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { LoadingShadeWrapper } from '../../../components/common';
import { NavbarWrapper, HeaderIconButton } from '../../../components/navigation';
import { containerStyles } from '../../../config/common-styles';
import { ScreenError, withKeyboardAvoidance } from '../../common';
import { BackIcon, CancelIcon, ConfirmUpdateIcon } from '/common/config/icons';
import { colors } from '/common/config/styles';

const UpdateSettingsScreenWrapper = inject('rootStore')(
  observer(
    ({
      form,
      navigation,
      rootStore,
      isDirty,
      navbarTitle,
      onPressCancel,
      onPressBack,
      onPressSave,
      updateFailedMessage,
    }) => {
      const { settingsStore } = rootStore;
      const { t } = useTranslation();
      return (
        <LoadingShadeWrapper
          isLoading={settingsStore.updateUserState.isPending}
          style={containerStyles.fillAvailableSpace}>
          <NavbarWrapper
            title={navbarTitle}
            leftButton={
              isDirty ? (
                <HeaderIconButton
                  disabled={settingsStore.updateUserState.isPending}
                  icon={<CancelIcon color={colors.error} />}
                  onPress={onPressCancel}
                />
              ) : (
                <HeaderIconButton
                  disabled={settingsStore.updateUserState.isPending}
                  icon={<BackIcon />}
                  onPress={onPressBack}
                />
              )
            }
            rightButton={
              isDirty ? (
                <HeaderIconButton
                  disabled={settingsStore.updateUserState.isPending}
                  icon={<ConfirmUpdateIcon />}
                  onPress={onPressSave}
                />
              ) : null
            }
            navigation={navigation}>
            <ScreenError
              state={settingsStore.updateUserState}
              title={t('PROFILE:ERRORS:UPDATE_FAILED')}
              message={updateFailedMessage}
              type="messageBar"
              ignoreFormErrors
            />
            {form}
          </NavbarWrapper>
        </LoadingShadeWrapper>
      );
    }
  )
);

export default withKeyboardAvoidance(UpdateSettingsScreenWrapper);
