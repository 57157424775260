import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react/native';
import { View, Text } from 'react-native';
import { withTranslation } from 'react-i18next';
import { textStyles, sizes } from '../config/styles';
import RoundButton from './RoundButton';

/**
 * A friendly error to display in the middle of a screen when loading fails.
 * Eventually we'll probably include an image here.
 */
const LoadingFailedPlaceholder = observer(
  ({ onPressRefresh, title, message, actionMessage, showImage, t, containerStyle }) => (
    <View
      style={[
        {
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          paddingLeft: sizes.large,
          paddingRight: sizes.large,
        },
        containerStyle,
      ]}>
      <Text style={[textStyles.bold.light, { textAlign: 'center', paddingBottom: sizes.large }]}>
        {title}
      </Text>
      <Text style={[textStyles.standard.light, { textAlign: 'center' }]}>{message}</Text>
      {actionMessage && actionMessage.length ? (
        <Text style={[textStyles.standard.light, { textAlign: 'center' }]}>{actionMessage}</Text>
      ) : null}
      {onPressRefresh && (
        <View style={{ marginTop: sizes.large }}>
          <RoundButton title={t('GENERAL:REFRESH')} onPress={onPressRefresh} />
        </View>
      )}
    </View>
  )
);

LoadingFailedPlaceholder.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  actionMessage: PropTypes.string,
  onPressRetry: PropTypes.func,
  style: PropTypes.any,
  showImage: PropTypes.bool,
  containerStyle: PropTypes.any,
  t: PropTypes.any.isRequired,
};

LoadingFailedPlaceholder.defaultProps = {
  title: '',
  message: '',
  actionMessage: '',
  onPressRetry: null,
  style: null,
  showImage: true,
  containerStyle: null,
};

export default withTranslation()(LoadingFailedPlaceholder);
