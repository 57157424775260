import { types, flow, getEnv, getRoot } from 'mobx-state-tree';
import { sortBy, first } from 'lodash';
import { LoadingState } from 'nudge-client-common/stores';
import Club from './Club';
import SocialFeed from './SocialFeed';

/**
 * Store of all loaded clients and everything within those views
 * (profile, conversation, scheduled messages)
 */
const SocialStore = types
  .model('SocialStore', {
    clubs: types.optional(types.array(Club), []),
    socialFeeds: types.optional(types.array(SocialFeed), []),
    loadClubsState: types.optional(LoadingState, {}),
    latestSocialFeedUpdate: types.frozen(),
    // has club_id, parent_id (in case of reply), thread_id
    latestNotification: types.frozen(),
    feedSortMode: types.optional(types.string, 'created_at'), // or latest_reply_at
  })
  .views(self => ({
    socialFeedForClubId(clubId) {
      return self.socialFeeds.find(c => c.clubId === clubId);
    },
    get clubsSorted() {
      return sortBy(self.clubs.slice(), c => c.title);
    },
    get clubForLatestNotification() {
      if (self.latestNotification) {
        return self.clubs.find(g => g.id === self.latestNotification.club_id);
      }
      return null;
    },
  }))
  .actions(self => {
    const afterCreate = () => {
      self.loadFeedSortMode();
    };

    const loadFeedSortMode = flow(function* loadFeedSortMode() {
      const cachingRepository = getEnv(self).cachingRepository;

      const item = yield cachingRepository.getItem('socialFeedSortMode');
      if (item) {
        self.feedSortMode = item;
      }
    });

    const updateFeedSortMode = flow(function* updateFeedSortMode(newValue) {
      const cachingRepository = getEnv(self).cachingRepository;

      self.feedSortMode = newValue;

      yield cachingRepository.setItem('socialFeedSortMode', self.feedSortMode);
    });

    /**
     * Load both all communities and the main feed dashboard.
     */
    const loadClubs = flow(function* loadClubs(options) {
      self.loadClubsState.setPending();
      try {
        let alreadyLoadedOnlyClub = false;
        // optimistically load only coach conversation if already loaded for speed and so conversation screen picks up any errors
        if (self.clubs.length === 1) {
          const socialFeed = self.socialFeedForClub(self.clubs[0].id);

          if (options && options.loadOnlyClub) {
            yield socialFeed.loadComments();
          }
        }
        // load groups
        const clubsFromQuery = yield getEnv(self).clubsRepository.getClubs();
        self.clubs.replace(clubsFromQuery);
        // load the only topic feed if there is only group
        if (self.clubs.length === 1 && !alreadyLoadedOnlyClub) {
          const socialFeed = self.socialFeedForClub(self.clubs[0].id);

          if (options && options.loadOnlyClub) {
            yield socialFeed.loadComments();
          }
        }

        // load notification levels

        self.clubs.forEach(club => {
          // not expensive because it will not double-subscribe
          getEnv(self).pusherListener.startListeningForClub(club.id);
        });

        // update leaderboard status for each topic feed we've already created so they'll load any new leaderboards the next time
        // and set the correct group title
        self.socialFeeds.forEach(socialFeed => {
          const matchingGroup = self.clubs.find(group => group.id === socialFeed.clubId);

          if (matchingGroup) {
            socialFeed.hasLeaderboard = matchingGroup.hasLeaderboard;
            socialFeed.hasArchivedLeaderboard = matchingGroup.hasArchivedLeaderboard;
            socialFeed.title = matchingGroup.title;
            socialFeed.isLeaderboardJoined = matchingGroup.isLeaderboardJoined;
            socialFeed.forumsUploadsEnabled = matchingGroup.forumsUploadsEnabled;
            socialFeed.sortMode = self.feedSortMode;
          }
        });

        self.loadClubsState.setDone();
      } catch (error) {
        self.loadClubsState.setFailed(error);
      }
    });

    /**
     * Get social feed for club id, initializating if it's not already there
     */
    const socialFeedForClub = clubId => {
      let existingSocialFeed = self.socialFeeds.find(f => f.clubId === clubId);
      // create new topic feed if it doesn't exist
      // topic feeds include enough props from groups to be able to display
      if (!existingSocialFeed) {
        const club = self.clubs.find(c => c.id === clubId);
        if (club) {
          self.socialFeeds.push({
            clubId,
            title: club.title,
            hasLeaderboard: club.hasLeaderboard,
            hasArchivedLeaderboard: club.hasArchivedLeaderboard,
            isLeaderboardJoined: club.isLeaderboardJoined,
            forumsUploadsEnabled: club.forumsUploadsEnabled,
            sortMode: self.feedSortMode,
          });
        } else {
          // possible the club isn't loaded yet, do a placeholder
          self.socialFeeds.push({
            clubId,
            title: '',
            hasLeaderboard: false,
            isLeaderboardJoined: false,
            forumsUploadsEnabled: false,
            sortMode: self.feedSortMode,
          });
        }

        existingSocialFeed = self.socialFeedForClubId(clubId);
      }
      return existingSocialFeed;
    };

    const setLatestSocialFeedUpdate = update => {
      self.latestSocialFeedUpdate = update;
    };

    const requestTestNotification = flow(function* requestTestNotification(notificationType) {
      yield self.loadClubs(); // hopefully you have groups
      if (notificationType === 'inApp') {
        self.latestNotification = {
          club_id: first(self.clubsSorted).id,
          messageId: new Date().getTime(), //doesn't matter what this is
          sender_name: 'Coach Name Here',
        };
      }
      if (notificationType === 'selected') {
        yield self.loadClubs(); // hopefully you have clubs
        getRoot(self).appLinkProcessor.setAppLink({
          source: 'notification',
          data: {
            club_id: first(self.clubsSorted).id,
            thread_id: new Date().getTime() /*doesn't matter what this is*/,
          },
        });
        getRoot(self).appLinkProcessor.processCurrentLink();
      }
      if (notificationType === 'selectInvalid') {
        yield self.loadClubs(); // hopefully you have groups
        getRoot(self).appLinkProcessor.setAppLink({
          source: 'notification',
          data: {
            club_id: 101010101, //first(self.groupsSorted).id,
            thread_id: new Date().getTime() /*doesn't matter what this is*/,
          },
        });
        getRoot(self).appLinkProcessor.processCurrentLink();
      }
      if (notificationType === 'inAppThread') {
        const socialFeed = self.socialFeedForClub(first(self.clubsSorted).id);
        // hopefully you have at least one post in the club
        yield socialFeed.loadInitial();
        self.latestNotification = {
          club_id: first(self.clubsSorted).id,
          messageId: new Date().getTime(), //doesn't matter what this is
          parent_id: first(socialFeed.commentsSorted).id,
          sender_name: 'Coach Name Here',
        };
      }
      if (notificationType === 'selectedThread') {
        yield self.loadClubs(); // hopefully you have clubs
        const socialFeed = self.socialFeedForClub(first(self.clubsSorted).id);
        // hopefully you have at least one post in the group
        yield socialFeed.loadInitial();
        getRoot(self).appLinkProcessor.setAppLink({
          source: 'notification',
          data: {
            club_id: first(self.clubsSorted).id,
            thread_id: new Date().getTime() /*doesn't matter what this is*/,
            parent_id: first(socialFeed.commentsSorted).id,
          },
        });
        getRoot(self).appLinkProcessor.processCurrentLink();
      }
    });

    const setLatestNotification = notification => {
      self.latestNotification = notification;
    };

    return {
      afterCreate,
      loadFeedSortMode,
      updateFeedSortMode,
      loadClubs,
      socialFeedForClub,
      setLatestSocialFeedUpdate,
      setLatestNotification,
      requestTestNotification,
    };
  });

export default SocialStore;
