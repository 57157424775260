import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { View, Text } from 'react-native';
import { usePersonalization } from '/common/config/personalization';
import { colors, sizes, textStyles, fonts } from '/common/config/styles';
import { capitalizeFirstLetter, time } from '/common/config/strings';
import { RightChevronIcon } from '/common/config/icons';
import { TrackerValue } from '/common/components/program';
import { NudgeTouchable as Touchable } from '/common/components/';

const emptyFunc = () => {};

const getSyncedLogDescription = log => {
  // it's clear from parent child relationship that this is synced from the parent source

  const isChildLog = log.isChild;

  if (isChildLog) {
    return log.activityName;
  }

  let abbreviatedSource = log.displaySource;
  if (log.displaySource.startsWith('Apple')) {
    abbreviatedSource = 'Apple';
  }

  // some sources have lowercase first letter
  let logDescription = 'via ' + capitalizeFirstLetter(abbreviatedSource);
  if (log.isUntimedActivity) {
    return logDescription;
  }
  if (log.activityName) {
    logDescription = log.activityName + ' ' + logDescription;
  }
  return logDescription;
};

/**
 * The top line of the typical tracker cell. Typically shows the icon, name, and value
 * when it is not focused, then just the icon and name when focused.
 */
const LogItem = observer(
  ({
    tracker,
    log,
    onPress,
    //isSelected,
    containerStyle,
  }) => {
    const personalization = usePersonalization();
    const branding = personalization.branding;
    const highlightColor =
      tracker.palettesId && tracker.clientMeta.type !== 'custom-question-freeform'
        ? branding.trackerColors[tracker.palettesId]
        : colors.darkText;

    const onSelectLogCallback = useCallback(() => {
      onPress(log.id);
    }, [log]);

    let timeDisplay;

    if (log.isTimedActivity) {
      if (tracker.clientMeta.showTimeRangeWithEntries) {
        timeDisplay = (
          <Text
            style={[
              textStyles.small.light,
              {
                color: colors.lightText,
                marginRight: sizes.small,
              },
            ]}>
            {`${time(log.startTime).toUpperCase()} - ${time(log.endTime).toUpperCase()}`}
          </Text>
        );
      } else {
        timeDisplay = (
          <Text
            style={[
              textStyles.small.light,
              {
                color: colors.lightText,
                marginRight: sizes.small,
              },
            ]}>
            {time(log.jsUserTime).toUpperCase()}
          </Text>
        );
      }
    }

    return (
      <Touchable isTouchableOpacity useHoverStyle onPress={onSelectLogCallback}>
        <View
          style={[
            {
              paddingHorizontal: sizes.medium,
              paddingVertical: sizes.small * 1.5,
              paddingLeft: sizes.medium + (log.isChild ? sizes.medium * 3 : 0),
            },
            containerStyle,
          ]}>
          <View
            style={[
              {
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              },
            ]}>
            <View
              style={{
                flex: 1,
              }}>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flex: 1,
                  }}>
                  <TrackerValue
                    log={
                      log.primaryValue /* this should work with just log, but time doesn't work right yet */
                    }
                    tracker={tracker}
                    valueTextStyle={{
                      fontWeight: fonts.weights.medium,
                      fontFamily: fonts.families.medium,
                      color: highlightColor,
                    }}
                    unitsTextStyle={{
                      color: colors.lightText,
                      fontWeight: fonts.weights.standard,
                    }}
                    usePreferredTimeUnit
                  />
                  {log.isSynced && tracker.type !== 'exerbotics' ? (
                    <View style={{ justifyContent: 'flex-end', flex: 1 }}>
                      <Text
                        style={[textStyles.standard.light, { marginRight: sizes.small }]}
                        ellipsizeMode="tail"
                        numberOfLines={1}>
                        {getSyncedLogDescription(log)}
                      </Text>
                    </View>
                  ) : null}
                  {log.isCrossedOut && !log.isChild ? (
                    <View
                      style={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        justifyContent: 'center',
                      }}>
                      <View
                        style={{ height: 1, width: '100%', backgroundColor: colors.lightText }}
                      />
                    </View>
                  ) : null}
                </View>
                {tracker.type === 'exerbotics' && (
                  <View style={{ justifyContent: 'flex-end', flex: 1 }}>
                    <Text
                      style={[textStyles.standard.light, { marginRight: sizes.small }]}
                      ellipsizeMode="tail"
                      numberOfLines={1}>
                      {log.meta && log.meta && log.meta.protocol ? log.meta.protocol.name : ''}
                    </Text>
                  </View>
                )}
                <View
                  style={{
                    alignItems: 'center',
                    flexDirection: 'row',
                    //paddingTop: fonts.sizes.standard - fonts.sizes.small,
                  }}>
                  {timeDisplay}
                  <RightChevronIcon size={16} color={colors.lightText} />
                </View>
              </View>
            </View>
          </View>
        </View>
      </Touchable>
    );
  }
);

LogItem.propTypes = {
  tracker: PropTypes.object.isRequired,
};

LogItem.displayName = 'LogItem';

export default LogItem;
