import { StyleSheet, Platform } from 'react-native';
import colors from './colors';

const fonts = {
  sizes: {
    tiny: 10,
    small: 13,
    standard: 18,
    medium: 20,
    large: 26,
    xlarge: 32,
  },
  weights: {
    standard: Platform.OS === 'ios' ? '400' : Platform.OS === 'web' ? '400' : '100',
    medium: Platform.OS === 'ios' ? '600' : Platform.OS === 'web' ? '500' : '100',
    medium2: Platform.OS === 'ios' ? '700' : Platform.OS === 'web' ? '500' : '100',
    bold: Platform.OS === 'ios' ? '800' : '700',
  },
  families: {
    // adding families is a workaround to the OnePlus / Oppo font issue
    // https://github.com/facebook/react-native/issues/25481#issuecomment-771135016
    // may still be a problem on some Xiaomi phones https://github.com/facebook/react-native/issues/29259
    standard: Platform.OS === 'android' ? 'sans-serif' : undefined,
    medium: Platform.OS === 'android' ? 'sans-serif-medium' : undefined,
    bold: Platform.OS === 'android' ? 'sans-serif-medium' : undefined,
  },
};

const textStyles = {
  standard: StyleSheet.create({
    light: {
      fontSize: fonts.sizes.standard,
      color: colors.lightText,
      fontWeight: fonts.weights.standard,
      fontFamily: fonts.families.standard,
    },
    dark: {
      fontSize: fonts.sizes.standard,
      color: colors.darkText,
      fontWeight: fonts.weights.standard,
      fontFamily: fonts.families.standard,
    },
    white: {
      fontSize: fonts.sizes.standard,
      color: 'white',
      fontWeight: fonts.weights.standard,
      fontFamily: fonts.families.standard,
    },
    // added due to OnePlus font issue, so we avoid one-off fontWeight refs
    semibold: {
      fontSize: fonts.sizes.standard,
      color: colors.darkText,
      fontWeight: fonts.weights.medium,
      fontFamily: fonts.families.medium,
    },
    // added due to OnePlus font issue, so we avoid one-off fontWeight refs
    bold: {
      fontSize: fonts.sizes.standard,
      color: colors.darkText,
      fontWeight: fonts.weights.bold,
      fontFamily: fonts.families.bold,
    },
  }),
  medium: StyleSheet.create({
    white: {
      fontSize: fonts.sizes.medium,
      color: 'white',
      fontWeight: fonts.weights.standard,
      fontFamily: fonts.families.standard,
    },
    light: {
      fontSize: fonts.sizes.medium,
      color: colors.lightText,
      fontWeight: fonts.weights.standard,
      fontFamily: fonts.families.standard,
    },
    dark: {
      fontSize: fonts.sizes.medium,
      color: colors.darkText,
      fontWeight: fonts.weights.standard,
      fontFamily: fonts.families.standard,
    },
    bold: {
      fontSize: fonts.sizes.medium,
      color: colors.darkText,
      fontWeight: fonts.weights.bold,
      fontFamily: fonts.families.bold,
    },
  }),
  // added due to OnePlus font issue, so we avoid one-off fontWeight refs
  large: StyleSheet.create({
    bold: {
      fontSize: fonts.sizes.large,
      color: colors.darkText,
      fontWeight: fonts.weights.bold,
      fontFamily: fonts.families.bold,
    },
  }),
  // wondering if this should be refactored into size objects, so it matches the other bold fonts
  bold: StyleSheet.create({
    white: {
      fontSize: fonts.sizes.medium,
      color: 'white',
      fontWeight: fonts.weights.bold,
      fontFamily: fonts.families.bold,
    },
    light: {
      fontSize: fonts.sizes.medium,
      color: colors.lightText,
      fontWeight: fonts.weights.bold,
      fontFamily: fonts.families.bold,
    },
    dark: {
      fontSize: fonts.sizes.medium,
      color: colors.darkText,
      fontWeight: fonts.weights.bold,
      fontFamily: fonts.families.bold,
    },
  }),
  small: StyleSheet.create({
    light: {
      fontSize: fonts.sizes.small,
      color: colors.lightText,
      fontWeight: fonts.weights.standard,
      fontFamily: fonts.families.standard,
    },
    dark: {
      fontSize: fonts.sizes.small,
      color: colors.darkText,
      fontWeight: fonts.weights.standard,
      fontFamily: fonts.families.standard,
    },
    white: {
      fontSize: fonts.sizes.small,
      color: 'white',
      fontWeight: fonts.weights.standard,
      fontFamily: fonts.families.standard,
    },
    // added due to OnePlus font issue, so we avoid one-off fontWeight refs
    bold: {
      fontSize: fonts.sizes.small,
      color: colors.darkText,
      fontWeight: fonts.weights.bold,
      fontFamily: fonts.families.bold,
    },
  }),
  centered: {
    textAlign: 'center',
  },
  link: {
    textDecorationLine: 'underline',
  },
};

export { textStyles, fonts };
