import React, { useMemo, useCallback, useEffect, useRef } from 'react';
import { View, Animated, Platform } from 'react-native';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { containerStyles } from '../../config/common-styles';
import { ClientSummaryCard, ProgramListSectionHeader } from '/common/components/program';
import { EmptyListPlaceholder } from '/common/components';
import { colors, sizes, useMediaQueryInfo, useDeviceDimensions } from '/common/config/styles';
import { HeaderTitle } from '/common/components/navigation';
import { executeIfNotExecutedBeforeForKey } from '/common/lib';
import { isOldPhone } from '/common/config/feature-support';

const ProgramList = observer(
  ({
    onPressCard,
    trackers,
    onPressTracker,
    onPressLink,
    groupedProgramEvents,
    onPullToRefresh,
    isPullToRefreshInProgress,
    onLoadAdditionalItems,
    onCardVisibleForFirstTime,
    isOnScreen,
    theme,
    overrideHeader,
    LoadMoreComponent,
    extendsToFullScreenHeight,
  }) => {
    const { t } = useTranslation();

    const { innerWindowWidth } = useMediaQueryInfo();

    const { statusBarHeight } = useDeviceDimensions();

    // *** callbacks ***

    const renderItem = useCallback(
      ({ item }) => {
        return (
          <ClientSummaryCard
            trackers={trackers}
            programEvent={item}
            onPressTracker={onPressTracker}
            onPressLink={onPressLink}
            onPressCard={onPressCard}
          />
        );
      },
      [onPressCard, trackers, onPressTracker, onPressLink]
    );

    const keyExtractor = useCallback(item => item.id);

    const renderSectionHeader = useCallback(({ section: { title, index } }) => {
      return <ProgramListSectionHeader index={index} title={title} />;
    }, []);

    // *** end callbacks ***

    // *** memoized grouped items ***

    const data = useMemo(
      () =>
        groupedProgramEvents.map((group, index) => {
          return {
            title: group.headerInfo.date,
            data: group.items,
            index,
          };
        }),
      [groupedProgramEvents]
    );

    /** visibility stuff */

    const sectionListRef = useRef();

    const onViewableItemsChanged = useCallback(({ viewableItems }) => {
      viewableItems.forEach(item => {
        if (item.key) {
          executeIfNotExecutedBeforeForKey({
            executeFn: () => onCardVisibleForFirstTime(item.item),
            uniquenessKey: item.key,
          });
        }
      });
    }, []);

    useEffect(() => {
      // this ensures
      if (isOnScreen) {
        sectionListRef.current.recordInteraction();
      }
    }, [isOnScreen]);

    // NOTE: using default section list behavior of stickySectionHeadersEnabled for iOS, but not Android
    // This is because the android animation gets real weird once you scroll down the list
    // The other option is to keep sticky section headers for all but disable the animation on Android

    const fullHeightProps = extendsToFullScreenHeight
      ? {
          contentInset: { top: statusBarHeight },
          contentOffset: { y: -1 * statusBarHeight },
        }
      : null;

    return (
      <View
        style={[
          containerStyles.fillAvailableSpace,
          { backgroundColor: colors.bg1, paddingTop: 0 },
        ]}>
        <Animated.SectionList
          ref={sectionListRef}
          contentContainerStyle={{
            width: innerWindowWidth,
            alignSelf: 'center',
            flexGrow: 1,
            backgroundColor: colors.bg1,
          }}
          showsVerticalScrollIndicator={
            Platform.OS !==
            'web' /* sometimes in some situations on some browsers, these shift the content over. So weird! */
          }
          sections={data}
          stickySectionHeadersEnabled={false}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
          onEndReached={onLoadAdditionalItems}
          onEndReachedThreshold={0.98}
          refreshing={isPullToRefreshInProgress}
          onRefresh={onPullToRefresh}
          renderSectionHeader={renderSectionHeader}
          ItemSeparatorComponent={() => <View style={{ height: sizes.large }} />}
          ListEmptyComponent={
            <EmptyListPlaceholder
              title={t('PROGRAMS:EMPTY_FEED:TITLE')}
              message={t('PROGRAMS:EMPTY_FEED:MESSAGE')}
              image={require('../../assets/pending-card.png')}
            />
          }
          ListHeaderComponent={
            <>
              {extendsToFullScreenHeight && Platform.OS === 'android' ? (
                <View style={{ height: statusBarHeight }} />
              ) : null}
              {overrideHeader ? (
                overrideHeader
              ) : theme.logo ? (
                <View
                  style={{
                    backgroundColor: theme.useDarkTheme ? theme.backgroundColor : 'transparent',
                    height: sizes.navbarHeight,
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <HeaderTitle title="" imageSource={theme.logo} />
                </View>
              ) : null}
            </>
          }
          ListFooterComponent={LoadMoreComponent}
          onViewableItemsChanged={
            isOldPhone() /* seems risky on old android phones */ ? null : onViewableItemsChanged
          }
          {...fullHeightProps}
        />
      </View>
    );
  }
);

ProgramList.propTypes = {
  groupedProgramEvents: PropTypes.array.isRequired,
  TabBarComponent: PropTypes.any, // optional tab bar to show at the top
  LoadMoreComponent: PropTypes.any, // optional component to show when scrolling to the bottom and about to load more items
};

ProgramList.defaultProps = {
  TabBarComponent: null,
  LoadMoreComponent: null,
};

export default ProgramList;
