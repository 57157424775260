import React from 'react';
import PropTypes from 'prop-types';
import { View, SafeAreaView } from 'react-native';
import * as Device from 'expo-device';
import { StatusBar } from 'expo-status-bar';
import {
  containerStyles,
  sizes,
  colors,
  useMediaQueryInfo,
  useDeviceDimensions,
} from '../../config/styles';

// safety status bar to make sure the dark style always shows up, even if onboarding pops up right away

const SimpleNavWrapper = ({ leftButton, rightButton, containerStyle, children }) => {
  const { isWideScreen } = useMediaQueryInfo();
  const { statusBarAndNavbarHeight, statusBarHeight } = useDeviceDimensions();

  // Use Device.modelName because users can change their Device.deviceName
  // Keep Device.deviceName for running in the simulator
  const isiPhone14 =
    (Device.modelName && Device.modelName.includes('iPhone 14 Pro')) ||
    (Device.deviceName && Device.deviceName.includes('iPhone 14 Pro'));
  return (
    <View
      style={[
        containerStyles.fillAvailableSpace,

        { backgroundColor: isWideScreen ? colors.bg1 : colors.bg0 },
        containerStyle,
      ]}>
      <StatusBar hidden={false} translucent style="dark" />
      <View
        style={[
          containerStyles.fillAvailableSpace,
          {
            marginTop: isiPhone14 ? statusBarAndNavbarHeight + 30 : statusBarAndNavbarHeight,
          },
        ]}>
        {children}
      </View>
      <SafeAreaView
        style={[
          containerStyles.overlayAndStretchAcrossTop,
          {
            top: isiPhone14 ? statusBarHeight + 30 : statusBarHeight,
            flexDirection: 'row',
            justifyContent: 'space-between',
            height: sizes.navbarHeight,
            alignItems: 'center',
          },
        ]}>
        <View>{leftButton}</View>
        <View>{rightButton}</View>
      </SafeAreaView>
    </View>
  );
};

SimpleNavWrapper.propTypes = {
  children: PropTypes.any,
  leftButton: PropTypes.any,
  rightButton: PropTypes.any,
};

SimpleNavWrapper.defaultProps = {
  children: null,
  leftButton: null,
  rightButton: null,
};

export default SimpleNavWrapper;
