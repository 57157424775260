import { TextInput, Platform } from 'react-native';
import * as Localization from 'expo-localization';
import numeral from 'numeral';
import { RowCenteredButton, CircleThumbnail, RowTextInput, PickerCell } from '/common/components';
import { colors, sizes, textStyles } from '../../config/common-styles';

// Store presets here for common controls
export default branding => {
  // common controls that should probably just be converted to use these styles directly since they're in the common folder
  RowCenteredButton.defaultProps.actionColor = branding.highlightedColor;
  RowCenteredButton.defaultProps.horizontalPadding = sizes.medium;
  RowCenteredButton.defaultProps.textStyle = textStyles.standard.dark;
  RowCenteredButton.defaultProps.destructiveColor = colors.error;

  RowTextInput.defaultProps.labelStyle = [textStyles.standard.light, { marginRight: sizes.medium }];
  RowTextInput.defaultProps.textInputStyle = textStyles.standard.dark;
  RowTextInput.defaultProps.containerStyle = {
    paddingHorizontal: sizes.medium,
  };
  RowTextInput.defaultProps.validationErrorTextStyle = [
    textStyles.small.dark,
    { color: colors.error, paddingBottom: sizes.small },
  ];

  PickerCell.defaultProps = {
    additionalSection: null,
    selected: null,
    containerStyle: null,
    selectedIconColor: branding.highlightedColor,
    unselectedIconColor: colors.lightText,
    selectedItemStyle: { backgroundColor: 'white' },
    unselectedItemStyle: { backgroundColor: 'white' },
    itemStyle: { padding: 16 /* matches navbar styles so checkbox is aligned with buttons */ },
    itemTitleStyle: [textStyles.standard.dark, { marginLeft: sizes.small }],
  };

  CircleThumbnail.defaultProps.loadingIndicatorColor = branding.highlightedColor;

  // get the locale and set numeral formatting
  try {
    console.log(Localization.locale);
    const decimalSeparator = Localization.decimalSeparator;

    // override if not period
    if (decimalSeparator !== '.') {
      console.log(`locale-based decimal separator overridden: ${decimalSeparator}`);
      const thousandsSeparator = Localization.digitGroupingSeparator;
      // borrowed from https://github.com/adamwdraper/Numeral-js/blob/master/src/numeral.js
      // this thing is hacky - can't detect bad locales, can't overwrite a locale, so we do this...
      numeral.register('locale', 'default', {
        delimiters: {
          thousands: thousandsSeparator,
          decimal: decimalSeparator,
        },
        abbreviations: {
          thousand: 'k',
          million: 'm',
          billion: 'b',
          trillion: 't',
        },
        ordinal: function(number) {
          const b = number % 10;
          return ~~((number % 100) / 10) === 1
            ? 'th'
            : b === 1
            ? 'st'
            : b === 2
            ? 'nd'
            : b === 3
            ? 'rd'
            : 'th';
        },
        currency: {
          symbol: '$',
        },
      });
      numeral.locale('default');
    }
  } catch (error) {}

  // TODO: this stopped working with react-native 68

  //TextInput.defaultProps.underlineColorAndroid = 'transparent';
  // not sure why defaultProps isn't found on web
  /*if (Platform.OS !== 'web') {
    TextInput.defaultProps.selectionColor = branding.highlightedColor;
    // Expo compatibility shim - it will either show underline due to missing AndroidManifest change
    // to prevent crash, or padding will be wrong
    // See https://github.com/facebook/react-native/issues/17530#issuecomment-493308243
    // And corresponding changes in TextInput.js (only place where padding was off, oddly)
    TextInput.defaultProps.underlineColorAndroid = 'transparent';
  }*/
};
