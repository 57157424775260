import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { PickerCell } from '/common/components';
import UpdateProfileItemList from './UpdateProfileItemList';

/**
 * We return the state with all the props anytime something changes.
 * What is changing doesn't map perfectly to what is in the object (first name, last name vs name),
 * So it's easier this way. I guess. I hate forms...
 */
@observer
class UpdateUnits extends Component {
  render() {
    const { units, onChangeUnits, t } = this.props;
    const sections = [];

    sections.push({
      title: '',
      data: [
        () => (
          <PickerCell
            selected={units}
            onPressOption={onChangeUnits}
            options={[
              { key: 'imperial', title: t('PROFILE:BUTTONS:IMPERIAL') },
              { key: 'metric', title: t('PROFILE:BUTTONS:METRIC') },
            ]}
          />
        ),
      ],
    });

    return <UpdateProfileItemList sections={sections} />;
  }
}

UpdateUnits.propTypes = {
  units: PropTypes.string.isRequired,
  onChangeUnits: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(UpdateUnits);
