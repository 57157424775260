import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { observer, inject } from 'mobx-react';
import { LoadingShadeWrapper } from '../../components/common';
import { withAlert } from '/common/navigation/';
import { EnterCodeForm } from '../../components/profile';
import { containerStyles } from '../../config/common-styles';
import { ModalNavWrapper } from './common';
import { ScreenError, withKeyboardAvoidance } from '../common';
import { BrandContext } from '../../config/branding';

const scrubHtml = body => {
  return body.replace(/<[^>]+>/g, '');
};

@inject('rootStore')
@observer
class EnterInviteIdModal extends Component {
  static contextType = BrandContext;

  _onSubmit = inviteId => {
    const { rootStore, navigation, alert } = this.props;
    const { settingsStore } = rootStore;

    navigation.setParams({ isModalCloseButtonDisabled: true });
    settingsStore.addCode({ code: inviteId, source: 'coach_settings' }).then(result => {
      if (settingsStore.addCodeState.isDone) {
        navigation.goBack(null);
        const messageParts = result.message.split('! ');
        // helps with android's limited dialog title
        if (messageParts.length === 2) {
          // wash away v3 cruft
          alert(scrubHtml(messageParts[0]), scrubHtml(messageParts[1]));
        } else {
          alert(scrubHtml(result.message));
        }
      } else {
        // don't set this while the modal is closing
        navigation.setParams({ isModalCloseButtonDisabled: false });
      }
    });
  };

  render() {
    const { rootStore, navigation, t } = this.props;
    const { settingsStore } = rootStore;
    const branding = this.context;

    return (
      <LoadingShadeWrapper
        style={containerStyles.fillAvailableSpace}
        isLoading={settingsStore.addCodeState.isPending}>
        <ModalNavWrapper
          navigation={navigation}
          title={t('SETTINGS:ENTER_YOUR_ID', {
            inviteIdShortName: branding.inviteIdDisplay.shortName,
          })}>
          <ScreenError
            state={settingsStore.addCodeState}
            title={t('SETTINGS:ERRORS:ADD_INVITE_ID_FAILED_TITLE', {
              inviteIdName: branding.inviteIdDisplay.name,
            })}
            message={(blahNotAnErrButItIs /*keep linter off my back*/, state) => {
              const formErrors = state.formErrors.filter(f => f.field === 'inviteId');

              if (formErrors.find(f => f.type === 'membershipLimitExceeded')) {
                return t('SIGN_UP:ERRORS:INVALID_INVITE_ID_MEMBERSHIP_LIMIT_EXCEEDED');
              } else if (formErrors.length) {
                return 'Your invite ID appears to be invalid. Please contact support by using the Help button on the Settings tab.';
              }
              return 'Unknown error while processing invite ID';
            }}
          />
          <EnterCodeForm
            codeFieldPlaceholder={branding.inviteIdDisplay.name}
            onSubmit={this._onSubmit}
          />
        </ModalNavWrapper>
      </LoadingShadeWrapper>
    );
  }
}

EnterInviteIdModal.propTypes = {
  rootStore: PropTypes.object,
};

export default withTranslation()(withKeyboardAvoidance(withAlert(EnterInviteIdModal)));
