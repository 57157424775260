import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reaction } from 'mobx';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { withAlert } from '/common/navigation/';
import { LoadingWrapper } from '../../components/common';
import { SimpleNavButton, SimpleNavWrapper } from '../../components/navigation';
import { navigationRoutes } from '../../config/constants';
import { ConnectedAppList } from '../../components/profile';
import { BrandContext } from '../../config/branding';
import { ScreenError, ScreenFirstLoadWrapper } from '../common';

@inject('rootStore', 'AuthWebBrowser')
@observer
class EditConnectedAppsScreen extends Component {
  // hack to prevent multiple workflows and thus mutiple listeners. This should be made better
  static currentWorkflow;
  static contextType = BrandContext;

  componentDidMount() {
    const { rootStore, t } = this.props;
    const branding = this.context;

    rootStore.settingsStore.loadConnectedApps();
    this.onAppConfirmReactionDisposer = reaction(
      () => rootStore.settingsStore.updateConnectedAppState.isDone,
      () => {
        if (rootStore.settingsStore.lastAppConnectAction.action === 'connect') {
          alert(
            t('GENERAL:SUCCESS'),
            t('SYNCED_APPS:CONFIRMATION', {
              appName: branding.appName,
              syncedAppName: rootStore.settingsStore.lastAppConnectAction.app.title,
            })
          );
        }
      }
    );
  }

  componentWillUnmount() {
    if (this.onAppConfirmReactionDisposer) {
      this.onAppConfirmReactionDisposer();
    }
  }

  _onPressStopApp = async app => {
    const { t, rootStore } = this.props;
    const appTitle = app.title;
    const branding = this.context;

    alert(
      t('SYNCED_APPS:STOP_APP_CONFIRM_TITLE'),
      t('SYNCED_APPS:STOP_APP_CONFIRM_MESSAGE', {
        appName: branding.appName,
        syncedAppName: appTitle,
      }),
      [
        {
          text: t('SYNCED_APPS:NO_KEEP_SYNCING'),
          onPress: () => {},
          style: 'cancel',
        },
        {
          text: t('SYNCED_APPS:YES_STOP_SYNCING', { syncedAppName: appTitle }),
          onPress: () => rootStore.settingsStore.unregisterConnectedApp(app),
          style: 'default',
        },
      ],
      { cancelable: false }
    );
  };

  _onPressApp = app => {
    const { AuthWebBrowser, rootStore } = this.props;
    if (app.isBuiltIn) {
      this.props.rootStore.settingsStore.registerBuiltInApp({
        app,
      });
    } else {
      AuthWebBrowser.openAuthSession(app.authUrl, rootStore.getCallbackUrls());
    }
  };

  _onPressNext = () => {
    const { rootStore } = this.props;
    rootStore.markOnboardingStepComplete(navigationRoutes.stacks.onboarding.connectedApps);
  };

  render() {
    const { rootStore } = this.props;
    const { settingsStore } = rootStore;
    return (
      <SimpleNavWrapper
        rightButton={
          <SimpleNavButton
            title={settingsStore.areAnyAppsEnabled ? 'done' : 'skip'}
            onPress={this._onPressNext}
            isPrimaryAction
          />
        }>
        <ScreenFirstLoadWrapper
          state={settingsStore.loadConnectedAppsState}
          hasData={settingsStore.connectedAppsSorted.length}
          serverUnavailableErrorMessage="Syncing new Connected Apps is temporarily disabled while we do some routine system maintenance. Please check back soon!"
          onPressRefresh={settingsStore.loadConnectedApps}>
          <LoadingWrapper
            style={{ flex: 1 }}
            isLoading={
              (settingsStore.loadConnectedAppsState.isPending &&
                settingsStore.connectedAppsSorted.length === 0) ||
              settingsStore.updateConnectedAppState.isPending
            }
            isFailed={settingsStore.loadConnectedAppsState.isFailed}>
            <ScreenError state={settingsStore.updateConnectedAppState} />
            <ConnectedAppList
              connectedApps={settingsStore.connectedAppsSorted}
              isRefreshing={settingsStore.loadConnectedAppsState.isPending}
              onRefresh={settingsStore.loadConnectedApps}
              onPressApp={this._onPressApp}
              onPressStopApp={this._onPressStopApp}
            />
          </LoadingWrapper>
        </ScreenFirstLoadWrapper>
      </SimpleNavWrapper>
    );
  }
}

EditConnectedAppsScreen.propTypes = {
  rootStore: PropTypes.object,
  t: PropTypes.func.isRequired,
};

const EditConnectedAppsModal = EditConnectedAppsScreen;

export default withTranslation()(withAlert(EditConnectedAppsModal));
