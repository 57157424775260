import { types } from 'mobx-state-tree';

const ConnectedApp = types
  .model('ConnectedApp', {
    // not everything is returning an ID anymore, hmmm...
    title: types.maybeNull(types.string),
    // TODO: maybe type should be an identifier, since we keep reloading the same list
    // but the connected app list is kind of weird, had errors when we tried this briefly
    type: types.identifier,
    description: types.maybeNull(types.string),
    iconSource: types.frozen(),
    authUrl: types.maybeNull(types.string),
    deauthUrl: types.maybeNull(types.string),
    isEnabled: types.boolean,
    isMissingPermissions: types.optional(types.boolean, false),
    ios: types.boolean,
    android: types.boolean,
    isBuiltIn: types.optional(types.boolean, false),
    // requires Nudge token exchange API being called in order to finish registration
    // (validic doesn't require this)
    requiresTokenExchange: types.optional(types.boolean, true),
    isBluetooth: types.optional(types.boolean, false),
    bluetoothPeripheralId: types.maybeNull(types.number),
  })
  .views(self => ({
    // simulating an ID while stuff is weird. It really doesn't matter, as long as its unique
    get id() {
      return self.type;
    },
  }));

export default ConnectedApp;
