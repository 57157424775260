import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { View, TouchableOpacity, Text, Platform } from 'react-native';
import { DateTime } from 'luxon';
import GraphData from './internal/GraphData';
import { colors, sizes, textStyles, useMediaQueryInfo } from '/common/config/styles';
import { RightChevronIcon, LeftChevronIcon } from '/common/config/icons';
import { BrandContext } from '../../config/branding';
import TopTabStylePicker from '../TopTabStylePicker';

const GraphDateRange = observer(({ tracker, graphSlice }) => {
  const startDateTime = DateTime.fromISO(graphSlice.startDate);
  const endDateTime = DateTime.fromISO(graphSlice.endDate);

  let startFormat = 'MMM d, yy';
  let endFormat = 'MMM d, yy';

  if (startDateTime.hasSame(endDateTime, 'year')) {
    startFormat = 'MMM d';
    endFormat = 'MMM d, yyyy';
  }

  // only for trackers where graph was disabled
  if (startDateTime.hasSame(endDateTime, 'day')) {
    return (
      <Text allowFontScaling={false} style={textStyles.standard.light}>
        {`${DateTime.fromISO(graphSlice.startDate).toFormat(startFormat)}`}
      </Text>
    );
  }

  if (!tracker.clientMeta.interpolateDates) {
    startFormat = 'MMM, yyyy';
    endFormat = 'MMM, yyyy';
    return graphSlice.points.length > 0 ? (
      <Text allowFontScaling={false} style={textStyles.standard.light}>
        {`${DateTime.fromISO(graphSlice.points[0].date).toFormat(startFormat)} - ${DateTime.fromISO(
          graphSlice.points[graphSlice.points.length - 1].date
        ).toFormat(endFormat)}`}
      </Text>
    ) : null;
  }

  return (
    <Text allowFontScaling={false} style={textStyles.standard.light}>
      {`${DateTime.fromISO(graphSlice.startDate).toFormat(startFormat)} - ${DateTime.fromISO(
        graphSlice.endDate
      ).toFormat(endFormat)}`}
    </Text>
  );
});

/**
 * The top line of the typical tracker cell. Typically shows the icon, name, and value
 * when it is not focused, then just the icon and name when focused.
 */
const TrackerGraph = observer(
  ({
    tracker,
    t,
    useMetricUnits,
    graphSlice,
    onPressPageLeft,
    onPressPageRight,
    onChangeTimeframe,
    timeframe,
    onPressDate,
    selectedDate,
    hideGraph,
    isEditing,
    graphHeight,
    datePickerBarHeight,
    topTabPickerHeight,
    customTarget,
    style,
  }) => {
    const branding = useContext(BrandContext);

    const { innerWindowWidth } = useMediaQueryInfo();

    let graphColor = tracker.palettesId
      ? branding.trackerColors[tracker.palettesId]
      : colors.darkText;

    const showGraph =
      graphHeight > 0 &&
      !branding.logging.disableGraphsForTrackerTypes.find(type => tracker.type === type);

    const tabs = useMemo(() => {
      if (!showGraph) {
        return [];
      }
      return tracker.clientMeta.graphConfig.timeframes.map(key => {
        if (key === 'week') {
          return { title: t('TRENDS:DETAILS:WEEK'), key: 'week' };
        }
        if (key === 'month') {
          return { title: t('TRENDS:DETAILS:MONTH'), key: 'month' };
        }
        if (key === 'quarter') {
          return { title: t('TRENDS:DETAILS:90DAY'), key: 'quarter' };
        }
        if (key === 'year') {
          return { title: t('TRENDS:DETAILS:YEAR'), key: 'year' };
        }
      });
    }, [t, tracker]);

    return (
      <View style={{ flex: 1, ...style }}>
        {tabs.length > 1 && !isEditing ? (
          <TopTabStylePicker
            style={{ backgroundColor: colors.bg0, height: topTabPickerHeight }}
            buttons={tabs}
            onPressButton={onChangeTimeframe}
            selectedKey={timeframe}
            selectedTabColor={graphColor}
          />
        ) : null}
        <View
          style={{
            flexDirection: 'row',
            justifyContent: !isEditing ? 'space-between' : 'center',
            alignItems: 'center',
            paddingHorizontal: sizes.small,
            height: datePickerBarHeight,
          }}>
          {!isEditing && (
            <TouchableOpacity
              hitSlop={{ top: 4, bottom: 4, left: 4, right: 4 }}
              onPress={onPressPageLeft}>
              <LeftChevronIcon size={20} />
            </TouchableOpacity>
          )}
          <GraphDateRange tracker={tracker} graphSlice={graphSlice} />
          {!isEditing && (
            <TouchableOpacity
              hitSlop={{ top: 4, bottom: 4, left: 4, right: 4 }}
              onPress={onPressPageRight}>
              <RightChevronIcon size={20} />
            </TouchableOpacity>
          )}
        </View>
        <View
          style={{
            marginHorizontal: 0,
            paddingBottom: sizes.small,
            justifyContent: 'center',
            flex: 1,
          }}>
          {showGraph ? (
            <GraphData
              useMetricUnits={useMetricUnits}
              tracker={tracker}
              color={graphColor}
              timeframe={timeframe}
              data={
                hideGraph
                  ? /* hide tail points that have shifted into the main view so nothing shows in the graph */ graphSlice.points.map(
                      p => ({ ...p, value: null })
                    )
                  : graphSlice.points
              }
              height={graphHeight}
              width={innerWindowWidth}
              head={graphSlice.head}
              tail={graphSlice.tail}
              greenTarget={customTarget ? customTarget : tracker.clientMeta.graphConfig.greenTarget}
              yellowTarget={customTarget ? null : tracker.clientMeta.graphConfig.yellowTarget}
              selectedDate={hideGraph ? null : selectedDate}
              onHoverOverDate={Platform.OS === 'web' ? null : onPressDate}
              onPressDate={Platform.OS === 'web' ? onPressDate : null}
              unselectedDateOpacity={0.5}
            />
          ) : null}
        </View>
      </View>
    );
  }
);

TrackerGraph.propTypes = {
  tracker: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  useMetricUnits: PropTypes.bool,
  graphSlice: PropTypes.object.isRequired, // special objects from mobx model that aggregates data by date
  onPressPageLeft: PropTypes.func.isRequired,
  onPressPageRight: PropTypes.func.isRequired,
  onChangeTimeframe: PropTypes.func.isRequired,
  timeframe: PropTypes.string.isRequired,
  onPressDate: PropTypes.func.isRequired,
  selectedDate: PropTypes.string,
  hideGraph: PropTypes.bool,
  isEditing: PropTypes.bool, // remove certain elements if data is being edited right now
  graphHeight: PropTypes.number.isRequired,
  customTarget: PropTypes.number, // if specified, use a custom goal as a y axis line instead of defaults
};

TrackerGraph.defaultProps = {
  hideGraph: false,
  isEditing: false,
  useMetricUnits: false,
  selectedDate: null,
};

export default withTranslation()(TrackerGraph);
