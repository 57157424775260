import React, { Component } from 'react';
import { TouchableOpacity as Touchable, Platform } from 'react-native';
import PropTypes from 'prop-types';
import Topic from './Topic';
import Reply from './Reply';
import { WebMessageContextMenuButton } from '/common/components/chat';

class ThreadMessage extends Component {
  _onLongPress = () => {
    if (this.props.onLongPress) {
      this.props.onLongPress(this.props.currentMessage);
    }
  };

  // I can't seem to trigger a long press here when using TouchableWithoutFeedback!
  // No idea why
  render() {
    const {
      currentMessage,
      singleThreadMode,
      onPressLink,
      onPressAttachment,
      onPressImage,
    } = this.props;

    let OuterComponent;
    if (Platform.OS === 'web') {
      OuterComponent = ({ children }) => (
        <WebMessageContextMenuButton
          position="right"
          onPress={this._onLongPress}
          buttonStyle={{ top: 0 }}>
          <Touchable style={{ flex: 1 }} activeOpacity={1} onLongPress={this._onLongPress}>
            {children}
          </Touchable>
        </WebMessageContextMenuButton>
      );
    } else {
      OuterComponent = ({ children }) => (
        <Touchable style={{ flex: 1 }} activeOpacity={1} onLongPress={this._onLongPress}>
          {children}
        </Touchable>
      );
    }
    return (
      <OuterComponent>
        {currentMessage.originatingMessage.replies ? (
          <Topic
            onPressLink={onPressLink}
            singleThreadMode={singleThreadMode}
            topic={currentMessage.originatingMessage}
            currentMessage={currentMessage}
            onPressAttachment={onPressAttachment}
            onLongPress={this._onLongPress}
            onPressImage={onPressImage}
          />
        ) : (
          <Reply
            onPressLink={onPressLink}
            reply={currentMessage.originatingMessage}
            currentMessage={currentMessage}
            onPressAttachment={onPressAttachment}
            onLongPress={this._onLongPress}
            onPressImage={onPressImage}
          />
        )}
      </OuterComponent>
    );
  }
}

ThreadMessage.propTypes = {
  currentMessage: PropTypes.object.isRequired,
  onLongPress: PropTypes.func,
  onPressLink: PropTypes.func.isRequired,
};

ThreadMessage.defaultProps = {
  onLongPress: () => {},
};

export default ThreadMessage;
