import React from 'react';
import { observer } from 'mobx-react';
import InlineLogItem from '../InlineLogItem';

const CardTrackerComponent = observer(function({
  onPressViewTracker,
  programEvent,
  component,
  onPressAddLog,
  disableAddLog,
}) {
  const tracker = component.tracker;

  if (!tracker) {
    return null;
  }

  return (
    <InlineLogItem
      key={component.id.toString()}
      tracker={tracker}
      date={programEvent.startDate}
      onPressViewTracker={onPressViewTracker}
      programEvent={programEvent}
      programCardComponent={component}
      onPressAddLog={onPressAddLog}
      disableAddLog={disableAddLog}
    />
  );
});

export default CardTrackerComponent;
