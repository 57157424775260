import React, { Component, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { View, Text, Image, SafeAreaView, StyleSheet, Platform, Alert } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { StatusBar } from 'expo-status-bar';
import { reaction } from 'mobx';
import { observer, inject } from 'mobx-react';
import { isIphoneX } from 'react-native-iphone-x-helper';
import * as Device from 'expo-device';
import { withTranslation } from 'react-i18next';
import { hook } from 'cavy';
import { NavbarlessScreen } from '/common/components/navigation';
import { withAlert } from '/common/navigation/';
import { LoadingShadeWrapper } from '../../components/common';
import { navigationRoutes } from '../../config/constants';
import {
  containerStyles,
  textStyles,
  useMediaQueryInfo,
  sizes,
  colors,
} from '/common/config/styles';
import { BrandContext } from '../../config/branding';
import env from '../../config/env';
import { RoundButton } from '../../../common/components/';
import useNextSignupRoute from './useNextSignupRoute';

const bigScreenRatio = Platform.isPad ? 0.5 : 0.61313131313;

// safely extract so we can use useWindowDimensions hook without rewriting everything
function WelcomeScreenImage() {
  const branding = useContext(BrandContext);
  const { isTabletOrDesktop, isTablet, innerWindowWidth } = useMediaQueryInfo();
  return (
    <View
      style={[
        containerStyles.fillAvailableSpace,
        {
          //borderRightWidth: StyleSheet.hairlineWidth, // this created a weird line on the right
          //borderColor: colors.border0,
          marginTop: -2, // no idea why I needed to do this to nudge the bg up and get rid of a white line
        },
        isTabletOrDesktop || isTablet
          ? {
              backgroundColor: branding.iPad.welcomeScreenBackground,
              width: innerWindowWidth * bigScreenRatio,
            }
          : null,
      ]}>
      <Image
        style={[containerStyles.fillAvailableSpace]}
        source={
          Platform.OS === 'web' && branding.brandKey === 'nudge'
            ? require('../../assets/welcome-nudge-for-clients-beta.png')
            : branding.assets.welcomeScreen
        }
        resizeMode={isTabletOrDesktop || isTablet ? 'contain' : 'cover'}
      />
    </View>
  );
}
function Buttons(props) {
  const { isTabletOrDesktop, isTablet, innerWindowWidth } = useMediaQueryInfo();
  const nextRoute = useNextSignupRoute(props.route);

  const onPressSignUp = useCallback(() => {
    props.navigation.navigate(nextRoute);
  }, [props.navigation, nextRoute]);
  return (
    <SafeAreaView
      style={[
        {
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          borderTopWidth: StyleSheet.hairlineWidth,
          borderColor: colors.border0,
        },
        isTabletOrDesktop || isTablet
          ? {
              borderColor: colors.border0,
              height: '100%',
              position: 'absolute',
              right: 0,
              left: innerWindowWidth * bigScreenRatio,
            }
          : {
              borderColor: colors.border0,
              height: isIphoneX() || Device.deviceName.includes('iPhone 14 Pro') ? 100 : 70,
            },
        { backgroundColor: colors.bg0, paddingBottom: isIphoneX() ? 60 : 0 },
      ]}>
      <View style={{ width: isTabletOrDesktop || isTablet ? '18%' : 10 }} />
      {!props.ssoEnabled && (
        <>
          <RoundButton
            title={props.t('LOGIN:BUTTONS:IM_NEW')}
            buttonStyle={{ flex: 1, margin: 0 }}
            ref={ref => {
              props.generateTestHook('WelcomeScreen.SignUp')(ref);
            }}
            onPress={onPressSignUp}
            theme={'lightOnDark'}
          />
          <View style={{ width: isTabletOrDesktop || isTablet ? 20 : 10 }} />
        </>
      )}
      <RoundButton
        title={props.t('LOGIN:BUTTONS:SIGN_IN')}
        buttonStyle={{ flex: 1, margin: 0 }}
        ref={ref => {
          props.generateTestHook('WelcomeScreen.SignIn')(ref);
        }}
        onPress={props.onPressSignIn}
      />
      <View style={{ width: isTabletOrDesktop || isTablet ? '18%' : 10 }} />
    </SafeAreaView>
  );
}

@inject('rootStore', 'AuthWebBrowser')
@observer
class WelcomeScreen extends Component {
  static contextType = BrandContext;

  async setHasClipboardPermission(setting) {
    const itemKey = 'hasClipBoardPermission';
    try {
      await AsyncStorage.setItem(itemKey, JSON.stringify(setting));
    } catch (e) {
      console.log('Could not set clipboard permission:', e);
    }
  }

  async checkClipboardPermission(t, branding) {
    const itemKey = 'hasClipBoardPermission';
    try {
      // Check if the permission is set in AsyncStorage
      const permission = await AsyncStorage.getItem(itemKey);
      if (permission === null) {
        // Permission is not set, show the alert
        Alert.alert(
          'Clipboard Data',
          t('SIGN_UP:CLIPBOARD_DISCLAIMER', {
            inviteIdName: branding.inviteIdDisplay.name,
          }),
          [
            {
              text: 'Deny',
              onPress: () => this.setHasClipboardPermission(false),
              style: 'destructive',
            },
            {
              text: 'Accept',
              onPress: () => this.setHasClipboardPermission(true),
              style: 'default',
            },
          ]
        );
      }
    } catch (e) {
      console.log('Error checking clipboard permission:', e);
    }
  }

  componentDidMount() {
    const { rootStore, t, alert } = this.props;
    const branding = this.context;
    if (Platform.OS === 'android') {
      this.checkClipboardPermission(t, branding);
    }
    this.onSssoErrorReactionDisposer = reaction(
      () => rootStore.loginState.isFailed,
      isFailed => {
        if (isFailed && rootStore.loginState.formErrors.length) {
          // mobx weirdness
          const error = rootStore.loginState.formErrors.slice().find(e => e.field === 'sso');
          if (error) {
            alert(
              t('LOGIN:ERRORS:GENERIC_TITLE'),
              t(`LOGIN:ERRORS:SSO:${error.type}`, { appName: branding.appName })
            );
          }
        }
      }
    );
  }

  componentWillUnmount() {
    if (this.onSssoErrorReactionDisposer) {
      this.onSssoErrorReactionDisposer();
    }
  }

  _onPressSignIn = async () => {
    const { navigation, AuthWebBrowser, rootStore } = this.props;
    const branding = this.context;

    if (branding.sso.enabled && !env.disableSso) {
      AuthWebBrowser.openAuthSession(rootStore.getSsoUrl(), rootStore.getCallbackUrls());
    } else {
      navigation.navigate(navigationRoutes.stacks.login.enterCredentials);
    }
  };

  render() {
    const { rootStore } = this.props;

    const branding = this.context;

    const buttonArea = (
      <Buttons
        ssoEnabled={branding.sso.enabled}
        onPressSignIn={this._onPressSignIn}
        {...this.props}
      />
    );

    return (
      <NavbarlessScreen>
        {Platform.OS === 'ios' /* looks awful on android phones with screen cutouts */ && (
          <StatusBar hidden />
        )}
        <LoadingShadeWrapper style={{ flex: 1 }} isLoading={rootStore.loginState.isPending}>
          <View style={containerStyles.fillAvailableSpace}>
            <WelcomeScreenImage />
            {rootStore.settingsStore.bluetoothPeripherals.find(
              p => p.isPaired
            ) /* check for paired devices - session timeout may have logged them out but kept devices paired */ ? (
              <View
                style={{
                  paddingHorizontal: sizes.medium,
                  paddingVertical: sizes.small,
                  backgroundColor: branding.highlightedColor,
                }}>
                <Text style={textStyles.standard.white}>
                  You paired one or more bluetooth devices during a previous session. They will
                  continue to sync even if you are not logged in.
                </Text>
              </View>
            ) : null}
            {buttonArea}
          </View>
        </LoadingShadeWrapper>
      </NavbarlessScreen>
    );
  }
}

WelcomeScreen.propTypes = {
  rootStore: PropTypes.object,
};

export default withTranslation()(withAlert(hook(WelcomeScreen)));
