import { Platform, useWindowDimensions } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import sizes from './sizes';

function useMediaQueryInfo(options) {
  const myOptions = { alwaysUseWindowWidth: false, ...options };
  const isTabletOrDesktop = useMediaQuery({ minWidth: 768 });
  const { width, height } = useWindowDimensions();

  if (Platform.OS === 'web') {
    const innerWindowWidth = isTabletOrDesktop && !myOptions.alwaysUseWindowWidth ? 650 : width;
    return {
      isTabletOrDesktop,
      isWideScreen: isTabletOrDesktop /* new prop: switch to this */,
      innerWindowWidth,
      leftOffsetFromEdge: innerWindowWidth === width ? 0 : (width - innerWindowWidth) / 2,
      rightOffsetFromEdge: innerWindowWidth === width ? 0 : (width - innerWindowWidth) / 2,
      innerWindowHeight: height - 30 - sizes.medium * 2,
      windowHeight: height,
      innerWidthMatchesWindowWidth: innerWindowWidth === width,
      isTablet: false,
      isWeb: true,
    };
  }

  // media queries don't work on 4300+ builds due to missing native deps
  return {
    isTabletOrDesktop: false, // set to false for now to disable web/ large screen optimizations
    isWideScreen: false,
    isTablet: Platform.isPad,
    isWeb: false,
    innerWindowWidth: width,
    leftOffsetFromEdge: 0,
    rightOffsetFromEdge: 0,
    innerWindowHeight: height,
    windowHeight: height,
    innerWidthMatchesWindowWidth: true,
  };
}

export default useMediaQueryInfo;
