const getIdFromYoutubeUrl = url => {
  console.log(url);
  if (url.includes('youtu.be') && !url.includes('youtube.com/watch')) {
    // need to account for videos with "feature=youtu.be", e.g., https://www.youtube.com/watch?v=q-_BWXpM-Y0&feature=youtu.be
    let video_id = url.split('youtu.be/')[1];
    return video_id;
  }
  if (url.includes('youtube.com/shorts')) {
    const lastPart = url.split('shorts/')[1];
    // example: https://youtube.com/shorts/1708sQIgTtU?feature=share
    return lastPart.split('?')[0];
  }
  let video_id = url.split('v=')[1];
  const ampersandPosition = video_id.indexOf('&');
  if (ampersandPosition != -1) {
    video_id = video_id.substring(0, ampersandPosition);
  }
  return video_id;
};

const getUrlHandlerInfo = ({ url, provider, meta }) => {
  // not great for short URL's or maybe other stuff
  if (
    (provider && provider.toUpperCase().endsWith('YOUTUBE')) ||
    url.includes('youtube.com/watch') ||
    url.includes('youtu.be')
  ) {
    return {
      handler: 'videoPlayer',
      source: 'youtube',
      videoId:
        meta.videoId /* will be included on at least new metadata soon */ ||
        getIdFromYoutubeUrl(url),
      previewImageUrl: `https://img.youtube.com/vi/${getIdFromYoutubeUrl(url)}/0.jpg`,
    };
  }
  // might just match on vimeo.com - needs at least one number afterwards
  if ((provider && provider.toUpperCase().endsWith('VIMEO')) || url.includes('vimeo.com/')) {
    let modifiedEmbedHtml = meta.html;
    modifiedEmbedHtml = modifiedEmbedHtml.replace('width', 'blah1').replace('height', 'blah2');
    modifiedEmbedHtml =
      modifiedEmbedHtml.slice(0, 7) + ` class="iframe" ` + modifiedEmbedHtml.slice(7);

    return {
      handler: 'videoPlayer',
      source: 'vimeo',
      videoId: meta.videoId,
      // placeholder - we need to do something like this, probably in Coach when the video is set
      // https://gist.github.com/bacoords/77ee4d13dfa76db03981cb4eb0df0c6f
      previewImageUrl: meta.thumbnailUrl,
      embedHtml: modifiedEmbedHtml,
    };
  }
  return {
    handler: 'os',
  };
};

export { getUrlHandlerInfo };
