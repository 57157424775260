import { NativeModules } from 'react-native';

let version = '???';
let buildNumber = '???';

if (NativeModules.RNDeviceInfo) {
  const DeviceInfo = require('react-native-device-info').default;
  version = DeviceInfo.getVersion();
  buildNumber = DeviceInfo.getBuildNumber();
} else {
  // if we are on web grab the version and build number from nudge releases repo
  fetch('https://api.github.com/repos/nudgeyourself/nudge-mobile-v4-releases/releases')
    .then(res => res.json())
    .then(response => {
      if (Object.keys(response).length > 0) {
        const release = response[0]?.tag_name;

        // extract version number (number before the first dash)
        let versionRegex = /^(\d+\.\d+\.\d+)-/;
        let versionMatch = release.match(versionRegex);
        let webVersionNumber = versionMatch[1];

        // extract build number (number in the middle)
        let buildRegex = /^(\d+\.\d+\.\d+)-(\d+)-/;
        let buildMatch = release.match(buildRegex);
        let webBuildNumber = buildMatch[2];

        version = webVersionNumber;
        buildNumber = webBuildNumber;
      } else {
        console.log('Version not found');
      }
    })
    .catch(error => {
      console.log('An error happened when trying to fetch version from nudge versions repo', error);
    });

  console.warn(
    'WARNING: Not using react-native-device-info! This is OK if you are running in the Expo client. Very bad if not!'
  );
}

export default () => {
  const versions = require('../../current-config/jsversions.json');

  return {
    version,
    buildNumber,
    jsBuild: versions.jsBuild,
  };
};
