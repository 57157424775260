import React, { useContext, useCallback } from 'react';
import { View, Text, TouchableOpacity, Platform } from 'react-native';
import PropTypes from 'prop-types';
import { getUniversalSvgComponents } from 'nudge-client-common/common-svg';
import { observer } from 'mobx-react';
import { CompletionGraph } from 'nudge-client-common/graphs';
import { BrandContext } from '../../config/branding';
import {
  colors,
  sizes,
  textStyles,
  containerStyles,
  useMediaQueryInfo,
} from '/common/config/styles';
import { RepeatIcon } from '/common/config/icons';
import { SecurableImage } from '/common/components';
import cardConstants from './internal/cardConstants';
import getFeatureImageSource from './internal/getFeatureImageSource';
import CardComponent from './internal/card-component';

/**
 * Used by ProgramList.js. Shown in the card feed on the client app.
 */
const ClientSummaryCard = observer(({ programEvent, onPressCard, perspective }) => {
  const branding = useContext(BrandContext);
  const onPress = useCallback(() => {
    onPressCard(programEvent.id);
  }, [onPressCard]);

  const { isTabletOrDesktop, innerWindowWidth } = useMediaQueryInfo();

  const cardMargin = isTabletOrDesktop ? sizes.large * 2 : sizes.medium;

  const windowWidth = innerWindowWidth;
  const cardWidth = Math.min(windowWidth - cardMargin * 2, 450);

  const programCard = programEvent.programCard;
  const featureImageSource = getFeatureImageSource(programCard, branding);
  const featImageWidth = cardWidth;
  const featImageHeight = (featImageWidth * 9) / 16;

  const elements = programCard.summaryComponents.map((p, i) => {
    return (
      <CardComponent
        key={i.toString()}
        programEvent={programEvent}
        component={p}
        index={i}
        headlineMode={'summary'}
        onPressLink={onPress}
        onPressVideo={onPress}
      />
    );
  });
  const cardInner = (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginHorizontal: cardMargin,
        marginBottom: 0,
        borderRadius: cardConstants.corners,
        shadowColor: '#BABABA',
        backgroundColor: colors.bg0,
        shadowOpacity: 0.5,
        shadowRadius: sizes.borderRadius,
        shadowOffset: { width: 0, height: 2 },
        padding: cardConstants.imagePadding,
        paddingBottom: 0,
        maxWidth: 450,
      }}>
      {featureImageSource ? (
        <View
          style={{
            width: featImageWidth,
            height: featImageHeight,
            overflow: 'hidden',
            borderTopLeftRadius: cardConstants.corners - cardConstants.imagePadding,
            borderTopRightRadius: cardConstants.corners - cardConstants.imagePadding,
          }}>
          <SecurableImage
            source={featureImageSource}
            resizeMode={'cover'}
            style={{
              width: featImageWidth,
              height: featImageHeight,
              backgroundColor: colors.bg0,
              borderTopLeftRadius: cardConstants.corners - cardConstants.imagePadding,
              borderTopRightRadius: cardConstants.corners - cardConstants.imagePadding,
            }}
          />
        </View>
      ) : (
        <View
          style={{
            width: featImageWidth /* force cards with no feature image to the same width */,
          }}
        />
      )}
      <View
        style={{
          position: 'absolute',
          width: 40,
          top: 18,
          right: 18,
        }}>
        {programCard.hasTrackers && (
          <CompletionGraph
            TextComponent={Text}
            ViewComponent={View}
            svg={getUniversalSvgComponents(Platform.OS)}
            diameter={cardConstants.completionGraphDiameter}
            strokeWidth={cardConstants.completionGraphStrokeWidth}
            percentComplete={programEvent.goalCompletionStats.percentComplete}
            numGoalsComplete={programEvent.goalCompletionStats.numComplete}
            totalGoals={programEvent.goalCompletionStats.total}
            completedColor={branding.highlightedColor}
            bgColor={
              programEvent.goalCompletionStats.percentComplete == 1
                ? branding.highlightedColor
                : colors.bg1
            }
            check={programEvent.goalCompletionStats.percentComplete == 1}
            contentsColor={
              programEvent.goalCompletionStats.percentComplete == 1 ? colors.bg0 : colors.darkText
            }
          />
        )}
      </View>
      <View
        style={{
          position: 'absolute',
          top: 18,
          left: 18,
        }}>
        {!programEvent.isRead &&
        programEvent.showCardOpenedStatus /* show NEW badge if a card has not been read and was shared after the October 2021 cutoff */ ? (
          <View
            style={{
              height: 24,
              paddingVertical: 4,
              paddingHorizontal: sizes.small,
              backgroundColor: branding.highlightedColor,
              borderRadius: sizes.borderRadius,
              justifyContent: 'center',
            }}>
            <Text allowFontScaling={false} style={{ fontSize: 13, color: 'white' }}>
              {perspective === 'coach' ? 'UNOPENED' : 'NEW'}
            </Text>
          </View>
        ) : programEvent.share.repeatingType &&
          programCard.hasTrackers /* show repeating symbol only for cards with trackers, because that's the only time the contents would really change */ ? (
          <View
            style={{
              height: 24,
              width: 24,
              backgroundColor: featureImageSource ? 'rgba(255,255,255,.5)' : colors.bg1,
              borderRadius: sizes.borderRadius,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <RepeatIcon />
          </View>
        ) : null}
      </View>
      <View
        style={[
          { width: '100%' },
          // make cards that are too small look like repeat cards
          elements.length === 0 ? { paddingBottom: sizes.small } : null,
          !featureImageSource
            ? {
                paddingTop: sizes.small,
              }
            : null,
        ]}>
        <View
          style={{
            padding: sizes.medium,
            //width: '100%', // prevents line wrapping
            paddingBottom: sizes.medium,
            // Add extra padding for cards with no feature image that have trackers or NEW badge
            paddingTop:
              !featureImageSource &&
              (programCard.hasTrackers ||
                (!programEvent.isRead && programEvent.showCardOpenedStatus))
                ? 50
                : sizes.medium,
            marginRight: programCard.hasTrackers && !featureImageSource ? 50 : undefined, // extra padding for compliance circle
          }}>
          <Text
            style={[
              textStyles.standard.bold,
              { fontSize: cardConstants.headlineFontSize, lineHeight: 26 },
            ]}>
            {programCard.title}
          </Text>
        </View>
      </View>
      {elements.length ? (
        <View
          style={{
            marginLeft: sizes.medium,
            marginRight: sizes.medium,
            marginBottom: sizes.medium,
          }}>
          {elements}
        </View>
      ) : null}
    </View>
  );

  return (
    <View style={containerStyles.verticalChildrenCentered}>
      <TouchableOpacity
        style={{ flex: 1 }}
        activeOpacity={Platform.OS === 'ios' ? 1 : 0.8}
        onPress={onPress}>
        {cardInner}
      </TouchableOpacity>
    </View>
  );
});

ClientSummaryCard.propTypes = {
  programEvent: PropTypes.object.isRequired,
  onPressCard: PropTypes.func.isRequired,
  // coach or client
  perspective: PropTypes.string,
};

ClientSummaryCard.defaultProps = {
  perspective: 'client',
};

export default ClientSummaryCard;