import { types } from 'mobx-state-tree';
import {
  v4TimestampToJsDate,
  JsDateToV4Timestamp,
  v3TimestampToJsDate,
  jsDateToV3Timestamp,
} from '../repos/utils';
const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

// I totally forgot these were used!
// Probably need to decide if they really work, and if they should continue being used

export const DateTimeV4 = types.custom({
  name: 'DateTimeV4',
  fromSnapshot: string => v4TimestampToJsDate(string),
  toSnapshot: jsDate => JsDateToV4Timestamp(jsDate),
  isTargetType: maybeJsDate => typeof maybeJsDate.getMonth === 'function',
  getValidationMessage: snapshot => {
    if (snapshot === undefined) return '';
    const maybeJsDate = v4TimestampToJsDate(snapshot);
    if (typeof maybeJsDate.getMonth !== 'function') {
      const message = `"${snapshot}" is not in valid date format ${DATE_FORMAT}`;
      console.error(message);
      return message;
    }
    return '';
  },
});

export const DateTimeV3 = types.custom({
  name: 'DateTimeV3',
  fromSnapshot: seconds => v3TimestampToJsDate(seconds),
  toSnapshot: jsDate => jsDateToV3Timestamp(jsDate),
  isTargetType: maybeJsDate => typeof maybeJsDate.getMonth === 'function',
  getValidationMessage: snapshot => {
    if (snapshot === undefined) return '';
    const maybeJsDate = v3TimestampToJsDate(snapshot);
    if (typeof maybeJsDate.getMonth !== 'function') {
      const message = `"${snapshot}" is not in valid date format of seconds since epoch`;
      console.error(message);
      return message;
    }
    return '';
  },
});
