/**
 * Object for reading environment variables.
 * These variables usually change for testing purposes, or for differnet server environments.
 */

/* eslint-disable */
// This file is not checked in, needs to be set via yarn run set-nudge-config.
import appConfig from '../../../current-config/nudge-config.json';
/* eslint-enable */

// We write these out in order to get intellisense, even though it is the
// same structure.
export default {
  apiUrl: appConfig.apiUrl,
  supportEmailLink: 'mailto:support@nudgecoach.com' || appConfig.supportEmailLink,
  // termsOfServiceUrl: 'https://nudgecoach.com/privacy/' || config.termsOfServiceUrl,
  privacyPolicyUrl: 'https://nudgecoach.com/privacy/' || appConfig.privacyPolicyUrl,
  // limited support for this. Requires a delay and doesn't work with pre-login routes
  // This doesn't work in v4 yet
  test: {
    startAtAlternateRoute: appConfig.test && appConfig.test.startAtAlternateRoute,
    alternateStartingRoute: appConfig.test && appConfig.test.alternateStartingRoute,
    // may want to change this if your debug environment is working slowly
    pushAlternateRouteDelayInMs: appConfig.test && appConfig.test.pushAlternateRouteDelayInMs,
    alternateStartingRouteParams: appConfig.test && appConfig.test.alternateStartingRouteParams,
  },
  logApiCalls: appConfig.logApiCalls,
  pusherKey: appConfig.pusherKey,
  pusherCluster: appConfig.pusherCluster,
  amplitudeKey: appConfig.amplitudeKey,
  showExperimentalFeatures: appConfig.showExperimentalFeatures,
  // array of mocks enabled. Currently supported:
  // log - mock a current week's worth of logs
  // signup - pretend to sign up with an existing account
  // allTabs - profile with all tabs enabled
  mocks: appConfig.mocks || [],
  // future home for in-development features that need to be semi-manually invoked.
  // Available shims:
  // "signUpNotFinished" - to sim when a user is logged in but never completed their sign up
  // "skipAccountDemographicsOnSignup" - skip entry of name, etc. (used when it is already populated on account)
  // "expectsLabResults" - may or may not have lab results, but at least expects them based on how they signed up
  // "emailConfirmed" - email confirmation is complete
  // Future shims: "waitingForLabs", "waitingForPatientIdForLabLink"
  shims: appConfig.shims || [],
  showDevTools: appConfig.showDevTools,
  newMessageNotificationDebounceInMs: appConfig.newMessageNotificationDebounceInMs || 30000,
  // hide features that we're not ready to show folks for review/ demo purposes
  hideNotReadyFeatures: appConfig.hideNotReadyFeatures || false,
  showTrackerColors: appConfig.showTrackerColors || true,
  disableSso: appConfig.disableSso || false,
  // for white label demo - shows what it will look like with the no-coach page
  forceNoCoachPage: appConfig.forceNoCoachPage || false,
  // for white label demo - we flash the Nudge brand to clear out labels as we switch them, cobrands mess that up
  disableCobrands: appConfig.disableCobrands || false,
  // whenever an OTA update is downloaded, prompt the user to apply it immediately
  // Used for test versions so folks don't test the wrong stuff
  forceOtaUpdateWhenAvailable: appConfig.forceOtaUpdateWhenAvailable || false,
  debug: appConfig.debug
    ? {
        apiErrorTestTriggersEnabled: appConfig.debug.apiErrorTestTriggersEnabled || false,
        apiErrorTestTriggers: appConfig.debug.apiErrorTestTriggers || null,
        defaultTab: appConfig.debug.defaultTab || null,
        defaultProgram: appConfig.debug.defaultProgram || null,
        simulatedApiToken: appConfig.debug.simulatedApiToken || null,
        simulatedUserId: appConfig.debug.simulatedUserId || null,
      }
    : {
        apiErrorTestTriggersEnabled: false,
        apiErrorTestTriggers: null,
        defaultTab: null,
        defaultProgram: null,
        simulatedApiToken: null,
        simulatedUserId: null,
      },
  // for loading of brand props and assets on the web build. These assets are in the brands/{brand} folder
  // if 'php' is set, will load from a local variable populated by php in index.php.
  // if 'network' is set, will load by downloading the files over http
  // 'network' is useful for local development. Run build-web to build in "debug" mode, which creates the brands folder, then
  // network mode will download those files, because the php in index will not work.
  webBrandLoadingMethod: 'php' || appConfig.webBrandLoadingMethod,
};
