import React, { useContext } from 'react';
import { BrandContext } from './branding';

const UserOptionsContext = React.createContext({ useMetricUnits: false });
// TODO: theme context so theme can be changed

/**
 * Upcoming context to provide all sorts of personalization info, including theming
 * UPDATE: We actually might not want to use this, because ANY change in ANY context would force a re-render of
 * ANYTHING using this hook. So, probably better to use each context separately.
 */
function usePersonalization() {
  const userOptionsContext = useContext(UserOptionsContext);
  const branding = useContext(BrandContext); // TODO: switch to common context
  return {
    userOptions: userOptionsContext,
    branding,
    // TODO: populate theme based on user settings (co-brand) and brand
    theme: {
      logo: null,
      background: null,
      useDarkTheme: false,
    },
  };
}

export { UserOptionsContext, usePersonalization };
