import React, { Component } from 'react';
import { Text, StyleSheet, Platform } from 'react-native';
import { observer } from 'mobx-react';
import { BrandContext } from '../../config/branding';
import { colors } from '../../config/common-styles';

/**
 * Used to show the proper custom-branded tab label using brand from context.
 *
 * @class TabLabel
 * @extends {Component}
 */
@observer
class TabLabel extends Component {
  static contextType = BrandContext;

  render() {
    const { tintColor, tabKey } = this.props;
    const branding = this.context;

    const title = branding.tabs[tabKey].name;

    return (
      <Text
        allowFontScaling={false}
        style={[
          {
            textAlign: 'center',
            color: tintColor ? tintColor : colors.lightText,
          },
          Platform.isPad ? styles.labelBeside : styles.labelBeneath,
        ]}>
        {title}
      </Text>
    );
  }
}

// copied from React Navigation
// https://github.com/react-navigation/react-navigation-tabs/blob/master/src/views/BottomTabBar.js
const styles = StyleSheet.create({
  labelBeneath: {
    fontSize: 11,
    marginBottom: 1.5,
  },
  labelBeside: {
    fontSize: 12,
    marginLeft: 15,
  },
});

const ComponentThing = TabLabel;

// I don't know why react navigation is being so weird about this
// wants a function, not a component...
export default props => <ComponentThing {...props} />;
