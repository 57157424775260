import React, { Component } from 'react';
import {
  View,
  Text,
  TextInput,
  StyleSheet,
  Platform,
  TouchableWithoutFeedback as Touchable,
} from 'react-native';
import PropTypes from 'prop-types';
import { colors, sizes, textStyles } from '../../config/common-styles';

export default class SignUpField extends Component {
  componentDidMount() {
    // autofocus doesn't seem to mix with navigation
    if (Platform.OS === 'web' && this.props.focus) {
      setTimeout(() => {
        this.focus();
      }, 500);
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    // do the focus stuff
    // if focus prop is newly-true, take focus
    // if focus prop is newly-false, relinquish focus
    const { focus } = nextProps;
    focus && !this.props.focus && this.focus();
    !focus && this.props.focus && this.blur();
  };

  focus = () => {
    this._textInputRef.focus();
  };

  blur = () => {
    this._textInputRef.blur();
  };
  render() {
    const {
      focus,
      text,
      onChangeText,
      placeholder,
      returnKeyType,
      onSubmitEditing,
      isSecure,
      validationError,
      onPress,
      ...rest
    } = this.props;

    let textInputInner = (
      <View
        style={{
          borderBottomColor: 'rgba(0,0,0,.18)',
          borderBottomWidth: StyleSheet.hairlineWidth,
          flex: 1,
        }}>
        <TextInput
          ref={ref => {
            this._textInputRef = ref;
          }}
          autoFocus={focus}
          value={text}
          style={[{ height: 40, flex: 1, textAlign: 'center' }, textStyles.medium.dark]}
          placeholder={placeholder}
          placeholderTextColor={colors.lightText}
          onChangeText={onChangeText}
          secureTextEntry={isSecure}
          returnKeyType={returnKeyType}
          onSubmitEditing={() => onSubmitEditing && onSubmitEditing()}
          autoCorrect={false}
          autoCapitalize="none"
          {...rest}
        />
      </View>
    );

    return (
      <View style={{ flex: 1, marginBottom: validationError ? sizes.small : sizes.medium }}>
        {textInputInner}
        {onPress && !this.props.editable ? (
          <View
            style={{
              position: 'absolute',
              backgroundColor: 'transparent',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            }}>
            <Touchable onPress={onPress} style={{ flex: 1 }}>
              <View style={{ flex: 1 }} />
            </Touchable>
          </View>
        ) : null}
        {validationError ? (
          <Text
            style={[
              textStyles.standard.dark,
              { color: colors.error, marginTop: sizes.small, textAlign: 'center' },
            ]}>
            {validationError}
          </Text>
        ) : null}
      </View>
    );
  }
}

SignUpField.propTypes = {
  text: PropTypes.any,
  placeholder: PropTypes.string,
  onChangeText: PropTypes.func.isRequired,
  focus: PropTypes.bool,
  isSecure: PropTypes.bool,
  onSubmitEditing: PropTypes.func,
  returnKeyType: PropTypes.string,
  validationError: PropTypes.string,
  editable: PropTypes.bool,
  onPress: PropTypes.func,
};

SignUpField.defaultProps = {
  text: '',
  focus: false,
  placeholder: '',
  isSecure: false,
  onSubmitEditing: null,
  returnKeyType: null,
  validationError: '',
  editable: true,
  onPress: null,
};
