import React, { useContext } from 'react';
import { View, Text, Image } from 'react-native';
import { observer } from 'mobx-react';
import { sizes, textStyles, useMediaQueryInfo } from '/common/config/styles';
import { BrandContext } from '../../config/branding';

const DownloadMobileApp = observer(function() {
  const branding = useContext(BrandContext);
  const { innerWindowWidth } = useMediaQueryInfo();

  let mode = 'desktop';

  if( /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ) {
    mode = 'mobile';
  }

  return (
    <View
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <View style={{ maxWidth: innerWindowWidth }}>
        <Image
          style={{
            height: 80,
            width: 80,
            borderRadius: sizes.borderRadius,
            marginBottom: sizes.large,
            marginHorizontal: sizes.medium,
          }}
          source={branding.assets.appIcon}
        />
        <View
          style={{
            width: '100%',
            marginBottom: sizes.medium,
          }}
        />
        <Text
          style={[
            textStyles.bold.dark,
            { marginBottom: sizes.small, marginHorizontal: sizes.medium },
          ]}>
          <Text>{`Download the ${branding.appName} app.`}</Text>
        </Text>
        <Text
          style={[
            textStyles.standard.light,
            { marginVertical: sizes.medium, marginHorizontal: sizes.large },
          ]}>
          <Text>{`With the app you'll have access to everything ${branding.appName} has to offer wherever you are and you'll never miss updates thanks to notifications.`}</Text>
        </Text>
      </View>
    </View>
  );
});

export default DownloadMobileApp;
