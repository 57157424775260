import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Platform, NativeModules } from 'react-native';
import { observer, inject } from 'mobx-react';
import getAppId from '/app/lib/getAppId';
import Constants from 'expo-constants';
import BadWordsFilter from 'bad-words';
import { withTranslation } from 'react-i18next';
import * as WebBrowser from 'expo-web-browser';
import * as Device from 'expo-device';
import { withScreenBehaviors, withAlert } from '/common/navigation/';
import { SettingsSummary } from '../../../components/settings';
import {
  NavbarWrapper,
  // We need to replace the navbar wrapper to make this look right.
  /*TopTabBarBlurOverlay,*/
} from '../../../components/navigation';
import { navigationRoutes } from '../../../config/constants';
import { BrandContext } from '../../../config/branding';
import { rootStore } from '../../../stores/instances';
import { ScreenFirstLoadWrapper } from '../../common';
import { getTheme } from '../../../lib/theming';
import getVersion from '../../../lib/getVersion';

@inject('rootStore', 'Linking')
@observer
class SettingsTab extends Component {
  static contextType = BrandContext;

  _onLogout = () => {
    this.props.rootStore.logout().then(() => {
      this.props.navigation.navigate(navigationRoutes.stacks.login.root);
    });
  };

  _onPressEnterPromoCode = () => {
    this.props.navigation.navigate(navigationRoutes.modals.enterPromoCode);
  };

  _onPressUpdateClientUserId = () => {
    this.props.navigation.navigate(navigationRoutes.stacks.main.updateClientUserId);
  };

  _onPressEnterPatientId = () => {
    this.props.navigation.navigate(navigationRoutes.modals.enterPatientId);
  };

  _onPressEditProfile = () => {
    this.props.navigation.navigate(navigationRoutes.stacks.main.editProfile);
  };

  _onPressConfirmEmailAddress = () => {
    this.props.navigation.navigate(navigationRoutes.modals.confirmEmailAddress);
  };

  _onPressHelp = () => {
    const { t, Linking, alert } = this.props;
    const branding = this.context;

    const versions = getVersion();

    let locale = '';
    if (Platform.OS === 'ios') {
      locale =
        NativeModules.SettingsManager.settings.AppleLocale ||
        NativeModules.SettingsManager.settings.AppleLanguages[0];
    } else if (Platform.OS === 'android') {
      locale = NativeModules.I18nManager.localeIdentifier;
    }
    // locale stuff breaks on web/ not super important right now

    console.log(branding.settings.supportEmailAddress);

    const url = `mailto:${branding.settings.supportEmailAddress}?subject=${
      branding.appName
    } Support Request (id: ${
      this.props.rootStore.settingsStore.user ? this.props.rootStore.settingsStore.user.id : null
    } / v. ${versions.version}-${versions.buildNumber}-${
      versions.jsBuild
    } / device model ID: ${new BadWordsFilter().clean(
      Platform.OS === 'ios'
        ? Device.modelName
        : Constants.deviceName /* this is the user-defined device name on ios */
    )} / ${Platform.OS} v. ${Platform.Version} / ${locale})`;

    Linking.openURL(encodeURI(url)).catch(() => {
      alert(
        t('SETTINGS:ERRORS:SUPPORT_EMAIL_FAILED_TITLE'),
        t('SETTINGS:ERRORS:SUPPORT_EMAIL_FAILED_MESSAGE')
      );
    });
  };

  _onPressPrivacyPolicy = () => {
    const branding = this.context;

    if (branding.links.privacyPolicy) {
      WebBrowser.openBrowserAsync(`${branding.links.privacyPolicy}`);
    } else {
      WebBrowser.openBrowserAsync('https://nudgecoach.com/privacy/');
    }
  };

  _onPressTermsOfService = () => {
    const branding = this.context;

    if (branding.links.tos) {
      WebBrowser.openBrowserAsync(`${branding.links.tos}`);
    } else {
      WebBrowser.openBrowserAsync('https://nudgecoach.com/tos');
    }
  };

  _onPressRateApp = () => {
    const branding = this.context;
    // TODO: get the right link (this is for Coach Messenger )
    // TODO: also needs to be specific to white label
    this.props.Linking.openURL(
      Platform.OS === 'ios'
        ? `https://itunes.apple.com/gb/app/id${branding.appStoreId}?action=write-review&mt=8`
        : `market://details?id=${getAppId()}`
    );
  };

  // TODO: we need the first load wrapper here

  render() {
    const { navigation, rootStore, t } = this.props;
    const { settingsStore, socialStore } = rootStore;
    const branding = this.context;

    const theme = getTheme({ rootStore, branding });

    return (
      <NavbarWrapper title={t('TABS:SETTINGS')} theme={theme}>
        <ScreenFirstLoadWrapper
          state={settingsStore.loadUserState}
          hasData={settingsStore.user}
          onPressRefresh={settingsStore.loadUser}>
          {settingsStore.user && (
            <SettingsSummary
              user={settingsStore.user}
              clubs={socialStore.clubs}
              isClubsLoaded={socialStore.loadClubsState.isDone}
              isGroupsEnabled={rootStore.enabledData.groups}
              onPressEnterPromoCode={this._onPressEnterPromoCode}
              onPressUpdateClientUserId={this._onPressUpdateClientUserId}
              onPressEnterPatientId={branding.hasLabs ? this._onPressEnterPatientId : null}
              onPressConnectedApps={() =>
                navigation.navigate(navigationRoutes.modals.editConnectedApps)
              }
              onPressNotifications={() =>
                navigation.navigate(navigationRoutes.modals.communityNotifications)
              }
              onPressPeripherals={() =>
                navigation.navigate(navigationRoutes.modals.editConnectedPeripherals)
              }
              onPressConfirmEmailAddress={this._onPressConfirmEmailAddress}
              onPressEditProfile={this._onPressEditProfile}
              onPressHelp={this._onPressHelp}
              onPressTermsOfService={this._onPressTermsOfService}
              onPressPrivacyPolicy={this._onPressPrivacyPolicy}
              onPressRateApp={this._onPressRateApp}
              hideRateApp={Platform.OS === 'ios' && !branding.appStoreId}
              otaUpdateStatus={rootStore.otaUpdateStatus}
            />
          )}
        </ScreenFirstLoadWrapper>
      </NavbarWrapper>
    );
  }
}

SettingsTab.propTypes = {
  rootStore: PropTypes.object,
  navigation: PropTypes.object,
  t: PropTypes.any.isRequired,
};

export default withScreenBehaviors({
  onFocus: () => {
    rootStore.setActiveFrame('settings');
    rootStore.settingsStore.loadUser();
  },
  onActive: () => {
    rootStore.settingsStore.loadUser();
  },
})(withTranslation()(withAlert(SettingsTab)));
