import React from 'react';
import { View, Platform, Text, Pressable } from 'react-native';
import { observer } from 'mobx-react';
import { colors, textStyles, sizes } from '/common/config/styles';

const TinyDot = () => {
  return (
    <View
      style={{
        borderRadius: 2,
        height: 4,
        width: 4,
        backgroundColor: colors.border1,
        marginHorizontal: 1,
      }}
    />
  );
};

const WebMessageContextMenuButton = ({ children, position, onPress, buttonStyle }) => {
  const overlap = sizes.small;
  return (
    <Pressable style={{ flex: 1 }} onPress={onPress}>
      {({ hovered }) => (
        <>
          {children}
          <View
            style={[
              {
                height: 24,
                width: 30,
                position: 'absolute',
                top: overlap * -1,
                borderRadius: sizes.borderRadius,
                borderWidth: 1,
                borderColor: colors.border1,
                backgroundColor: colors.bg0,
                justifyContent: 'center',
                alignItems: 'center',
                shadowColor: '#000',
                shadowOffset: { width: 0.5, height: 0.5 },
                shadowOpacity: 0.1,
                shadowRadius: 1,
                elevation: 1,
                opacity: hovered ? 1 : 0,
                visibility: hovered ? 'visible' : 'hidden',
                transition: hovered ? 'all 500ms ease-in' : 'all 500ms ease-out',
              },
              position === 'left'
                ? { left: sizes.small + overlap / 2 }
                : { right: sizes.small + overlap / 2 },
              buttonStyle,
            ]}>
            <View style={{ flexDirection: 'row' }}>
              <TinyDot />
              <TinyDot />
              <TinyDot />
            </View>
          </View>
        </>
      )}
    </Pressable>
  );
};

export default WebMessageContextMenuButton;
