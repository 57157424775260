import { StyleSheet } from 'react-native';
import colors from './colors';

export default StyleSheet.create({
  // *** navbar ***
  navbar: {
    ...{
      backgroundColor: colors.bg1,
      shadowColor: 'transparent',
      borderBottomColor: colors.border1,
      borderBottomWidth: 0.5,
      // removes bottom border from Android
      shadowOpacity: 0,
      elevation: 0,
    },
  },
  // navbar that joins up against content below it. (no line at the bottom)
  // note that this still leaves a line on android right now
  expandedNavbar: {
    // not sure if this does anything anymore
    shadowColor: 'transparent',
    // removes bottom border from iOS
    borderBottomColor: 'transparent',
    borderBottomWidth: 0,
    shadowRadius: 0,
    // shadowOpacity: .1,
    // elevation: 4,
    // shadowOffset: { height: 0.5 },
    // removes bottom border from Android
    shadowOpacity: 0,
    elevation: 0,
    // makes navbar and top tab bar a little closer
    // This was previously set to -10, but that cut off the top border of the search box on some views
    marginBottom: -8,
  },

  // *** navbar buttons ***

  // replicates styles from Native Base button,
  // which seemed to work well in navbar
  navbarButton: {
    height: 45,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 6,
    paddingBottom: 6,
    justifyContent: 'center',
  },
});
