import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { PickerCell, } from '/common/components';
import UpdateProfileItemList from './UpdateProfileItemList';

/**
 * We return the state with all the props anytime something changes.
 * What is changing doesn't map perfectly to what is in the object (first name, last name vs name),
 * So it's easier this way. I guess. I hate forms...
 */
@observer
class UpdateGender extends Component {
  render() {
    const { gender, onChangeGender, t } = this.props;
    const sections = [];

    sections.push({
      title: '',
      data: [
        () => (
          <PickerCell
            selected={gender}
            nullIsAnOption
            onPressOption={onChangeGender}
            options={[
              { key: 'm', title: t('PROFILE:BUTTONS:MALE') },
              { key: 'f', title: t('PROFILE:BUTTONS:FEMALE') },
              { key: null, title: t('PROFILE:BUTTONS:NOT_SPECIFIED_OTHER') },
            ]}
          />
        ),
      ],
    });

    return <UpdateProfileItemList sections={sections} />;
  }
}

UpdateGender.propTypes = {
  gender: PropTypes.string,
  onChangeGender: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

UpdateGender.defaultProps = {
  gender: null,
};

export default withTranslation()(UpdateGender);
