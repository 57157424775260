import React from 'react';
import { HeaderIconButton, NavbarWrapper } from '../../components/navigation';
import { CloseModalIcon } from '../../config/common-icons';

export default (ContainerComponent, title) => {
  // We get our headers from the StackNavigator, even though this isn't
  // really a stack. There's probably a better way.
  const Modal = ({ navigation, route, ...props }) => {
    const navParams = route ? route.params : navigation.state.params; // v4 to v5 compat layer
    return (
      <NavbarWrapper
        title={title}
        leftButton={
          <HeaderIconButton
            disabled={navParams && navParams.isModalCloseButtonDisabled}
            icon={<CloseModalIcon />}
            onPress={() => {
              navigation.pop(); // requires a null for key for some reason - probably a pre-1.0 thing
            }}
          />
        }
        navigation={navigation} route={route}>
        <ContainerComponent navigation={navigation} {...props} />
      </NavbarWrapper>
    );
  };

  return Modal;
};
