import React from 'react';
import { Platform } from 'react-native';
import PropTypes from 'prop-types';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
//import { spy } from 'mobx';
import { observer, inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { NavbarlessScreen } from '/common/components/navigation';
import { useScreenBehaviors } from '/common/navigation';
import { sizes } from '/common/config/styles';
import { ScreenFirstLoadWrapper, ScreenError } from '../../../common';
import { navigationRoutes } from '../../../../config/constants';
import FeedSubTab from './FeedSubTab';
import TagsSubTab from './TagsSubTab';
import ProgramTopTabs from './ProgramTopTabs';

const EmptyComponent = () => null;

const errorContainerStyle = { paddingTop: sizes.large };

const Tab = createBottomTabNavigator();

/**
 * Tab that contains a header that allows navigating between the seven days of the week,
 * or can expand to show a calendar to navigate to any day/ week.
 * Under the header is a carousel that can swipe between the seven active days,
 * and can be reloaded to show a different week.
 */
const ProgramTab = inject(
  'rootStore',
  'Linking'
)(
  observer(({ rootStore }) => {
    const { t } = useTranslation();

    useScreenBehaviors({
      onFocus: () => {
        const { logStore } = rootStore;
        rootStore.setActiveFrame('programs');
        // experimenting on reloading only when the app becomes active (see RootStore)
        // except when there are no cards ... load more aggressively to get this populated
        if (!logStore.programEvents.length && !logStore.loadProgramDataState.isPending) {
          logStore.loadNewestProgramEvents();
        }
      },
    });

    const { logStore } = rootStore;

    const hasTags = rootStore.settingsStore.user
      ? rootStore.settingsStore.user.enabledData.collections
      : false;

    return (
      <NavbarlessScreen>
        <ScreenFirstLoadWrapper
          state={logStore.loadProgramDataState}
          hasData={logStore.programEvents.length}
          errorContainerStyle={errorContainerStyle}
          onPressRefresh={logStore.loadNewestProgramEvents}>
          <ScreenError
            state={
              logStore.loadProgramDataState /* tricky! - only happens on subsequent refreshes because it's inside first load wrapper */
            }
            title={t('PROGRAMS:ERRORS:REFRESH_FAILED')}
            type="messageBar"
          />
          {hasTags && <ProgramTopTabs />}
          <Tab.Navigator
            detachInactiveScreens={
              Platform.OS === 'web' /* setting to false prevents scrolling on web */
            }
            initialRouteName={navigationRoutes.stacks.main.feedSubTab}
            screenOptions={{ headerShown: false }}
            tabBar={EmptyComponent}>
            <Tab.Screen name={navigationRoutes.stacks.main.feedSubTab} component={FeedSubTab} />
            <Tab.Screen name={navigationRoutes.stacks.main.tagsSubTab} component={TagsSubTab} />
          </Tab.Navigator>
        </ScreenFirstLoadWrapper>
      </NavbarlessScreen>
    );
  })
);

// TODO: try this for collapsing tab bar: https://github.com/benevbright/react-navigation-collapsible

ProgramTab.propTypes = {
  rootStore: PropTypes.object,
};

export default ProgramTab;
