import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useTranslation } from 'react-i18next';
import { usePersonalization } from '/common/config/personalization';
import { colors, sizes, textStyles } from '/common/config/styles';
import FieldContainer from './FieldContainer';

/**
 * NOTE: This thing is intended to always be the right-most field...
 * or at least, to take up the most space. It'll grow to take up any remaining space in the editor area.
 */
function MultipleChoiceField({
  tracker,
  options,
  value,
  canEdit,
  valueTextStyle,
  onChangeValue,
  formError,
  isRequired,
}) {
  const { t } = useTranslation();
  const personalization = usePersonalization();
  const branding = personalization.branding;
  const highlightColor = tracker.palettesId
    ? branding.trackerColors[tracker.palettesId]
    : colors.darkText;

  const pickerOptions = options.map(option => {
    const isSelected = option === value;
    return (
      <TouchableOpacity
        style={{ width: '100%' }}
        key={option}
        onPress={() => onChangeValue(option)}>
        <View
          style={{
            borderRadius: sizes.borderRadius,
            borderColor: isSelected ? highlightColor : colors.border1,
            borderWidth: 1,
            marginVertical: sizes.small / 2,
            padding: sizes.small,
            backgroundColor: isSelected ? highlightColor : 'white',
            flex: 1,
          }}>
          <Text
            numberOfLines={3}
            ellipsizeMode="tail"
            style={[
              textStyles.standard.dark,
              {
                textAlign: 'left',
                color: isSelected ? colors.bg0 : colors.lightText,
              },
            ]}>
            {option}
          </Text>
        </View>
      </TouchableOpacity>
    );
  });

  return (
    <FieldContainer
      title={null}
      isRequired={isRequired}
      validationError={formError ? t('LOG:ENTRY_ERRORS.MUST_SELECT_ONE') : null}
      canEdit={canEdit}>
      <View
        style={[
          {
            flex: 1,
            alignItems: 'flex-start',
          },
        ]}>
        {canEdit ? (
          <View style={{ width: '100%' }}>
            <View>
              <Text
                style={[
                  textStyles.standard.semibold,
                  {
                    color: highlightColor,
                    textAlign: 'center',
                    paddingVertical: sizes.medium,
                  },
                ]}>
                Pick One
              </Text>
            </View>
            {pickerOptions}
          </View>
        ) : (
          <Text style={[textStyles.standard.dark, valueTextStyle]}>
            {value ? value : 'Tap to Pick One...'}
          </Text>
        )}
      </View>
    </FieldContainer>
  );
}

MultipleChoiceField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  canEdit: PropTypes.bool,
  valueTextStyle: PropTypes.any,
  palettesId: PropTypes.any,
  options: PropTypes.any,
};

MultipleChoiceField.defaultProps = {
  value: 0,
  canEdit: false,
};

export default observer(MultipleChoiceField);
