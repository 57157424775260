import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { RequestPasswordReset } from '../../components/login';
import { SimpleNavWrapper, HeaderIconButton } from '../../components/navigation';
import { LoadingShadeWrapper } from '../../components/common';
import { containerStyles } from '../../config/common-styles';
import { BackIcon } from '../../config/common-icons';
import { withKeyboardAvoidance, ScreenError } from '../common';

const ResetPasswordScreen = inject('rootStore')(
  observer(({ navigation, route, rootStore }) => {
    useEffect(() => {
      rootStore.requestPasswordResetState.reset(); // clear isDone state
    }, []);

    const [username, setUsername] = useState(route.params.username || '');

    const onPressRequestReset = useCallback(() => {
      rootStore.requestPasswordReset({ username });
    }, [username]);

    return (
      <SimpleNavWrapper
        leftButton={
          <HeaderIconButton
            cavyId="EnterInviteIdScreen.Back"
            icon={<BackIcon />}
            onPress={navigation.goBack}
          />
        }>
        <LoadingShadeWrapper
          coversOnboardingScreen
          isLoading={rootStore.requestPasswordResetState.isPending}
          style={containerStyles.fillAvailableSpace}>
          <ScreenError state={rootStore.requestPasswordResetState} ignoreFormErrors />
          <RequestPasswordReset
            username={username}
            onChangeUsername={setUsername}
            onPressRequestReset={onPressRequestReset}
            formErrors={rootStore.requestPasswordResetState.formErrors}
            isRequestComplete={rootStore.requestPasswordResetState.isDone}
            onGoBack={navigation.goBack}
            lastPasswordRequestedAt={rootStore.lastPasswordResetRequestedAt}
          />
        </LoadingShadeWrapper>
      </SimpleNavWrapper>
    );
  })
);

ResetPasswordScreen.propTypes = {
  navigation: PropTypes.object,
};

export default withKeyboardAvoidance(ResetPasswordScreen, {
  enabledForIos: false,
});
