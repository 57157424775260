import React, { Component } from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import DatePicker from 'react-datepicker';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { jsDateToDateString, dateStringToJsDate } from '../../lib/dates';
import UpdateProfileItemList from './UpdateProfileItemList';
import { sizes, colors } from '/common/config/styles/';
import '/common/config/styles/TimeInput.css';

const PopperContainer = ({ children }) => createPortal(children, document.body);

/**
 * We return the state with all the props anytime something changes.
 * What is changing doesn't map perfectly to what is in the object (first name, last name vs name),
 * So it's easier this way. I guess. I hate forms...
 */
@observer
class UpdateDateOfBirth extends Component {
  _onChangeDate = date => {
    this.props.onChangeDateOfBirth(jsDateToDateString(date));
  };

  _onPressDate = () => {
    this._datePickerRef.showModal();
  };

  render() {
    const { dateOfBirth, t } = this.props;
    const sections = [];

    sections.push({
      title: '',
      data: [
        () => (
          <View style={{ padding: sizes.medium, backgroundColor: colors.bg0 }}>
            <DatePicker
              selected={dateOfBirth ? dateStringToJsDate(dateOfBirth) : null}
              placeholderText="Select a date..."
              onChange={this._onChangeDate}
              popperContainer={PopperContainer}
            />
          </View>
        ),
      ],
    });

    return <UpdateProfileItemList sections={sections} />;
  }
}

UpdateDateOfBirth.propTypes = {
  dateOfBirth: PropTypes.string,
  onChangeDateOfBirth: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

UpdateDateOfBirth.defaultProps = {
  dateOfBirth: null,
};

export default withTranslation()(UpdateDateOfBirth);
