// adds image process indicator and no longer opens weird default fullscreen modal
// but instead implements standard onPress.

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { View, TouchableOpacity, Platform } from 'react-native';
import { observer } from 'mobx-react/native';
import { SecurableImage } from '/common/components';
import { sizes, useMediaQueryInfo } from '/common/config/styles';

// This weirdness is courtesy of https://github.com/oblador/react-native-image-progress/issues/61
// Basically, react-native-image-progress is now wrapped in a container, but it doesn't support
// passing props directly to the image component.
// But it does support creating a component that shows progress out of another Image component.
const ImageWithCustomSizing = props => {
  const { isPortrait, source, style, ...rest } = props;

  const { innerWindowWidth } = useMediaQueryInfo();

  let maxImageSquare = innerWindowWidth / 2 - sizes.medium;

  // still too big on iPad
  if (Platform.isPad) {
    maxImageSquare = innerWindowWidth / 4 - sizes.medium;
  }

  const smallerDimension = (maxImageSquare * 2) / 3;

  const imageWidth = isPortrait ? smallerDimension : maxImageSquare;
  const imageHeight = isPortrait ? maxImageSquare : smallerDimension;

  // I don't rememember why we specify only maxHeight or maxWidth, but specifying both fixes some android weirdness with images that are
  // too large for image previews, but iOS worked fine already, so just being safe
  return (
    <SecurableImage
      showSpinnerWhileLoading
      resizeMode="cover"
      style={{
        borderRadius: sizes.borderRadius,
        width: imageWidth,
        height: imageHeight,
      }}
      source={source}
      {...rest}
    />
  );
};

@observer
export default class MessageImage extends Component {
  // removed long press for images because there's nothing actionable until you can delete them.
  // set opacity to 1 so it never flashes when you're scrolling over it
  render() {
    const isPortrait =
      this.props.currentMessage.attachmentSource.height >
      this.props.currentMessage.attachmentSource.width;
    return (
      <View style={this.props.containerStyle}>
        <TouchableOpacity
          onPress={this.props.onPress}
          activeOpacity={1}
          onLongPress={this.props.onLongPress}>
          <ImageWithCustomSizing
            isPortrait={isPortrait}
            source={this.props.currentMessage.attachmentSource}
            indicatorProps={{ color: this.props.highlightedColor }}
          />
        </TouchableOpacity>

      </View>
    );
  }
}

MessageImage.defaultProps = {
  currentMessage: {
    image: null,
  },
  containerStyle: {},
  imageProps: {},
  lightboxProps: {},
  highlightedColor: 'blue',
};

MessageImage.contextTypes = {
  actionSheet: PropTypes.func,
};

MessageImage.propTypes = {
  currentMessage: PropTypes.object,
  containerStyle: PropTypes.any,
  imageProps: PropTypes.object,
  highlightedColor: PropTypes.string,
};
