import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { observer } from 'mobx-react';
import { NoCoach } from '../../../../components/messaging';
import { navigationRoutes } from '../../../../config/constants';
import { containerStyles } from '../../../../config/common-styles';

@observer
export default class NoCoachesView extends Component {
  _onPressEnterCode = () => {
    this.props.navigation.navigate(navigationRoutes.modals.enterPromoCode);
  };

  render() {
    const { rootStore, Linking } = this.props;
    const { conversationsStore } = rootStore;
    return (
      <View style={containerStyles.fillAvailableSpace}>
        <NoCoach
          onPressLink={Linking.openURL}
          onPressEnterCode={this._onPressEnterCode}
          noCoachPageContent={conversationsStore.noCoachPageContent}
        />
      </View>
    );
  }
}

NoCoachesView.propTypes = {
  rootStore: PropTypes.object,
  navigation: PropTypes.object,
  Linking: PropTypes.any,
};
