
/**
 * Services shared between coach mobile and nudge. Will eventually be merged
 *
 * @export
 * @class UserRepository
 */
export default class CommonRepository {
  _apiV5;

  constructor({ apiV5 }) {
    this._apiV5 = apiV5;
  }

  getShareableUrl = async fileId => {
    const result = await this._apiV5.get(`upload/${fileId}/shareable`);
    return result.data.shareableUrl;
  };
}
