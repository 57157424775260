import React from 'react';
import PropTypes from 'prop-types';
import { FlatList, View, Text } from 'react-native';
import { observer } from 'mobx-react';
import { isIphoneX } from 'react-native-iphone-x-helper';
import { withTranslation } from 'react-i18next';
import { sortBy } from 'lodash';
import { SeparatorLine } from '../../components/common';
import ConnectedApp from './ConnectedApp';
import { colors, sizes, textStyles } from '../../config/common-styles';

const headerStyle = {
  marginHorizontal: sizes.large,
  marginTop: sizes.large,
};

const ConnectedAppList = observer(
  ({ connectedApps, onPressApp, onPressStopApp, isRefreshing, onRefresh, t }) => {
    // construct composite key that puts enabled apps first, everything else alphabetical order
    const data = sortBy(connectedApps, a => `${!a.isEnabled}-${a.title}`);

    return (
      <FlatList
        data={data}
        style={{ backgroundColor: colors.bg0 }}
        refreshing={isRefreshing}
        onRefresh={onRefresh}
        keyExtractor={i => i.id}
        renderItem={({ item }) => (
          <ConnectedApp app={item} onPressApp={onPressApp} onPressStopApp={onPressStopApp} />
        )}
        ItemSeparatorComponent={() => <SeparatorLine style={{ marginHorizontal: sizes.large }} />}
        ListHeaderComponent={
          <View style={headerStyle}>
            <Text style={textStyles.standard.light}>{t('SYNCED_APPS:DESCRIPTION')}</Text>
          </View>
        }
        ListFooterComponent={
          isIphoneX() ? <View style={{ height: sizes.iphoneXBottomPadding }} /> : null
        }
      />
    );
  }
);

ConnectedAppList.propTypes = {
  connectedApps: PropTypes.arrayOf(PropTypes.object).isRequired,
  isRefreshing: PropTypes.bool.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onPressApp: PropTypes.func.isRequired,
  onPressStopApp: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ConnectedAppList);
