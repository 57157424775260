import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { uniq, sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  NudgeSectionList as SectionList,
  EmptyListPlaceholder,
  AestheticallyPleasingListFooter,
} from '/common/components';
import LogItem from './internal/LogItem';
import SectionHeader from '../section-header';
import { colors, useMediaQueryInfo } from '/common/config/styles';
import { descriptiveDateWithoutYear } from '/common/config/strings';

const emptyFunc = () => {};

const extractKey = item => item.id.toString();

const LogsList = observer(({ logs, tracker, onPressLog }) => {
  const { isWideScreen } = useMediaQueryInfo();
  const { t } = useTranslation();

  const renderLog = useCallback(
    ({ item }) => {
      return (
        <LogItem
          key={item.id.toString()}
          log={item}
          tracker={tracker}
          onPress={onPressLog}
          isSelected={false}
          onPressDone={emptyFunc}
        />
      );
    },
    [tracker, onPressLog]
  );

  const uniqueDays = uniq(logs.map(l => l.date));

  const sections = sortBy(
    uniqueDays.map(date => ({
      title: date,
      data: logs.filter(l => l.date === date),
    })),
    s => s.title
  ).reverse();

  return (
    <SectionList
      style={{ backgroundColor: colors.bg1 /* not sure why I need this here */ }}
      contentContainerStyle={{ flexGrow: 1 }}
      keyboardShouldPersistTaps="always"
      removeClippedSubviews={false}
      sections={sections}
      renderItem={renderLog}
      keyExtractor={extractKey}
      initialNumToRender={15}
      ListEmptyComponent={
        <EmptyListPlaceholder title={null} message={'No logs during this period.'} />
      }
      renderSectionHeader={({ section }) => (
        <SectionHeader
          style={{ backgroundColor: isWideScreen ? colors.bg0 : undefined }}
          title={descriptiveDateWithoutYear(section.title, { t })}
        />
      )}
      ListFooterComponent={AestheticallyPleasingListFooter}
    />
  );
});

LogsList.propTypes = {};

export default LogsList;
