import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Text } from 'react-native';
import { textStyles } from '/common/config/styles';

class RowEditorControl extends Component {
  render() {
    const { label, containerStyle, labelStyle, editor, validationError } = this.props;

    const defaultContainerStyle = {
      justifyContent: 'center',
      paddingHorizontal: 15,
      minHeight: 45.5, // ensures other cells are same size as cell with a toggle
      backgroundColor: '#FFFFFF',
    };

    const defaultLabelStyle = {
      marginRight: 8,
    };

    return (
      <View style={[defaultContainerStyle, containerStyle]}>
        <View
          style={[
            {
              flexDirection: 'row',
              alignItems: 'center',
            },
            defaultContainerStyle, // allows the validation error to only take up additional space, not eat into min height
            containerStyle,
          ]}>
          <Text numberOfLines={1} ellipsizeMode="tail" style={[defaultLabelStyle, labelStyle]}>
            {label}
          </Text>
          {editor}
        </View>
        {validationError}
      </View>
    );
  }
}

RowEditorControl.propTypes = {
  label: PropTypes.string.isRequired,
  containerStyle: PropTypes.any,
  labelStyle: PropTypes.any,
  editor: PropTypes.element.isRequired,
  validationError: PropTypes.element,
};

RowEditorControl.defaultProps = {
  containerStyle: null,
  labelStyle: textStyles.standard.light,
  validationError: null,
};

export default RowEditorControl;