import React, { Component } from 'react';
import { View, Platform } from 'react-native';
import * as Animatable from 'react-native-animatable';
import { colors } from '../../config/common-styles';
import { delay } from '/common/lib';
import { LoadingWrapper } from '/common/components';

export default class FadeWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = { isInProgress: false };
  }
  handleViewRef = ref => (this.view = ref);

  flash = () => this.view.animate({ 0: { opacity: 0 }, 0.5: { opacity: 1 } });

  startFadeIos = () => this.view.animate({ 0: { opacity: 1 }, 1: { opacity: 0.3 } }, 200);

  endFadeIos = () => this.view.animate({ 0: { opacity: 0.3 }, 1: { opacity: 1 } }, 200);

  startFadeAndroid = async () => {
    this.setState({ isInProgress: true });
    await delay(10);
    // delay means an unmount is possible, so don't fade if that happens
    if (this.view) {
      await this.view.animate({ 0: { opacity: 0 }, 1: { opacity: 0.6 } }, 150);
    }
  };

  endFadeAndroid = async () => {
    await this.view.animate({ 0: { opacity: 0.6 }, 1: { opacity: 0 } }, 150);
    this.setState({ isInProgress: false });
  };

  startFade = Platform.OS === 'android' ? this.startFadeAndroid : this.startFadeIos;

  endFade = Platform.OS === 'android' ? this.endFadeAndroid : this.endFadeIos;

  render() {
    const { children, wrapperStyle } = this.props;

    if (Platform.OS === 'ios') {
      return (
        <View style={[{ flex: 1, backgroundColor: colors.bg0 }, wrapperStyle]}>
          <Animatable.View style={{ flex: 1 }} ref={this.handleViewRef} useNativeDriver>
            {children}
          </Animatable.View>
        </View>
      );
    }
    return (
      <View style={[{ flex: 1, backgroundColor: colors.bg0 }, wrapperStyle]}>
        <View style={{ flex: 1 }}>{children}</View>
        {this.state.isInProgress && (
          <Animatable.View
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              backgroundColor: colors.bg0,
              opacity: 0,
            }}
            useNativeDriver
            ref={this.handleViewRef}>
            <LoadingWrapper style={{ flex: 1 }} isLoading />
          </Animatable.View>
        )}
      </View>
    );
  }
}
