import React, { FunctionComponent, useCallback } from 'react';
import { observer } from 'mobx-react';
import TextareaAutosize from 'react-textarea-autosize';
import { fonts } from '../../config/styles';

interface InputToolbarTextInputProps {
  currentMessage: { text: string };
  isOperationPending: boolean;
  placeholderText: string;
  onChangeText: (text: string) => {};
  verticalInputPadding: number;
}

const InputToolbarTextInput: FunctionComponent<InputToolbarTextInputProps> = observer(
  ({ currentMessage, isOperationPending, placeholderText, onChangeText, verticalInputPadding }) => {
    const onChange = useCallback(event => onChangeText(event.target.value), [onChangeText]);
    return (
      <TextareaAutosize
        placeholder={placeholderText}
        minRows={1}
        maxRows={5}
        onChange={onChange}
        style={{
          flex: 1,
          paddingTop: verticalInputPadding,
          paddingBottom: verticalInputPadding,
          height: 20,
          fontSize: fonts.sizes.standard,
          fontFamily: 'sans-serif',
          overflowY: 'hidden',
        }}
        value={isOperationPending ? '' : currentMessage.text}
      />
    );
  }
);

export default InputToolbarTextInput;
