import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { View, Text, Platform, Alert } from 'react-native';
import * as IntentLauncher from 'expo-intent-launcher';
import * as WebBrowser from 'expo-web-browser';
import Constants from 'expo-constants';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import {
  RowCenteredButton,
  CircleThumbnail,
  RowChevronButton,
  NudgeSectionList as SectionList,
  AestheticallyPleasingListFooter,
  RoundButton,
  SectionHeader,
} from '/common/components';
import { SeparatorLine } from '../../common';
import {
  containerStyles,
  colors,
  sizes,
  textStyles,
  useMediaQueryInfo,
} from '/common//config/styles';
import Clipboard from '/common/lib/clipboard/';
import { common, dates } from '../../../config/strings';
import { BrandContext } from '../../../config/branding';

// helps add margin around button with background color
const RoundButtonContainer = ({ children }) => {
  const { isTabletOrDesktop } = useMediaQueryInfo();
  return (
    <View
      style={{
        backgroundColor: isTabletOrDesktop ? colors.bg0 : colors.bg1,
        paddingHorizontal: sizes.medium,
        paddingVertical: 5,
      }}>
      {children}
    </View>
  );
};

const buildTextComponent = title => () => <Text style={textStyles.standard.dark}>{title}</Text>;

const buildProfilePropertyComponent = ({ label, value }) => () => (
  <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1, paddingRight: sizes.small }}>
    <Text style={[textStyles.standard.light, { width: 120, paddingRight: sizes.small }]}>
      {label}
    </Text>
    <Text numberOfLines={1} ellipsizeMode="tail" style={[textStyles.standard.dark, { flex: 1 }]}>
      {value}
    </Text>
  </View>
);

const formatHeight = (height, units) => {
  if (units === 'imperial') {
    let inchesString = '';
    let feetString = '';
    const inches = Math.floor(height % 12);
    if (inches) {
      inchesString = `${inches}"`;
    }
    const feet = Math.floor(height / 12);
    if (feet) {
      feetString = `${feet}'` + (inches ? ' ' : '');
    }
    if (!feet && !inches) {
      feetString = `0'`;
    }
    return `${feetString}${inchesString}`;
  }

  return `${Math.floor(height * 2.54)} cm`;
};

/**
 * We return the state with all the props anytime something changes.
 * What is changing doesn't map perfectly to what is in the object (first name, last name vs name),
 * So it's easier this way. I guess. I hate forms...
 */

const EditProfile = observer(
  ({
    user,
    onPressChangePassword,
    onPressSetProfilePhoto,
    onPressDeleteAccount,
    onRequestTestNotification,
    onDoDeveloperModeAction,
    onGetDeveloperModeDebugInfo,
    onPressLogout,
    onPressCoachSettings,
    onPressName,
    onPressEmail,
    onPressGender,
    onPressBirthDate,
    onPressUnits,
    onPressHeight,
    onPressUpdateTrackingConsent,
    devToolsEnabled,
    onSendErrorReportingMessage,
  }) => {
    const branding = useContext(BrandContext);

    const { isTabletOrDesktop } = useMediaQueryInfo();

    const { t } = useTranslation();

    const [badJsx, setBadJsx] = useState(null);

    const sections = [];

    // -- name and email --

    const nameAndPasswordProfileSection = {
      title: '',
      data: [
        () => (
          <RowChevronButton
            onPress={onPressName}
            LabelComponent={buildProfilePropertyComponent({
              label: t('PROFILE:FIELDS:FULL_NAME'),
              value: common.fullName(user),
            })}
          />
        ),
      ],
    };

    // if user has SSO but no email address, that means it was hidden from the SSO payload,
    // so we should not make it visible. (Swift Connect does this)
    if (!(branding.sso.enabled && !user.email) && branding.settings.showEmail) {
      nameAndPasswordProfileSection.data.push(() => (
        <RowChevronButton
          onPress={onPressEmail}
          LabelComponent={buildProfilePropertyComponent({
            label: t('PROFILE:FIELDS:EMAIL'),
            value: user.email,
          })}
        />
      ));
    }

    sections.push(nameAndPasswordProfileSection);

    // -- password --

    const passwordProfileSection = {
      title: '',
      data: [],
    };

    // no change password button if SSO is enabled!
    if (!branding.sso.enabled) {
      passwordProfileSection.data.push(() => (
        <RowChevronButton
          onPress={onPressChangePassword}
          LabelComponent={buildTextComponent(t('PROFILE:BUTTONS:CHANGE_PASSWORD'))}
        />
      ));
    }
    if (branding.settings.showChangeProfilePhoto) {
      passwordProfileSection.data.push(() => (
        <RowChevronButton
          onPress={onPressSetProfilePhoto}
          LabelComponent={buildTextComponent(t('PROFILE:BUTTONS:SET_PROFILE_PHOTO'))}
        />
      ));
    }

    if (branding.locationTracking.enabled) {
      passwordProfileSection.data.push(() => (
        <RowChevronButton
          onPress={onPressUpdateTrackingConsent}
          LabelComponent={buildTextComponent('Update Location Tracking')}
        />
      ));
    }

    sections.push(passwordProfileSection);

    // -- other settings --

    const minorSettingsSection = {
      title: '',
      data: [],
    };
    if (branding.settings.showGender) {
      minorSettingsSection.data.push(() => (
        <RowChevronButton
          onPress={onPressGender}
          LabelComponent={buildProfilePropertyComponent({
            label: t('PROFILE:FIELDS:GENDER'),
            value: user.gender
              ? user.gender === 'm'
                ? t('PROFILE:BUTTONS:MALE')
                : t('PROFILE:BUTTONS:FEMALE')
              : t('PROFILE:BUTTONS:NOT_SPECIFIED_OTHER'),
          })}
        />
      ));
    }
    if (branding.settings.showBirthDate) {
      minorSettingsSection.data.push(() => (
        <RowChevronButton
          onPress={onPressBirthDate}
          LabelComponent={buildProfilePropertyComponent({
            label: t('PROFILE:FIELDS:DATE_OF_BIRTH'),
            value: user.birthday
              ? dates.birthdate(user.birthday)
              : t('PROFILE:BUTTONS:NO_BIRTHDATE_SET'),
          })}
        />
      ));
    }
    if (branding.settings.showUnits) {
      minorSettingsSection.data.push(() => (
        <RowChevronButton
          onPress={onPressUnits}
          LabelComponent={buildProfilePropertyComponent({
            label: t('PROFILE:FIELDS:UNITS'),
            value:
              user.units === 'imperial'
                ? t('PROFILE:BUTTONS:IMPERIAL')
                : t('PROFILE:BUTTONS:METRIC'),
          })}
        />
      ));
    }
    if (branding.settings.showHeight) {
      minorSettingsSection.data.push(() => (
        <RowChevronButton
          onPress={onPressHeight}
          LabelComponent={buildProfilePropertyComponent({
            label: t('PROFILE:FIELDS:HEIGHT'),
            value: formatHeight(user.height, user.units) /* todo formatting */,
          })}
        />
      ));
    }

    sections.push(minorSettingsSection);

    // -- coach --

    sections.push({
      title: '',
      data: [
        () => (
          <RoundButtonContainer>
            <RoundButton
              onPress={onPressCoachSettings}
              title={t('PROFILE:BUTTONS:COACH_SETTINGS')}
            />
          </RoundButtonContainer>
        ),
      ],
    });

    // -- logout --

    sections.push({
      title: '',
      data: [
        () => (
          <RoundButtonContainer>
            <RoundButton onPress={onPressLogout} title={t('SETTINGS:LOG_OUT')} />
          </RoundButtonContainer>
        ),
      ],
    });

    // -- delete --

    sections.push({
      title: '',
      data: [
        () => (
          <RoundButtonContainer>
            <RoundButton
              onPress={onPressDeleteAccount}
              title={t('PROFILE:BUTTONS:DELETE_ACCOUNT')}
              textStyle={{ color: colors.error }}
            />
          </RoundButtonContainer>
        ),
      ],
    });

    // -- dev tools --

    if (devToolsEnabled) {
      if (branding.sync.bluetoothDevicesEnabled) {
        sections.push({
          title: 'Dev Tools: Bluetooth',
          data: [
            () => (
              <RowCenteredButton
                onPress={() => {
                  onSendErrorReportingMessage('Bluetooth diagnostics logs submitted');
                  Alert.alert('Bluetooth logs sent!');
                }}
                title="Send Bluetooth Connection Logs"
              />
            ),
          ],
        });
      }
      sections.push({
        title: 'Dev Tools: Open PDF',
        data: [
          () => (
            <RowCenteredButton
              onPress={async () => {
                const testLink =
                  'https://api.labgreen.nudgedev.com/4/stream/uploads/207?test_uid=42327&test_role=user';
                if (Platform.OS === 'ios') {
                  WebBrowser.openBrowserAsync(testLink);
                } else {
                  await IntentLauncher.startActivityAsync('android.intent.action.VIEW', {
                    type: 'application/pdf',
                    data: testLink,
                  });
                }
              }}
              title="View sample PDF"
            />
          ),
        ],
      });

      sections.push({
        title: 'Dev Tools: Notifications',
        data: [
          () => (
            <RowCenteredButton
              onPress={() =>
                onRequestTestNotification({
                  dataType: 'programCard',
                  notificationType: 'foreground',
                })
              }
              title="Show in-app new program card in 5 seconds"
            />
          ),
          () => (
            <RowCenteredButton
              onPress={() =>
                onRequestTestNotification({
                  dataType: 'programCard',
                  notificationType: 'selected',
                })
              }
              title="Simulate selected new program card notification in 5 seconds"
            />
          ),
          () => (
            <RowCenteredButton
              onPress={() =>
                onRequestTestNotification({ dataType: 'conversation', notificationType: 'inApp' })
              }
              title="Show in-app coach notification in 5 seconds"
            />
          ),
          () => (
            <RowCenteredButton
              onPress={() =>
                onRequestTestNotification({
                  dataType: 'conversation',
                  notificationType: 'selected',
                })
              }
              title="Simulate selected coach notification in 5 seconds"
            />
          ),
          () => (
            <RowCenteredButton
              onPress={() =>
                onRequestTestNotification({ dataType: 'club', notificationType: 'inApp' })
              }
              title="Show in-app group message notification in 5 seconds"
            />
          ),
          () => (
            <RowCenteredButton
              onPress={() =>
                onRequestTestNotification({ dataType: 'club', notificationType: 'selected' })
              }
              title="Simulate selected group message notification in 5 seconds"
            />
          ),
          () => (
            <RowCenteredButton
              onPress={() =>
                onRequestTestNotification({ dataType: 'club', notificationType: 'inAppThread' })
              }
              title="Show in-app group thread reply notification in 5 seconds"
            />
          ),
          () => (
            <RowCenteredButton
              onPress={() =>
                onRequestTestNotification({ dataType: 'club', notificationType: 'selectedThread' })
              }
              title="Simulate selected group thread reply notification in 5 seconds"
            />
          ),
          () => (
            <RowCenteredButton
              onPress={() =>
                onRequestTestNotification({ dataType: 'group', notificationType: 'selectInvalid' })
              }
              title="Simulate selected group message notification for invalid group in 5 seconds (403/404)"
            />
          ),
          () => (
            <Text
              onPress={() => {
                Clipboard.setString(
                  JSON.stringify(onGetDeveloperModeDebugInfo('lastSelectedNotificationPayload'))
                );
              }}
              style={{ marginHorizontal: sizes.medium }}>
              <Text style={{ fontWeight: 'bold' }}>{`Last Selected Notification:\n`}</Text>
              <Text>
                {JSON.stringify(onGetDeveloperModeDebugInfo('lastSelectedNotificationPayload'))}
              </Text>
            </Text>
          ),
          () => (
            <Text
              onPress={() => {
                Clipboard.setString(
                  JSON.stringify(
                    onGetDeveloperModeDebugInfo('lastReceivedInForegroundNotificationPayload')
                  )
                );
              }}
              style={{ marginHorizontal: sizes.medium }}>
              <Text
                style={{ fontWeight: 'bold' }}>{`Last Foreground Received Notification:\n`}</Text>
              <Text>
                {JSON.stringify(
                  onGetDeveloperModeDebugInfo('lastReceivedInForegroundNotificationPayload')
                )}
              </Text>
            </Text>
          ),
        ],
      });

      sections.push({
        title: 'Dev Tools: Sign Up / Onboarding',
        data: [
          () => (
            <RowCenteredButton
              onPress={() => onDoDeveloperModeAction('finishSignup')}
              title="Inititate New Nudge User signup workflow"
            />
          ),
          () => (
            <RowCenteredButton
              onPress={() => onDoDeveloperModeAction('finishSsoSignup')}
              title="Inititate New SSO User signup workflow"
            />
          ),
          () => (
            <RowCenteredButton
              onPress={() => onDoDeveloperModeAction('unAckUserHints')}
              title="Reset user hints"
            />
          ),
        ],
      });

      sections.push({
        title: 'Dev Tools: Info',
        data: [
          () => (
            <RowCenteredButton
              onPress={() => {}}
              title={`Device Year Class: ${Constants.deviceYearClass}`}
            />
          ),
        ],
      });

      sections.push({
        title: 'Dev Tools: Errors',
        data: [
          () => (
            <RowCenteredButton
              onPress={() => {
                ThisDoesntExist.callSomethingOnIt();
              }}
              title={t('Trigger Redbox error')}
              isDestructive
            />
          ),
          () => (
            <>
              <RowCenteredButton
                onPress={() => {
                  setBadJsx('asdfadsfadfjaklfjadlkfj');
                }}
                title={t('Trigger in-app error screen')}
                isDestructive
              />
              {badJsx}
            </>
          ),
        ],
      });
    }

    return (
      <View style={{ flex: 1 }}>
        {user && (
          <SectionList
            renderItem={({ item }) => item()}
            sections={sections}
            keyExtractor={() => uuidv4()}
            initialNumToRender={25}
            renderSectionHeader={({ section }) => (
              <SectionHeader
                title={section.title}
                style={{ backgroundColor: isTabletOrDesktop ? colors.bg0 : colors.bg1 }}
              />
            )}
            ItemSeparatorComponent={SeparatorLine}
            style={[containerStyles.fillAvailableSpace, { backgroundColor: colors.bg1 }]}
            keyboardShouldPersistTaps="always"
            ListHeaderComponent={
              <View
                style={{
                  paddingHorizontal: sizes.medium,
                  paddingVertical: sizes.small,
                  alignItems: 'center',
                  backgroundColor: isTabletOrDesktop ? colors.bg0 : colors.bg1,
                }}>
                <CircleThumbnail
                  source={user.photoSource}
                  diameter={sizes.thumbnail * 2}
                  altTitle={common.fullName(user)}
                />
              </View>
            }
            ListFooterComponent={
              <>
                <AestheticallyPleasingListFooter expandsToBottomOfScreen />
              </>
            }
          />
        )}
      </View>
    );
  }
);

EditProfile.propTypes = {
  user: PropTypes.object,
  onRequestTestNotification: PropTypes.func.isRequired,
  onSendErrorReportingMessage: PropTypes.func.isRequired,
  onGetDeveloperModeDebugInfo: PropTypes.func.isRequired,
  onPressChangePassword: PropTypes.func.isRequired,
  onPressSetProfilePhoto: PropTypes.func.isRequired,
  onPressDeleteAccount: PropTypes.func.isRequired,
  onPressLogout: PropTypes.func.isRequired,
  onPressCoachSettings: PropTypes.func.isRequired,
  devToolsEnabled: PropTypes.bool,
};

EditProfile.defaultProps = {
  // this can briefly be null if the user is logging out, due to data getting cleared out
  user: null,
  devToolsEnabled: false,
};

EditProfile.displayName = 'EditProfile';

export default EditProfile;
