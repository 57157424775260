import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import TimeInput from './TimeInput';
import FieldContainer from './FieldContainer';

@observer
class TimeField extends Component {
  render() {
    const {
      value,
      style,
      onChangeValue,
      canEdit,
      valueStyle,
      accessoryID,
      title,
      minDate,
      maxDate,
      formError,
      isRequired,
      t,
    } = this.props;

    let time;

    if (value) {
      time = value;
    } else {
      time = new Date();
    }

    let validationError;
    if (formError && formError.type === 'invalid') {
      validationError = t('LOG:ENTRY_ERRORS.TIME_MUST_BE_LESS_THAN_24_HOURS');
    }
    if (formError && formError.type === 'overlapping') {
      validationError = t('LOG:ENTRY_ERRORS.TIMES_CANNOT_OVERLAP');
    }
    return (
      <FieldContainer
        style={style}
        title={title}
        validationError={validationError}
        isRequired={isRequired}
        canEdit={canEdit}>
        <TimeInput
          style={[{ paddingHorizontal: 0 }]}
          value={time}
          textStyle={valueStyle}
          onChangeValue={onChangeValue}
          canEdit={canEdit}
          minDate={minDate}
          maxDate={maxDate}
          accessoryID={accessoryID}
          isRequired={isRequired}
        />
      </FieldContainer>
    );
  }
}

TimeField.propTypes = {
  canEdit: PropTypes.bool,
  onChangeValue: PropTypes.func,
  style: PropTypes.any,
  valueStyle: PropTypes.any,
  value: PropTypes.instanceOf(Date),
  accessoryID: PropTypes.string,
  title: PropTypes.string,
};

TimeField.defaultProps = {
  canEdit: false,
  style: null,
  valueStyle: null,
};

export default withTranslation()(TimeField);
