// adds image process indicator and no longer opens weird default fullscreen modal
// but instead implements standard onPress.

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
import { View, Text, TouchableOpacity } from 'react-native';
import { observer } from 'mobx-react/native';
import { sizes, textStyles, colors } from '../../config/styles';
import { VideoConferenceIcon } from '../../config/icons';

const baseBubbleStyle = { borderRadius: sizes.borderRadius, minHeight: 20 };

@observer
class MessageMeetingLink extends Component {
  _onLongPress = () => {
    if (this.props.onLongPress) {
      this.props.onLongPress(this.context, this.props.currentMessage);
    }
  };

  // removed long press for images because there's nothing actionable until you can delete them.
  // set opacity to 1 so it never flashes when you're scrolling over it
  render() {
    const {
      containerStyle,
      bubbleStyle,
      textStyle,
      MeetingIconComponent,
      currentMessage,
      onPress,
      highlightColor,
      clickToStartText,
      expiredText,
      lightTextColor,
      darkTextColor,
    } = this.props;
    const meetingStatus = currentMessage.meeting.status;
    return (
      <View
        style={[
          containerStyle,
          { marginBottom: 1 /* extra margin for space above potential caption */ },
        ]}>
        <TouchableOpacity
          onPress={meetingStatus !== 'finished' && meetingStatus !== 'expired' ? onPress : () => {}}
          activeOpacity={1}
          onLongPress={this._onLongPress}>
          <View
            style={[{ flexDirection: 'row', alignItems: 'center' }, baseBubbleStyle, bubbleStyle]}>
            <VideoConferenceIcon />
            <View>
              <Text
                style={[
                  textStyle,
                  { marginLeft: sizes.small },
                  { color: meetingStatus === 'finished' ? darkTextColor : lightTextColor },
                ]}>
                {currentMessage.meeting.topic.toUpperCase()}
              </Text>
              {meetingStatus === 'finished' ? (
                <Text style={[textStyle, { color: lightTextColor }, { marginLeft: sizes.small }]}>
                  {moment
                    .duration({ seconds: currentMessage.meeting.duration })
                    .humanize()
                    .toUpperCase()}
                </Text>
              ) : meetingStatus === 'expired' ? (
                <Text style={[textStyle, { color: lightTextColor }, { marginLeft: sizes.small }]}>
                  {expiredText.toUpperCase()}
                </Text>
              ) : /* optionally make it null so it will center the topic text */
              /* we use this when creating new meetings to not show a status */
              clickToStartText ? (
                <Text style={[textStyle, { color: highlightColor }, { marginLeft: sizes.small }]}>
                  {clickToStartText.toUpperCase()}
                </Text>
              ) : null}
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

MessageMeetingLink.propTypes = {
  currentMessage: PropTypes.object,
  containerStyle: PropTypes.any,
  bubbleStyle: PropTypes.any,
  imageProps: PropTypes.object,
  textStyle: PropTypes.any,
  MeetingIconComponent: PropTypes.func,
  onPress: PropTypes.func,
  // canned text
  clickToStartText: PropTypes.string,
  expiredText: PropTypes.string,
  // style configuration - these get added to text styles based on meeting status
  highlightColor: PropTypes.string,
  darkTextColor: PropTypes.string,
  lightTextColor: PropTypes.string,
};

MessageMeetingLink.contextTypes = {
  actionSheet: PropTypes.func,
};

MessageMeetingLink.defaultProps = {
  currentMessage: {
    meeting: null,
  },
  containerStyle: {},
  bubbleStyle: {},
  imageProps: {},
  lightboxProps: {},
  textStyle: { fontSize: 20 },
  MeetingIconComponent: null,
  onPress: () => {},
  // canned text
  clickToStartText: 'Click to Join',
  expiredText: 'Expired',
  // style configuration - these get added to text styles based on meeting status
  highlightColor: 'blue',
  darkTextColor: 'black',
  lightTextColor: 'gray',
};

const MyMeetingLink = props => (
  <MessageMeetingLink
    bubbleStyle={{ backgroundColor: colors.bg1, padding: sizes.small }}
    textStyle={[textStyles.small.light, { fontWeight: 'bold' }]}
    darkTextColor={colors.darkText}
    lightTextColor={colors.lightText}
    {...props}
  />
);

export default MyMeetingLink;
