import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useScreenBehaviors } from '/common/navigation';
import { SimpleNavWrapper, NavbarlessScreen } from '/common/components/navigation';
import { HeaderIconButton, HeaderTextButton } from '../../components/navigation';
import { colors, useMediaQueryInfo } from '/common/config/styles';
import { LoadingShadeWrapper } from '../../components/common';
import { CancelIcon } from '/common/config/icons';
import { WebNewLogEntryFooter } from '../../components/tracking';
import { LogEntryForm, LogEntryHeader } from '/common/components/tracking';
import { BrandContext } from '/common/config/branding';
import { withKeyboardAvoidance, ScreenError, useLogEntryBehaviors } from '../common';

const AddLogScreen = inject('rootStore')(
  observer(function({ navigation, rootStore, route }) {
    const { t } = useTranslation();
    const { logStore } = rootStore;
    const branding = useContext(BrandContext);
    const { isWideScreen } = useMediaQueryInfo();

    const { onPressCancel, onPressSave, editingLog, tracker } = useLogEntryBehaviors({
      route,
      navigation,
      logStore: rootStore.logStore,
    });

    // enables screen breadcrumbs
    useScreenBehaviors({});

    const NavWrapper = isWideScreen ? NavbarlessScreen : SimpleNavWrapper;
    return (
      <NavWrapper
        leftButton={
          <HeaderIconButton
            icon={<CancelIcon color={colors.darkText} />}
            onPress={onPressCancel}
            disabled={logStore.saveState.isPending}
          />
        }
        rightButton={
          <HeaderTextButton
            text={t('GENERAL:SAVE')}
            disabled={logStore.saveState.isPending}
            onPress={onPressSave}
            textStyle={
              tracker.palettesId ? { color: branding.trackerColors[tracker.palettesId] } : null
            }
          />
        }>
        <ScreenError
          state={logStore.saveState}
          title={t('LOG:ERRORS:SAVE_FAILED')}
          type="messageBar"
          ignoreFormErrors
        />
        <LoadingShadeWrapper
          style={{ flex: 1, backgroundColor: colors.bg1 }}
          isLoading={logStore.saveState.isPending}>
          {editingLog && (
            <LogEntryForm
              header={<LogEntryHeader tracker={tracker} />}
              date={route.params.date}
              tracker={tracker}
              log={editingLog}
              formErrors={logStore.saveState.formErrors}
              isEditing
              isNew
              webLogEntryFooter={
                <WebNewLogEntryFooter
                  tracker={tracker}
                  onPressCancel={onPressCancel}
                  onPressSave={onPressSave}
                />
              }
            />
          )}
        </LoadingShadeWrapper>
      </NavWrapper>
    );
  })
);

AddLogScreen.propTypes = {
  rootStore: PropTypes.object,
  navigation: PropTypes.object,
};

export default withKeyboardAvoidance(AddLogScreen, { enabledForIos: false });
