import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Text } from 'react-native';
import { observer } from 'mobx-react';
import { colord } from 'colord';
import NudgeTouchable from './NudgeTouchable';
import { textStyles, colors, sizes } from '../config/styles';
import { BrandContext } from '../config/branding';

const emptyFunc = () => {};

/**
 * The big beautiful button that appears on the Login page.
 * We're not using such a button anywhere else, so, until further notice, it is "the" LoginButton.
 * This button can be white or be the brand's color. isDisabled will only show a different color when the
 * lightOnDark theme is chosen.
 * @param {string} title the text that will appear in the button
 * @param {func} onPress the function to be called when the button is pressed
 */
@observer
export default class RoundButton extends Component {
  static contextType = BrandContext;
  render() {
    const {
      title,
      contents,
      onPress,
      buttonStyle,
      isDisabled,
      theme,
      textStyle,
      color,
    } = this.props;
    const branding = this.context;
    const buttonColor = color ? color : branding.highlightedColor;
    const buttonBackgroundColor =
      theme === 'darkOnLight' ? colors.bg0 : isDisabled ? colors.border1 : buttonColor;
    return (
      <NudgeTouchable
        onPress={isDisabled ? emptyFunc : onPress}
        isDebounced
        isTouchableOpacity
        useHoverStyle
        defaultColor={buttonBackgroundColor}
        hoverColor={colord(buttonBackgroundColor)
          .darken(0.025)
          .toHex()}
        style={[
          {
            height: 50,
            backgroundColor: buttonBackgroundColor,
            borderRadius: sizes.borderRadius,
            shadowColor: '#000',
            shadowOffset: {
              width: 0,
              height: 1,
            },
            shadowOpacity: 0.25,
            shadowRadius: 2,
            elevation: 2,
            justifyContent: 'center',
            alignItems: 'center',
          },
          buttonStyle,
        ]}>
        {contents}
        {title && (
          <Text
            style={[
              textStyles.standard.semibold,
              {
                color: theme === 'lightOnDark' ? 'white' : buttonColor,
                paddingHorizontal: sizes.small,
              },
              textStyle,
            ]}>
            {title}
          </Text>
        )}
      </NudgeTouchable>
    );
  }
}

RoundButton.propTypes = {
  onPress: PropTypes.func.isRequired,
  title: PropTypes.string,
  contents: PropTypes.any,
  buttonStyle: PropTypes.any,
  textStyle: PropTypes.any,
  hideBorder: PropTypes.bool,
  isDisabled: PropTypes.bool,
  color: PropTypes.string,
  theme: PropTypes.oneOf(['lightOnDark', 'darkOnLight']),
};

RoundButton.defaultProps = {
  title: null,
  contents: null,
  hideBorder: false,
  isDisabled: false,
  theme: 'darkOnLight',
  textStyle: null,
  color: null,
};
