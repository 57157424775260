import React, { useContext } from 'react';
import { View, Text, Image, Linking } from 'react-native';
import QRCode from 'react-native-qrcode-svg';
import { observer } from 'mobx-react';
import { sizes, textStyles, colors, useMediaQueryInfo } from '/common/config/styles';
import { RoundButton } from '../common';
import { BrandContext } from '../../config/branding';

const isMobileBrowser = () => {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
};

const getDownloadUrl = brandKey => {
  // clients.nudgecoach.com/download
  if (brandKey === 'nudge') {
    return window.location.protocol + '//' + window.location.host + '//download';
  }

  // brand.nudgecoach.com/clients/download
  return window.location.protocol + '//' + window.location.host + '//clients//download';
};

const getAppStoreUrl = branding => {
  if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    return `https://apps.apple.com/app/id/${branding.appStoreId}`;
  }
  return `https://play.google.com/store/apps/details?id=${branding.fastlane.PACKAGE_NAME}`;
};

const DownloadMobileAppHowto = observer(function({ user, onPressContinue }) {
  const branding = useContext(BrandContext);
  const { innerWindowWidth } = useMediaQueryInfo();

  const downloadUrl = getDownloadUrl(branding.brandKey);

  const downloadInstructions = isMobileBrowser() ? (
    <View
      style={{
        backgroundColor: colors.bg1,
        paddingVertical: sizes.large,
        paddingHorizontal: sizes.large,
        borderRadius: sizes.borderRadius,
        alignItems: 'center',
      }}>
      <Image
        style={{
          height: 80,
          width: 80,
          marginHorizontal: sizes.medium,
          borderTopLeftRadius: 17,
          borderTopRightRadius: 17,
          borderBottomRightRadius: 17,
          borderBottomLeftRadius: 17,
          boxShadow: 'rgba(0, 0, 0, 0.25) 0px 2px 5px 0px',
        }}
        source={branding.assets.appIcon}
      />
      <RoundButton
        buttonStyle={{
          marginTop: sizes.large,
          marginHorizontal: sizes.medium,
        }}
        theme="lightOnDark"
        onPress={() => {
          Linking.openURL(getAppStoreUrl(branding));
        }}
        title={`Get the App`}
      />
    </View>
  ) : (
    <View
      style={{
        backgroundColor: colors.bg2,
        paddingVertical: sizes.large,
        paddingHorizontal: sizes.large,
        borderRadius: sizes.borderRadius,
        flexDirection: 'row',
      }}>
      <View style={{ width: 225 }}>
        <QRCode size={225} value={downloadUrl} />
      </View>
      <View style={{ flex: 1, marginLeft: sizes.medium, justifyContent: 'center' }}>
        <Text style={[textStyles.standard.light]}>
          Open the camera app on your phone and point your camera at this screen. Tap the
          notification that appears to be taken directly to the download page.
        </Text>
      </View>
    </View>
  );

  return (
    <View
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        marginHorizontal: sizes.large,
      }}>
      <View style={{ maxWidth: innerWindowWidth }}>
        <Image
          style={{
            height: 80,
            width: 80,
            borderRadius: sizes.borderRadius,
            marginBottom: sizes.large,
          }}
          source={branding.assets.appIcon}
        />
        <View
          style={{
            width: '100%',
            marginBottom: sizes.medium,
          }}
        />
        <Text style={[textStyles.bold.dark, { marginBottom: sizes.small }]}>
          <Text>{`Welcome, ${user.firstName}!`}</Text>
          <Text>{'\n'}</Text>
          <Text>{`Download the ${branding.appName} app.`}</Text>
        </Text>
        <Text style={[textStyles.standard.light, { marginVertical: sizes.medium }]}>
          <Text>{`With the app you'll have access to everything ${branding.appName} has to offer wherever you are and you'll never miss updates thanks to notifications.`}</Text>
        </Text>
        {downloadInstructions}
        <RoundButton
          buttonStyle={{
            marginTop: sizes.large,
            marginBottom: sizes.large,
            width: '50%',
          }}
          theme="darkOnLight"
          onPress={onPressContinue}
          title={`Continue to ${branding.appName}`}
        />
      </View>
    </View>
  );
});

export default DownloadMobileAppHowto;
