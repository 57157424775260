import { StyleSheet } from 'react-native';
import { sizes, colors } from '../../../../config/styles';

export default StyleSheet.create({
  clubTopicThreadMessage: {
    paddingVertical: sizes.small,
    paddingHorizontal: sizes.medium,
    backgroundColor: colors.bg0,
    flex: 1,
  },
  threadMessageText: {},
  threadMessageNameText: {
    paddingRight: sizes.small,
  },
  threadMessageTopLine: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    flex: 1,
  },
  threadCommentsIndicator: {
    paddingTop: sizes.small,
    textAlign: 'right',
  },
  messageThumbnailAndBody: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flex: 1,
  },
  messageBody: {
    paddingLeft: sizes.small,
    flex: 1,
  },
});
