import React from 'react';
import { observer } from 'mobx-react';
import { View, Text, Image } from 'react-native';
import { useTranslation } from 'react-i18next';
import { last } from 'lodash';
import numeral from 'numeral';
import { getLogDisplayComponents, formatLogValueForDisplay } from 'nudge-client-common/trackers';
import { usePersonalization } from '/common/config/personalization';
import { sizes, textStyles, fonts, colors } from '/common/config/styles';
import { shortDateWithShortYear } from '/common/config/strings';
import { RoundButton } from '/common/components';
import { RightChevronIcon } from '/common/config/icons';
import { toDateTime } from 'nudge-client-common/lib/dates';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Wrap a state with a button press (including chevron)
 */
const PressableStatWrapper = ({ onPress, children }) => {
  if (!onPress) {
    return children;
  }
  return (
    <RoundButton
      onPress={onPress}
      contents={
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <View style={{ marginHorizontal: sizes.small }}>{children}</View>
          <View style={{ position: 'absolute', right: -3 }}>
            <RightChevronIcon />
          </View>
        </View>
      }
      buttonStyle={{ height: undefined, alignItems: undefined }}
    />
  );
};

const StandardStat = ({
  value,
  units,
  valuesWithUnits,
  description,
  date,
  color,
  valueStyle,
  hideValue = false,
  hasUpIndicator,
  hasDownIndicator,
  dateFormat,
}) => {
  const myValuesWithUnits = valuesWithUnits || [{ value, units }];
  return (
    <View
      style={{
        flexDirection: 'column',
        alignItems: 'center',
        paddingVertical: sizes.small,
        marginHorizontal: sizes.small,
      }}>
      <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
        {(hasUpIndicator || hasDownIndicator) && (
          <Image
            style={{
              height: 12,
              width: 12,
              alignSelf: 'center',
              margin: 5,
              tintColor: color,
              transform: [{ rotate: hasUpIndicator ? '180deg' : '0deg' }],
            }}
            source={require('../../assets/icons/caret.png')}
          />
        )}
        {!hideValue &&
          myValuesWithUnits.map((vwu, index) => (
            <View style={{ flexDirection: 'row' }} key={index.toString()}>
              <Text
                allowFontScaling={false}
                style={[
                  textStyles.large.bold,
                  {
                    color: vwu.value !== null ? color : colors.lightText,
                    lineHeight: fonts.sizes.large,
                  },
                  valueStyle,
                ]}>
                {vwu.value !== null ? vwu.value : '-' /* null / no value condition */}
              </Text>
              {vwu.value !== null && vwu.units && (
                <Text
                  allowFontScaling={false}
                  style={[textStyles.standard.light, { lineHeight: fonts.sizes.large }]}>
                  {` ${vwu.units}` + (index + 1 === myValuesWithUnits.length ? '' : ' ')}
                </Text>
              )}
            </View>
          ))}
      </View>
      {description && (
        <Text allowFontScaling={false} style={[textStyles.standard.light, { textAlign: 'center' }]}>
          {description}
        </Text>
      )}
      {date && (
        <Text allowFontScaling={false} style={[textStyles.standard.light]}>
          {dateFormat ? toDateTime(date).toFormat(dateFormat) : shortDateWithShortYear(date)}
        </Text>
      )}
    </View>
  );
};

const CaloriesLegendComponent = ({ title, color }) => {
  return (
    <View style={{ marginHorizontal: sizes.small, flexDirection: 'row', alignItems: 'center' }}>
      <View
        style={{
          backgroundColor: color,
          height: fonts.sizes.small,
          width: fonts.sizes.small,
          borderRadius: fonts.sizes.small / 2,
          marginRight: 3,
        }}
      />
      <Text allowFontScaling={false} style={[textStyles.small.light]}>
        {title}
      </Text>
    </View>
  );
};

const TrackerStatGraphFooter = observer(({ tracker, date, style, graphSlice, onPressLog }) => {
  const personalization = usePersonalization();
  const { t } = useTranslation();
  const branding = personalization.branding;

  const components = [];

  // *** add special components ***

  // calories legend
  if (tracker.type === 'calories') {
    components.push(
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <CaloriesLegendComponent
          title="carbs"
          color={branding.trackerColors['graphs-calories-carbs']}
        />
        <CaloriesLegendComponent
          title="fats"
          color={branding.trackerColors['graphs-calories-fats']}
        />
        <CaloriesLegendComponent
          title="proteins"
          color={branding.trackerColors['graphs-calories-proteins']}
        />
      </View>
    );
  }

  // exerbotics stats
  if (tracker.type === 'exerbotics') {
    const baselineLog = last(tracker.logs);
    const baselineDisplayComponents = getLogDisplayComponents({
      log: baselineLog.baseline,
      tracker,
      usePreferredTimeUnit: true,
      overrideRounding: 1,
    });
    components.push(
      <StandardStat
        value={baselineDisplayComponents.value[0].value}
        units={
          baselineDisplayComponents.value[0].units
            ? t(`UNITS:${baselineDisplayComponents.value[0].units}`)
            : null
        }
        color={branding.trackerColors[tracker.palettesId]}
        description="Baseline"
        date={baselineLog.baselineUserTime}
      />
    );
    const stats = tracker.statsFor({
      date,
      interval: 'day',
      aggregateType: 'latestForInterval',
    });
    const strIdx = stats.latestLog ? stats.latestLog.strIdx : null;
    const baselinePercent = ((strIdx - baselineLog.baseline) / baselineLog.baseline) * 100;
    if (strIdx) {
      components.push(
        <StandardStat
          value={baselinePercent > 0 ? numeral(baselinePercent).format('0') : null}
          hideValue={baselinePercent <= 0}
          units={'%'}
          color={branding.trackerColors[tracker.palettesId]}
          description={baselinePercent > 0 ? 'Gain Over Baseline' : 'No\nGain Over Baseline'}
        />
      );
    }
  }

  // finance stats
  if (
    [
      'total-revenue',
      'total-gross-profit',
      'total-gross-profit-margin',
      'net-income',
      'cash-flow',
    ].includes(tracker.type)
  ) {
    const fieldName =
      tracker.type === 'total-revenue'
        ? 'revenue'
        : tracker.type === 'total-gross-profit'
        ? 'grossProfit'
        : tracker.type === 'total-gross-profit-margin'
        ? 'grossProfitMargin'
        : tracker.type === 'net-income'
        ? 'netIncome'
        : 'netCashIncrease';

    const stats = tracker.statsFor({
      date,
      interval: 'day',
      aggregateType: 'latestForInterval',
    });
    if (stats.latestLog) {
      const addlComponents = ['total-gross-profit-margin', 'cash-flow'].includes(tracker.type)
        ? ['Ly']
        : ['Ly', 'Ytd'];
      addlComponents.forEach(c => {
        if (c !== 'Ly' || stats.latestLog[`${fieldName}${c}`] !== null) {
          const value = formatLogValueForDisplay({
            value: stats.latestLog[`${fieldName}${c}`],
            fieldName,
          });
          components.push(
            <StandardStat
              value={value}
              units={tracker.clientMeta.primaryFieldUom === 'percent' ? '%' : null}
              color={branding.trackerColors[tracker.palettesId]}
              description={c === 'Ly' ? 'Previous Year' : 'Year To Date'}
            />
          );
        }
      });
    }
  }

  // weight change stats
  if (tracker.type === 'weight') {
    const sortedPoints = [...graphSlice.points]
      .filter(point => point.value !== null)
      .sort(p => p.date);
    const firstWeightInRange = sortedPoints.length > 0 ? sortedPoints[0].value : 0;
    const lastWeightInRange = graphSlice.latestHistoricalForEndDate.value;
    const weightChange = Math.abs(lastWeightInRange - firstWeightInRange);
    const formattedChange = personalization.userOptions.useMetricUnits
      ? numeral(weightChange * 0.453592).format('0[.]0')
      : numeral(weightChange).format('0[.]0');
    components.push(
      <StandardStat
        hasDownIndicator={lastWeightInRange < firstWeightInRange}
        hasUpIndicator={lastWeightInRange >= firstWeightInRange}
        value={formattedChange}
        units={personalization.userOptions.useMetricUnits ? 'kg' : 'lbs'}
        color={branding.trackerColors[tracker.palettesId]}
        description={`${shortDateWithShortYear(graphSlice.points[0].date)}-`}
        date={graphSlice.points.slice(-1).pop().date}
      />
    );
  }

  // *** add total/ average ***
  if (tracker.canBeTotaled) {
    const logDisplayComponents = getLogDisplayComponents({
      stats: tracker.statsFor({
        date,
        aggregateType: 'sum',
        interval: 'day',
      }),
      tracker,
      usePreferredTimeUnit: true,
      useLongFormForUnits: true,
      useMetricUnits: personalization.userOptions.useMetricUnits,
    });

    components.push(
      <StandardStat
        value={logDisplayComponents.value[0].value}
        description={
          logDisplayComponents.value[0].units
            ? capitalizeFirstLetter(t(`UNITS:${logDisplayComponents.value[0].units}`))
            : null
        }
        date={date}
        color={branding.trackerColors[tracker.palettesId]}
      />
    );

    const average = graphSlice.average;

    if (average) {
      const averageDisplayComponents = getLogDisplayComponents({
        log: average,
        tracker,
        usePreferredTimeUnit: true,
      });
      components.push(
        <StandardStat
          value={averageDisplayComponents.value[0].value}
          description={t('TRENDS:DETAILS:DAILY_AVERAGE')}
          color={branding.trackerColors[tracker.palettesId]}
        />
      );
    }
  } else {
    // *** add latest for those that don't support total ***

    // TODO: wrap latest in button going directly to record
    const stats = tracker.statsFor({
      date,
      interval: 'day',
      aggregateType: 'latestForInterval',
    });

    const logDisplayComponents = getLogDisplayComponents({
      stats,
      tracker,
      usePreferredTimeUnit: true,
      useLongFormForUnits: true,
      fieldAbbreviations: {
        SYSTOLIC: 'systolic',
        DIASTOLIC: 'diastolic',
        MINHEARTRATE: 'min',
        MAXHEARTRATE: 'max',
        AVGHEARTRATE: 'avg',
        HEARTRATE: 'bpm',
      },
      useMetricUnits: personalization.userOptions.useMetricUnits,
    });

    // TODO: this absolutely breaks for heart rate and blood pressure

    if (tracker.clientMeta.primaryField === 'response') {
      components.push(
        <StandardStat
          value={logDisplayComponents.value.length ? logDisplayComponents.value[0].value : null}
          color={branding.trackerColors[tracker.palettesId]}
          valueStyle={textStyles.standard.dark}
          date={date}
        />
      );
    } else if (logDisplayComponents.value.length === 0) {
      components.push(
        <StandardStat
          value={null}
          color={branding.trackerColors[tracker.palettesId]}
          description={t('LOG:BUTTONS:SELECTED')}
          date={date}
        />
      );
    } else {
      components.push(
        <PressableStatWrapper
          onPress={stats.latestLog ? () => onPressLog(stats.latestLog.id) : null}>
          <StandardStat
            valuesWithUnits={logDisplayComponents.value}
            color={branding.trackerColors[tracker.palettesId]}
            description={t('LOG:BUTTONS:SELECTED')}
            date={date}
            dateFormat={tracker.clientMeta.dateFormat}
          />
        </PressableStatWrapper>
      );
    }
  }
  return (
    <View
      style={[
        {
          marginHorizontal: sizes.medium,
        },
        style,
      ]}>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-around',
          width: '100%',
        }}>
        {components.map((c, index) => (
          <View
            style={{
              flex:
                components.length > 2 && index !== 2
                  ? 1 /* make sure selected exerbotics value button is large enough */
                  : undefined,
              justifyContent: 'center',
            }}
            key={index.toString()}>
            {c}
          </View>
        ))}
      </View>
    </View>
  );
});

export default TrackerStatGraphFooter;
