import React, { Component } from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import Touchable from '../NudgeTouchable';
import { RightChevronIcon } from '/common/config/icons';
import { sizes } from '/common/config/styles';

/**
 * A single row with an onPress action and a title.
 */
export default class RowChevronButton extends Component {
  render() {
    const { LabelComponent, onPress, style, ChevronIconComponent, showChevron } = this.props;

    const cellStyle = {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingHorizontal: sizes.medium,
      minHeight: 45.5, // ensures other cells are same size as cell with a toggle
      backgroundColor: '#FFFFFF',
    };

    return (
      <Touchable onPress={onPress} isTouchableOpacity useHoverStyle style={[cellStyle, style]}>
        <LabelComponent />
        {showChevron && (
          <View style={{ justifyContent: 'center' }}>
            <ChevronIconComponent size={18} />
          </View>
        )}
      </Touchable>
    );
  }
}

RowChevronButton.propTypes = {
  LabelComponent: PropTypes.any.isRequired,
  onPress: PropTypes.func.isRequired,
  style: PropTypes.any,
  ChevronIconComponent: PropTypes.any,
  showChevron: PropTypes.bool,
};

RowChevronButton.defaultProps = {
  style: { paddingHorizontal: sizes.medium },
  ChevronIconComponent: RightChevronIcon,
  showChevron: true,
};
