import React, { Component } from 'react';
import { View, Platform } from 'react-native';
import PropTypes from 'prop-types';
import Modal from 'react-native-modal';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { CancelIcon, ConfirmIcon } from '/common/config/icons';
import { BrandContext } from '/common/config/branding';
import RoundButton from './RoundButton';
import 'react-datepicker/dist/react-datepicker.css';

// TODO: this thing is a disaster! Fix!
// TODO: didn't completely remove native code from here yet, just enough to make it build!

export default class DatePickerModal extends Component {
  static contextType = BrandContext;

  constructor() {
    super();
    this.state = {
      isModalVisible: false,
      date: new Date(),
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.date) {
      this.setState({
        date: moment(this.props.date).toDate(),
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.date) {
      this.setState({
        date: moment(nextProps.date).toDate(),
      });
    }
  }

  showModal = async () => {
    this.setState({
      isModalVisible: true,
    });
  };

  _onCancel = () => {
    this.setState({
      isModalVisible: false,
      date: this.props.date ? moment(this.props.date).toDate() : new Date(),
    });
  };

  _onConfirm = () => {
    this.props.onChangeDate(this.state.date);
    this.setState({ isModalVisible: false });
  };

  _onChangeNewAndroidPicker = (event, selectedDate) => {
    const currentDate = selectedDate || this.state.date;
    this.setState({
      date: currentDate,
      isModalVisible: false,
    });
    this.props.onChangeDate(currentDate);
  };

  _onChangeNewWebPicker = selectedDate => {
    const currentDate = selectedDate || this.state.date;
    this.setState({
      date: currentDate,
      //isModalVisible: false,
    });
    //this.props.onChangeDate(currentDate);
  };

  _onChangeNewIosPicker = (event, selectedDate) => {
    this.setState({
      date: selectedDate,
    });
  };

  render() {
    const { minDate, maxDate, mode } = this.props;
    const { isModalVisible, date } = this.state;
    const branding = this.context;

    if (isModalVisible) {
      if (Platform.OS === 'web') {
        let additionalProps = {};
        if (mode === 'datetime') {
          additionalProps.showTimeInput = true;
        } else if (mode === 'time') {
          additionalProps.showTimeSelect = true;
          additionalProps.showTimeSelectOnly = true;
        }
        return (
          <Modal
            isVisible={isModalVisible}
            onBackdropPress={this._onCancel}
            animationIn="fadeIn"
            animationOut="fadeOut"
            animationInTiming={50}
            animationOutTiming={50}
            style={{
              margin: 0,
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <View>
              <DatePicker
                selected={date}
                onChange={this._onChangeNewWebPicker}
                minDate={minDate}
                maxDate={maxDate}
                inline
                {...additionalProps}
              />
              <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                <RoundButton
                  title="Cancel"
                  theme="darkOnLight"
                  buttonStyle={{
                    width: 100,
                  }}
                  darkColor={branding.highlightedColor}
                  onPress={this._onCancel}
                />
                <View style={{ width: 15 }} />
                <RoundButton
                  title="OK"
                  theme="lightOnDark"
                  buttonStyle={{
                    width: 100,
                  }}
                  darkColor={branding.highlightedColor}
                  onPress={this._onConfirm}
                />
              </View>
            </View>
          </Modal>
        );
      }
    }

    // android handled by showModal()
    return null;
  }
}

DatePickerModal.propTypes = {
  date: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  mode: PropTypes.oneOf(['date', 'time', 'datetime']),
  /** If true, use spinners instead of calendars for both iOS and Android */
  usePastFriendlyPickers: PropTypes.bool,
  onChangeDate: PropTypes.func.isRequired,
  iosModalBackgroundColor: PropTypes.any,
  confirmCancelBarStyle: PropTypes.any,
  iosCalendarStyles: PropTypes.any,
  ConfirmButtonComponent: PropTypes.any,
  CancelButtonComponent: PropTypes.any,
  anchor: PropTypes.oneOf(['top', 'bottom']),
};

DatePickerModal.defaultProps = {
  date: null,
  mode: 'datetime',
  // unexpected things can happen if you don't allow past dates, and, say
  // allow date and time to be edited independently.
  // We validate that our times are in range later in the pipeline, generally
  minDate: moment()
    .subtract(1, 'years')
    .toDate(),
  maxDate: moment()
    .add(10, 'years')
    .toDate(),
  iosModalBackgroundColor: 'white',
  confirmCancelBarStyle: { backgroundColor: 'white', padding: 15, borderColor: 'white' },
  usePastFriendlyPickers: false,
  ConfirmButtonComponent: ConfirmIcon,
  CancelButtonComponent: CancelIcon,
  anchor: 'bottom',
};
