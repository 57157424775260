import React, { useContext, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { Platform, View, Keyboard, KeyboardAvoidingView } from 'react-native';
import { BadgeIcon } from '/common/components';
import {
  AlertBarSuppressor,
  useMessagingState,
  useAlert,
  commonNavigationRoutes,
  useActionSheet,
} from '/common/navigation';
import { useTranslation } from 'react-i18next';
import { colors } from '/common/config/styles';
import { NavbarWrapper, HeaderIconButton } from '../../../components/navigation';
import { SocialFeed } from '/common/components/social';
import { navigationRoutes } from '../../../config/constants';
import { BellIcon } from '../../../config/common-icons';
import { BrandContext } from '../../../config/branding';
import {
  ScreenError,
  ScreenFirstLoadWrapper,
  TriggerActionOnUpdateWhenFocused,
  ModerationWorkflow,
  withKeyboardAvoidance,
} from '../../common';

const GroupScreenInner = inject(
  'rootStore',
  'Linking'
)(
  observer(function({
    navigation,
    route,
    hasBackButton,
    tabBarHeight,
    rightNavbarButton,
    socialFeed,
    additionalBottomOffset,
    theme,
    Linking,
    rootStore,
  }) {
    const {
      currentMessage,
      onSendMessage,
      onCurrentMessageTextChanged,
      onChooseImage,
      onRemoveImage,
      onOpenAttachment,
      onLongPressMessage,
    } = useMessagingState({
      getSendableStore: () => ({
        sendMessage: socialFeed.sendComment,
        sendMessageState: socialFeed.sendCommentState,
        deleteMessage: socialFeed.deleteComment,
        deleteMessageState: socialFeed.deleteCommentState,
      }),
      getCurrentUser: () => {
        return rootStore.settingsStore.user;
      },
      capabilities: ['delete'],
      navigation,
      route,
    });

    const { t } = useTranslation();

    const branding = useContext(BrandContext);

    const alert = useAlert();

    const { showActionSheetWithOptions } = useActionSheet();

    const onPressTopic = useCallback(
      comment => {
        if (Platform.OS === 'android') {
          Keyboard.dismiss();
          // there's buffers that don't go away on the group screen if we don't dismiss the keyboard and provide some space here
          setTimeout(() => {
            navigation.navigate(
              navigationRoutes.stacks.main.topic,
              {
                clubId: socialFeed.clubId,
                commentId: comment.id,
              },
              50
            );
          });
        } else {
          navigation.navigate(navigationRoutes.stacks.main.topic, {
            clubId: socialFeed.clubId,
            commentId: comment.id,
          });
        }
      },
      [navigation, socialFeed]
    );

    const onLongPressTopic = useCallback(
      message => {
        if (Platform.OS === 'android') {
          Keyboard.dismiss();
        }

        const myMessage = message.originatingMessage;

        if (myMessage.redacted) {
          return;
        }
        if (message.userId === rootStore.settingsStore.user.id) {
          // normal delete behavior
          onLongPressMessage(message);
        } /* can moderate someone else's message */ else {
          const workflow = new ModerationWorkflow({
            t,
            showActionSheetWithOptions,
            alert,
            Platform,
            Keyboard,
            onFilterTopic: () => {
              socialFeed.loadInitial();
            },
          });
          workflow.startModerationWorkflow({
            message: myMessage,
            parentComment: myMessage, // hack to get it to refresh when the comment is filtered
            socialFeed,
          });
        }
      },
      [t, showActionSheetWithOptions, socialFeed, onLongPressMessage]
    );

    const goToLeaderboard = useCallback(() => {
      navigation.navigate(commonNavigationRoutes.clubs.leaderboard, {
        clubId: socialFeed.clubId,
      });
    }, [navigation, socialFeed]);

    const goToIndividualNotifications = useCallback(() => {
      navigation.navigate(navigationRoutes.modals.individualCommunityNotifications, {
        clubId: socialFeed.clubId,
        communityName: socialFeed.title,
        communityId: socialFeed.clubId,
        communityNotificationLevel: rootStore.socialStore.clubs.find(
          club => club.id === socialFeed.clubId
        ).notificationLevel,
      });
    }, [navigation, socialFeed]);

    const onPressAttachment = useCallback(
      message => {
        onOpenAttachment(message, {
          topicId: message.originatingMessage.id,
          socialFeed,
        });
      },
      [onOpenAttachment, socialFeed]
    );

    const imageModalProps = useMemo(
      () => ({
        extraBottomNavigationButton: {
          title: 'Reply',
          onPress: currentMessage =>
            navigation.navigate(navigationRoutes.stacks.main.topic, {
              clubId: socialFeed.clubId,
              commentId: currentMessage.originatingMessage.id,
            }),
        },
      }),
      [socialFeed, navigation]
    );

    const notificationsButton = (
      <HeaderIconButton
        onPress={goToIndividualNotifications}
        icon={<BellIcon useDarkTheme={theme.useDarkTheme} />}
      />
    );

    // this iOS keyboard handling helps us work around the extra space at the bottom caused by making the tab bar translucent
    // This is an awkward consequence of a text input directly on a tab + translucent tab bars
    // We keep the offset for the tab bar outside of the KAV so they keyboard will go right over it and not too far
    const KeyboardAvoidingViewComponent = Platform.OS === 'ios' ? KeyboardAvoidingView : View;

    return (
      <View style={{ flex: 1 }}>
        <KeyboardAvoidingViewComponent style={{ flex: 1 }} behavior="padding">
          <ScreenError
            state={socialFeed.sendCommentState}
            title={t('COMMUNITY:ERRORS:POST_MESSAGE')}
          />
          <ScreenError
            state={socialFeed.deleteCommentState}
            title={t('COMMUNITY:ERRORS:DELETE_MESSAGE')}
          />
          <ScreenError
            state={socialFeed.loadAdditionalCommentsState}
            title={t('COMMUNITY:ERRORS:LOADING_PREVIOUS_MESSAGES')}
            type="messageBar"
          />
          <TriggerActionOnUpdateWhenFocused
            getWatchValue={() => rootStore.socialStore.latestSocialFeedUpdate}
            triggerWhen={update => update.clubId === socialFeed.clubId}
            onTrigger={socialFeed.loadNewComments}
          />
          <AlertBarSuppressor
            suppressWhen={idPayload =>
              idPayload.clubId === socialFeed.clubId && !idPayload.parentId
            }
          />
          <ScreenFirstLoadWrapper
            state={socialFeed.loadCommentsState}
            hasData={socialFeed.commentsSorted.length}
            onPressRefresh={socialFeed.loadInitial}
            NavbarWrapperComponent={NavbarWrapper}
            navbarWrapperProps={{
              title: socialFeed.title,
              hasBackButton,
              rightButton: rightNavbarButton
                ? rightNavbarButton
                : Platform.OS !== 'web'
                ? notificationsButton
                : null,
              theme,
              navigation,
            }}
            showRefreshFailedErrorOnSubsequentLoads>
            <SocialFeed
              showChooseImageButton={socialFeed.forumsUploadsEnabled}
              leaderboard={socialFeed.hasLeaderboard ? socialFeed.leaderboard : null}
              topics={socialFeed.commentsSorted}
              onPressComment={onPressTopic}
              onPressAttachment={onPressAttachment}
              onLongPressComment={onLongPressTopic}
              onPressLink={Linking.openURL}
              onLoadEarlier={socialFeed.loadAdditionalComments}
              isLoadEarlierEnabled={!socialFeed.isAtEndOfCommentHistory}
              isLoadingEarlier={socialFeed.loadAdditionalCommentsState.isPending}
              isLoadingEarlierFailed={socialFeed.loadAdditionalCommentsState.isFailed}
              onPressChooseImage={onChooseImage}
              onPressRemoveImage={onRemoveImage}
              onPostMessage={onSendMessage}
              currentMessage={currentMessage}
              onCurrentMessageTextChanged={onCurrentMessageTextChanged}
              isOperationPending={
                socialFeed.deleteCommentState.isPending || socialFeed.sendCommentState.isPending
              }
              bottomOffset={tabBarHeight + additionalBottomOffset}
              onPressLeaderboard={goToLeaderboard}
              isLeaderboardJoined={socialFeed.isLeaderboardJoined}
              groupTitle={socialFeed.title}
              imageModalProps={imageModalProps}
              perspective="client"
            />
          </ScreenFirstLoadWrapper>
        </KeyboardAvoidingViewComponent>
      </View>
    );
  })
);

GroupScreenInner.propTypes = {
  navigation: PropTypes.object,
  topicFeed: PropTypes.object,
  hasBackButton: PropTypes.bool.isRequired,
  tabBarHeight: PropTypes.number,
  // any additional offset besides that for the tab bar
  // This is basically for the iPhone X, which needs additional space for the
  // home bar.
  additionalBottomOffset: PropTypes.number,
  theme: PropTypes.object,
  Linking: PropTypes.any,
};

GroupScreenInner.defaultProps = {
  tabBarHeight: 0,
  additionalBottomOffset: 0,
  theme: {},
};

export default withKeyboardAvoidance(GroupScreenInner, { enabledForIos: false });
