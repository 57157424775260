import React from 'react';
import { View, Text, Platform } from 'react-native';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { descriptiveDateWithoutYear } from '../../config/strings';
import { sizes, textStyles } from '/common/config/styles';

const ProgramListSectionHeader = observer(({ title, containerStyle }) => {
  const { t } = useTranslation();
  return (
    <View
      style={[
        {
          marginVertical: sizes.medium,
        },
        containerStyle,
      ]}>
      {Platform.OS === 'web' ? (
        <View style={{ width: '100%' }}>
          <Text
            allowFontScaling={false}
            style={[
              textStyles.standard.dark,
              {
                textAlign: 'center',
              },
            ]}>
            {descriptiveDateWithoutYear(title, { t })}
          </Text>
        </View>
      ) : (
        <View
          style={{
            height: 30,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Text
            allowFontScaling={false}
            style={[
              textStyles.standard.dark,
              {
                fontSize: 18,
                textAlign: 'center',
              },
            ]}>
            {descriptiveDateWithoutYear(title, { t })}
          </Text>
        </View>
      )}
    </View>
  );
});

ProgramListSectionHeader.propTypes = {
  extraTopMargin: PropTypes.number,
};

ProgramListSectionHeader.defaultProps = {
  extraTopMargin: 0,
};

export default ProgramListSectionHeader;
