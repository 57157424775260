import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { View } from 'react-native';
import { uniqBy, sortBy, take } from 'lodash';
import { CircleThumbnail } from '/common/components';
import { sizes } from '../../../../config/styles';
import { fullName } from '../../../../config/strings';

/**
 * The original message for a topic.
 */
const RecentMessagesThumbnailList = observer(({ messages }) => {
  // uniqBy sorting is not predictable, so you need to sort afterward
  const replyThumbnails = take(
    sortBy(
      uniqBy(messages, r => r.author.id),
      m => m.id
    ).reverse(),
    3
  ).map(r => (
    <View key={r.author.id} style={{ marginLeft: sizes.small / 2 }}>
      <CircleThumbnail
        source={r.author.photoUrl}
        diameter={sizes.thumbnail / 2}
        altTitle={fullName(r.author)}
      />
    </View>
  ));

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}>
      {replyThumbnails}
    </View>
  );
});

RecentMessagesThumbnailList.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      userPhotoSource: PropTypes.object,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      userId: PropTypes.any,
    })
  ),
};

export default RecentMessagesThumbnailList;
