import React, { useCallback } from 'react';
import { Platform, Keyboard, View } from 'react-native';
import {
  useScreenBehaviors,
  AlertBarSuppressor,
  useMessagingState,
  useAlert,
  useActionSheet,
} from '/common/navigation/';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { CommentThread } from '/common/components/social';
import { NavbarWrapper } from '../../components/navigation';
import {
  ScreenError,
  ModerationWorkflow,
  withKeyboardAvoidance,
  TriggerActionOnUpdateWhenFocused,
  ScreenFirstLoadWrapper,
} from '../common';

const TopicScreen = inject(
  'rootStore',
  'Linking'
)(
  observer(function({ navigation, route, Linking, rootStore }) {
    const { socialStore } = rootStore;
    const { clubId, commentId } = route.params;
    const socialFeed = socialStore.socialFeedForClub(clubId);
    const parentComment = socialFeed.commentForId(commentId);

    const {
      currentMessage,
      onSendMessage,
      onCurrentMessageTextChanged,
      onChooseImage,
      onRemoveImage,
      onOpenAttachment,
      onLongPressMessage,
    } = useMessagingState({
      getCurrentUser: () => {
        return rootStore.settingsStore.user;
      },
      getSendableStore: () => {
        return {
          sendMessage: async params =>
            await socialFeed.sendComment({ ...params, parentCommentId: commentId }),
          sendMessageState: socialFeed.sendCommentState,
          deleteMessageState: socialFeed.deleteCommentState,
          deleteMessage: async params =>
            await socialFeed.deleteComment({ ...params, parentCommentId: commentId }),
        };
      },
      capabilities: ['delete'],
      navigation,
      route,
    });

    const { t } = useTranslation();

    const { showActionSheetWithOptions } = useActionSheet();

    const alert = useAlert();

    useScreenBehaviors({
      onFocus: () => socialFeed.loadComment(commentId),
      onActive: () => socialFeed.loadComment(commentId),
    });

    const onLongPressTopic = useCallback(
      message => {
        const myMessage = message.originatingMessage;

        if (myMessage.redacted) {
          return;
        }

        if (message.userId === rootStore.settingsStore.user.id) {
          // normal delete behavior
          onLongPressMessage(message);
        } else {
          /* can moderate someone else's message */
          const workflow = new ModerationWorkflow({
            t,
            showActionSheetWithOptions,
            alert,
            Platform,
            Keyboard,
            onFilterTopic: () => {
              // if this topic is filtered, need to close this screen
              navigation.pop();
              setTimeout(() => {
                socialFeed.loadInitial();
              }, 1000);
            },
          });
          workflow.startModerationWorkflow({
            message: myMessage,
            parentComment,
            socialFeed,
          });
        }
      },
      [t, showActionSheetWithOptions, onLongPressMessage, route, navigation]
    );

    return (
      <NavbarWrapper title={socialFeed.title} hasBackButton navigation={navigation}>
        <TriggerActionOnUpdateWhenFocused
          getWatchValue={() => socialStore.latestSocialFeedUpdate}
          triggerWhen={update => update.clubId === socialFeed.clubId}
          onTrigger={() => socialFeed.loadComment(commentId)}
        />
        <AlertBarSuppressor suppressWhen={idPayload => idPayload.parentId === commentId} />
        <ScreenFirstLoadWrapper state={socialFeed.loadCommentState} hasData={!!parentComment}>
          {parentComment ? (
            <View style={{ flex: 1 }}>
              <ScreenError
                state={socialFeed.sendCommentState}
                title={t('COMMUNITY:ERRORS:POST_REPLY')}
              />
              <CommentThread
                showChooseImageButton={socialFeed.forumsUploadsEnabled}
                comment={parentComment}
                replies={
                  socialFeed.repliesSortedFor(commentId) /* use this to weave in pending replies */
                }
                onCurrentMessageTextChanged={onCurrentMessageTextChanged}
                onPressLink={Linking.openURL}
                currentMessage={currentMessage}
                onPostReply={onSendMessage}
                onLongPressComment={onLongPressTopic}
                isOperationPending={
                  socialFeed.deleteCommentState.isPending || socialFeed.sendCommentState.isPending
                }
                onPressAttachment={onOpenAttachment}
                onPressChooseImage={onChooseImage}
                onPressRemoveImage={onRemoveImage}
              />
            </View>
          ) : null}
        </ScreenFirstLoadWrapper>
      </NavbarWrapper>
    );
  })
);

TopicScreen.propTypes = {
  navigation: PropTypes.object,
  rootStore: PropTypes.object,
  Linking: PropTypes.any,
  t: PropTypes.any,
};

export default withKeyboardAvoidance(TopicScreen);
