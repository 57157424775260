import React, { Component, useCallback, useContext } from 'react';
import { Platform, Keyboard } from 'react-native';
import { observer, inject, useLocalStore, MobXProviderContext } from 'mobx-react';
import { toJS } from 'mobx';
import { withTranslation, useTranslation } from 'react-i18next';
import ImagePicker from '../screens/ImagePicker'; // avoid circular ref that we really ought to fix
import Clipboard from '/common/lib/clipboard/';
import { useActionSheet, withActionSheet } from '/common/navigation/useActionSheet';
import commonNavigationRoutes from './commonNavigationRoutes';

const useMessagingState = ({
  // gets a store from props that implements:
  // sendMessage
  // sendMessageState
  // updateMessage
  // updateMessageState
  // deleteMessage
  // deleteMessageState
  getSendableStore = () => {},
  // used to determine if actions can be taken on own message
  getCurrentUser = () => null,
  // certain capabilities are not available as a client
  capabilities = [
    'edit',
    'delete' /* 'deleteAll' - for client group messages from coach perspective */,
    /* 'notifyFlag' - for coach group message, optionally turn on notification */
  ],
  navigation,
  route,
}) => {
  const { rootStore, Linking } = useContext(MobXProviderContext);
  const { t } = useTranslation();
  const { showActionSheetWithOptions } = useActionSheet();
  // current message state
  const currentMessage = useLocalStore(() => ({
    text: '',
    _image: null,
    notifyFlag: false,
    editModeEnabled: false,
    editingMessageId: null,
    setText(newText) {
      this.text = newText;
    },
    get image() {
      return toJS(this._image);
    },
    setImage(newImage) {
      this._image = newImage;
    },
    setNotifyFlag(newNotifyFlag) {
      this.notifyFlag = newNotifyFlag;
    },
    setEditingMessageId(newEditingMessageId) {
      this.editingMessageId = newEditingMessageId;
    },
    setEditModeEnabled(newEditModeEnabled) {
      this.editModeEnabled = newEditModeEnabled;
    },
    reset() {
      this.text = '';
      this._image = null;
      this.notifyFlag = false;
      this.editModeEnabled = false;
      this.editingMessageId = null;
    },
  }));

  // callbacks
  const onSendMessage = useCallback(() => {
    const sendableStore = getSendableStore();
    sendableStore
      .sendMessage({
        text: currentMessage.text,
        image: currentMessage.image,
        notify: currentMessage.notifyFlag,
      })
      .then(() => {
        // clear message text if successful
        if (!sendableStore.sendMessageState.isFailed) {
          currentMessage.reset();
        }
      });
  }, [getSendableStore, currentMessage]);

  const onCurrentMessageTextChanged = useCallback(
    text => {
      currentMessage.setText(text);
    },
    [currentMessage]
  );

  const onChooseImage = useCallback(() => {
    const options = [];
    const optionFns = [];

    if (Platform.OS === 'android') {
      Keyboard.dismiss();
    }

    if (Platform.OS === 'web') {
      ImagePicker.pickImageFromLibrary().then(imageSource => {
        if (imageSource.canceled) {
          return;
        }
        currentMessage.setImage(imageSource.assets[0]);
      });
      return;
    }

    options.push(t('GENERAL:PHOTO_PICKER:CHOOSE_FROM_LIBRARY'));
    optionFns.push(() => {
      // we noticed a bug where the keyboard button would stop working if the keyboard was up
      // after adding an image from the library only
      if (Platform.OS === 'ios') {
        Keyboard.dismiss();
      }
      ImagePicker.pickImageFromLibrary().then(imageSource => {
        if (imageSource.canceled) {
          return;
        }
        currentMessage.setImage(imageSource.assets[0]);
      });
    });

    options.push(t('GENERAL:PHOTO_PICKER:TAKE_PICTURE_WITH_CAMERA'));
    optionFns.push(() => {
      ImagePicker.pickImageFromCamera().then(imageSource => {
        if (imageSource.canceled) {
          return;
        }
        currentMessage.setImage(imageSource.assets[0]);
      });
    });

    if (Object.keys(options).length > 0) {
      options.push(t('GENERAL:CANCEL'));
      optionFns.push(() => {});
    }

    const cancelButtonIndex = 2;

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        showSeparators: false,
      },
      buttonIndex => {
        optionFns[buttonIndex]();
      }
    );
  }, [currentMessage, t, showActionSheetWithOptions]);

  const onRemoveImage = useCallback(() => {
    currentMessage.setImage(null);
  }, [currentMessage]);

  const onOpenAttachment = useCallback(
    message => {
      if (currentMessage.editModeEnabled) {
        // don't allow during editing
        return;
      }

      // file attachment - open in OS viewer
      if (message.hasFileAttachment) {
        rootStore.openShareableFileInOsViewer(message.attachmentSource.id);
        // meeting link - open in browser
      } else if (message.hasMeetingLink) {
        Linking.openURL(message.meeting.joinUrl);
      }
    },
    [currentMessage, navigation, route, rootStore, Linking]
  );

  const onLongPressMessage = useCallback(
    message => {
      if (currentMessage.editModeEnabled) {
        // don't allow long press during editing
        return;
      }
      const currentUser = getCurrentUser();
      const options = [];
      const optionFns = [];
      // delete is always first - if it exists
      let destructiveButtonIndex = null;

      // allow user to delete own message
      if (
        (capabilities.find(c => c === 'delete') && message.userId === currentUser.id) ||
        capabilities.find(c => c === 'deleteAll')
      ) {
        destructiveButtonIndex = 0;
        options.push(t('MESSAGING:BUTTONS:DELETE_MESSAGE'));
        optionFns.push(() => {
          const sendableStore = getSendableStore();
          sendableStore.deleteMessage({ messageId: message.originatingMessage.id });
        });
      }

      if (capabilities.find(c => c === 'edit') && message.userId === currentUser.id) {
        options.push(t('MESSAGING:BUTTONS:EDIT'));
        optionFns.push(() => {
          // different logic for message sent to many
          // (I don't like this prop-name anymore (sounds too visual for something that changes logic),
          // also I just don't like the ChatMessage abstraction in general anymore. We should do a pseudo-interface
          // like the other stuff passed to withMessagingState)
          if (message.showAsStack) {
            navigation.navigate(commonNavigationRoutes.messages.viewMessageDetail, {
              id: message.originatingMessage.messageInstancesId,
              type: 'sent',
            });
          } else {
            currentMessage.setEditModeEnabled(true);
            currentMessage.setEditingMessageId(message.originatingMessage.id);
            currentMessage.setText(message.text);
            currentMessage.setImage(message.attachmentSource);
          }
        });
      }

      if (message.text && message.text !== '') {
        options.push(t('MESSAGING:BUTTONS:COPY_TEXT'));
        optionFns.push(() => {
          Clipboard.setString(message.text);
        });
      }

      if (Object.keys(options).length > 0) {
        options.push(t('GENERAL:CANCEL'));
        optionFns.push(() => {});
      }

      // TODO: stick custom options in the middle

      if (Object.keys(options).length > 0) {
        // cancel is always last
        const cancelButtonIndex = Object.keys(options).length - 1;
        if (Platform.OS === 'android') {
          Keyboard.dismiss();
        }
        showActionSheetWithOptions(
          {
            options,
            cancelButtonIndex,
            destructiveButtonIndex,
            showSeparators: false,
          },
          buttonIndex => {
            optionFns[buttonIndex]();
          }
        );
      }
    },
    [showActionSheetWithOptions, currentMessage, t, capabilities, getCurrentUser]
  );

  const onEditMessage = useCallback(() => {
    const sendableStore = getSendableStore();
    sendableStore
      .updateMessage({
        text: currentMessage.text,
        image: currentMessage.image,
        messageId: currentMessage.editingMessageId,
      })
      .then(() => {
        // clear message text if successful
        if (!sendableStore.updateMessageState.isFailed) {
          currentMessage.reset();
        }
      });
  }, [getSendableStore, currentMessage]);

  const toggleNotifyFlag = useCallback(() => {
    currentMessage.setNotifyFlag(!currentMessage.currentMessageNotifyFlag);
  }, [currentMessage]);

  return {
    currentMessage,
    onCurrentMessageTextChanged,
    onSendMessage,
    onEditMessage,
    onChooseImage,
    onRemoveImage,
    onOpenAttachment,
    onLongPressMessage,
    toggleNotifyFlag,
  };
};

/**
 * HOC for providing common functionality related to keeping state for messaging
 * (image and text-sending, and message posting with associated cleanup) that's common
 * to coach conversations and group message boards.
 * Provides the following props to wrapped component:
 *  currentMessageText,
    currentMessageImage,
    currentMessageNotifyFlag,
    editModeEnabled,
    editingMessageId,
    onSubmitMessage (sends message using SendableStore and clears state if successful),
    onCurrentMessageTextChanged,
    onChooseImage,
    onRemoveImage,
    onOpenAttachment,
    onCancelEditing,
    setNotifyFlag,
    showNotifyFlagToggle,
    
     as well as:
    Linking,
    rootStore,
    showActionSheetWithOptions
    (via injected dependencies)
 */
const withMessagingState = (
  WrappedComponent,
  {
    // gets a store from props that implements:
    // sendMessage
    // sendMessageState
    // updateMessage
    // updateMessageState
    // deleteMessage
    // deleteMessageState
    getSendableStore = () => {},
    // extra attachment functionality when on fullscreen image screen
    extraBottomNavigationButton,
    // used to determine if actions can be taken on own message
    getCurrentUser = () => null,
    // certain capabilities are not available as a client
    capabilities = [
      'edit',
      'delete' /* 'deleteAll' - for client group messages from coach perspective */,
      /* 'notifyFlag' - for coach group message, optionally turn on notification */
    ],
  }
) => {
  @inject('rootStore', 'Linking')
  @observer
  class MessagingStateComponent extends Component {
    constructor() {
      super();
      this.state = {
        currentMessageText: '',
        currentMessageImage: null,
        editModeEnabled: false,
        editingMessageId: null,
        currentMessageNotifyFlag: false,
      };
    }

    _sendMessage = () => {
      const sendableStore = getSendableStore(this.props);
      sendableStore
        .sendMessage({
          text: this.state.currentMessageText,
          image: this.state.currentMessageImage,
          notify: this.state.currentMessageNotifyFlag,
        })
        .then(() => {
          // clear message text if successful
          if (!sendableStore.sendMessageState.isFailed) {
            this.setState({
              currentMessageText: '',
              currentMessageImage: null,
              editModeEnabled: false,
              editingMessageId: null,
              currentMessageNotifyFlag: false,
            });
          }
        });
    };

    _editMessage = () => {
      const sendableStore = getSendableStore(this.props);
      sendableStore
        .updateMessage({
          text: this.state.currentMessageText,
          image: this.state.currentMessageImage,
          messageId: this.state.editingMessageId,
        })
        .then(() => {
          // clear message text if successful
          if (!sendableStore.updateMessageState.isFailed) {
            this.setState({
              currentMessageText: '',
              currentMessageImage: null,
              editModeEnabled: false,
              editingMessageId: null,
              currentMessageNotifyFlag: false,
            });
          }
        });
    };

    _onPressAttachment = (message, extraRouteParams) => {
      if (this.state.editModeEnabled) {
        // don't allow during editing
        return;
      }
      const { navigation, rootStore, Linking, route } = this.props;

      // file attachment - open in OS viewer
      if (message.hasFileAttachment) {
        rootStore.openShareableFileInOsViewer(message.attachmentSource.id);
        // meeting link - open in browser
      } else if (message.hasMeetingLink) {
        Linking.openURL(message.meeting.joinUrl);
      } else {
        // image - open in modal
        navigation.navigate(commonNavigationRoutes.showFullscreenImage, {
          source: message.attachmentSource,
          // extra params can be forwarded to a custom bottom button,
          // which can then be used to replace the route, in the case of replying to message from a full screen image
          ...extraRouteParams,
          ...(route ? route.params : null),
          extraBottomNavigationButton,
          // NOTE: this parameter handling is really ugly, we could do better
          // It's complicated in the first place so we can go to a fullscreen image, and then to a thread, but then back to the main feed (using route replacement)
          // extraBottomNavigationButton is always specified by the screen itself. So, we get the Reply button from the main feed, but do not get it
          // from the thread. Since it's the last parameter, it will never get passed automatically when the modal forwards the route params to the next screen.
          // The modal forwards the params in the first place so the thread screen gets the group/ topic ID's, which are needed to display the thread.
        });
      }
    };

    _onChooseImage = () => {
      const { t } = this.props;
      const options = [];
      const optionFns = [];

      if (Platform.OS === 'android') {
        Keyboard.dismiss();
      }

      if (Platform.OS === 'web') {
        ImagePicker.pickImageFromLibrary().then(imageSource => {
          if (imageSource.canceled) {
            return;
          }
          this.setState({ currentMessageImage: imageSource.assets[0] });
        });
        return;
      }

      options.push(t('GENERAL:PHOTO_PICKER:CHOOSE_FROM_LIBRARY'));
      optionFns.push(() => {
        // we noticed a bug where the keyboard button would stop working if the keyboard was up
        // after adding an image from the library only
        if (Platform.OS === 'ios') {
          Keyboard.dismiss();
        }
        ImagePicker.pickImageFromLibrary().then(imageSource => {
          if (imageSource.canceled) {
            return;
          }
          this.setState({ currentMessageImage: imageSource.assets[0] });
        });
      });

      options.push(t('GENERAL:PHOTO_PICKER:TAKE_PICTURE_WITH_CAMERA'));
      optionFns.push(() => {
        ImagePicker.pickImageFromCamera().then(imageSource => {
          if (imageSource.canceled) {
            return;
          }
          this.setState({ currentMessageImage: imageSource.assets[0] });
        });
      });

      if (Object.keys(options).length > 0) {
        options.push(t('GENERAL:CANCEL'));
        optionFns.push(() => {});
      }

      const cancelButtonIndex = 2;

      this.props.showActionSheetWithOptions(
        {
          options,
          cancelButtonIndex,
          showSeparators: false,
        },
        buttonIndex => {
          optionFns[buttonIndex]();
        }
      );
    };

    _onLongPressMessage = message => {
      if (this.state.editModeEnabled) {
        // don't allow long press during editing
        return;
      }
      const { t, navigation } = this.props;
      const currentUser = getCurrentUser(this.props);
      const options = [];
      const optionFns = [];
      // delete is always first - if it exists
      let destructiveButtonIndex = null;

      // allow user to delete own message
      if (
        (capabilities.find(c => c === 'delete') && message.userId === currentUser.id) ||
        capabilities.find(c => c === 'deleteAll')
      ) {
        destructiveButtonIndex = 0;
        options.push(t('MESSAGING:BUTTONS:DELETE_MESSAGE'));
        optionFns.push(() => {
          const sendableStore = getSendableStore(this.props);
          sendableStore.deleteMessage({ messageId: message.originatingMessage.id });
        });
      }

      if (capabilities.find(c => c === 'edit') && message.userId === currentUser.id) {
        options.push(t('MESSAGING:BUTTONS:EDIT'));
        optionFns.push(() => {
          // different logic for message sent to many
          // (I don't like this prop-name anymore (sounds too visual for something that changes logic),
          // also I just don't like the ChatMessage abstraction in general anymore. We should do a pseudo-interface
          // like the other stuff passed to withMessagingState)
          if (message.showAsStack) {
            navigation.navigate(commonNavigationRoutes.messages.viewMessageDetail, {
              id: message.originatingMessage.messageInstancesId,
              type: 'sent',
            });
          } else {
            this.setState({
              editModeEnabled: true,
              editingMessageId: message.originatingMessage.id,
              currentMessageImage: message.attachmentSource,
              currentMessageText: message.text,
            });
          }
        });
      }

      if (message.text && message.text !== '') {
        options.push(t('MESSAGING:BUTTONS:COPY_TEXT'));
        optionFns.push(() => {
          Clipboard.setString(message.text);
        });
      }

      if (Object.keys(options).length > 0) {
        options.push(t('GENERAL:CANCEL'));
        optionFns.push(() => {});
      }

      // TODO: stick custom options in the middle

      if (Object.keys(options).length > 0) {
        // cancel is always last
        const cancelButtonIndex = Object.keys(options).length - 1;
        if (Platform.OS === 'android') {
          Keyboard.dismiss();
        }
        this.props.showActionSheetWithOptions(
          {
            options,
            cancelButtonIndex,
            destructiveButtonIndex,
            showSeparators: false,
          },
          buttonIndex => {
            optionFns[buttonIndex]();
          }
        );
      }
    };

    _onCurrentMessageTextChanged = text => {
      this.setState({
        currentMessageText: text,
      });
    };

    _onRemoveImage = () => {
      this.setState({ currentMessageImage: null });
    };

    _onCancelEditing = () => {
      this.setState({
        currentMessageText: '',
        currentMessageImage: null,
        editModeEnabled: false,
        editingMessageId: null,
      });
    };

    _toggleNotifyFlag = () => {
      this.setState({ currentMessageNotifyFlag: !this.state.currentMessageNotifyFlag });
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onSubmitMessage={this.state.editModeEnabled ? this._editMessage : this._sendMessage}
          onCurrentMessageTextChanged={this._onCurrentMessageTextChanged}
          onRemoveImage={this._onRemoveImage}
          onChooseImage={this._onChooseImage}
          onOpenAttachment={this._onPressAttachment}
          onCancelEditing={this._onCancelEditing}
          onLongPressMessage={this._onLongPressMessage}
          showNotifyFlagToggle={!!capabilities.find(c => c === 'notifyFlag')}
          toggleNotifyFlag={this._toggleNotifyFlag}
        />
      );
    }
  }

  return withTranslation()(withActionSheet(MessagingStateComponent));
};

export { useMessagingState };

export default withMessagingState;
