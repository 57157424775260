import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { isSameDay } from 'nudge-client-common/lib/dates';
import { observer } from 'mobx-react';
import { Text, View, Platform } from 'react-native';
import { textStyles, colors, sizes } from '/common/config/styles';
import { NudgeTouchable as Touchable } from '/common/components';
import { RightChevronIcon } from '/common/config/icons';
import TrackerValue from './TrackerValue';
import { shortDateWithoutYear } from '/common/config/strings';

const getTrackerStyle = isSelected => ({
  backgroundColor: isSelected ? 'white' : 'transparent',
  paddingLeft: sizes.medium,
  paddingRight: sizes.medium,
  paddingTop: sizes.medium,
  paddingBottom: sizes.medium,
  marginLeft: Platform.OS === 'ios' ? 5 : 0,
  marginRight: Platform.OS === 'ios' ? 5 : 0,
  borderRadius: isSelected && Platform.OS === 'ios' ? 7 : 0,
});

// *** THIS IS ROUGHLY COPIED FROM COACH MOBILE ***
// *** We added onPress events and branding context, which will go back into coach ***

/**
 * The top line of the typical tracker cell. Typically shows the icon, name, and value
 * when it is not focused, then just the icon and name when focused.
 */
@observer
class TrackerSummary extends Component {
  _onPressTracker = () => {
    const { tracker, onPress } = this.props;
    onPress(tracker.id);
  };
  render() {
    const { date, tracker, t, onPress } = this.props;

    const stats = tracker.statsFor({
      date,
      interval: 'day',
      aggregateType: 'latestHistorical',
    });

    const inner = (
      <View>
        <View style={getTrackerStyle(false)}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'space-between',
            }}>
            <View style={{ flex: 1 }}>
              <Text style={[textStyles.standard.semibold, { flex: 1 }]}>
                {t(`LOG:TRACKERS:${tracker.clientMeta.type.toUpperCase()}`, tracker.name)}
              </Text>
              {tracker.clientMeta.showExtraInfoInTitle &&
              tracker.clientMeta.extraInfoInTitle.find(e => e === 'exerbotics') ? (
                <Text
                  style={[textStyles.standard.semibold, { flex: 1, textTransform: 'capitalize' }]}>
                  {tracker.meta.location.name}
                </Text>
              ) : null}
            </View>
            <RightChevronIcon size={20} />
          </View>
          <View
            style={{
              paddingTop: sizes.small / 2,
              flexDirection: 'row',
              alignItems: 'space-between',
            }}>
            <View style={{ flex: 1, marginRight: sizes.medium }}>
              <TrackerValue
                stats={stats}
                tracker={tracker}
                valueTextProps={{
                  numberOfLines: 4,
                  ellipsizeMode: 'tail',
                }}
                usePreferredTimeUnit
              />
            </View>
            {stats.latestLog ? (
              <View style={{ alignSelf: 'flex-start' }}>
                <Text style={[textStyles.small.light]}>
                  {isSameDay(stats.latestLog.date, new Date())
                    ? t('GENERAL:DATES:TODAY')
                    : stats.latestLog.date
                    ? shortDateWithoutYear(stats.latestLog.date)
                    : ''}
                </Text>
              </View>
            ) : null}
          </View>
        </View>
      </View>
    );

    if (onPress) {
      return (
        <Touchable
          style={{ backgroundColor: colors.bg0 }}
          isTouchableOpacity
          useHoverStyle
          onPress={this._onPressTracker}>
          {inner}
        </Touchable>
      );
    }

    return inner;
  }
}

TrackerSummary.propTypes = {
  tracker: PropTypes.object.isRequired,
};

export default withTranslation()(TrackerSummary);
