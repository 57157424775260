// option to use this instead via child dep of Expo.
// Should be hardware level screenshot. Doesn't seem to look any different, though.
// See https://github.com/gre/react-native-view-shot
//import { captureScreen } from "react-native-view-shot";
import { Platform } from 'react-native';
import { getToday } from 'nudge-client-common/lib/dates';
import AlertAsync from 'react-native-alert-async';
import { apiTokenManager } from '../../../stores/api';
import { delay, requestScreenshot } from '../utils';
import { navigationRoutes } from '../../../config/constants';
import { brandingStore, rootStore, getNavigation } from '../../../stores/instances';

async function waitForConfirmStart() {
  await AlertAsync(
    'Ready for screenshots',
    'Screenshots will start 10 seconds after tapping OK. Make sure to wait for all devices to get to this screen before proceeding to avoid weird stuff on screen'
  );
}

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

const cycleWhiteLabels = ({ data, options }) => {
  const myOptions = options || {};
  const storeScreensOnly = myOptions.storeScreensOnly;
  const singleBrandOnly = myOptions.singleBrandOnly;
  const excludeScreens = myOptions.excludeScreens || [];
  const brandKeys = myOptions.brandKeys;

  let brandList = brandKeys.filter(b => b !== 'nudgetest');
  //.filter(b => b === 'well121' || b === 'nourishedas'); // just for testing

  if (storeScreensOnly) {
    // filter out nudge for mass store screenshots - these need to be different
    brandList = brandList.filter(b => b !== 'nudge');
  }

  if (singleBrandOnly) {
    brandList = brandList.filter(b => b === singleBrandOnly);
  }

  const takeSnapshot = async ({ brand, scene }) => {
    try {
      requestScreenshot({ brand, scene });
    } catch (error) {
      console.log('screenshot error');
      console.log(error);
    }
  };

  const standardScreenshotDelay = Platform.OS === 'ios' ? 3000 : 4500;

  const cycleSceneAmongBrands = async ({
    scene,
    switchDelay = 2000,
    onAfterLoadBrand = async () => {},
    shouldTakeScreenshot = () => true,
  }) => {
    await asyncForEach(brandList, async brand => {
      // flush header image stuff out
      await brandingStore.loadBrand('nudge');
      await delay(150);
      await brandingStore.loadBrand(brand);
      console.log(`loaded brand ${brand}`);
      await onAfterLoadBrand();
      await delay(switchDelay);
      if (shouldTakeScreenshot(brand) && !excludeScreens.find(s => s === scene)) {
        await takeSnapshot({ brand, scene });
        await delay(standardScreenshotDelay);
      }
    });
    // flip back to nudge so everything is available
    await brandingStore.loadBrand('nudge');
  };

  // TODO: pause at the beginning and wait for a confirmation to start all emulators at once
  // https://stackoverflow.com/questions/48809762/how-to-await-for-the-response-of-alert-dialog-in-react-native
  // Also TODO: suppress all possible prompts - e.g., notifications stuff, onboarding

  const getTestSpecs = () => {
    return spec => {
      spec.describe(`Take screenshots for all white labels`, function() {
        spec.it('works', async function() {
          await waitForConfirmStart();
          await delay(7000);
          await cycleSceneAmongBrands({ scene: '1-welcome' });
          // weird sign up stuff gotta do it per brand because it could go to a different screen
          if (!storeScreensOnly) {
            await asyncForEach(brandList, async brand => {
              await brandingStore.loadBrand(brand);
              const brandConfig = brandingStore.branding;
              if (!brandConfig.sso.enabled) {
                await spec.press('WelcomeScreen.SignUp');
                await delay(1000);
                await takeSnapshot({ brand, scene: '1.1-signup' });
                await delay(3000);
                if (brandConfig.signUp.inviteIdRequired) {
                  await spec.press('EnterInviteIdScreen.Back');
                } else {
                  await spec.press('CreateAccountScreen.Back');
                }
              }
              await delay(1000);
            });
          }
          // we login as nudge so we dont get any weirdness (like group badges) from whatever the last white label was
          await brandingStore.loadBrand('nudge');
          await delay(150);
          await rootStore.login({ username: 'generictester@nudgecoach.com', password: 'nudge123' });
          // can't get login buttons to work anymore
          /*await spec.press('WelcomeScreen.SignIn');
          await delay(1000);
          await spec.fillIn('LoginForm.Username', 'generictester@nudgecoach.com');
          await delay(500);
          await spec.fillIn('LoginForm.Password', 'nudge123');
          await delay(500);
          await spec.press('LoginForm.Login');*/
          await delay(Platform.OS === 'ios' ? 3000 : 5000 /* slower fade-in on android images */);
          // store screenshots:

          // 1) single program card
          /*(await getNavigation()).navigate(navigationRoutes.stacks.main.programCardDetail, {
            programEventId: rootStore.logStore.programEventsSorted[0].id, // TODO: map screenshot data to special ID
          });*/

          await cycleSceneAmongBrands({
            scene: '2-programs',
            switchDelay: Platform.OS === 'ios' ? 3000 : 4000 /* slower fade-in on android images */,
            onAfterLoadBrand: async () => {
              // clearing here is bad because we depend on the tracker to be loaded!
              // ...actually, probably not
              //rootStore.clearCriticalData();
              rootStore.reloadCriticalData({ flush: true });
            },
          });

          // 2) single tracker
          (await getNavigation()).navigate(navigationRoutes.stacks.main.trackerHistory, {
            trackerId: rootStore.logStore.trackersSorted[0].id, // TODO: map screenshot data to special ID
            date: getToday(),
            timeframe: Platform.isPad ? 'month' : 'week',
          });

          await cycleSceneAmongBrands({
            scene: '3-log',
            onAfterLoadBrand: async () => {
              // clearing here is bad because we depend on the tracker to be loaded!
              //rootStore.clearCriticalData();
              rootStore.reloadCriticalData();
            },
          });

          // all optional screenshots - just for testing
          if (!storeScreensOnly) {
            (await getNavigation()).navigate(navigationRoutes.stacks.main.programsTab);
            await delay(1000);

            (await getNavigation()).navigate(navigationRoutes.stacks.main.logTab);
            await delay(3000);
            await cycleSceneAmongBrands({
              scene: '4.2-log',
              onAfterLoadBrand: async () => {
                rootStore.clearCriticalData();
                rootStore.reloadCriticalData();
              },
            });
            (await getNavigation()).navigate(navigationRoutes.stacks.main.coachTab);
            await cycleSceneAmongBrands({
              scene: '4.3-connect',
              delay: 1000,
              onAfterLoadBrand: async () => {
                await rootStore.conversationsStore.loadInitial({ forceReloadNoCoachPage: true });
              },
            });
            (await getNavigation()).navigate(navigationRoutes.stacks.main.groupsTab);
            await delay(3000);
            // take group screenshots ... if the brand supports groups
            await asyncForEach(brandList, async brand => {
              await brandingStore.loadBrand(brand);
              const brandConfig = brandingStore.branding;
              if (brandConfig.tabs.groups.enabled) {
                await delay(1000);
                await takeSnapshot({ brand, scene: '4.4-groups' });
                await delay(1000);
              }
              await delay(1000);
            });
          }
        });
      });
    };
  };

  apiTokenManager.setApiToken('');

  const allSpecs = [getTestSpecs()];

  return allSpecs;
};

export default cycleWhiteLabels;
