import React, { useContext } from 'react';
import { View, Text } from 'react-native';
import { observer } from 'mobx-react';
import { RoundButton } from '/common/components';
import { FileIcon } from '/common/config/icons';
import { BrandContext } from '/common/config/branding';
import cardConstants from '../cardConstants';

const CardPdfComponent = observer(function({ onPressPdf, component }) {
  const branding = useContext(BrandContext);
  const fileNameWithoutExtension =
    component.body || component.upload.sourceFilename.replace('.pdf', '');
  return (
    <View key={component.id.toString()} style={{ marginBottom: cardConstants.elementSpacing }}>
      <RoundButton
        contents={
          <Text>
            <FileIcon size={20} color={branding.highlightedColor} />
            <Text>{` ${fileNameWithoutExtension}`}</Text>
          </Text>
        }
        onPress={onPressPdf}
      />
    </View>
  );
});

export default CardPdfComponent;
