import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { observer } from 'mobx-react';
import { SeparatorLine } from '../common';
import {
  NudgeSectionList as SectionList,
  AestheticallyPleasingListFooter,
  SectionHeader,
} from '/common/components';
import { containerStyles, colors } from '../../config/common-styles';

/**
 * Common section list used by all of the update property screens
 */
const UpdateProfileItemList = observer(({ sections }) => {
  return (
    <View style={{ flex: 1 }}>
      <SectionList
        renderItem={({ item }) => item()}
        sections={sections}
        keyExtractor={index => index}
        renderSectionHeader={({ section }) => (
          <SectionHeader title={section.title} style={{ backgroundColor: colors.bg0 }} />
        )}
        ItemSeparatorComponent={SeparatorLine}
        style={[containerStyles.fillAvailableSpace, { backgroundColor: colors.bg1 }]}
        keyboardShouldPersistTaps="always"
        ListFooterComponent={AestheticallyPleasingListFooter}
      />
    </View>
  );
});

UpdateProfileItemList.propTypes = {
  sections: PropTypes.array.isRequired,
};

export default UpdateProfileItemList;
