import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import React, { Component, useMemo } from 'react';
import { View } from 'react-native';
import { withTranslation } from 'react-i18next';
import { Chat, ChatMessage } from '../chat';
import ThreadMessage from './components/threads/ThreadMessage';
import { fullName } from '../../config/strings';

@observer
class CommentThreadBubble extends Component {
  render() {
    const props = this.props;

    const { onLongPressMessage, onPressLink } = this.props;

    return (
      <View
        style={{ opacity: props.currentMessage.originatingMessage.isPending ? 0.4 : 1, flex: 1 }}>
        <ThreadMessage
          singleThreadMode
          onPressLink={onPressLink}
          {...props}
          onLongPress={onLongPressMessage}
        />
      </View>
    );
  }
}

class TopicFeedDay extends Component {
  render() {
    return null;
  }
}

const CommentThread = observer(
  ({
    comment,
    replies,
    currentMessage,
    onPostReply,
    onCurrentMessageTextChanged,
    isOperationPending,
    onLongPressComment,
    onPressLink,
    onPressAttachment,
    onPressChooseImage,
    onPressRemoveImage,
    showChooseImageButton,
    t,
    ...rest
  }) => {
    const finalMessages = useMemo(() => {
      let threadMessages = [comment].concat(replies);
      threadMessages = threadMessages.map(
        m =>
          new ChatMessage({
            messageId: m.id.toString(),
            createdAt: m.createdAt,
            text: m.comment,
            userId: m.author.id,
            userName: fullName(m.author),
            userPhotoUrl: m.userPhotoUrl,
            originatingMessage: m,
            attachmentSource: m.attachment,
            isPending: m.isPending,
          })
      );
      return threadMessages;
    }, [comment, replies]);

    return (
      <Chat
        {...rest}
        onLongPressMessage={onLongPressComment}
        onPressLink={onPressLink}
        placeholder={t('COMMUNITY:GROUP_FEED:ADD_A_REPLY')}
        onCurrentMessageTextChanged={onCurrentMessageTextChanged}
        currentMessage={currentMessage}
        messages={finalMessages}
        currentUserId="na"
        inverted={false}
        isLoadEarlierEnabled={false}
        onPressSend={onPostReply}
        isOperationPending={isOperationPending}
        showEmptyListPlaceholder={!finalMessages.length}
        MessageBubbleComponent={CommentThreadBubble}
        DayComponent={TopicFeedDay}
        onPressAttachment={onPressAttachment}
        onPressChooseImage={onPressChooseImage}
        onPressRemoveImage={onPressRemoveImage}
        showChooseImageButton={showChooseImageButton}
        hoverMenuDirection="right"
      />
    );
  }
);

CommentThread.propTypes = {
  currentMessage: PropTypes.object.isRequired,
  onCurrentMessageTextChanged: PropTypes.func.isRequired,
  onPostReply: PropTypes.func.isRequired,
  comment: PropTypes.object.isRequired,
  isOperationPending: PropTypes.bool.isRequired,
  onLongPressComment: PropTypes.func.isRequired,
  onPressLink: PropTypes.func.isRequired,
  t: PropTypes.any.isRequired,
};

export default withTranslation()(CommentThread);
