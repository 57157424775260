export default {
  appleHealth: require('./icon-ahealth.png'),
  mapMyFitness: require('./icon-mmf.png'),
  strava: require('./icon-strava.png'),
  runKeeper: require('./icon-runkeeper.png'),
  sleepAsAndroid: require('./icon-saa.png'),
  fit: require('./icon-gfit.png'),
  withings: require('./icon-withings.png'),
  walgreens: require('./icon-walgreens.png'),
  shealth: require('./icon-shealth.png'),
  quickBooks: require('./icon-quickbooks.png'),
};
