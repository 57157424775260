import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Keyboard } from 'react-native';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { withAlert } from '/common/navigation/';
import { UpdateGender } from '../../../components/settings';
import UpdateSettingsScreenWrapper from './UpdateSettingsScreenWrapper';

@inject('rootStore')
@observer
class UpdateGenderScreen extends Component {
  constructor(props) {
    super(props);
    const { rootStore } = this.props;
    const { settingsStore } = rootStore;
    const { user } = settingsStore;
    this.state = {
      gender: user.gender,
    };
  }

  _onPressSave = async () => {
    Keyboard.dismiss();
    const { gender } = this.state;
    const { rootStore, navigation } = this.props;
    const { settingsStore } = rootStore;
    await settingsStore.updateUserFields({ gender });
    if (settingsStore.updateUserState.isDone) {
      navigation.pop();
    }
  };

  _onChangeGender = gender => {
    this.setState({ gender });
  };

  _onPressBack = () => {
    Keyboard.dismiss();
    this.props.navigation.pop();
  };

  _onPressCancel = () => {
    const { alert, t } = this.props;

    alert(t('PROFILE:EDIT:UNSAVED_CHANGES'), t('PROFILE:EDIT:WHAT_WOULD_YOU_LIKE_TO_DO'), [
      {
        text: t('PROFILE:EDIT:SAVE_CHANGES'),
        onPress: () => {
          this._onPressSave();
        },
        style: 'default',
      },
      {
        text: t('PROFILE:EDIT:DISCARD_CHANGES'),
        onPress: () => {
          this._onPressBack();
        },
        style: 'destructive',
      },
    ]);
  };

  render() {
    const { rootStore, navigation, t } = this.props;
    const { settingsStore } = rootStore;
    const { user } = settingsStore;
    const { gender } = this.state;
    return (
      <UpdateSettingsScreenWrapper
        navigation={navigation}
        isDirty={user.gender !== gender}
        navbarTitle={t('PROFILE:FIELDS:GENDER')}
        updateFailedMessage={t('PROFILE:ERRORS:UPDATE_FAILED_MESSAGE')}
        onPressCancel={this._onPressCancel}
        onPressBack={this._onPressBack}
        onPressSave={this._onPressSave}
        form={<UpdateGender gender={gender} onChangeGender={this._onChangeGender} />}
      />
    );
  }
}

UpdateGenderScreen.propTypes = {
  rootStore: PropTypes.object,
  navigation: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(withAlert(UpdateGenderScreen));
