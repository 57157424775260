import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { UserOptionsContext } from '/common/config/personalization';
import TimeField from './TimeField';
import SourceField from './SourceField';
import MultipleChoiceField from './MultipleChoiceField';
import TextInputField from './TextInputField';
import ActivityField from './ActivityField';
import DividerField from './DividerField';
import ReadOnlyField from './ReadOnlyField';

const FieldWrapper = observer(function({
  tracker,
  log,
  field,
  canEdit,
  isFirstField,
  formErrors,
  disableAutofocus,
}) {
  const userOptions = useContext(UserOptionsContext);
  const onChangeValue = useCallback(value => {
    log.setField({ fieldName: field.modelField, value });
  });
  const { t } = useTranslation();

  let FieldComponent;
  let extraProps = {};
  switch (field.editor) {
    case 'multipleChoice':
      FieldComponent = MultipleChoiceField;
      break;
    case 'number':
    case 'freeform':
      FieldComponent = TextInputField;
      extraProps = { multiline: true };
      break;
    case 'activity':
      FieldComponent = ActivityField;
      break;
    case 'time':
      FieldComponent = TimeField;
      break;
    case 'divider':
      FieldComponent = DividerField;
  }

  return (
    <FieldComponent
      title={field.name}
      placeholder={
        field.isRequired
          ? `${t(`PROGRAMS:TRACKER_PLACEHOLDERS:${field.editor.toUpperCase()}`)}`
          : undefined
      }
      value={log[field.modelField]}
      validator={field.validator}
      editor={field.editor}
      maxLength={field.maxLength}
      decimals={field.decimals}
      zeroAllowed={field.zeroAllowed}
      showUomInline={field.showUomInline}
      inputAccessoryViewID="editingTrackerFooter"
      maxDate={DateTime.fromJSDate(new Date())
        .endOf('day')
        .toJSDate()}
      minDate={field.modelField === 'endTime' ? log.startTime : undefined}
      canEdit={canEdit}
      onChangeValue={onChangeValue}
      uom={
        tracker.units ||
        (userOptions.useMetricUnits && field.uom_metric ? field.uom_metric : field.uom)
      }
      autoFocus={!disableAutofocus && (isFirstField || field.forceAutofocus)}
      options={tracker.options}
      activityOptions={tracker.activityOptions}
      tracker={tracker}
      isRequired={field.isRequired}
      formError={formErrors.find(fe => fe.field === field.modelField)}
      {...extraProps}
    />
  );
});

const LogFields = observer(function({
  tracker,
  style,
  log,
  canEdit,
  formErrors,
  disableAutofocus,
}) {
  const fields = tracker.clientMeta.entryFields;
  let components = [];
  let Component;

  fields.forEach((field, index) => {
    // don't show notes on synced data
    if (field.modelField === 'notes' && log.isSynced) {
      return;
    }
    if (field.hideIfEmpty && !log[field.modelField]) {
      return;
    }
    components.push(
      <FieldWrapper
        key={field.modelField}
        tracker={tracker}
        log={log}
        field={field}
        canEdit={canEdit}
        isFirstField={index === 0}
        formErrors={formErrors}
        disableAutofocus={disableAutofocus}
      />
    );
  });

  if (!components.length) {
    components.push(
      <ReadOnlyField
        key="readonly"
        value="Sorry, we cannot display this tracker right now. Please update your app."
      />
    );
  }

  components.push(<SourceField key={'sourceField'} log={log} />);

  Component = () => <View style={style}>{[components]}</View>;

  return <Component />;
});

export default LogFields;

LogFields.propTypes = {
  tracker: PropTypes.object.isRequired,
};
