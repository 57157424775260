import { keys } from 'lodash';

class ApiTokenManager {
  _defaultHeaders;
  _LocalStorage;
  _apiInstances;
  _overrideToken;
  _componentsRequiringTokenHeaders;

  constructor({
    LocalStorage,
    componentsRequiringTokenHeaders,
    apiInstances,
    defaultHeaders,
    overrideToken,
  }) {
    this._defaultHeaders = defaultHeaders;
    this._LocalStorage = LocalStorage;
    this._overrideToken = overrideToken;
    this._apiInstances = apiInstances;
    this._componentsRequiringTokenHeaders = componentsRequiringTokenHeaders;
  }

  // *** private ***

  _setDefaults = ({ existingHeaders, token, excludedHeaders = [] }) => {
    const headers = keys(this._defaultHeaders);
    headers.forEach(header => {
      if (excludedHeaders.find(h => h === header)) {
        return;
      }
      existingHeaders[header] =
        this._defaultHeaders[header] === '$token' ? token : this._defaultHeaders[header];
    });
  };

  _applyTokenToApis = token => {
    this._apiInstances.forEach(apiInstance => {
      this._setDefaults({ existingHeaders: apiInstance.defaults.headers.common, token });
    });

    this._componentsRequiringTokenHeaders.forEach(component => {
      // hamfisted attempt to work around:
      /*

       You attempted to set the key `X-Requested-With` with the value `"XMLHttpRequest"` on an object that is meant to be immutable and has been frozen.
       at throwOnImmutableMutation (deepFreezeAndThrowOnMutationInDev.js:70)
       at ApiTokenManager.js:32

      */
      const componentHttpHeaders = { ...component.httpHeaders };

      this._setDefaults({
        existingHeaders: componentHttpHeaders,
        token,
        excludedHeaders: ['Accept'],
      });

      component.httpHeaders = componentHttpHeaders;
    });
  };

  // *** public ***

  getStoredApiToken = async () => {
    if (this._overrideToken) {
      return this._overrideToken;
    }
    try {
      const token = await this._LocalStorage.getItemAsync('apiToken');
      if (token) {
        return token;
      }
    } catch (error) {
      return null;
    }
  };

  setApiToken = async token => {
    if (this._overrideToken) {
      this._applyTokenToApis(token);
      return;
    }
    await this._LocalStorage.setItemAsync('apiToken', token);
    this._applyTokenToApis(token);
  };

  initCustomHeaders = () => {
    this._applyTokenToApis('');
  };
}

export default ApiTokenManager;
