// We use SecureStore because AsyncStorage seems buggy (https://github.com/facebook/react-native/issues/18372)
// (we've seen stuff like this before)
// Though I'm not sure if it's really an issue anymore
import AsyncStorage from '@react-native-async-storage/async-storage';

/**
 * Use this for low-priority persistence for performance purposes.
 * If something cannot be retrieved for any reason, it will just return null,
 * indicating that it should be re-hydrated from its original source.
 */

export default class CachingRepository {
  /**
   * Sets the item, returns true if cached, false if not
   *
   * @memberof CachingRepository
   */
  setItem = async (key, value) => {
    try {
      if (value) {
        await AsyncStorage.setItem(key, JSON.stringify(value));
      }
      return true;
    } catch (error) {
      return false;
    }
  };

  /**
   * Gets the item, returns null if it's not found
   *
   * @memberof CachingRepository
   */
  getItem = async key => {
    try {
      const result = await AsyncStorage.getItem(key);
      if (result) {
        return JSON.parse(result);
      }
      return null;
    } catch (error) {
      return null;
    }
  };

  /**
   * Deletes the item, returns true if deleted, false if not
   *
   * @memberof CachingRepository
   */
  deleteItem = async key => {
    try {
      await AsyncStorage.removeItem(key);
      return true;
    } catch (error) {
      return false;
    }
  };
}
