export default {
  GENERAL: {
    SUCCESS: 'הצלחה',
    SAVE: 'שמור',
    EDIT: 'ערוך',
    CANCEL: 'ביטול',
    OK: 'אישור',
    REFRESH: 'רענן',
    TRY_AGAIN: 'נסה שוב',
    LOAD_MORE: 'טען עוד',
    ACCEPT: 'קבל',
    DECLINE: 'דחה',
    PHOTO_PICKER: {
      CHOOSE_FROM_LIBRARY: 'בחר מהספרייה',
      TAKE_PICTURE_WITH_CAMERA: 'צלם תמונה במצלמה',
    },
    ERRORS: {
      APP_FIRST_LOAD_TITLE: 'סליחה, יש בעיה בהתחלת האפליקציה...',
      FIRST_LOAD_TITLE: 'סליחה, יש בעיה...',
      FIRST_LOAD_MESSAGE: 'לא ניתן להתחבר כרגע.',
      PLEASE_REFRESH_ACTION: 'רענן כדי לנסות שוב.',
      LOAD_EARLIER_MESSAGES_FAILED: 'נכשל לטעון הודעות קודמות. בדוק את החיבור שלך ונסה שוב.',
      NETWORK_ERROR_MESSAGE: 'בדוק את חיבור הרשת שלך',
      SERVER_UNAVAILABLE_ERROR_MESSAGE: `מתנצלים על התקלות - אנו עובדים על תחזוקה כרגע. נחזור בקרוב!`,
      ALERT_UNEXPECTED_ERROR_MESSAGE: 'נסה שוב מאוחר יותר.',
      ALERT_UNEXPECTED_ERROR_TITLE: 'סליחה, יש בעיה עם זה...',
      ALERT_ACCESS_REMOVED_ERROR_MESSAGE: 'אין לך יותר גישה למשאב זה. ייתכן שהוסרת מקבוצה.',
      CANNOT_OPEN_FILE: 'שגיאה בפתיחת הקובץ',
    },
    DATES: {
      TODAY: 'היום',
      YESTERDAY: 'אתמול',
    },
  },
  SIGN_UP: {
    INVITE_ID_DISPLAY_NAME: 'מזהה הזמנה',
    INVITE_ID_DISPLAY_SHORTNAME: 'מזהה',
    INVITE_ID_DISPLAY_ARTICLE: 'אחת',
    CLIPBOARD_DISCLAIMER: 'אפליקציה זו אוספת נתוני לוח להפעלת הדבק של {{inviteIdName}} שלך',
    PLEASE_ENTER_INVITE_ID: 'אנא הזן את {{inviteIdName}} שלך',
    PLEASE_ENTER_CLIENT_USER_ID: 'אנא הזן {{clientUserIdName}} שלך אם יש לך אחד.',
    ENTER_YOUR_EMAIL_ADDRESS: 'הזן את כתובת הדוא"ל שלך',
    ID_LIKE_TO: 'אני רוצה ל',
    GREAT: 'נהדר!',
    SECURE_YOUR_ACCOUNT_WITH_A_PASSWORD: 'אנו אישרנו את החשבון שלך. כעת אנא שגור אותו בסיסמה.',
    COMPLETE_YOUR_PROFILE: 'השלם את הפרופיל שלך',
    THIS_WILL_HELP_US_VERIFY_YOUR_RESULTS: 'זה יסייע לנו לאמת את התוצאות שלך',
    CREATE_AN_ACCOUNT: `צור חשבון`,
    CREATE_YOUR_ACCOUNT: `צור את חשבונך.`,
    ENTER_A_NEW_EMAIL_ADDRESS: 'הזן כתובת דוא"ל חדשה.',
    TERMS_OF_SERVICE: {
      DESCRIPTION: 'בהרשמתך ל-{{appName}}, עליך להיות בגיל 13 לפחות ולהסכים ל',
      LINK_NAME: 'תנאי השירות',
    },
    CONFIRMATION_CODE: 'קוד אימות',
    WE_JUST_SENT_AN_EMAIL_TO: 'שלחנו כעת אימייל אל',
    WE_PREVIOUSLY_SENT_AN_EMAIL_TO: 'שלחנו אימייל כבר לפני כן אל',
    CONFIRM_YOUR_ACCOUNT: 'אמת את האימייל שלך.',
    FINISH_SETTING_UP_YOUR_ACCOUNT: 'סיים את הגדרת החשבון שלך',
    USE_THIS_LINK_TO_CONFIRM_YOUR_EMAIL_ADDRESS: 'השתמש בקישור זה כדי לאמת את כתובת הדוא"ל שלך.',
    ERRORS: {
      INVALID_INVITE_ID: `סליחה, לא ניתן למצוא את ה{{inviteIdName}} הזה`,
      INVALID_INVITE_ID_MEMBERSHIP_LIMIT_EXCEEDED: `סליחה, למאמן זה יש כבר הגבלה מספר המנויים.`,
      NETWORK_ERROR: `לא ניתן להתחבר! בדוק את החיבור שלך.`,
      UNKNOWN_ERROR: `סליחה, יש בעיה בהתחברות כרגע.`,
      EMAIL_ALREADY_IN_USE: `כתובת הדוא"ל הזו כבר בשימוש`,
      EMAIL_BLANK: `אנא הזן כתובת דוא"ל`,
      EMAIL_INVALID: `כתובת דוא"ל לא חוקית`,
      NAME_BLANK: `אנא הזן שם`,
      FIRST_NAME_BLANK: `אנא הזן שם פרטי`,
      LAST_NAME_BLANK: `אנא הזן שם משפחה`,
      PASSWORD_NOT_MINIMUM_LENGTH: 'הסיסמה חייבת להיות בעלת 6 תווים או יותר',
      PASSWORD_INVALID: 'סיסמה לא תקפה (עד 30 תווים)',
      BIRTH_DATE_BLANK: 'יש לספק תאריך לידה',
      INVALID_EMAIL_CONFIRMATION_CODE: 'סליחה, קוד אימות הדוא"ל אינו חוקי.',
    },
    BUTTONS: {
      CONTINUE: 'המשך',
      NO_CONTINUE_WITH_SIGN_UP: 'לא, המשך עם ההרשמה.',
      CONINUE_WITH_SELF_GUIDED_PROGRAM: 'המשך עם תוכנית דרכים עצמאית',
      SUBMIT: 'שלח',
      ACCEPT: 'קבל',
      DECLINE: 'דחה',
    },
    REQUEST_EMAIL_CODE_DIALOG: {
      TITLE: 'סיימת!',
      MESSAGE: 'אם יש לך בעיה למצוא את האימייל של האימות שלך, בדוק בתיקיית הזבל / ספאם שלך.',
    },
  },
  ONBOARDING: {
    ERRORS: {
      PUSH_NOTIFICATION_REGISTRATION: 'שגיאה ברישום להתראות דחוסות.',
      VIEW_TUTORIAL: 'סליחה, לא ניתן לפתוח את המדריך כרגע',
    },
    CONTINUE: 'המשך',
  },
  LOGIN: {
    BUTTONS: {
      IM_NEW: 'אני חדש כאן',
      SIGN_IN: 'היכנס',
      NEXT: 'הבא',
      FORGOT_PASSWORD: 'שכחת סיסמה?',
      REQUEST_PASSWORD_RESET: 'אפס סיסמה',
      LOGIN_AGAIN: 'חזרה להתחברות',
    },
    PASSWORD_RESET_CONFIRMATION: 'נשלח לך קישור לאיפוס הסיסמה אל הדוא"ל שלך.',
    PASSWORD_RESET_TROUBLESHOOTING_TIPS_TITLE: 'עצות לפתרון בעיה',
    PASSWORD_RESET_TROUBLESHOOTING_TIPS_DETAIL1: `• האימיילים לעיתים יכולים לקחת מספר דקות עד שהם יופיעו`,
    PASSWORD_RESET_TROUBLESHOOTING_TIPS_DETAIL2: `• בדוק בתיקיית הזבל שלך לאחר מספר דקות`,
    PASSWORD_RESET_TROUBLESHOOTING_TIPS_DETAIL3: `• ודא שאתה בודק בתיקיית הדואר הנכונה (זה קורה 🤷)`,
    HEADERS: {
      CREATE_AN_ACCOUNT: 'צור חשבון',
      SIGN_IN: 'היכנס',
    },
    FIELDS: {
      NAME: 'שם',
      EMAIL: 'דוא"ל',
      PASSWORD: 'סיסמה',
    },
    ERRORS: {
      GENERIC_TITLE: `סליחה, יש בעיה בהתחברות`,
      INCORRECT_USERNAME_PASSWORD: 'האימייל או הסיסמה אינם תואמים למשתמש אחר.',
      SSO: {
        RESPONSE_MALFORMED:
          'התגובה מספקת הכניסה היחידה אינה בפורמט נכון. ייתכן שיש בעיה עם שרת הכניסה. יש ליצור קשר עם תמיכת המוצר.',
        NO_AUTHORIZATION_CODE:
          'התגובה מספקת הכניסה היחידה לא כללה קוד אישור. אם הייתה בעיה במהלך הכניסה, יש לנסות שוב או ליצור קשר עם תמיכת המוצר.',
        UNRECOGNIZED_URL:
          'התגובה מספקת הכניסה היחידה כללה כתובת URL שלא הכרנו. יש ליצור קשר עם תמיכת המוצר.',
        UNEXPECTED:
          'אירעה שגיאה בלתי צפויה במהלך הכניסה. יש לנסות שוב מאוחר יותר או ליצור קשר עם תמיכת המוצר.',
        VALIDIC_USER_NOT_PROVISIONED: 'נראה שאינך רשום ל-{{appName}}.',
      },
    },
  },
  BUTTONS: {
    CANCEL: 'ביטול',
  },
  MESSAGING: {
    ERRORS: {
      SEND_MESSAGE: 'שגיאה בשליחת ההודעה.',
      DELETE_MESSAGE: 'שגיאה במחיקת ההודעה.',
      LOADING_PREVIOUS_MESSAGES: 'שגיאה בטעינת ההודעות הקודמות.',
    },
    BUTTONS: {
      COPY_TEXT: 'העתק טקסט',
      DELETE_MESSAGE: 'מחק הודעה',
    },
    NO_MESSAGES_MESSAGE: {
      LINE1: 'כאן שקט!',
      LINE2: 'למה לא להתחיל שיחה?',
    },
    LOAD_EARLIER_MESSAGES: 'טען הודעות קודמות',
  },
  PROFILE: {
    TITLE: 'פרופיל',
    FIELDS: {
      FULL_NAME: 'שם מלא',
      NAME: 'שם',
      FIRST_NAME: 'שם פרטי',
      LAST_NAME: 'שם משפחה',
      EMAIL: 'אימייל',
      GENDER: 'מגדר',
      DATE_OF_BIRTH: 'תאריך לידה',
      UNITS: 'יחידות',
      HEIGHT: 'גובה',
      PHOTO: 'תמונה',
    },
    BUTTONS: {
      CHANGE_PASSWORD: 'שנה סיסמה',
      SET_PROFILE_PHOTO: 'הגדר תמונת פרופיל',
      ENTER_A_CODE: 'הכנס קוד הזמנה',
      HELP: 'עזרה',
      MALE: 'זכר',
      FEMALE: 'נקבה',
      NOT_SPECIFIED_OTHER: 'לא צוין',
      IMPERIAL: 'אימפריאלי',
      METRIC: 'מטרי',
      LOG_OUT: 'התנתק',
      SYNCED_APPS_NOT_SYNCING: 'חבר יישומונים ומעקב נשמעים',
      SYNCED_APPS_SYNCING: 'יישומונים מחוברים',
      EDIT: 'ערוך',
      SHARE: 'שתף',
      NO_BIRTHDATE_SET: 'בחר...',
      DELETE_ACCOUNT: 'מחק חשבון',
      COACH_SETTINGS: 'הגדרות מאמן',
      SUBMIT: 'שלח',
    },
    LABELS: {
      COMPLETE_YOUR_PROFILE: {
        TITLE: 'השלם את הפרופיל שלך',
        DESCRIPTION: 'נסה לרשום חלק מהפעילויות האהובות עליך ליצירת פרופיל המשקף את סגנונך הפעיל.',
      },
      ACTIVITIES: {
        TITLE: 'פעילויות',
        LATEST: 'אחרונות',
        FAVORITE: 'אהובות',
      },
    },
    ERRORS: {
      UPDATE_FAILED: 'עדכון נכשל',
      EMAIL_UPDATE_FAILED_MESSAGE: 'וודא שהשתמשת בכתובת אימייל חוקית',
      CANNOT_BE_BLANK: 'אין להשאיר ריק',
      EMAIL_ALREADY_USED: 'האימייל כבר בשימוש',
      INVALID_EMAIL: 'אימייל לא חוקי',
      TRACKING_CONSENT_UPDATE_FAILED_MESSAGE: 'העדכון של רשות המעקב שלך נכשל. נסה שוב מאוחר יותר',
    },
    EDIT: {
      UNSAVED_CHANGES: 'שינויים שלא נשמרו',
      WHAT_WOULD_YOU_LIKE_TO_DO: 'מה תרצה לעשות?',
      SAVE_CHANGES: 'שמור שינויים',
      DISCARD_CHANGES: 'סרוק שינויים',
    },
  },
  SYNCED_APPS: {
    TITLE: 'יישומונים מסונכרנים',
    DESCRIPTION: 'לחץ וסנכרן את היישומון המועדף עליך',
    CONFIRMATION:
      '{{appName}} כעת נסנכר עם החשבון שלך ב-{{syncedAppName}}. שים לב שיכול לקחת עד 24 שעות עד שהמידע יופיע במסך היומן של {{appName}}.',
    STOP_APP_CONFIRM_TITLE: 'האם להפסיק לסנכרן את היישומון?',
    STOP_APP_CONFIRM_MESSAGE:
      'האם אתה בטוח שברצונך להפסיק לסנכרן מידע מ-{{syncedAppName}} ל-{{appName}}?',
    NO_KEEP_SYNCING: 'לא, המשך לסנכרן',
    YES_STOP_SYNCING: 'כן, הפסק לסנכרן מ-{{syncedAppName}}',
    CHECK_APPLE_HEALTH_SOURCES:
      'בדוק בלשונית "מקורות" ביישום הבריאות כדי לאפשר סוגי נתונים נוספים לסנכרון.',
  },
  PROGRAMS: {
    EMPTY_FEED: {
      TITLE: 'השלב הבא בתוכנית שלך\nיטענו כאן.',
      MESSAGE: 'חזור בקרוב',
    },
    EMPTY_TAG: {
      TITLE: 'אין כרטיסים כאן כרגע.',
      MESSAGE: 'יתכן שהתג נמחק.',
    },
    ERRORS: {
      REFRESH_FAILED: 'מצטערים, אין אפשרות לרענן כרגע...',
    },
    TRACKER_PLACEHOLDERS: {
      /** These are based on tracker editor */
      NUMBER: 'הזן מספר...',
      DURATION: 'רשום את הזמן שלך...',
      PERCENT: 'הזן את האחוז שלך...',
      BLOODPRESSURE: 'רשום את לחץ הדם שלך...',
      FREEFORM: 'כתוב את תשובתך...',
      MULTIPLECHOICE: 'בחר אחת...',
      HEARTRATE: 'צפה בקצב הלב שלך...',
    },
    SEGMENTED_CONTROL: {
      FEED: 'הזרם',
      TAGS: 'תגיות',
    },
    LOAD_MORE: 'טען עוד',
  },
  LOG: {
    BUTTONS: {
      OPTIONS: 'אפשרויות',
      DETAILS: 'פרטים',
      SELECTED: 'נבחר',
    },
    EMPTY_TRACKER_LIST: {
      TITLE: 'אין מעקבים כרגע',
      MESSAGE: 'אם יש לך מידע מכשיל יחידות, המידע שלך יופיע כאן בקרוב.',
    },
    TRACKERS: {
      CARDIO: 'פעילות גופנית',
      WATER: 'מים',
      PEDOMETER: 'צעדים',
      WEIGHT: 'משקל',
      'BLOOD-PRESSURE': 'לחץ דם',
      'BLOOD-GLUCOSE': 'סוכר בדם',
      CALORIES: 'קלוריות',
      'BODY-FAT': 'אחוז שומן',
      SLEEP: 'שינה',
    },
    ENTRY_ERRORS: {
      MUST_SELECT_ONE: 'יש לבחור לפחות אחד',
      MUST_BE_NUMBER_GREATER_THAN_ZERO: 'יש להזין מספר גדול מ-0',
      MUST_BE_INTEGER_GREATER_THAN_ZERO: 'יש להזין מספר שלם גדול מ-0',
      MUST_BE_NUMBER_GREATER_THAN_OR_EQUAL_TO_ZERO: 'יש להזין מספר שווה או גדול מ-0',
      MUST_BE_VALID_MINUTES: 'יש להזין זמן בין 0 דקות ל-24 שעות',
      MUST_BE_VALID_PERCENT: 'יש להזין אחוז בין 0 ל-100',
      CANNOT_BE_BLANK: 'אין להשאיר ריק',
      TIME_MUST_BE_LESS_THAN_24_HOURS: 'הזמן חייב להיות בין 1 דקות ל-24 שעות',
      TIMES_CANNOT_OVERLAP: 'הזמן אינו יכול להתקלף עם זמן אחר',
    },
    ERRORS: {
      APP_FAILED_TO_SYNC: 'יש לנו בעיה בסנכרון המידע שלך מ-{{syncedAppName}}. יש לנסות מאוחר יותר.',
      APP_FAILED_TO_SYNC_GENERAL: 'יש לנו בעיה בסנכרון המידע שלך. יש לנסות מאוחר יותר.',
      SYNC_UPDATES_ERROR_TITLE: 'יש לנו בעיה בסנכרון העדכונים האחרונים שלך כרגע.',
      SYNC_UPDATES_UNKNOWN_ERROR_MESSAGE: 'יש לנסות שוב.',
      SYNC_UPDATES_NEED_TO_UPDATE_LOG:
        'התוכנית שלך עודכנה. יש לרענן כדי לקבל את העדכונים האחרונים.',
      REFRESH_FAILED: 'מצטערים, אין אפשרות לרענן כרגע...',
      SAVE_FAILED: 'מצטערים, אין אפשרות לשמור כרגע',
    },
    FIELDS: {
      NOTES: 'הערות',
      SYSTOLIC: 'סיסטולי',
      DIASTOLIC: 'דיאסטולי',
      TIME: 'זמן',
      CARBOHYDRATES: 'פחמימות',
      SODIUM: 'נתרן',
      CALORIES: 'קלוריות',
      TOTAL_FAT: 'שומן כולל',
      DIETARY_FIBER: 'סיבים תזונתיים',
      PROTEIN: 'חלבון',
      START_TIME: 'זמן התחלה',
      END_TIME: 'זמן סיום',
      DURATION: 'משך זמן',
      BLOOD_GLUCOSE: 'סוכר בדם',
      ACTIVITY: 'פעילות',
      DISTANCE: 'מרחק',
      SOURCE: 'מקור',
      WRITE_SOMETHING: 'כתוב משהו...',
      SELECT_ONE: 'בחר אחד...',
      HEART_RATE: 'דופק',
      AVERAGE_HEART_RATE: 'דופק ממוצע',
      MINIMUM_HEART_RATE: 'דופק מינימלי',
      MAXIMUM_HEART_RATE: 'דופק מקסימלי',
    },
    FIELD_ABBREVIATIONS: {
      SYSTOLIC: 'סיסט.',
      DIASTOLIC: 'דיאסט.',
      MINHEARTRATE: 'מינ.',
      MAXHEARTRATE: 'מקס.',
      AVGHEARTRATE: 'ממוצע',
      HEARTRATE: 'דופק',
    },
    ENTRY: {
      DELETE: 'מחק',
      CANCEL: 'ביטול',
      DELETE_LOG: 'מחק יומן',
      ARE_YOU_SURE: 'האם אתה בטוח שברצונך למחוק את היומן הזה?',
      UNSAVED_CHANGES: 'יש לך שינויים שלא נשמרו',
      KEEP_EDITING: 'המשך לערוך',
      DISCARD_CHANGES: 'סגור וזרוק את השינויים',
    },
  },
  TRENDS: {
    DETAILS: {
      DAILY_AVERAGE: 'ממוצע יומי',
      DAILY_TOTAL: 'סך הכל יומי',
      DAILY: 'יומי',
      MONTH: 'חודש',
      WEEK: 'שבוע',
      '90DAY': '90 ימים',
      YEAR: 'שנה',
    },
    SUMMARY: {
      NO_RECENT_DATA: 'אין נתונים אחרונים',
      LAST_30_DAYS: '30 הימים האחרונים',
      LAST_7_DAYS: '7 הימים האחרונים',
    },
    ERRORS: {
      LOADING_OF_GRAPH_FAILED: 'הטעינה של הגרף נכשלה. אנא רענן כדי לנסות שוב',
    },
  },
  COMMUNITY: {
    TITLE: 'קהילה',
    LEADERBOARD: {
      TITLE: 'טבלת השוואה',
      JOIN_LEADERBOARD: 'הצטרף לטבלת השוואה',
      JOIN: 'הצטרף',
      LEAVE: 'צא',
      LEAVE_CONFIRMATION_DIALOG_TITLE: 'לצאת מטבלת השוואה?',
      LEAVE_CONFIRMATION_DIALOG_MESSAGE: 'ניתן להצטרף שוב לטבלת השוואה בכל עת',
      JOIN_CONFIRMATION_DIALOG_TITLE: 'הצטרף לטבלת השוואה?',
      JOIN_CONFIRMATION_DIALOG_MESSAGE: 'אנשים בקבוצה זו יוכלו לראות את {{leaderboardType}} שלך',
      LEADERBOARD_RESETS_AT: 'טבלת השוואה איפוס {{date}}',
      RESULTS_RECORDED_ON: 'תוצאות נרשמו בתאריך {{date}}',
      WEEKLY_TOTAL: 'סך הכל שבועי',
      MONTHLY_TOTAL: 'סך הכל חודשי',
      NO_LEADERS: 'היה הראשון להצטרף לטבלת השוואה!',
      CHECK_SOON_FOR_LEADERS: 'תחזור בקרוב לעדכונים בטבלת השוואה!',
      PERSON_LEFT_GROUP: 'האדם הזה עזב את הקבוצה.',
      ACTIVE: 'פעיל',
      PREVIOUS: 'קודם',
    },
    LATEST_POSTS: {
      TITLE: 'פוסטים אחרונים',
      NO_POSTS_SINGLE_DESCRIPTION: 'אין פוסטים כרגע בקהילה הזו. למה לא להתחיל שיחה?',
    },
    BUTTONS: {
      LEAVE: 'צא',
      VIEW_LEADERBOARD: 'צפה בטבלת השוואה',
    },
    ERRORS: {
      POST_MESSAGE: 'שגיאה בפרסום הודעה חדשה.',
      DELETE_MESSAGE: 'שגיאה במחיקת הודעה.',
      POST_REPLY: 'שגיאה בפרסום תגובה.',
      JOIN_LEADERBOARD: 'שגיאה בהצטרפות לטבלת השוואה.',
      LEAVE_LEADERBOARD: 'שגיאה בעזיבת טבלת השוואה',
      LOADING_PREVIOUS_MESSAGES: 'שגיאה בטעינת הודעות קודמות',
    },
    MODERATION_OPTIONS: {
      DELETE: 'מחק',
      HIDE_THIS_MESSAGE: 'הסתר הודעה זו',
      HIDE_ALL_MESSAGES_FROM_USER: 'חסום משתמש זה',
      REPORT_ABUSE: 'דווח על שימוש לרעה',
      ITS_SPAM: 'זה דואר זבל',
      ITS_OFFENSIVE: 'זה מכליל',
      OTHER_ABUSE_OPTION: 'אחר',
      WHY_WAS_THIS_ABUSIVE: 'למה נחשב להודעה זו כמזיקה?',
      HIDE_ALL_MESSAGES: 'חסום משתמש',
      HIDE_ALL_MESSAGES_FROM_USER_TITLE: 'חסום/הסתר את כל ההודעות מאת {{username}}?',
      HIDE_ALL_MESSAGES_FROM_USER_MESSAGE: 'לא תראה עוד הודעות ממשתמש זה',
      HIDE_ALL_MESSAGES_FROM_USER_CONFIRMATION_TITLE: 'אישור',
      HIDE_ALL_MESSAGES_FROM_USER_CONFIRMATION_MESSAGE: 'לא תראה עוד הודעות מאת {{username}}.',
    },
    NO_POSTS_MESSAGE: {
      LINE1: 'ברוך הבא!',
      LINE2: 'למה לא להתחיל שיחה?',
    },
    DIALOGS: {
      JOIN_LEADERBOARD_CONFIRMATION_TITLE: 'הצטרפת!',
      JOIN_LEADERBOARD_CONFIRMATION_MESSAGE: 'עשוי לקחת כמה שעות עד שהנתונים שלך יופיעו.',
    },
    GROUP_FEED: {
      MESSAGE_GROUP: 'שלח הודעה ל{{groupName}}.',
      REPLY: 'הגב',
      REPLIES: 'תגובות',
      ADD_A_REPLY: 'הוסף תגובה',
    },
  },
  TABS: {
    MESSAGES: 'הודעות',
    CONNECT: 'חיבור',
    DAILY: 'יומן יומי',
    TRENDS: 'טרנדים',
    SETTINGS: 'הגדרות',
    COMMUNITY: 'קהילות',
    COACHES: 'מאמנים',
    GROUP_FEED: 'סטרים של קבוצה',
    LOG: 'הנתונים שלי',
    BOTTOM_TABS: {
      PROGRAM: 'תוכנית',
      GROUPS: 'קהילות',
      ACCOUNT: 'חשבון',
      MESSAGES: 'הודעות',
      CONNECT: 'חיבור',
      LOG: 'הנתונים שלי',
    },
  },
  SETTINGS: {
    EDIT_CONNECTED_APPS: 'עריכת יישומים מחוברים',
    CONNECTED_APPS_AND_TRACKERS: 'יישומים ומעקבים מחוברים',
    CONNECT_BLUETOOTH_DEVICES: 'חיבור לתקני Bluetooth',
    ENTER_AN_INVITE_ID: 'הזן {{article}} {{inviteIdName}}',
    ENTER_A_CLIENT_USER_ID: 'הזן {{article}} {{clientUserIdName}}',
    CLIENT_USER_ID_DISPLAY: 'מזהה משתמש של לקוח',
    CLIENT_USER_ID_ARTICLE: 'אחד',
    ENTER_YOUR_PATIENT_ID: 'הזן את מזהה המטופל שלך',
    ENTER_EMAIL_CONFIRMATION_CODE: 'הזן קוד אימות דוא"ל',
    PATIENT_ID: 'מזהה מטופל',
    ENTER_YOUR_ID: 'הזן את {{inviteIdShortName}} שלך',
    PATIENT_ID_ADDED_TITLE: 'הצלחה!',
    PATIENT_ID_ADDED_MESSAGE: 'תוצאות המעבדה יופיעו כעת עבור מזהה המטופל זה כשהן מוכנות',
    HELP: 'עזרה',
    PRIVACY_POLICY: 'מדיניות פרטיות',
    TERMS_OF_SERVICE: 'תנאי השירות',
    LOG_OUT: 'התנתקות',
    LOAD_FAILURE: 'טעינת הגדרות המדריך נכשלה. משיכה למטה לרענון ונסה שוב.',
    RATE_APP: 'דרג את היישום',
    EMAIL_CONFIRMED: 'תודה שאימתת את כתובת הדוא"ל שלך.',
    CONFIRM_EMAIL_ADDRESS: 'אמת את כתובת הדוא"ל שלך',
    DELETE_ACCOUNT: {
      BUTTON_TITLE: 'מחק',
      COMPARATOR: 'מחק',
      DESCRIPTION:
        'פעולה זו תמחק את חשבונך וכל הנתונים הקשורים לו ממערכת שלנו. לא תהיה לך אפשרות להפעיל מחדש את החשבון שלך או לשחזר נתונים שכנסת או סינכרנזת.',
      INSTRUCTIONS: 'אם ברצונך למחוק את החשבון שלך לצמיתות, הקלד DELETE (באותיות גדולות), ואז קשוב',
      ERROR_MESSAGE: 'הקלד DELETE (באותיות גדולות)',
      ERROR_DELETING_ACCOUNT: 'מצטערים, לא ניתן למחוק את החשבון שלך כרגע.',
      CONFIRMATION_TITLE: 'חשבון נמחק',
      CONFIRMATION_MESSAGE: `אנו מוחקים את חשבונך ואת כל הנתונים הקשורים לו ממערכת שלנו. זה יכול לקחות זמן.`,
    },
    ERRORS: {
      SUPPORT_EMAIL_FAILED_TITLE: 'לא ניתן לפתוח דוא"ל לבקשת תמיכה',
      SUPPORT_EMAIL_FAILED_MESSAGE: 'יש לוודא שיש לך חשבון דוא"ל מופעל על המכשיר שלך.',
      ADD_INVITE_ID_FAILED_TITLE: 'לא ניתן להוסיף {{inviteIdName}}',
      ADD_PATIENT_ID_FAILED_TITLE: 'לא ניתן להוסיף מזהה מטופל',
      INVALID_PATIENT_ID_MESSAGE: 'מזהה מטופל, שם ותאריך לידה אינם תואמים להזמנה לבדיקה קיימת.',
      ENTER_EMAIL_CONFIRMATION_CODE_FAILED_TITLE: 'אימות דוא"ל נכשל',
      CURRENT_PASSWORD_NOT_CORRECT: 'הסיסמה הנוכחית אינה נכונה',
      NEW_PASSWORD_DOES_NOT_MATCH: 'הסיסמה החדשה והאימות אינם תואמים',
    },
    BLUETOOTH_LOCATION_PERMISSION_PROMPT: {
      PRIVACY_POLICY_URL_DESCRIPTION: 'ראה מדיניות פרטיות',
      TITLE: 'לאפשר גישה למיקום?',
      EXPLANATION:
        'נדרש גישה למיקום כדי ש-{{appName}} יוכל למצוא את ההתקן ה-Bluetooth שלך ולהישאר מחובר אליו. אנו אינו אוספים או שומרים מידע אודות מיקומך.', // טקסט ג'נרי שמופיע תמיד בראש מסך ההסכמה למיקום כדי לספק הסבר על הגישה למיקום
      SETTINGS_INFO: {
        IOS:
          "ניתן לעדכן את ההרשאות למיקום בהגדרות האפליקציה שלך. לחווייה הטובה ביותר, הקפד להגדיר גישה למיקום כמו 'תמיד'.",
        ANDROID:
          "ניתן לעדכן את ההרשאות למיקום בהגדרות האפליקציה שלך. לחווייה הטובה ביותר, הקפד להגדיר גישה למיקום כמו 'לאפשר תמיד'.",
      }, // משהו שמופיע ממעל לכפתור המעבר להגדרות. אנו מציגים את הכפתור כל עוד השאלה אודות המיקום נענתה כבר, כך שהמשתמש יכול לאשר הרשאות שסרב להן או לכבות הרשאות שאישר
      ACCEPTED: 'גישה למיקום מופעלת. אתה מוכן!', // אנו מציגים את זה מתחת להסבר כדי לציין שהמשתמש אישר הרשאות ואין צורך לעשות דבר נוסף
      REJECTED: 'גישה למיקום אינה מופעלת. יש להפעיל אותה כדי להתחבר להתקני Bluetooth.', // אנו מציגים זאת אם הם סרבו לכל/חלק מההרשאות למיקום, ממעל לכפתור ההגדרות שבאמצעותו הם יכולים לתקן אותם
      REJECTED_ALWAYS:
        'גישה למיקום רקע אינה מופעלת. זה נדרש כדי לקרוא נתונים מהתקני Bluetooth שלך כאשר האפליקציה סגורה.', // אנו מציגים זאת רק ב- iOS, אם המשתמש אישר 'כל עוד האפליקציה פתוחה' אך סירב 'תמיד'. שים לב שהאישור הראשוני הוא תמיד תמיד זמני, ואז המשתמש מתבקש להמשיך למעקב ברקע מאוחר יותר. אך המשתמש לא יראה הודעה זו עד שיסרב למעקב תמיד
      ANDROID_11_BACKGROUND_LOCATION_PROMPT: "אפשר 'תמיד' למיקום כדי להשלים את ההגדרות.", // זה מופיע בהצגת ההרשאות למיקום שנדרשות על ידי המערכת
      NOT_ANSWERED: '',
    },
    NOTIFICATIONS: {
      NOTIFICATIONS: 'התראות',
      COMMUNITIES: 'קהילות',
      NO_COMMUNITIES: 'אתה לא חבר באף קהילה',
      NOTIFICATION_SETTING: 'הגדרות התראות',
      COMMUNITY: 'קהילה',
      ALL_POSTS: 'כל הפוסטים',
      ADMIN_POSTS_REPLIES_THREADS: 'פוסטים של מנהל וכל התגובות באשכולים שהשתתפתי בהם',
      ADMIN_POSTS_REPLIES: 'פוסטים של מנהל ותגובות לפוסטים שלי',
      ADMIN_POSTS: 'פוסטים של מנהל בלבד',
      TURN_OFF: 'כבה התראות',
    },
    OTA: {
      NEW_VERSION_AVAILIBLE: 'גרסה חדשה של האפליקציה זמינה.',
      UPGRADE_TO_LATEST: 'שדרג לגרסה האחרונה',
      NEW_VERSION_AVAILIBLE_TRY_AGAIN:
        'גרסה חדשה של האפליקציה זמינה, אך אנו לא יכולים להוריד אותה כרגע. אל דאגה, ננסה שוב מאוחר יותר.',
      NETWORK_MAY_BE_BLOCKING:
        'אם אתה רואה את זה בתדירות, יתכן שהרשת שלך מונעת עדכונים. החלפה לרשת WiFi שונה עשויה לעזור.',
      ERROR_MESSAGE: 'הודעת שגיאה:',
    },
  },
  UNITS: {
    SVG: 'svg',
    SVGS: 'svgs',
    MINUTE: 'דקה',
    MINUTES: 'דקות',
    HOUR: 'שעה',
    HOURS: 'שעות',
    SYSTOLIC: 'סיסטולית',
    DIASTOLIC: 'דיאסטולית',
    MINHEARTRATE: 'מינימום',
    MAXHEARTRATE: 'מקסימום',
    AVGHEARTRATE: 'ממוצע',
  },
};
