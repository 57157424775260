import { types, flow, getEnv } from 'mobx-state-tree';
import { merge } from 'lodash';
import { LoadingState } from '../common';
import getBrandingDefaults from '/common/config/branding/getBrandingDefaults';
import getOtaBrandOverrides from '/common/config/branding/getOtaBrandOverrides';

/**
 * gets actual brand-specific URL from "default" nudge-branded URL.
 * Can now be overridden with an entirely custom URL.
 */
const getApiUrl = ({ defaultUrl, subDomain, customApiUrl }) => {
  if (customApiUrl) {
    return customApiUrl;
  }
  if (subDomain === 'nudge') {
    return defaultUrl;
  }
  // NOTE: labgreen this doesn't work for all white labels.
  // Some of them are different formats
  if (defaultUrl === 'https://api.labgreen.nudgedev.com') {
    return `https://${subDomain}api.labgreen.nudgedev.com`;
  }
  if (defaultUrl === 'https://api.labblue.nudgedev.com') {
    return `https://${subDomain}api.labblue.nudgedev.com`;
  }
  if (defaultUrl === 'https://api.labpurple.nudgedev.com') {
    return `https://${subDomain}api.labpurple.nudgedev.com`;
  }
  if (defaultUrl === 'https://api.nudgeyourself.com') {
    return `https://${subDomain}api.nudgeyourself.com`;
  }
  return defaultUrl;
};

const mergeBrandBundleWithDefaultBranding = ({ brandProps, brandAssets, getOtaBrandOverrides }) => {
  let brandingObj = getBrandingDefaults();

  // add bundle config - not present in default brand props
  brandingObj.bundleConfig = {
    id: brandProps.id, // bundle ID
    subDomain: brandProps.subDomain,
    brandKey: brandProps.brandKey,
  };

  // perform deep merge, with custom props taking precedence
  brandingObj = merge({}, brandingObj, brandProps);

  // perform overrides for legacy properties
  // required props
  brandingObj.appName = brandProps.appStoreName;
  brandingObj.highlightedColor = brandProps.brandColor;
  brandingObj.appStoreId = brandProps.appStoreId;

  // optional legacy properties
  if (brandProps.messagesTabName) {
    brandingObj.tabs.messages.name = brandProps.messagesTabName;
  }
  if (brandProps.connectTabName) {
    brandingObj.tabs.connect.name = brandProps.connectTabName;
  }
  if (brandProps.inviteId) {
    brandingObj.inviteIdDisplay = brandProps.inviteId;
  }
  if (brandProps.signUpPromptWhenInviteIdIsNotRequired) {
    brandingObj.signUp.nonRequiredInviteIdPrompt = brandProps.signUpPromptWhenInviteIdIsNotRequired;
  }
  if (brandProps.inviteIdRequiredAtSignUp !== undefined) {
    brandingObj.signUp.inviteIdRequired = brandProps.inviteIdRequiredAtSignUp;
  }
  if (brandProps.showInviteIdAtSignup !== undefined) {
    brandingObj.signUp.showInviteIdStep = brandProps.showInviteIdAtSignup;
  }
  // set sync object based off of legacy props
  if (brandProps.showConnectedApps !== undefined) {
    if (brandProps.showConnectedApps) {
      // ensures new props are added
      brandingObj.sync = merge({}, brandingObj.sync, {
        appsEnabled: true,
        //Default for legacy apps - just enable fitness apps
        fitnessAppsEnabled: true,
        businessAppsEnabled: false,
        bluetoothDevicesEnabled: false,
        showUserHint: true,
      });
      brandingObj.signUp.showConnectedAppsStep = true;
    } else {
      // ensures new props are added
      brandingObj.sync = merge({}, brandingObj.sync, {
        appsEnabled: false,
        fitnessAppsEnabled: false,
        businessAppsEnabled: false,
        bluetoothDevicesEnabled: false,
        showUserHint: false,
      });
      brandingObj.signUp.showConnectedAppsStep = false;
    }
  }

  // apply tracker color overrides
  // 1) apply default colors (already done by using default object)
  // 2) apply default overrides (change nudge blue to brand color)
  brandingObj.trackerColors['trackers-custom-002'] = brandingObj.highlightedColor;
  brandingObj.trackerColors['trackers-standard-pedometer'] = brandingObj.highlightedColor;
  // 3) if custom tracker colors exist, add those in
  brandingObj.trackerColors = brandProps.customTrackerColors
    ? { ...brandingObj.trackerColors, ...brandProps.customTrackerColors }
    : brandingObj.trackerColors;

  // apply assets (supports club thumb, welcome screen, and logo so far)
  if (brandAssets.welcomeScreen) {
    brandingObj.assets.welcomeScreen = { uri: brandAssets.welcomeScreen };
  }
  if (brandAssets.logo) {
    brandingObj.assets.logo = { uri: brandAssets.logo };
  }
  if (brandAssets.clubThumbnail) {
    brandingObj.assets.clubThumb = { uri: brandAssets.clubThumbnail };
  }
  if (brandAssets.connectTabIcon) {
    brandingObj.tabs.connect.image = { uri: brandAssets.connectTabIcon };
  }
  if (brandAssets.appIcon) {
    brandingObj.assets.appIcon = { uri: brandAssets.appIcon };
  }

  // screenshot program card feature images
  if (brandAssets.todayCard) {
    brandingObj.assets.todayCard = { uri: brandAssets.todayCard };
  }
  if (brandAssets.yesterdayCard) {
    brandingObj.assets.yesterdayCard = { uri: brandAssets.yesterdayCard };
  }

  // apply OTA overrides
  merge(brandingObj, getOtaBrandOverrides(brandingObj));

  return brandingObj;
};

/**
 * Root store. Includes programs and a way to initialize the graph from the
 * transport layer.
 */
const BrandingStore = types
  .model('BrandingStore', {
    branding: types.frozen(getBrandingDefaults()),
    loadBrandState: types.optional(LoadingState, {}),
  })
  .actions(self => {
    const loadBrand = flow(function* loadBrand(brandKey) {
      self.loadBrandState.setPending();
      try {
        let brandingProps;

        // 1) check if brand config is overridden (e.g., in brand switching for screenshots)
        if (brandKey) {
          // 1b) check for an array in appConfig simulated brand bundle
          if (!brandingProps) {
            const appConfig = getEnv(self).getAppConfig();
            if (appConfig.multipleBrands) {
              // combined props and assets - we store multiple of these when testing by cycling through brands
              const brandBundles = appConfig.multipleBrands.find(b => b.brandKey === brandKey);
              if (brandBundles) {
                brandingProps = mergeBrandBundleWithDefaultBranding({
                  brandProps: brandBundles.brandProps,
                  brandAssets: brandBundles.brandAssets,
                  getOtaBrandOverrides,
                });
              }
            }
          }
        }

        // 2) Check if brand config is bundled in binary
        if (!brandingProps) {
          const brandProps = yield getEnv(self).getResource('brandProps.json');
          const brandAssets = yield getEnv(self).getResource('brandAssets.json');

          if (brandProps && brandAssets /* return null if binary bundle unavailable */) {
            brandingProps = mergeBrandBundleWithDefaultBranding({
              brandProps,
              brandAssets,
              getOtaBrandOverrides,
            });
          }
        }

        // 3) Check if brand config is bundled in nudge-config (this is just for testing in Expo client)
        if (!brandingProps) {
          const appConfig = getEnv(self).getAppConfig();
          if (appConfig.brandProps && appConfig.brandAssets) {
            brandingProps = mergeBrandBundleWithDefaultBranding({
              brandProps: appConfig.brandProps,
              brandAssets: appConfig.brandAssets,
              getOtaBrandOverrides,
            });
          }
        }

        // 4) freak out
        if (!brandingProps) {
          throw new Error('FATAL: Cannot find brand config in JS bundle or binary!');
        }

        // determine api URL
        const apiUrl = getApiUrl({
          defaultUrl: getEnv(self).getDefaultApiUrl(),
          subDomain: brandingProps.bundleConfig.subDomain,
          customApiUrl: brandingProps.customApiUrl,
        });
        brandingProps.apiUrl = apiUrl;
        getEnv(self).overrideApiUrl(apiUrl);

        // special overrides
        brandingProps.bundleId = brandingProps.bundleConfig.id;

        self.branding = brandingProps;

        // follow-up setting of brand config
        getEnv(self).setPresets(self.branding);

        // load page title, favicon, etc.
        if (getEnv(self).Platform.OS === 'web') {
          const brandKey = getEnv(self).resolveBrandKey();
          if (brandKey) {
            window.document.title = self.branding.appStoreName;
            const link =
              window.document.querySelector("link[rel*='icon']") ||
              window.document.createElement('link');
            link.type = 'image/png';
            link.rel = 'shortcut icon';
            link.href = `brands/${brandKey}/favicon.png`;
            window.document.getElementsByTagName('head')[0].appendChild(link);
          }
        }

        // TODO: set API URL
        self.loadBrandState.setDone();
      } catch (error) {
        console.log('error setting brand!');
        console.log(error);
        self.loadBrandState.setFailed(error);
      }
    });

    return {
      loadBrand,
    };
  });

export default BrandingStore;

// exports for unit testing
export { mergeBrandBundleWithDefaultBranding };
