import React, { useEffect, useCallback, useState } from 'react';
import { AppState, Platform } from 'react-native';
import * as SentryBase from 'sentry-expo';
import { useNavigation, useRoute, useIsFocused } from '@react-navigation/native';

// normalize Sentry per new API
// https://docs.expo.io/guides/using-sentry/
// this is the last place where we don't use /lib/sentry, because it's in common lib and these imports
// aren't common-compatible yet.
const Sentry = Platform.OS === 'web' ? SentryBase.Browser : SentryBase.Native;

/**
 * Implements common screen behaviors.
 * Params:
 * onFocus = function to execute when this screen gets focus
 * onActive = function to execute when the app goes from background to active status and this screen is in focus
 * onBackground = function to execute when the app goes from active to background status and this screen is in focus
 * onManualRefresh = function to execute when the user requests manual refresh. This will be executed in conjunction with setting
 *  isPullToRefreshInProgress in progress to true and then false, allowing us to identify a manual refresh (as opposed to an automatic one, which is usually silent)
 *
 * returns:
 * Object with:
 *  onPullToRefresh: function that executes onManualRefresh while toggling isPullToRefreshInProgress
 *  isPullToRefreshInProgress: bool that is true if a pull to refresh is in progress, false if otherwise
 *
 */
function useScreenBehaviors({
  onDidMount = () => {}, // first focus/ mount only
  onFocus = () => {},
  onBlur = () => {},
  onActive = () => {},
  onBackground = () => {},
  onManualRefresh = () => {},
}) {
  const navigation = useNavigation();

  const isFocused = useIsFocused();

  const route = useRoute();

  const [isOnScreen, setIsOnScreen] = useState(false);

  // leave app and come back when on this screen
  // WHOA THERE!
  // Guess what? This function only gets registered with AppState.addEventListener() ONCE
  // So, you gotta use navigation.isFocused(), or else isFocused will never get updated.
  const handleChange = useCallback(nextAppState => {
    const isFocused = navigation.isFocused();
    if (nextAppState === 'active' && isFocused) {
      // short delay to work around network wake-up weirdness
      setTimeout(() => {
        console.log(`active: ${route.name}`);
        onActive();
      }, 500);
      setIsOnScreen(true);
    }
    if (nextAppState === 'background' && isFocused) {
      onBackground();
      setIsOnScreen(false);
    }
  });

  useEffect(() => {
    onDidMount();
  }, []);

  // switch focus to this screen
  useEffect(() => {
    if (isFocused) {
      onFocus();
      setIsOnScreen(true);
      if (route && route.name) {
        console.log(`focused: ${route.name}`);
        Sentry.addBreadcrumb({
          category: 'navigation',
          message: route.name,
          level: 'info',
        });
      }
    } else {
      onBlur();
      setIsOnScreen(false);
    }
  }, [isFocused]);

  useEffect(() => {
    // App state undefined on web for some reason..
    if (Platform.OS !== 'web') {
      this.change = AppState.addEventListener('change', handleChange);

      return () => {
        this.change.remove();
      };
    }
  }, []);

  // logic for pull to refresh
  const [isPullToRefreshInProgress, setIsPullToRefreshInProgress] = useState(false);

  const onPullToRefresh = useCallback(
    params => {
      async function executeAsync(queryProps) {
        setIsPullToRefreshInProgress(true);
        try {
          await onManualRefresh(queryProps);
        } finally {
          setIsPullToRefreshInProgress(false);
        }
      }
      executeAsync(params);
    },
    [onManualRefresh, isPullToRefreshInProgress]
  );

  return {
    onPullToRefresh,
    isPullToRefreshInProgress,
    isOnScreen,
  };
}

/**
 * compatibility HOC.
 * Called like:
 * withScreenBehaviors({ onFocus, onActive, onBackground })(Component)
 * Events will get component props passed to them, because with HOC the props aren't normally available, since this is composed outside of render
 */
function withScreenBehaviors({
  onFocus = () => {},
  onBlur = () => {},
  onActive = () => {},
  onBackground = () => {},
  onManualRefresh = () => {},
}) {
  return function withScreenBehaviorsInner(Component) {
    function WrappedComponent(props) {
      const { onPullToRefresh, isPullToRefreshInProgress } = useScreenBehaviors({
        onFocus: () => onFocus(props),
        onActive: () => onActive(props),
        onBackground: () => onBackground(props),
        onManualRefresh: async () => {
          await onManualRefresh(props);
        },
        onBlur: () => onBlur(props),
      });

      return (
        <Component
          {...props}
          onPullToRefresh={onPullToRefresh}
          isPullToRefreshInProgress={isPullToRefreshInProgress}
        />
      );
    }

    return WrappedComponent;
  };
}

export { withScreenBehaviors };
export default useScreenBehaviors;
