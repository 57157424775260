import React, { useContext } from 'react';
import { View, Text, ActivityIndicator } from 'react-native';
import { useTranslation } from 'react-i18next';
import { sizes, textStyles } from '../../config/common-styles';
import { BrandContext } from '../../config/branding';

const OnboardingWrapUpInfo = ({}) => {
  const branding = useContext(BrandContext);

  return (
    <View
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Text style={[textStyles.bold.dark, { marginBottom: sizes.medium }]}>
        Wrapping some things up...
      </Text>
      <ActivityIndicator color={branding.highlightedColor} size="large" />
    </View>
  );
};

export default OnboardingWrapUpInfo;
