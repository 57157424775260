import React from 'react';
import { View, Platform, Text } from 'react-native';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { hook } from 'cavy';
import { withTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { RoundButton, KeyboardAwareScrollView } from '/common/components';
import { sizes, textStyles } from '/common/config/styles';
import SignUpField from '../sign-up/SignUpField';

const RequestPasswordReset = observer(
  ({
    username,
    onChangeUsername,
    t,
    onPressRequestReset,
    formErrors,
    isRequestComplete,
    onGoBack,
    lastPasswordRequestedAt,
  }) => {
    let validationErrorText = null;
    if (formErrors.find(e => e.field === 'emailAddress' && e.type === 'invalid')) {
      validationErrorText = t('SIGN_UP:ERRORS:INVALID_INVITE_ID');
    }

    const twoMinutesInMs = 2 * 60 * 1000;
    const timeSinceLastPasswordReset = lastPasswordRequestedAt
      ? DateTime.fromJSDate(new Date()).diff(DateTime.fromJSDate(lastPasswordRequestedAt))
          .milliseconds
      : twoMinutesInMs;

    return (
      <View style={{ flex: 1 }}>
        <KeyboardAwareScrollView
          scrollViewProps={{
            showsVerticalScrollIndicator: false,
          }}
          textInputOffsetFromKeyboard={Platform.OS === 'android' ? 35 + 110 : 100}>
          <View
            style={{
              marginHorizontal: sizes.large,
              paddingTop: sizes.large * 2,
            }}>
            {!isRequestComplete &&
            !(
              timeSinceLastPasswordReset < twoMinutesInMs
            ) /* don't allow another reset within two minutes of resetting */ ? (
              [
                <SignUpField
                  key="1"
                  text={username}
                  onChangeText={onChangeUsername}
                  placeholder={'enter email address'}
                  focus
                  onSubmitEditing={onPressRequestReset}
                  validationError={validationErrorText}
                />,
                <RoundButton
                  key="2"
                  buttonStyle={{ marginTop: sizes.large, marginBottom: sizes.large }}
                  theme="lightOnDark"
                  isDisabled={!username || username.trim().length === 0}
                  onPress={onPressRequestReset}
                  title={t('LOGIN:BUTTONS:REQUEST_PASSWORD_RESET').toLowerCase()}
                />,
              ]
            ) : (
              <>
                <Text
                  style={[
                    textStyles.centered,
                    textStyles.bold.dark,
                    { marginBottom: sizes.large * 3 },
                  ]}>
                  {t('LOGIN:PASSWORD_RESET_CONFIRMATION')}
                </Text>
                <Text
                  style={[
                    textStyles.centered,
                    textStyles.standard.dark,
                    { marginBottom: sizes.large },
                  ]}>
                  {t('LOGIN:PASSWORD_RESET_TROUBLESHOOTING_TIPS_TITLE')}
                </Text>
                <Text
                  style={[
                    textStyles.centered,
                    textStyles.standard.dark,
                    { marginBottom: sizes.medium },
                  ]}>
                  {t('LOGIN:PASSWORD_RESET_TROUBLESHOOTING_TIPS_DETAIL1')}
                </Text>
                <Text
                  style={[
                    textStyles.centered,
                    textStyles.standard.dark,
                    { marginBottom: sizes.medium },
                  ]}>
                  {t('LOGIN:PASSWORD_RESET_TROUBLESHOOTING_TIPS_DETAIL2')}
                </Text>
                <Text style={[textStyles.centered, textStyles.standard.dark]}>
                  {t('LOGIN:PASSWORD_RESET_TROUBLESHOOTING_TIPS_DETAIL3')}
                </Text>
                <RoundButton
                  buttonStyle={{ marginTop: sizes.large * 3, marginBottom: sizes.large }}
                  onPress={onGoBack}
                  title={t('LOGIN:BUTTONS:LOGIN_AGAIN')}
                />
              </>
            )}
          </View>
        </KeyboardAwareScrollView>
      </View>
    );
  }
);

RequestPasswordReset.propTypes = {
  onChangeUsername: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  t: PropTypes.any.isRequired,
};

export default withTranslation(
  'defaultNamespace' /*{
  innerRef: ref => {
    if (ref) {
      ref.props.myRef(ref);
    }
  },
}*/
)(hook(RequestPasswordReset));
