import React from 'react';
import { View, Text } from 'react-native';
import { observer } from 'mobx-react';
import { TrackerValue } from 'nudge-client-common/trackers';
import { usePersonalization } from '/common/config/personalization';
import { textStyles, colors } from '/common/config/styles';

export default observer(function(props) {
  const personalization = usePersonalization();
  const branding = personalization.branding;
  return (
    <TrackerValue
      TextComponent={Text}
      ViewComponent={View}
      {...props}
      darkTextColor={colors.darkText}
      useMetricUnits={personalization.userOptions.useMetricUnits}
      valueTextStyle={[
        props.tracker.type.startsWith('custom-question')
          ? textStyles.standard.dark
          : textStyles.standard.semibold,
        props.valueTextStyle,
      ]}
      unitsTextStyle={[textStyles.standard.light, props.unitsTextStyle]}
      trackerColor={branding.trackerColors[props.tracker.palettesId]}
    />
  );
});
