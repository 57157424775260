import { types } from 'mobx-state-tree';

const Message = types.model({
  id: types.identifierNumber,
  messageInstancesId: types.maybeNull(types.number),
  message: types.maybeNull(types.string),
  sender: types.string,
  createdAt: types.Date,
  attachmentSource: types.frozen(),
  meeting: types.frozen(),
});

export default Message;
