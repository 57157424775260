import React from 'react';
import { View, Platform } from 'react-native';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { observer, inject } from 'mobx-react';
import { AestheticallyPleasingListFooter, RoundButton } from '/common/components/';
import LogFields from './internal/LogFields';
import { sizes, useMediaQueryInfo, colors } from '/common/config/styles';

/**
 * Lays out a list of programs with a list of trackers underneath.
 * Handles anything outside of a tracker itself (like borders between trackers, headers, etc.).
 * Also enforces a consistent style on each tracker's container.
 * KeyboardAwareScrollView automatically detects focused TextInputs and scrolls to make them
 * visible.
 */
const LogEntryForm = inject('rootStore')(
  observer(
    ({
      tracker,
      log,
      isEditing,
      header,
      formErrors,
      disableAutofocus,
      onPressDelete,
      webLogEntryFooter,
    }) => {
      const { innerWindowWidth, isTabletOrDesktop } = useMediaQueryInfo();
      const { t } = useTranslation();

      return (
        <View
          style={{
            flex: 1,
            backgroundColor: isTabletOrDesktop ? undefined : colors.bg0,
          }}>
          <KeyboardAwareScrollView
            keyboardShouldPersistTaps="always"
            extraScrollHeight={50 /* seems to be just enough for iOS to show the comments */}
            keyboardOpeningTime={
              Number.MAX_SAFE_INTEGER /* see: https://github.com/APSL/react-native-keyboard-aware-scroll-view/issues/418 */
            }
            contentContainerStyle={[
              {
                width: innerWindowWidth,
                alignSelf: 'center',
                overflow: 'hidden',
              },
              {
                flexGrow: isTabletOrDesktop ? 1 : undefined,
                justifyContent: isTabletOrDesktop ? 'center' : undefined,
              },
            ]}>
            <View
              style={{
                borderRadius: isTabletOrDesktop
                  ? sizes.cardBorderRadius
                  : 0 /* this looks odd on mobile, since the whole screen is mostly bg0 */,
                backgroundColor: colors.bg0,
              }}>
              <View
                style={{
                  width: innerWindowWidth,
                  alignSelf: 'center',
                }}>
                {header}
              </View>
              <View>
                <View
                  style={{
                    backgroundColor: 'white',
                    overflow: 'hidden',
                    paddingHorizontal: sizes.medium + 8 + (Platform.isPad ? sizes.large * 4 : 0),
                  }}>
                  <LogFields
                    disableAutofocus={disableAutofocus}
                    tracker={tracker}
                    log={log}
                    canEdit={isEditing}
                    formErrors={formErrors}
                  />
                </View>
              </View>
            </View>
            {onPressDelete && isEditing && !isTabletOrDesktop ? (
              <View
                style={{
                  paddingHorizontal: sizes.large,
                  marginTop: sizes.medium * 2,
                  backgroundColor: colors.bg0,
                  paddingBottom: 30,
                }}>
                <RoundButton
                  title={t('LOG:ENTRY:DELETE')}
                  onPress={onPressDelete}
                  textStyle={{ color: colors.error }}
                />
              </View>
            ) : (
              <View style={{ height: 30, backgroundColor: colors.bg0 }} />
            )}
            {isTabletOrDesktop ? webLogEntryFooter : null}
            <AestheticallyPleasingListFooter
              style={
                isTabletOrDesktop && {
                  borderBottomRightRadius: sizes.cardBorderRadius,
                  borderBottomLeftRadius: sizes.cardBorderRadius,
                  height: sizes.cardBorderRadius,
                }
              }
            />
          </KeyboardAwareScrollView>
        </View>
      );
    }
  )
);

LogEntryForm.propTypes = {
  tracker: PropTypes.object.isRequired,
  log: PropTypes.object.isRequired,
};

export default LogEntryForm;
