import React, { useState } from 'react';
import { View, TouchableWithoutFeedback, Image } from 'react-native';
import { observer } from 'mobx-react';
import { sizes } from '/common/config/styles';
import cardConstants from '../cardConstants';

const CardVideoComponent = observer(function({ onPressVideo, component }) {
  const [width, setWidth] = useState(0);

  // we're going to not render the video for now until we figure out better handling for
  // videos whose metadata doesn't resolve.
  if (!component.meta) {
    return <View />; // this can be a summary component, so render something or else sharedelement is gonna spaz
  }

  return (
    <View style={{ marginBottom: cardConstants.elementSpacing }}>
      <TouchableWithoutFeedback
        onPress={onPressVideo}
        style={{
          maxWidth: '100%',
        }}>
        <View
          onLayout={event => {
            let { x, y, width, height } = event.nativeEvent.layout;
            setWidth(width);
          }}
          style={{
            width: '100%',
            height: (width * 9) / 16,
            backgroundColor: 'black',
            borderRadius: sizes.borderRadius,
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}>
          <Image
            source={require('../../../../assets/icons/play.png')}
            pointerEvents={'none'}
            style={{
              width: 44,
              height: 44,
              tintColor: 'white',
              position: 'absolute',
              zIndex: 1,
              margin: 'auto',
            }}
          />
          <Image
            source={{
              uri: component.meta.thumbnailUrl,
            }}
            style={{
              width: '100%',
              height: '100%',
              borderRadius: sizes.borderRadius,
              opacity: 0.5,
            }}
          />
        </View>
      </TouchableWithoutFeedback>
    </View>
  );
});

export default CardVideoComponent;
