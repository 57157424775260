import React, { useContext, createContext, useCallback } from 'react';
import { Alert, Platform } from 'react-native';
import Swal from 'sweetalert2';
import { BrandContext } from '../config/branding';

const AlertContext = createContext(null);

/**
 * Note about the alert function provided via context:
 * It basically conforms to the react native Alert API, with a title, message, and an array of buttons.
 * The buttons are then mapped to Sweet Alert for web based on the style:
 * style = cancel => dismiss
 * style = default => confirm
 * style = destructive => deny
 *
 * So, even though Alert would technically allow multiple default style buttons, this will not map correctly to Sweet Alert.
 */
const AlertProvider = function({ children }) {
  const branding = useContext(BrandContext);
  const alert = useCallback(
    (title, message, ...rest) => {
      if (Platform.OS === 'web') {
        // default when no buttons are specified
        let buttonProps = { confirmButtonColor: branding.highlightedColor };
        // array of buttons
        if (rest[0] && rest[0].length) {
          const alertButtons = rest[0];
          buttonProps.showConfirmButton = false;
          // destructive button
          const destructiveButton = alertButtons.find(b => b.style === 'destructive');
          if (destructiveButton) {
            buttonProps.showDenyButton = true;
            buttonProps.denyButtonText = destructiveButton.text;
            buttonProps.denyButtonOnPress = destructiveButton.onPress;
          }
          // cancel button
          const cancelButton = alertButtons.find(b => b.style === 'cancel');
          if (cancelButton) {
            buttonProps.showCancelButton = true;
            buttonProps.cancelButtonText = cancelButton.text;
            buttonProps.cancelButtonOnPress = cancelButton.onPress;
          }
          const confirmButton = alertButtons.find(b => b.style === 'default' || !b.style);
          if (confirmButton) {
            buttonProps.showConfirmButton = true;
            buttonProps.confirmButtonText = confirmButton.text;
            buttonProps.confirmButtonOnPress = confirmButton.onPress;
          }
        }
        Swal.fire({
          title,
          text: message,
          customClass: {
            title: 'sweetalert-title',
            container: 'sweetalert-title',
          },
          ...buttonProps,
        }).then(result => {
          if (result.isDenied && buttonProps.denyButtonOnPress) {
            buttonProps.denyButtonOnPress();
          }
          if (result.isDismissed && buttonProps.cancelButtonOnPress) {
            buttonProps.cancelButtonOnPress();
          }
          if (result.isConfirmed && buttonProps.confirmButtonOnPress) {
            buttonProps.confirmButtonOnPress();
          }
        });
      } else {
        Alert.alert(title, message, ...rest);
      }
    },
    [branding]
  );
  return <AlertContext.Provider value={alert} children={children} />;
};

function useAlert() {
  const alert = useContext(AlertContext);
  return alert;
}

const withAlert = function(Component) {
  function WrappedComponent(props) {
    const alert = useAlert();
    return <Component {...props} alert={alert} />;
  }

  return WrappedComponent;
};

export { AlertProvider, AlertContext, useAlert, withAlert };
