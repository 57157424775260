import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, ScrollView, Text, Platform } from 'react-native';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { RoundButton } from '../common';
import { colors, textStyles, sizes } from '../../config/common-styles';
import { BrandContext } from '/common/config/branding';

console.log(Platform.Version);

/**
 * We return the state with all the props anytime something changes.
 * What is changing doesn't map perfectly to what is in the object (first name, last name vs name),
 * So it's easier this way. I guess. I hate forms...
 */
@observer
class UpdateTrackingConsent extends Component {
  static contextType = BrandContext;
  render() {
    const branding = this.context;

    const {
      onPressPrivacyPolicy,
      onPressAcceptForegroundTracking,
      onPressAcceptBackgroundTracking,
      onPressOpenSettings,
      trackingConsentStatus,
      consentScreenStrings,
      t,
    } = this.props;

    // play around with the states
    //const trackingConsentStatus = { canAsk: true, canTrack: true, canTrackAlways: true }

    if (!trackingConsentStatus) {
      return null;
    }

    let consentStatus;

    // figure out primary acceptance function
    // iOS = always ask for background - it will not actually ask for background, but will confirm background access late
    // Android <=10 - always ask for background - it will be granted at the time
    // Android >=11 - ask for foreground, then ask for background separately

    let primaryAcceptFn = onPressAcceptBackgroundTracking;
    let primaryAcceptButtonText = 'Enable Location';
    let secondaryAcceptFn = null;
    if (Platform.OS === 'android' && Platform.Version >= 30) {
      primaryAcceptFn = onPressAcceptForegroundTracking;
      secondaryAcceptFn = onPressAcceptBackgroundTracking;
      primaryAcceptButtonText = 'Enable Foreground Location';
    }

    // not decided yet
    if (trackingConsentStatus.canAsk) {
      consentStatus = [
        <Text
          key="1"
          style={[textStyles.medium.dark, { marginHorizontal: sizes.medium, textAlign: 'center' }]}>
          {consentScreenStrings.notAnswered}
        </Text>,
      ];
      consentStatus.push(
        <RoundButton
          key="2"
          buttonStyle={{ marginTop: sizes.large, marginBottom: sizes.large }}
          theme="lightOnDark"
          onPress={primaryAcceptFn}
          title={primaryAcceptButtonText}
        />
      );
    } else if (trackingConsentStatus.canTrack && trackingConsentStatus.canTrackAlways) {
      // answered already in the 100% affirmative
      consentStatus = (
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            marginHorizontal: sizes.large,
            alignItems: 'center',
            padding: sizes.medium,
            backgroundColor: colors.green + '33',
            borderColor: colors.green,
            borderRadius: sizes.borderRadius,
            borderWidth: 1,
          }}>
          <Text style={[textStyles.medium.dark, { textAlign: 'center', flex: 1 }]}>
            {consentScreenStrings.accepted}
          </Text>
        </View>
      );
    } else if (trackingConsentStatus.canTrack && !trackingConsentStatus.canTrackAlways) {
      // answered already, background location not accepted yet
      consentStatus = (
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            marginHorizontal: sizes.large,
            alignItems: 'center',
            padding: sizes.medium,
            backgroundColor: colors.warning + '33',
            borderColor: colors.warning,
            borderRadius: sizes.borderRadius,
            borderWidth: 1,
          }}>
          <Text style={[textStyles.medium.dark, { textAlign: 'center', flex: 1 }]}>
            {consentScreenStrings.rejectedAlways}
          </Text>
        </View>
      );
    } else {
      // answered already, rejected completely
      consentStatus = (
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            marginHorizontal: sizes.large,
            alignItems: 'center',
            padding: sizes.medium,
            backgroundColor: colors.error + '33',
            borderColor: colors.error,
            borderRadius: sizes.borderRadius,
            borderWidth: 1,
          }}>
          <Text style={[textStyles.medium.dark, { textAlign: 'center', flex: 1 }]}>
            {!trackingConsentStatus.canTrack
              ? consentScreenStrings.rejected
              : !trackingConsentStatus.canTrackAlways
              ? consentScreenStrings.rejectedAlways
              : "Sorry, but we can't determine if you have location enabled. Please check your settings"}
          </Text>
        </View>
      );
    }

    let settingsCallToAction = null;

    // if a secondary acceptance is required and hasn't been granted, show this
    if (
      secondaryAcceptFn &&
      trackingConsentStatus.canTrack &&
      !trackingConsentStatus.canTrackAlways
    ) {
      settingsCallToAction = [
        <Text
          key="1"
          style={[
            textStyles.medium.dark,
            { marginHorizontal: sizes.medium, marginTop: sizes.large, textAlign: 'center' },
          ]}>
          {consentScreenStrings.android11BackgroundLocationPrompt}
        </Text>,
        <RoundButton
          key="2"
          buttonStyle={{ marginTop: sizes.large, marginBottom: sizes.large }}
          theme="lightOnDark"
          onPress={secondaryAcceptFn}
          title={'Enable Background Location'}
        />,
      ];
    } else if (!trackingConsentStatus.canAsk) {
      // add settings button at the bottom regardless, as long as we've asked at least once
      settingsCallToAction = [
        <Text
          key="settingsText"
          style={[
            textStyles.centered,
            textStyles.standard.light,
            {
              marginTop: sizes.large,
              marginBottom: sizes.small,
              marginHorizontal: sizes.large,
            },
          ]}>
          {consentScreenStrings.settingsInfo[Platform.OS]}
        </Text>,
        <RoundButton
          key="button"
          buttonStyle={{ marginBottom: sizes.large, marginHorizontal: sizes.large }}
          theme="darkOnLight"
          onPress={onPressOpenSettings}
          title={'open location settings'}
        />,
      ];
    }

    return (
      <View style={{ flex: 1, backgroundColor: colors.bg0 }}>
        <ScrollView>
          <View
            style={{
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <View style={{ width: '100%', alignItems: 'center', marginTop: sizes.medium }}>
              <View
                style={{
                  width: '100%',
                  borderBottomColor: colors.border0,
                  borderBottomWidth: 0,
                  marginBottom: sizes.medium,
                }}
              />
              <Text
                style={[
                  textStyles.centered,
                  textStyles.bold.dark,
                  { marginBottom: sizes.small, marginHorizontal: sizes.medium },
                ]}>
                {consentScreenStrings.title}
              </Text>
              <Text
                style={[
                  textStyles.centered,
                  textStyles.standard.light,
                  { marginBottom: sizes.small, marginHorizontal: sizes.large },
                ]}>
                {consentScreenStrings.explanation}
              </Text>
              <Text
                onPress={onPressPrivacyPolicy}
                style={[
                  textStyles.centered,
                  textStyles.standard.dark,
                  {
                    marginHorizontal: sizes.large,
                    color: branding.highlightedColor,
                    marginBottom: sizes.large,
                  },
                ]}>
                {consentScreenStrings.privacyPolicyUrlDescription}
              </Text>
              {consentStatus}
              {settingsCallToAction}
            </View>
          </View>
        </ScrollView>
      </View>
    );
  }
}

UpdateTrackingConsent.propTypes = {
  t: PropTypes.func.isRequired,
  consentScreenStrings: PropTypes.shape({
    title: PropTypes.string,
    explanation: PropTypes.string,
    privacyPolicyUrlDescription: PropTypes.string,
    settingsInfo: PropTypes.shape({
      ios: PropTypes.string,
      android: PropTypes.string,
    }),
    android11BackgroundLocationPrompt: PropTypes.string,
    accepted: PropTypes.string,
    rejected: PropTypes.string,
    rejectedAlways: PropTypes.string,
    notAnswered: PropTypes.string,
  }),
};

export default withTranslation()(UpdateTrackingConsent);
