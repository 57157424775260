export default {
  /**
   * Given an object with firstName and lastName props, determines the printed full name.
   */
  fullName: ({ firstName, lastName }) => {
    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    }
    if (firstName) {
      return firstName;
    }
    if (lastName) {
      return lastName;
    }
    return '';
  },
  ordinal: number => {
    const j = number % 10,
      k = number % 100;
    if (j == 1 && k != 11) {
      return 'st';
    }
    if (j == 2 && k != 12) {
      return 'nd';
    }
    if (j == 3 && k != 13) {
      return 'rd';
    }
    return 'th';
  },
};
