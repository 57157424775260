import { types, flow, getEnv, getRoot } from 'mobx-state-tree';
import { sortBy, first } from 'lodash';
import Coach from './Coach';
import Conversation from './Conversation';
import { LoadingState } from '../../common';

/**
 * Root store. Includes programs and a way to initialize the graph from the
 * transport layer.
 */
const ConversationsStore = types
  .model('ConversationsStore', {
    coaches: types.optional(types.array(Coach), []),
    conversations: types.optional(types.array(Conversation), []),
    loadInitialState: types.optional(LoadingState, {}),
    noCoachPageContent: types.maybeNull(types.string),
    // has coachId, messageId, isNewMessage of the latest notification received
    latestUpdateFromCoach: types.frozen(),
  })
  .views(self => ({
    get coachesSorted() {
      return sortBy(self.coaches, c => c.lastName);
    },
    coachWithId(id) {
      return self.coaches.find(c => c.id === id);
    },
    conversationWithId(id) {
      return self.conversations.find(c => c.coachId === id);
    },
    // This exists because the message deleted pusher notification only has a message ID
    // but we only want to refresh the current coach's messages
    conversationForMessageInstancesId(messageInstancesId) {
      return this.conversations.find(conversation => {
        // different ID's for individual messages vs. the ID from the new message subsystem
        const message = conversation.messages.find(
          m => m.messageInstancesId === messageInstancesId
        );
        return !!message;
      });
    },
    get coachForLatestNewMessage() {
      if (self.latestUpdateFromCoach) {
        return self.coachWithId(self.latestUpdateFromCoach.coachId);
      }
      return null;
    },
    // used by the SingleCoachView so we don't show some orphaned conversation
    // should only be used if there is only one coach
    get onlyCoach() {
      return first(self.coaches);
    },
  }))
  .actions(self => {
    /**
     * Load coaches and/ or the no coach page. If only a single coach, load that coach's
     * initial messages.
     */
    const loadInitial = flow(function* loadInitial(options) {
      self.loadInitialState.setPending();
      try {
        let alreadyLoadedOnlyConversation = false;
        // optimistically load only coach conversation if already loaded for speed and so conversation screen picks up any errors
        if (self.coaches.length === 1) {
          const conversation = self.initConversation(self.coaches[0].id);
          // refresh it if we want to (e.g., when actually viewing the screen)
          if (options && options.loadOnlyConversation) {
            yield conversation.loadInitial();
            alreadyLoadedOnlyConversation = true;
          }
        }

        self.coaches.replace(yield getEnv(self).coachesRepository.getCoaches());
        // load the No Coach page if there are no coaches
        if (
          self.coaches.length === 0 &&
          (!self.noCoachPageContent || (options && options.forceReloadNoCoachPage))
        ) {
          self.noCoachPageContent = yield getEnv(self).coachesRepository.getNoCoachContent();
        }
        // init/ refresh conversation if it is the only one and we didn't already load it
        if (self.coaches.length === 1 && !alreadyLoadedOnlyConversation) {
          const conversation = self.initConversation(self.coaches[0].id);
          // refresh it if we want to (e.g., when actually viewing the screen)
          if (options && options.loadOnlyConversation) {
            yield conversation.loadInitial();
          }
        }

        // keep names in sync for display on conversation
        self.conversations.forEach(conversation => {
          const matchingCoach = self.coaches.find(coach => coach.id === conversation.coachId);
          if (matchingCoach) {
            conversation.firstName = matchingCoach.firstName;
            conversation.lastName = matchingCoach.lastName;
          }
        });
        self.loadInitialState.setDone();
      } catch (error) {
        self.loadInitialState.setFailed(error);
        /*if (options && options.loadOnlyConversation && self.conversations.length === 1) {
          // if a previous conversation was loaded, propagate the error so handlers on the conversation screen pick it up
          first(self.conversations).loadInitialState.copyLoadingStateFrom(self.loadInitialState);
        }*/
      }
    });

    /**
     * If coach ID exists in list, starts a refresh of the conversation
     * Does not wait for it to complete.
     * Returns a summary of conversation if it exists, null if not.
     * Currently, this summary only needs the first and last name of the coach.
     * A quirk of this is that if somehow we have the conversation but no coach,
     * then we still return null.
     * Which is probably actually correct, since that may indicate stale conversation data.
     * Used when a web socket notification comes in that a new message exists.
     */
    const onConversationUpdatedByCoach = flow(function* onConversationUpdatedByCoach({
      coachId,
      messageId,
      isNewMessage = false,
      isDeleted = false,
    }) {
      self.latestUpdateFromCoach = { coachId, messageId, isNewMessage, isDeleted };
    });

    /**
     * Init conversation if it doesn't exist, kicking off refresh.
     * Must return synchronously for ConversationScreen - need to be able to immediately get
     * conversation stub so we can start to populate screen.
     */
    const initConversation = coachId => {
      const myCoachId = parseInt(coachId);
      let existingConversation = self.conversations.find(c => c.coachId === myCoachId);
      let matchingCoach = self.coaches.find(c => c.id === myCoachId);
      // create new conversation if it doesn't exist
      if (!existingConversation) {
        self.conversations.push({
          coachId: myCoachId,
          firstName: matchingCoach ? matchingCoach.firstName : null,
          lastName: matchingCoach ? matchingCoach.lastName : null,
        });
        existingConversation = self.conversations.find(c => c.coachId === myCoachId);
      }

      return existingConversation;
    };

    // TESTING ONLY

    const requestTestNotification = flow(function* requestTestNotification(notificationType) {
      yield self.loadInitial(); // hopefully you have coaches
      if (notificationType === 'inApp') {
        self.latestUpdateFromCoach = {
          coachId: first(self.coachesSorted).id,
          messageId: new Date().getTime(), //doesn't matter what this is
          isNewMessage: true,
        };
      }
      if (notificationType === 'selected') {
        yield self.loadInitial(); // hopefully you have coaches
        getRoot(self).appLinkProcessor.setAppLink({
          source: 'notification',
          data: { coach_id: first(self.coachesSorted).id },
        });
        getRoot(self).appLinkProcessor.processCurrentLink();
      }
    });

    return {
      initConversation,
      loadInitial,
      onConversationUpdatedByCoach,
      requestTestNotification,
    };
  });

export default ConversationsStore;
