import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { NavbarWrapper } from '../../../../components/navigation';
import { ConversationSummaryList } from '../../../../components/messaging';
import { navigationRoutes } from '../../../../config/constants';
import { TriggerActionOnUpdateWhenFocused } from '../../../common';
import { BrandContext } from '../../../../config/branding';
import { getTheme } from '../../../../lib/theming';

@observer
class MultipleCoachesView extends Component {
  static contextType = BrandContext;

  _onPressConversationSummary = coachId => {
    const { navigation, isSidecar } = this.props;
    if (isSidecar) {
      navigation.replace(navigationRoutes.stacks.main.conversation, {
        coachId,
      });
    }
    navigation.navigate(navigationRoutes.stacks.main.conversation, {
      coachId,
    });
  };

  render() {
    const { rootStore, isSidecar, t } = this.props;
    const { conversationsStore } = rootStore;
    const branding = this.context;

    const theme = getTheme({ rootStore, branding });

    return (
      <NavbarWrapper title={t('TABS:COACHES')} theme={theme} isSidecar={isSidecar}>
        <TriggerActionOnUpdateWhenFocused
          getWatchValue={() => rootStore.conversationsStore.latestUpdateFromCoach}
          triggerWhen={update => update.isNewMessage}
          onTrigger={rootStore.conversationsStore.loadInitial}
        />
        <ConversationSummaryList
          conversationSummaries={conversationsStore.coachesSorted}
          onPressConversationSummary={this._onPressConversationSummary}
        />
      </NavbarWrapper>
    );
  }
}

MultipleCoachesView.propTypes = {
  rootStore: PropTypes.object,
  navigation: PropTypes.object,
  isSidecar: PropTypes.bool,
  t: PropTypes.any,
};

MultipleCoachesView.defaultProps = {
  isSidecar: false,
};

export default withTranslation()(MultipleCoachesView);
