import { useEffect, useCallback } from 'react';
import { BackHandler } from 'react-native';
import { useIsFocused } from '@react-navigation/native';

function AndroidBackHandler({ swallowBackPress = true }) {
  const isFocused = useIsFocused();
  // leave app and come back when on this screen
  const handleBackPress = useCallback(() => {
    // not on current screen, let others handle
    if (!isFocused) {
      return false;
    }
    // return true to swallow, false to let pass through
    return swallowBackPress;
  }, [isFocused]);

  useEffect(() => {
    BackHandler.addEventListener('hardwareBackPress', handleBackPress);

    return () => {
      BackHandler.removeEventListener('hardwareBackPress', handleBackPress);
    };
  }, []);

  return null;
}

export default AndroidBackHandler;
