import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Picker } from '@react-native-picker/picker';
import { Platform, View } from 'react-native';
import { sizes, colors, textStyles } from '/common/config/styles';
import FieldContainer from './FieldContainer';
import TextInputField from './TextInputField';

function ActivityField({ value, onChangeValue, title, activityOptions, canEdit, isRequired }) {
  const selectedActivity = activityOptions.find(a => a.id === parseInt(value, 10));

  if (!canEdit) {
    return (
      <TextInputField value={selectedActivity.name} {...{ title, activityOptions, canEdit }} />
    );
  }

  return (
    <FieldContainer
      title={title}
      isRequired={isRequired}
      childrenContainerStyle={{
        height: undefined,
        flexDirection: undefined,
        justifyContent: undefined,
        alignItems: undefined,
      }}
      canEdit={canEdit}>
      <View
        style={{
          borderWidth: sizes.hairlineWidth,
          borderColor: colors.lightText,
          borderRadius: sizes.borderRadius,

          //padding: 10,
          //paddingTop: 10,
        }}>
        <Picker
          style={[
            Platform.OS === 'web' && {
              border: 0,
              height: 40,
              backgroundImage: 'none',
              fontSize: 18,
              cursor: 'pointer',
              webkitAppearance: 'none',
              paddingHorizontal: sizes.small,
              borderRadius: sizes.borderRadius,
            },
          ]}
          itemStyle={textStyles.standard.dark}
          selectedValue={parseInt(value, 10)}
          onValueChange={onChangeValue}>
          {activityOptions.map(option => (
            <Picker.Item key={option.id} label={option.name} value={option.id} />
          ))}
        </Picker>
      </View>
    </FieldContainer>
  );
}

ActivityField.propTypes = {
  title: PropTypes.string.isRequired,
  activityOptions: PropTypes.array.isRequired,
};

export default observer(ActivityField);
