import React from 'react';
import { View, Platform, KeyboardAvoidingView } from 'react-native';
import { AlertBarSuppressor, useMessagingState } from '/common/navigation';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { colors } from '/common/config/styles';
import { NavbarWrapper } from '../../../components/navigation';
import { Conversation } from '../../../components/messaging';
import { common } from '../../../config/strings';
import {
  ScreenError,
  ScreenFirstLoadWrapper,
  withKeyboardAvoidance,
  TriggerActionOnUpdateWhenFocused,
} from '../../common';
import { LoadingShadeWrapper } from '../../../components/common';

/**
 * Version of conversation screen that exposes the current coach as a prop.
 * This is done so we can use the same screen component from both a dedicated conversation
 * screen and as the initial screen on the Messenging tab when there is only one coach.
 */

const ConversationScreenInner = inject(
  'rootStore',
  'Linking'
)(
  observer(
    ({
      navigation,
      route,
      conversation,
      hasBackButton,
      leftNavbarButton,
      rightNavbarButton,
      theme,
      Linking,
      rootStore,
    }) => {
      const {
        currentMessage,
        onSendMessage,
        onCurrentMessageTextChanged,
        onChooseImage,
        onRemoveImage,
        onOpenAttachment,
        onLongPressMessage,
      } = useMessagingState({
        getSendableStore: () => conversation,
        capabilities: ['delete'],
        getCurrentUser: () => ({
          id: 'patient',
        }),
        navigation,
        route,
      });

      const { t } = useTranslation();

      // this iOS keyboard handling helps us work around the extra space at the bottom caused by making the tab bar translucent
      // This is an awkward consequence of a text input directly on a tab + translucent tab bars
      // We keep the offset for the tab bar outside of the KAV so they keyboard will go right over it and not too far
      const KeyboardAvoidingViewComponent = Platform.OS === 'ios' ? KeyboardAvoidingView : View;

      return (
        <View style={{ flex: 1 }}>
          <KeyboardAvoidingViewComponent style={{ flex: 1 }} behavior="padding">
            <ScreenError
              state={conversation.sendMessageState}
              title={t('MESSAGING:ERRORS:SEND_MESSAGE')}
            />
            <ScreenError
              state={conversation.deleteMessageState}
              title={t('MESSAGING:ERRORS:DELETE_MESSAGE')}
            />
            <ScreenError
              state={conversation.loadAdditionalMessagesState}
              title={t('MESSAGING:ERRORS:LOADING_PREVIOUS_MESSAGES')}
              type="messageBar"
            />
            <ScreenError
              state={rootStore.openShareableFileInOsViewerState}
              title={t('GENERAL:ERRORS:CANNOT_OPEN_FILE')}
            />
            <TriggerActionOnUpdateWhenFocused
              getWatchValue={() => rootStore.conversationsStore.latestUpdateFromCoach}
              triggerWhen={
                update =>
                  update.coachId === conversation.coachId &&
                  update.isNewMessage /* append new messages */
              }
              onTrigger={conversation.loadNewMessages}
            />
            <TriggerActionOnUpdateWhenFocused
              getWatchValue={() => rootStore.conversationsStore.latestUpdateFromCoach}
              triggerWhen={
                update =>
                  update.coachId === conversation.coachId &&
                  !update.isNewMessage &&
                  !update.isDeleted /* reload on updates */
              }
              onTrigger={conversation.loadInitial}
            />
            <TriggerActionOnUpdateWhenFocused
              getWatchValue={() => rootStore.conversationsStore.latestUpdateFromCoach}
              triggerWhen={
                update =>
                  update.coachId === conversation.coachId &&
                  update.isDeleted /* clear and reload on deletes */
              }
              onTrigger={conversation.resetAndLoadInitial}
            />
            <AlertBarSuppressor
              suppressWhen={idPayload => idPayload.senderId === conversation.coachId}
            />
            <ScreenFirstLoadWrapper
              state={conversation.loadInitialState}
              hasData={conversation.messagesSorted.length}
              onPressRefresh={conversation.loadInitial}
              NavbarWrapperComponent={NavbarWrapper}
              navbarWrapperProps={{
                title: common.fullName(conversation),
                hasBackButton,
                leftButton: leftNavbarButton,
                rightButton: rightNavbarButton,
                theme,
                navigation,
              }}
              showRefreshFailedErrorOnSubsequentLoads>
              <LoadingShadeWrapper
                style={{ flex: 1, backgroundColor: colors.bg1 }}
                isLoading={rootStore.openShareableFileInOsViewerState.isPending}>
                <Conversation
                  currentMessage={currentMessage}
                  onCurrentMessageTextChanged={onCurrentMessageTextChanged}
                  messages={conversation.messagesSorted}
                  onPressChooseImage={onChooseImage}
                  onPressRemoveImage={onRemoveImage}
                  canFetchNextPage={!conversation.isAtEndOfMessageHistory}
                  onRequestNextPage={conversation.loadAdditionalMessages}
                  isFetchingNextPage={conversation.loadAdditionalMessagesState.isPending}
                  isOperationPending={
                    conversation.sendMessageState.isPending ||
                    conversation.deleteMessageState.isPending
                  }
                  onPressUrl={Linking.openURL}
                  onPressSend={onSendMessage}
                  onPressAttachment={onOpenAttachment}
                  onLongPressMessage={onLongPressMessage}
                />
              </LoadingShadeWrapper>
            </ScreenFirstLoadWrapper>
          </KeyboardAvoidingViewComponent>
        </View>
      );
    }
  )
);

ConversationScreenInner.propTypes = {
  navigation: PropTypes.object,
  conversation: PropTypes.object,
  // height for a tab bar that shows up along with conversation
  // when there is only one coach
  tabBarHeight: PropTypes.number,
  // any additional offset besides that for the tab bar
  // This is basically for the iPhone X, which needs additional space for the
  // home bar.
  additionalBottomOffset: PropTypes.number,
  hasBackButton: PropTypes.bool,
  leftNavbarButton: PropTypes.element,
  rightNavbarButton: PropTypes.element,
  theme: PropTypes.object,
  Linking: PropTypes.any,
};

ConversationScreenInner.defaultProps = {
  tabBarHeight: 0,
  additionalBottomOffset: 0,
  hasBackButton: true,
  leftNavbarButton: null,
  rightNavbarButton: null,
  theme: {},
};

export default withKeyboardAvoidance(ConversationScreenInner, { enabledForIos: false });
