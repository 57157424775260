import React from 'react';
import { Platform, Dimensions, StatusBar, StyleSheet } from 'react-native';
import { isIphoneX } from 'react-native-iphone-x-helper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

// this doesn't help because detecting the 2nd gen is not iPhone X is confusing due to it having
// the same resolution as the 3rd
//const iPadThirdGenTabBarHeight = 69;

const deviceWidth = Dimensions.get('screen').width;

// not entirely true - 2nd gen 12.9 has same width, but we also have issues with that elsewhere
const isThirdGenIpad = () =>
  Platform.OS === 'ios' && Platform.isPad && (deviceWidth === 1024 || deviceWidth === 834);

// Should consider using the constants for all status bar values. iPhone X helper has
// this, too. Would need to test.
const statusBarHeight = Platform.OS === 'android' ? StatusBar.currentHeight : isIphoneX() ? 44 : 20;
const navbarHeight = Platform.OS === 'ios' ? 44 : 56;
const statusBarAndNavbarHeight = navbarHeight + statusBarHeight;

/**
 * It seems like StatusBar.currentHeight stopped working on some combination of Expo 44/ Android 12/ Pixel 4a
 * This workaround keeps the variables the same for everything else while letting us substitute in where they don't work.
 */
const useDeviceDimensions = () => {
  const insets = useSafeAreaInsets();
  if (Platform.OS === 'android' && Platform.Version >= 31) {
    return {
      navbarHeight,
      statusBarHeight: insets.top,
      statusBarAndNavbarHeight: insets.top + navbarHeight,
    };
  }
  return {
    navbarHeight,
    statusBarHeight,
    statusBarAndNavbarHeight,
  };
};

const withDeviceDimensions = function(Component) {
  const WrappedComponent = function(props) {
    const deviceDimensions = useDeviceDimensions();
    return <Component {...props} {...deviceDimensions} />;
  };
  return WrappedComponent;
};

const sizes = {
  small: 8,
  medium: 18,
  large: 25,
  thumbnail: 50,
  notificationThumbnail: 40,
  smallIcon: 28,
  borderRadius: 6,
  webBorderRadius: 10,
  webInnerViewMargin: 25,
  cardBorderRadius: 27,
  thickBorder:
    Platform.OS === 'ios'
      ? StyleSheet.hairlineWidth * 4
      : StyleSheet.hairlineWidth * 3 /* only used by TrackerSummary */,
  hairlineWidth:
    Platform.OS === 'ios' || Platform.OS === 'web'
      ? StyleSheet.hairlineWidth * 2
      : StyleSheet.hairlineWidth * 3, // Android sometimes makes hairlines dissappear due to different scaling
  // need this as a placeholder when no background image is set... at least for now.
  navbarHeight,
  navbarLogoHeight: navbarHeight - 14 - (Platform.OS === 'android' ? 10 : 0),
  statusBarHeight,
  // for when we just need something to clear this entire height
  statusBarAndNavbarHeight,
  // We don't need this for the navbar itself, but if we're doing any math to offset the navbar,
  // and it has extra controls, we need to account for it.
  navbarBottomControlHeight: 40,
  tabBarHeight: isIphoneX() ? 83 : isThirdGenIpad() ? 69 : 49,
  iphoneXBottomPadding: 34, // deprecated - use homeBarPadding instead and avoid conditionals for each usage
  homeBarPadding: isIphoneX() ? 34 : 0,
  topTabBarHeight: 48,
  trackerMinHeight: Platform.OS === 'ios' ? 55 : 46,
  // *** Coach Messenger-specific constants ***
  // These are not from Nudge. Would be good to figure out if we really need them
  iPhoneXTopNotchPadding: 24, // total status bar is 44, but this gets you right below the notch
  filterBarHeight: 44,
  filterControlHeight: 28,

  // android shadows.
  headerElevation: 8,
  cardElevation: 2,
};

export default sizes;

export { useDeviceDimensions, withDeviceDimensions };
