import { types, flow, getEnv, applySnapshot } from 'mobx-state-tree';
import { sortBy, first, uniqBy, remove } from 'lodash';
import Message from './Message';
import { LoadingState } from '../../common';

const Conversation = types
  .model('Conversation', {
    coachId: types.number,
    // for the coach - firstName/ lastName are compatible with the common.name() string parser
    // in the future, would like to change the names of these
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    loadInitialState: types.optional(LoadingState, {}),
    loadAdditionalMessagesState: types.optional(LoadingState, {}),
    sendMessageState: types.optional(LoadingState, {}),
    deleteMessageState: types.optional(LoadingState, {}),
    messages: types.optional(types.array(Message), []),
    isAtEndOfMessageHistory: types.optional(types.boolean, true),
    pendingMessage: types.frozen(),
  })
  .views(self => ({
    get messagesSorted() {
      const sortedMessages = sortBy(self.messages.slice(), c => c.createdAt);
      if (self.pendingMessage) {
        sortedMessages.push(self.pendingMessage);
      }
      return sortedMessages;
    },
    get earliestId() {
      const firstItem = first(sortBy(self.messages.slice(), c => c.id));
      if (firstItem) {
        return firstItem.id;
      }
      return null;
    },
  }))
  .actions(self => {
    const loadInitial = flow(function* loadInitial() {
      self.loadInitialState.setPending();
      try {
        // apparently this endpoint doesn't exist
        if (!self.firstName && !self.lastName) {
          // populate coach name if its not available for some reason (e.g., navigated directly to unloaded coach)
          // this will 404 when it can't access the coach (doesn't matter if it exists or not)
          const coachProps = yield getEnv(self).coachesRepository.getCoach(self.coachId);
          self.firstName = coachProps.firstName;
          self.lastName = coachProps.lastName;
        }
        // If no messages, do a full load, start paging
        if (!self.messages.length) {
          const updatedMessages = yield getEnv(self).coachesRepository.getMessages(self.coachId);
          self.messages.replace(updatedMessages);
          if (self.messages.length < 20) {
            self.isAtEndOfMessageHistory = true;
          } else {
            self.isAtEndOfMessageHistory = false;
          }
        } else {
          // if there are messages, just refresh the last 20. This will pick up new/ edited messages
          // WARNING: this will not process deletes, but prevents jank where the feed jumps around after returning to the window
          yield self.loadNewMessages();
        }

        self.loadInitialState.setDone();
      } catch (error) {
        self.loadInitialState.setFailed(error);
      }
    });

    const loadAdditionalMessages = flow(function* loadAdditionalMessages() {
      // should we prevent this from being called if history is complete? We do that in TopicFeed
      if (!self.loadAdditionalMessagesState.isPending) {
        self.loadAdditionalMessagesState.setPending();
        try {
          const additionalMessages = yield getEnv(self).coachesRepository.getMessages(
            self.coachId,
            null,
            self.earliestId
          );
          if (additionalMessages.length < 20) {
            self.isAtEndOfMessageHistory = true;
          } else {
            self.isAtEndOfMessageHistory = false;
          }
          self.messages.replace(uniqBy(self.messages.concat(additionalMessages), m => m.id));
          self.loadAdditionalMessagesState.setDone();
        } catch (error) {
          self.loadAdditionalMessagesState.setFailed(error);
        }
      }
    });

    const loadNewMessages = flow(function* loadNewMessages() {
      try {
        const latestMessages = yield getEnv(self).coachesRepository.getMessages(self.coachId);
        latestMessages.forEach(message => {
          const existingMessage = self.messages.find(m => m.id === message.id);
          if (!existingMessage) {
            self.messages.push(message);
          } else {
            applySnapshot(existingMessage, message);
          }
        });
      } catch (error) {}
    });

    const sendMessage = flow(function* sendMessage({ text, image }) {
      self.sendMessageState.setPending();
      self.pendingMessage = {
        id: 'pending',
        message: text,
        sender: 'patient',
        createdAt: new Date(),
        isPending: true,
        attachmentSource: image,
      };
      try {
        const result = yield getEnv(self).coachesRepository.postMessage({
          coachId: self.coachId,
          messageText: text,
          image,
        });
        self.messages.push(result);
        self.sendMessageState.setDone();
      } catch (error) {
        self.sendMessageState.setFailed(error);
      } finally {
        self.pendingMessage = null;
      }
    });

    const deleteMessage = flow(function* deleteMessage({ messageId }) {
      self.deleteMessageState.setPending();
      try {
        yield getEnv(self).coachesRepository.deleteMessage(messageId);
        const messagesCopy = self.messages.slice();
        remove(messagesCopy, m => m.id === messageId);
        self.messages = messagesCopy;
        self.deleteMessageState.setDone();
      } catch (error) {
        self.deleteMessageState.setFailed(error);
      }
    });

    // used to ensure deletes are cleared if we get a pusher notification indicating that deletes should be cleared
    const resetAndLoadInitial = () => {
      self.messages = [];
      self.loadInitialState.reset();
      self.loadInitial();
    }

    return {
      loadInitial,
      loadAdditionalMessages,
      sendMessage,
      deleteMessage,
      loadNewMessages,
      resetAndLoadInitial,
    };
  });

export default Conversation;
