import { AppState } from 'react-native';

export default class AppStateListener {
  isListening = false;
  wasStartedOnce = false;
  startListening() {
    // unsubscribe isn't working! So, we simulate by starting just once and then toggling after that
    // TODO: fix this after we upgrade to react 65, when it supports returning an object with the subscriptioiin
    if (!this.wasStartedOnce) {
      AppState.addEventListener('change', this._handleAppStateChange);
    }
    this.isListening = true;
    this.wasStartedOnce = true;
  }

  stopListening() {
    this.isListening = false;
  }

  // override these to response to state changes
  onInactive = () => {};
  onActive = () => {};

  _handleAppStateChange = nextAppState => {
    if (this.isListening) {
      if (nextAppState === 'background') {
        // we should change these function names to "background"
        // inactive = app is dead on iOS
        // background = app goes offscreen (both platforms)
        this.onInactive();
      }
      if (nextAppState === 'active') {
        // short delay to work around network wake-up weirdness
        setTimeout(() => {
          this.onActive();
        }, 500);
      }
    }
  };
}
