import React, { useEffect, useState } from 'react';
import { Platform, Keyboard } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import { BottomTabBar } from '@react-navigation/bottom-tabs';

/**
 * Our custom bottom tab bar.
 * Right now it only implements better keyboard handling for Android,
 * but we could do more with it later.
 */

// workaround for the tab bar sliding under the keyboard on Android
// makes things faster/ less obvious
// See: https://github.com/react-navigation/react-navigation/issues/6700#issuecomment-707891974
const CustomBottomTabBar = props => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    let keyboardEventListeners;
    if (Platform.OS === 'android' && props.isFocused) {
      keyboardEventListeners = [
        Keyboard.addListener('keyboardDidShow', () => setVisible(false)),
        Keyboard.addListener('keyboardDidHide', () => setVisible(true)),
      ];
    }
    return () => {
      if (Platform.OS === 'android') {
        keyboardEventListeners &&
          keyboardEventListeners.forEach(eventListener => eventListener.remove());
      }
    };
  }, []);

  const render = () => {
    if (Platform.OS === 'ios') {
      return <BottomTabBar {...props} />;
    }
    if (!visible) return null;
    return <BottomTabBar {...props} />;
  };

  return render();
};

export default function(props) {
  const isFocused = useIsFocused();
  return <CustomBottomTabBar {...props} isFocused={isFocused} />;
}
