import React, { useContext } from 'react';
import { View, ScrollView, Text, Platform } from 'react-native';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { RoundButton } from '../common';
import { colors, textStyles, sizes } from '../../config/common-styles';
import { BrandContext } from '/common/config/branding';

console.log(Platform.Version);

/**
 * We return the state with all the props anytime something changes.
 * What is changing doesn't map perfectly to what is in the object (first name, last name vs name),
 * So it's easier this way. I guess. I hate forms...
 */
const UpdateBluetoothPermissions = observer(function({
  onPressPrivacyPolicy,
  onPressEnableBluetooth,
  onPressOpenSettings,
  bluetoothPermissionsReadyStatus,
}) {
  const { t } = useTranslation();

  const branding = useContext(BrandContext);

  // play around with the states
  //const trackingConsentStatus = { canAsk: true, canTrack: true, canTrackAlways: true }

  if (
    !bluetoothPermissionsReadyStatus ||
    !bluetoothPermissionsReadyStatus.bluetoothPermissionsStatus
  ) {
    return null;
  }

  const bluetoothPermissionsStatus = bluetoothPermissionsReadyStatus.bluetoothPermissionsStatus;

  let consentStatus;

  let settingsCallToAction = null;

  // not decided yet
  if (bluetoothPermissionsStatus.canAsk) {
    consentStatus = [
      <Text
        key="1"
        style={[textStyles.medium.dark, { marginHorizontal: sizes.medium, textAlign: 'center' }]}>
        In order to connect your device, you need to approve access to nearby devices.
      </Text>,
    ];
    consentStatus.push(
      <RoundButton
        key="2"
        buttonStyle={{ marginTop: sizes.large, marginBottom: sizes.large }}
        theme="lightOnDark"
        onPress={onPressEnableBluetooth}
        title="Grant Access to Bluetooth"
      />
    );
  } else if (bluetoothPermissionsReadyStatus.bluetoothPermissionsReady) {
    // answered already in the 100% affirmative
    consentStatus = (
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          marginHorizontal: sizes.large,
          alignItems: 'center',
          padding: sizes.medium,
          backgroundColor: colors.green + '33',
          borderColor: colors.green,
          borderRadius: sizes.borderRadius,
          borderWidth: 1,
        }}>
        <Text style={[textStyles.medium.dark, { textAlign: 'center', flex: 1 }]}>
          You have granted access to nearby devices. Thank you!
        </Text>
      </View>
    );
  } else {
    // answered already, rejected completely
    consentStatus = (
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          marginHorizontal: sizes.large,
          alignItems: 'center',
          padding: sizes.medium,
          backgroundColor: colors.error + '33',
          borderColor: colors.error,
          borderRadius: sizes.borderRadius,
          borderWidth: 1,
        }}>
        <Text style={[textStyles.medium.dark, { textAlign: 'center', flex: 1 }]}>
          Access to nearby devices has been rejected previously. It can be enabled in your app settings.
        </Text>
      </View>
    );

    settingsCallToAction = [
      // add settings button at the bottom regardless, as long as we've asked at least once
      <Text
        key="settingsText"
        style={[
          textStyles.centered,
          textStyles.standard.light,
          {
            marginTop: sizes.large,
            marginBottom: sizes.small,
            marginHorizontal: sizes.large,
          },
        ]}>
        Open App Settings
      </Text>,
      <RoundButton
        key="button"
        buttonStyle={{ marginBottom: sizes.large, marginHorizontal: sizes.large }}
        theme="darkOnLight"
        onPress={onPressOpenSettings}
        title={'open app settings'}
      />,
    ];
  }

  return (
    <View style={{ flex: 1, backgroundColor: colors.bg0 }}>
      <ScrollView>
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <View style={{ width: '100%', alignItems: 'center', marginTop: sizes.medium }}>
            <View
              style={{
                width: '100%',
                borderBottomColor: colors.border0,
                borderBottomWidth: 0,
                marginBottom: sizes.medium,
              }}
            />
            <Text
              style={[
                textStyles.centered,
                textStyles.bold.dark,
                { marginBottom: sizes.small, marginHorizontal: sizes.medium },
              ]}>
              Enable access to nearby devices
            </Text>
            <Text
              style={[
                textStyles.centered,
                textStyles.standard.light,
                { marginBottom: sizes.small, marginHorizontal: sizes.large },
              ]}>
              Just one more thing... we need permission to find and connect to your bluetooth device.
            </Text>
            <Text
              onPress={onPressPrivacyPolicy}
              style={[
                textStyles.centered,
                textStyles.standard.dark,
                {
                  marginHorizontal: sizes.large,
                  color: branding.highlightedColor,
                  marginBottom: sizes.large,
                },
              ]}>
              See our privacy policy
            </Text>
            {consentStatus}
            {settingsCallToAction}
          </View>
        </View>
      </ScrollView>
    </View>
  );
});

export default UpdateBluetoothPermissions;
