import { types, flow, getEnv, applySnapshot } from 'mobx-state-tree';
import URI from 'urijs';
import idx from 'idx';
import { findIndex, first, sortBy } from 'lodash';
import { getAlphanumericKeyWithSize } from '/common/lib';
import { RootStoreBase } from '/common/stores';
import { navigationRoutes } from '../config/constants';
import SettingsStore from './settings';
import { ConversationsStore } from './conversations';
import { LogStore } from './log';
import { LoadingState } from './common';
import { getFormErrors } from '/common/lib/errors';
import AppLinkProcessor from './AppLinkProcessor';

/**
 * Root store. Includes programs and a way to initialize the graph from the
 * transport layer.
 */
const ClientRootStore = types
  .model('ClientRootStore', {
    isLoggedIn: types.optional(types.boolean, false),
    initState: types.optional(LoadingState, {}),
    loginState: types.optional(LoadingState, {}),
    logoutState: types.optional(LoadingState, {}),
    requestPasswordResetState: types.optional(LoadingState, {}),
    lastPasswordResetRequestedAt: types.frozen(),
    // SSO props
    // random string that we use to determine if SSO callback originated from the app
    ssoState: types.maybeNull(types.string),
    // sign up and onboarding
    signupActionState: types.optional(LoadingState, {}),
    onboardingActionState: types.optional(LoadingState, {}),
    // central services
    openShareableFileInOsViewerState: types.optional(LoadingState, {}),
    updateOnDemandAppsState: types.optional(LoadingState, {}),
    // child stores
    settingsStore: types.optional(SettingsStore, {}),
    conversationsStore: types.optional(ConversationsStore, {}),
    logStore: types.optional(LogStore, { id: 0 }),
    // onboarding
    onboardingTutorial: types.frozen(),
    // other abstracted logic
    appLinkProcessor: types.optional(AppLinkProcessor, {}),
    activeFrame: types.optional(
      types.enumeration(['programs', 'myData', 'coach', 'groups', 'settings', 'none']),
      'none'
    ),
    // indicates if any hints for the user to take action should show in the UI
    activeUserHints: types.optional(types.array(types.enumeration(['sync'])), []),
    // auto-use invite ID to use when entering the app after copying it
    copiedInviteId: types.maybeNull(types.string),
    // new pattern for in-app notifications - let's put them in a single variable and adjust display
    // based on the contents.
    // For tapping the notifications, all this logic is already in AppLinkProcessor, so
    // we can call appLinkProcessor.processLink({ link }) with roughly the same payload.
    // TODO: move message and group notifications over to the same pattern.
    foregroundNotification: types.frozen(),
    // OTA update status
    otaUpdateStatus: types.frozen(),
  })
  .views(self => ({
    get enabledData() {
      if (self.settingsStore.user) {
        return self.settingsStore.user.enabledData;
      }
      return {};
    },
  }))
  .actions(self => {
    // --- private ---

    /**
     * Set user properties to be associated with a user in Amplitude.
     * We do this separately so it doesn't block the UI and doesn't crash
     */
    const setAnalyticsIdentityProps = async () => {
      const Amplitude = getEnv(self).Amplitude;
      const branding = getEnv(self).getBranding();

      try {
        await self.settingsStore.loadCoaches();
        const coaches =
          self.settingsStore.coaches && self.settingsStore.coaches.length
            ? sortBy(
                self.settingsStore.coaches.map(c => c.id),
                c => c.id
              ).join(',')
            : null;

        Amplitude.setUserPropertiesAsync({
          coaches,
          brandKey: branding.brandKey,
        });
      } catch (error) {
        // swallow to avoid unrejected promise warning
        // Generally reasons this would fail would be minor (like just couldn't re-register that one time)
      }
    };

    const startValidicSession = async ({ endOldSession, bluetoothDevicesEnabled }) => {
      try {
        if (endOldSession) {
          await getEnv(self).syncRepository.endSession();
        }
        await getEnv(self).syncRepository.startSession();
        if (bluetoothDevicesEnabled) {
          if (endOldSession) {
            // this will get rid of the old session and any local storage settings about peripherals paired under the old session
            await getEnv(self).bluetoothSyncRepository.unpairBluetoothPeripherals();
            await getEnv(self).bluetoothSyncRepository.endSession();
          }
          await getEnv(self).bluetoothSyncRepository.startSession();
          resumeValidicServices({ bluetoothDevicesEnabled });
        }
      } catch (error) {
        // swallow to avoid unrejected promise warning
        // we now trigger reporting for this error in shouldReportError()
      }
    };

    const resumeValidicServices = async ({ bluetoothDevicesEnabled }) => {
      if (bluetoothDevicesEnabled) {
        await getEnv(self).bluetoothSyncRepository.resumeBluetoothServices();
      }
    };

    const setTimeZone = async () => {
      try {
        await getEnv(self).userRepository.reportUserTimezone();
      } catch (error) {
        // swallow to avoid unrejected promise warning
      }
    };

    const registerPushNotificationToken = async promptIfNeeded => {
      try {
        await getEnv(self).authenticationRepository.registerPushNotificationToken(promptIfNeeded);
      } catch (error) {
        // swallow to avoid unrejected promise warning
        // Generally reasons this would fail would be minor (like just couldn't re-register that one time)
      }
    };

    // -- end private --

    // need to wait until user is loaded in order to officially say we're logged in
    // We need that user ID in order to init pusher.
    // actions: signup, resume, login
    // sources: nudge, sso
    const onLogin = flow(function* onLogin({ source, action, trustUserCache = false }) {
      let shouldBlockForUserLoad = true;
      if (trustUserCache) {
        if (yield self.settingsStore.loadCachedUser()) {
          console.log('got the cache!');
          shouldBlockForUserLoad = false;
          // if cached user is loaded, don't block on loading user but still refresh it
          self.settingsStore.loadUser();
        }
      }
      if (shouldBlockForUserLoad) {
        // otherwise, block on loading user and fail if the user can't be loaded
        yield self.settingsStore.loadUser();
        // if we can't load the user, can't keep going. We're logged out.
        if (self.settingsStore.loadUserState.isFailed) {
          throw self.settingsStore.loadUserState.error;
        }
      }

      // setup onboarding queue if this is signup or some other intervention is required
      yield self.initOnboarding({ source, action });

      // stage user hints so they're seen when signing up
      if (action === 'signup') {
        yield getEnv(self).userRepository.stageUserHint('sync');
      }

      // add error reporting context
      getEnv(self).errorReporter.setUser({
        id: self.settingsStore.user.id,
      });

      // assign user ID to LogStore
      self.logStore = { id: self.settingsStore.user.id };

      self.isLoggedIn = true;

      // re-init validic session for HealthKit / SHealth / Bluetooth
      // changing session will invalidiate Validic session. Logging out explicitly will end old session, but logging in after a 401/ timeout will not
      // Therefore, we want to compare user ID's and end the session if the user ID changed since last login.
      const cachingRepository = getEnv(self).cachingRepository;
      const lastLoggedInUser = yield cachingRepository.getItem('LastLoggedInUser');
      console.log(lastLoggedInUser);
      const didReloginAsDifferentUser =
        action !== 'resume' &&
        (!lastLoggedInUser || lastLoggedInUser.id !== self.settingsStore.user.id); // force ending of session if no previously recorded user / previously recorded user doesn't match
      cachingRepository.setItem('LastLoggedInUser', { id: self.settingsStore.user.id });
      // we don't need to wait for this to finish
      const branding = getEnv(self).getBranding();
      startValidicSession({
        endOldSession: didReloginAsDifferentUser,
        bluetoothDevicesEnabled: branding.sync.bluetoothDevicesEnabled,
      });

      // also report timezone updates
      setTimeZone();

      // check for unread messages
      self.updateUnreadMessageCount();

      // Try updating push notification token in case it changed somehow
      // default is to prompt on iOS just in case this is their first time logging in since app installed
      // but this will be suppressed if onLogin is called during signup
      if (action === 'signup') {
        registerPushNotificationToken();
      } else {
        registerPushNotificationToken(true);
      }

      // init pusher notification receiving
      const pusherListener = getEnv(self).pusherListener;
      pusherListener.onMessageDelivered = async message => {
        self.updateUnreadMessageCount();
        self.conversationsStore.onConversationUpdatedByCoach({
          coachId: message.coach_id,
          messageId: message.message,
          isNewMessage: true, // required by NewMessageBar
        });
      };
      pusherListener.onMessageUpdated = message => {
        self.conversationsStore.onConversationUpdatedByCoach({
          coachId: message.coach_id,
          messageId: message.message,
        });
      };
      pusherListener.onMessageDeleted = message => {
        self.conversationsStore.onConversationUpdatedByCoach({
          coachId: message.coach_id,
          messageId: message.message,
          isDeleted: true,
        });
      };
      pusherListener.onLogUpdated = () => {
        // if logs are getting updated and daily and trends aren't showing up, they probably should be soon
        if (!self.enabledData.tracking) {
          // reload user to see if we should enable daily/ trends or both
          self.settingsStore.loadUser();
        }
      };
      pusherListener.onInviteUpdated = () => {
        // reload user when new invite is sent
        self.settingsStore.loadUser();
      };
      pusherListener.onGroupTopicFeedUpdated = payload => {
        self.updateUnreadMessageCount();
        if (self.socialStore.clubs.length > 1) {
          // reload group unread counts, as well
          self.socialStore.loadClubs();
        }
        self.socialStore.setLatestSocialFeedUpdate({
          clubId: payload.club.id,
          commentId: payload.comment.id,
        });
      };
      pusherListener.init();
      pusherListener.startListeningForUser(self.settingsStore.user.id);

      // init app state change behaviors
      const appStateListener = getEnv(self).appStateListener;
      appStateListener.onActive = async () => {
        const branding = getEnv(self).getBranding();
        // this will restart if it is needed
        // if session isn't ended, because we don't end the old one, all it will do is check that the current one is active
        startValidicSession({
          endOldSession: false,
          bluetoothDevicesEnabled: branding.sync.bluetoothDevicesEnabled,
        });
        setTimeZone();
        // reload user to see if we should enable daily/ trends or both
        await self.settingsStore.loadUser();
        self.updateUnreadMessageCount();
        // this is how we make sure the main page of the log screen stays up to date and the immediate surrounding days load properly
        if (self.enabledData.tracking || self.enabledData.programs) {
          // must happen before loading logs
          await self.updateOnDemandApps();
          self.logStore.loadNewestProgramEvents();
          // this depends on data from loadNewestProgramEvents, so, if the data loaded above would trigger the hint,
          // the user might not actually see the hint until the next onActive. That should be OK.
          self.logStore.loadTags();

          self.updateUserHints();
        }
        // with Expo 45 and Android 13 there's a bug where the app doesn't immediately
        // detect that the user has approved Push Notifications. Go ahead and re-register
        // the token every time the app resumes - should keep things up to date.
        // PLUS it appears that an onActive event fires after the user dismisses the push modal
        // so we get an async workaround for the expo bug
        registerPushNotificationToken();

        // check if there's an OTA update
        getEnv(self).otaUpdateListener.forceCheckAndUpdate();
      };
      appStateListener.onInactive = () => {};
      appStateListener.startListening();

      // init push notification handling
      const pushNotificationListener = getEnv(self).pushNotificationListener;
      pushNotificationListener.onNotificationReceivedInForeground = async notification => {
        if (notification.club_id) {
          self.socialStore.setLatestNotification(notification);
        } else if (notification.card_id) {
          // new pattern to be used for all notifications
          if (!self.enabledData.programs) {
            // refresh to see if tab should be enabled
            await self.settingsStore.loadUser();
          }
          await self.logStore.loadNewestProgramEvents();
          self.setForegroundNotification(notification);
        }
      };
      pushNotificationListener.startListening();

      // init listening to OTA updates
      const otaUpdateListener = getEnv(self).otaUpdateListener;
      otaUpdateListener.onOtaUpdateEventReceived = event => {
        self.setOtaUpdateStatus(event);
      };
      otaUpdateListener.startListening();

      // process any pending links
      self.appLinkProcessor.processCurrentLink();

      // Preload coaches and conversation stubs (not actual conversations so we don't clear out unread counts)
      //self.conversationsStore.loadInitial({ forceReloadNoCoachPage: true });
      // Preload group conversation stubs (not actual group topic so we don't clear unread counts)
      // This triggers subscription to group update pusher events
      reloadCriticalData();
    });

    const reloadCriticalData = async options => {
      if (self.enabledData.tracking || self.enabledData.programs) {
        // must happen before loading logs
        await self.updateOnDemandApps();
        await self.logStore.loadNewestProgramEvents(options); // this should load a little sooner than normal without double-loading
        self.logStore.loadTags();
        self.updateUserHints();
      }
      self.conversationsStore.loadInitial({ forceReloadNoCoachPage: true });
      self.socialStore.loadClubs();

      // these may change as critical data is reloaded - particularly, coaches may change
      setAnalyticsIdentityProps();
    };

    const clearCriticalData = () => {
      applySnapshot(self.logStore, { id: self.logStore.id });
      applySnapshot(self.conversationsStore, {});
      applySnapshot(self.socialStore, {});
    };

    // --- init / login / logout ---
    // This may be called anytime we need to reinitialize who is logged in
    // (e.g., after a new user registration)
    const init = flow(function* init() {
      // Weird: AppLinkProcessor afterCreate() is not called until the model is referenced
      // So, here's a reference.
      // We might need to think of a better way around this
      // See https://github.com/mobxjs/mobx-state-tree/issues/1089
      self.appLinkProcessor.lastAppLink;
      // end weird stuff

      self.initState.setPending();

      // check if logged in
      try {
        const branding = getEnv(self).getBranding();
        const token = yield getEnv(self).apiTokenManager.getStoredApiToken();
        if (token) {
          yield getEnv(self).apiTokenManager.setApiToken(token);
          yield onLogin({
            source: 'nudge',
            action: 'resume',
            trustUserCache: branding.system.cacheUser,
          });
        } else {
          self.tryStartSignupFromCopiedInviteId();
          getEnv(self).appStateListener.onActive = () => {
            // handle case where the user starts app, leaves to copy ID,
            // then comes back.
            // This shouldn't interrupt login because of guards against copying ID
            // only before starting login or during certain steps of startup
            // this check will be removed in onLogin()
            self.tryStartSignupFromCopiedInviteId();
          };
          getEnv(self).appStateListener.startListening();

          // load bluetooth peripherals to indicate if they are synced
          self.settingsStore.loadBluetoothPeripherals();
        }

        resumeValidicServices({ bluetoothDevicesEnabled: branding.sync.bluetoothDevicesEnabled });
        self.initState.setDone();
      } catch (error) {
        // clears anything cached.
        self.initState.setFailed(error);
      }
    });

    const tryStartSignupFromCopiedInviteId = flow(function* tryStartSignupFromCopiedInviteId() {
      // stay safe!
      if (self.baseProtectedRoute === 'loggedIn') {
        return;
      }

      // never fails, returns null if we can't use clipboard contents
      const clipboardContents = yield getEnv(self).getClipboardStringIfAvailable();

      // if invite ID used at sign up and there's one in your clipboard, head over to sign up
      if (
        getEnv(self).getBranding().signUp.inviteIdRequired &&
        clipboardContents &&
        clipboardContents.length < 33 &&
        clipboardContents.length > 4
      ) {
        try {
          yield getEnv(self).userRepository.validatePromoCode(clipboardContents);
          // don't do this if user has already logging in or is past this point in sign up
          if (self.baseProtectedRoute !== 'loggedIn') {
            // if logged out, and we haven't copied it already, attempt to navigate to the entire invite ID screen
            if (
              self.baseProtectedRoute === 'loggedOut' &&
              self.copiedInviteId !== clipboardContents
            ) {
              const navigation = yield getEnv(self).getNavigation();
              navigation.navigate(navigationRoutes.stacks.login.inviteId);
            }
            // otherwise, we just let the signup process pick it up later
            self.copiedInviteId = clipboardContents;
          }
        } catch (error) {}
      }
    });

    const requestPasswordReset = flow(function* requestPasswordReset({ username }) {
      self.requestPasswordResetState.setPending();
      try {
        yield getEnv(self).authenticationRepository.requestPasswordReset(
          username ? username.trim() : ''
        );
        self.requestPasswordResetState.setDone();
        self.lastPasswordResetRequestedAt = new Date();
      } catch (error) {
        self.requestPasswordResetState.setFailed(error);
      }
    });

    const login = flow(function* login({ username, password }) {
      self.loginState.setPending();
      try {
        yield getEnv(self).authenticationRepository.login(
          username ? username.trim() : '',
          password
        );
        yield onLogin({ source: 'nudge', action: 'login' });
        self.loginState.setDone();
      } catch (error) {
        self.loginState.setFailed(error);
      }
    });

    /**
     * Executed on signup and login when already signed up (we don't know until its done)
     */
    const loginWithSso = flow(function* loginWithSso(callbackUrl) {
      self.loginState.setPending();
      const url = callbackUrl;
      const branding = getEnv(self).getBranding();
      const Platform = getEnv(self).Platform;

      const ssoProps = branding.sso[Platform.OS];
      try {
        const parsedLink = URI.parse(url);
        const queryParams = URI.parseQuery(parsedLink.query);
        // check URL
        // At least in some environments, the URL gets changed to exp:// in iOS 10
        if (parsedLink.hostname === ssoProps.listenForUriPart) {
          // check state
          if (queryParams.state !== self.ssoState) {
            self.loginState.setFailed(new Error(), [{ field: 'sso', type: 'RESPONSE_MALFORMED' }]);
          } else if (!queryParams.code) {
            self.loginState.setFailed(new Error(), [
              { field: 'sso', type: 'NO_AUTHORIZATION_CODE' },
            ]);
          } else {
            const loginResult = yield getEnv(self).authenticationRepository.loginWithSso({
              service: ssoProps.service,
              clientId: ssoProps.clientId,
              code: queryParams.code,
            });
            yield onLogin({ action: loginResult.isNewUser ? 'signup' : 'login', source: 'sso' });
            self.loginState.setDone();
            return loginResult;
          }
        } else {
          self.loginState.setFailed(new Error(), [{ field: 'sso', type: 'UNRECOGNIZED_URL' }]);
        }
      } catch (error) {
        console.log(error);
        console.log(callbackUrl);
        if (idx(error, _ => _.response.data.error) === 'validic user not provisioned') {
          self.loginState.setFailed(error, [
            { field: 'sso', type: 'VALIDIC_USER_NOT_PROVISIONED' },
          ]);
          return;
        }
        self.loginState.setFailed(error, [{ field: 'sso', type: 'UNEXPECTED' }]);
        // log unexpected errors to Sentry because it could indicate SSO server is down
        getEnv(self).errorReporter.captureException(
          new Error('Unexpected SSO login error: ' + idx(error, _ => _.response.data.error))
        );
      }
    });

    /**
     * Gets the SSO URL and sets the state (which prevents replay attacks)
     */
    const getSsoUrl = () => {
      const branding = getEnv(self).getBranding();
      const authUrl = new URI(branding.sso.authorizationTokenEndpoint);
      const ssoProps = branding.sso[getEnv(self).Platform.OS];
      self.ssoState = getAlphanumericKeyWithSize(30);
      authUrl.search({
        client_id: ssoProps.clientId,
        scope: branding.sso.scope.join(' '),
        state: self.ssoState,
        response_type: 'code',
        redirect_uri: ssoProps.redirectUri,
      });
      return authUrl.toString();
    };

    const getCallbackUrls = () => {
      const branding = getEnv(self).getBranding();
      return branding.sso
        ? {
            ios:
              branding.sso.ios.redirectUri === ''
                ? 'com.nudgeyourself.nudge://'
                : branding.sso.ios.redirectUri,
            android:
              branding.sso.android.redirectUri === ''
                ? 'com.nudgeyourself.nudge://'
                : branding.sso.android.redirectUri,
          }
        : {
            ios: 'com.nudgeyourself.nudge://',
            android: 'com.nudgeyourself.nudge://',
          };
    };

    const logout = flow(function* logout(userInitiated = false) {
      try {
        self.logoutState.setPending();
        // since we auto-logout on any 401, we don't want to always try to kill the session
        // unless we end up with many 401's and cause an infinite loop
        // Also, kill the session before we clear the token
        if (userInitiated) {
          try {
            yield getEnv(self).authenticationRepository.logout();
          } catch (error) {}
        }
        yield getEnv(self).apiTokenManager.setApiToken('');
        // TODO: refactor into some kind of onLogout
        getEnv(self).pusherListener.stopListening();
        getEnv(self).pushNotificationListener.stopListening();
        getEnv(self).appStateListener.stopListening();
        // only want to end sync sessions if user explicitly logged out
        // this function may be called if there was just a timeout, in which case
        // we want syncing to continue
        const branding = getEnv(self).getBranding();
        // kill validic sessions if the user is explicitly logging out or if the session is ending and we aren't supposed to keep the session
        if (userInitiated || !branding.system.keepValidicSessionOnAutoLogout) {
          getEnv(self).syncRepository.endSession();
          if (branding.sync.bluetoothDevicesEnabled) {
            // ending the session will crash on android if bluetooth permissions aren't there (aka for a non-bluetooth app)
            getEnv(self).bluetoothSyncRepository.unpairBluetoothPeripherals();
            getEnv(self).bluetoothSyncRepository.endSession();
          }
        }
        self.isLoggedIn = false;
        self.logoutState.setDone();
        self.clearEverything();
        // remove error reporting context
        getEnv(self).errorReporter.setUser({
          id: null,
        });
        getEnv(self).userRepository.clearCachedUser();
        getEnv(self).cachingRepository.deleteItem('enabledData');
        getEnv(self).userRepository.resetUserHints(); // reset hints for next login
        // load bluetooth peripherals to indicate if they are synced outside of session
        self.settingsStore.loadBluetoothPeripherals();
      } catch (error) {
        self.logoutState.setFailed(error);
      }
    });

    const clearEverything = () => {
      applySnapshot(self, { perspective: 'client' });
    };

    // Link reporting
    const reportLinkClicked = flow(function* removeCoach({ url, contextId, contextType }) {
      // fire and forget, don't worry about errors, nobody cares if this fails
      try {
        yield getEnv(self).userRepository.reportLinkClicked({ url, contextId, contextType });
      } catch (error) {}
    });

    // -- unread message count --

    const updateUnreadMessageCount = flow(function* updateUnreadMessageCount() {
      try {
        if (self.enabledData.groups || self.enabledData.coaches) {
          const counts = yield getEnv(self).userRepository.getUnreadMessageCount();
          self.tabBadges = counts;
          // suppress incrementing counts when the active frame is looking at the affected messages
          if (self.activeFrame === 'coach') {
            self.tabBadges.messages = 0;
          }
          if (self.activeFrame === 'groups') {
            self.tabBadges.social = 0;
          }
        }
      } catch (error) {
        // don't break anything if this doesn't work for some reason
      }
    });

    // -- active frame / tab checking --
    // weird to start tight-coupling the data with view again like this, but seems to be necessary for the
    // best experience
    const setActiveFrame = frame => {
      self.activeFrame = frame;
    };

    // -- central services --

    const openShareableFileInOsViewer = flow(function* openShareableFileInOsViewer(fileId) {
      const { IntentLauncher, WebBrowser, Platform } = getEnv(self);
      self.openShareableFileInOsViewerState.setPending();
      try {
        const url = yield getEnv(self).commonRepository.getShareableUrl(fileId);
        self.openShareableFileInOsViewerState.setDone();
        if (Platform.OS === 'ios') {
          WebBrowser.dismissBrowser();
          WebBrowser.openBrowserAsync(url);
        } else if (Platform.OS === 'android') {
          yield IntentLauncher.startActivityAsync('android.intent.action.VIEW', {
            type: 'application/pdf',
            data: url,
          });
        } else if (Platform.OS === 'web') {
          window.open(url, '_blank');
        }
      } catch (error) {
        self.openShareableFileInOsViewerState.setFailed(error);
      }
    });

    const updateOnDemandApps = flow(function* updateOnDemandApps() {
      self.updateOnDemandAppsState.setPending();
      try {
        yield getEnv(self).logRepository.requestUpdateOnDemandApps(getEnv(self).getToday());
        self.updateOnDemandAppsState.setDone();
      } catch (error) {
        self.updateOnDemandAppsState.setFailed(error);
      }
    });

    const setForegroundNotification = n => {
      self.foregroundNotification = n;
    };

    // --- sign up / onboarding ---

    const registerAccount = flow(function* registerAccount({
      firstName,
      lastName,
      email,
      password,
      inviteId,
      clientUserId,
    }) {
      try {
        self.signupActionState.setPending();
        if (!firstName || firstName.trim() === '') {
          const newError = new Error();
          newError.formErrors = [{ field: 'firstName', type: 'blank' }];
          throw newError;
        }
        yield getEnv(self).userRepository.registerUser({
          firstName,
          lastName,
          email,
          password,
          inviteId,
          clientUserId,
        });
        yield onLogin({ action: 'signup', source: 'nudge' });
        self.signupActionState.setDone();
      } catch (error) {
        self.signupActionState.setFailed(error, getFormErrors(error));
      }
    });

    /**
     * Checks if invite ID is valid
     */
    const validateInviteId = flow(function* validateInviteId(inviteId) {
      try {
        self.signupActionState.setPending();
        yield getEnv(self).userRepository.validatePromoCode(inviteId);
        self.signupActionState.setDone();
      } catch (error) {
        if (error.response && error.response.status === 400) {
          // still throw so it goes to goToNextStepStatus, but attach a formError for on-screen handling
          error.formErrors = [{ field: 'inviteId', type: 'invalid' }];
        }
        self.signupActionState.setFailed(error, getFormErrors(error));
      }
    });

    const registerForPushNotifications = flow(function* registerForPushNotifications() {
      try {
        self.onboardingActionState.setPending();
        yield getEnv(self).authenticationRepository.registerPushNotificationToken(true);
        self.onboardingActionState.setDone();
      } catch (error) {
        self.onboardingActionState.setFailed(error);
      }
    });

    const markOnboardingStepComplete = flow(function* markOnboardingStepComplete(route) {
      const screenIndex = findIndex(self.onboardingScreens, os => os.route === route);
      // if last screen, remove all onboarding steps, which causes onboarding to finish
      if (screenIndex === self.onboardingScreens.length - 1) {
        self.onboardingScreens = [];

        // try a reload after onboarding is complete to work around this issue:
        // https://github.com/nudgeyourself/nudge-api/issues/81
        // still possibility of timing issues if auto-assign is slow
        try {
          yield self.settingsStore.loadUser();
          yield self.logStore.loadNewestProgramEvents();
        } catch (error) {}
      } else if (screenIndex > -1) {
        // move ahead to next screen
        const navigation = yield getEnv(self).getNavigation();
        navigation.navigate(self.onboardingScreens[screenIndex + 1].route);
      }
    });

    // This is public so we can test the onboarding setup in isolation from the debug screen
    // adding onboarding items to the queue will switch over to the onboarding workflow
    const initOnboarding = flow(function* initOnboarding({ action, source }) {
      const branding = getEnv(self).getBranding();
      const Platform = getEnv(self).Platform;
      if (action === 'signup') {
        if (source === 'sso') {
          // invite ID
          if (branding.signUp.showInviteIdStep) {
            self.onboardingScreens.push({ route: navigationRoutes.stacks.onboarding.inviteId });
          }
        }
        if (getEnv(self).Platform.OS === 'ios') {
          // push notifications
          const arePushNotificationsApproved = yield getEnv(
            self
          ).authenticationRepository.isPushNotificationPermissionGranted();
          if (!arePushNotificationsApproved) {
            self.onboardingScreens.push({
              route: navigationRoutes.stacks.onboarding.pushNotifications,
            });
          }
        }

        // connected apps are web-only
        if (Platform.OS !== 'web') {
          if (branding.signUp.showConnectedAppsStep) {
            // connected apps
            self.onboardingScreens.push({
              route: navigationRoutes.stacks.onboarding.connectedApps,
            });
          }
          if (branding.signUp.showConnectedBluetoothPeripheralsStep) {
            // connected apps
            self.onboardingScreens.push({
              route: navigationRoutes.stacks.onboarding.connectedBluetoothPeripherals,
            });
          }
        }

        // nobody uses this anymore
        if (branding.locationTracking.enabled) {
          // otherwise, always show something about consent, even if it's just that it was already accepted
          self.onboardingScreens.push({
            route: navigationRoutes.stacks.onboarding.updateTrackingConsent,
          });
        }

        // add callout to download mobile app
        if (Platform.OS === 'web') {
          // only enable temporarily for nudge, as the download page doesn't yet work for white labels
          self.onboardingScreens.push({
            route: navigationRoutes.stacks.onboarding.downloadMobileApp,
          });
        }

        // add OTA update wrap-up to mobile
        // not doing this yet because sometimes the reload crashes on Android!
        /*if (Platform.OS !== 'web') {
          self.onboardingScreens.push({
            route: navigationRoutes.stacks.onboarding.wrapUp,
          });
        }*/

        // always show tutorial, even if somehow it was already shown
        // (this is good for testing the tutorial, since we create a lot of new test accounts, or sometimes use debug tools to check the signup process)
        /*if (branding.signUp.showTutorial) {
          // we no longer show the tutorial, it's old news
          self.onboardingScreens.push({ route: navigationRoutes.stacks.onboarding.tutorial });
        }*/
        // (safe call, never throws) - still need to ack, tho, so it doesn't show up again
        yield getEnv(self).userRepository.checkAndAckHasUsedNewFeaturesBefore();
      }
    });

    const getOnboardingTutorial = flow(function* getOnboardingTutorial() {
      try {
        self.onboardingActionState.setPending();
        self.onboardingTutorial = yield getEnv(self).userRepository.getNewFeatureTutorial();
        self.onboardingActionState.setDone();
      } catch (error) {
        self.onboardingActionState.setFailed(error);
      }
    });

    // --- Test/ Debug functionality ---

    // grab pre-defined debug information from unconventional sources
    const getDebugInfo = infoField => {
      if (infoField === 'lastSelectedNotificationPayload') {
        return getEnv(self).pushNotificationListener.lastSelectedNotificationPayload;
      }
      if (infoField === 'lastReceivedInForegroundNotificationPayload') {
        return getEnv(self).pushNotificationListener.lastReceivedInForegroundNotificationPayload;
      }
      return null;
    };

    const requestTestNotification = flow(function* requestTestNotification({
      dataType,
      notificationType,
    }) {
      if (dataType === 'programCard') {
        const firstCardEvent = first(self.logStore.programEventsSorted);
        if (notificationType === 'selected') {
          self.appLinkProcessor.setAppLink({
            source: 'notification',
            data: {
              card_id: firstCardEvent.programCard.id,
              date: firstCardEvent.date,
              body: 'Hey check out this new card!',
            },
          });
          self.appLinkProcessor.processCurrentLink();
        } else if (notificationType === 'foreground') {
          self.foregroundNotification = {
            card_id: firstCardEvent.programCard.id,
            date: firstCardEvent.date,
            body: 'Hey check out this new card!',
          };
        }
      }
    });

    // -- user hints --

    /**
     * Updates activeUserHints based on criteria for showing hints (e.g., certain data exists, hint hasn't been dismissed).
     * Assumes required data has already been loaded
     */
    const updateUserHints = flow(function* updateUserHints() {
      const updatedUserHints = [];
      // sync hint (the only one right now)
      const branding = getEnv(self).getBranding();
      const Platform = getEnv(self).Platform;
      if (branding.sync.showUserHint) {
        const userHints = yield getEnv(self).userRepository.getUserHints();
        // if not previously acked and there's at least one tracker loaded that can sync, show the prop
        if (
          Platform.OS !== 'web' &&
          userHints.sync &&
          !userHints.sync.acked &&
          self.logStore.trackers.find(t => t.clientMeta.canSync)
        ) {
          updatedUserHints.push('sync');
        }
      }
      self.activeUserHints = updatedUserHints;
    });

    const ackUserHint = function ackUserHint(key) {
      getEnv(self).userRepository.ackUserHint(key);
      self.activeUserHints = self.activeUserHints.filter(h => h !== key);
    };

    // diagnostic/ debug only
    const unAckUserHints = function unAckUserHints() {
      getEnv(self).userRepository.unAckUserHint('sync');
    };

    return {
      login,
      init,
      logout,
      loginWithSso,
      requestPasswordReset,
      clearEverything,
      reportLinkClicked,
      updateUnreadMessageCount,
      setActiveFrame,
      getSsoUrl,
      getCallbackUrls,
      onLogin,
      reloadCriticalData,
      clearCriticalData,
      openShareableFileInOsViewer,
      tryStartSignupFromCopiedInviteId,
      setForegroundNotification,
      // sign up
      registerAccount,
      validateInviteId,
      registerForPushNotifications,
      markOnboardingStepComplete,
      initOnboarding,
      getOnboardingTutorial,
      updateOnDemandApps,
      // UX
      updateUserHints,
      ackUserHint,
      unAckUserHints,
      // test
      requestTestNotification,
      getDebugInfo,
    };
  });

const RootStore = types.compose(RootStoreBase, ClientRootStore).named('RootStore');

export default RootStore;
