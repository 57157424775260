import React, { useRef, useCallback, useEffect, useState } from 'react';
import { Platform, View, Keyboard } from 'react-native';
import PropTypes from 'prop-types';
import { last, sortBy } from 'lodash';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import MessageBubble from './MessageBubble';
import InputToolbar from './InputToolbar';
import Day from './Day';
import EmptyListPlaceholder from '../EmptyListPlaceholder';
import { colors, useMediaQueryInfo } from '/common/config/styles';
import FullscreenImageModal from '/common/screens/FullscreenImageModal'; // bad organization, needed to avoid circular ref
import AestheticallyPleasingListFooter from '../AestheticallyPleasingListFooter';
import ChatList from './ChatList';

const newestMessageId = messages => {
  if (!messages.length) {
    return 0;
  }
  const lastItem = last(sortBy(messages, m => m.createdAt));
  if (lastItem.isPending) {
    // pending items don't have ID's
    return Number.MAX_SAFE_INTEGER;
  }
  return lastItem._id;
};

const Chat = observer(function({
  isLoadEarlierEnabled,
  onLoadEarlier,
  isLoadingEarlier,
  messages,
  inverted,
  currentUserId,
  MessageBubbleComponent,
  DayComponent,
  onPressAttachment,
  onLongPressMessage,
  onPressUrl,
  currentMessage,
  onCurrentMessageTextChanged,
  onPressSend,
  onPressChooseImage,
  onPressRemoveImage,
  showChooseImageButton,
  isOperationPending,
  placeholderText,
  showEmptyListPlaceholder,
  EmptyListPlaceholder,
  emptyChatPlaceholderBottomOffset,
  inputToolbarProps,
  editModeEnabled,
  onCancelEditing,
  showNotifyFlagToggle,
  toggleNotifyFlag,
  imageModalProps,
  persistentTopBubble,
  hoverMenuDirection,
  ...rest
}) {
  // ref to get scroll functions
  const chatListRef = useRef();

  const { innerWindowWidth, isTabletOrDesktop } = useMediaQueryInfo();

  const [keyboardShown, setKeyboardShown] = useState(false);

  useEffect(() => {
    const showSubscription = Keyboard.addListener('keyboardDidShow', () => {
      setKeyboardShown(true);
    });
    const hideSubscription = Keyboard.addListener('keyboardDidHide', () => {
      setKeyboardShown(false);
    });

    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);

  const scrollToLastItem = useCallback(() => {
    const scrollableRef = chatListRef.current;
    if (scrollableRef && messages.length) {
      scrollableRef.scrollToLastItem();
    }
  }, [chatListRef, messages, inverted]);

  // const image modal
  const imageModalRef = useRef();

  const onPressImage = useCallback(
    function(currentMessage) {
      if (imageModalRef.current) {
        imageModalRef.current.show(currentMessage.attachmentSource, currentMessage);
      }
    },
    [imageModalRef]
  );

  // ref to get previous messages
  const previousMessagesRef = useRef([]);
  useEffect(() => {
    // auto-scroll to top when there's a new message
    const oldMessages = previousMessagesRef.current;
    const newMessages = messages;
    if (
      oldMessages.length !== newMessages.length &&
      newestMessageId(newMessages) > newestMessageId(oldMessages)
    ) {
      scrollToLastItem();
    }
    // set current to previous for next round
    previousMessagesRef.current = messages;
  }, [messages, chatListRef, scrollToLastItem, previousMessagesRef]);

  useEffect(() => {
    if (keyboardShown) {
      scrollToLastItem();
    }
  }, [keyboardShown, scrollToLastItem]);

  return (
    <View
      style={{
        flex: 1,
        backgroundColor:
          Platform.OS === 'ios'
            ? colors.bg0 /* avoid the gray color showing up when bouncing scrollview */
            : colors.bg1,
      }}>
      <View style={{ flex: 1 }}>
        <ChatList
          ref={chatListRef}
          isLoadEarlierEnabled={isLoadEarlierEnabled}
          onLoadEarlier={onLoadEarlier}
          isLoadingEarlier={isLoadingEarlier}
          messages={messages}
          inverted={inverted}
          currentUserId={currentUserId}
          MessageBubbleComponent={MessageBubbleComponent}
          DayComponent={DayComponent}
          onPressAttachment={onPressAttachment}
          onPressImage={onPressImage}
          onLongPressMessage={onLongPressMessage}
          onPressUrl={onPressUrl}
          persistentTopBubble={persistentTopBubble}
          hoverMenuDirection={hoverMenuDirection}
          {...rest}
        />
        <InputToolbar
          style={{ width: innerWindowWidth, alignSelf: 'center' }}
          showChooseImageButton={showChooseImageButton}
          currentMessage={currentMessage}
          onChangeText={onCurrentMessageTextChanged}
          onPressSend={onPressSend}
          onPressChooseImage={onPressChooseImage}
          isOperationPending={isOperationPending}
          placeholderText={placeholderText}
          onPressRemoveImage={onPressRemoveImage}
          editModeEnabled={editModeEnabled}
          onCancelEditing={onCancelEditing}
          showNotifyFlagToggle={showNotifyFlagToggle}
          toggleNotifyFlag={toggleNotifyFlag}
          {...inputToolbarProps}
        />
        <View style={{ alignSelf: 'center' }}>
          <AestheticallyPleasingListFooter />
        </View>
        <FullscreenImageModal ref={imageModalRef} {...imageModalProps} />
      </View>
      {showEmptyListPlaceholder && EmptyListPlaceholder && !keyboardShown ? (
        <View
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: emptyChatPlaceholderBottomOffset,
          }}>
          <EmptyListPlaceholder />
        </View>
      ) : null}
    </View>
  );
});

Chat.propTypes = {
  // core customizable components
  MessageBubbleComponent: PropTypes.any,
  DayComponent: PropTypes.any,
  // core visuals / perspective
  currentMessage: PropTypes.shape({
    text: PropTypes.string,
    image: PropTypes.any,
    notifyFlag: PropTypes.bool,
  }).isRequired,
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCurrentMessageTextChanged: PropTypes.func.isRequired,
  inverted: PropTypes.bool,
  currentUserId: PropTypes.string,
  isOperationPending: PropTypes.bool,
  showChooseImageButton: PropTypes.bool,
  placeholderText: PropTypes.string,
  // buttons
  onPressSend: PropTypes.func,
  onPressAttachment: PropTypes.func,
  onPressChooseImage: PropTypes.func,
  onPressRemoveImage: PropTypes.func,
  // paging
  isLoadingEarlier: PropTypes.bool,
  isLoadEarlierEnabled: PropTypes.bool,
  onLoadEarlier: PropTypes.func,
  // empty state
  EmptyListPlaceholder: PropTypes.any,
  showEmptyListPlaceholder: PropTypes.bool,
  emptyChatPlaceholderBottomOffset: PropTypes.number,
  //passthroughs
  inputToolbarProps: PropTypes.object,
  // to be implemented
  onCancelUpdateMode: PropTypes.func,
  isUpdateModeEnabled: PropTypes.bool,
  onPressUpdate: PropTypes.func,
  // if true, show the notify toggle below chat
  showNotifyFlagToggle: PropTypes.bool,
};

Chat.defaultProps = {
  // core customizable components
  MessageBubbleComponent: MessageBubble,
  DayComponent: Day,
  // core visuals / perspective
  inverted: true,
  currentUserId: null,
  isOperationPending: false,
  showChooseImageButton: true,
  placeholderText: 'Type a message...',
  // buttons
  onPressSend: () => {},
  onPressAttachment: () => {},
  onPressChooseImage: () => {},
  onPressRemoveImage: () => {},
  // paging
  isLoadingEarlier: false,
  isLoadEarlierEnabled: false,
  onLoadEarlier: () => {},
  // empty state
  EmptyListPlaceholder: () => {
    const { t } = useTranslation();
    return (
      <EmptyListPlaceholder
        title={t('MESSAGING:NO_MESSAGES_MESSAGE:LINE1')}
        message={t('MESSAGING:NO_MESSAGES_MESSAGE:LINE2')}
      />
    );
  },
  showEmptyListPlaceholder: false,
  emptyChatPlaceholderBottomOffset: 250,
  // passthroughs
  inputToolbarProps: null,
  // to be implemented
  onCancelUpdateMode: () => {},
  isUpdateModeEnabled: false,
  onPressUpdate: () => {},
  showNotifyFlagToggle: false,
};

export default Chat;
