import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { DownloadMobileAppHowto } from '../../components/sign-up';
import { SimpleNavWrapper } from '../../components/navigation';
import { LoadingShadeWrapper } from '../../components/common';
import { navigationRoutes } from '../../config/constants';
import { containerStyles } from '../../config/common-styles';

const DownloadMobileAppHowtoScreen = inject('rootStore')(
  observer(({ rootStore }) => {
    const { t } = useTranslation();

    const onPressNext = useCallback(() => {
      const asyncInner = async () => {
        await rootStore.markOnboardingStepComplete(
          navigationRoutes.stacks.onboarding.downloadMobileApp
        );
      };
      asyncInner();
    }, [rootStore]);

    return (
      <SimpleNavWrapper>
        <LoadingShadeWrapper
          coversOnboardingScreen
          isLoading={rootStore.onboardingActionState.isPending}
          style={containerStyles.fillAvailableSpace}>
          <DownloadMobileAppHowto
            user={rootStore.settingsStore.user}
            onPressContinue={onPressNext}
          />
        </LoadingShadeWrapper>
      </SimpleNavWrapper>
    );
  })
);

export default DownloadMobileAppHowtoScreen;
