import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { observer, inject } from 'mobx-react';
import { connectAlertBar } from '/common/navigation';
import { LoadingShadeWrapper } from '../../components/common';
import { DeleteAccount } from '../../components/settings';
import { containerStyles } from '../../config/common-styles';
import buildModalWithNavHeader from './buildModalWithNavHeader';
import { SuccessAlertBar, ScreenError, withKeyboardAvoidance } from '../common';

@inject('rootStore')
@connectAlertBar
@observer
class DeleteAccountScreen extends Component {
  constructor() {
    super();
    this.state = {
      deleteInput: '',
      hasDeleteInputValidationError: false,
    };
  }

  _onChangeDeleteInput = text => {
    this.setState({ deleteInput: text });
  };

  _onPressDeleteAccount = () => {
    const { navigation, rootStore, showAlertBar, t } = this.props;
    const { deleteInput } = this.state;
    const comparator = t('SETTINGS:DELETE_ACCOUNT:COMPARATOR');
    navigation.setParams({ isModalCloseButtonDisabled: true });
    rootStore.settingsStore.deleteAccount({ deleteInput, comparator }).then(result => {
      navigation.setParams({ isModalCloseButtonDisabled: false });
      if (result.isError && result.reason === 'textDoesNotMatch') {
        this.setState({
          hasDeleteInputValidationError: true,
        });
      } else {
        this.setState({
          hasDeleteInputValidationError: false,
        });

        // if this is error state, let ScreenError pick it up
        if (rootStore.settingsStore.deleteAccountState.isDone) {
          showAlertBar({
            view: (
              <SuccessAlertBar
                title={t('SETTINGS:DELETE_ACCOUNT:CONFIRMATION_TITLE')}
                message={t('SETTINGS:DELETE_ACCOUNT:CONFIRMATION_MESSAGE')}
              />
            ),
          });

          rootStore.logout();
        }
      }
    });
  };

  render() {
    const { deleteInput, hasDeleteInputValidationError } = this.state;
    const { rootStore, t } = this.props;

    return (
      <LoadingShadeWrapper
        isLoading={rootStore.settingsStore.deleteAccountState.isPending}
        style={[containerStyles.fillAvailableSpace]}>
        <ScreenError
          state={rootStore.settingsStore.deleteAccountState}
          title={t('SETTINGS:DELETE_ACCOUNT:ERROR_DELETING_ACCOUNT')}
        />
        <DeleteAccount
          onPressDeleteAccount={this._onPressDeleteAccount}
          deleteInput={deleteInput}
          onChangeDeleteInput={this._onChangeDeleteInput}
          hasValidationError={hasDeleteInputValidationError}
        />
      </LoadingShadeWrapper>
    );
  }
}

DeleteAccountScreen.propTypes = {
  rootStore: PropTypes.object,
  navigation: PropTypes.object,
  t: PropTypes.func,
};

const DeleteAccountModal = buildModalWithNavHeader(
  DeleteAccountScreen,
  'Delete Account',
  'DeleteAccountModal'
);

export default withTranslation()(withKeyboardAvoidance(DeleteAccountModal));
