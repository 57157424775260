import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { observer, MobXProviderContext } from 'mobx-react';
import { NavbarWrapper, HeaderIconButton } from '/common/components/navigation';
import { ClubsList } from '/common/components/social';
import {
  ScreenFirstLoadWrapper,
  commonNavigationRoutes,
  useScreenBehaviors,
} from '/common/navigation/';
import { SettingsIcon } from '/common/config/icons';
//import SocialFeedScreen from './SocialFeedScreen';

const MultipleGroupsView = observer(({ navigation, perspective }) => {
  const onPressClub = useCallback(
    club => {
      navigation.navigate(commonNavigationRoutes.clubs.socialFeed, { clubId: club.id });
    },
    [navigation]
  );

  const { rootStore } = useContext(MobXProviderContext);
  const { socialStore } = rootStore;
  const clubs = socialStore.clubsSorted;

  return (
    <NavbarWrapper
      title="Communities"
      rightButton={
        perspective === 'coach' && (
          <HeaderIconButton
            icon={<SettingsIcon />}
            onPress={() => {
              navigation.navigate(commonNavigationRoutes.clubs.settings);
            }}
          />
        )
      }>
      <ClubsList
        clubs={clubs}
        onPressClub={onPressClub}
        perspective={perspective}
        feedSortMode={socialStore.feedSortMode}
      />
    </NavbarWrapper>
  );
});

/**
 * Version of social tab without navigation chrome or events.
 * Shows a single group's feed or multiple groups depending on context
 * This "base" screen handles all social UX, including:
 * - sending messages
 * - long press
 * - attach images
 * - errors
 * - loading spinners
 * - navbar
 * It does not handle:
 * - reloading of data on new message / enter screen
 * - keyboard avoidance
 * - wiring of top-level stores (rootStore, navigation, etc.)
 */
const SocialEntryPointScreen = observer(({ navigation, perspective, feedSortMode }) => {
  const { rootStore } = useContext(MobXProviderContext);
  const { socialStore } = rootStore;
  useScreenBehaviors({
    onFocus: socialStore.loadClubs,
    onActive: socialStore.loadClubs,
  });
  const onPressRefresh = useCallback(() => {
    socialStore.loadClubs();
  }, []);
  const clubs = socialStore.clubsSorted;

  // Let's do the view switching later when we merge coach and client together
  /*let innerView = null;

  if (clubs.length === 1) {
    innerView = (
      <SocialFeedScreen socialFeed={getSocialFeed(clubs[0].id)} navigation={navigation} />
    );
  } else if (clubs.length > 1) {
    innerView = <MultipleGroupsView navigation={navigation} />;
  } else if (clubs.length === 0 && socialStore.loadClubsState.isDone) {
    innerView = null; // user should never see this
  }*/

  return (
    <ScreenFirstLoadWrapper
      state={socialStore.loadClubsState}
      hasData={clubs.length}
      onPressRefresh={onPressRefresh}>
      <MultipleGroupsView
        navigation={navigation}
        perspective={perspective}
        feedSortMode={feedSortMode}
      />
    </ScreenFirstLoadWrapper>
  );
});

SocialEntryPointScreen.propTypes = {
  rootStore: PropTypes.object,
};

export default SocialEntryPointScreen;
