import React, { useContext, useEffect } from 'react';
import { Platform, View } from 'react-native';
//import Constants from 'expo-constants';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
//import { isIphoneX } from 'react-native-iphone-x-helper';
import { observer, inject } from 'mobx-react';
import { BottomTabBar } from '/common/navigation';
import { colors, sizes, useMediaQueryInfo } from '/common/config/styles';
import { BrandContext } from '/common/config/branding';
import env from '../../../config/env';
import { isOldPhone } from '/common/config/feature-support';
import useTabBarConfig from './useTabBarConfig';
import { navigationRoutes } from '../../../../app/config/constants';

const Tab = createBottomTabNavigator();

// PureFunction efficiency stuff, not really sure it helps
const bottomTabBarFunc = props => <BottomTabBar {...props} />;

/*const fadeInterpolator = ({ current: { progress }, next }) => {
  return { cardStyle: { opacity: next ? next.progress : progress } };
};*/

const MainTabs = inject('rootStore')(
  observer(function({ rootStore, navigation }) {
    const branding = useContext(BrandContext);

    const { settingsStore, logStore } = rootStore;

    // check for pending invites and fire Accpet/Decline screen if there are
    useEffect(() => {
      // we are waiting for programs to load before checking for pending invites
      // due to the invite modal not showing on clients web on login
      if (!settingsStore.loadUserState.isDone && logStore.loadProgramDataState.status !== 'done')
        return;
      if (settingsStore.user.invites.pending > 0) {
        navigation.navigate(navigationRoutes.stacks.main.acceptDecline);
      }
    }, [settingsStore.loadUserState.status, logStore.loadProgramDataState.status]);

    const tabBarConfig = useTabBarConfig();

    const { isWideScreen, isWeb } = useMediaQueryInfo();

    const innerTabs = (
      <Tab.Navigator
        tabBar={isWideScreen && isWeb ? () => null : bottomTabBarFunc}
        lazy={isOldPhone()}
        initialRouteName={
          env.debug.defaultTab ? env.debug.defaultTab : tabBarConfig[0].name // go to first tab by default
        }
        screenOptions={{ headerShown: false }}
        tabBarOptions={{
          allowFontScaling: false,
          labelPosition: 'below-icon',
          showLabel: !Platform.isPad,
          activeTintColor: branding.highlightedColor,
          inactiveTintColor: colors.lightText,
          keyboardHidesTabBar: Platform.OS === 'android',
          labelStyle: !Platform.isPad ? { paddingBottom: 4, marginTop: -3 } : undefined,
          style: [
            {
              backgroundColor: 'white',
              borderTopWidth: sizes.hairlineWidth,
              borderTopColor: colors.border0,
            },
          ],
        }}>
        {tabBarConfig.map(tab => (
          <Tab.Screen
            key={tab.name}
            name={tab.name}
            component={tab.component}
            options={tab.options}
          />
        ))}
      </Tab.Navigator>
    );

    if (isWideScreen && isWeb) {
      return (
        <View
          style={{
            flex: 1,
            /*backgroundColor: colors.bg1,
            flexDirection: 'row',
            justifyContent: 'center',*/
          }}>
          <View
            style={{
              flex: 1,
              /*width: 650*/
            }}>
            {innerTabs}
          </View>
        </View>
      );
    }

    return innerTabs;
  })
);

export default MainTabs;
