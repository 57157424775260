import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reaction } from 'mobx';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { withAlert } from '/common/navigation/';

import { LoadingWrapper } from '../../components/common';
import { NavbarWrapper, HeaderIconButton } from '../../components/navigation';
import { ConnectedAppList } from '../../components/profile';
import { CloseModalIcon } from '../../config/common-icons';
import { BrandContext } from '../../config/branding';
import { ScreenError, ScreenFirstLoadWrapper } from '../common';
import { navigationRoutes } from '../../config/constants';

@inject('rootStore', 'AuthWebBrowser')
@observer
class EditConnectedAppsModal extends Component {
  // hack to prevent multiple workflows and thus mutiple listeners. This should be made better
  static currentWorkflow;
  static contextType = BrandContext;

  componentDidMount() {
    const { rootStore, t, alert } = this.props;
    const branding = this.context;


    rootStore.settingsStore.loadConnectedApps();
    this.onAppConfirmReactionDisposer = reaction(
      () => rootStore.settingsStore.updateConnectedAppState.isDone,
      () => {
        if (rootStore.settingsStore.lastAppConnectAction.action === 'connect') {
          alert(
            t('GENERAL:SUCCESS'),
            t('SYNCED_APPS:CONFIRMATION', {
              appName: branding.appName,
              syncedAppName: rootStore.settingsStore.lastAppConnectAction.app.title,
            })
          );
        }
      }
    );
  }

  componentWillUnmount() {
    if (this.onAppConfirmReactionDisposer) {
      this.onAppConfirmReactionDisposer();
    }
  }

  _onPressStopApp = async app => {
    const { t, rootStore, navigation, alert } = this.props;
    const appTitle = app.title;
    const branding = this.context;

    if (app.isBluetooth) {
      navigation.navigate(navigationRoutes.modals.syncBluetoothDevice, {
        peripheral: app.bluetoothPeripheral,
      });
    } else {
      alert(
        t('SYNCED_APPS:STOP_APP_CONFIRM_TITLE'),
        t('SYNCED_APPS:STOP_APP_CONFIRM_MESSAGE', {
          appName: branding.appName,
          syncedAppName: appTitle,
        }),
        [
          {
            text: t('SYNCED_APPS:NO_KEEP_SYNCING'),
            onPress: () => {},
            style: 'cancel',
          },
          {
            text: t('SYNCED_APPS:YES_STOP_SYNCING', { syncedAppName: appTitle }),
            onPress: () => rootStore.settingsStore.unregisterConnectedApp(app),
            style: 'default',
          },
        ],
        { cancelable: false }
      );
    }
  };

  _onPressApp = app => {
    this._onPressApp_AfterConfirm(app);
    // TODO: put back onboarding for connected apps once we figure out how we will invoke it
    /*navigation.navigate(navigationRoutes.modals.onboarding, {
      url: 'https://nudgecoach.com/privacy/',
      confirmButtonText: 'continue',
      onPressConfirm: () => this._onPressApp_AfterConfirm(app),
      showCancelButton: true,
    });*/
  };

  _onPressApp_AfterConfirm = app => {
    const { AuthWebBrowser, rootStore } = this.props;
    if (app.isBuiltIn) {
      this.props.rootStore.settingsStore.registerBuiltInApp({ app });
    } else {
      AuthWebBrowser.openAuthSession(app.authUrl, rootStore.getCallbackUrls());
    }
  };

  render() {
    const { rootStore, navigation, t } = this.props;
    const { settingsStore } = rootStore;

    return (
      <NavbarWrapper
        title={t('SETTINGS:EDIT_CONNECTED_APPS')}
        leftButton={
          <HeaderIconButton
            icon={<CloseModalIcon />}
            onPress={() => {
              navigation.pop();
            }}
          />
        }
        hasBenignConfirmButton
        navigation={navigation}>
        <ScreenFirstLoadWrapper
          state={settingsStore.loadConnectedAppsState}
          hasData={settingsStore.connectedAppsSorted.length}
          serverUnavailableErrorMessage="Syncing new Connected Apps is temporarily disabled while we do some routine system maintenance. Please check back soon!"
          onPressRefresh={settingsStore.loadConnectedApps}>
          <LoadingWrapper
            style={{ flex: 1 }}
            isLoading={
              (settingsStore.loadConnectedAppsState.isPending &&
                settingsStore.connectedAppsSorted.length === 0) ||
              settingsStore.updateConnectedAppState.isPending
            }
            isFailed={settingsStore.loadConnectedAppsState.isFailed}>
            <ScreenError state={settingsStore.updateConnectedAppState} />
            <ConnectedAppList
              connectedApps={settingsStore.connectedAppsSorted}
              isRefreshing={settingsStore.loadConnectedAppsState.isPending}
              onRefresh={settingsStore.loadConnectedApps}
              onPressApp={this._onPressApp}
              onPressStopApp={this._onPressStopApp}
            />
          </LoadingWrapper>
        </ScreenFirstLoadWrapper>
      </NavbarWrapper>
    );
  }
}

EditConnectedAppsModal.propTypes = {
  rootStore: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withAlert(EditConnectedAppsModal));
