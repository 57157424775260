import React, { useContext, useState, useMemo, useRef, useCallback } from 'react';
import { SafeAreaView, TouchableOpacity, View, Text, Animated, Platform } from 'react-native';
import { isIphoneX } from 'react-native-iphone-x-helper';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import * as Device from 'expo-device';
import { useTranslation } from 'react-i18next';
import { getUniversalSvgComponents } from 'nudge-client-common/common-svg';
import {
  sizes,
  colors,
  textStyles,
  useMediaQueryInfo,
  useDeviceDimensions,
} from '/common/config/styles';
import { SecurableImage } from '/common/components';
import { FullscreenImageModal, VideoPlayerModal } from '/common/screens';
import { WhiteBackIcon } from '/common/config/icons';
import CardComponent from './internal/card-component';
import { CompletionGraph } from 'nudge-client-common/graphs';
import { BrandContext } from '../../config/branding';
import { descriptiveDateWithoutYear } from '../../config/strings';
import LoadingWrapper from '../loading-wrapper';
import RepeatingCardTimeline from './internal/RepeatingCardTimeline';
import cardConstants from './internal/cardConstants';
import ImageAttribution from './internal/ImageAttribution';
import { shouldRemoveAnimationsAndScrollTransitionsForSomeAndroidPhones } from '/common/config/feature-support';

/**
 * Loading State for a program card that is opened from a notification that may or may not be loaded yet
 */
const WaitingForProgramCard = observer(({ loadState, onPressBackOrConfirm }) => {
  const { statusBarHeight } = useDeviceDimensions();
  return (
    <View style={{ flex: 1 }}>
      <View
        style={{
          position: 'absolute',
          height: 40,
          top:
            cardConstants.getTopButtonTopOffset(statusBarHeight) +
            (2.5 + 3) / 2 /* cheat to center relative to completion graph */,
          left: 15,
          zIndex: 2,
        }}>
        <View
          style={{
            height: 40,
            backgroundColor: colors.darkText, //this.context.highlightedColor
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 30,
            paddingLeft: 15,
            paddingRight: 15,
          }}>
          <TouchableOpacity
            style={{
              height: 40,
              justifyContent: 'flex-start',
              alignItems: 'center',
              flexDirection: 'row',
            }}
            onPress={onPressBackOrConfirm}>
            <WhiteBackIcon />
            <Text
              allowFontScaling={false}
              style={{
                fontSize: 13,
                fontWeight: '700',
                padding: 5,
                textAlign: 'center',
                marginLeft: 5,
                color: colors.bg0,
              }}>
              Back
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        {loadState.isPending ? (
          <LoadingWrapper isLoading style={{ flex: 1 }} />
        ) : loadState.isFailed ? (
          /* need to do more work to make a more specific error message- that's pretty deep in our screen error handling code
         also, this doesn't handle the case where the load completed fine but the card isn't found. I originally showed the error
         message if the card simply wasn't there, but it would flash the error message before the spinner and it looked bad */ <View
            style={{ padding: sizes.large, alignItems: 'center', justifyContent: 'center' }}>
            <Text style={[textStyles.medium.dark, { textAlign: 'center' }]}>
              Sorry, we were unable to load this card.
            </Text>
            <Text
              style={[textStyles.standard.light, { textAlign: 'center', paddingTop: sizes.small }]}>
              Check your network connection, head on back, and try to refresh again
            </Text>
          </View>
        ) : null}
      </View>
    </View>
  );
});

/**
 * Back button containing date overlayed on top of feature image
 */
const BackButton = observer(({ programEvent, onPressBackOrConfirm, showDateOnBackButton }) => {
  const { leftOffsetFromEdge } = useMediaQueryInfo();
  const { t } = useTranslation();
  // keep track of the first event for populating the back button, so it doesn't change as the user switches between events
  const initialProgramEvent = useMemo(() => programEvent, []);
  const { statusBarHeight } = useDeviceDimensions();
  return (
    <SafeAreaView
      style={{
        position: 'absolute',
        height: 40,
        top:
          cardConstants.getTopButtonTopOffset(statusBarHeight) +
          (cardConstants.completionGraphStrokeWidth + 3) /
            2 /* cheat to center relative to completion graph */,
        left: 15 + leftOffsetFromEdge,
        zIndex: 2,
      }}>
      <View
        style={{
          height: 40,
          backgroundColor: colors.darkText, //this.context.highlightedColor
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 30,
          paddingLeft: 15,
          paddingRight: 15,
        }}>
        <TouchableOpacity
          style={{
            height: 40,
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: 'row',
          }}
          onPress={onPressBackOrConfirm}>
          <WhiteBackIcon />
          <Text
            allowFontScaling={false}
            style={[
              textStyles.standard.bold,
              {
                fontSize: 13,
                padding: 5,
                textAlign: 'center',
                marginLeft: 5,
                color: colors.bg0,
              },
            ]}>
            {showDateOnBackButton
              ? descriptiveDateWithoutYear(initialProgramEvent.date, { t })
              : 'Back'}
          </Text>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
});

/**
 * Entire detail screen, including prominent feature image, back button, goal circle (optionally goal timeline for repeating cards),
 * and card components.
 */

const ProgramCardDetail = observer(
  ({
    programEvent,
    onPressBackOrConfirm,
    showDateOnBackButton,
    onPressLink,
    onTrackOpenVideo,
    onPressViewTracker,
    onPressJoinGroup,
    onPressPdf,
    onPressAddLog,
    loadState,
    disableTransitions,
    focusableEvents,
    onPressEvent,
    disableAddLog,
  }) => {
    // image and video modal handling
    const imageModalRef = useRef();

    const { statusBarHeight } = useDeviceDimensions();

    const onPressImage = useCallback(
      function(component) {
        if (imageModalRef.current) {
          imageModalRef.current.show(component.image);
        }
      },
      [imageModalRef]
    );

    const videoModalRef = useRef();

    const { isTabletOrDesktop, innerWindowWidth, rightOffsetFromEdge } = useMediaQueryInfo();
    const windowWidth = innerWindowWidth;

    const onPressVideoToOpenModal = useCallback(
      function(component) {
        if (videoModalRef.current) {
          videoModalRef.current.show({
            url: component.url,
            provider:
              component.meta.providerName ||
              component.type /* new provider-specific component types */,
            meta: component.meta,
          });
          onTrackOpenVideo(component);
        }
      },
      [imageModalRef, onTrackOpenVideo]
    );

    const branding = useContext(BrandContext);

    const [scrollY] = useState(new Animated.Value(0));

    if (!programEvent) {
      return (
        <WaitingForProgramCard onPressBackOrConfirm={onPressBackOrConfirm} loadState={loadState} />
      );
    }

    const removeIncompatibleAnimations = shouldRemoveAnimationsAndScrollTransitionsForSomeAndroidPhones();

    const { programCard } = programEvent;

    // added isFirstTracker stuff when taking out header, so we can curve around the first tracker

    const featureImage = programCard.image ? programCard.image : null;
    const featImageWidth = windowWidth;
    const featImageHeight = (featImageWidth * 9) / 16;
    const HEADER_MIN_HEIGHT =
      Device.deviceName?.includes('iPhone 14 Pro') || isIphoneX() ? 110 : 90;
    const HEADER_SCROLL_DISTANCE = HEADER_MIN_HEIGHT;

    // Image opacity calc
    const imageOpacityScrollY = Animated.add(scrollY, 0);

    const imageOpacity = imageOpacityScrollY.interpolate({
      inputRange: [0, HEADER_SCROLL_DISTANCE],
      outputRange: [0, 1],
      extrapolate: 'clamp',
    });
    // end calc

    // only show the timeline when we can cycle through data
    // weird bit: going from 1+ to zero trackers will make the entire timeline dissappear
    const showTimeline =
      programEvent.share.repeatingType &&
      focusableEvents &&
      focusableEvents.length > 1 &&
      programCard.hasTrackers;

    const elements = programCard.expandedComponents.map((p, i) => {
      return (
        <CardComponent
          key={i.toString()}
          programEvent={programEvent}
          component={p}
          index={i}
          onPressViewTracker={onPressViewTracker}
          onPressAddLog={onPressAddLog}
          onPressImage={onPressImage}
          onPressLink={onPressLink}
          onPressVideo={onPressVideoToOpenModal}
          transitionType={disableTransitions ? 'none' : null}
          onPressJoinGroup={onPressJoinGroup}
          onPressPdf={onPressPdf}
          disableAddLog={disableAddLog}
        />
      );
    });

    const backButton = (
      <BackButton
        programEvent={programEvent}
        onPressBackOrConfirm={onPressBackOrConfirm}
        showDateOnBackButton={showDateOnBackButton}
      />
    );

    return (
      <View style={{ flex: 1, backgroundColor: colors.bg1 }}>
        {removeIncompatibleAnimations ? (
          <View style={{ minHeight: HEADER_MIN_HEIGHT }}>{backButton}</View>
        ) : null}
        <Animated.ScrollView
          useNativeDriver
          contentContainerStyle={{
            flexGrow: 1,
            width: windowWidth,
            alignSelf: 'center',
            backgroundColor: colors.bg0,
          }}
          showsVerticalScrollIndicator={
            Platform.OS !==
            'web' /* sometimes in some situations on some browsers, these shift the content over. So weird! */
          }
          scrollEventThrottle={1}
          onScroll={
            removeIncompatibleAnimations
              ? undefined
              : Animated.event([{ nativeEvent: { contentOffset: { y: scrollY } } }], {
                  useNativeDriver: true,
                })
          }
          // iOS offset for RefreshControl
        >
          <View
            style={{
              backgroundColor: colors.bg0,
              borderRadius: 0,
              overflow: 'hidden',
            }}>
            <View
              style={{
                overflow: 'hidden',
                height: featImageHeight,
              }}>
              <SecurableImage
                style={[
                  {
                    width: featImageWidth,
                    height: featImageHeight,
                    resizeMode: 'cover',
                  },
                ]}
                source={featureImage ? featureImage : null}
              />
            </View>
            <View
              pointerEvents={'none'}
              style={{ padding: cardConstants.textPadding, width: '100%', paddingBottom: 0 }}>
              <Text style={textStyles.large.bold}>{programCard.title}</Text>
            </View>
            {showTimeline ? (
              <RepeatingCardTimeline
                programEvents={focusableEvents}
                focusedEvent={programEvent}
                onPressEvent={onPressEvent}
              />
            ) : null}
            <View style={{ padding: cardConstants.textPadding, paddingTop: 18 }}>
              {elements}
              <ImageAttribution programCard={programCard} onPressLink={onPressLink} />
            </View>
          </View>
        </Animated.ScrollView>
        {removeIncompatibleAnimations ? null : (
          <BackButton
            programEvent={programEvent}
            onPressBackOrConfirm={onPressBackOrConfirm}
            showDateOnBackButton={showDateOnBackButton}
          />
        )}
        {programCard.hasTrackers && (
          <SafeAreaView
            style={{
              position: 'absolute',
              top: cardConstants.getTopButtonTopOffset(statusBarHeight),
              opacity: 1,
              right: 20 + rightOffsetFromEdge,
              zIndex: 2,
            }}>
            {!showTimeline && (
              <CompletionGraph
                TextComponent={Text}
                ViewComponent={View}
                svg={getUniversalSvgComponents(Platform.OS)}
                diameter={cardConstants.completionGraphDiameter}
                strokeWidth={cardConstants.completionGraphStrokeWidth}
                percentComplete={programEvent.goalCompletionStats.percentComplete}
                numGoalsComplete={programEvent.goalCompletionStats.numComplete}
                totalGoals={programEvent.goalCompletionStats.total}
                completedColor={branding.highlightedColor}
                bgColor={featureImage ? colors.bg0 : colors.bg1}
                check={programEvent.goalCompletionStats.percentComplete == 1}
              />
            )}
          </SafeAreaView>
        )}
        {removeIncompatibleAnimations || isTabletOrDesktop ? null : (
          <Animated.View
            useNativeDriver
            style={{
              position: 'absolute',
              top: 0,
              width: '100%',
              opacity: imageOpacity,
              height: HEADER_MIN_HEIGHT,
              backgroundColor: colors.bg1,
              zIndex: 1,
            }}
          />
        )}
        <FullscreenImageModal ref={imageModalRef} />
        <VideoPlayerModal ref={videoModalRef} />
      </View>
    );
  }
);

ProgramCardDetail.propTypes = {
  programEvent: PropTypes.object,
  onPressLink: PropTypes.func.isRequired,
  onPressJoinGroup: PropTypes.func,
  onPressPdf: PropTypes.func,
  onTrackOpenVideo: PropTypes.func,
  // if true, don't apply transitions to any components
  disableTransitions: PropTypes.bool,
  showDateOnBackButton: PropTypes.bool,
  disableAddLog: PropTypes.bool,
};

ProgramCardDetail.defaultProps = {
  disableTransitions: false,
  programEvent: null,
  showDateOnBackButton: true,
  onPressJoinGroup: () => {},
  onPressPdf: () => {},
  onTrackOpenVideo: () => {},
  disableAddLog: false,
};

export default ProgramCardDetail;
