/* eslint no-use-before-define: ["error", { "variables": false }] */

import PropTypes from 'prop-types';
import React from 'react';
import { View, TouchableWithoutFeedback, Text, SafeAreaView, Platform } from 'react-native';
import { observer } from 'mobx-react/native';
import { FlexibleThumbnail, DebouncedTouchableOpacity } from '/common/components';
import { sizes, textStyles, colors } from '../../config/styles';
import {
  ChatCameraIcon,
  ChatSendIcon,
  CancelIcon,
  ConfirmIcon,
  ChatNotificationIconSolid,
  ChatNotificationIconOutline,
} from '../../config/icons';
import PendingOperationIndicator from './PendingOperationIndicator';
import { BrandContext } from '../../config/branding';
import ChatToolbarTextInput from './ChatToolbarTextInput';

let verticalInputPadding = sizes.small + 2;

@observer
export default class InputToolbar extends React.Component {
  static contextType = BrandContext;

  render() {
    const {
      onChangeText,
      currentMessage,
      CameraButtonComponent,
      SendButtonComponent,
      EditButtonComponent,
      onPressSend,
      onPressChooseImage,
      onPressRemoveImage,
      isOperationPending,
      placeholderText,
      showChooseImageButton,
      editModeEnabled,
      onCancelEditing,
      showNotifyFlagToggle,
      toggleNotifyFlag,
      style,
    } = this.props;

    const branding = this.context;

    let bottomIconPadding = verticalInputPadding + (Platform.OS === 'android' ? 2 : 0); // working around intrinsic padding again, I guess?

    const rightButtons = [];

    const hasContent =
      (currentMessage.text && currentMessage.text.length > 0) || currentMessage.image;

    if (editModeEnabled) {
      rightButtons.push(
        <DebouncedTouchableOpacity
          key="edit"
          style={{ marginBottom: bottomIconPadding }}
          hitSlop={{ top: 4, bottom: 4, left: 4, right: 4 }}
          onPress={onCancelEditing}>
          <CancelIcon color={colors.error} />
        </DebouncedTouchableOpacity>
      );
    }
    if (!editModeEnabled && showNotifyFlagToggle && hasContent) {
      rightButtons.push(
        <DebouncedTouchableOpacity
          key="notify"
          style={{ marginBottom: bottomIconPadding - 4 }}
          hitSlop={{ top: 4, bottom: 4, left: 4, right: 4 }}
          onPress={toggleNotifyFlag}>
          {currentMessage.notifyFlag ? (
            <ChatNotificationIconSolid color={branding.highlightedColor} />
          ) : (
            <ChatNotificationIconOutline />
          )}
        </DebouncedTouchableOpacity>
      );
    }
    if (hasContent) {
      rightButtons.push(
        <DebouncedTouchableOpacity
          key="send"
          wait={400}
          style={{ marginBottom: bottomIconPadding }}
          hitSlop={{ top: 4, bottom: 4, left: 4, right: 4 }}
          onPress={onPressSend}>
          {editModeEnabled ? <EditButtonComponent color={colors.green} /> : <SendButtonComponent />}
        </DebouncedTouchableOpacity>
      );
    }

    const rightButtonArray = (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'flex-end',
          marginHorizontal: verticalInputPadding,
        }}>
        {rightButtons.map((button, index) =>
          index === 0 ? (
            button
          ) : (
            <View key={index.toString()} style={{ marginLeft: verticalInputPadding * 2 }}>
              {button}
            </View>
          )
        )}
      </View>
    );

    const aboveTextContentItems = [];

    if (currentMessage.image) {
      aboveTextContentItems.push(
        <View
          key="image"
          style={{
            paddingTop: verticalInputPadding,
            paddingLeft: sizes.small,
            alignItems: 'flex-start',
            paddingBottom: verticalInputPadding,
            marginBottom: sizes.small,
          }}>
          <FlexibleThumbnail
            source={currentMessage.image}
            maxHeight={80}
            imageStyle={{
              borderRadius: sizes.small / 2,
            }}
            containerStyle={{ paddingTop: sizes.medium * 0.8, paddingRight: sizes.medium * 0.8 }}
            renderOverlay={() => (
              <View
                style={{
                  position: 'absolute',
                  // Android doesn't let these buttons overlap the overlay container
                  top: 0,
                  right: -1 * sizes.medium * 0.8, // not sure why the padding didn't take care of this, but OK
                }}>
                <TouchableWithoutFeedback
                  onPress={onPressRemoveImage}
                  hitSlop={{
                    top: sizes.medium,
                    bottom: sizes.medium,
                    left: sizes.medium,
                    right: sizes.medium,
                  }}>
                  <View
                    style={{
                      backgroundColor: colors.error,
                      height: sizes.medium * 2 * 0.8 /* Russ said "80% of what is now" :-P */,
                      width: sizes.medium * 2 * 0.8,
                      borderRadius: sizes.medium * 0.8,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    <CancelIcon color={colors.bg0} />
                  </View>
                </TouchableWithoutFeedback>
              </View>
            )}
          />
        </View>
      );
    }

    if (currentMessage.notifyFlag) {
      aboveTextContentItems.push(
        <Text
          style={[
            {
              paddingTop: verticalInputPadding,
              paddingLeft: sizes.small,
              alignItems: 'flex-start',
              fontStyle: 'italic',
            },
            textStyles.small.light,
          ]}
          key="notificationInfo">
          *Clients will be notified
        </Text>
      );
    }

    return (
      <SafeAreaView
        style={[
          {
            borderTopWidth: sizes.hairlineWidth,
            borderTopColor: colors.border0,
            backgroundColor: colors.bg0,
          },
          style,
        ]}>
        {!isOperationPending ? aboveTextContentItems : null}
        <View style={[{ flexDirection: 'row', alignItems: 'flex-end' }]}>
          {showChooseImageButton ? (
            <DebouncedTouchableOpacity
              style={{
                marginHorizontal: verticalInputPadding,
                marginBottom:
                  bottomIconPadding -
                  1 /* temporary tweak due to positioning differences with png and fontello icons */,
              }}
              hitSlop={{ top: 4, bottom: 4, left: 4, right: 4 }}
              onPress={onPressChooseImage}>
              <CameraButtonComponent />
            </DebouncedTouchableOpacity>
          ) : (
            <View style={{ width: verticalInputPadding }} />
          )}
          <ChatToolbarTextInput
            currentMessage={currentMessage}
            isOperationPending={isOperationPending}
            placeholderText={placeholderText}
            onChangeText={onChangeText}
            verticalInputPadding={verticalInputPadding}
          />
          {rightButtonArray}
        </View>
        {isOperationPending && (
          <View
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              left: 0,
              bottom: sizes.homeBarPadding,
              backgroundColor: colors.bg0,
            }}>
            <PendingOperationIndicator
              containerStyle={{ alignSelf: 'center' }}
              color={branding.highlightedColor}
              size={24}
            />
          </View>
        )}
      </SafeAreaView>
    );
  }
}

InputToolbar.propTypes = {
  SendButtonComponent: PropTypes.any,
  EditButtonComponent: PropTypes.any,
  CameraButtonComponent: PropTypes.any,
};

InputToolbar.defaultProps = {
  SendButtonComponent: ChatSendIcon,
  EditButtonComponent: ConfirmIcon,
  CameraButtonComponent: ChatCameraIcon,
};
