import React from 'react';
import { Text } from 'react-native';
import AnimateNumber from 'react-native-animate-number';
import { observer } from 'mobx-react';
import { textStyles, fonts } from '../../../../config/styles';
import { unitsForValueAndUom } from '../../../../config/strings';

/**
 * Just a value followed by units if they're applicable.
 */
const SingleValue = observer(
  ({ value, formatter, uom, valueStyle, unitsStyle, style, countBy, showDashForNoValue }) => {
    if (!value && showDashForNoValue) {
      return <Text style={[textStyles.small.light, unitsStyle]}>-</Text>;
    }

    // TODO: add standard font to inherit all props
    const unitsString = unitsForValueAndUom(value, uom);
    return (
      <Text style={[style, { opacity: value ? 1 : 0.4 }]}>
        <Text style={[textStyles.bold.dark, valueStyle]}>
          {/* 
          TODO: After adding LeaderboardSegmentedControl i noticed that
          this animation is taking too long on larger numbers in the tens of thousands
          need to test and fix 
           */}
          {/* <AnimateNumber
            initialValue={value ? value : 0}
            value={value ? value : 0}
            formatter={formatter}
            timing="easeOut"
            interval={10}
            countBy={countBy}
          />  */}
          {value ? formatter(value) : 0}
        </Text>
        <Text style={[{ fontSize: fonts.sizes.small }, unitsStyle]}>
          {unitsString ? ` ${unitsString}` : unitsString}
        </Text>
      </Text>
    );
  }
);

export default SingleValue;
