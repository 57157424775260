/**
 * Resolves source for a card feature image
 * Normally, it's just programCard.image, but we use local images for store screenshots,
 * so that's why this is here.
 */
const getFeatureImageSource = (programCard, branding) => {
  if (programCard.image) {
    if (programCard.image.localImage) {
      return branding.assets[programCard.image.localImage];
    }
    return programCard.image;
  }
  return null;
};

export default getFeatureImageSource;
