import React from 'react';
import { View } from 'react-native';
import { colors, sizes } from '../config/styles';

const ListSeparatorLine = ({ style }) => (
  <View
    style={[{ borderBottomColor: colors.border0, borderBottomWidth: sizes.hairlineWidth }, style]}
  />
);

export default ListSeparatorLine;
