import React, { Component } from 'react';
import { observer } from 'mobx-react/native';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ScreenFirstLoadWrapperBase from './ScreenFirstLoadWrapperBase';
import { LoadingFailedPlaceholder, LoadingWrapper } from '../components';

/**
 * Shows a spinner for first load and optionally an error replacing the screen if that load fails.
 * Optionally can also show an additional error if subsequent data loads fail without hiding the main
 * view. The hasData prop makes this component sensitive to whether a load failure occurs on the screen
 * with or without data loaded, so it can choose not to hide data already loaded.
 */
@observer
class ScreenFirstLoadWrapper extends Component {
  render() {
    const {
      t,
      networkErrorMessage,
      errorMessage,
      errorTitle,
      serverUnavailableErrorMessage,
      actionMessage,
    } = this.props;

    return (
      <ScreenFirstLoadWrapperBase
        {...this.props}
        networkErrorMessage={networkErrorMessage || t('GENERAL:ERRORS:NETWORK_ERROR_MESSAGE')}
        errorMessage={errorMessage || t('GENERAL:ERRORS:FIRST_LOAD_MESSAGE')}
        serverUnavailableErrorMessage={
          serverUnavailableErrorMessage || t('GENERAL:ERRORS:SERVER_UNAVAILABLE_ERROR_MESSAGE')
        }
        errorTitle={errorTitle || t('GENERAL:ERRORS:FIRST_LOAD_TITLE')}
        actionMessage={actionMessage || t('GENERAL:ERRORS:PLEASE_REFRESH_ACTION')}
        LoadingFailedPlaceholderComponent={LoadingFailedPlaceholder}
        LoadingWrapperComponent={LoadingWrapper}
      />
    );
  }
}

ScreenFirstLoadWrapper.propTypes = {
  state: PropTypes.shape({
    isFailed: PropTypes.bool,
    isDone: PropTypes.bool,
    isPendingFirstLoad: PropTypes.bool,
  }),
  onPressRefresh: PropTypes.func,
  errorTitle: PropTypes.string,
  errorMessage: PropTypes.string,
  networkErrorMessage: PropTypes.string,
  hasData: PropTypes.any,
  children: PropTypes.any,
  t: PropTypes.any.isRequired,
  errorContainerStyle: PropTypes.any,
  serverUnavailableErrorMessage: PropTypes.string,
  actionMessage: PropTypes.string,
  showRefreshFailedErrorOnSubsequentLoads: PropTypes.bool,
};

ScreenFirstLoadWrapper.defaultProps = {
  state: null,
  hasData: false,
  children: null,
  errorTitle: null,
  errorMessage: null,
  networkErrorMessage: null,
  onPressRefresh: null,
  errorContainerStyle: null,
  serverUnavailableErrorMessage: null,
  actionMessage: null,
  showRefreshFailedErrorOnSubsequentLoads: false,
};

export default withTranslation()(ScreenFirstLoadWrapper);
