import { observer, inject } from 'mobx-react';
import React from 'react';
import { SafeAreaView, StyleSheet, View, Text, Image } from 'react-native';
import { isIphoneX } from 'react-native-iphone-x-helper';
import { withTranslation } from 'react-i18next';
import { colors, useMediaQueryInfo, fonts } from '/common/config/styles';
import { RoundButton } from '../components';
import { navigationRoutes } from '../../app/config/constants';

const AcceptDeclineScreen = inject('rootStore')(
  observer(function({ rootStore, navigation, t }) {
    const inviteIndex = 0;

    const invitesListLength = rootStore.settingsStore.user.invitesList.length;

    const { isTabletOrDesktop, isTablet, isWeb, windowHeight } = useMediaQueryInfo();

    const Decline = () => {
      rootStore.settingsStore.declineCoachInvite({
        url: rootStore.settingsStore.user.invitesList[inviteIndex].meta.decline.url,
        id: rootStore.settingsStore.user.invitesList[inviteIndex].id,
      });
      if (invitesListLength === 1) {
        navigation.navigate(navigationRoutes.stacks.main.tabs);
      }
    };
    const Accept = () => {
      rootStore.settingsStore.acceptCoachInvite({
        url: rootStore.settingsStore.user.invitesList[inviteIndex].meta.accept.url,
        id: rootStore.settingsStore.user.invitesList[inviteIndex].id,
      });
      if (invitesListLength === 1) {
        navigation.navigate(navigationRoutes.stacks.main.tabs);
      }
    };

    return (
      <SafeAreaView
        style={[
          {
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'space-between',
            borderTopWidth: StyleSheet.hairlineWidth,
            borderColor: colors.border0,
            display: 'flex',
            flex: 1,
          },
          isTabletOrDesktop || isTablet
            ? {
                borderColor: colors.border0,
                height: '100%',
                position: 'absolute',
                right: 0,
                left: 0,
              }
            : {
                borderColor: colors.border0,
                height: isIphoneX() ? 100 : 70,
              },
          { backgroundColor: colors.bg0, paddingBottom: isIphoneX() ? 60 : 0 },
        ]}>
        <View
        //spacer
        />
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '10%',
            marginRight: '20%',
            width: '70%',
          }}>
          <Image
            style={{
              height: 85,
              width: 85,
              borderRadius: 50,
              marginBottom: 30,
              borderColor: 'red',
            }}
            source={{
              uri: rootStore.settingsStore.user.invitesList[inviteIndex]?.meta?.icon,
            }}
          />
          <Text style={{ fontSize: fonts.sizes.xlarge, fontWeight: fonts.weights.medium2 }}>
            {rootStore.settingsStore.user.invitesList[inviteIndex]?.meta?.message}
          </Text>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            width: isWeb ? '100%' : null,
            paddingBottom: windowHeight <= 667 ? 10 : 0,
          }}>
          <View style={{ width: isTabletOrDesktop || isTablet ? '18%' : 10 }} />

          <RoundButton
            color="#000"
            title={t('GENERAL:DECLINE')}
            buttonStyle={{ flex: 1, margin: 0 }}
            onPress={Decline}
          />
          <View style={{ width: isTabletOrDesktop || isTablet ? '18%' : 10 }} />

          <RoundButton
            title={t('GENERAL:ACCEPT')}
            buttonStyle={{ flex: 1, margin: 0 }}
            onPress={Accept}
            theme={'lightOnDark'}
          />
          <View style={{ width: isTabletOrDesktop || isTablet ? '18%' : 10 }} />
        </View>
      </SafeAreaView>
    );
  })
);

export default withTranslation()(AcceptDeclineScreen);
