import idx from 'idx';

const isNetworkError = error =>
  error &&
  !error.response &&
  error.message &&
  (error.message.toUpperCase() === 'NETWORK ERROR' ||
    error.message.toUpperCase() === 'TIMEOUT OF 0MS EXCEEDED');
// there definitely seems to be some confusion here: https://github.com/axios/axios/issues/383

/*
  These errors are usually handled by kicking out to a login screen or refreshing everything.
  We may want to override normal error handling for situations like this.
*/
const isAccessedRemovedError = error =>
  error &&
  error.response &&
  (error.response.status === 403 ||
    (error.response.status === 422 && error.response.data && error.response.data.coaches_id));

const isInvalidSessionError = error => error && error.response && error.response.status === 401;

const isServerUnavailableError = error => error && error.response && error.response.status === 503;

// if true, is a class of error that fails due to incorrect input, that we'll typically handle in a way involving
// specific information about the form field and type of error
const isFormError = error =>
  error && error.response && (error.response.status === 422 || !isAccessedRemovedError(error));

/**
 * Get array of objects defining the field where the error occurred and the type of error.
 * These errors are based on the messages in 422 errors.
 * Can prepend any errors from preflight checks by adding a formErrors prop to the error object with an
 * array of preflight errors.
 */
const getFormErrors = error => {
  const formErrors = error.formErrors ? error.formErrors : [];
  if (isFormError(error)) {
    // email
    const emailErrors = idx(error, _ => _.response.data.errors.email);
    if (emailErrors && emailErrors.find(e => e === 'The email has already been taken.')) {
      formErrors.push({ field: 'email', type: 'alreadyUsed' });
    } else if (
      emailErrors &&
      emailErrors.find(e => e === 'The email must be a valid email address.')
    ) {
      formErrors.push({ field: 'email', type: 'invalid' });
    } else if (emailErrors && emailErrors.length) {
      // catch all
      formErrors.push({ field: 'email', type: 'invalid' });
    }
    // password
    const passwordErrors =
      idx(error, _ => _.response.data.errors.password) ||
      idx(error, _ => _.response.data.errors.current_password);

    const field = idx(error, _ => _.response.data.errors.password) ? 'password' : 'currentPassword';
    if (
      passwordErrors &&
      passwordErrors.find(e => e === 'The password must be at least 6 characters.')
    ) {
      formErrors.push({ field, type: 'notMinimumLength' });
    } else if (passwordErrors && passwordErrors.length) {
      formErrors.push({ field, type: 'invalid' });
    }

    // inviteId
    let inviteIdErrors = idx(
      error,
      _ => _.response.data.errors.invite_code // signup API
    );

    if (!inviteIdErrors || !inviteIdErrors.length) {
      inviteIdErrors = idx(
        error,
        _ => _.response.data.errors.promo_code // signup API
      );
    }

    if (
      inviteIdErrors &&
      inviteIdErrors.find(e => e === 'A Memberships Limit has been Exceeded.')
    ) {
      formErrors.push({ field: 'inviteId', type: 'membershipLimitExceeded' });
    } else if (inviteIdErrors) {
      formErrors.push({ field: 'inviteId', type: 'invalid' });
    }

    // other?

    return formErrors;
  }

  return formErrors;
};

const isValidEmail = email => {
  // horrible regex from https://emailregex.com
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

/**
 * Get errors for password change in the FormError format.
 * All of these errors can be determined before talking to a server
 */
const getPasswordFormErrors = fields => {
  const { newPassword, confirmNewPassword } = fields;

  const formErrors = [];

  if (newPassword !== confirmNewPassword) {
    formErrors.push({ field: 'newPassword', type: 'passwordsDoNotMatch' });
  }

  if (newPassword.length < 6) {
    formErrors.push({ field: 'newPassword', type: 'notMinimumLength' });
  }

  return formErrors;
};

/**
 * Get errors for user account creation/ update in the FormError format.
 * All of these errors can be determined before talking to a server
 */
const getUserFieldFormErrors = fields => {
  const formErrors = [];
  if (fields.hasOwnProperty('birthDate') && (!fields.birthDate || fields.birthDate === '')) {
    formErrors.push({ field: 'birthDate', type: 'blank' });
  }
  if (fields.hasOwnProperty('firstName') && (!fields.firstName || fields.firstName.trim() === '')) {
    formErrors.push({ field: 'firstName', type: 'blank' });
  }
  if (
    fields.hasOwnProperty('email') &&
    (!fields.email || fields.email.trim() === '' || !isValidEmail(fields.email))
  ) {
    formErrors.push({ field: 'email', type: 'invalid' });
  }
  return formErrors;
};

/**
 * Given a JS error, detect conditions and select a standard error message,
 * checking for server unavailable, network error, etc.
 */
function getStandardErrorMessage({
  error,
  serverUnavailableErrorMessage,
  networkErrorMessage,
  defaultErrorMessage,
}) {
  return isServerUnavailableError(error)
    ? serverUnavailableErrorMessage
    : isNetworkError(error)
    ? networkErrorMessage
    : defaultErrorMessage;
}

export {
  isNetworkError,
  isAccessedRemovedError,
  isInvalidSessionError,
  isServerUnavailableError,
  isFormError,
  getFormErrors,
  isValidEmail,
  getPasswordFormErrors,
  getUserFieldFormErrors,
  getStandardErrorMessage,
};
