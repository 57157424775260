import React, { Component } from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { some } from 'lodash';
import PickerItem from './PickerItem';

const getSelectedIcon = color => (
  <MaterialCommunityIcons size={20} name="checkbox-marked-circle" color={color} />
);

const getUnselectedIcon = color => (
  <MaterialCommunityIcons size={20} name="checkbox-blank-circle-outline" color={color} />
);

export default class PickerCell extends Component {
  render() {
    const {
      options,
      selected,
      onPressOption,
      containerStyle,
      selectedIconColor,
      unselectedIconColor,
      selectedItemStyle,
      unselectedItemStyle,
      itemTitleStyle,
      itemStyle,
      nullIsAnOption,
    } = this.props;

    let selectedKeys = [];
    if (selected) {
      if (selected.constructor === Array) {
        selectedKeys = selected;
      } else {
        selectedKeys = [selected];
      }
    } else if (!selected && nullIsAnOption) {
      selectedKeys = [null];
    }

    return (
      <View style={containerStyle}>
        {options.map(option => {
          const isSelected = some(selectedKeys, k => k == option.key);
          if (isSelected) {
            return (
              <PickerItem
                key={option.key}
                title={option.title}
                onPress={() => onPressOption(option.key)}
                icon={getSelectedIcon(selectedIconColor)}
                style={[itemStyle, selectedItemStyle]}
                titleStyle={itemTitleStyle}
              />
            );
          } else {
            return (
              <PickerItem
                key={option.key}
                title={option.title}
                onPress={() => onPressOption(option.key)}
                icon={getUnselectedIcon(unselectedIconColor)}
                style={[itemStyle, unselectedItemStyle]}
                titleStyle={itemTitleStyle}
              />
            );
          }
        })}
      </View>
    );
  }
}

PickerCell.propTypes = {
  options: PropTypes.array.isRequired,
  selected: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
    PropTypes.number,
  ]),
  onPressOption: PropTypes.func.isRequired,
  containerStyle: PropTypes.any,
  selectedIconColor: PropTypes.string,
  unselectedIconColor: PropTypes.string,
  selectedItemStyle: PropTypes.any,
  unselectedItemStyle: PropTypes.any,
  itemTitleStyle: PropTypes.any,
  itemStyle: PropTypes.any,
  // if true, one of the keys can be null and choosing null will result in an item being picked
  nullIsAnOption: PropTypes.bool,
};

PickerCell.defaultProps = {
  additionalSection: null,
  selected: null,
  containerStyle: null,
  selectedIconColor: 'blue',
  unselectedIconColor: 'gray',
  selectedItemStyle: { backgroundColor: 'white' },
  unselectedItemStyle: { backgroundColor: 'white' },
  itemStyle: { padding: 7 },
  itemTitleStyle: { fontSize: 17 },
  nullIsAnOption: false,
};
