import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { View, Text, Image, TouchableWithoutFeedback } from 'react-native';
import { textStyles, colors, sizes } from '../../config/common-styles';
import { UpdateIcon } from '../../config/common-icons';

const rightSideVerticalLayoutStyle = {
  flex: 1,
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
};

@observer
class ConnectedApp extends Component {
  _onPressApp = () => {
    if (!this.props.app.isEnabled) {
      this.props.onPressApp(this.props.app);
    } else {
      this.props.onPressStopApp(this.props.app);
    }
  };

  render() {
    const { app, t } = this.props;

    return (
      <TouchableWithoutFeedback onPress={this._onPressApp}>
        <View
          style={{
            marginHorizontal: sizes.large,
          }}>
          <View
            style={[
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginVertical: sizes.medium,
              },
            ]}>
            <View style={rightSideVerticalLayoutStyle}>
              <Image
                style={{
                  height: sizes.thumbnail,
                  width: sizes.thumbnail,
                  marginRight: sizes.medium,
                }}
                source={app.iconSource}
              />
              <Text
                numberOfLines={1}
                ellipsizeMode="tail"
                style={[
                  app.isEnabled ? textStyles.standard.bold : textStyles.standard.light,
                  { flex: 1, marginRight: sizes.medium },
                ]}>
                {app.title}
              </Text>
            </View>
            {app.isEnabled && <UpdateIcon size={sizes.large + 2} />}
            {!app.isEnabled && (
              <View
                style={{
                  height: sizes.large,
                  width: sizes.large,
                  borderRadius: sizes.large / 2,
                  borderColor: colors.lightText,
                  borderWidth: 1,
                }}
              />
            )}
          </View>
          {app.isMissingPermissions && (
            <Text style={[textStyles.small.light, { marginBottom: sizes.medium }]}>
              {t('SYNCED_APPS:CHECK_APPLE_HEALTH_SOURCES')}
            </Text>
          )}
        </View>
      </TouchableWithoutFeedback>
    );
  }
}

ConnectedApp.propTypes = {
  app: PropTypes.object.isRequired,
  onPressApp: PropTypes.func.isRequired,
  onPressStopApp: PropTypes.func.isRequired,
  t: PropTypes.any.isRequired,
};

export default withTranslation()(ConnectedApp);
