import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import { cacheAdapterEnhancer } from 'axios-extensions';
import { ApiTokenManager, SecureStore } from '/common/stores';
import { SecurableImage } from '/common/components';
import applyNewNudgeMocks from './applyNewNudgeMocks';
import env from '../../config/env';

// TODO: eliminate use of v4 object since it points to v5 now
const apiV4 = axios.create({
  baseURL: `${env.apiUrl}/5`,
});

const apiV3 = axios.create({
  baseURL: `${env.apiUrl}/3`,
  adapter: cacheAdapterEnhancer(axios.defaults.adapter, {
    enabledByDefault: false,
  }),
});

const apiV5 = axios.create({
  baseURL: `${env.apiUrl}/5`,
  adapter: cacheAdapterEnhancer(axios.defaults.adapter, {
    enabledByDefault: false,
  }),
});

const supportApi = axios.create({
  baseURL: `${env.apiUrl}/support`,
});

// New V5 API that doesn't really exist yet
// We're just using it as a new place for mock data

const mockApiV5 = new MockAdapter(apiV5);
applyNewNudgeMocks(mockApiV5);

const mockSupportApi = new MockAdapter(supportApi);
applyNewNudgeMocks(mockSupportApi);

const apiTokenManager = new ApiTokenManager({
  LocalStorage: SecureStore,
  componentsRequiringTokenHeaders: [SecurableImage],
  apiInstances: [apiV5, apiV4, apiV3],
  defaultHeaders: {
    'X-Requested-With': 'XMLHttpRequest',
    Accept: 'application/json',
    'X-API-KEY': '1ccb73d4c689414294cf951fd29a4eee5cdc8770',
    'X-API-TOKEN': '$token',
  },
  overrideToken: env.debug && env.debug.simulatedApiToken ? env.debug.simulatedApiToken : null,
});

apiTokenManager.initCustomHeaders();

// Sets up special onError interceptor callbacks so the app can reroute, etc. based on errors
// onRecognized404 = 404's from navigating to invalid coaches or groups that we know should be acted upon
// Better to restrict to these 404's for now while we're making sure this kind of rerouting doesn't have adverse side effects
const configureInterceptors = ({ on401, on403, onRecognized404 }) => {
  const successInterceptor = response => {
    //console.log(config);
    if (env.forceNoCoachPage) {
      if (response.config.url.includes('/users/me/coaches?messages=HEAD')) {
        response.data.data = [];
      }
      if (response.config.url.includes('/users/me/profile') && response.data.components) {
        response.data.components.coached = 0;
      }
    }
    return response;
  };
  const errorInterceptor = error => {
    console.log(JSON.stringify(error.response));
    if (error.response && error.response.status === 401) {
      on401();
    }
    if (
      (error.response && error.response.status === 403) ||
      (error.response &&
        error.response.status === 422 &&
        error.response.data &&
        error.response.data.coaches_id)
    ) {
      // 422 with a coaches ID is a 403-like scenario - accessing a coach that we no longer are assigned to
      on403();
    }
    if (
      error.response &&
      error.response.status === 404 &&
      error.config &&
      error.config.url &&
      (error.config.url.includes('users/me/clubs') || error.config.url.includes('users/me/coaches'))
    ) {
      onRecognized404();
    }
    // transform old invite ID error messages into new format so it's compatible with FormError logic
    if (
      error.response &&
      error.response.status === 400 &&
      error.config &&
      error.config.url &&
      error.config.url.includes('/user/-/promo-code')
    ) {
      error.status = 422;
      error.response.data = {
        errors: {
          promo_code: ['Invalid Invite ID. Please try again.'],
        },
      };
    }
    if (
      error.response &&
      (error.response.status === 412 || error.response.status === 422) &&
      error.config &&
      error.config.url &&
      error.config.url.includes('/user/-/promo-code')
    ) {
      error.status = 422;
      error.response.data = {
        errors: {
          promo_code: ['A Memberships Limit has been Exceeded.'],
        },
      };
    }
    return Promise.reject(error);
  };
  apiV3.interceptors.response.use(successInterceptor, errorInterceptor);
  apiV4.interceptors.response.use(successInterceptor, errorInterceptor);
  apiV5.interceptors.response.use(successInterceptor, errorInterceptor);
  supportApi.interceptors.response.use(successInterceptor, errorInterceptor);

  if (env.debug.simulatedUserId) {
    const simulateUserIdInterceptor = config => {
      config.params = config.params || {};
      config.params['test_role'] = 'user';
      config.params['test_uid'] = env.debug.simulatedUserId;
      return config;
    };
    apiV3.interceptors.request.use(simulateUserIdInterceptor);
    apiV4.interceptors.request.use(simulateUserIdInterceptor);
    apiV5.interceptors.request.use(simulateUserIdInterceptor);
  }
};

// debug only interceptor for adding errors to every Nth response to trigger
// error handling
const errorCounts = {};

const addErrorTestingInterceptors = () => {
  const isErrorTriggersEnabled = !!env.debug.apiErrorTestTriggersEnabled;
  const urlsToTrigger = env.debug.apiErrorTestTriggers;
  if (isErrorTriggersEnabled) {
    const successInterceptor = config => {
      const urlMatch = urlsToTrigger.find(
        u =>
          config.config.url.includes(u.url) &&
          config.config.method.toUpperCase() === u.method.toUpperCase()
      );
      if (urlMatch) {
        // fail every time without counting if count is set to zero.
        if (urlMatch.triggerCount === 0) {
          return Promise.reject(new Error());
        }
        // increment counter
        if (isNaN(errorCounts[urlMatch])) {
          errorCounts[urlMatch] = 0;
        } else {
          errorCounts[urlMatch] = errorCounts[urlMatch] + 1;
          if (errorCounts[urlMatch] >= urlMatch.triggerCount) {
            errorCounts[urlMatch] = 0; // reset if triggered
            return Promise.reject(new Error());
          }
        }
      }
      return config;
    };
    const errorInterceptor = error => {
      return Promise.reject(error);
    };
    apiV3.interceptors.response.use(successInterceptor, errorInterceptor);
    apiV4.interceptors.response.use(successInterceptor, errorInterceptor);
    apiV5.interceptors.response.use(successInterceptor, errorInterceptor);
  }
};

addErrorTestingInterceptors();

export { apiV3, apiV4, apiV5, configureInterceptors, supportApi, apiTokenManager };
