import React, { useCallback, forwardRef } from 'react';
import { TextInput, Platform } from 'react-native';

// compatible with autoresize for web
// based on https://github.com/necolas/react-native-web/issues/795#issuecomment-733610960
export default forwardRef(function AutoresizableTextInput(props, ref) {
  const handleChange = useCallback(e => {
    e.target.style.height = 0;
    e.target.style.height = `${e.target.scrollHeight}px`;
  }, []);

  return (
    <TextInput
      ref={ref}
      onChange={props.multiline && Platform.OS === 'web' ? handleChange : undefined}
      {...props}
    />
  );
});
