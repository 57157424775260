import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, StyleSheet } from 'react-native';
import { tableStyles as Styles } from '/common/config/styles';

/**
 * A section header within a table.
 */
const SectionHeader = ({ isAllCaps, title, style, textStyle }) => (
  <View style={[title.length ? Styles.sectionHeader : Styles.emptySectionHeader, style]}>
    {title.length ? (
      <Text style={[Styles.sectionHeaderText, textStyle]}>
        {isAllCaps ? title.toUpperCase() : title}
      </Text>
    ) : (
      <View />
    )}
  </View>
);

SectionHeader.propTypes = {
  isAllCaps: PropTypes.bool,
  style: PropTypes.any,
  textStyle: PropTypes.any,
  title: PropTypes.string,
};

SectionHeader.defaultProps = {
  isAllCaps: false,
  style: StyleSheet.create({}),
  title: '',
  textStyle: null,
};

export default SectionHeader;
