import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableWithoutFeedback } from 'react-native';
import { navigationStyles, textStyles } from '../../config/common-styles';
import { BrandContext } from '../../config/branding';

class SimpleNavButton extends Component {
  static contextType = BrandContext;

  render() {
    const { title, onPress, isPrimaryAction } = this.props;
    const branding = this.context;

    return (
      <View style={navigationStyles.navbarButton}>
        <TouchableWithoutFeedback onPress={onPress}>
          <View>
            <Text
              style={[
                textStyles.medium.light,
                isPrimaryAction && { color: branding.highlightedColor },
              ]}>
              {title}
            </Text>
          </View>
        </TouchableWithoutFeedback>
      </View>
    );
  }
}

SimpleNavButton.propTypes = {
  onPress: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  isPrimaryAction: PropTypes.bool,
};

SimpleNavButton.defaultProps = {
  isPrimaryAction: false,
};

export default SimpleNavButton;
