import React, { useRef, useImperativeHandle, forwardRef, useEffect } from 'react';
import { View } from 'react-native';
import InfiniteScroll from 'react-infinite-scroll-component';
import { uniqueId } from 'lodash';
import { observer } from 'mobx-react';
import { useMediaQueryInfo, colors } from '../../config/styles';
import ChatListItem from './ChatListItem';
import LoadEarlier from './LoadEarlier';

const ChatList = observer(
  forwardRef(function(
    {
      isLoadEarlierEnabled,
      onLoadEarlier,
      isLoadingEarlier,
      messages,
      inverted,
      currentUserId,
      MessageBubbleComponent,
      DayComponent,
      onPressAttachment,
      onLongPressMessage,
      onPressUrl,
      onPressImage,
      hoverMenuDirection,
      ...rest
    },
    ref
  ) {
    const flatListRef = useRef();
    const { innerWindowWidth } = useMediaQueryInfo();

    // unique ID for chat scrollable div
    const chatScrollableDivIdRef = useRef(uniqueId('chatScrollableDiv-'));

    useEffect(() => {
      setTimeout(() => {
        if (flatListRef.current) {
          flatListRef.current.scrollToEnd({ animated: false });
        }
      }, 100);
    });

    // forward used imperative functions from FlatList
    useImperativeHandle(
      ref,
      () => ({
        scrollToLastItem: () => {
          const myDiv = document.getElementById(chatScrollableDivIdRef.current);
          if (myDiv);
          myDiv.scrollTop = 0;
        },
      }),
      [flatListRef, inverted]
    );

    return (
      <View
        style={{
          width: innerWindowWidth,
          alignSelf: 'center',
          flex: 1,
          backgroundColor: colors.bg0,
        }}>
        <div
          id={chatScrollableDivIdRef.current}
          style={{
            height: '100%',
            overflow: 'auto',
            display: 'flex',
            flexDirection: inverted ? 'column-reverse' : 'column',
          }}>
          <InfiniteScroll
            style={{ display: 'flex', flexDirection: inverted ? 'column-reverse' : 'column' }}
            dataLength={messages.length} //This is important field to render the next data
            next={onLoadEarlier}
            hasMore={isLoadEarlierEnabled}
            inverse={inverted}
            scrollableTarget={chatScrollableDivIdRef.current}
            loader={
              <LoadEarlier
                isLoadEarlierEnabled={isLoadEarlierEnabled}
                onLoadEarlier={onLoadEarlier}
                isLoadingEarlier={isLoadingEarlier}
              />
            }>
            {messages.map(message => {
              return (
                <View key={message._id.toString()} style={{ width: '100%', position: 'relative' }}>
                  <ChatListItem
                    key={message.id}
                    message={message}
                    hoverMenuDirection={hoverMenuDirection}
                    {...{
                      inverted,
                      currentUserId,
                      MessageBubbleComponent,
                      DayComponent,
                      onPressAttachment,
                      onLongPressMessage,
                      onPressUrl,
                      onPressImage,
                    }}
                    {...rest}
                  />
                </View>
              );
            })}
          </InfiniteScroll>
        </div>
      </View>
    );
  })
);

export default ChatList;
