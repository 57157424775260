export default class ChatMessage {
  // members
  messageId;
  text;
  createdAt;
  userId;
  userName;
  attachmentSource;
  showAsStack;
  isPending;
  meeting;
  previousMessageCreatedAt;

  /**
   * This is the original message object.
   * Could be of any type.
   * custom message components can reference this if they are aware of the type
   * of the original message.
   */
  originatingMessage;

  constructor({
    messageId,
    text,
    createdAt,
    userId,
    userName,
    attachmentSource,
    originatingMessage,
    // used for calculating timestamps
    previousMessageCreatedAt,
    meeting,
    // used to indicate a group message in some contexts
    showAsStack = false,
    isPending = false,
  }) {
    this.messageId = messageId;
    this.text = text;
    this.createdAt = createdAt;
    this.userId = userId;
    this.userName = userName;
    this.attachmentSource = attachmentSource;
    this.originatingMessage = originatingMessage;
    this.showAsStack = showAsStack;
    this.isPending = isPending;
    this.meeting = meeting;
    this.previousMessageCreatedAt = previousMessageCreatedAt;

    // gifted chat compatibility
    this._id = messageId;
    this.user = {
      _id: userId,
      name: userName,
      //avatar: userPhotoUrl,
    };
    this.image = this.hasImage ? attachmentSource.uri : null;
  }

  get hasFileAttachment() {
    if (this.attachmentSource && this.attachmentSource.mime === 'application/pdf') {
      return true;
    }

    return false;
  }

  get hasText() {
    return !!this.text && !!this.text.length;
  }

  get hasImage() {
    if (this.hasFileAttachment) {
      return false;
    }
    if (!this.attachmentSource) {
      return false;
    }

    return true;
  }

  get hasMeetingLink() {
    if (this.meeting) {
      return true;
    }
    return false;
  }

  get isMixedContent() {
    return this.hasText && (this.hasImage || this.hasFileAttachment || this.hasMeetingLink);
  }
}
