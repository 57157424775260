import { types } from 'mobx-state-tree';

const Coach = types
  .model('Coach', {
    id: types.identifierNumber,
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    photoSource: types.frozen(),
    lastMessage: types.frozen(),
  })
  .views(self => ({
    get lastMessageIsPhoto() {
      return (
        !self.lastMessageText &&
        self.lastMessage &&
        self.lastMessage.attachment &&
        self.lastMessage.attachment.mime &&
        self.lastMessage.attachment.mime.startsWith('image')
      );
    },
    get lastMessageIsFileAttachment() {
      return (
        !self.lastMessageText &&
        self.lastMessage &&
        self.lastMessage.attachment &&
        self.lastMessage.attachment.mime &&
        self.lastMessage.attachment.mime === 'application/pdf'
      );
    },
    get lastMessageIsMeeting() {
      return !self.lastMessageText && self.lastMessage && self.lastMessage.meeting;
    },
    get lastMessageText() {
      if (self.lastMessage && self.lastMessage.message && self.lastMessage.message.length) {
        return self.lastMessage.message;
      }
      return null;
    },
  }));

export default Coach;
