import React, { useContext, useCallback } from 'react';
import { observer, MobXProviderContext } from 'mobx-react';
import { View } from 'react-native';
import { withTranslation } from 'react-i18next';
import { NavbarWrapper, HeaderIconButton } from '/common/components/navigation';
import { SocialFeed } from '../components/social';
import {
  ScreenError,
  ScreenFirstLoadWrapper,
  TriggerActionOnUpdateWhenFocused,
  withMessagingState,
  useScreenBehaviors,
  commonNavigationRoutes,
  AlertBarSuppressor,
} from '/common/navigation';
import { BadgeIcon } from '../components';
import { LeaderboardIcon } from '../config/icons';
import { colors } from '../config/styles';
import { usePersonalization } from '../config/personalization';

const SocialFeedScreen = observer(
  ({
    navigation,
    route,
    tabBarHeight,
    additionalBottomOffset,
    // props from withMessagingState
    Linking,
    currentMessageText,
    currentMessageImage,
    onSubmitMessage,
    onCurrentMessageTextChanged,
    onChooseImage,
    onRemoveImage,
    onOpenAttachment,
    onLongPressMessage,
    editModeEnabled,
    onCancelEditing,
    toggleNotifyFlag,
    showNotifyFlagToggle,
    currentMessageNotifyFlag,
    t,
  }) => {
    const { rootStore } = useContext(MobXProviderContext);
    const { socialStore } = rootStore;

    // --- hooks ---

    useScreenBehaviors({
      onFocus: () => socialStore.socialFeedForClub(clubId).loadComments(),
      onActive: () => socialStore.socialFeedForClub(clubId).loadComments(),
    });

    const { branding, theme } = usePersonalization();

    const onPressComment = useCallback(
      comment => {
        navigation.navigate(commonNavigationRoutes.clubs.reply, {
          clubId: route.params ? route.params.clubId : socialStore.clubs[0].id,
          commentId: comment.id,
        });
      },
      [route, rootStore]
    );

    const onPressLeaderboard = useCallback(() => {
      navigation.navigate(commonNavigationRoutes.clubs.leaderboard, {
        clubId: route.params ? route.params.clubId : socialStore.clubs[0].id,
      });
    }, [route, rootStore]);

    // --- end hooks ---

    let clubId; // derive from either only club, or params
    let hasBackButton = false;

    // clearly from a pushed screen
    if (route.params) {
      clubId = route.params.clubId;
      hasBackButton = true;
    } else {
      clubId = socialStore.clubs[0].id;
    }

    const socialFeed = socialStore.socialFeedForClub(clubId);

    // --- leaderboard button ---

    const leaderboardButton = (
      <HeaderIconButton
        onPress={onPressLeaderboard}
        icon={
          <BadgeIcon
            icon={<LeaderboardIcon useDarkTheme={theme.useDarkTheme} />}
            badgeColor={theme && theme.useDarkTheme ? colors.bg0 : branding.highlightedColor}
            badgeTextColor={theme && theme.useDarkTheme ? colors.darkText : undefined}
            badgeText={
              socialFeed.hasLeaderboard &&
              socialFeed.leaderboard &&
              socialFeed.leaderboard.position &&
              socialFeed.isLeaderboardJoined &&
              socialFeed.leaderboard.position.rank < 100 // don't show three our four digits - just looks bad
                ? socialFeed.leaderboard.position.rank
                : null
            }
            iconStyle={{ marginRight: 5 /* this helps show more of the icon */ }}
          />
        }
      />
    );

    return (
      <View style={{ flex: 1 }}>
        <NavbarWrapper
          title={socialFeed.title}
          hasBackButton={hasBackButton}
          rightButton={
            socialFeed.hasLeaderboard && socialFeed.leaderboard ? leaderboardButton : null
          }
          navigation={navigation}>
          <ScreenError
            state={socialFeed.sendCommentState}
            title={t('COMMUNITY:ERRORS:POST_MESSAGE')}
          />
          <ScreenError
            state={socialFeed.sendCommentState}
            title={t('COMMUNITY:ERRORS:DELETE_MESSAGE')}
          />
          <ScreenError
            state={socialFeed.updateCommentState}
            title={t('COMMUNITY:ERRORS:UPDATE_MESSAGE')}
          />
          <ScreenError
            state={socialFeed.loadAdditionalCommentsState}
            title={t('COMMUNITY:ERRORS:LOADING_PREVIOUS_MESSAGES')}
            type="messageBar"
          />
          <TriggerActionOnUpdateWhenFocused
            getWatchValue={() => rootStore.socialStore.latestSocialFeedUpdate}
            triggerWhen={update => update.clubId === socialFeed.clubId}
            onTrigger={socialFeed.loadNewComments}
          />
          <AlertBarSuppressor
            suppressWhen={idPayload =>
              idPayload.clubId === socialFeed.clubId && !idPayload.parentId
            }
          />
          <ScreenFirstLoadWrapper
            state={socialFeed.loadCommentsState}
            hasData={socialFeed.commentsSorted.length}
            onPressRefresh={socialFeed.loadComments}>
            <SocialFeed
              showChooseImageButton={socialFeed.forumsUploadsEnabled}
              leaderboard={socialFeed.hasLeaderboard ? socialFeed.leaderboard : null}
              topics={socialFeed.commentsSorted}
              onPressComment={onPressComment}
              onPressAttachment={message =>
                onOpenAttachment(message, {
                  clubId: route.params ? route.params.clubId : socialStore.clubs[0].id,
                  commentId: message.originatingMessage.id,
                })
              }
              onLongPressComment={onLongPressMessage}
              onPressLink={Linking.openURL}
              onLoadEarlier={socialFeed.loadAdditionalComments}
              isLoadEarlierEnabled={!socialFeed.isAtEndOfCommentHistory}
              isLoadingEarlier={socialFeed.loadAdditionalCommentsState.isPending}
              isLoadingEarlierFailed={socialFeed.loadAdditionalCommentsState.isFailed}
              onPressChooseImage={onChooseImage}
              onPressRemoveImage={onRemoveImage}
              onPostMessage={onSubmitMessage}
              currentMessage={{
                text: currentMessageText,
                image: currentMessageImage,
                notifyFlag: currentMessageNotifyFlag,
              }}
              onCurrentMessageTextChanged={onCurrentMessageTextChanged}
              isOperationPending={
                /*topicFeed.redactCommentState.isPending || */ socialFeed.sendCommentState
                  .isPending ||
                socialFeed.updateCommentState.isPending ||
                socialFeed.deleteCommentState.isPending
              }
              bottomOffset={tabBarHeight + additionalBottomOffset}
              onPressLeaderboard={onPressLeaderboard}
              isLeaderboardJoined={socialFeed.isLeaderboardJoined}
              perspective={rootStore.perspective}
              groupTitle={socialFeed.title}
              editModeEnabled={editModeEnabled}
              onCancelEditing={onCancelEditing}
              toggleNotifyFlag={toggleNotifyFlag}
              showNotifyFlagToggle={showNotifyFlagToggle}
              imageModalProps={{
                extraBottomNavigationButton: {
                  title: 'Reply',
                  onPress: currentMessage =>
                    navigation.navigate(commonNavigationRoutes.clubs.reply, {
                      commentId: currentMessage.originatingMessage.id,
                      clubId: socialFeed.clubId,
                    }),
                },
              }}
            />
          </ScreenFirstLoadWrapper>
        </NavbarWrapper>
      </View>
    );
  }
);

export default withTranslation()(
  withMessagingState(SocialFeedScreen, {
    getSendableStore: ({ route, rootStore }) => {
      let clubId; // derive from either only club, or params
      const { socialStore } = rootStore;

      // clearly from a pushed screen
      if (route.params) {
        clubId = route.params.clubId;
      } else {
        clubId = socialStore.clubs[0].id;
      }
      const socialFeed = socialStore.socialFeedForClub(clubId);
      return {
        sendMessage: socialFeed.sendComment,
        sendMessageState: socialFeed.sendCommentState,
        updateMessage: socialFeed.updateComment,
        updateMessageState: socialFeed.updateCommentState,
        deleteMessage: socialFeed.deleteComment,
        deleteMessageState: socialFeed.deleteCommentState,
      };
    },
    extraBottomNavigationButton: {
      title: 'Reply',
      navigationRoute: commonNavigationRoutes.clubs.reply,
    },
    capabilities: ['edit', 'delete', 'deleteAll', 'notifyFlag'],
    getCurrentUser: ({ rootStore }) => rootStore.coachProfile,
  })
);
