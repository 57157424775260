import React, { useCallback } from 'react';
import { Keyboard } from 'react-native';
import { HeaderIconButton, NavbarWrapper } from '../../../components/navigation';
import { CloseModalIcon } from '../../../config/common-icons';
import { delay } from '/common/lib';

// We get our headers from the StackNavigator, even though this isn't
// really a stack. There's probably a better way.
const Modal = ({ navigation, route, title, children }) => {
  const navParams = route ? route.params : navigation.state ? navigation.state.params : null; // v4 to v5 compat layer

  const onPressBack = useCallback(() => {
    async function doAsync() {
      Keyboard.dismiss();
      await delay(300); // delay for android tab bar weirdness due to keyboard
      navigation.pop();
    }
    doAsync();
  }, [navigation]);

  return (
    <NavbarWrapper
      title={title}
      leftButton={
        <HeaderIconButton
          disabled={navParams && navParams.isModalCloseButtonDisabled}
          icon={<CloseModalIcon />}
          onPress={onPressBack}
        />
      }
      navigation={navigation}>
      {children}
    </NavbarWrapper>
  );
};

export default Modal;
