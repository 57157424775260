import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, TouchableOpacity, Text } from 'react-native';

/**
 * TODO: make the Android one look a little more like iOS
 */
export default class RowCenteredButton extends Component {
  render() {
    const { title, onPress, style, isDestructive, textStyle, ...styleProps } = this.props;

    const cellStyle = {
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: styleProps.backgroundColor,
      paddingLeft: styleProps.horizontalPadding,
      paddingRight: styleProps.horizontalPadding,
      paddingTop: 8,
      paddingBottom: 8,
      minHeight: styleProps.minHeight,
    };
    const actionColor = styleProps.actionColor;
    const destructiveColor = styleProps.destructiveColor;

    return (
      <TouchableOpacity onPress={onPress}>
        <View style={[cellStyle, style]}>
          <Text
            style={[
              textStyle,
              // make sure color is applied last
              { color: isDestructive ? destructiveColor : actionColor },
            ]}>
            {title}
          </Text>
        </View>
      </TouchableOpacity>
    );
  }
}

RowCenteredButton.propTypes = {
  isDestructive: PropTypes.bool,
  onPress: PropTypes.func.isRequired,
  style: PropTypes.any,
  title: PropTypes.string.isRequired,
  horizontalPadding: PropTypes.number,
  minHeight: PropTypes.number,
  backgroundColor: PropTypes.string,
  actionColor: PropTypes.string,
  destructiveColor: PropTypes.string,
  textStyle: PropTypes.any,
};

RowCenteredButton.defaultProps = {
  isDestructive: false,
  style: null,
  horizontalPadding: 15,
  minHeight: 45.5, // ensures other cells are same size as cell with a toggle
  backgroundColor: '#FFFFFF',
  actionColor: 'blue',
  destructiveColor: '#FC423E',
  textStyle: null,
};
