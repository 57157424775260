import { DateTime } from 'luxon';
import { ImagePicker } from '/common/screens';
import AsyncStorage from '@react-native-async-storage/async-storage';
//import { checkMultiple, requestMultiple, PERMISSIONS } from 'react-native-permissions';
import { Platform, NativeModules } from 'react-native';
import * as Location from 'expo-location';
import { jsDateToDateString } from 'nudge-client-common/lib/dates';
import { imageUrlToSource } from './utils';
import camelcaseKeys from 'camelcase-keys';

let reactNativePermissions;
let checkMultiple, requestMultiple, PERMISSIONS;
if (NativeModules.RNPermissions) {
  reactNativePermissions = require('react-native-permissions');
  checkMultiple = reactNativePermissions.checkMultiple;
  requestMultiple = reactNativePermissions.requestMultiple;
  PERMISSIONS = reactNativePermissions.PERMISSIONS;
}

export default class UserRepository {
  _apiV3;
  _apiV4;
  _apiV5;
  _supportApi;
  userDto;
  _apiTokenManager;

  constructor({ apiV3, apiV4, apiV5, supportApi, apiTokenManager, cachingRepository }) {
    this._apiV3 = apiV3;
    this._apiV4 = apiV4;
    this._apiV5 = apiV5;
    this._supportApi = supportApi;
    this._apiTokenManager = apiTokenManager;
    this._cachingRepository = cachingRepository;
  }

  getNewFeatureTutorial = async () => {
    const response = await this._apiV5.get('/users/me/app-info');
    return response.data;
  };

  checkAndAckHasUsedNewFeaturesBefore = async () => {
    const itemKey = 'usedProgramsBefore'; // change this as we update onboarding to reshow for new features
    try {
      const result = await AsyncStorage.getItem(itemKey);
      await AsyncStorage.setItem(itemKey, JSON.stringify(true));
      if (result) {
        return JSON.parse(result);
      }
      return false;
    } catch (error) {
      console.log(error);
      // try to set it again if there's an error, hopefully avoid infinite new user messages
      try {
        await AsyncStorage.setItem(itemKey, JSON.stringify(true));
      } catch (error) {}
      return true; // don't keep trying to say they've never used it if we can't set the flag, that would be annoying
    }
  };

  /**
   * Return a user object cached in local storage if one exists, otherwise false.
   * Used for fast loading of the user.
   *
   * @memberof UserRepository
   */
  getCachedUser = async () => {
    const user = await this._cachingRepository.getItem('user');

    if (!user) {
      return false;
    }

    // we call this so it also restores the userDto object that we still use in other repos
    // hope to get rid of this because it's kind of weird
    return this._userDtoToUserBusinessObject(user);
  };

  getUser = async () => {
    const response = await this._apiV5.get(
      '/users/me/profile?components%5B%5D=theme&components%5B%5D=badges&components%5B%5D=forums&components%5B%5D=coached&components%5B%5D=tracking&components%5B%5D=integrations&components%5B%5D=card_feed&components%5B%5D=client_users_id&components%5B%5D=invites&components%5B%5D=features'
    );
    const profile = response.data;
    const invitesResponse = await this._apiV5.get(
      profile.components.invites.resource + '?limit=1000000'
    );
    profile.invitesList = invitesResponse.data.data;

    // cache user whenever we load it
    this._cachingRepository.setItem('user', profile);

    // construct business object - munge DTO into fields we need
    return this._userDtoToUserBusinessObject(profile);
  };

  // accept or decline coach invite
  declineInvite = async url => {
    const response = await this._apiV5.put(url);
    return response;
  };

  acceptInvite = async url => {
    const response = await this._apiV5.post(url);
    return response;
  };

  getUnreadMessageCount = async () => {
    const response = await this._apiV5.get('/users/me/profile?components%5B%5D=badges');
    const profile = response.data;
    return {
      messages: profile.components.badges.find(b => b.key === 'coach').meta.total,
      social: profile.components.badges.find(b => b.key === 'clubs').meta.total,
    };
  };

  /**
   * Use this to prevent a logged out user from seeing any data due to a delayed user load after a logout and login
   *
   * @memberof UserRepository
   */
  clearCachedUser = () => {
    this.userDto = null;
    this._cachingRepository.deleteItem('user');
  };

  updateUserFields = async fields => {
    const fieldsToUpdate = Object.assign({}, fields);
    // massage fields with differences
    if (fields.hasOwnProperty('firstName')) {
      fieldsToUpdate.firstname = fields.firstName;
    }
    if (fields.hasOwnProperty('lastName')) {
      fieldsToUpdate.lastname = fields.lastName;
    }
    if (fields.birthDate) {
      fieldsToUpdate.birthday = fields.birthDate;
    }
    // compatibility with old units field
    if (fields.units) {
      fieldsToUpdate.metric = fields.units === 'metric';
    }

    if (fields.hasOwnProperty('clientUserId')) {
      console.log('update the field!');
      fieldsToUpdate.client_users_id = fields.clientUserId;
    }

    const response = await this._apiV5.put(`users/me`, fieldsToUpdate);

    const user = response.data;

    // just the user, not the profile
    return this._userDtoToUserBusinessObject({
      user,
      // hack: object coming back from users/me is just the user, not the profile components, so, just have to assume it updated
      // if it returns 200
      components: { client_users_id: { client_users_id: fieldsToUpdate.client_users_id } },
    });
  };

  deleteAccount = async () => {
    const result = await this._apiV4.delete(`/users/me`);
    return result.data;
  };

  /**
   * Changes password. Returns an object with isError = true and a message if there was an error.
   */
  changePassword = ({ currentPassword, newPassword }) => {
    const payload = {
      id: this.userDto.id,
      current_password: currentPassword,
      password: newPassword,
      password_confirmation: newPassword,
    };

    return this._apiV3.put(`/user/${this.userDto.id}`, payload);
  };

  updateUserPhoto = async image => {
    const formData = new FormData();
    const resizedImage = await ImagePicker.resizeImage(image);
    formData.append('image', resizedImage);
    const response = await this._apiV3.post('/user/photo', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    const result = response.data;
    result.photoSource = imageUrlToSource(result.image_url, this._apiV3);
    return result;
  };

  submitPromoCode = (promoCode, source) => {
    return this._apiV3.post('/user/-/promo-code', { code: promoCode, source }).then(response => {
      return response.data;
    });
  };

  revokeCoachAccess = coachId => {
    return this._apiV3.delete(`/coaches/${coachId}`).then(() => {
      return Promise.resolve();
    });
  };

  joinGroup = groupId => {
    return this._apiV5.put(`/users/me/clubs/${groupId}/membership`);
  };

  // -- registration --

  validatePromoCode = promoCode => {
    return this._supportApi.get('/validate-promo-code', { params: { promo_code: promoCode } });
    // returns 400 with error if not unique
  };

  // -- new user registration

  registerUser = async ({ firstName, lastName, email, password, inviteId, clientUserId }) => {
    const response = await this._apiV5.post('/users/registration', {
      firstname: firstName,
      lastname: lastName === '' ? undefined : lastName,
      email,
      password,
      invite_code: inviteId ? inviteId : undefined,
      client_users_id: clientUserId ? clientUserId : undefined,
    });
    await this._apiTokenManager.setApiToken(response.data.token);
    return this._userDtoToUserBusinessObject(response.data.profile);
  };

  // - utility -

  reportUserTimezone = async () => {
    if (!this.userDto.timezone_lock) {
      const localTime = DateTime.local();
      await this._apiV4.put('/users/me/timezone', {
        identifier: localTime.zoneName,
        offset: localTime.offset * 60 /* Luxon reports offset in minutes, we expect seconds */,
      });
    }
  };

  reportLinkClicked = async ({ url, contextId, contextType }) => {
    await this._apiV4.post('users/me/tracking/link', {
      url,
      context_id: contextId,
      context_type: contextType,
    });
  };

  // --

  _userDtoToUserBusinessObject = profileDto => {
    const user = profileDto.user;
    user.photoSource = imageUrlToSource(user.photo_url, this._apiV3);
    user.firstName = user.firstname;
    user.lastName = user.lastname;
    user.units = user.metric ? 'metric' : 'imperial';
    user.theme = undefined; // remove this prop from the legacy object in case we end up not overwriting it. It's just a number.

    // only add these fields if they're on the object
    if (profileDto.components) {
      if (profileDto.components.theme && profileDto.components.theme.name !== 'Classic') {
        const theme = profileDto.components.theme;
        user.theme = {
          useDarkTheme: theme.theme === 'dark',
          logoSource: {
            uri: theme.logo_url,
          } /*imageUrlToSource(myTheme.logo_url, this._apiV3),*/,
          backgroundSource: { uri: theme.background_url } /*imageUrlToSource(, this._apiV3)*/,
        };
      }

      if (
        profileDto.components.tracking ||
        profileDto.components.card_feed ||
        profileDto.components.forums ||
        profileDto.components.coached
      ) {
        user.enabledData = {
          tracking: profileDto.components.tracking && profileDto.components.tracking.trends > 0,
          // we only want to how My Data is there are trackers AND at least one has data,
          // because we hide trackers there that don't have data.
          programs:
            profileDto.components.card_feed &&
            (profileDto.components.card_feed.hasCards || profileDto.components.card_feed.has_cards),
          collections:
            profileDto.components.card_feed && profileDto.components.card_feed.hasCollections,
          groups: profileDto.components.forums > 0,
          groupCount: profileDto.components.forums,
          coaches: profileDto.components.coached > 0,
          coachCount: profileDto.components.coached,
          features: camelcaseKeys(profileDto.components.features),
        };
      }

      if (profileDto.components.integrations) {
        user.pollingConnectedAppsRequired = !!profileDto.components.integrations.find(
          i => i.pollable
        );
      }

      // attach client users ID to user object because we later update it off of the user object
      if (profileDto.components.client_users_id) {
        user.clientUserId = profileDto.components.client_users_id.client_users_id;
      }

      // push invites into user.invites object
      if (profileDto.components.invites) {
        user.invites = profileDto.components.invites;
        if (profileDto.components.invites.pending > 0) {
          user.invitesList = profileDto.invitesList;
        }
      }
    }

    // stash for later user elsewhere
    // don't overwrite the profile object in case we're only updating the user itself and not the profile
    this.userDto = { ...this.userDto, ...user };

    return this.userDto;
  };

  // -- one-time user hints prompt --

  getUserHints = async () => {
    try {
      const result = await AsyncStorage.getItem('userHints');
      if (!result) {
        return {};
      }
      return JSON.parse(result);
    } catch (error) {
      return {};
    }
  };

  ackUserHint = async key => {
    try {
      let hints = await AsyncStorage.getItem('userHints');
      hints = JSON.parse(hints);
      if (hints && hints[key]) {
        hints[key].acked = true;
        hints[key].ackedDate = jsDateToDateString(new Date());
        await AsyncStorage.setItem('userHints', JSON.stringify(hints));
      }
    } catch (error) {
      console.log(error);
    }
  };

  stageUserHint = async key => {
    try {
      let hints = await AsyncStorage.getItem('userHints');
      hints = JSON.parse(hints);
      if (!hints) {
        hints = {};
      }
      hints[key] = { acked: false, stageDate: jsDateToDateString(new Date()) };
      await AsyncStorage.setItem('userHints', JSON.stringify(hints));
    } catch (error) {
      console.log(error);
    }
  };

  resetUserHints = async () => {
    try {
      await AsyncStorage.removeItem('userHints');
    } catch (error) {
      console.log(error);
    }
  };

  unAckUserHint = async key => {
    try {
      let hints = await AsyncStorage.getItem('userHints');
      hints = JSON.parse(hints);
      if (hints && hints[key]) {
        hints[key].acked = false;
        hints[key].ackedDate = jsDateToDateString(new Date());
        await AsyncStorage.setItem('userHints', JSON.stringify(hints));
      }
    } catch (error) {
      console.log(error);
    }
  };

  // -- location access --
  // this could be for bluetooth on Android OR some future location-based feature (used to be Whitespace)

  getBluetoothPermissionsReadyStatus = async () => {
    if (Platform.OS === 'ios') {
      return {
        bluetoothPermissionsReady: true,
      };
    } else {
      if (Platform.Version < 31) {
        const foregroundPermissionsReponse = await Location.getForegroundPermissionsAsync();
        const backgroundPermissionsReponse = await Location.getBackgroundPermissionsAsync();

        const locationPermissionsStatus = {
          canTrack: foregroundPermissionsReponse.status === 'granted',
          canAsk: foregroundPermissionsReponse.status === 'undetermined',
          canTrackAlways: backgroundPermissionsReponse.status === 'granted',
        };

        return {
          bluetoothPermissionsReady: locationPermissionsStatus.canTrackAlways,
          locationPermissionsStatus,
        };
      } else {
        // Android 12: ask for location and bluetooth
        // Maybe we only have to ask for bluetooth once our target SDK is 31
        if (!reactNativePermissions) {
          return;
        }
        const statuses = await checkMultiple([
          PERMISSIONS.ANDROID.BLUETOOTH_SCAN,
          PERMISSIONS.ANDROID.BLUETOOTH_CONNECT,
        ]);

        const bluetoothPermissionsStatus = {
          canScan: statuses[PERMISSIONS.ANDROID.BLUETOOTH_SCAN] === 'granted',
          canConnect: statuses[PERMISSIONS.ANDROID.BLUETOOTH_CONNECT] === 'granted',
          canAsk:
            statuses[PERMISSIONS.ANDROID.BLUETOOTH_SCAN] !== 'blocked' &&
            statuses[PERMISSIONS.ANDROID.BLUETOOTH_SCAN] !== 'blocked' &&
            !(
              statuses[PERMISSIONS.ANDROID.BLUETOOTH_SCAN] === 'granted' &&
              statuses[PERMISSIONS.ANDROID.BLUETOOTH_SCAN] === 'granted'
            ),
        }; // really lame bug: these permissions returns granted if you go into settings and disable them later
        // could be just an API 30 thing.
        // This does not happen if you reject the permission outright.
        const foregroundPermissionsReponse = await Location.getForegroundPermissionsAsync();
        const backgroundPermissionsReponse = await Location.getBackgroundPermissionsAsync();

        const locationPermissionsStatus = {
          canTrack: foregroundPermissionsReponse.status === 'granted',
          canAsk: foregroundPermissionsReponse.status === 'undetermined',
          canTrackAlways: backgroundPermissionsReponse.status === 'granted',
        };
        return {
          bluetoothPermissionsReady:
            bluetoothPermissionsStatus.canScan &&
            bluetoothPermissionsStatus.canConnect &&
            locationPermissionsStatus.canTrackAlways,
          bluetoothPermissionsStatus,
          locationPermissionsStatus,
          locationPermissionsReady: locationPermissionsStatus.canTrackAlways,
          nearbyDevicesPermissionsReady:
            bluetoothPermissionsStatus.canScan && bluetoothPermissionsStatus.canConnect,
        };
      }
    }
  };

  updateTrackingConsent = async ({ background }) => {
    if (background) {
      await Location.requestBackgroundPermissionsAsync();
    } else {
      await Location.requestForegroundPermissionsAsync();
    }
  };

  updateBluetoothPermissionsConsent = async () => {
    if (!reactNativePermissions) {
      return;
    }
    await requestMultiple([
      PERMISSIONS.ANDROID.BLUETOOTH_SCAN,
      PERMISSIONS.ANDROID.BLUETOOTH_CONNECT,
    ]);
  };
}
