import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { View, Text, Image } from 'react-native';
import { textStyles, sizes } from '../../config/common-styles';

/**
 * A friendly error to display in the middle of a screen when loading fails.
 * Eventually we'll probably include an image here.
 */
const EmptyListPlaceholder = observer(({ title, message, showImage }) => (
  <View
    style={[
      {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        paddingLeft: sizes.large,
        paddingRight: sizes.large,
      },
    ]}>
    <Text style={[textStyles.bold.dark, { textAlign: 'center', paddingBottom: sizes.large * 2 }]}>
      {title}
    </Text>
    <Text style={[textStyles.standard.dark, { textAlign: 'center' }]}>{message}</Text>
  </View>
));

EmptyListPlaceholder.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  style: PropTypes.any,
  showImage: PropTypes.bool,
};

EmptyListPlaceholder.defaultProps = {
  title: '',
  message: '',
  actionMessage: '',
  onPressRetry: null,
  style: null,
  showImage: true,
};

export default EmptyListPlaceholder;
