import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity } from 'react-native';
import { hook } from 'cavy';
import { observer } from 'mobx-react';
import { navigationStyles } from '../../config/common-styles';

@observer
class HeaderIconButton extends Component {
  render() {
    return (
      <TouchableOpacity
        ref={ref => {
          if (this.props.cavyId) {
            this.props.generateTestHook(`${this.props.cavyId}`)(ref);
          }
        }}
        style={navigationStyles.navbarButton}
        disabled={this.props.disabled}
        onPress={this.props.onPress}>
        {this.props.icon}
      </TouchableOpacity>
    );
  }
}

HeaderIconButton.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.element.isRequired,
  onPress: PropTypes.func.isRequired,
  cavyId: PropTypes.string,
};

HeaderIconButton.defaultProps = {
  disabled: false,
  cavyId: null,
};

export default hook(HeaderIconButton);
