import { addMiddleware, getType } from 'mobx-state-tree';
import { Platform, NativeModules } from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import AsyncStorage from '@react-native-async-storage/async-storage';
import idx from 'idx';
import { NavigationService, Linking, OtaUpdateListener } from '/common/stores';
import Clipboard from '/common/lib/clipboard/';
import { getToday, overrideToday } from 'nudge-client-common/lib/dates';
import { ClubsRepository } from '/common/stores/repos';
import {
  AuthenticationRepository,
  UserRepository,
  CoachesRepository,
  SyncRepository,
  LogRepository,
  CachingRepository,
  CommonRepository,
  BluetoothSyncRepository,
  MockBluetoothSyncRepository,
} from './repos';
import * as Amplitude from '../lib/amplitude';
import { Sentry } from '../lib/sentry';
import { getUrlParam } from '../lib/web';
import { BadgeHandler, AuthWebBrowser, IntentLauncher } from './device-services';
import { delay } from '/common/lib';
import { apiV3, apiV4, apiTokenManager, supportApi, apiV5 } from './api';
import RootStore from './RootStore';
import {
  PusherListener,
  AppStateListener,
  LinkListener,
  PushNotificationListener,
  BluetoothListener,
} from './listeners';
import { shouldReportError } from './common';
// weird branding stuff
import BrandingStore from './branding';
import getAppId from '../lib/getAppId';
import setPresets from './branding/setPresets'; // just for DI
/* eslint-disable */
// This file is not checked in, needs to be set via yarn run set-nudge-config.
import appConfig from '../../current-config/nudge-config.json';
/* eslint-enable */
import env from '../config/env';
let RNFS;
if (NativeModules.RNFSManager) {
  RNFS = require('react-native-fs');
}

// override today's date for taking screenshots
if (env.mocks && env.mocks.length && env.mocks.find(m => m === 'screenshots')) {
  overrideToday('2021-01-30' /* screenshots always on a saturday in January */);
}

const pusherListener = new PusherListener({ apiV5 });
const appStateListener = new AppStateListener();
const linkListener = new LinkListener();
const pushNotificationListener = new PushNotificationListener();
const bluetoothListener = new BluetoothListener();
const otaUpdateListener = new OtaUpdateListener({
  forceOtaUpdateWhenAvailable: env.forceOtaUpdateWhenAvailable,
});
const cachingRepository = new CachingRepository();

const authenticationRepository = new AuthenticationRepository({
  apiV3,
  apiV4,
  apiV5,
  apiTokenManager,
});
const userRepository = new UserRepository({
  apiV3,
  apiV4,
  apiV5,
  apiTokenManager,
  supportApi,
  cachingRepository,
});

const coachesRepository = new CoachesRepository({ apiV3, apiV4, apiV5, userRepository });
const logRepository = new LogRepository({ apiV5, apiV3, apiV4, userRepository });
const clubsRepository = new ClubsRepository({ apiV5, pusherListener, context: 'users' });

const commonRepository = new CommonRepository({ apiV5 });

const syncRepository = new SyncRepository({
  apiV4,
  apiV3,
  errorReporter: Sentry,
  getBranding: () => brandingStore.branding,
});

let bluetoothSyncRepository = new BluetoothSyncRepository({
  errorReporter: Sentry,
  getBranding: () => brandingStore.branding,
  apiV5,
});
if (env.mocks && env.mocks.length && env.mocks.find(m => m === 'bluetooth')) {
  bluetoothSyncRepository = new MockBluetoothSyncRepository({ bluetoothListener });
}

/**
 * Provide a function that can provide the parent navigation object, which can be used to navigate anywhere
 * This doesn't normally exist early enough to inject with the rest of the dependencies
 */
const setNavigation = nav => {
  NavigationService.setTopLevelNavigator(nav);
};

/**
 * Provide a function that can get parent navigation from anywhere
 */
const getNavigation = async () => {
  async function waitForNavigation() {
    if (NavigationService.isNavigationAvailable()) {
      return {
        navigate: NavigationService.navigate,
        state: NavigationService.getState(),
        goBack: NavigationService.goBack,
      };
    } else {
      await delay(250);
      return waitForNavigation();
    }
  }
  return await waitForNavigation();
};

const rootStore = RootStore.create(
  {
    perspective: 'client',
  },
  {
    authenticationRepository,
    apiTokenManager,
    userRepository,
    coachesRepository,
    pusherListener,
    clubsRepository,
    syncRepository,
    commonRepository,
    bluetoothSyncRepository,
    appStateListener,
    bluetoothListener,
    otaUpdateListener,
    linkListener,
    pushNotificationListener,
    logRepository,
    // only branding prop currently needed at stores level
    // required by TopicFeed to load correct amount of leaders (branding.leaderboardLength)
    getBranding: () => brandingStore.branding,
    getToday,
    getCurrentUser: () => rootStore.settingsStore.user,
    getNavigation,
    errorReporter: Sentry,
    cachingRepository,
    Platform,
    Linking,
    WebBrowser,
    IntentLauncher,
    // get a string, or return null;
    // don't error out so we don't log Sentry errors about other stuff being in the clipboard (which we don't care about)
    getClipboardStringIfAvailable: async () => {
      const itemKey = 'hasClipBoardPermission';
      try {
        if (Platform.OS === 'android') {
          const permission = await AsyncStorage.getItem(itemKey);
          if (permission) {
            return Clipboard.getString();
          } else {
            return null;
          }
        }
        // shows an error on safari for some reason instead of swallowing
        return Clipboard.getString();
      } catch (error) {
        return null;
      }
    },
    Amplitude,
  }
);

const middlewareInterceptor = (call, next) => {
  if (call.type === 'flow_resume_error') {
    // TODO: call.name actually gets minified, real bummer! Is there anything we can do?
    const type = getType(call.context);
    console.log(`action ${call.name} on ${type.name} threw an error!`);
    if (call.args.length && call.args[0]) {
      console.log(call.args[0]);
    }
    // reject expected API errors
    if (call.args.length && call.args[0] && !shouldReportError(call.args[0])) {
      console.log('normal API error, do not send to sentry');
      return next(call);
    }
    const fingerprint = ['{{ default }}'];
    const error = call.args.length ? call.args[0] : null;
    if (error) {
      Sentry.withScope(function(scope) {
        // group errors together based on their request and response
        const status = idx(error, _ => _.response.status);
        if (status) {
          fingerprint.push(status);
          scope.setFingerprint(['{{ default }}', type.name, status]);
        }
        scope.setTag('store', type.name);
        Sentry.captureException(error);
      });
    }
  }
  /*if (call.type === 'flow_throw') {
    console.log(`action ${call.name} threw an error!`);
    // first arg has the error
    console.log(call.args);
  }*/
  return next(call);
};

addMiddleware(rootStore, middlewareInterceptor);

const resolveBrandKey = () => {
  // 1) use BRAND server variable if available
  let brandKey;
  if (
    window.BRAND &&
    !window.BRAND.includes(
      `$_SERVER['BRAND']`
    ) /* placeholder is PHP strings b/c we haven't figured out templates yet */
  ) {
    brandKey = window.BRAND;
  }
  // 2) use secret brand override
  // this should not override the window.BRAND variable eventually, just doing this for testing
  const overrideBrand = getUrlParam('brand');
  if (overrideBrand) {
    brandKey = overrideBrand;
  }
  // 3) fallback to nudge (for now, may want to error out later)
  if (!brandKey) {
    brandKey = 'nudge';
  }

  return brandKey;
};

const brandingStore = BrandingStore.create(
  {},
  {
    setPresets,
    getDefaultApiUrl: () => appConfig.apiUrl,
    overrideApiUrl: apiUrl => {
      apiV3.defaults.baseURL = `${apiUrl}/3`;
      apiV4.defaults.baseURL = `${apiUrl}/4`;
      apiV5.defaults.baseURL = `${apiUrl}/5`;
      supportApi.defaults.baseURL = `${apiUrl}/support`;
    },
    Platform,
    getAppId,
    getAppConfig: () => appConfig,
    resolveBrandKey,
    getResource: async resourceName => {
      if (RNFS) {
        if (Platform.OS === 'android') {
          const result = await RNFS.readFileAssets(resourceName, 'utf8');
          const resultJson = JSON.parse(result);
          return resultJson;
        } else if (Platform.OS === 'ios') {
          //const result = await fetch(resourceName);
          //const resultJson = await result.json();
          const result = await RNFS.readFile(RNFS.MainBundlePath + '/' + resourceName, 'utf8');
          const resultJson = JSON.parse(result);
          return resultJson;
        }
      } else if (
        Platform.OS === 'web' &&
        !appConfig.brandProps /* this gets checked after brand bundle normally, so we'd break debug mode if we didn't make an exception here */
      ) {
        const brandKey = resolveBrandKey();
        // we've optimized the props themselves to be loaded directly into variables as we load the app
        if (appConfig.webBrandLoadingMethod === 'network') {
          const response = await fetch(`brands/${brandKey}/${resourceName}`);
          const resultJson = await response.json();
          return resultJson;
        }
        return window[resourceName];
      }
      return null;
    },
  }
);

addMiddleware(brandingStore, middlewareInterceptor);

// Link rootStore to Linking to handle link logging
Linking.onOpenURL = url => {
  rootStore.reportLinkClicked({ url });
};

// Link rootStore to AuthWebBrowser so it can process links that come from iOS auth session
AuthWebBrowser.onIosAuthSessionSuccess = url => {
  rootStore.appLinkProcessor.setAppLink({ source: 'url', url });
  rootStore.appLinkProcessor.processCurrentLink();
};

export {
  rootStore,
  brandingStore,
  Linking,
  BadgeHandler,
  AuthWebBrowser,
  setNavigation,
  getNavigation,
};
