import { types, flow, getEnv, getRoot } from 'mobx-state-tree';
import { when } from 'mobx';
import idx from 'idx';
import { getDateMinusXDays, getToday } from 'nudge-client-common/lib/dates';
import { LoadingState } from './common';
import { navigateToCoach, navigateToGroup } from '../lib/navigation-utils';
import { delay } from '/common/lib';
import { navigationRoutes } from '../config/constants';

/* eslint-disable no-case-declarations */

/**
 * We support three different "link" sources:
 * url: nudge:// or com.nudgeyourself.nudge://
 * branch: weird URL that branch handler does stuff with, totally different payload
 * notification: user tapped a notification outside the app and now we need to route them somewhere
 *
 * Notification isn't strictly a "link" but it behaves so much like one, and you really can't be handling a notification and a link
 * at the same time.
 */
const AppLink = types.model('AppLink', {
  source: types.enumeration(['branch', 'url', 'notification']),
  // for url source
  url: types.maybeNull(types.string),
  // for branch source
  inviteId: types.maybeNull(types.string),
  // for notification source
  data: types.frozen(),
});

/**
 * Dedicated store for processing app links. Basically exists to offload this from RootStore
 * A lot of references to root, unfortunately.
 * Links will be processed as they are received, and also upon login.
 * If a link can't be processed when not logged in, it will be held for manual invocation in rootStore.onLogin()
 * OH, and this now handles actions when tapping a notification when outside the app, because those are a type of link...right?
 */
const AppLinkProcessor = types
  .model('AppLinkProcessor', {
    // all external link processing (including Branch, email confirmation, etc.)
    processLinkState: types.optional(LoadingState, {}),
    // type and message
    // used when link processing may include a message indicating ther result of processing
    processLinkResult: types.frozen(),
    // url, or type and inviteId (branch)
    lastAppLink: types.maybeNull(AppLink),
  })
  .actions(self => {
    // --- other app links ---
    const setAppLink = payload => {
      self.lastAppLink = payload;
    };

    const ackAppLink = () => {
      self.lastAppLink = null;
    };

    const afterCreate = () => {
      getEnv(self).linkListener.onReceiveLink = payload => {
        // empty link for invoking the app- already handled by virtue of opening it
        if (payload.url === 'nudge://') {
          return;
        }
        // if iOS 11+, we're using AuthSession, which means we do not accept links outside the app for SSO
        // These instead come from SFAuthenticationSession's async return value
        const Platform = getEnv(self).Platform;
        if (Platform.OS === 'ios' && parseInt(Platform.Version, 10) >= 11) {
          const branding = getEnv(self).getBranding();
          if (
            branding.sso &&
            branding.sso.enabled &&
            payload.url &&
            payload.url.includes(branding.sso[Platform.OS].redirectUri)
          ) {
            return;
          }
        }
        // immediately try to process link. It will be held if it cannot be processed yet (e.g., due to the user not being logged in)
        // important: some sources cause redundant links (like branch and Linking)
        // if we don't recognize the link as something we handle, don't overwrite the last link
        if (!getLinkType(payload)) {
          return;
        }
        self.setAppLink(payload);
        self.processCurrentLink();
      };
      getEnv(self).linkListener.startListening();

      getEnv(self).pushNotificationListener.onNotificationSelected = payload => {
        const link = { source: 'notification', data: payload };
        // important: some sources cause redundant links (like branch and Linking)
        // if we don't recognize the link as something we handle, don't overwrite the last link
        if (!getLinkType(link)) {
          return;
        }
        self.setAppLink(link);
        self.processCurrentLink();
      };

      getEnv(self).pushNotificationListener.startListening();
    };

    function getLinkType(link) {
      if (!link) {
        return null;
      }
      if (link.url) {
        const branding = getEnv(self).getBranding();
        const Platform = getEnv(self).Platform;
        // looks like these links might be normalized to exp://
        // see https://github.com/expo/expo/issues/2644
        // Might be due to it not being the URL scheme in app.json
        if (
          link.url.startsWith('nudge://') ||
          link.url.startsWith('com.nudgeyourself.nudge://') ||
          link.url.startsWith(branding.urlScheme) ||
          link.url.startsWith('exp://')
        ) {
          if (
            link.url.includes('APITokenExchange') ||
            link.url.includes('AuthenticationComplete')
          ) {
            return 'connectedApp';
          } else if (
            branding.sso &&
            branding.sso.enabled &&
            link.url
              .replace('exp', 'com.nudgeyourself.nudge')
              .includes(branding.sso[Platform.OS].redirectUri)
          ) {
            return 'sso';
          }
        }
      }

      if (link.source === 'branch' && link.inviteId) {
        return 'branch';
      }

      if (link.source === 'notification' && link.data.coach_id) {
        return 'coachMessage';
      }

      if (link.source === 'notification' && link.data.club_id) {
        return 'clubMessage';
      }

      if (link.source === 'notification' && link.data.card_id) {
        return 'programCard';
      }

      return null;
    }

    /**
     * Process the given link. Can be called as part of automatic processing based on listeners, or called directly by the app
     * Params:
     * - link - the link to be processed
     * - ackWhenDone - if true, this is the current app link and should be ack'ed when done
     *
     * The ack'ing stuff is mostly defensive coding. It seems like stashing the current link isn't necessary anymore,
     * because it's always processed immediately.
     * We need to add direct processing in order to use the link processor for tapping in-app notifications.
     * However, there's just not enough time to test refactoring everything.
     */
    const processLink = flow(function* processLink({ link, ackWhenDone = false }) {
      console.log('processed link:');
      if (!link) {
        return;
      }
      console.log(link);
      const linkType = getLinkType(link);

      self.processLinkState.setPending();

      const rootStore = getRoot(self);
      const navigation = yield getEnv(self).getNavigation();
      //const Linking = getEnv(self).Linking;

      self.processLinkResult = null; // clean this out or it could keep repeating the same message on future links!
      try {
        // wait until we know whether or not we're logged in, because that can change what we do to a link
        yield when(() => !rootStore.initState.isPending);
        yield delay(300); // see if this can help with Android navigating with notifications on cold start.
        if (rootStore.isLoggedIn) {
          // All links have an effect if logged in, except sso link, which we ack anyway so it doesn't do anything
          switch (linkType) {
            case 'connectedApp':
              yield rootStore.settingsStore.registerOauthApp(link.url);
              self.processLinkResult = { type: 'connectedApp', message: null };
              break;
            case 'sso':
              break;
            case 'coachMessage':
              navigateToCoach({
                navigation,
                coachCount: rootStore.settingsStore.coachCount,
                onInitConversation: () =>
                  rootStore.conversationsStore.initConversation(link.data.coach_id),
                isReadyToNavigateFn: () => rootStore.baseProtectedRoute === 'loggedIn',
              });
              break;
            case 'clubMessage':
              navigateToGroup({
                navigation,
                groupCount: rootStore.settingsStore.groupCount,
                clubId: link.data.club_id,
                commentId: link.data.parent_id,
                isReadyToNavigateFn: () => rootStore.baseProtectedRoute === 'loggedIn',
              });
              break;
            case 'programCard':
              try {
                // our standard card refresh only goes back 4 weeks, so bail on any really old cards
                const date4weeksAgo = getDateMinusXDays({ date: getToday(), x: 27 });
                if (date4weeksAgo > link.data.date) {
                  navigation.navigate(navigationRoutes.stacks.main.programsTab);
                } else {
                  navigation.navigate(navigationRoutes.stacks.main.programCardDetail, {
                    date: link.data.date,
                    programCardId: link.data.card_id,
                  });
                }
              } catch (error) {
                navigation.navigate(navigationRoutes.stacks.main.programsTab);
              }
              break;
            default:
              break;
          }
          if (ackWhenDone) {
            self.ackAppLink();
          }
        } else {
          switch (linkType) {
            case 'sso':
              // sso only works if not already logged in
              yield rootStore.loginWithSso(link.url);
              if (ackWhenDone) {
                self.ackAppLink();
              }
              break;
            default:
              break;
          }
        }
        self.processLinkState.setDone();
      } catch (error) {
        const processLinkMessage = idx(error, _ => _.response.data.error);
        self.processLinkResult = { type: linkType, message: processLinkMessage };
        self.processLinkState.setFailed(error);
      }
    });

    /**
     * Process the last link if the app is in the correct context for processing
     */
    const processCurrentLink = flow(function* processCurrentLink() {
      yield self.processLink({ link: self.lastAppLink, ackWhenDone: true });
    });

    return {
      afterCreate,
      processCurrentLink,
      processLink,
      setAppLink,
      ackAppLink,
    };
  });

export default AppLinkProcessor;
