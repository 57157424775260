import { ImagePicker } from '/common/screens';
import camelcaseKeys from 'camelcase-keys';
import { imageUrlToSource, v3TimestampToJsDate, imageDtoToSource } from './utils';
import { urlToThumb } from '../../assets/clubs';

export default class GroupRepository {
  _apiV5;
  _pusherListener;

  constructor({ apiV5, pusherListener }) {
    this._apiV5 = apiV5;
    this._pusherListener = pusherListener;
  }

  getGroups = async () => {
    const thumbLogoUrlToSource = url => {
      if (url.startsWith('http') || url.startsWith('https')) {
        return imageUrlToSource(url + '_thumb.jpg', this._apiV5);
      }
      if (url === 'images/brand/club') {
        // we've moved branding out of here because it's weird, now this switch is done in Group component
        return 'clubThumb';
        //return branding.assets.clubThumb;
      }
      if (url === 'images/clubs/company') {
        return 'clubThumb';
        //return branding.assets.clubThumb || clubs.companyThumb;
      }
      return urlToThumb(url);
    };

    const groupsResponse = await this._apiV5.get('/users/me/clubs?limit=1000000&latest_comments=1');
    const groups = groupsResponse.data.data;

    groups.forEach(group => {
      group.forumsUploadsEnabled = group.forums_uploads_enabled;
      group.isLeaderboardJoined = group.joined;
      group.isLeaderboardPublic = group.is_leaderboard_public;
      group.leaderboardId = group.leaderboards_id;
      group.thumbLogoImageSource = thumbLogoUrlToSource(group.image_url);
      group.unreadComments = group.unread_comments;
      //splice in recent message info
      // grabbing the combined feed is a hacky way to do this that should be semi-performant
      group.recentTopics = group.comments
        ? group.comments.map(comment => ({
            ...comment,
            firstName: comment.author.first_name,
            lastName: comment.author.last_name,
            userPhotoSource: imageUrlToSource(comment.author.photo_url, this._apiV5),
            createdAt: v3TimestampToJsDate(comment.author.created_at),
            userId: comment.author.id,
            replies: [],
            groupId: group.id,
          }))
        : [];
    });

    return groups;
  };

  getTopicsForGroup = async ({ groupId, page }) => {
    const response = await this._apiV5.get(`users/me/clubs/${groupId}/comments?limit=20`, {
      params: { page },
    });
    if (response.data && !response.data.data) {
      let errorMessage = 'Response does not have a data prop. It is a ' + typeof response.data;
      if (typeof response.data === 'string') {
        errorMessage = errorMessage + response.data;
      }
      throw new Error(errorMessage);
    }
    return response.data.data.map(t => this._topicDtoToModel(t));
  };

  getTopic = async ({ groupId, topicId }) => {
    const result = await this._apiV5.get(`users/me/clubs/${groupId}/comments/${topicId}`);
    return this._topicDtoToModel(result.data);
  };

  joinLeaderboard = groupId => {
    return this._apiV5.put(`/users/me/clubs/${groupId}/membership`, { leaderboard: true });
  };

  leaveLeaderboard = groupId => {
    return this._apiV5.put(`/users/me/clubs/${groupId}/membership`, { leaderboard: false });
  };

  getLeaderboard = (groupId, limit) => {
    return this._apiV5
      .get(`/clubs/${groupId}/leaderboard`, { params: { limit } })
      .then(response => {
        const board = response.data.board;
        if (!board) {
          let errorMessage =
            'Leaderboard board property does not exist! Actual contents: ' +
            JSON.parse(response.data);
          throw new Error(errorMessage);
        }
        board.startsAt = board.starts_at
          ? { ...board.starts_at, timezoneType: board.starts_at.timezone_type }
          : null;
        board.endsAt = board.ends_at
          ? { ...board.ends_at, timezoneType: board.ends_at.timezone_type }
          : null;
        board.resetsAt = board.resets_at
          ? { ...board.resets_at, timezoneType: board.resets_at.timezone_type }
          : null;
        // only populated for custom tracker leaderboards
        board.customTrackerId = board.custom_tracker_id;
        board.durationType = board.duration_type;
        const leaders = response.data.leaders;
        leaders.forEach(leader => {
          leader.userPhotoSource = imageUrlToSource(leader.photo_url, this._apiV5);
          leader.firstName = leader.firstname;
          leader.lastName = leader.lastname;
          // prod server is returning strings on decimals
          leader.total = Number.parseFloat(leader.total);
        });
        const position = response.data.position;
        if (position) {
          position.userPhotoSource = imageUrlToSource(position.photo_url, this._apiV5);
          position.firstName = position.firstname;
          position.lastName = position.lastname;
          // prod server is returning strings on decimals
          position.total = Number.parseFloat(position.total);
        }
        const metricType = board.metric_type;
        metricType.uom = board.metric_type.unit;

        return { ...board, leaders, position, metricType };
      });
  };

  /**
   * Post group topic or reply to topic.
   * If topicId is present, then it's a reply
   */
  postGroupMessage = async ({ groupId, comment, attachment, topicId }) => {
    const formData = new FormData();
    if (comment && comment.length > 0) {
      formData.append('comment', comment);
    }
    if (attachment) {
      const resizedImage = await ImagePicker.resizeImage(attachment);
      formData.append('attachment', resizedImage);
    }
    if (topicId) {
      formData.append('parent_id', topicId);
    }
    const response = await this._apiV5.post(`/users/me/clubs/${groupId}/comments`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-Socket-ID': this._pusherListener.getSocketId(),
      },
    });

    return this._topicDtoToModel(response.data);
  };

  redactComment = ({ commentId, groupId }) => {
    return this._apiV5.patch(`/users/me/clubs/${groupId}/comments/${commentId}/redact`, {
      redacted: true,
    });
  };

  filterComment = commentId => {
    return this._apiV5.put(`/users/me/filtered/comment`, { club_comment_id: commentId });
  };

  filterPostsByUser = userId => {
    return this._apiV5.put(`/users/me/filtered/users/comments`, { users_id: userId });
  };

  reportAbuse = ({ commentId, reason }) => {
    return this._apiV5.post(`/report/club/comment/${commentId}`, { reason });
  };

  _topicDtoToModel = dto => {
    return {
      id: dto.id,
      groupId: dto.commentable_id,
      userId: dto.author_id,
      authorType: dto.author.type,
      userPhotoSource: imageUrlToSource(dto.author.photo_url, this._apiV5),
      createdAt: v3TimestampToJsDate(dto.created_at),
      firstName: dto.author.first_name,
      lastName: dto.author.last_name,
      comment: dto.comment,
      replies: dto.clubs_comments ? dto.clubs_comments.map(c => this._replyDtoToModel(c)) : [],
      attachmentSource: dto.attachment
        ? imageDtoToSource(camelcaseKeys(dto.attachment), this._apiV5)
        : null,
      redacted: dto.redacted,
    };
  };

  _replyDtoToModel = dto => {
    return {
      id: dto.id,
      userId: dto.author_id,
      authorType: dto.author.type,
      userPhotoSource: imageUrlToSource(dto.author.photo_url, this._apiV5),
      createdAt: v3TimestampToJsDate(dto.created_at),
      firstName: dto.author.first_name,
      lastName: dto.author.last_name,
      comment: dto.comment,
      redacted: dto.redacted,
      attachmentSource: dto.attachment
        ? imageDtoToSource(camelcaseKeys(dto.attachment), this._apiV5)
        : null,
    };
  };
}
