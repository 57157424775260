import AmplitudeWeb from 'amplitude-js';
import env from '../../config/env';

if (env.amplitudeKey) {
  AmplitudeWeb.getInstance().init(env.amplitudeKey);
  console.log('Amplitude initialized!');
}

const shim = {
  logEventAsync: async eventName => {
    await AmplitudeWeb.getInstance().logEvent(eventName);
  },
  setUserPropertiesAsync: async props => {
    await AmplitudeWeb.getInstance().setUserProperties(props);
  },
  logEventWithPropertiesAsync: async (eventName, props) => {
    await AmplitudeWeb.getInstance().logEvent(eventName, props);
  },
};

// not what we usually do, but makes the whole import * as Amplitude thing work
export const logEventAsync = shim.logEventAsync;
export const setUserPropertiesAsync = shim.setUserPropertiesAsync;
export const logEventWithPropertiesAsync = shim.logEventWithPropertiesAsync;
