import React, { useState, useContext } from 'react';
import { FlatList, View, Text } from 'react-native';
import { observer } from 'mobx-react';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { NavbarWrapper, HeaderIconButton } from '../components/navigation';
import { NudgeTouchable } from '../components';
import { BackIcon, ConfirmIcon } from '../config/icons';
import { textStyles, sizes, colors } from '../config/styles';
import { BrandContext } from '/common/config/branding';

/**
 * Use this within the actual screen for an option picker, providing the applicable props to populate what items will be picked,
 * and what will happen when they're picked.
 */
const OptionPickerScreenBase = observer(
  ({ navigation, title, options, onConfirmKey, selectedKey }) => {
    const [mySelectedKey, setMySelectedKey] = useState(selectedKey);

    const branding = useContext(BrandContext);

    return (
      <NavbarWrapper
        title={title}
        navigation={navigation}
        leftButton={
          <HeaderIconButton onPress={() => onConfirmKey(mySelectedKey)} icon={<BackIcon />} />
        }
        rightButton={
          <HeaderIconButton
            onPress={() => onConfirmKey(mySelectedKey)}
            icon={<ConfirmIcon color={colors.green} />}
          />
        }>
        <FlatList
          data={options}
          renderItem={({ item }) => {
            const isSelected = mySelectedKey == item.key;
            return (
              <NudgeTouchable onPress={() => setMySelectedKey(item.key)}>
                <View
                  style={{
                    paddingVertical: sizes.medium,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingHorizontal: sizes.medium,
                    backgroundColor: isSelected ? colors.bg0 : undefined,
                  }}>
                  {isSelected ? (
                    <MaterialCommunityIcons
                      size={20}
                      name="checkbox-marked-circle"
                      style={{ paddingRight: 5 }}
                      color={branding.highlightedColor}
                    />
                  ) : (
                    <MaterialCommunityIcons
                      size={20}
                      name="checkbox-blank-circle-outline"
                      style={{ paddingRight: 5 }}
                      color={colors.lightText}
                    />
                  )}
                  <View style={{ flex: 1 }}>
                    <Text style={textStyles.standard.dark}>{item.title}</Text>
                    {item.description && (
                      <Text style={textStyles.small.light}>{item.description}</Text>
                    )}
                  </View>
                </View>
              </NudgeTouchable>
            );
          }}
        />
      </NavbarWrapper>
    );
  }
);

export default OptionPickerScreenBase;
