import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { withScreenBehaviors } from '/common/navigation/';
import MultipleCoachesView from './MultipleCoachesView';
import SingleCoachView from './SingleCoachView';
import NoCoachesView from './NoCoachesView';
import { ScreenFirstLoadWrapper } from '../../../common';
import { rootStore } from '../../../../stores/instances';

//import env from '../../../config/env';

const CoachSplitter = observer(({ coaches, rootStore, navigation, route }) => {
  if (coaches.length > 1) {
    return <MultipleCoachesView rootStore={rootStore} navigation={navigation} />;
  } else if (coaches.length === 1) {
    // second wrapper is a workaround for the fact that coaches might be cached in the user object while the rest of the view fails to load
    // Also, it immediately creates a conversation but might not even get to the point where it starts to laod it
    // this would cause the screen to stay spinning, because it has data yet the conversation isn't finished loading
    return (
      <ScreenFirstLoadWrapper
        state={rootStore.conversationsStore.loadInitialState}
        hasData={
          rootStore.conversationsStore.conversations.length &&
          rootStore.conversationsStore.conversations[0].messagesSorted.length
        }
        onPressRefresh={rootStore.conversationsStore.loadInitial}>
        <SingleCoachView rootStore={rootStore} navigation={navigation} route={route} />
      </ScreenFirstLoadWrapper>
    );
  }

  // should never happen
  return null;
});

@inject('rootStore')
@inject('Linking')
@observer
class CoachTab extends Component {
  render() {
    const { rootStore, Linking, navigation, route } = this.props;
    const { conversationsStore } = rootStore;

    return (
      <ScreenFirstLoadWrapper
        state={conversationsStore.loadInitialState}
        hasData={conversationsStore.coachesSorted.length || !!conversationsStore.noCoachPageContent}
        onPressRefresh={conversationsStore.loadInitial}>
        {conversationsStore.coaches.length === 0 && conversationsStore.noCoachPageContent ? (
          <NoCoachesView rootStore={rootStore} navigation={navigation} Linking={Linking} />
        ) : (
          <CoachSplitter
            coaches={conversationsStore.coaches}
            rootStore={rootStore}
            navigation={navigation}
            route={route}
          />
        )}
      </ScreenFirstLoadWrapper>
    );
  }
}

CoachTab.propTypes = {
  rootStore: PropTypes.object,
  navigationStore: PropTypes.object,
};

export default withScreenBehaviors({
  onFocus: () => {
    rootStore.setActiveFrame('coach');
    rootStore.clearMessagesUnreadCount();
    rootStore.conversationsStore.loadInitial({ loadOnlyConversation: true });
  },
  onActive: () => {
    rootStore.clearMessagesUnreadCount();
    rootStore.conversationsStore.loadInitial({ loadOnlyConversation: true });
  },
})(CoachTab);
