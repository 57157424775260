import { types, flow, getEnv } from 'mobx-state-tree';
import { pageableStoreFactory } from 'nudge-client-common/stores';
import BluetoothPeripheral from './BluetoothPeripheral';

const BluetoothConnector = types.compose(
  'BluetoothConnector',
  pageableStoreFactory({
    friendlyObjectPluralName: 'BluetoothPeripherals',
    friendlyObjectSingularName: 'BluetoothPeripheral',
    ObjectClass: BluetoothPeripheral,
    repositoryName: 'bluetoothSyncRepository',
    repositoryFetchFunctionName: 'getAvailableBluetoothPeripherals',
    sortKeyGeneratorFunction: p => p.name,
    pagingMode: 'none', // options v3, none
    hasId: false,
    objectClassIdPropName: 'peripheralId',
  }),
  types
    .model({
      // keep these separate because they will be unloaded when the list is refreshed
      // TODO: also save values to local storage so they will persist
      lastReadValues: types.frozen(),
    })
    .views(self => ({
      get supportedBluetoothPeripherals() {
        const supportedPeripherals = getEnv(self).getBranding().sync.supportedBluetoothPeripherals;
        return self.bluetoothPeripheralsSorted.filter(b =>
          supportedPeripherals.find(pid => pid === b.peripheralId)
        );
      },
      lastReadValueForPeripheralId(peripheralId) {
        const myValue = self.lastReadValues && self.lastReadValues[peripheralId];
        if (myValue) {
          return myValue;
        }
        return null;
      },
    }))
    .actions(self => {
      const afterCreate = () => {
        const bluetoothListener = getEnv(self).bluetoothListener;
        bluetoothListener.onPeripheralDidPair = event => {
          const peripheral = self.bluetoothPeripheralForId(event.peripheralId);
          peripheral.setPairingStatus(peripheral.pairingStatuses.paired);
        };
        bluetoothListener.onPeripheralDidNotPair = event => {
          console.warn('PAIRING ERROR:');
          console.log(event);
          const peripheral = self.bluetoothPeripheralForId(event.peripheralId);
          if (peripheral) {
            peripheral.setPairingStatus(peripheral.pairingStatuses.didFailToPair);
            peripheral.setPairingError(event.error);
          }
        };
        bluetoothListener.onPeripheralReadyToRead = event => {
          const peripheral = self.bluetoothPeripheralForId(event.peripheralId);
          peripheral.setReadingStatus(peripheral.readingStatuses.isReadyToRead);
        };
        bluetoothListener.onPeripheralPassiveDidRead = event => {
          if (event.data && event.data.length > 0) {
            const sorted = event.data.slice().sort((a, b) => b.timestamp - a.timestamp);
            self.setLastReadValue({ peripheralId: event.peripheralId, value: sorted[0] });
          }
        };
        bluetoothListener.startListening();
      };

      const pairBluetoothPeripheral = flow(function* pairBluetoothPeripheral(peripheralId) {
        try {
          const peripheral = self.bluetoothPeripheralForId(peripheralId);
          peripheral.setPairingStatus(peripheral.pairingStatuses.pairing);
          yield getEnv(self).bluetoothSyncRepository.pairBluetoothPeripheral(peripheral);
        } catch (error) {
          console.log(error);
        }
      });

      const unpairBluetoothPeripheral = flow(function* unpairBluetoothPeripheral(peripheralId) {
        try {
          const peripheral = self.bluetoothPeripheralForId(peripheralId);
          peripheral.setPairingStatus(peripheral.pairingStatuses.unpaired);
          yield getEnv(self).bluetoothSyncRepository.unpairBluetoothPeripheral(peripheralId);
        } catch (error) {
          console.log(error);
        }
      });

      const setLastReadValue = function({ peripheralId, value }) {
        let myLastReadValues = self.lastReadValues || {};
        myLastReadValues = { ...myLastReadValues };
        myLastReadValues[peripheralId] = value;
        self.lastReadValues = myLastReadValues;
        console.log('setting read values');
        console.log(myLastReadValues);
      };

      return {
        afterCreate,
        pairBluetoothPeripheral,
        unpairBluetoothPeripheral,
        setLastReadValue,
      };
    })
);

export default BluetoothConnector;
