import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react/native';
import { View, Text, Image } from 'react-native';
import { textStyles, sizes, fonts } from '../config/styles';

/**
 * A friendly error to display in the middle of a screen when loading fails.
 * Eventually we'll probably include an image here.
 */
const EmptyListPlaceholder = observer(({ title, message, image, style }) => (
  <View
    style={[
      {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        paddingLeft: sizes.large * 2,
        paddingRight: sizes.large * 2,
      },
      style,
    ]}>
    {image ? <Image source={image} /> : null}
    {title && (
      <Text
        style={[
          textStyles.bold.dark,
          {
            textAlign: 'center',
            fontSize: fonts.sizes.large,
            paddingTop: image ? sizes.large : undefined,
            paddingBottom: sizes.large,
          },
        ]}>
        {title}
      </Text>
    )}
    <Text style={[textStyles.standard.light, { textAlign: 'center' }]}>{message}</Text>
  </View>
));

EmptyListPlaceholder.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  style: PropTypes.any,
  showImage: PropTypes.bool,
};

EmptyListPlaceholder.defaultProps = {
  title: '',
  message: '',
  actionMessage: '',
  onPressRetry: null,
  style: null,
  showImage: true,
};

export default EmptyListPlaceholder;
