import { useEffect, useCallback, useState } from 'react';
import { getToday } from 'nudge-client-common/lib/dates';
import { navigationRoutes } from '../../config/constants';
import { useAlert } from '/common/navigation';
import { useMediaQueryInfo } from '/common/config/styles';

/**
 * Determine the program event based on the route. If it's not loaded, return null;
 */
const resolveProgramEventFromRoute = ({ route, rootStore }) => {
  const { programEventId, date, programCardId } = route.params;
  const { logStore } = rootStore;
  let programEvent = null;
  if (programEventId) {
    programEvent = logStore.programEvents.find(p => p.id === programEventId);
  } else {
    programEvent = logStore.programEventFor({
      date,
      programCardId,
    });
  }
  return programEvent;
};

/**
 * Determine if a card was accessed from the program tab or a notification based on the route params.
 * Returns a string indicating the source. Used for analytics.
 */
const resolveCardSourceFromRoute = route => {
  if (route.params.programEventId) {
    return 'tab';
  } else {
    return 'notification';
  }
};

/**
 * Implements common card viewing functionality so these can be shared across screens.
 * Will log Amplitude events on first open and at all other points desired.
 *
 * Params:
 * programEvent - the event to be loaded initially for card detail
 * cardOpenedSource - source of the card open - used for analytics
 * rootStore, Linking, navigation - dependencies (we could actually use context/ useNavigation to get these, maybe later)
 * returns:
 * Object with:
 *  programEvent - the event that's to be displayed currently
 *  onPressLink, onPressVideo, onPressBack, onPressEvent, onPressTracker - events used by all card detail screens
 */
function useCardDetailBehaviors({
  programEvent,
  cardOpenedSource,
  rootStore,
  Linking,
  navigation,
}) {
  // log initial card open
  const [cardOpenLogged, setCardOpenLogged] = useState(false);
  const alert = useAlert();

  const { isWideScreen } = useMediaQueryInfo();

  useEffect(() => {
    // event may be null when card first opened, because it needs to page in data
    if (programEvent && !cardOpenLogged) {
      rootStore.logEvent('Card Opened', () => ({
        source: cardOpenedSource,
        feedKey: programEvent.id,
        cardId: programEvent.programCard.id,
        title: programEvent.programCard.title,
        numTrackers: programEvent.programCard.trackerComponents.length,
        // guess this is null if it's not daily or weekly?
        repeatingType: programEvent.share ? programEvent.share.repeatingType : 'UNKNOWN',
      }));
      rootStore.logStore.markProgramEventAsRead(programEvent);
      setCardOpenLogged(true);
    }
  }, [programEvent, cardOpenedSource, cardOpenLogged]);

  // assemble callbacks

  const onPressPdf = useCallback(
    component => {
      rootStore.openShareableFileInOsViewer(component.upload.id);
    },
    [programEvent, rootStore]
  );

  const onPressLink = useCallback(
    (url, component /* safe workaround so we don't break button while adding amplitude */) => {
      Linking.openURL(url, { forceHttpOnNoProtocol: !url.includes('mailto') });
      try {
        rootStore.logEvent('Card Button Tapped', () => ({
          feedKey: programEvent.id,
          cardId: programEvent.programCard.id,
          title: component.body,
        }));
      } catch (error) {}
    },
    [programEvent, rootStore]
  );

  const onPressJoinGroup = useCallback(
    (component /* safe workaround so we don't break button while adding amplitude */) => {
      alert(
        'Join now?',
        'Once you join, you’ll start receiving customized program content.',
        [
          {
            text: 'Join',
            style: 'default',
            onPress: async () => {
              const { settingsStore } = rootStore;
              await settingsStore.joinGroup(component.clubId);
              if (settingsStore.updateUserState.isDone) {
                alert("You're in!", 'Stay tuned for new content.');
              } else {
                alert('Error joining', 'Please try again later.');
              }

              try {
                rootStore.logEvent('Join Group Tapped', () => ({
                  feedKey: programEvent.id,
                  cardId: programEvent.programCard.id,
                  clubId: component.clubId,
                  title: component.body,
                }));
              } catch (error) {}
            },
          },
          {
            text: 'Cancel',
            onPress: () => {},
            style: 'cancel',
          },
        ],
        {
          cancelable: true,
          onDismiss: () => {},
        }
      );
    },
    [programEvent, rootStore]
  );

  // used to open video, but now we do that interal to program card detail via modal,
  // so now we just track from here
  const onTrackOpenVideo = useCallback(
    component => {
      try {
        rootStore.logEvent('Card Video Tapped', () => ({
          feedKey: programEvent.id,
          cardId: programEvent.programCard.id,
          url: component.url,
        }));
      } catch (error) {}
    },
    [programEvent, rootStore]
  );

  // navigate to tracker detail screen if there's already a log for today or start a new log if there's not
  // navigate to tracker detail screen if there's already a log for today or start a new log if there's not
  const onPressViewTracker = useCallback(
    ({ trackerId, date, dailyGoal }) => {
      const tracker = rootStore.logStore.trackerFor(trackerId);
      navigation.navigate(navigationRoutes.stacks.main.trackerHistory, {
        trackerId,
        date,
        timeframe: tracker.clientMeta.graphConfig.summaryTimeframe,
        dailyGoal,
      });

      try {
        rootStore.logEvent('Card Tracker Tapped', () => ({
          feedKey: programEvent.id,
          cardId: programEvent.programCard.id,
          title: rootStore.logStore.trackerFor(trackerId).name,
          numTrackers: programEvent.programCard.trackerComponents.length,
        }));
      } catch (error) {}
    },
    [programEvent, rootStore, navigation]
  );

  const onPressAddLog = useCallback(
    ({ trackerId, date }) => {
      navigation.navigate(
        isWideScreen
          ? navigationRoutes.stacks.main.addLog_modal
          : navigationRoutes.stacks.main.addLog,
        {
          date,
          trackerId,
        }
      );

      try {
        rootStore.logEvent('Card Tracker Tapped', () => ({
          feedKey: programEvent.id,
          cardId: programEvent.programCard.id,
          title: rootStore.logStore.trackerFor(trackerId).name,
          numTrackers: programEvent.programCard.trackerComponents.length,
        }));
      } catch (error) {}
    },
    [programEvent, rootStore, navigation, isWideScreen]
  );

  const onPressBack = useCallback(() => {
    navigation.goBack();

    rootStore.logEvent('Card Closed', () => ({
      cardId: programEvent.programCard.id,
      feedKey: programEvent.id,
      title: programEvent.programCard.title,
      goalsTotal: programEvent.goalCompletionStats.total,
      goalsComplete: programEvent.goalCompletionStats.numComplete,
      goalsPercentage: programEvent.goalCompletionStats.percentComplete,
      numTrackers: programEvent.programCard.trackerComponents.length,
    }));
  }, [programEvent, rootStore, navigation]);

  const onPressEvent = useCallback(
    myProgramEvent => {
      navigation.setParams({ programEventId: myProgramEvent.id });
    },
    [navigation]
  );

  return {
    onPressLink,
    onTrackOpenVideo,
    onPressViewTracker,
    onPressAddLog,
    onPressBack,
    onPressEvent,
    onPressJoinGroup,
    onPressPdf,
  };
}

export { useCardDetailBehaviors, resolveProgramEventFromRoute, resolveCardSourceFromRoute };
