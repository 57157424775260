import React, { useContext, useCallback } from 'react';
import { View, Text, Image } from 'react-native';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { BrandContext } from '../../config/branding';
import { SecurableImage, RowChevronButton } from '/common/components';
import { colors, sizes, textStyles } from '/common/config/styles';
import getFeatureImageSource from './internal/getFeatureImageSource';

/**
 * Card displayed on Coach mobile feed
 * Includes a thumbnail image and coach (internal) title, similar to Coach Web.
 */
const TagListCard = observer(({ programEvent, onPressCard }) => {
  const branding = useContext(BrandContext);
  const onPress = useCallback(() => {
    onPressCard(programEvent.id);
  }, [onPressCard]);

  const programCard = programEvent.programCard;

  const featureImageSource = getFeatureImageSource(programCard, branding);

  const cardInner = (
    <View
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        paddingVertical: sizes.small,
      }}>
      <View
        style={{
          height: 45,
          width: 80,
        }}>
        {featureImageSource ? (
          <SecurableImage
            source={featureImageSource}
            resizeMode={'cover'}
            style={{
              width: 80,
              height: 45,
              backgroundColor: colors.bg0,
              borderRadius: 4,
            }}
          />
        ) : (
          <Image
            source={require('../../assets/icons/card-placeholder.png')}
            resizeMode={'cover'}
            style={{
              width: 80,
              height: 45,
              backgroundColor: colors.bg0,
              borderRadius: 4,
            }}
          />
        )}
      </View>
      <Text
        style={[
          textStyles.standard.semibold,
          {
            alignSelf: 'center',
            marginLeft: sizes.medium,
            flex: 1,
            marginRight: sizes.medium,
          },
        ]}>
        {programCard.title}
      </Text>
    </View>
  );

  return <RowChevronButton onPress={onPress} LabelComponent={() => cardInner} />;
});

TagListCard.propTypes = {
  programEvent: PropTypes.object.isRequired,
};

export default TagListCard;
