

/**
 * Get an array of font assets.
 * Must have a single key with the font name (any name of your choosing)
 * that's set to the require() for the font.
 */
const getAllFonts = () => ({
  'custom-icons': require('./fonts/fontello.ttf'),
  //...Entypo.font, //  these must be prefetched and cached - see https://docs.expo.io/versions/latest/guides/icons.html#existing-projects
  //...MaterialCommunityIcons.font,
  // fontello file from coach web - has the PDF icon
  // even through this file is in nudge-client-common, we copied it here so we can import nudge-mobile-v4 into nudge-coach-mobile
  'custom-icons-web': require('./fonts/fontello-web.ttf'),
});

const assetsToLoadAsync = [
  require('./icons/settings.png'),
  require('./icons/trends.png'),
  require('./icons/camera.png'),
  require('./icons/log.png'),
  require('./icons/connect.png'),
  require('./icons/x.png'),
  require('./icons/messages.png'),
  require('./icons/community.png')
];

const getAllAssets = () => {
  return assetsToLoadAsync;
};


export { getAllFonts, getAllAssets };
