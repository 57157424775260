import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Text, View, Platform } from 'react-native';
import { NudgeTouchable as Touchable } from '/common/components/';
import TrackerValue from '../TrackerValue';
import { PlusIcon } from '/common/config/icons';
import { colors, textStyles, sizes } from '/common/config/styles';
import { usePersonalization } from '/common/config/personalization';

/**
 * The top line of the typical tracker cell. Typically shows the icon, name, and value
 * when it is not focused, then just the icon and name when focused.
 */
const InlineLogItem = observer(
  ({
    tracker,
    t,
    onPressViewTracker,
    onPressAddLog,
    programEvent,
    programCardComponent,
    disableAddLog,
  }) => {
    // we can't memoize any of this because stats will recalculate without any of these variables changing
    const stats = programEvent.statsFor({ componentId: programCardComponent.id });

    const onPressTrackerCallback = () =>
      onPressViewTracker({
        trackerId: tracker.id,
        date: programEvent.date,
        dailyGoal: stats.interval === 'day' ? stats.goal : null,
      });

    const onPressAddLogCallback = () =>
      onPressAddLog({
        trackerId: tracker.id,
        date: programEvent.date,
        dailyGoal: stats.interval === 'day' ? stats.goal : null,
      });

    const hasEntryForToday = stats.latestLog || stats.total || stats.hasValidEntries;
    const personalization = usePersonalization();
    const branding = personalization.branding;

    return (
      <View style={{ marginBottom: 35 }}>
        <Text style={[textStyles.standard.dark, { marginBottom: sizes.small * 1.5 }]}>
          <Text>{t(`LOG:TRACKERS:${tracker.type.toUpperCase()}`, tracker.name)}</Text>
        </Text>
        <View>
          <Touchable
            onPress={
              hasEntryForToday || tracker.clientMeta.isReadOnly || disableAddLog // prevent adding entries if tracker is read-only
                ? onPressTrackerCallback
                : onPressAddLogCallback
            }>
            <View
              style={{
                minHeight: 40,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: colors.bg1,
                borderRadius: sizes.borderRadius,
              }}>
              <View
                style={[
                  ...(Platform.OS === 'web' ? [{ width: '100%' }] : []),
                  { padding: sizes.small * 1.3 },
                ]}>
                {stats.goal ? (
                  hasEntryForToday ? (
                    <TrackerValue
                      stats={stats}
                      tracker={tracker}
                      useLongFormForUnits
                      usePreferredTimeUnit
                    />
                  ) : (
                    <TrackerValue
                      stats={stats}
                      tracker={tracker}
                      useLongFormForUnits
                      useGoalOnlyFormatForZeroTotal
                      usePreferredTimeUnit
                      goalPreamble={
                        <Text style={textStyles.standard.dark}>
                          {`${t(
                            `PROGRAMS:TRACKER_PLACEHOLDERS:${tracker.clientMeta.editor.toUpperCase()}`
                          )}   `}
                        </Text>
                      }
                    />
                  )
                ) : hasEntryForToday ? (
                  <TrackerValue
                    stats={stats}
                    tracker={tracker}
                    useLongFormForUnits
                    usePreferredTimeUnit
                  />
                ) : (
                  <Text style={textStyles.standard.light}>
                    {t(`PROGRAMS:TRACKER_PLACEHOLDERS:${tracker.clientMeta.editor.toUpperCase()}`)}
                  </Text>
                )}
              </View>
              {hasEntryForToday &&
              !tracker.clientMeta
                .multipleValuesDisabled /* can't add value if only value already exists */ &&
              !tracker.clientMeta.isReadOnly /* can't add value if read only*/ &&
              !disableAddLog ? (
                <Touchable onPress={onPressAddLogCallback}>
                  <View
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: branding.trackerColors[tracker.palettesId],
                      borderRadius: sizes.borderRadius / 2,
                      height: 44,
                      width: 44,
                    }}>
                    <PlusIcon size={20} color={'white'} />
                  </View>
                </Touchable>
              ) : null}
            </View>
          </Touchable>
        </View>
      </View>
    );
  }
);

InlineLogItem.propTypes = {
  tracker: PropTypes.object.isRequired,
};

export default withTranslation()(InlineLogItem);
