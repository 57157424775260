import { types } from 'mobx-state-tree';

const Coach = types
  .model('Coach', {
    id: types.identifierNumber,
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    photoSource: types.frozen(),
    promoCode: types.maybeNull(types.string),
  })
  .actions(() => ({}));

export default Coach;
