import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { NavbarWrapper, HeaderTextButton } from '../components/navigation';
import { LoadingShadeWrapper } from '../components';
import { ExpandedLeaderboard } from '../components/social';
import { containerStyles, colors } from '../config/styles';
import { ScreenError, withAlert } from '../navigation';

@inject('rootStore')
@observer
class LeaderboardScreen extends Component {
  _onPressJoinLeaderboard = () => {
    const { t, route, rootStore, alert } = this.props;
    const { socialStore } = rootStore;

    const socialFeed = socialStore.socialFeedForClub(route.params.clubId);

    const leaderboard = socialFeed.leaderboard;

    if (!socialFeed.isLeaderboardJoined) {
      alert(
        t('COMMUNITY:LEADERBOARD:JOIN_CONFIRMATION_DIALOG_TITLE'),
        t('COMMUNITY:LEADERBOARD:JOIN_CONFIRMATION_DIALOG_MESSAGE', {
          leaderboardType: leaderboard.description,
        }),
        [
          { text: t('GENERAL:CANCEL'), onPress: () => {}, style: 'cancel' },
          {
            text: t('COMMUNITY:LEADERBOARD:JOIN'),
            onPress: () => {
              socialFeed.joinLeaderboard().then(() => {
                if (socialFeed.isLeaderboardJoined) {
                  alert(
                    t('COMMUNITY:DIALOGS:JOIN_LEADERBOARD_CONFIRMATION_TITLE'),
                    t('COMMUNITY:DIALOGS:JOIN_LEADERBOARD_CONFIRMATION_MESSAGE')
                  );
                }
              });
            },
          },
        ],
        { cancelable: false }
      );
    } else {
      alert(
        t('COMMUNITY:LEADERBOARD:LEAVE_CONFIRMATION_DIALOG_TITLE'),
        t('COMMUNITY:LEADERBOARD:LEAVE_CONFIRMATION_DIALOG_MESSAGE'),
        [
          { text: t('GENERAL:CANCEL'), onPress: () => {}, style: 'cancel' },
          {
            text: t('COMMUNITY:LEADERBOARD:LEAVE'),
            onPress: () => {
              socialFeed.leaveLeaderboard();
            },
          },
        ],
        { cancelable: false }
      );
    }
  };

  render() {
    const { navigation, t, route, rootStore } = this.props;

    const { socialStore } = rootStore;

    const socialFeed = socialStore.socialFeedForClub(route.params.clubId);

    const leaderboard = socialFeed.leaderboard;
    const archivedleaderboard = socialFeed.archivedleaderboard;

    return (
      <NavbarWrapper
        title={leaderboard.description}
        hasBackButton
        navigation={navigation}
        rightButton={
          rootStore.perspective === 'client' && socialFeed.isLeaderboardJoined ? (
            <HeaderTextButton
              text={t('COMMUNITY:LEADERBOARD:LEAVE')}
              onPress={this._onPressJoinLeaderboard}
              disabled={socialFeed.updateMembershipState.isPending}
            />
          ) : null
        }>
        <ScreenError
          state={socialFeed.updateMembershipState}
          title={'Error updating leaderboard membership'}
        />
        <LoadingShadeWrapper
          isLoading={socialFeed.updateMembershipState.isPending}
          style={[containerStyles.fillAvailableSpace, { backgroundColor: colors.bg1 }]}>
          <ExpandedLeaderboard
            upToTheMinuteTotalForUser={socialFeed.upToTheMinuteTotalsForUser}
            leaderboard={leaderboard}
            archivedleaderboard={archivedleaderboard}
            leaders={leaderboard.leadersSorted}
            archivedleaders={archivedleaderboard?.leadersSorted}
            you={leaderboard.position}
            metricType={leaderboard.metricType}
            isLeaderboardJoined={socialFeed.isLeaderboardJoined}
            perspective={rootStore.perspective}
            onPressJoinLeaderboard={this._onPressJoinLeaderboard}
          />
        </LoadingShadeWrapper>
      </NavbarWrapper>
    );
  }
}

LeaderboardScreen.propTypes = {
  navigation: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(withAlert(LeaderboardScreen));
