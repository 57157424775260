// adds image process indicator and no longer opens weird default fullscreen modal
// but instead implements standard onPress.

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { observer } from 'mobx-react/native';
import { sizes, textStyles, colors } from '../../config/styles';
import { FileIcon } from '../../config/icons';

const baseBubbleStyle = { borderRadius: sizes.borderRadius };

@observer
class MessageFileAttachment extends Component {
  _onLongPress = () => {
    if (this.props.onLongPress) {
      this.props.onLongPress(this.context, this.props.currentMessage);
    }
  };

  // removed long press for images because there's nothing actionable until you can delete them.
  // set opacity to 1 so it never flashes when you're scrolling over it
  render() {
    const { containerStyle, bubbleStyle, textStyle, currentMessage, onPress } = this.props;
    return (
      <View
        style={[
          {
            marginBottom: 1 /* extra margin for space above potential caption */,
          },
          containerStyle,
        ]}>
        <TouchableOpacity onPress={onPress} activeOpacity={1} onLongPress={this._onLongPress}>
          <View
            style={[{ flexDirection: 'row', alignItems: 'center' }, baseBubbleStyle, bubbleStyle]}>
            <FileIcon />
            <Text
              ellipsizeMode="tail"
              numberOfLines={1}
              style={[textStyle, { marginLeft: sizes.small }]}>
              {currentMessage.attachmentSource.sourceFilename}
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

MessageFileAttachment.contextTypes = {
  actionSheet: PropTypes.func,
};

MessageFileAttachment.defaultProps = {
  currentMessage: {
    image: null,
  },
  containerStyle: {},
  bubbleStyle: {},
  imageProps: {},
  highlightColor: 'blue',
  textStyle: { fontSize: 20 },
};

MessageFileAttachment.propTypes = {
  currentMessage: PropTypes.object,
  containerStyle: PropTypes.any,
  bubbleStyle: PropTypes.any,
  imageProps: PropTypes.object,
  highlightColor: PropTypes.string,
  textStyle: PropTypes.any,
};

const MyFileAttachment = props => (
  <MessageFileAttachment
    textStyle={textStyles.standard.dark}
    bubbleStyle={{ backgroundColor: colors.bg1, padding: sizes.small }}
    {...props}
  />
);

export default MyFileAttachment;
