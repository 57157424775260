import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';
import ImageZoom from 'react-native-image-pan-zoom';
import { View, TouchableOpacity, Text, Dimensions, SafeAreaView, Platform } from 'react-native';
import { sizes, textStyles } from '../config/styles';
import { SecurableImage } from '../components';

// there's limited support, but not worrying about it for now
// long term, should look at async isSharingAvailable() https://docs.expo.dev/versions/latest/sdk/sharing/
const isSharingAvailable = Platform.OS !== 'web';

const navbarButtonStyle = {
  paddingLeft: 16,
  paddingRight: 16,
  justifyContent: 'center',
};

const imageStyle = {
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
};

// temporary home while we figure out zooming and sharing
class FullscreenImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }
  _onPressShare = async () => {
    const { source } = this.props;
    try {
      this.setState({ isLoading: true });
      const file = await FileSystem.downloadAsync(
        source.uri /* custom munged sources */ ||
          source.url /* standard image from API - trying to switch to this in order to avoid munging */,
        FileSystem.cacheDirectory + source.sourceFilename,
        { headers: source.headers /*mimeType: 'image/jpeg'*/ } // need to copy token from image source in order to download
      );
      this.setState({ isLoading: false });
      await Sharing.shareAsync(file.uri);
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const {
      onPressClose,
      CloseButtonComponent,
      source,
      style,
      extraBottomButton,
      navigation,
    } = this.props;

    if (!source) {
      return null;
    }

    const bottomBarGutter = Platform.OS === 'ios' ? sizes.iphoneXBottomPadding : 0; // iphone x helper isn't detecting iPad, so just allowing extra space here for now
    const bottomBarContentHeight = sizes.navbarHeight; // helps match spacing of top button
    return (
      <View
        style={[
          {
            flex: 1,
            backgroundColor: 'white',
            paddingTop:
              Platform.OS === 'ios'
                ? sizes.statusBarHeight
                : 10 /* something about Modal makes this weird on android */,
          },
          style,
        ]}>
        <SafeAreaView>
          <TouchableOpacity onPress={onPressClose} style={navbarButtonStyle}>
            <CloseButtonComponent />
          </TouchableOpacity>
        </SafeAreaView>
        <View style={imageStyle}>
          <ImageZoom
            maxScale={2}
            cropWidth={Dimensions.get('window').width}
            cropHeight={
              Dimensions.get('window').height -
              sizes.statusBarAndNavbarHeight -
              bottomBarContentHeight -
              bottomBarGutter
            }
            imageWidth={Dimensions.get('window').width}
            imageHeight={Dimensions.get('window').height}>
            <SecurableImage
              source={source}
              resizeMode="contain"
              style={{
                width: '100%',
                height: '100%',
              }}
            />
          </ImageZoom>
        </View>
        <SafeAreaView
          style={{
            marginBottom: bottomBarGutter,
            height: bottomBarContentHeight,
            justifyContent: extraBottomButton ? 'space-around' : 'center',
            alignItems: 'center',
            flexDirection: 'row',
          }}>
          {extraBottomButton ? (
            <TouchableOpacity onPress={() => extraBottomButton.onPress({ navigation })}>
              <Text style={textStyles.medium.dark}>{extraBottomButton.title}</Text>
            </TouchableOpacity>
          ) : null}
          {isSharingAvailable /* not available until binary update that includes native module */ &&
          source.sourceFilename /* avoids issue where user tries to share file they just uploaded */ ? (
            <TouchableOpacity
              onPress={this._onPressShare}
              style={{
                textAlignVertical: 'center',
                height: bottomBarContentHeight,
                justifyContent: 'center',
                paddingHorizontal: sizes.medium,
              }}>
              <Text style={[textStyles.medium.dark]}>Share</Text>
            </TouchableOpacity>
          ) : null}
        </SafeAreaView>
      </View>
    );
  }
}

FullscreenImage.propTypes = {
  source: PropTypes.object,
  navigation: PropTypes.any,
  onPressClose: PropTypes.func,
  CloseButtonComponent: PropTypes.func,
  style: PropTypes.any,
  extraBottomButton: PropTypes.any,
};

FullscreenImage.defaultProps = {
  onPressClose: null,
  CloseButtonComponent: props => <Text {...props}>X</Text>,
  extraBottomButton: null,
  style: null,
  navigation: null,
  source: null,
};

export default FullscreenImage;
