import React, { Component } from 'react';
import { View, Text, TouchableOpacity, Dimensions } from 'react-native';
import PropTypes from 'prop-types';
import { sizes, colors, fonts } from '/common/config/styles';

/**
 * A single row with an onPress radio button action and a title.
 */

function RadioButton(props) {
  return (
    <TouchableOpacity
      onPress={props.onPress}
      style={[
        {
          height: 27,
          width: 27,
          borderRadius: 20,
          borderWidth: 2,
          borderColor: colors.border3,
          alignItems: 'center',
          justifyContent: 'center',
        },
        props.style,
      ]}>
      {props.selected ? (
        <View
          style={{
            height: 18,
            width: 18,
            borderRadius: 20,
            backgroundColor: '#000',
          }}
        />
      ) : null}
    </TouchableOpacity>
  );
}
function IncompleteBorder() {
  return (
    <View
      style={{
        position: 'absolute',
        bottom: 0,
        height: 1,
        width: Dimensions.get('window').width,
        display: 'flex',
        alignItems: 'center',
      }}>
      <View
        style={{
          width: Dimensions.get('window').width - sizes.medium * 2,
          height: '100%',
          backgroundColor: colors.border2,
        }}
      />
    </View>
  );
}
export default class RowRadioButton extends Component {
  render() {
    const { onPress, style, label, isSelected } = this.props;

    const cellStyle = {
      position: 'relative',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingHorizontal: sizes.medium,
      paddingVertical: sizes.medium,
    };
    return (
      <View style={[cellStyle, style]}>
        <Text
          style={{
            color: isSelected ? colors.darkText : colors.gray,
            fontSize: fonts.sizes.standard,
            maxWidth: '80%',
            fontWeight: '500',
          }}>
          {label}
        </Text>
        <RadioButton selected={isSelected} onPress={onPress} />
        <IncompleteBorder />
      </View>
    );
  }
}

RadioButton.propTypes = {
  onPress: PropTypes.func,
  style: PropTypes.any,
  selected: PropTypes.bool,
};

RowRadioButton.propTypes = {
  onPress: PropTypes.func,
  style: PropTypes.any,
  label: PropTypes.any,
  isSelected: PropTypes.bool.isRequired,
};

RowRadioButton.defaultProps = {
  style: { paddingHorizontal: sizes.medium },
};
