import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Text, View } from 'react-native';
import { sizes, textStyles } from '/common/config/styles';
/**
 * The top line of the typical tracker cell. Typically shows the icon, name, and value
 * when it is not focused, then just the icon and name when focused.
 */
const LogEntryHeader = observer(({ tracker, button, text }) => {
  const { t } = useTranslation();

  const myText = text || `${t(`LOG:TRACKERS:${tracker.type.toUpperCase()}`, tracker.name)}`;

  return (
    <View style={{ paddingTop: sizes.statusBarHeight }}>
      {button && <View style={{ paddingBottom: 12 }}>{button}</View>}
      <Text
        style={[
          textStyles.standard.semibold,
          { paddingHorizontal: sizes.large, paddingBottom: 12, lineHeight: 24 },
        ]}>
        {myText}
      </Text>
    </View>
  );
});

LogEntryHeader.propTypes = {
  tracker: PropTypes.object.isRequired,
};

export default LogEntryHeader;
