import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Text } from 'react-native';
import AlertBar from './AlertBar';
import { sizes, colors, textStyles } from '../config/styles';
import { ErrorIcon } from '../config/icons';

export default class ErrorAlertBar extends Component {
  render() {
    const { title, message } = this.props;
    return (
      <AlertBar>
        <View
          style={{
            minHeight: 30,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingVertical: sizes.small,
            paddingHorizontal: sizes.small,
          }}>
          <ErrorIcon size={sizes.thumbnail} color={colors.error} />
          <View style={{ flex: 1, marginLeft: sizes.small }}>
            <Text style={[textStyles.medium.dark, { color: colors.error }]}>{title}</Text>
            {message.length ? (
              <Text style={[textStyles.standard.dark, { color: colors.error }]}>{message}</Text>
            ) : null}
          </View>
        </View>
      </AlertBar>
    );
  }
}

ErrorAlertBar.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
};

ErrorAlertBar.defaultProps = {
  message: '',
};
