import React, { useCallback } from 'react';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { EmptyListPlaceholder, NudgeFlatList as FlatList, AestheticallyPleasingListFooter, ListSeparatorLine } from '/common/components';
//import { BrandContext } from '../../config/branding';
import { colors, sizes, containerStyles } from '/common/config/styles';
import TagListCard from './TagListCard';

const CardsList = observer(({ onPressCard, programEvents }) => {
  const { t } = useTranslation();

  // *** callbacks ***

  const renderItem = useCallback(
    ({ item }) => {
      return <TagListCard programEvent={item} onPressCard={onPressCard} />;
    },
    [onPressCard, onPressCard]
  );

  const keyExtractor = useCallback(item => item.id);

  // *** end callbacks ***

  // *** memoized grouped items ***

  // NOTE: using default section list behavior of stickySectionHeadersEnabled for iOS, but not Android
  // This is because the android animation gets real weird once you scroll down the list
  // The other option is to keep sticky section headers for all but disable the animation on Android

  return (
    <View
      style={[containerStyles.fillAvailableSpace, { backgroundColor: colors.bg1, paddingTop: 0 }]}>
      <FlatList
        contentContainerStyle={{ flexGrow: 1 }}
        data={programEvents}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        ListEmptyComponent={
          <EmptyListPlaceholder
            title={t('PROGRAMS:EMPTY_TAG:TITLE')}
            message={t('PROGRAMS:EMPTY_TAG:MESSAGE')}
          />
        }
        ListFooterComponent={AestheticallyPleasingListFooter}
        ItemSeparatorComponent={ListSeparatorLine}
      />
    </View>
  );
});

export default CardsList;
