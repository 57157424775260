import { unionBy } from 'lodash';
import camelcaseKeys from 'camelcase-keys';
import { ImagePicker } from '/common/screens';

export default class ClubsRepository {
  _apiV5;
  _pusherListener;
  _context;
  _enableSortModeFlag;

  /**
   * context = 'users' or 'coaches'
   * enableSortModeFlag - we turn off the new sort mode until it is in prod
   */
  constructor({ apiV5, pusherListener, context, enableSortModeFlag = false }) {
    this._apiV5 = apiV5;
    this._pusherListener = pusherListener;
    this._context = context;
    this._enableSortModeFlag = enableSortModeFlag;
  }

  getClubs = async () => {
    const thumbLogoUrlToSource = url => {
      if (url.startsWith('http') || url.startsWith('https')) {
        return url + '_thumb.jpg';
      }
      if (url === 'images/brand/club') {
        // we've moved branding out of here because it's weird, now this switch is done in Group component
        return 'clubThumb';
        //return branding.assets.clubThumb;
      }
      if (url === 'images/clubs/company') {
        return 'clubThumb';
        //return branding.assets.clubThumb || clubs.companyThumb;
      }
      return null;
    };

    const groupsResponse = await this._apiV5.get(
      `/${this._context}/me/clubs?limit=1000000&latest_comments=1`
    );
    let coachClubs = camelcaseKeys(groupsResponse.data.data, { deep: true });

    coachClubs = coachClubs.filter(c => c.forumsEnabled);

    let allClubs = [];

    if (this._context === 'coaches') {
      const allClubsResponse = await this._apiV5.get(`/clubs?limit=1000000&latest_comments=1`);

      // we splice in just the company clubs not in the coach list, because /clubs doesn't support latest_comments

      allClubs = camelcaseKeys(allClubsResponse.data.data, { deep: true });

      allClubs = allClubs.filter(c => c.forumsEnabled && c.totalUsers);
    }

    const combinedClubs = unionBy(coachClubs, allClubs, c => c.id);

    combinedClubs.forEach(club => {
      club.isLeaderboardJoined = club.joined;
      club.thumbLogoImageSource = thumbLogoUrlToSource(club.imageUrl);
      club.notificationLevel = club.notificationLevelId;
      //splice in recent message info
      // grabbing the combined feed is a hacky way to do this that should be semi-performant
      club.recentComments = club.comments
        ? club.comments.map(comment => ({
            ...comment,
            id: comment.id.toString(),
          }))
        : [];
      if (!club.comments) {
        club.recentCommentsNotAvailable = true;
      }
    });

    return combinedClubs;
  };

  getComments = async ({ clubId, page, sortMode = 'created_at' /* also: 'latest_reply_at' */ }) => {
    const params = { page, limit: 20 };
    if (this._enableSortModeFlag) {
      params['order[0][column]'] = sortMode;
      params['order[0][order]'] = 'desc';
      params['order[1][column]'] = 'reply_created_at';
      params['order[1][order]'] = 'asc';
    }
    const response = await this._apiV5.get(`${this._context}/me/clubs/${clubId}/comments`, {
      params,
    });
    const comments = camelcaseKeys(response.data.data, { deep: true });
    return comments.map(t => this._commentDtoToModel(t));
  };

  getComment = async ({ clubId, commentId }) => {
    const response = await this._apiV5.get(
      `${this._context}/me/clubs/${clubId}/comments/${commentId}`
    );
    return this._commentDtoToModel(camelcaseKeys(response.data, { deep: true }));
  };

  joinLeaderboard = groupId => {
    return this._apiV5.put(`/${this._context}/me/clubs/${groupId}/membership`, {
      leaderboard: true,
    });
  };

  leaveLeaderboard = groupId => {
    return this._apiV5.put(`/${this._context}/me/clubs/${groupId}/membership`, {
      leaderboard: false,
    });
  };

  changeNotificationLevel = (groupId, notificationLevelId) => {
    const endpoint = this._context === 'coaches' ? 'settings' : 'membership';
    return this._apiV5.put(`/${this._context}/me/clubs/${groupId}/${endpoint}`, {
      leaderboard: true,
      notification_level_id: notificationLevelId,
    });
  };

  getLeaderboard = ({ clubId, limit, archived }) => {
    return this._apiV5
      .get(`/clubs/${clubId}/leaderboard`, { params: { limit, archived } })
      .then(response => {
        const data = camelcaseKeys(response.data, { deep: true });
        const board = data.board;
        if (!board) {
          let errorMessage =
            'Leaderboard board property does not exist! Actual contents: ' + JSON.parse(data);
          throw new Error(errorMessage);
        }
        const leaders = data.leaders;
        leaders.forEach(leader => {
          leader.firstName = leader.firstname;
          leader.lastName = leader.lastname;
          // prod server is returning strings on decimals
          leader.total = Number.parseFloat(leader.total);
        });
        const position = data.position;
        if (position) {
          position.firstName = position.firstname;
          position.lastName = position.lastname;
          // prod server is returning strings on decimals
          position.total = Number.parseFloat(position.total);
        }

        return { ...board, leaders, position };
      });
  };

  /**
   * Post group topic or reply to topic.
   * If topicId is present, then it's a reply
   */
  postComment = async ({ clubId, comment, attachment, parentCommentId, notify }) => {
    const formData = new FormData();
    if (comment && comment.length > 0) {
      formData.append('comment', comment);
    }
    if (attachment) {
      const resizedImage = await ImagePicker.resizeImage(attachment);
      formData.append('attachment', resizedImage);
    }
    if (parentCommentId) {
      formData.append('parent_id', parentCommentId);
    }
    if (notify) {
      formData.append('notify', 1);
    }

    const response = await this._apiV5.post(
      `/${this._context}/me/clubs/${clubId}/comments`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Socket-ID': this._pusherListener.getSocketId(),
        },
      }
    );

    return this._commentDtoToModel(camelcaseKeys(response.data, { deep: true }));
  };

  updateComment = async ({ clubId, comment, attachment, parentCommentId, commentId }) => {
    const formData = new FormData();
    if (comment && comment.length > 0) {
      formData.append('comment', comment);
    }
    // not great, but an easy way to tell if the image was not replaced
    if (attachment && !(attachment.url && attachment.url.startsWith('http'))) {
      const resizedImage = await ImagePicker.resizeImage(attachment);
      formData.append('attachment', resizedImage);
    }
    if (parentCommentId) {
      formData.append('parent_id', parentCommentId);
    }

    formData.append('_method', 'PUT');

    const response = await this._apiV5.post(
      `/${this._context}/me/clubs/${clubId}/comments/${commentId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Socket-ID': this._pusherListener.getSocketId(),
        },
      }
    );

    return this._commentDtoToModel(camelcaseKeys(response.data, { deep: true }));
  };

  deleteComment = async ({ clubId, commentId }) => {
    if (this._context === 'users') {
      return this.redactComment({ clubId, commentId });
    } else {
      await this._apiV5.delete(`/${this._context}/me/clubs/${clubId}/comments/${commentId}`);
      return { deletionType: 'deleted' };
    }
  };

  redactComment = async ({ commentId, clubId }) => {
    await this._apiV5.patch(`/${this._context}/me/clubs/${clubId}/comments/${commentId}/redact`, {
      redacted: true,
    });
    return { deletionType: 'redacted' };
  };

  filterComment = commentId => {
    return this._apiV5.put(`/${this._context}/me/filtered/comment`, { club_comment_id: commentId });
  };

  filterPostsByUser = userId => {
    return this._apiV5.put(`/${this._context}/me/filtered/users/comments`, { users_id: userId });
  };

  reportAbuse = ({ commentId, reason }) => {
    return this._apiV5.post(`/report/club/comment/${commentId}`, { reason });
  };

  joinLeaderboard = groupId => {
    return this._apiV5.put(`/users/me/clubs/${groupId}/membership`, { leaderboard: true });
  };

  leaveLeaderboard = groupId => {
    return this._apiV5.put(`/users/me/clubs/${groupId}/membership`, { leaderboard: false });
  };

  _commentDtoToModel = dto => {
    return {
      ...dto,
      id: dto.id.toString(),
      serverId: dto.id,
      replies: dto.clubsComments ? dto.clubsComments.map(c => this._commentDtoToModel(c)) : [],
    };
  };
}
