import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet, Platform, Image, Keyboard, SafeAreaView } from 'react-native';
import { observer } from 'mobx-react';
import HeaderTitle from './HeaderTitle';
import HeaderIconButton from './HeaderIconButton';
import { sizes, colors, useMediaQueryInfo, withDeviceDimensions } from '../../config/styles';
import { BackIcon, UpdateIcon, KeyboardDismissIcon } from '../../config/icons';

const HeaderBackground = observer(function({ theme }) {
  const { innerWindowWidth } = useMediaQueryInfo();

  // used to take the entire background image, stretch it across the top, and cut anything past the header off
  // while maintaining aspect ratio
  const height = innerWindowWidth * 1.1;
  const headerBackgroundStyle = { width: innerWindowWidth, height };

  return (
    <View
      style={{
        position: 'absolute',
        flex: 1,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      }}>
      <Image
        style={[
          {
            position: 'absolute',
          },
          headerBackgroundStyle,
        ]}
        resizeMode="cover"
        source={theme.background}
      />
    </View>
  );
});

/**
 * Header that is fixed above the rest of the view.
 * Used for non-branded screens, usually not on the main navigation view.
 * hasBackButton and hasBenignConfirmButton override leftButton and rightButton.
 */
@observer
class Header extends Component {
  constructor() {
    super();
    this.state = {
      showDismissKeyboardButton: false,
    };
  }

  componentDidMount() {
    // keyboard logic only needed when there is one coach, so we can show the keyboard dismiss button
    // Tho maybe we should show it everywhere
    // Right now this triggers a re-render whenever the keyboard appears
    // even if the tab isn't active
    if (this.props.hasKeyboardDismissButton) {
      if (Platform.OS === 'ios') {
        this.keyboardDidShowListener = Keyboard.addListener(
          'keyboardWillShow',
          this._keyboardDidShow
        );
        this.keyboardDidHideListener = Keyboard.addListener(
          'keyboardWillHide',
          this._keyboardDidHide
        );
      } else {
        this.keyboardDidShowListener = Keyboard.addListener(
          'keyboardDidShow',
          this._keyboardDidShow
        );
        this.keyboardDidHideListener = Keyboard.addListener(
          'keyboardDidHide',
          this._keyboardDidHide
        );
      }
    }
  }

  componentWillUnmount() {
    if (this.props.hasKeyboardDismissButton) {
      this.keyboardDidShowListener.remove();
      this.keyboardDidHideListener.remove();
    }
  }

  _keyboardDidShow = () => {
    this.setState({
      showDismissKeyboardButton: true,
    });
  };

  _keyboardDidHide = () => {
    this.setState({
      showDismissKeyboardButton: false,
    });
  };

  _onPressDismissKeyboard = () => {
    Keyboard.dismiss();
  };

  render() {
    const {
      title,
      theme,
      style,
      navigation,
      hasBackButton,
      hasKeyboardDismissButton,
      hasBenignConfirmButton,
      leftButton,
      rightButton,
      bottomControl,
      disableBottomBorder,
      statusBarHeight,
    } = this.props;

    const { showDismissKeyboardButton } = this.state;

    const useHeaderBackground = theme.background && theme.useDarkTheme;

    return (
      <SafeAreaView
        style={[
          {
            backgroundColor: theme.backgroundColor ? theme.backgroundColor : colors.bg0,
            borderBottomColor: colors.border0,
            borderBottomWidth: disableBottomBorder ? 0 : 1,
          },
          style,
        ]}>
        {useHeaderBackground && <HeaderBackground theme={theme} />}
        <View
          style={{
            marginTop: Platform.OS === 'android' ? statusBarHeight : 0,
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <View
            style={{
              height: sizes.navbarHeight,
              justifyContent: 'center',
              maxWidth:
                '70%' /* kind of a kludge to keep titles from going outside the middle without repositioning everything */,
            }}>
            <HeaderTitle title={title} imageSource={theme.logo} />
          </View>
          <View
            style={[
              StyleSheet.absoluteFill,
              { right: null, alignItems: 'flex-start', justifyContent: 'center' },
            ]}>
            {hasBackButton ? (
              <HeaderIconButton icon={<BackIcon />} onPress={() => navigation.goBack()} />
            ) : leftButton ? (
              leftButton
            ) : null}
          </View>
          <View
            style={[
              StyleSheet.absoluteFill,
              { left: null, alignItems: 'flex-end', justifyContent: 'center' },
            ]}>
            {hasBenignConfirmButton ? (
              <HeaderIconButton icon={<UpdateIcon />} onPress={() => navigation.goBack()} />
            ) : hasKeyboardDismissButton && showDismissKeyboardButton ? (
              <HeaderIconButton
                icon={<KeyboardDismissIcon useDarkTheme={theme.useDarkTheme} />}
                onPress={this._onPressDismissKeyboard}
              />
            ) : rightButton ? (
              rightButton
            ) : null}
          </View>
        </View>
        {bottomControl && (
          <View style={{ height: sizes.navbarBottomControlHeight, justifyContent: 'center' }}>
            {bottomControl}
          </View>
        )}
      </SafeAreaView>
    );
  }
}

Header.propTypes = {
  hasBenignConfirmButton: PropTypes.bool,
  hasBackButton: PropTypes.bool,
  navigation: PropTypes.object,
  style: PropTypes.any,
  title: PropTypes.string,
  leftButton: PropTypes.element,
  rightButton: PropTypes.element,
  // children that appear under the header
  bottomControl: PropTypes.element,
  theme: PropTypes.object,
  // use to turn off bottom border to when controls are contiguous with navbar (like a search bar)
  disableBottomBorder: PropTypes.bool,
};

Header.defaultProps = {
  hasBenignConfirmButton: false,
  hasBackButton: false,
  navigation: null,
  style: null,
  leftButton: null,
  rightButton: null,
  bottomControl: null,
  theme: {},
  title: '',
  disableBottomBorder: false,
};

export default withDeviceDimensions(Header);
