import React from 'react';
import { Text, Image, View } from 'react-native';
import { Entypo, MaterialIcons, MaterialCommunityIcons } from '@expo/vector-icons';
import { colors } from '../styles';
import { BrandContext } from '../branding';

const darkColor = colors.darkText;
const toolbarIconSize = 22;
const lightColor = colors.lightText;

/**
 * Only used for tabs. Going from SDK 25 to 30, these icons would not show up in the tab
 * bar unless we gave them a width and a height. The Clients icon is widest of all and requireds
 * ~ 40px width.
 */
const createCustomFocusableIconComponent = (focusedText, notFocusedText) => ({
  focused,
  color,
}) => (
  <Text
    style={{
      fontFamily: 'custom-icons',
      fontSize: 22,
      color,
      width: 40,
      textAlign: 'center',
      alignSelf: 'center',
      marginTop: 3,
    }}>
    {focused ? focusedText : notFocusedText}
  </Text>
);

// custom font size requires explicit disable of scaling for Android
const createCustomIconComponent = (
  text,
  color = darkColor,
  defaultSize = 19,
  fontFamily = 'custom-icons'
) => {
  const myColor = color;
  return ({ style, size, color = myColor }) => (
    <Text
      allowFontScaling={false}
      style={[
        {
          fontFamily,
          fontSize: size || defaultSize,
          color,
        },
        style,
      ]}>
      {text}
    </Text>
  );
};

const createCustomIconComponentFromImage = (source, color = darkColor, defaultSize = 24) => {
  const myColor = color;
  return ({ style, size, color = myColor }) => (
    <Image
      source={source}
      style={{
        height: size || defaultSize,
        width: size || defaultSize,
        tintColor: color,
        ...style,
      }}
    />
  );
};

// new custom icons
const BellIcon = createCustomIconComponentFromImage(require('../../assets/icons/bell.png'));

// navbar
const CloseModalIcon = createCustomIconComponentFromImage(require('../../assets/icons/x.png'));
const TrashIcon = createCustomIconComponentFromImage(require('../../assets/icons/trash.png'));
const UpdateIcon = createCustomIconComponent('\ue812', colors.green, 28);
const BackIcon = createCustomIconComponentFromImage(require('../../assets/icons/back.png'));
const WhiteBackIcon = createCustomIconComponentFromImage(
  require('../../assets/icons/back-white.png'),
  'white'
);
const SettingsIcon = createCustomIconComponent('\ue804');
const NewMessageIcon = createCustomIconComponent('\ue814', darkColor, 22);

// chat
const ChatSendIcon = () => (
  <BrandContext.Consumer>
    {branding => {
      const CustomIconComponent = createCustomIconComponent(
        '\ue806',
        branding.highlightedColor,
        toolbarIconSize - 2
      );
      return <CustomIconComponent />;
    }}
  </BrandContext.Consumer>
);

const ChatCameraIcon = createCustomIconComponentFromImage(require('../../assets/icons/camera.png'));

const RightChevronIcon = createCustomIconComponentFromImage(
  require('../../assets/icons/chevron-right.png'),
  lightColor,
  22
);

const LeftChevronIcon = createCustomIconComponentFromImage(
  require('../../assets/icons/chevron-left.png'),
  lightColor,
  22
);

// card

const RepeatIcon = () => (
  <Image
    source={require('../../assets/icons/repeat.png')}
    tintColor={'black'}
    style={{
      height: 16,
      width: 16,
      tintColor: 'black',
    }}
  />
);

const PlusIcon = createCustomIconComponentFromImage(
  require('../../assets/icons/plus-white.png'),
  'gray'
);

const PdfIcon = createCustomIconComponent('\uf1c1', darkColor, 30, 'custom-icons-web');
const FileIcon = createCustomIconComponentFromImage(
  require('../../assets/icons/file-text.png'),
  lightColor,
  30
);

const VideoConferenceIcon = createCustomIconComponent('\ue823', lightColor, 30, 'custom-icons-web');

const ErrorIcon = props => <MaterialIcons name="error-outline" {...props} />;

const LeaderboardIcon = ({ useDarkTheme = false }) => (
  <Image
    source={require('../../assets/icons/leaderboard.png')}
    style={{
      height: 23,
      width: 15,
      tintColor: useDarkTheme ? 'white' : colors.darkText,
      opacity: useDarkTheme ? 0.5 : 1,
    }}
  />
);

const CancelIcon = createCustomIconComponentFromImage(
  require('../../assets/icons/x.png'),
  colors.darkText,
  22
);
const ConfirmIcon = createCustomIconComponent('\ue803');
const ConfirmUpdateIcon = createCustomIconComponent('\ue803', colors.green, toolbarIconSize);

const KeyboardDismissIcon = ({ useDarkTheme = false }) => (
  <BrandContext.Consumer>
    {branding => (
      <View
        style={{
          backgroundColor: useDarkTheme ? 'white' : branding.highlightedColor,
          borderRadius: 6,
          paddingHorizontal: 3,
        }}>
        <Image
          source={require('../../assets/icons/dismiss-keyboard.png')}
          style={{
            width: 32,
            height: 32,
            tintColor: useDarkTheme ? branding.highlightedColor : 'white',
          }}
        />
      </View>
    )}
  </BrandContext.Consumer>
);

// higher / lower icons
const UpCaretIcon = createCustomIconComponent('\ue801', darkColor, 14, 'custom-icons-web');
const DownCaretIcon = createCustomIconComponent('\ue800', darkColor, 14, 'custom-icons-web');

// tab icons
const TabIconComponent = ({ tintColor, color, source, style }) => (
  <Image
    source={source}
    style={[{ tintColor: tintColor || color /* react nav v5 */, height: 24, width: 32 }, style]}
  />
);

const coachIconSize = 24;

const ClientsTabIcon = props => (
  <TabIconComponent
    source={require('../../assets/icons/coach/icon-client.png')}
    style={{ height: coachIconSize, width: coachIconSize }}
    {...props}
  />
);

const MessagesTabIcon = props => (
  <TabIconComponent
    source={require('../../assets/icons/coach/icon-messages.png')}
    style={{ height: coachIconSize, width: coachIconSize }}
    {...props}
  />
);

const CoachAppSettingsTabIcon = props => (
  <TabIconComponent
    source={require('../../assets/icons/coach/icon-settings.png')}
    style={{ height: coachIconSize, width: coachIconSize }}
    {...props}
  />
);

const CoachAppSocialTabIcon = props => (
  <TabIconComponent
    source={require('../../assets/icons/coach/icon-social.png')}
    style={{ height: coachIconSize, width: coachIconSize }}
    {...props}
  />
);

const CoachTabIcon = props => (
  <TabIconComponent source={require('../../assets/icons/messages.png')} {...props} />
);

const ConnectTabIcon = props => (
  <TabIconComponent source={require('../../assets/icons/connect.png')} {...props} />
);

const ProgramTabIcon = props => (
  <TabIconComponent source={require('../../assets/icons/log.png')} {...props} />
);

const CommunityTabIcon = props => (
  <TabIconComponent source={require('../../assets/icons/community.png')} {...props} />
);

const ProfileTabIcon = props => (
  <TabIconComponent source={require('../../assets/icons/trends.png')} {...props} />
);

const AccountTabIcon = props => (
  <TabIconComponent source={require('../../assets/icons/account.png')} {...props} />
);

const ExpandComponentIcon = props => (
  <TabIconComponent source={require('../../assets/icons/expand.png')} {...props} />
);

const CustomTabIcon = props => <TabIconComponent {...props} />;

// coach-specific icons

const CameraToolbarIcon = createCustomIconComponent('\ue807', lightColor, toolbarIconSize);

const ClockIcon = createCustomIconComponent('\ue80b', darkColor, 18);

const CycleIcon = createCustomIconComponent('\ue80c', darkColor, 18);

const CalendarIcon = createCustomIconComponent('\ue81a', darkColor, 18);

const PauseIcon = createCustomIconComponent('\ue81b', lightColor, 18);

const GroupIcon = createCustomIconComponent('\ue808', darkColor, 18);

const CycleIconSmall = createCustomIconComponent('\ue80c', lightColor, 12);

const FilterIcon = createCustomIconComponent('\ue80e');
const HamburgerMenuIcon = createCustomIconComponent('\ue80f');
const SearchIcon = createCustomIconComponent('\ue811');

const AndroidEditIcon = ({ color }) => (
  <BrandContext.Consumer>
    {branding => {
      const CustomIconComponent = createCustomIconComponent(
        '\ue80d',
        color || branding.highlightedColor
      );
      return <CustomIconComponent />;
    }}
  </BrandContext.Consumer>
);
const AndroidSendIcon = () => (
  <BrandContext.Consumer>
    {branding => {
      const CustomIconComponent = createCustomIconComponent('\ue806', branding.highlightedColor);
      return <CustomIconComponent />;
    }}
  </BrandContext.Consumer>
);

const ChatCancelIcon = createCustomIconComponent('\ue810', lightColor, toolbarIconSize - 3);
const ChatConfirmEditIcon = props => (
  <BrandContext.Consumer>
    {branding => {
      const CustomIconComponent = createCustomIconComponent(
        '\ue803',
        branding.highlightedColor,
        toolbarIconSize
      );
      return <CustomIconComponent {...props} />;
    }}
  </BrandContext.Consumer>
);

const StartVideoConferenceIcon = createCustomIconComponent(
  '\ue824',
  darkColor,
  30,
  'custom-icons-web'
);

// extra chat icons
const ChatNotificationIconSolid = props => (
  <MaterialCommunityIcons name="bell" size={24} color={lightColor} {...props} />
);
const ChatNotificationIconOutline = props => (
  <MaterialCommunityIcons name="bell-outline" size={24} color={lightColor} {...props} />
);

export {
  // base nudge for clients icons
  CloseModalIcon,
  BackIcon,
  TrashIcon,
  WhiteBackIcon,
  UpdateIcon,
  ChatSendIcon,
  ChatCameraIcon,
  SettingsIcon,
  NewMessageIcon,
  KeyboardDismissIcon,
  LeaderboardIcon,
  CancelIcon,
  ConfirmIcon,
  ConfirmUpdateIcon,
  ErrorIcon,
  PdfIcon,
  FileIcon,
  UpCaretIcon,
  DownCaretIcon,
  VideoConferenceIcon,
  RightChevronIcon,
  LeftChevronIcon,
  PlusIcon,
  BellIcon,
  // tab bar icons
  CoachTabIcon,
  ConnectTabIcon,
  ProgramTabIcon,
  CommunityTabIcon,
  ProfileTabIcon,
  AccountTabIcon,
  ExpandComponentIcon,
  RepeatIcon,
  CustomTabIcon,
  // coach-specific icons
  HamburgerMenuIcon,
  MessagesTabIcon,
  CoachAppSettingsTabIcon,
  CoachAppSocialTabIcon,
  AndroidSendIcon,
  CameraToolbarIcon,
  ClockIcon,
  CycleIcon,
  CycleIconSmall,
  AndroidEditIcon,
  FilterIcon,
  SearchIcon,
  ClientsTabIcon,
  CalendarIcon,
  PauseIcon,
  GroupIcon,
  ChatCancelIcon,
  ChatConfirmEditIcon,
  StartVideoConferenceIcon, // slightly different, just for starting a video conference
  ChatNotificationIconSolid,
  ChatNotificationIconOutline,
};
