import React, { useContext, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  ScrollView,
  View,
  Text,
  Image,
  ActivityIndicator,
  Alert,
  Dimensions,
  Platform,
} from 'react-native';
import numeral from 'numeral';
import { observer } from 'mobx-react';
import { observe } from 'mobx';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { sizes, textStyles, colors } from '/common/config/styles';
import { BrandContext } from '/common/config/branding';
import { RoundButton } from '/common/components';
import { UserOptionsContext } from '/common/config/personalization';

const SmallSpacer = () => <View style={{ height: sizes.small }} />;

const MediumSpacer = () => <View style={{ height: sizes.medium }} />;

const LargeSpacer = () => <View style={{ height: sizes.large }} />;

const LastReadValue = observer(({ lastReadValue }) => {
  const { useMetricUnits } = useContext(UserOptionsContext);

  return (
    <>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Text style={[textStyles.standard.bold]}>Last Read Value</Text>
        {lastReadValue && (
          <Text style={textStyles.standard.light}>
            {DateTime.fromSQL(lastReadValue.timestamp).toLocaleString(DateTime.DATETIME_MED)}
          </Text>
        )}
      </View>
      <SmallSpacer />
      <Text style={textStyles.large.bold}>
        {lastReadValue ? (
          lastReadValue.weight ? (
            <Text>
              {useMetricUnits
                ? numeral(lastReadValue.weight, '0[.0]').format() /* metric is default */
                : numeral(lastReadValue.weight * 2.20462, '0[].0]').format()}
              <Text style={textStyles.standard.dark}>{useMetricUnits ? ' kg' : ' lbs'}</Text>
            </Text>
          ) : (
            <>
              <Text>
                <Text style={textStyles.standard.dark}>sys </Text>
                {lastReadValue.systolic}
              </Text>
              <Text>
                <Text style={textStyles.standard.dark}> dia </Text>
                {lastReadValue.diastolic}
              </Text>
            </>
          )
        ) : (
          <Text>-</Text>
        )}
      </Text>
    </>
  );
});

const SyncBluetoothPeripheral = observer(
  ({
    bluetoothPeripheral,
    onPressConnectPeripheral,
    onPressDisconnectPeripheral,
    lastReadValue,
  }) => {
    const { t } = useTranslation();
    const branding = useContext(BrandContext);
    const screenWidth = Dimensions.get('window').width;

    const onPressDisconnectPeripheralWrapped = useCallback(() => {
      Alert.alert(
        'Are you sure?',
        'This device will no longer sync. You can reconnect this device at any time.',
        [
          {
            text: 'Cancel',
            onPress: () => {},
            style: 'cancel',
          },
          {
            text: 'Disconnect',
            onPress: () => {
              onPressDisconnectPeripheral();
            },
            style: 'destructive',
          },
        ]
      );
    });

    // show confirmation after connecting
    useEffect(() => {
      const disposer = observe(bluetoothPeripheral, 'isPaired', ({ newValue, oldValue }) => {
        // isPaired goes from false to true
        if (newValue && !oldValue) {
          Alert.alert('Connected!', 'This device will automatically connect in the background.');
        }
      });
      return () => disposer();
    }, [bluetoothPeripheral]);

    const pairingContent = (
      <>
        <Text style={[textStyles.standard.bold]}>Connection Instructions</Text>
        <SmallSpacer />
        <Text style={[textStyles.standard.dark]}>{bluetoothPeripheral.pairingInstructions}</Text>
        <LargeSpacer />
        <RoundButton title="Connect" onPress={onPressConnectPeripheral} theme="lightOnDark" />
      </>
    );

    return (
      <ScrollView
        contentContainerStyle={{
          paddingHorizontal: sizes.medium,
          paddingTop: sizes.medium,
          backgroundColor: colors.bg0,
          flex: 1,
        }}>
        <Image
          source={{ uri: bluetoothPeripheral.imageUrl }}
          style={{ height: screenWidth * 0.6, width: screenWidth * 0.6, alignSelf: 'center' }}
        />
        <SmallSpacer />
        {bluetoothPeripheral.isPaired /* already paired, show disconnect, latest value */ ? (
          <>
            <Text style={[textStyles.standard.bold]}>Instructions</Text>
            <SmallSpacer />
            <Text style={[textStyles.standard.dark]}>{bluetoothPeripheral.instructions}</Text>
            <MediumSpacer />
            <View
              style={{
                height: sizes.hairlineWidth,
                backgroundColor: colors.bg2,
              }}
            />
            <MediumSpacer />
            <LastReadValue
              bluetoothPeripheral={bluetoothPeripheral}
              lastReadValue={lastReadValue}
            />
            <LargeSpacer />
            <RoundButton
              title="Disconnect Device"
              onPress={onPressDisconnectPeripheralWrapped}
              theme="darkOnLight"
              textStyle={{ color: colors.error }}
            />
          </>
        ) : /* failure: show error + pair instructions */ bluetoothPeripheral.didFailToPair ? (
          <>
            <Text style={[textStyles.standard.bold, { color: colors.error }]}>Pairing Failed!</Text>
            <SmallSpacer />
            <Text style={[textStyles.standard.dark]}>{bluetoothPeripheral.pairingError}</Text>
            <SmallSpacer />
            {Platform.OS === 'android' ? (
              <Text style={[textStyles.standard.light]}>
                Please check that bluetooth is turned on in your system settings.
              </Text>
            ) : null}
            <SmallSpacer />
            {pairingContent}
          </>
        ) : /* pairing in progress */ bluetoothPeripheral.isPairing ? (
          <View style={{ alignItems: 'center' }}>
            <Text style={[textStyles.standard.bold]}>Connecting...</Text>
            <LargeSpacer />
            <ActivityIndicator color={branding.highlightedColor} size="large" />
          </View>
        ) : (
          /* ready to pair, not paired yet */ <>{pairingContent}</>
        )}
      </ScrollView>
    );
  }
);

SyncBluetoothPeripheral.propTypes = {
  bluetoothPeripheral: PropTypes.object.isRequired,
  onPressConnectPeripheral: PropTypes.func.isRequired,
};

export default SyncBluetoothPeripheral;
