import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { sortBy } from 'lodash';
import { Chat, ChatMessage } from '/common/components/chat';
import { BrandContext } from '../../config/branding';

@observer
class Conversation extends Component {
  static contextType = BrandContext;

  render() {
    const {
      onCurrentMessageTextChanged,
      messages,
      canFetchNextPage,
      isFetchingNextPage,
      onRequestNextPage,
      isOperationPending,
      onPressSend,
      onPressChooseImage,
      onPressRemoveImage,
      onPressAttachment,
      onPressUrl,
      currentMessage,
      onLongPressMessage,
    } = this.props;

    const chatMessages = sortBy(messages, m => m.createdAt)
      .map(
        (m, index, array) =>
          new ChatMessage({
            messageId: m.id,
            createdAt: m.createdAt,
            text: m.message,
            userId: m.sender,
            attachmentSource: m.attachmentSource,
            meeting: m.meeting,
            originatingMessage: m,
            isPending: m.isPending,
            previousMessageCreatedAt: index === 0 ? null : array[index - 1].createdAt,
          })
      )
      .reverse();

    return (
      <Chat
        placeholderText="Send a message..."
        onCurrentMessageTextChanged={onCurrentMessageTextChanged}
        currentMessage={currentMessage}
        messages={chatMessages}
        currentUserId="patient"
        onLongPressMessage={onLongPressMessage}
        onPressChooseImage={onPressChooseImage}
        onPressRemoveImage={onPressRemoveImage}
        isLoadingEarlier={isFetchingNextPage}
        onLoadEarlier={onRequestNextPage}
        isLoadEarlierEnabled={canFetchNextPage}
        onPressSend={onPressSend}
        isOperationPending={isOperationPending}
        onPressAttachment={onPressAttachment}
        showEmptyListPlaceholder={!messages.length}
        onPressUrl={onPressUrl}
      />
    );
  }
}

Conversation.propTypes = {
  canFetchNextPage: PropTypes.bool.isRequired,
  isFetchingNextPage: PropTypes.bool.isRequired,
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
  onPressSend: PropTypes.func.isRequired,
  onRequestNextPage: PropTypes.func.isRequired,
  isOperationPending: PropTypes.bool.isRequired,
  onPressAttachment: PropTypes.func.isRequired,
  onLongPressMessage: PropTypes.func.isRequired,
  currentMessage: PropTypes.object.isRequired,
  onCurrentMessageTextChanged: PropTypes.func.isRequired,
  onPressUrl: PropTypes.func.isRequired,
  onPressChooseImage: PropTypes.func.isRequired,
  onPressRemoveImage: PropTypes.func.isRequired,
};

export default Conversation;
