import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { isIphoneX } from 'react-native-iphone-x-helper';
import { useScreenBehaviors } from '/common/navigation/';
import GroupScreenInner from './GroupScreenInner';
import { sizes } from '../../../config/common-styles';

const GroupScreen = inject('rootStore')(
  observer(({ navigation, route, rootStore }) => {
    const { clubId } = route.params;
    const socialFeed = rootStore.socialStore.socialFeedForClub(clubId);

    useScreenBehaviors({
      onDidMount: socialFeed.loadInitial,
      onFocus: socialFeed.loadNewComments,
      onActive: socialFeed.loadNewComments,
    });

    return (
      <GroupScreenInner
        socialFeed={socialFeed}
        navigation={navigation}
        hasBackButton
        additionalBottomOffset={isIphoneX() ? sizes.iphoneXBottomPadding : 0}
      />
    );
  })
);

GroupScreen.propTypes = {
  navigation: PropTypes.object,
};

export default GroupScreen;
