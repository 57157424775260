import React from 'react';
import { View, Platform } from 'react-native';
import { TransitionPresets, createStackNavigator } from '@react-navigation/stack';
import { AlertBarProvider, commonNavigationRoutes } from '/common/navigation';
import { navigationRoutes } from '../../config/constants';
import { rootStore } from '../../stores/instances';
import MainTabs from './main-tabs';
import GroupScreen from './group-screen';
import TopicScreen from './TopicScreen';
import ConversationScreen from './conversation-screen';
import AddLogScreen from './AddLogScreen';
import EditLogScreen from './EditLogScreen';
import LogEntriesScreen from './LogEntriesScreen';
import TrackerHistoryScreen from './TrackerHistoryScreen';
import ProgramCardDetailScreen from './ProgramCardDetailScreen';
import EditProfileScreen from './EditProfileScreen';
import ProgramCardsListScreen from './ProgramCardsListScreen';
import CardFromTagDetailScreen from './CardFromTagDetailScreen';
import {
  UpdateNameScreen,
  UpdateEmailScreen,
  UpdateGenderScreen,
  UpdateDateOfBirthScreen,
  UpdateHeightScreen,
  UpdateUnitsScreen,
  UpdateTrackingConsentScreen,
  UpdateClientUserIdScreen,
} from './update-settings-screens';
import { UserOptionsContext } from '/common/config/personalization';
import { containerStyles, useMediaQueryInfo } from '/common/config/styles';
import { NewMessageBar, AddCodeAlert } from '../overlays';
import {
  FullscreenImageScreen,
  PreviewImageModal,
  VideoPlayerScreen,
  LeaderboardScreen,
  AcceptDeclineScreen,
} from '/common/screens';
import BigScreenTabBar from './main-tabs/BigScreenTabBar';

const fadeInterpolator = ({ current: { progress } }) => {
  return { cardStyle: { opacity: progress } };
};

const Stack = createStackNavigator();


export default () => {
  const { isWideScreen, isWeb } = useMediaQueryInfo();
  return (
    <View style={containerStyles.fillAvailableSpace}>
      {isWideScreen && isWeb ? <BigScreenTabBar /> : null}
      <AlertBarProvider>
        <NewMessageBar />
        <AddCodeAlert />
        <UserOptionsContext.Provider
          value={{
            useMetricUnits: rootStore.settingsStore.user
              ? rootStore.settingsStore.user.units === 'metric'
              : false,
          }}>
          <Stack.Navigator
            initialRouteName={navigationRoutes.stacks.main.tabs}
            headerMode="none"
            screenOptions={
              Platform.OS === 'ios'
                ? TransitionPresets.SlideFromRightIOS
                : TransitionPresets.FadeFromBottomAndroid
            }
            mode="modal">
            <Stack.Screen name={navigationRoutes.stacks.main.tabs} component={MainTabs} />
            <Stack.Screen name={navigationRoutes.stacks.main.group} component={GroupScreen} />
            <Stack.Screen name={navigationRoutes.stacks.main.topic} component={TopicScreen} />
            <Stack.Screen
              name={navigationRoutes.stacks.main.conversation}
              component={ConversationScreen}
            />
            <Stack.Screen name={navigationRoutes.stacks.main.addLog} component={AddLogScreen} />
            <Stack.Screen name={navigationRoutes.stacks.main.editLog} component={EditLogScreen} />
            <Stack.Screen
              name={navigationRoutes.stacks.main.logEntries}
              component={LogEntriesScreen}
            />
            <Stack.Screen
              name={navigationRoutes.stacks.main.acceptDecline}
              component={AcceptDeclineScreen}
            />
            <Stack.Screen
              name={navigationRoutes.stacks.main.trackerHistory}
              component={TrackerHistoryScreen}
            />
            <Stack.Screen
              name={commonNavigationRoutes.clubs.leaderboard}
              component={LeaderboardScreen}
            />
            <Stack.Screen
              name={navigationRoutes.stacks.main.editProfile}
              component={EditProfileScreen}
            />
            <Stack.Screen
              name={navigationRoutes.stacks.main.updateName}
              component={UpdateNameScreen}
            />
            <Stack.Screen
              name={navigationRoutes.stacks.main.updateEmail}
              component={UpdateEmailScreen}
            />
            <Stack.Screen
              name={navigationRoutes.stacks.main.updateGender}
              component={UpdateGenderScreen}
            />
            <Stack.Screen
              name={navigationRoutes.stacks.main.updateDateOfBirth}
              component={UpdateDateOfBirthScreen}
            />
            <Stack.Screen
              name={navigationRoutes.stacks.main.updateHeight}
              component={UpdateHeightScreen}
            />
            <Stack.Screen
              name={navigationRoutes.stacks.main.updateUnits}
              component={UpdateUnitsScreen}
            />
            <Stack.Screen
              name={navigationRoutes.stacks.main.updateTrackingConsent}
              component={UpdateTrackingConsentScreen}
            />
            <Stack.Screen
              name={navigationRoutes.stacks.main.updateClientUserId}
              component={UpdateClientUserIdScreen}
            />
            <Stack.Screen
              name={navigationRoutes.stacks.main.programCardsList}
              component={ProgramCardsListScreen}
            />
            <Stack.Screen
              name={navigationRoutes.stacks.main.cardFromTagDetailScreen}
              component={CardFromTagDetailScreen}
            />
            <Stack.Screen
              name={navigationRoutes.stacks.main.programCardDetail}
              component={ProgramCardDetailScreen}
            />
            <Stack.Screen
              name={commonNavigationRoutes.previewImage}
              component={PreviewImageModal}
            />
            <Stack.Screen
              name={commonNavigationRoutes.showFullscreenVideo}
              component={VideoPlayerScreen}
            />
            <Stack.Screen
              name={commonNavigationRoutes.showFullscreenVideo_fade}
              component={VideoPlayerScreen}
              options={{
                cardStyleInterpolator: fadeInterpolator,
              }}
            />
            <Stack.Screen
              name={commonNavigationRoutes.showFullscreenImage}
              component={FullscreenImageScreen}
            />
            <Stack.Screen
              name={commonNavigationRoutes.showFullscreenImage_fade}
              component={FullscreenImageScreen}
              options={{
                cardStyleInterpolator: fadeInterpolator,
              }}
            />
          </Stack.Navigator>
        </UserOptionsContext.Provider>
      </AlertBarProvider>
    </View>
  );
};
