import React, { useContext } from 'react';
import { Text, View, ActivityIndicator, TouchableOpacity, Platform } from 'react-native';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { sizes, colors, textStyles } from '/common/config/styles';
import { BrandContext } from '../../config/branding';

const LoadEarlier = observer(({ isLoadEarlierEnabled, isLoadingEarlier, onLoadEarlier }) => {
  const branding = useContext(BrandContext);
  const { t } = useTranslation();

  if (!isLoadEarlierEnabled) {
    return null;
  }
  if (isLoadingEarlier) {
    return (
      <View style={{ padding: sizes.small }}>
        <ActivityIndicator color={branding.highlightedColor} size={'small'} />
      </View>
    );
  }

  return (
    <View
      style={{
        alignItems: 'center',
        // since flatlist is not inverted on android for performance reasons, this needs to be flipped..
        ...(Platform.OS === 'android' && { transform: [{ rotate: '180deg' }] }),
      }}>
      <TouchableOpacity onPress={onLoadEarlier} accessibilityTraits="button">
        <View
          style={{
            backgroundColor: colors.bg1,
            padding: sizes.small,
            borderRadius: sizes.borderRadius,
            marginTop: sizes.medium,
          }}>
          <Text style={textStyles.small.light}>{t('MESSAGING:LOAD_EARLIER_MESSAGES')}</Text>
        </View>
      </TouchableOpacity>
    </View>
  );
});

export default LoadEarlier;
