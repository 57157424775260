import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import ConversationScreenInner from './ConversationScreenInner';
import { useScreenBehaviors } from '/common/navigation/';

// Used when a conversation is a child screen and not directly on a tab
const ConversationScreen = inject('rootStore')(
  observer(function({ navigation, route, rootStore }) {
    const { coachId } = route.params;
    const conversation = rootStore.conversationsStore.initConversation(coachId);

    useScreenBehaviors({
      onFocus: conversation.loadInitial,
      onActive: conversation.loadInitial,
    });

    return (
      <ConversationScreenInner conversation={conversation} navigation={navigation} route={route} />
    );
  })
);

ConversationScreen.propTypes = {
  navigation: PropTypes.object,
};

export default ConversationScreen;
