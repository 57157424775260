import React from 'react';
import PropTypes from 'prop-types';
import { StatusBar } from 'expo-status-bar';
import { View, Platform } from 'react-native';
import { observer } from 'mobx-react';
import Header from './Header';
import { containerStyles, useMediaQueryInfo, colors, sizes } from '../../config/styles';

const NavbarWrapper = observer(({ theme, customHeader, children, style, ...rest }) => {
  const { isTabletOrDesktop, innerWindowWidth } = useMediaQueryInfo();

  let header = customHeader || (
    <Header
      theme={theme}
      customHeader={customHeader}
      children={children}
      style={[
        style,
        isTabletOrDesktop
          ? {
              borderTopLeftRadius: sizes.webBorderRadius,
              borderTopRightRadius: sizes.webBorderRadius,
            }
          : undefined,
      ]}
      {...rest}
    />
  );

  if (isTabletOrDesktop) {
    header = (
      <View style={{ backgroundColor: colors.bg1, paddingTop: sizes.webInnerViewMargin }}>
        <View
          style={{
            width: innerWindowWidth,
            alignSelf: 'center',
            borderTopLeftRadius: sizes.webBorderRadius,
            borderTopRightRadius: sizes.webBorderRadius,
          }}>
          {header}
        </View>
      </View>
    );
  }

  return (
    <View style={[containerStyles.fillAvailableSpace, { backgroundColor: colors.bg1 }]}>
      <View style={[containerStyles.fillAvailableSpace]}>
        {Platform.OS === 'ios' && (
          <StatusBar
            hidden={false}
            translucent
            style={
              'dark' /* we removed light theme because it only looks meh on one label, and messes up the programs tab, which should be opposite */
            }
          />
        )}
        {/* bar change on theme looks weird on Android */
        Platform.OS === 'android' && <StatusBar hidden={false} translucent style="dark" />}
        <View
          style={{
            zIndex:
              Platform.OS === 'ios'
                ? 10
                : undefined /* needed to show drop shadow on iOS, hides elevation on Android */,
          }}>
          {header}
        </View>
        {children}
      </View>
    </View>
  );
});

NavbarWrapper.propTypes = {
  children: PropTypes.any,
  hasBackButton: PropTypes.bool,
  hasBenignConfirmButton: PropTypes.bool,
  navigation: PropTypes.object,
  style: PropTypes.any,
  title: PropTypes.string,
  theme: PropTypes.object,
  customHeader: PropTypes.element,
};

NavbarWrapper.defaultProps = {
  children: null,
  hasBackButton: false,
  hasBenignConfirmButton: false,
  navigation: null,
  style: null,
  theme: {},
  customHeader: null,
  title: '',
};

export default NavbarWrapper;
