import React, { useCallback } from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import CardTextComponent from './CardTextComponent';
import CardButtonComponent from './CardButtonComponent';
import CardVideoComponent from './CardVideoComponent';
import CardHeadlineComponent from './CardHeadlineComponent';
import CardImageComponent from './CardImageComponent';
import CardJoinGroupButtonComponent from './CardJoinGroupButtonComponent';
import CardTrackerComponent from './CardTrackerComponent';
import CardPdfComponent from './CardPdfComponent';
import CardBulletComponent from './CardBulletComponent';
import CardQuoteComponent from './CardQuoteComponent';
import CardDividerComponent from './CardDividerComponent';

const CardComponent = observer(function({
  component,
  onPressImage,
  onPressLink,
  programEvent,
  onPressPdf,
  onPressJoinGroup,
  onPressVideo,
  onPressViewTracker,
  onPressAddLog,
  headlineMode,
  disableAddLog,
}) {
  const handleImagePress = useCallback(() => {
    onPressImage(component);
  }, [component, onPressImage]);

  const handleLinkPress = useCallback(() => {
    onPressLink(component.url, component);
  }, [component, onPressLink]);

  const handleInlineLinkPress = useCallback(
    url => {
      onPressLink(url, component);
    },
    [component, onPressLink]
  );

  const handleJoinGroupPress = useCallback(() => {
    onPressJoinGroup(component);
  }, [onPressJoinGroup, component]);

  const handleVideoPress = useCallback(() => {
    onPressVideo(component);
  }, [onPressVideo, component]);

  const handlePdfPress = useCallback(() => {
    onPressPdf(component);
  }, [onPressPdf, component]);

  let element;

  switch (component.type) {
    case 'headline':
      element =
        headlineMode === 'summary' ? (
          <CardTextComponent
            component={component}
            onPressLink={handleInlineLinkPress}
            headlineMode={headlineMode}
          />
        ) : (
          <CardHeadlineComponent component={component} onPressLink={handleInlineLinkPress} />
        );
      break;
    case 'bullet':
      element = <CardBulletComponent component={component} headlineMode={headlineMode} />;
      break;
    case 'quote':
      element = <CardQuoteComponent component={component} headlineMode={headlineMode} />;
      break;
    case 'divider':
      element = <CardDividerComponent component={component} />;
      break;
    case 'button':
      element = <CardButtonComponent component={component} onPressLink={handleLinkPress} />;
      break;
    case 'image':
      element = <CardImageComponent component={component} onPressImage={handleImagePress} />;
      break;
    case 'pdf':
      element = <CardPdfComponent component={component} onPressPdf={handlePdfPress} />;
      break;
    case 'tracker':
      element = (
        <CardTrackerComponent
          component={component}
          programEvent={programEvent}
          onPressViewTracker={onPressViewTracker}
          onPressAddLog={onPressAddLog}
          disableAddLog={disableAddLog}
        />
      );
      break;
    case 'join-group-button':
      element = (
        <CardJoinGroupButtonComponent
          component={component}
          onPressJoinGroup={handleJoinGroupPress}
        />
      );
      break;
    default:
      if (component.type.startsWith('video')) {
        element = <CardVideoComponent component={component} onPressVideo={handleVideoPress} />;
      } else {
        element = (
          <CardTextComponent
            component={component}
            onPressLink={handleInlineLinkPress}
            headlineMode={headlineMode}
          />
        );
      }
  }

  return <View key={component.id.toString()}>{element}</View>;
});

CardComponent.propTypes = {
  programEvent: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
  // Headlines change based on whether they're in a summary or detail card. Options:
  // summary = use normal text with modifications
  // normal = use typical headeline
  headlineMode: PropTypes.oneOf(['summary', 'normal']),
};

CardComponent.defaultProps = {
  headlineMode: 'normal',
};

export default CardComponent;