import { useContext } from 'react';
import { remove, findIndex } from 'lodash';
import { BrandContext } from '../../config/branding';
import { navigationRoutes } from '../../config/constants';

const fullSequence = [
  navigationRoutes.stacks.login.welcome,
  navigationRoutes.stacks.login.inviteId,
  navigationRoutes.stacks.login.clientUserId,
  navigationRoutes.stacks.login.enterNameEmailAndPassword,
];

// one place to figure out the next route during sign-up
// guess we could do this with onboarding,too, but we already have onboarding in the RootStore
// and it's working well and I just didn't want to do it (yet) for signup
function useNextSignupRoute(currentRoute) {
  const branding = useContext(BrandContext);

  // remove unused steps based on brand config
  if (!branding.signUp.showInviteIdStep) {
    remove(fullSequence, r => r === navigationRoutes.stacks.login.inviteId);
  }
  if (!branding.signUp.showClientUserIdStep) {
    remove(fullSequence, r => r === navigationRoutes.stacks.login.clientUserId);
  }

  // find next step based on current route index
  const currentIndex = findIndex(fullSequence, r => r === currentRoute.name);

  if (currentIndex + 1 === fullSequence.length) {
    return null;
  }

  return fullSequence[currentIndex + 1];
}

export default useNextSignupRoute;
