import axios from 'axios';
import { last, sortBy, padStart, cloneDeep } from 'lodash';
import { getLastXDaysFrom } from 'nudge-client-common/lib/dates';
import { DateTime } from 'luxon';
import env from '../../config/env';

const rawNudgeConfig = require('../../../current-config/nudge-config');

const getScreenshotConfig = brandSubDomain => {
  let screenshotConfig;
  if (
    rawNudgeConfig.multipleBrands &&
    rawNudgeConfig.multipleBrands.find(
      b => b.brandProps.subDomain === brandSubDomain && b.screenshotConfig
    )
  ) {
    screenshotConfig = rawNudgeConfig.multipleBrands.find(
      b => b.brandProps.subDomain === brandSubDomain
    ).screenshotConfig;
  } else {
    screenshotConfig = require('./mock-data/screenshots/default-screenshot-config.json');
  }

  return screenshotConfig;
};

const applyNewNudgeMocks = mock => {
  if (env.mocks && env.mocks.length) {
    // new program card + tracker enter screenshots
    if (env.mocks.find(m => m === 'screenshots')) {
      mock.onGet('/users/me/trackers').reply(config => {
        const baseUrl = config.baseURL;
        // get brand-specific screenshot data if available
        const brandSubDomain = baseUrl
          .replace('api.nudgeyourself.com/5', '')
          .replace('https://', '');
        const screenshotConfig = getScreenshotConfig(brandSubDomain);

        // get base mock data to interweave
        const mockDataBase = require('./mock-data/screenshots/trackers.json');

        const tracker = mockDataBase[0];
        tracker.name = screenshotConfig.tracker.name;
        tracker.palettes_id = screenshotConfig.tracker.color;
        tracker.meta.config.units = screenshotConfig.tracker.units;

        let primaryLogField = 'quantity';
        // optional: apply slug to force to standard tracker type
        if (screenshotConfig.tracker.standardTrackerSlug) {
          tracker.meta.slug = screenshotConfig.tracker.standardTrackerSlug;
          tracker.meta.logFormat = 'blah'; // cheese it so it doesn't pick up a custom tracker

          // looks like we're always using quantity even for standard trackers
          // I think I did this so we could get the look of a duration tracker without the nuance of filling in hiDuration
          if (screenshotConfig.tracker.standardTrackerSlug === 'weight') {
            primaryLogField = 'weight';
          }
        } else if (screenshotConfig.tracker.type === 'custom-question-multiple-choice') {
          tracker.meta.slug = 'custom-tracker'; // swap back or weird stuff will happen
          tracker.meta.logFormat = 'questions-log';
          tracker.meta.config = {
            options: screenshotConfig.tracker.options,
          };
          primaryLogField = 'response'; // TODO: we could support other fields like this
        } else if (screenshotConfig.tracker.type === 'custom-question-freeform') {
          tracker.meta.slug = 'custom-tracker'; // swap back or weird stuff will happen
          tracker.meta.logFormat = 'questions-log';
          primaryLogField = 'response'; // TODO: we could support other fields like this
        } else {
          tracker.meta.slug = 'custom-tracker'; // swap back or weird stuff will happen
          tracker.meta.logFormat = 'counters-log';
        }
        // TODO: type? might not need it
        const logPrototype = tracker.user.logs[0];
        const sampleLogs = [];
        // today logs
        screenshotConfig.tracker.today.forEach((todayValue, index) => {
          sampleLogs.push({
            ...logPrototype,
            id: 1000 + index,
            [primaryLogField]: todayValue.value,
            notes: todayValue.notes,
            user_time: todayValue.time
              ? `2021-01-30 ${todayValue.time}`
              : `2021-01-30 ${padStart((9 + 1 * index).toString(), 2, '0')}:30:00`,
          });
        });
        // other day logs
        const previousDays = getLastXDaysFrom({ date: '2021-01-29', x: 30 });
        previousDays.reverse().forEach((previousDay, index) => {
          sampleLogs.push({
            ...logPrototype,
            id: 1100 + index,
            [primaryLogField]:
              index < screenshotConfig.tracker.previous.length
                ? screenshotConfig.tracker.previous[index]
                : screenshotConfig.tracker.previous[
                    index % screenshotConfig.tracker.previous.length
                  ],
            user_time: previousDay + ' 09:00:00',
          });
        });

        tracker.user.logs = sampleLogs;

        return [200, mockDataBase];
      });
      mock.onGet('/users/me/feed/cards').reply(config => {
        const baseUrl = config.baseURL;
        // get brand-specific screenshot data if available
        const brandSubDomain = baseUrl
          .replace('api.nudgeyourself.com/5', '')
          .replace('https://', '');
        const screenshotConfig = getScreenshotConfig(brandSubDomain);

        // get base mock data to interweave
        const mockDataBase = cloneDeep(require('./mock-data/screenshots/programs-feed.json'));
        const myCards = [];
        const myDates = [];

        const todayCard = mockDataBase.cards[0];
        todayCard.title = screenshotConfig.cards.today.title;
        todayCard.components[0].body = screenshotConfig.cards.today.text;
        myCards.push(todayCard);
        myDates.push(mockDataBase.dates[0]);

        // if no yesterday card, just show one
        if (screenshotConfig.cards.yesterday) {
          const yesterdayCard = mockDataBase.cards[1];
          yesterdayCard.title = screenshotConfig.cards.yesterday.title;
          yesterdayCard.components[0].body = screenshotConfig.cards.yesterday.text;
          myCards.push(yesterdayCard);
          myDates.push(mockDataBase.dates[1]);
        }

        return [200, { ...mockDataBase, cards: myCards, dates: myDates }];
      });
      mock.onGet('/users/me/announcements').reply(() => {
        return [200, { data: [] }];
      });
      mock.onPost(/\/trackers\/\d+\/logs/).reply(() => {
        return [200, {}];
      });
      mock.onPut(/\/trackers\/\d+\/logs\/\S+/).reply(() => {
        return [200, {}];
      });
      mock.onGet(/\/trackers\/\d+\/logs/).reply(() => {
        return [200, []];
      });
      // not sure why this isn't working
      mock.onDelete(/\/trackers\/\d+\/logs\/\S+/).reply(() => {
        return [200, {}];
      });
    }
    // mock log data - legacy screenshots
    if (env.mocks.find(m => m === 'logs')) {
      mock.onGet('/users/me/trackers').reply(config => {
        let mockData;
        const baseUrl = config.baseURL;
        const brandSubDomain = baseUrl
          .replace('api.nudgeyourself.com/5', '')
          .replace('https://', '');
        if (
          rawNudgeConfig.multipleBrands &&
          rawNudgeConfig.multipleBrands.find(
            b => b.brandProps.subDomain === brandSubDomain && b.mockLogData
          )
        ) {
          mockData = rawNudgeConfig.multipleBrands.find(
            b => b.brandProps.subDomain === brandSubDomain
          ).mockLogData;
        } else {
          mockData = require('./mock-data/log/log-v5-screenshots-ipad.json');
        }
        const lastDate = last(sortBy(mockData[0].user.logs, l => l.user_time)).user_time;
        let lastDateTime = DateTime.fromSQL(lastDate);
        lastDateTime = lastDateTime.set({ hour: 23, minute: 59, second: 59 });
        console.log(lastDateTime);
        let todayDateTime = DateTime.fromJSDate(new Date());
        todayDateTime = todayDateTime.set({ hour: 23, minute: 59, second: 59 });
        console.log(todayDateTime);
        const diff = todayDateTime.diff(lastDateTime, 'days');
        console.log(diff);

        mockData.forEach(tracker => {
          tracker.user.logs.forEach(log => {
            log.user_time = DateTime.fromSQL(log.user_time)
              .plus(diff /*{ days: Math.ceil(diff.values.days) }*/) // not a whole number!
              .toSQL();
          });
        });
        return [200, mockData];
      });
      mock.onGet('/users/me/announcements').reply(() => {
        return [200, { data: [] }];
      });
      mock.onPost(/\/trackers\/\d+\/logs/).reply(() => {
        return [200, {}];
      });
      mock.onPut(/\/trackers\/\d+\/logs\/\S+/).reply(() => {
        return [200, {}];
      });
      // not sure why this isn't working
      mock.onDelete(/\/trackers\/\d+\/logs\/\S+/).reply(() => {
        return [200, {}];
      });
    }

    if (env.mocks.find(m => m === 'allTabs')) {
      mock
        .onGet(
          '/users/me/profile?components%5B%5D=theme&components%5B%5D=badges&components%5B%5D=forums&components%5B%5D=coached&components%5B%5D=tracking&components%5B%5D=integrations&components%5B%5D=card_feed'
        )
        .reply(() => {
          return [200, require('./mock-data/profile')]; // TODO: same object, but with all tabs enabled
        });
      mock.onGet('/users/me/profile?components%5B%5D=badges').reply(() => {
        return [200, require('./mock-data/profile')]; // TODO: same object, but with all tabs enabled
      });
    }

    if (env.mocks.find(m => m === 'signup')) {
      // mock sign up data (test signup without creating a ton of new accounts)
      mock.onPost('/users/registration').reply(async config => {
        const params = JSON.parse(config.data);
        try {
          const response = await axios.post(
            config.baseURL.replace('/5', '/3') + '/login',
            { email: params.email, password: params.password },
            { headers: config.headers }
          );
          return [200, response.data];
        } catch (error) {
          return [422, { message: '' }];
        }
      });
      // check email uniqueness - mocking lets us reuse accounts
      mock.onGet('/validate-unique-email').reply(() => {
        // treat every email as OK because we'll fake signing up
        return [200, {}];
      });
      // validate verification code
      mock.onPut('/users/me/email/verify').reply(async config => {
        const params = JSON.parse(config.data);
        let is_valid = false;
        if (params.code.toUpperCase().startsWith('YES')) {
          is_valid = true;
        }
        if (is_valid) {
          return [200, { is_valid }];
        } else {
          return [422, { message: 'blah' }];
        }
      });
      // verification code email resend
      mock.onGet('/users/me/email/verify/send').reply(() => {
        return [200, {}];
      });
      mock.onPut('/users/me', () => {
        return [200, {}];
      });
    }

    if (env.mocks.find(m => m === 'programCards')) {
      mock.onGet('/users/me/feed/cards').reply(() => {
        return [200, require('./mock-data/program-cards')];
      });
    }
  }

  mock.onAny().passThrough();
};

export default applyNewNudgeMocks;
