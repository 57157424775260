import { types, getEnv, flow } from 'mobx-state-tree';
import { uniqBy, sortBy } from 'lodash';
import { FeedCard, ProgramEvent, LoadingState } from 'nudge-client-common/stores';

const TagPage = types
  .model('TagPage', {
    id: types.identifier,
    programCards: types.optional(types.array(FeedCard), []),
    programEvents: types.optional(types.array(ProgramEvent), []),
    loadTagEventsState: types.optional(LoadingState, {}),
  })
  .views(self => ({
    get eventsForTagSorted() {
      const eventsFiltered = uniqBy(self.programEvents, p => p.programCard.stackId);
      return sortBy(eventsFiltered, p => p.programCard.tagRank);
    },
  }))
  .actions(self => {
    const loadTagEvents = flow(function* loadTagEvents() {
      const today = getEnv(self).getToday();
      self.loadTagEventsState.setPending();
      try {
        const data = yield getEnv(self).logRepository.getCardEventsForTag({
          tag: self.id,
          endDate: today,
        });
        self.programCards = data.cards;
        self.programEvents = data.events;
        self.loadTagEventsState.setDone();
      } catch (error) {
        self.loadTagEventsState.setFailed(error);
      }
    });

    return {
      loadTagEvents,
    };
  });

export default TagPage;
