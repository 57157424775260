import { types, flow, getEnv, getRoot } from 'mobx-state-tree';
import { sortBy, flatten, keys } from 'lodash';
import URI from 'urijs';
import { getFormErrors, getUserFieldFormErrors, getPasswordFormErrors } from '/common/lib/errors';
import User from './User';
import Coach from './Coach';
import ConnectedAppsSection from './ConnectedAppsSection';
import ConnectedApp from './ConnectedApp';
import BluetoothConnector from './BluetoothConnector';
import { LoadingState } from '../common';

const ConnectAction = types.model('ConnectAction', {
  app: types.reference(ConnectedApp),
  action: types.enumeration(['connect', 'disconnect']),
});

const SettingsStore = types
  .model({
    user: types.maybeNull(User),
    coaches: types.optional(types.array(Coach), []),
    connectedAppsSections: types.optional(types.array(ConnectedAppsSection), []),
    // async action states
    loadUserState: types.optional(LoadingState, {}),
    loadCoachesState: types.optional(LoadingState, {}),
    updateUserState: types.optional(LoadingState, {}), // going forward, let's use this state for most stuff- should be sufficient
    updateUserPhotoState: types.optional(LoadingState, {}),
    changePasswordState: types.optional(LoadingState, {}),
    loadConnectedAppsState: types.optional(LoadingState, {}),
    updateConnectedAppState: types.optional(LoadingState, {}),
    deleteAccountState: types.optional(LoadingState, {}),
    lastAppConnectAction: types.maybeNull(ConnectAction),
    // for white label apps that track location
    bluetoothPermissionsReadyStatusActionState: types.optional(LoadingState, {}),
    bluetoothPermissionsReadyStatus: types.frozen(),
    // invocations from outside the app (app URL's)
    addCodeState: types.optional(LoadingState, {}),
    removeCoachState: types.optional(LoadingState, {}),
    // for whitelabels with custom settings
    enabledFeatures: types.frozen(),
  })
  .views(self => ({
    get isKonamiCodeEnabled() {
      if (self.user && self.user.firstName === `⬆⬆⬇⬇⬅➡⬅➡🅱🅰`) {
        return true;
      }
      return false;
    },
    get connectedAppsSectionsListSorted() {
      return sortBy(self.connectedAppsSections.slice(), s => s.sortKey);
    },
    get connectedAppsSorted() {
      return sortBy(flatten(self.connectedAppsSections.map(s => s.appsList)), a => a.title);
    },
    get areAnyAppsEnabled() {
      return self.connectedAppsSorted.find(a => a.isEnabled);
    },
    // I didn't unit test it because the structure of the apps is so weird and I'd rather just rework it all
    // and then test it
    isAppEnabled(appType) {
      return !!self.connectedAppsSorted.find(a => a.type === appType && a.isEnabled);
    },
    get coachesSorted() {
      return sortBy(self.coaches.slice(), c => c.lastName);
    },
    // quick access of coach count even if all coaches aren't loaded (from enabled data)
    get coachCount() {
      if (self.user) {
        return self.user.enabledData.coachCount;
      }
      return 0;
    },
    // quick access of group count even if all coaches aren't loaded (from enabled data)
    get groupCount() {
      if (self.user) {
        return self.user.enabledData.groupCount;
      }
      return 0;
    },
    get useMetricUnits() {
      if (self.user) {
        return self.user.units === 'metric';
      }
      return false;
    },
  }))
  .actions(self => {
    // --- user ---

    // Attempt to load a cached user quickly
    // Used on startup to quickly get the app going
    // Return false if no cached user is available so we can block on loading the user
    // Return true if loaded cached user successfully so then we know not to block on loading the user
    const loadCachedUser = flow(function* loadCachedUser() {
      try {
        const env = getEnv(self);
        const cachedUser = yield env.userRepository.getCachedUser();
        if (!cachedUser) {
          return false;
        }
        self.user = cachedUser;
        return true;
      } catch (error) {
        return false;
      }
    });
    const loadUser = flow(function* loadUser() {
      self.loadUserState.setPending();
      try {
        const env = getEnv(self);
        self.user = yield env.userRepository.getUser();
        self.loadUserState.setDone();
      } catch (error) {
        self.loadUserState.setFailed(error);
      }
    });

    const loadCoaches = flow(function* loadUser() {
      self.loadCoachesState.setPending();
      try {
        const env = getEnv(self);
        self.coaches = yield env.coachesRepository.getCoaches();
        self.loadCoachesState.setDone();
      } catch (error) {
        self.loadCoachesState.setFailed(error);
      }
    });

    const updateUserFields = flow(function* updateUserFields(fields) {
      self.updateUserState.setPending();
      try {
        const updatedFields = Object.assign({}, fields);
        if (updatedFields.email) {
          updatedFields.email = updatedFields.email.trim();
        }
        const preflightErrors = getUserFieldFormErrors(updatedFields);
        if (preflightErrors.length) {
          self.updateUserState.setFailed(new Error(), preflightErrors);
          return;
        }
        const updatedUser = yield getEnv(self).userRepository.updateUserFields(updatedFields);
        // a bit of a cludge, but we need to clear out the log/ trends so it can reprocess everything
        // as metric
        if (keys(fields).find(k => k === 'units')) {
          // we want to clear everything- reset all data, but without clearing out the user object,
          // because a lot of things depend on that (like the log queries, which check units)
          // This order ensures everything else is cleared but the user still exists.
          // We can stay on the settings screen until init is finished, then.
          self.user.overwriteUpdatedFields(updatedUser);
          // question - if we restore the user correctly, do we need the init?
          getRoot(self).clearCriticalData();
          getRoot(self).reloadCriticalData();
        } else {
          // also update user for non-units changes
          self.user.overwriteUpdatedFields(updatedUser);
        }
        // workaround for the fact that the updated user blows away the old components, including enabled data
        self.loadUser();
        self.updateUserState.setDone();
      } catch (error) {
        self.updateUserState.setFailed(error, getFormErrors(error));
      }
    });

    const updateUserPhoto = flow(function* updateUserPhoto({ image }) {
      self.updateUserPhotoState.setPending();
      try {
        const result = yield getEnv(self).userRepository.updateUserPhoto(image);
        self.user.photoSource = result.photoSource;
        self.updateUserPhotoState.setDone();
      } catch (error) {
        self.updateUserPhotoState.setFailed(error);
      }
    });

    const declineCoachInvite = flow(function* declineCoachInvite(params) {
      try {
        self.user.subtractPending(params.id);
        const result = yield getEnv(self).userRepository.declineInvite(params.url);
        return result;
      } catch (error) {
        console.log(error);
      }
    });

    const acceptCoachInvite = flow(function* acceptCoachInvite(params) {
      try {
        self.user.subtractPending(params.id);
        const result = yield getEnv(self).userRepository.acceptInvite(params.url);
        getRoot(self).clearCriticalData();
        getRoot(self).reloadCriticalData(); // reinitialize everything since views will change
        self.loadUser();
        self.loadCoaches();
        return result;
      } catch (error) {
        console.log(error);
      }
    });

    const changePassword = flow(function* changePassword({
      currentPassword,
      newPassword,
      confirmNewPassword,
    }) {
      const preflightErrors = getPasswordFormErrors({
        currentPassword,
        newPassword,
        confirmNewPassword,
      });
      if (preflightErrors.length) {
        self.changePasswordState.setFailed(new Error(), preflightErrors);
        return;
      }

      self.changePasswordState.setPending();
      try {
        yield getEnv(self).userRepository.changePassword({
          currentPassword,
          newPassword,
        });
        self.changePasswordState.setDone();
      } catch (error) {
        self.changePasswordState.setFailed(error, getFormErrors(error));
      }
    });

    // --- connected apps ---

    const loadConnectedApps = flow(function* loadConnectedApps() {
      self.loadConnectedAppsState.setPending();
      try {
        self.connectedAppsSections.replace(
          yield getEnv(self).syncRepository.getConnectedAppsSections()
        );
        self.loadConnectedAppsState.setDone();
      } catch (error) {
        self.loadConnectedAppsState.setFailed(error);
      }
    });

    const registerBuiltInApp = flow(function* registerBuiltInApp({ app, callbackUrlParams }) {
      self.updateConnectedAppState.setPending();
      try {
        yield getEnv(self).syncRepository.enableSync({ app, callbackUrlParams });
        // force refresh
        yield self.loadConnectedApps();
        // forces refresh of synced services to request on-demand syncing
        yield self.loadUser();
        self.lastAppConnectAction = {
          app: self.connectedAppsSorted.find(a => a.type === app.type),
          action: 'connect',
        };
        self.updateConnectedAppState.setDone();
      } catch (error) {
        self.updateConnectedAppState.setFailed(error);
      }
    });

    const registerOauthApp = flow(function* registerOauthApp(url) {
      self.updateConnectedAppState.setPending();
      try {
        // make sure connected apps are up-to-date
        yield self.loadConnectedApps();
        // get app from URL and URL parts
        let appType;
        // token exchange;
        let appTypeMatchResults = url.match(/sync\/call(.*)APITokenExchange/);
        if (appTypeMatchResults && appTypeMatchResults.length > 1) {
          appType = appTypeMatchResults[1];
        } else {
          // matches on a double-slash at the beginning because it will be right after the protocol, e.g.,
          // nudge://AppAuthenticationComplete
          appTypeMatchResults = url.match(/\/\/(.*)AuthenticationComplete/);
          if (appTypeMatchResults && appTypeMatchResults.length > 1) {
            appType = appTypeMatchResults[1];
          }
        }
        //console.log(`connecting to app: ${appType}`);
        const app = self.connectedAppsSorted.find(
          a =>
            a.type.toUpperCase() === appType.toUpperCase() ||
            (appType.toUpperCase() === 'GOOGLE' && a.type === 'sleepAsAndroid')
          // hopefully this doesn't also apply to Google Fit...I think it doesn't
        );
        const callbackUrl = new URI(url);
        const callbackUrlParams = callbackUrl.search(true);
        if (app.requiresTokenExchange) {
          yield getEnv(self).syncRepository.enableSync({
            app,
            oauthResponseProps: callbackUrlParams,
          });
        }
        // if no token exchange, app is already registered and we just need to refresh data
        // force refresh
        yield self.loadConnectedApps();
        // forces refresh of synced services to request on-demand syncing
        yield self.loadUser();
        self.lastAppConnectAction = { app, action: 'connect' };
        self.updateConnectedAppState.setDone();
      } catch (error) {
        self.updateConnectedAppState.setFailed(error);
      }
    });

    const unregisterConnectedApp = flow(function* unregisterConnectedApp(app) {
      self.updateConnectedAppState.setPending();
      try {
        yield getEnv(self).syncRepository.disableSync(app);
        // clear this out to force a full reload
        yield self.loadConnectedApps();
        // forces refresh of synced services to request on-demand syncing
        yield self.loadUser();
        self.lastAppConnectAction = {
          app: self.connectedAppsSorted.find(a => a.type === app.type),
          action: 'disconnect',
        };
        self.updateConnectedAppState.setDone();
      } catch (error) {
        self.updateConnectedAppState.setFailed(error);
      }
    });

    // -- bluetooth --

    /*const pairBluetoothPeripheral = flow(function* pairBluetoothPeripheral(peripheral) {
      console.log('ATTEMPTING TO PAIR');
      try {
        peripheral.setPairingStatus(peripheral.pairingStatuses.pairing);
        yield getEnv(self).syncRepository.pairBluetoothPeripheral(peripheral);
      } catch (error) {
        console.log(error);
      }
    });

    const readBluetoothPeripheral = flow(function* readBluetoothPeripheral(peripheral) {
      console.log('ATTEMPTING TO READ', peripheral, peripheral.readingStatuses);
      try {
        yield getEnv(self).syncRepository.readBluetoothPeripheral(peripheral);
      } catch (error) {
        console.log(error);
      }
    });

    const unsetPairedBluetoothPeripherals = flow(function* unsetPairedBluetoothPeripherals() {
      try {
        yield getEnv(self).syncRepository.unsetPairedBluetoothPeripherals();
        getRoot(self).bluetoothPeripherals.forEach(p =>
          p.setPairingStatus(p.pairingStatuses.unpaired)
        );
        yield self.loadConnectedApps();
      } catch (error) {
        console.log(error);
      }
    });*/

    // -- delete account

    const deleteAccount = flow(function* deleteAccount({ deleteInput, comparator }) {
      if (deleteInput !== comparator) {
        return {
          isError: true,
          reason: 'textDoesNotMatch',
        };
      }

      self.deleteAccountState.setPending();
      try {
        yield getEnv(self).userRepository.deleteAccount();
        self.deleteAccountState.setDone();
        return {
          isError: false,
        };
      } catch (error) {
        self.deleteAccountState.setFailed(error);
        return {
          isError: true,
        };
      }
    });

    // --- add/ remove coach, patient id, group, or whatever ---

    const addCode = flow(function* addCode({ code, source, type = 'inviteId' }) {
      self.addCodeState.setPending();
      try {
        let result;
        if (type === 'inviteId') {
          result = yield getEnv(self).userRepository.submitPromoCode(code, source);
        } else {
          throw new Error('Invalid code/ id type!');
        }

        getRoot(self).clearCriticalData();
        getRoot(self).reloadCriticalData(); // reinitialize everything since views will change
        self.loadUser();
        self.loadCoaches();
        self.addCodeState.setDone();
        return result;
      } catch (error) {
        self.addCodeState.setFailed(error, getFormErrors(error));
      }
    });

    const removeCoach = flow(function* removeCoach(coachId) {
      self.removeCoachState.setPending();
      try {
        yield getEnv(self).userRepository.revokeCoachAccess(coachId);
        // quick remove to update UI
        self.coaches = self.coaches.filter(coach => coach.id !== coachId);
        self.loadUser();
        self.loadCoaches();
        getRoot(self).clearCriticalData();
        getRoot(self).reloadCriticalData(); // reinitialize everything since views will change
        self.removeCoachState.setDone();
      } catch (error) {
        self.removeCoachState.setFailed(error);
      }
    });

    const joinGroup = flow(function* joinGroup(groupId) {
      self.updateUserState.setPending();
      try {
        yield getEnv(self).userRepository.joinGroup(groupId);
        getRoot(self).clearCriticalData();
        getRoot(self).reloadCriticalData(); // reinitialize everything since views will change
        self.updateUserState.setDone();
      } catch (error) {
        self.updateUserState.setFailed(error);
      }
    });

    // -- white label customizations --

    const getBluetoothPermissionsReadyStatus = flow(function* getBluetoothPermissionsReadyStatus() {
      self.bluetoothPermissionsReadyStatusActionState.setPending();
      try {
        const status = yield getEnv(self).userRepository.getBluetoothPermissionsReadyStatus();
        self.bluetoothPermissionsReadyStatusActionState.setDone();
        self.bluetoothPermissionsReadyStatus = status;
      } catch (error) {
        self.bluetoothPermissionsReadyStatusActionState.setFailed(error);
      }
    });

    const updateBluetoothPermissionsConsent = flow(function* updateBluetoothPermissionsConsent() {
      self.bluetoothPermissionsReadyStatusActionState.setPending();
      try {
        self.bluetoothPermissionsReadyStatus = yield getEnv(
          self
        ).userRepository.updateBluetoothPermissionsConsent();
        // NOTE: this doesn't return async thanks to Radar
        self.bluetoothPermissionsReadyStatusActionState.setDone();
      } catch (error) {
        console.log(error);
        self.bluetoothPermissionsReadyStatusActionState.setFailed(error);
      } finally {
        // requery because there seems to be issue with request failing even though status is updated
        self.getBluetoothPermissionsReadyStatus();
      }
    });

    const updateTrackingConsent = flow(function* updateTrackingConsent({ background }) {
      self.bluetoothPermissionsReadyStatusActionState.setPending();
      try {
        yield getEnv(self).userRepository.updateTrackingConsent({ background });
        // NOTE: this doesn't return async thanks to Radar
        self.bluetoothPermissionsReadyStatusActionState.setDone();
      } catch (error) {
        console.log(error);
        self.bluetoothPermissionsReadyStatusActionState.setFailed(error);
      } finally {
        // requery because there seems to be issue with request failing even though status is updated
        self.getBluetoothPermissionsReadyStatus();
      }
    });

    return {
      loadCachedUser,
      loadUser,
      loadCoaches,
      loadConnectedApps,
      registerBuiltInApp,
      registerOauthApp,
      updateUserFields,
      updateUserPhoto,
      changePassword,
      unregisterConnectedApp,
      deleteAccount,
      addCode,
      removeCoach,
      getBluetoothPermissionsReadyStatus,
      updateBluetoothPermissionsConsent,
      updateTrackingConsent,
      joinGroup,
      declineCoachInvite,
      acceptCoachInvite,
    };
  });

export default types.compose('SettingsStore', SettingsStore, BluetoothConnector);
