import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { RowTextInput } from '/common/components';
import UpdateProfileItemList from './UpdateProfileItemList';

/**
 * We return the state with all the props anytime something changes.
 * What is changing doesn't map perfectly to what is in the object (first name, last name vs name),
 * So it's easier this way. I guess. I hate forms...
 */
@observer
class UpdateHeight extends Component {
  _onChangeHeightFeet = newFeet => {
    let feet = parseInt(newFeet, 10);
    if (feet > 9) {
      feet = 9;
    }
    if (isNaN(feet) || newFeet.trim() === '') {
      feet = 0;
    }
    const { height, onChangeHeight } = this.props;
    const originalInches = height % 12;
    const newInches = feet * 12 + originalInches;
    onChangeHeight(newInches);
  };

  _onChangeHeightInches = newInches => {
    let inches = parseInt(newInches, 10);
    if (inches > 11) {
      inches = 11;
    }
    if (isNaN(inches) || newInches.trim() === '') {
      inches = 0;
    }
    const { height, onChangeHeight } = this.props;
    const originalFeet = Math.floor(height / 12);
    const newTotalInches = originalFeet * 12 + inches;
    onChangeHeight(newTotalInches);
  };

  _onChangeHeightCentimeters = newCentimeters => {
    const { onChangeHeight } = this.props;
    let centimeters = newCentimeters;
    if (centimeters > 300) {
      centimeters = 300;
    }
    if (isNaN(centimeters) || newCentimeters.trim() === '') {
      centimeters = 0;
    }
    const tryParseCentimeters = parseInt(centimeters, 10);
    if (!isNaN(tryParseCentimeters)) {
      // always store in inches
      onChangeHeight(tryParseCentimeters * 0.393701);
    }
  };

  render() {
    const { units, height, t } = this.props;

    let heightFeet;
    let heightInches;
    let heightCm;

    if (!isNaN(height)) {
      heightFeet = Math.floor(height / 12);
      heightInches = Math.floor(height % 12);
      heightCm = Math.floor(height * 2.54);
    }

    const sections = [];

    if (units === 'imperial') {
      sections.push({
        title: '',
        data: [
          () => (
            <RowTextInput
              value={heightFeet ? heightFeet.toString() : ''}
              label={'Feet'}
              onChangeText={this._onChangeHeightFeet}
              textInputProps={{ autoFocus: true, keyboardType: 'numeric' }}
            />
          ),
          () => (
            <RowTextInput
              value={heightInches ? heightInches.toString() : ''}
              label={'Inches'}
              onChangeText={this._onChangeHeightInches}
              textInputProps={{ keyboardType: 'numeric' }}
            />
          ),
        ],
      });
    } else {
      sections.push({
        title: '',
        data: [
          () => (
            <RowTextInput
              value={heightCm ? heightCm.toString() : ''}
              label={'Centimeters'}
              onChangeText={this._onChangeHeightCentimeters}
              textInputProps={{ autoFocus: true, keyboardType: 'numeric' }}
            />
          ),
        ],
      });
    }

    return <UpdateProfileItemList sections={sections} />;
  }
}

UpdateHeight.propTypes = {
  height: PropTypes.number,
  units: PropTypes.string.isRequired,
  onChangeHeight: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

UpdateHeight.defaultProps = {
  height: null,
};

export default withTranslation()(UpdateHeight);
