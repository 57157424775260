import { NativeModules } from 'react-native';

const { ValidicBluetooth } = NativeModules;

const SupportedBluetoothPeripheralIDs = [21, 22];

export default class MockBluetoothSyncRepository {
  _bluetoothListener;
  // pass in the bluetooth listener so we can trigger mock events on it
  constructor({ bluetoothListener }) {
    this._bluetoothListener = bluetoothListener;
  }
  // built-in connected apps

  startSession = async () => {
    if (ValidicBluetooth) {
      const result = await ValidicBluetooth.isSessionActive();
      if (!result.isActive) {
        const validicDetails = await this.getValidicDetails();
        await ValidicBluetooth.startSession(
          validicDetails.externalUsersId,
          validicDetails.serviceClientId,
          validicDetails.accessToken
        );
      }
    }

    if (!ValidicBluetooth) {
      console.warn(
        'WARNING: Not using react-native-validic bluetooth! This is OK if you are running in the Expo client. Very bad if not!'
      );
    }
  };

  async endSession() {
    if (ValidicBluetooth) {
      await ValidicBluetooth.endSession();
    }
  }

  async startBluetoothPassiveReading() {
    if (ValidicBluetooth) {
      console.log(
        'STARTING PASSIVE BLUETOOTH READING FOR PERIPHERAL IDS',
        SupportedBluetoothPeripheralIDs
      );
      try {
        await ValidicBluetooth.startPassiveReading(SupportedBluetoothPeripheralIDs);
      } catch (e) {
        console.log(e);
      }
    }
  }

  async stopBluetoothPassiveReading() {
    if (ValidicBluetooth) {
      ValidicBluetooth.stopPassiveReading();
    }
  }

  async resumeBluetoothServices() {
    
  }

  async setPeripheralIsPaired(peripheralID) {}

  async unpairBluetoothPeripheral(peripheralId) {}

  async getAvailableBluetoothPeripherals() {
    return [
      {
        peripheralId: 21,
        name: 'Welch Allyn RPM-Scale100',
        pairingStatus: 'unpaired',
        pairingInstructions:
          "Press the button on the under side of your scale to turn it on, then press and hold it until you see the Bluetooth icon blinking on the scale's display.",
        instructions: 'Stand on your scale. Once your weight is captured you can step off.',
        requiresPairing: true,
        imageUrl: 'https://assets.validic.com/mobile/peripheral/ble-24.png',
      },
      {
        peripheralId: 22,
        name: 'Welch Allyn RPM-BP100/H-BP100SBP',
        pairingStatus: 'unpaired',
        pairingInstructions:
          'Press and hold the power button on the device until you see the Bluetooth icon blinking on the display.',
        instructions:
          'Press the power button. Your blood pressure measurement will begin shortly. Please be still and quiet.',
        requiresPairing: true,
        imageUrl: 'https://assets.validic.com/mobile/peripheral/ble-25.png',
      },
      /*{
        peripheralId: 22,
        name: 'A device that will definitely fail to pair',
        pairingStatus: 'unpaired',
        pairingInstructions: 'Do some stuff blah blah',
        instructions: 'Press the button then do the hokey pokey.',
        requiresPairing: true,
      },*/
    ];
  }

  pairBluetoothPeripheral = async peripheral => {
    // simple happy path - pair in 3 seconds, read a value in 7
    /*if (peripheral.peripheralId === 22) {
      setTimeout(() => {
        this._bluetoothListener.onPeripheralDidNotPair({
          peripheralId: peripheral.peripheralId,
          error: 'It is just never gonna work',
        });
      }, 3000);
      return;
    }*/

    setTimeout(() => {
      this._bluetoothListener.onPeripheralDidPair({ peripheralId: peripheral.peripheralId });
    }, 3000);

    setTimeout(() => {
      if (peripheral.peripheralId === 21 /* scale */) {
        this._bluetoothListener.onPeripheralPassiveDidRead({
          peripheralId: peripheral.peripheralId,
          data: [
            {
              timestamp: '2021-08-16 13:52:01',
              weight: 182,
            },
          ],
        });
      } else if (peripheral.peripheralId === 22 /* bp monitor */) {
        this._bluetoothListener.onPeripheralPassiveDidRead({
          peripheralId: peripheral.peripheralId,
          data: [{ timestamp: '2021-08-16 13:52:01', systolic: 129, diastolic: 77 }],
        });
      }
    }, 7000);
  };

  readBluetoothPeripheral = async peripheral => {
    console.log('READING', peripheral);
    try {
      await ValidicBluetooth.read(peripheral.peripheralID);
    } catch (e) {
      //Reading failed to *initialize* for some reason
      //(this is different from the readingFailed event)
      console.log(e);
    }
  };
}
