import { DateTime } from 'luxon';
import { toDateTime, getToday } from 'nudge-client-common/lib/dates';
import { unitsForValueAndUom } from 'nudge-client-common/lib/strings';

const capitalizeFirstLetter = text => {
  if (text && text.length) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  return text;
};

/**
 * Given an object with firstName and lastName props, determines the printed full name.
 */
const fullName = ({ firstName, lastName }) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  if (firstName) {
    return firstName;
  }
  if (lastName) {
    return lastName;
  }
  return '';
};
const ordinal = number => {
  const j = number % 10,
    k = number % 100;
  if (j == 1 && k != 11) {
    return 'st';
  }
  if (j == 2 && k != 12) {
    return 'nd';
  }
  if (j == 3 && k != 13) {
    return 'rd';
  }
  return 'th';
};

// date functions
const shortDateWithoutYear = date => {
  const dateTime = toDateTime(date);
  return dateTime.toFormat('MMM d');
};

const shortDateWithShortYear = date => {
  const dateTime = toDateTime(date);
  return dateTime.toFormat(`MMM d, yy`);
};

const descriptiveDateWithoutYear = (date, options) => {
  const defaultOptions = { showDateForYesterday: false };
  const myOptions = { ...defaultOptions, ...options };

  const dateTime = toDateTime(date);
  if (myOptions.dontShowRelativeDate) {
    return dateTime.toFormat('MMM d');
  }
  const today = toDateTime(getToday());
  if (today.hasSame(dateTime, 'day')) {
    return options && options.t ? options.t('GENERAL:DATES:TODAY') : 'Today';
  }

  const yesterday = today.minus({
    days: 1,
  });
  if (!myOptions.showDateForYesterday && yesterday.hasSame(dateTime, 'day')) {
    return options && options.t ? options.t('GENERAL:DATES:YESTERDAY') : 'Yesterday';
  }

  return dateTime.toFormat('MMM d');
};

const formatDateTimeToTime = dateTime => {
  return dateTime.toFormat('h:mm a').toLowerCase();
};

const time = date => {
  return formatDateTimeToTime(toDateTime(date));
};

const timeForTodayOrDateForYesterday = date => {
  const dateInQuestion = toDateTime(date);
  const today = DateTime.local();
  if (today.hasSame(dateInQuestion, 'day')) {
    return formatDateTimeToTime(dateInQuestion);
  }
  return dateInQuestion.toLocaleString(DateTime.DATE_SHORT);
};

const fullDateTimeWithZone = date => {
  // TODO: make exact format we want for leaderboard
  // https://moment.github.io/luxon/docs/manual/formatting.html
  const dateTime = toDateTime(date);
  return dateTime.toLocaleString(DateTime.DATETIME_FULL);
};

export {
  ordinal,
  fullName,
  unitsForValueAndUom,
  shortDateWithoutYear,
  shortDateWithShortYear,
  descriptiveDateWithoutYear,
  time,
  capitalizeFirstLetter,
  timeForTodayOrDateForYesterday,
  fullDateTimeWithZone,
};
