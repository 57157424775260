import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { NavbarWrapper } from '../../../../components/navigation';
import { ClubsList } from '/common/components/social';
import { navigationRoutes } from '../../../../config/constants';
import { BrandContext } from '../../../../config/branding';
import { getTheme } from '../../../../lib/theming';

@observer
class MultipleGroupsView extends Component {
  static contextType = BrandContext;

  _onPressClub = club => {
    const { rootStore, navigation } = this.props;

    navigation.navigate(navigationRoutes.stacks.main.group, {
      clubId: club.id,
    });
  };

  render() {
    const { rootStore, t } = this.props;
    const { socialStore } = rootStore;
    const branding = this.context;

    const theme = getTheme({ rootStore, branding });

    return (
      <NavbarWrapper title={t('TABS:GROUP_FEED')} theme={theme}>
        <ClubsList
          clubs={socialStore.clubsSorted}
          onPressClub={this._onPressClub}
          perspective="client"
        />
      </NavbarWrapper>
    );
  }
}

MultipleGroupsView.propTypes = {
  rootStore: PropTypes.object,
  navigation: PropTypes.object,
};

export default withTranslation()(MultipleGroupsView);
