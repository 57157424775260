export default {
  GENERAL: {
    SUCCESS: 'Succès',
    SAVE: 'Enregistrer',
    EDIT: 'Modifier',
    CANCEL: 'Annuler',
    OK: 'OK',
    REFRESH: 'Actualiser',
    TRY_AGAIN: 'Réessayer',
    LOAD_MORE: 'Charger plus',
    ACCEPT: 'Accepter',
    DECLINE: 'Refuser',
    PHOTO_PICKER: {
      CHOOSE_FROM_LIBRARY: 'Choisir depuis la bibliothèque',
      TAKE_PICTURE_WITH_CAMERA: "Prendre une photo avec l'appareil photo",
    },
    ERRORS: {
      APP_FIRST_LOAD_TITLE: 'Désolé, nous avons des problèmes pour démarrer...',
      FIRST_LOAD_TITLE: 'Désolé, nous rencontrons des problèmes...',
      FIRST_LOAD_MESSAGE: 'Nous ne parvenons pas à nous connecter pour le moment.',
      PLEASE_REFRESH_ACTION: 'Actualisez pour réessayer.',
      LOAD_EARLIER_MESSAGES_FAILED:
        'Échec du chargement des messages précédents. Veuillez vérifier votre connexion et réessayer.',
      NETWORK_ERROR_MESSAGE: 'Veuillez vérifier votre connexion réseau',
      SERVER_UNAVAILABLE_ERROR_MESSAGE:
        'Nous nous excusons pour la gêne occasionnée - nous effectuons actuellement une maintenance. Nous serons bientôt de retour !',
      ALERT_UNEXPECTED_ERROR_MESSAGE: 'Veuillez réessayer plus tard.',
      ALERT_UNEXPECTED_ERROR_TITLE: 'Désolé, nous avons des problèmes avec cela...',
      ALERT_ACCESS_REMOVED_ERROR_MESSAGE:
        "Vous n'avez plus accès à cette ressource. Vous avez peut-être été retiré d'un groupe.",
      CANNOT_OPEN_FILE: "Erreur lors de l'ouverture du fichier",
    },
    DATES: {
      TODAY: "Aujourd'hui",
      YESTERDAY: 'Hier',
    },
  },
  SIGN_UP: {
    INVITE_ID_DISPLAY_NAME: "ID d'invitation",
    INVITE_ID_DISPLAY_SHORTNAME: 'ID',
    INVITE_ID_DISPLAY_ARTICLE: 'un',
    CLIPBOARD_DISCLAIMER:
      'Cette application collecte des données du presse-papiers pour permettre le collage de votre {{inviteIdName}}',
    PLEASE_ENTER_INVITE_ID: 'Veuillez entrer votre {{inviteIdName}}',
    PLEASE_ENTER_CLIENT_USER_ID: 'Veuillez entrer votre {{clientUserIdName}} si vous en avez un.',
    ENTER_YOUR_EMAIL_ADDRESS: 'Entrez votre adresse e-mail',
    ID_LIKE_TO: "J'aimerais",
    GREAT: 'Génial !',
    SECURE_YOUR_ACCOUNT_WITH_A_PASSWORD:
      'Nous avons confirmé votre compte. Sécurisez-le maintenant avec votre mot de passe.',
    COMPLETE_YOUR_PROFILE: 'Complétez votre profil',
    THIS_WILL_HELP_US_VERIFY_YOUR_RESULTS: 'Cela nous aidera à vérifier vos résultats',
    CREATE_AN_ACCOUNT: 'Créer un compte',
    CREATE_YOUR_ACCOUNT: 'Créez votre compte.',
    ENTER_A_NEW_EMAIL_ADDRESS: 'Entrez une nouvelle adresse e-mail.',
    TERMS_OF_SERVICE: {
      DESCRIPTION:
        'En vous inscrivant à {{appName}}, vous devez avoir au moins 13 ans et accepter les',
      LINK_NAME: "Conditions d'utilisation",
    },
    CONFIRMATION_CODE: 'Code de confirmation',
    WE_JUST_SENT_AN_EMAIL_TO: "Nous venons d'envoyer un e-mail à",
    WE_PREVIOUSLY_SENT_AN_EMAIL_TO: 'Nous avons déjà envoyé un e-mail à',
    CONFIRM_YOUR_ACCOUNT: 'Vérifiez votre e-mail.',
    FINISH_SETTING_UP_YOUR_ACCOUNT: 'Terminez la configuration de votre compte',
    USE_THIS_LINK_TO_CONFIRM_YOUR_EMAIL_ADDRESS:
      'Utilisez ce lien pour vérifier votre adresse e-mail.',
    ERRORS: {
      INVALID_INVITE_ID: 'Désolé, nous ne trouvons pas ce {{inviteIdName}}',
      INVALID_INVITE_ID_MEMBERSHIP_LIMIT_EXCEEDED:
        'Désolé, ce coach ne peut plus ajouter de clients.',
      NETWORK_ERROR: 'Nous ne parvenons pas à nous connecter ! Veuillez vérifier votre connexion.',
      UNKNOWN_ERROR: 'Désolé, nous avons des problèmes de connexion pour le moment.',
      EMAIL_ALREADY_IN_USE: 'Cet e-mail est déjà utilisé',
      EMAIL_BLANK: 'Veuillez saisir une adresse e-mail',
      EMAIL_INVALID: 'Adresse e-mail non valide',
      NAME_BLANK: 'Veuillez saisir un nom',
      FIRST_NAME_BLANK: 'Veuillez saisir un prénom',
      LAST_NAME_BLANK: 'Veuillez saisir un nom de famille',
      PASSWORD_NOT_MINIMUM_LENGTH: 'Le mot de passe doit comporter 6 caractères ou plus',
      PASSWORD_INVALID: 'Mot de passe invalide (30 caractères max)',
      BIRTH_DATE_BLANK: 'Doit fournir une date de naissance',
      INVALID_EMAIL_CONFIRMATION_CODE:
        "Désolé, ce n'est pas un code de confirmation d'e-mail valide.",
    },
    BUTTONS: {
      CONTINUE: 'Continuer',
      NO_CONTINUE_WITH_SIGN_UP: "Non, continuer avec l'inscription.",
      CONINUE_WITH_SELF_GUIDED_PROGRAM: 'Continuer avec un programme autonome',
      SUBMIT: 'Soumettre',
      ACCEPT: 'Accepter',
      DECLINE: 'Refuser',
    },
    REQUEST_EMAIL_CODE_DIALOG: {
      TITLE: "C'est fait !",
      MESSAGE:
        'Si vous avez du mal à trouver votre e-mail de confirmation, vérifiez votre dossier de courrier indésirable/spam.',
    },
  },
  ONBOARDING: {
    ERRORS: {
      PUSH_NOTIFICATION_REGISTRATION: "Erreur d'enregistrement des notifications push.",
      VIEW_TUTORIAL: 'Désolé, nous ne pouvons pas ouvrir le tutoriel pour le moment',
    },
    CONTINUE: 'Continuer',
  },
  LOGIN: {
    BUTTONS: {
      IM_NEW: 'Je suis nouveau',
      SIGN_IN: 'Se connecter',
      NEXT: 'Suivant',
      FORGOT_PASSWORD: 'Mot de passe oublié?',
      REQUEST_PASSWORD_RESET: 'Réinitialiser le mot de passe',
      LOGIN_AGAIN: 'Retour à la connexion',
    },
    PASSWORD_RESET_CONFIRMATION:
      'Un lien pour réinitialiser votre mot de passe a été envoyé à votre adresse e-mail.',
    PASSWORD_RESET_TROUBLESHOOTING_TIPS_TITLE: 'Conseils de dépannage',
    PASSWORD_RESET_TROUBLESHOOTING_TIPS_DETAIL1:
      '• Les e-mails mettent parfois plusieurs minutes à apparaître',
    PASSWORD_RESET_TROUBLESHOOTING_TIPS_DETAIL2:
      '• Vérifiez votre dossier spam après quelques minutes',
    PASSWORD_RESET_TROUBLESHOOTING_TIPS_DETAIL3:
      '• Assurez-vous de vérifier la bonne boîte de réception e-mail (eh oui, ça arrive 🤷)',
    HEADERS: {
      CREATE_AN_ACCOUNT: 'Créer un compte',
      SIGN_IN: 'Se connecter',
    },
    FIELDS: {
      NAME: 'Nom',
      EMAIL: 'E-mail',
      PASSWORD: 'Mot de passe',
    },
    ERRORS: {
      GENERIC_TITLE: 'Désolé, nous avons des problèmes pour nous connecter',
      INCORRECT_USERNAME_PASSWORD: "L'e-mail ou le mot de passe ne correspond à aucun utilisateur.",
      SSO: {
        RESPONSE_MALFORMED:
          "La réponse du fournisseur d'authentification unique est mal formée. Il peut y avoir un problème avec le serveur d'authentification unique. Veuillez contacter le support produit.",
        NO_AUTHORIZATION_CODE:
          "La réponse du fournisseur d'authentification unique n'incluait pas de code d'autorisation. S'il y a eu une erreur lors du processus de connexion, veuillez réessayer ou contacter le support produit.",
        UNRECOGNIZED_URL:
          "La réponse du fournisseur d'authentification unique incluait une URL que nous ne reconnaissons pas. Veuillez contacter le support produit.",
        UNEXPECTED:
          "Une erreur inattendue s'est produite lors de la connexion. Veuillez réessayer plus tard ou contacter le support produit.",
        VALIDIC_USER_NOT_PROVISIONED:
          'Il semble que vous ne soyez pas enregistré pour {{appName}}.',
      },
    },
  },
  BUTTONS: {
    CANCEL: 'Annuler',
  },
  MESSAGING: {
    ERRORS: {
      SEND_MESSAGE: "Erreur d'envoi de message.",
      DELETE_MESSAGE: 'Erreur de suppression de message.',
      LOADING_PREVIOUS_MESSAGES: 'Erreur de chargement des messages précédents.',
    },
    BUTTONS: {
      COPY_TEXT: 'Copier le texte',
      DELETE_MESSAGE: 'Supprimer le message',
    },
    NO_MESSAGES_MESSAGE: {
      LINE1: "C'est calme ici !",
      LINE2: 'Pourquoi ne pas commencer une conversation ?',
    },
    LOAD_EARLIER_MESSAGES: 'Charger les messages précédents',
  },
  PROFILE: {
    TITLE: 'Profil',
    FIELDS: {
      FULL_NAME: 'Nom complet',
      NAME: 'Nom',
      FIRST_NAME: 'Prénom',
      LAST_NAME: 'Nom de famille',
      EMAIL: 'E-mail',
      GENDER: 'Genre',
      DATE_OF_BIRTH: 'Date de naissance',
      UNITS: 'Unités',
      HEIGHT: 'Hauteur',
      PHOTO: 'Photo',
    },
    BUTTONS: {
      CHANGE_PASSWORD: 'Changer le mot de passe',
      SET_PROFILE_PHOTO: 'Définir la photo de profil',
      ENTER_A_CODE: "Entrer un ID d'invitation",
      HELP: 'Aide',
      MALE: 'Homme',
      FEMALE: 'Femme',
      NOT_SPECIFIED_OTHER: 'Non spécifié',
      IMPERIAL: 'Impérial',
      METRIC: 'Métrique',
      LOG_OUT: 'Déconnexion',
      SYNCED_APPS_NOT_SYNCING: 'Connecter des applications et des trackers portables',
      SYNCED_APPS_SYNCING: 'Applications connectées',
      EDIT: 'Modifier',
      SHARE: 'Partager',
      NO_BIRTHDATE_SET: 'Sélectionner...',
      DELETE_ACCOUNT: 'Supprimer le compte',
      COACH_SETTINGS: 'Paramètres du coach',
      SUBMIT: 'Soumettre',
    },
    LABELS: {
      COMPLETE_YOUR_PROFILE: {
        TITLE: 'COMPLÉTER VOTRE PROFIL',
        DESCRIPTION:
          'Essayez de consigner certaines de vos activités préférées pour un profil reflétant votre style de vie actif.',
      },
      ACTIVITIES: {
        TITLE: 'ACTIVITÉS',
        LATEST: 'DERNIER',
        FAVORITE: 'PRÉFÉRÉ',
      },
    },
    ERRORS: {
      UPDATE_FAILED: 'Échec de la mise à jour',
      EMAIL_UPDATE_FAILED_MESSAGE: "Assurez-vous d'utiliser une adresse e-mail valide",
      CANNOT_BE_BLANK: 'Ne peut pas être vide',
      EMAIL_ALREADY_USED: 'E-mail déjà utilisé',
      INVALID_EMAIL: 'E-mail non valide',
      TRACKING_CONSENT_UPDATE_FAILED_MESSAGE:
        "Votre préférence de consentement de suivi n'a pas été mise à jour. Veuillez réessayer",
    },
    EDIT: {
      UNSAVED_CHANGES: 'Modifications non enregistrées',
      WHAT_WOULD_YOU_LIKE_TO_DO: 'Que souhaitez-vous faire?',
      SAVE_CHANGES: 'Enregistrer les modifications',
      DISCARD_CHANGES: 'Ignorer les modifications',
    },
  },
  SYNCED_APPS: {
    TITLE: 'Applications synchronisées',
    DESCRIPTION: 'Appuyez et synchronisez votre (vos) application(s) préférée(s)',
    CONFIRMATION:
      "{{appName}} synchronise désormais avec votre compte {{syncedAppName}}. Notez que cela peut prendre jusqu'à 24 heures pour que les données synchronisées apparaissent sur l'écran de journal de {{appName}}.",
    STOP_APP_CONFIRM_TITLE: "Arrêter la synchronisation de l'application ?",
    STOP_APP_CONFIRM_MESSAGE:
      'Êtes-vous sûr de vouloir arrêter la synchronisation des données de {{syncedAppName}} vers {{appName}} ?',
    NO_KEEP_SYNCING: 'Non, continuer la synchronisation',
    YES_STOP_SYNCING: 'Oui, arrêter la synchronisation de {{syncedAppName}}',
    CHECK_APPLE_HEALTH_SOURCES:
      "Consultez l'onglet Sources dans l'application Santé pour activer plus de types de données pour la synchronisation.",
  },
  PROGRAMS: {
    EMPTY_FEED: {
      TITLE: 'Les prochaines étapes de votre\nprogramme se chargeront ici.',
      MESSAGE: 'Revenez bientôt',
    },
    EMPTY_TAG: {
      TITLE: 'Aucune carte ici pour le moment.',
      MESSAGE: 'Cette balise a peut-être été supprimée.',
    },
    ERRORS: {
      REFRESH_FAILED: 'Désolé, impossible de rafraîchir pour le moment...',
    },
    TRACKER_PLACEHOLDERS: {
      NUMBER: 'Entrez un nombre...',
      DURATION: 'Enregistrez votre temps...',
      PERCENT: 'Entrez votre %...',
      BLOODPRESSURE: 'Enregistrez votre tension artérielle...',
      FREEFORM: 'Écrivez votre réponse...',
      MULTIPLECHOICE: 'Choisissez une option...',
      HEARTRATE: 'Consultez votre fréquence cardiaque...',
    },
    SEGMENTED_CONTROL: {
      FEED: 'Flux',
      TAGS: 'Balises',
    },
    LOAD_MORE: 'Charger plus',
  },
  LOG: {
    BUTTONS: {
      OPTIONS: 'Options',
      DETAILS: 'Détails',
      SELECTED: 'Choisi',
    },
    EMPTY_TRACKER_LIST: {
      TITLE: 'Pas encore de trackers',
      MESSAGE:
        'Si vous avez synchronisé des données depuis des appareils, vos données apparaîtront bientôt ici.',
    },
    TRACKERS: {
      CARDIO: 'Activités',
      WATER: 'Eau',
      PEDOMETER: 'Pas',
      WEIGHT: 'Poids',
      'BLOOD-PRESSURE': 'Tension artérielle',
      'BLOOD-GLUCOSE': 'Glucose sanguin',
      CALORIES: 'Calories',
      'BODY-FAT': 'Masse grasse',
      SLEEP: 'Sommeil',
    },
    ENTRY_ERRORS: {
      MUST_SELECT_ONE: 'Doit en sélectionner un',
      MUST_BE_NUMBER_GREATER_THAN_ZERO: 'Doit être un nombre > 0',
      MUST_BE_INTEGER_GREATER_THAN_ZERO: 'Doit être un nombre entier > 0',
      MUST_BE_NUMBER_GREATER_THAN_OR_EQUAL_TO_ZERO: 'Doit être un nombre >= 0',
      MUST_BE_VALID_MINUTES: 'Doit être compris entre 0 minute et 24 heures',
      MUST_BE_VALID_PERCENT: 'Doit être compris entre 0 et 100',
      CANNOT_BE_BLANK: 'Ne peut pas être vide',
      TIME_MUST_BE_LESS_THAN_24_HOURS: 'Le temps doit être compris entre 1 minute et 24 heures',
      TIMES_CANNOT_OVERLAP: 'Le temps ne peut pas se chevaucher avec un autre journal',
    },
    ERRORS: {
      APP_FAILED_TO_SYNC: `Nous avons des problèmes pour synchroniser vos données {{syncedAppName}}. Veuillez réessayer plus tard.`,
      APP_FAILED_TO_SYNC_GENERAL: `Nous avons des problèmes pour synchroniser vos données. Veuillez réessayer plus tard.`,
      SYNC_UPDATES_ERROR_TITLE: `Nous avons des problèmes pour synchroniser vos dernières mises à jour en ce moment.`,
      SYNC_UPDATES_UNKNOWN_ERROR_MESSAGE: `Veuillez réessayer.`,
      SYNC_UPDATES_NEED_TO_UPDATE_LOG: `Votre programme a été mis à jour. Actualisez pour obtenir la dernière version.`,
      REFRESH_FAILED: 'Désolé, impossible de rafraîchir pour le moment...',
      SAVE_FAILED: "Désolé, impossible d'enregistrer pour le moment",
    },
    FIELDS: {
      NOTES: 'Commentaires',
      SYSTOLIC: 'Systolique',
      DIASTOLIC: 'Diastolique',
      TIME: 'Temps',
      CARBOHYDRATES: 'Glucides',
      SODIUM: 'Sodium',
      CALORIES: 'Calories',
      TOTAL_FAT: 'Graisses totales',
      DIETARY_FIBER: 'Fibres alimentaires',
      PROTEIN: 'Protéines',
      START_TIME: 'Heure de début',
      END_TIME: 'Heure de fin',
      DURATION: 'Durée',
      BLOOD_GLUCOSE: 'Glucose sanguin',
      ACTIVITY: 'Activité',
      DISTANCE: 'Distance',
      SOURCE: 'Source',
      WRITE_SOMETHING: 'Écrivez quelque chose...',
      SELECT_ONE: 'Sélectionnez une option...',
      HEART_RATE: 'Fréquence cardiaque',
      AVERAGE_HEART_RATE: 'Fréquence cardiaque moyenne',
      MINIMUM_HEART_RATE: 'Fréquence cardiaque minimale',
      MAXIMUM_HEART_RATE: 'Fréquence cardiaque maximale',
    },
    FIELD_ABBREVIATIONS: {
      SYSTOLIC: 'sys',
      DIASTOLIC: 'dia',
      MINHEARTRATE: 'min',
      MAXHEARTRATE: 'max',
      AVGHEARTRATE: 'moy',
      HEARTRATE: 'bpm',
    },
    ENTRY: {
      DELETE: 'Supprimer',
      CANCEL: 'Annuler',
      DELETE_LOG: 'Supprimer le journal',
      ARE_YOU_SURE: 'Êtes-vous sûr de vouloir supprimer ce journal ?',
      UNSAVED_CHANGES: 'Vous avez des modifications non enregistrées',
      KEEP_EDITING: "Continuer l'édition",
      DISCARD_CHANGES: 'Ignorer les modifications',
    },
  },
  TRENDS: {
    DETAILS: {
      DAILY_AVERAGE: 'Moyenne quotidienne',
      DAILY_TOTAL: 'Total quotidien',
      DAILY: 'Quotidien',
      MONTH: 'Mois',
      WEEK: 'Semaine',
      '90DAY': '90 jours',
      YEAR: 'Année',
    },
    SUMMARY: {
      NO_RECENT_DATA: 'PAS DE DONNÉES RÉCENTES',
      LAST_30_DAYS: '30 DERNIERS JOURS',
      LAST_7_DAYS: '7 DERNIERS JOURS',
    },
    ERRORS: {
      LOADING_OF_GRAPH_FAILED:
        'Échec du chargement du graphique. Veuillez rafraîchir pour réessayer',
    },
  },
  COMMUNITY: {
    TITLE: 'Communauté',
    LEADERBOARD: {
      TITLE: 'Classement',
      JOIN_LEADERBOARD: 'Rejoindre le classement',
      JOIN: 'Rejoindre',
      LEAVE: 'Quitter',
      LEAVE_CONFIRMATION_DIALOG_TITLE: 'Quitter le classement de groupe ?',
      LEAVE_CONFIRMATION_DIALOG_MESSAGE: 'Vous pouvez rejoindre ce classement à tout moment',
      JOIN_CONFIRMATION_DIALOG_TITLE: 'Rejoindre le classement de groupe ?',
      JOIN_CONFIRMATION_DIALOG_MESSAGE:
        'Les personnes de ce groupe pourront voir vos {{leaderboardType}}',
      LEADERBOARD_RESETS_AT: 'Le classement est réinitialisé le {{date}}',
      RESULTS_RECORDED_ON: 'Résultats enregistrés le {{date}}',
      WEEKLY_TOTAL: 'Total hebdomadaire',
      MONTHLY_TOTAL: 'Total mensuel',
      NO_LEADERS: 'Soyez le premier à rejoindre le classement !',
      CHECK_SOON_FOR_LEADERS: 'Revenez bientôt pour les mises à jour du classement !',
      PERSON_LEFT_GROUP: 'Cette personne a quitté le groupe.',
      ACTIVE: ' Actif ',
      PREVIOUS: 'Précédent',
    },
    LATEST_POSTS: {
      TITLE: 'DERNIERS POSTS',
      NO_POSTS_SINGLE_DESCRIPTION:
        "Cette communauté n'a pas encore de posts. Pourquoi ne pas commencer une conversation ?",
    },
    BUTTONS: {
      LEAVE: 'Quitter',
      VIEW_LEADERBOARD: 'Voir le classement',
    },
    ERRORS: {
      POST_MESSAGE: "Erreur lors de l'envoi du nouveau message.",
      DELETE_MESSAGE: 'Erreur lors de la suppression du message.',
      POST_REPLY: "Erreur lors de l'envoi du nouveau message.",
      JOIN_LEADERBOARD: 'Erreur lors de la participation au classement.',
      LEAVE_LEADERBOARD: 'Erreur lors de la sortie du classement',
      LOADING_PREVIOUS_MESSAGES: 'Erreur lors du chargement des messages précédents',
    },
    MODERATION_OPTIONS: {
      DELETE: 'Supprimer',
      HIDE_THIS_MESSAGE: 'Masquer ce message',
      HIDE_ALL_MESSAGES_FROM_USER: 'Bloquer cet utilisateur',
      REPORT_ABUSE: 'Signaler un abus',
      ITS_SPAM: "C'est du spam",
      ITS_OFFENSIVE: "C'est offensant",
      OTHER_ABUSE_OPTION: 'Autre',
      WHY_WAS_THIS_ABUSIVE: 'Pourquoi ce message est-il abusif ?',
      HIDE_ALL_MESSAGES: "Bloquer l'utilisateur",
      HIDE_ALL_MESSAGES_FROM_USER_TITLE: 'Bloquer/masquer tous les messages de {{username}} ?',
      HIDE_ALL_MESSAGES_FROM_USER_MESSAGE: 'Vous ne verrez plus aucun message de cet utilisateur',
      HIDE_ALL_MESSAGES_FROM_USER_CONFIRMATION_TITLE: 'OK',
      HIDE_ALL_MESSAGES_FROM_USER_CONFIRMATION_MESSAGE:
        'Vous ne verrez plus aucun message de {{username}}.',
    },
    NO_POSTS_MESSAGE: {
      LINE1: 'Bienvenue !',
      LINE2: 'Pourquoi ne pas commencer une conversation ?',
    },
    DIALOGS: {
      JOIN_LEADERBOARD_CONFIRMATION_TITLE: 'Vous avez rejoint !',
      JOIN_LEADERBOARD_CONFIRMATION_MESSAGE:
        'Il se peut que vos statistiques mettent quelques heures à apparaître.',
    },
    GROUP_FEED: {
      MESSAGE_GROUP: 'Message {{groupName}}.',
      REPLY: 'Répondre',
      REPLIES: 'Réponses',
      ADD_A_REPLY: 'Ajouter une réponse',
    },
  },
  TABS: {
    MESSAGES: 'Messages',
    CONNECT: 'Connecter',
    DAILY: 'Quotidien',
    TRENDS: 'Tendances',
    SETTINGS: 'Paramètres',
    COMMUNITY: 'Groupes',
    COACHES: 'Coachs',
    GROUP_FEED: 'Flux de groupe',
    LOG: 'Mes données',
    BOTTOM_TABS: {
      PROGRAM: 'Programme',
      GROUPS: 'Communautés',
      ACCOUNT: 'Compte',
      MESSAGES: 'Messages',
      CONNECT: 'Connecter',
      LOG: 'Mes données',
    },
  },
  SETTINGS: {
    EDIT_CONNECTED_APPS: 'Modifier les applications connectées',
    CONNECTED_APPS_AND_TRACKERS: 'Applications et traqueurs connectés',
    CONNECT_BLUETOOTH_DEVICES: 'Connecter les appareils Bluetooth',
    ENTER_AN_INVITE_ID: 'Entrez {{article}} {{inviteIdName}}',
    ENTER_A_CLIENT_USER_ID: 'Entrez {{article}} {{clientUserIdName}}',
    CLIENT_USER_ID_DISPLAY: "Identifiant de l'utilisateur client",
    CLIENT_USER_ID_ARTICLE: 'un',
    ENTER_YOUR_PATIENT_ID: 'Entrez votre ID patient',
    ENTER_EMAIL_CONFIRMATION_CODE: 'Entrez le code de confirmation par email',
    PATIENT_ID: 'ID patient',
    ENTER_YOUR_ID: 'Entrez votre {{inviteIdShortName}}',
    PATIENT_ID_ADDED_TITLE: 'Succès !',
    PATIENT_ID_ADDED_MESSAGE:
      'Les résultats de laboratoire apparaîtront maintenant pour cet ID patient au fur et à mesure de leur disponibilité',
    HELP: 'Aide',
    PRIVACY_POLICY: 'Politique de confidentialité',
    TERMS_OF_SERVICE: "Conditions d'utilisation",
    LOG_OUT: 'Se déconnecter',
    LOAD_FAILURE:
      'Le chargement des paramètres du Coach a échoué. Tirer vers le bas pour rafraîchir et réessayer.',
    RATE_APP: 'Évaluer application',
    EMAIL_CONFIRMED: "Merci d'avoir vérifié votre adresse e-mail.",
    CONFIRM_EMAIL_ADDRESS: 'Vérifiez votre adresse e-mail',
    DELETE_ACCOUNT: {
      BUTTON_TITLE: 'Supprimer',
      COMPARATOR: 'SUPPRIMER',
      DESCRIPTION:
        'Cela supprimera votre compte et toutes les données associées de notre système. Vous ne pourrez pas réactiver votre compte ni récupérer les données que vous avez enregistrées ou synchronisées.',
      INSTRUCTIONS:
        'Si vous souhaitez supprimer définitivement votre compte, saisissez SUPPRIMER (en MAJUSCULES), puis appuyez sur Supprimer',
      ERROR_MESSAGE: 'Saisissez SUPPRIMER (en MAJUSCULES)',
      ERROR_DELETING_ACCOUNT: 'Désolé, nous ne pouvons pas supprimer votre compte pour le moment.',
      CONFIRMATION_TITLE: 'Compte supprimé',
      CONFIRMATION_MESSAGE: `Nous supprimons votre compte et toutes les données associées de notre système. Cela peut prendre un certain temps.`,
    },
    ERRORS: {
      SUPPORT_EMAIL_FAILED_TITLE:
        "Impossible d'ouvrir le courrier électronique pour une demande de support",
      SUPPORT_EMAIL_FAILED_MESSAGE:
        'Veuillez vérifier que vous avez un compte e-mail activé sur votre appareil.',
      ADD_INVITE_ID_FAILED_TITLE: "Impossible d'ajouter {{inviteIdName}}",
      ADD_PATIENT_ID_FAILED_TITLE: "Impossible d'ajouter l'ID patient",
      INVALID_PATIENT_ID_MESSAGE:
        "L'ID patient, le nom et la date de naissance ne correspondent à aucune commande de laboratoire existante.",
      ENTER_EMAIL_CONFIRMATION_CODE_FAILED_TITLE: 'Échec de la vérification par e-mail',
      CURRENT_PASSWORD_NOT_CORRECT: "Le mot de passe actuel n'est pas correct",
      NEW_PASSWORD_DOES_NOT_MATCH:
        'Le nouveau mot de passe et la confirmation ne correspondent pas',
    },
    BLUETOOTH_LOCATION_PERMISSION_PROMPT: {
      PRIVACY_POLICY_URL_DESCRIPTION: 'Voir la politique de confidentialité',
      TITLE: "Autoriser l'accès à la position ?",
      EXPLANATION:
        "L'accès à la position est requis pour que {{appName}} puisse trouver votre appareil Bluetooth et rester connecté. Nous ne collectons ni ne stockons d'informations sur votre position.", //Some generic text always at the top of the location consent screen telling you about why we're doing this
      SETTINGS_INFO: {
        IOS:
          "Vous pouvez mettre à jour vos autorisations de localisation dans les paramètres de votre application. Pour une meilleure expérience, assurez-vous que l'accès à la localisation est défini sur 'Toujours'.",
        ANDROID:
          "Vous pouvez mettre à jour vos autorisations de localisation dans les paramètres de votre application. Pour une meilleure expérience, assurez-vous que l'accès à la localisation est défini sur 'Autoriser tout le temps'.",
      }, //something that appears just above the button that takes you to settings. We show that button as long as the location question has been answered already, so a user can either accept permissions they rejected, or turn off permissions they accepted
      ACCEPTED: "L'accès à la localisation est activé. Vous êtes prêt !", //We show this below the explanation to indicate that the user accepted permissions already and doesn't need to do anything else.
      REJECTED:
        "L'accès à la localisation n'est pas activé. Il doit être activé pour se connecter aux appareils Bluetooth.", //We show this if they rejected any/ all location permissions, right above the settings button that they can use to fix them
      REJECTED_ALWAYS:
        "L'accès à la localisation en arrière-plan est désactivé. Cela est nécessaire pour lire les données de vos appareils Bluetooth lorsque l'application est fermée.", //We show this on iOS only, if the user accepted 'While the app is open' permissions but rejected 'Always' permissions. Note that the initial acceptance is always a provisional-always, and then the user is prompted to keep tracking in the background later. But the user will not see this message until they reject always-on tracking
      ANDROID_11_BACKGROUND_LOCATION_PROMPT:
        "Activez la localisation 'Tout le temps' pour terminer la configuration.", //This shows up on the OS-required prompt that actually sets the location permissions
      NOT_ANSWERED: '',
    },
    NOTIFICATIONS: {
      NOTIFICATIONS: 'Notifications',
      COMMUNITIES: 'COMMUNAUTÉS',
      NO_COMMUNITIES: "Vous n'êtes membre d'aucune communauté",
      NOTIFICATION_SETTING: 'Paramètres de notification',
      COMMUNITY: 'Communauté',
      ALL_POSTS: 'Tous les messages',
      ADMIN_POSTS_REPLIES_THREADS:
        "Messages d'administration et toutes les réponses dans les discussions auxquelles j'ai contribué",
      ADMIN_POSTS_REPLIES: "Messages d'administration et réponses à mes messages",
      ADMIN_POSTS: "Messages d'administration uniquement",
      TURN_OFF: 'Désactiver les notifications',
    },
    OTA: {
      NEW_VERSION_AVAILIBLE: "Une nouvelle version de l'application est disponible.",
      UPGRADE_TO_LATEST: 'Mettez à jour vers la dernière version',
      NEW_VERSION_AVAILIBLE_TRY_AGAIN:
        "Une nouvelle version de l'application est disponible, mais nous ne pouvons pas la télécharger pour le moment. Ne vous inquiétez pas, nous réessaierons plus tard.",
      NETWORK_MAY_BE_BLOCKING:
        'Si vous voyez cela fréquemment, votre réseau peut bloquer les mises à jour. Passer à un réseau wifi différent peut aider.',
      ERROR_MESSAGE: "Message d'erreur :",
    },
  },
  UNITS: {
    SVG: 'svg',
    SVGS: 'svgs',
    MINUTE: 'minute',
    MINUTES: 'minutes',
    HOUR: 'heure',
    HOURS: 'heures',
    SYSTOLIC: 'systolique',
    DIASTOLIC: 'diastolique',
    MINHEARTRATE: 'min',
    MAXHEARTRATE: 'max',
    AVGHEARTRATE: 'moy',
  },
};
