import Echo from 'laravel-echo';
import Pusher from 'pusher-js/react-native';
import env from '../../../config/env';

// required per https://github.com/laravel/echo/issues/126#issuecomment-321420772
window.Pusher = Pusher;

//Pusher.logToConsole = true;

export default class PusherListener {
  _apiV5;
  _echo;
  _pusherSocket;
  _userChannel;
  _groupChannelIds = [];
  _userId;

  constructor({ apiV5 }) {
    this._apiV5 = apiV5;
  }

  onMessageDelivered = () => {};
  onMessageUpdated = () => {};
  onMessageDeleted = () => {};

  onLogUpdated = () => {};

  onInviteUpdated = () => {};

  onGroupTopicFeedUpdated = () => {};

  _getChannelName = userId => `users.${userId}`;

  init() {
    if (this._echo) {
      this._echo.disconnect();
    }

    /*this._pusherSocket = new Pusher(env.pusherKey, {
      encrypted: true,
      cluster: env.pusherCluster,
    });*/
    // create new echo instance so we know it's bound to the correct api token
    this._echo = new Echo({
      namespace: 'Nudge.Events',
      authEndpoint: `${this._apiV5.defaults.baseURL}/broadcasting/auth`,
      broadcaster: 'pusher',
      //client: Pusher,//this._pusherSocket,
      key: env.pusherKey,
      encrypted: true,
      cluster: env.pusherCluster,
      auth: {
        headers: this._apiV5.defaults.headers.common,
      },
    });
  }

  startListeningForUser(userId) {
    this._userId = userId;
    this._userChannel = this._echo.channel(this._getChannelName(userId));
    this._privateUserChannel = this._echo.private(this._getChannelName(userId));
    this._userChannel.listen('\\Nudge\\Services\\Messenger\\Events\\MessageDelivered', payload => {
      if (!payload.notification_only) {
        this.onMessageDelivered(payload);
      }
    });
    this._userChannel.listen('\\Nudge\\Services\\Messenger\\Events\\MessageUpdated', payload => {
      this.onMessageUpdated(payload);
    });
    this._userChannel.listen('\\Nudge\\Services\\Messenger\\Events\\MessageDeleted', payload => {
      this.onMessageDeleted(payload);
    });
    this._userChannel.listen('\\Nudge\\Events\\UserTrackerLogsUpdated', payload => {
      this.onLogUpdated(payload);
    });
    this._privateUserChannel.listen('\\Nudge\\Events\\UserInvited', payload => {
      this.onInviteUpdated(payload);
    });
  }

  startListeningForClub(groupId) {
    if (!this._groupChannelIds.find(id => id === groupId)) {
      this._groupChannelIds.push(groupId);
      this._echo.private(`clubs.${groupId}`).listen('NewClubsComment', payload => {
        console.log(payload);
        this.onGroupTopicFeedUpdated(payload);
      });
    }
  }

  stopListening() {
    if (this._echo) {
      this._echo.disconnect();
    }
  }

  // used to add socket ID to request headers (X-Socket-ID) so we don't get a new pusher message from the result of that request
  // (e.g., don't get NewClubsComment when we ourselves are posting the message)
  getSocketId() {
    if (this._echo) {
      return this._echo.socketId();
    }
    return null;
  }
}
