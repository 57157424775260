import React, { useCallback, useMemo } from 'react';
//import { spy } from 'mobx';
import { observer, inject } from 'mobx-react';
import { TagsList } from '/common/components/program';
import { useScreenBehaviors } from '/common/navigation';
import { ScreenFirstLoadWrapper } from '../../../common';
import { navigationRoutes } from '../../../../config/constants';
import ProgramTopTabs from './ProgramTopTabs';

const TagsSubTag = inject('rootStore')(
  observer(({ navigation, rootStore }) => {
    const { onPullToRefresh, isPullToRefreshInProgress, } = useScreenBehaviors({
      // we refresh automatically as app is reopened
      onManualRefresh: async () => {
        // someone refreshing intentionally is looking for something
        await logStore.loadTags();
      },
    });

    const onPressTag = useCallback(tag => {
      navigation.navigate(navigationRoutes.stacks.main.programCardsList, { tagId: tag.name });
    }, []);

    const ScreenFirstLoadWrapperComponent = useMemo(() => {
      return props => (
        <ScreenFirstLoadWrapper
          state={logStore.loadTagsState}
          hasData={logStore.tags.length}
          onPressRefresh={logStore.loadTags}>
          {props.children}
        </ScreenFirstLoadWrapper>
      );
    }, []);

    const { logStore } = rootStore;

    return (
      <TagsList
        tags={logStore.tagsSorted}
        onPressTag={onPressTag}
        FirstLoadComponent={ScreenFirstLoadWrapperComponent}
        onPullToRefresh={onPullToRefresh}
        isPullToRefreshInProgress={isPullToRefreshInProgress}
        onLoadAdditionalItems={logStore.loadAdditionalTags}
      />
    );
  })
);

export default TagsSubTag;
