import React from 'react';
import getBrandingDefaults from './getBrandingDefaults';

// Different from coach due to differences in getting default branding

// create compatibility for copying components that use Nudge v4 BrandContext
// So it'll be easier to merge these codebases in the future.
// largely, this will just have highlightedColor and maybe a few others

// We're probably not going to want to use this until we have React 16.6, which supports
// contextType, which makes it possible for us to use branding in context like we use
// branding today.
// See https://reactjs.org/blog/2018/10/23/react-v-16-6.html
const BrandContext = React.createContext({
  ...getBrandingDefaults(),
});

export { BrandContext };
