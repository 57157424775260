import React, { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import * as Updates from 'expo-updates';
import { View, Text, Platform } from 'react-native';
import { observer, Observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { shouldForceOnGroupTabs } from '/common/config/feature-support';
import {
  RoundButton,
  CircleThumbnail,
  RowChevronButton,
  NudgeSectionList as SectionList,
  AestheticallyPleasingListFooter,
  SectionHeader,
} from '/common/components/';
import { SeparatorLine } from '../../common';
import {
  containerStyles,
  colors,
  textStyles,
  sizes,
  useMediaQueryInfo,
} from '/common/config/styles';
import { common } from '../../../config/strings';
import { BrandContext } from '../../../config/branding';
import getVersion from '../../../lib/getVersion';

// helps add margin around button with background color
const RoundButtonContainer = ({ children }) => {
  const { isTabletOrDesktop } = useMediaQueryInfo();
  return (
    <View
      style={{
        backgroundColor: isTabletOrDesktop ? colors.bg0 : colors.bg1,
        paddingHorizontal: sizes.medium,
        paddingVertical: 5,
      }}>
      {children}
    </View>
  );
};

const buildTextComponent = title => () => <Text style={textStyles.standard.dark}>{title}</Text>;

const SettingsSummary = observer(
  ({
    onPressConnectedApps,
    onPressNotifications,
    user,
    onPressEnterPromoCode,
    onPressUpdateClientUserId,
    onPressEditProfile,
    onPressHelp,
    onPressTermsOfService,
    onPressPrivacyPolicy,
    onPressRateApp,
    hideRateApp,
    onPressPeripherals,
    otaUpdateStatus,
    isGroupsEnabled,
  }) => {
    const sections = [];

    const branding = useContext(BrandContext);
    const { t } = useTranslation();

    const { isTabletOrDesktop } = useMediaQueryInfo();

    if (user) {
      const userAndAppsSection = {
        title: '',
        data: [
          () => (
            <RowChevronButton
              onPress={onPressEditProfile}
              LabelComponent={() => (
                <Observer>
                  {() => (
                    <View
                      style={{
                        flex: 1,
                        paddingVertical: sizes.medium,
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                      <CircleThumbnail
                        source={user.photoSource}
                        diameter={sizes.thumbnail}
                        altTitle={common.fullName(user)}
                      />
                      <Text
                        style={[
                          textStyles.standard.semibold,
                          { paddingLeft: sizes.medium, flex: 1 },
                        ]}>
                        {common.fullName(user)}
                      </Text>
                    </View>
                  )}
                </Observer>
              )}
            />
          ),
        ],
      };

      if (branding.sync.appsEnabled && Platform.OS !== 'web') {
        userAndAppsSection.data.push(() => (
          <RowChevronButton
            onPress={onPressConnectedApps}
            LabelComponent={buildTextComponent(t('SETTINGS:CONNECTED_APPS_AND_TRACKERS'))}
          />
        ));
      }
      if (branding.sync.bluetoothDevicesEnabled && Platform.OS !== 'web') {
        userAndAppsSection.data.push(() => (
          <RowChevronButton
            onPress={onPressPeripherals}
            LabelComponent={buildTextComponent(t('SETTINGS:CONNECT_BLUETOOTH_DEVICES'))}
          />
        ));
      }

      const isCommunitiesEnabled =
        shouldForceOnGroupTabs(branding) || (branding.tabs.groups.enabled && isGroupsEnabled);

      if (Platform.OS !== 'web' && isCommunitiesEnabled) {
        userAndAppsSection.data.push(() => (
          <RowChevronButton
            onPress={onPressNotifications}
            LabelComponent={buildTextComponent(t('SETTINGS:NOTIFICATIONS:NOTIFICATIONS'))}
          />
        ));
      }
      sections.push(userAndAppsSection);
    }

    sections.push({
      title: '',
      data: [
        () => (
          <RoundButtonContainer>
            <RoundButton
              onPress={onPressEnterPromoCode}
              title={t('SETTINGS:ENTER_AN_INVITE_ID', {
                inviteIdName: branding.inviteIdDisplay.name,
                article: branding.inviteIdDisplay.article,
              })}
            />
          </RoundButtonContainer>
        ),
      ],
    });

    if (branding.settings.showClientUserId) {
      sections.push({
        title: '',
        data: [
          () => (
            <RoundButtonContainer>
              <RoundButton
                onPress={onPressUpdateClientUserId}
                title={t('SETTINGS:ENTER_A_CLIENT_USER_ID', {
                  clientUserIdName:
                    branding.clientUserIdDisplay.name || t('SETTINGS:CLIENT_USER_ID_DISPLAY'),
                  article:
                    branding.clientUserIdDisplay.article || t('SETTINGS:CLIENT_USER_ID_ARTICLE'),
                })}
              />
            </RoundButtonContainer>
          ),
        ],
      });
    }

    const infoSection = {
      title: '',
      data: [
        () => (
          <RowChevronButton
            onPress={onPressHelp}
            LabelComponent={buildTextComponent(t('SETTINGS:HELP'))}
          />
        ),
        () => (
          <RowChevronButton
            onPress={onPressPrivacyPolicy}
            LabelComponent={buildTextComponent(t('SETTINGS:PRIVACY_POLICY'))}
          />
        ),
        () => (
          <RowChevronButton
            onPress={onPressTermsOfService}
            LabelComponent={buildTextComponent(t('SETTINGS:TERMS_OF_SERVICE'))}
          />
        ),
      ],
    };

    if (!hideRateApp && Platform.OS !== 'web') {
      infoSection.data.push(() => (
        <RowChevronButton
          onPress={onPressRateApp}
          LabelComponent={buildTextComponent(t('SETTINGS:RATE_APP'))}
        />
      ));
    }

    sections.push(infoSection);

    const versions = getVersion();

    return (
      <SectionList
        renderItem={({ item }) => item()}
        failureMessage={t('SETTINGS:LOAD_FAILURE')}
        sections={sections}
        keyExtractor={() => uuidv4()}
        renderSectionHeader={({ section }) => (
          <SectionHeader
            title={section.title}
            style={{ backgroundColor: isTabletOrDesktop ? colors.bg0 : colors.bg1 }}
          />
        )}
        ItemSeparatorComponent={SeparatorLine}
        style={[containerStyles.fillAvailableSpace, { backgroundColor: colors.bg1 }]}
        ListFooterComponent={() => (
          <>
            <View
              style={{ padding: 30, backgroundColor: isTabletOrDesktop ? colors.bg0 : colors.bg1 }}>
              <Text
                style={[
                  textStyles.small.light,
                  { textAlign: 'center' },
                ]}>{`v. ${versions.version} / ${versions.buildNumber} / ${versions.jsBuild}`}</Text>
              {Platform.OS !== 'web' &&
              otaUpdateStatus &&
              otaUpdateStatus.status === 'updateAvailable' ? (
                <View>
                  <Text
                    style={[
                      textStyles.small.light,
                      { textAlign: 'center', marginBottom: sizes.small },
                    ]}>{`${t('SETTINGS:OTA:NEW_VERSION_AVAILIBLE')}\n`}</Text>
                  <Text
                    onPress={Updates.reloadAsync}
                    style={[
                      textStyles.small.light,
                      textStyles.link,
                      { textAlign: 'center', marginBottom: sizes.small },
                    ]}>
                    {t('SETTINGS:OTA:UPGRADE_TO_LATEST')}
                  </Text>
                </View>
              ) : null}
              {Platform.OS !== 'web' && otaUpdateStatus && otaUpdateStatus.status === 'error' ? (
                <Text style={[textStyles.small.light, { textAlign: 'center' }]}>{`${t(
                  'SETTINGS:OTA:NEW_VERSION_AVAILIBLE_TRY_AGAIN'
                )}\n\n ${t('SETTINGS:OTA:NETWORK_MAY_BE_BLOCKING')}\n\n ${t(
                  'SETTINGS:OTA:ERROR_MESSAGE'
                )} "${otaUpdateStatus.errorMessage}"`}</Text>
              ) : null}
            </View>
            <AestheticallyPleasingListFooter />
          </>
        )}
      />
    );
  }
);

SettingsSummary.propTypes = {
  onPressConnectedApps: PropTypes.func.isRequired,
  onPressUpdateClientUserId: PropTypes.func.isRequired,
  onPressPeripherals: PropTypes.func.isRequired,
  onPressEnterPromoCode: PropTypes.func.isRequired,
  user: PropTypes.object,
  onPressEditProfile: PropTypes.func.isRequired,
  onPressHelp: PropTypes.func.isRequired,
  onPressTermsOfService: PropTypes.func.isRequired,
  onPressPrivacyPolicy: PropTypes.func.isRequired,
  onPressRateApp: PropTypes.func.isRequired,
  hideRateApp: PropTypes.bool,
};

SettingsSummary.defaultProps = {
  hideRateApp: false,
  onPressEnterPatientId: null,
};

export { SettingsSummary };
export default SettingsSummary;
