import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import Styles from './styles';
import MessageBody from './MessageBody';

/**
 * The a reply to a topic.
 */
const Reply = ({
  reply,
  onPressLink,
  currentMessage,
  onPressAttachment,
  onLongPress,
  onPressImage,
}) => (
  <View style={[Styles.clubTopicThreadMessage]}>
    <MessageBody
      topicOrReply={reply}
      onPressLink={onPressLink}
      currentMessage={currentMessage}
      onPressAttachment={onPressAttachment}
      onLongPressMessage={onLongPress}
      onPressImage={onPressImage}
    />
  </View>
);

Reply.propTypes = {
  reply: PropTypes.object.isRequired,
  onPressLink: PropTypes.func.isRequired,
};

export default Reply;
