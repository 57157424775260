import PropTypes from 'prop-types';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { withTranslation } from 'react-i18next';
import { DateTime } from 'luxon'; //started switching over to luxon after I first made this
import moment from 'moment';
import { textStyles, colors, sizes } from '../../../config/styles';

/**
 * Get the date and time if before today and just the time if it's today
 * @param {*} date
 */
const getChatDate = (date, t) => {
  const today = DateTime.local();
  const dateInQuestion = DateTime.fromJSDate(date);
  if (today.hasSame(dateInQuestion, 'day')) {
    return t('GENERAL:DATES:TODAY');
  }
  if (today.minus({ days: 1 }).hasSame(dateInQuestion, 'day')) {
    return t('GENERAL:DATES:YESTERDAY');
  }

  return dateInQuestion.toLocaleString(DateTime.DATE_MED);
};

class DaySeparator extends React.Component {
  render() {
    const { currentMessage, previousMessage, t } = this.props;
    const currentMoment = moment(currentMessage.createdAt);
    const previousMoment = moment(previousMessage.createdAt);

    if (!currentMoment.isSame(previousMoment, 'd')) {
      return (
        <View style={[styles.container, this.props.containerStyle]}>
          <View
            style={{
              flex: 1,
              height: StyleSheet.hairlineWidth,
              backgroundColor: colors.border0,
              marginHorizontal: sizes.medium,
            }}
          />
          <View style={[styles.wrapper, this.props.wrapperStyle]}>
            <Text style={[textStyles.small.light, styles.text, this.props.textStyle]}>
              {getChatDate(currentMessage.createdAt, t).toUpperCase()}
            </Text>
          </View>
          <View
            style={{
              flex: 1,
              height: StyleSheet.hairlineWidth,
              backgroundColor: colors.border0,
              marginHorizontal: sizes.medium,
            }}
          />
        </View>
      );
    }
    return null;
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 5,
    marginBottom: 10,
  },
  text: {
    backgroundColor: 'transparent',
  },
});

DaySeparator.contextTypes = {
  getLocale: PropTypes.func,
};

DaySeparator.defaultProps = {
  currentMessage: {
    // TODO test if crash when createdAt === null
    createdAt: null,
  },
  previousMessage: {},
  containerStyle: {},
  wrapperStyle: {},
  textStyle: {},
};

DaySeparator.propTypes = {
  currentMessage: PropTypes.object,
  previousMessage: PropTypes.object,
  containerStyle: PropTypes.any,
  wrapperStyle: PropTypes.any,
  textStyle: PropTypes.any,
  t: PropTypes.any.isRequired,
};

export default withTranslation()(DaySeparator);
