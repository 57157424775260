import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { View, ActivityIndicator, Text } from 'react-native';
import { colors, textStyles, sizes, useMediaQueryInfo } from '/common/config/styles';
import { BrandContext } from '../../config/branding';

/**
 * Wraps a component and covers it in a shade when isLoading is true
 */
const LoadingShadeWrapper = observer(
  ({ isLoading, children, style, message, coversOnboardingScreen }) => {
    const { isWideScreen } = useMediaQueryInfo();
    const backgroundColor =
      isWideScreen && coversOnboardingScreen
        ? colors.bg1
        : colors.bg0; /* this probably shouldn't be bg0, but kind of dangerous to change now, so leaving alone */
    return (
      <View
        style={[
          {
            backgroundColor,
          },
          style,
        ]}>
        {children}
        {isLoading ? (
          <View
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              opacity: 0.5,
              backgroundColor,
            }}
          />
        ) : null}
        {isLoading ? (
          <View
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            {message ? (
              <Text style={[textStyles.medium.dark, { marginBottom: sizes.large }]}>{message}</Text>
            ) : null}
            <BrandContext.Consumer>
              {branding => <ActivityIndicator color={branding.highlightedColor} size="large" />}
            </BrandContext.Consumer>
          </View>
        ) : null}
      </View>
    );
  }
);

LoadingShadeWrapper.propTypes = {
  children: PropTypes.any,
  isLoading: PropTypes.bool,
  style: PropTypes.any,
  // optional message above the loading indicator
  message: PropTypes.string,
  // if true, invoke alternate styling
  // this is kind of a kludge due to the default color being bg0, except for onboarding on wide screens
  isWideScreen: PropTypes.bool,
};

LoadingShadeWrapper.defaultProps = {
  isLoading: false,
  children: null,
  style: null,
  message: null,
  coversOnboardingScreen: false,
};

export default LoadingShadeWrapper;
