import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Keyboard } from 'react-native';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { withAlert } from '/common/navigation/';
import { UpdateEmail } from '../../../components/settings';
import { delay } from '/common/lib';
import { BrandContext } from '../../../config/branding';
import { navigationRoutes } from '../../../config/constants';
import UpdateSettingsScreenWrapper from './UpdateSettingsScreenWrapper';

@inject('rootStore')
@observer
class UpdateEmailScreen extends Component {
  static contextType = BrandContext;

  constructor(props) {
    super(props);
    const { rootStore } = this.props;
    const { settingsStore } = rootStore;
    const { user } = settingsStore;
    this.state = {
      email: user.email,
    };
  }

  _onPressSave = async () => {
    Keyboard.dismiss();
    const { email } = this.state;
    const { rootStore, navigation } = this.props;
    const branding = this.context;

    const { settingsStore } = rootStore;
    await settingsStore.updateUserFields({ email });
    if (settingsStore.updateUserState.isDone) {
      navigation.pop();
      await delay(1000);
      if (branding.confirmEmailAtSignup && !settingsStore.user.emailVerifiedAt) {
        navigation.navigate(navigationRoutes.modals.confirmEmailAddress);
      }
    }
  };

  _clearErrors = () => {
    const { rootStore } = this.props;
    const { settingsStore } = rootStore;
    settingsStore.updateUserState.reset();
  };

  _onChangeEmail = email => {
    this._clearErrors();
    this.setState({ email });
  };

  _onPressBack = () => {
    Keyboard.dismiss();
    this._clearErrors();
    this.props.navigation.pop();
  };

  _onPressCancel = () => {
    const { alert, t } = this.props;

    alert(t('PROFILE:EDIT:UNSAVED_CHANGES'), t('PROFILE:EDIT:WHAT_WOULD_YOU_LIKE_TO_DO'), [
      {
        text: t('PROFILE:EDIT:SAVE_CHANGES'),
        onPress: () => {
          this._onPressSave();
        },
        style: 'default',
      },
      {
        text: t('PROFILE:EDIT:DISCARD_CHANGES'),
        onPress: () => {
          this._onPressBack();
        },
        style: 'destructive',
      },
    ]);
  };

  render() {
    const { rootStore, navigation, t } = this.props;
    const { settingsStore } = rootStore;
    const { user } = settingsStore;
    const { email } = this.state;
    return (
      <UpdateSettingsScreenWrapper
        navigation={navigation}
        isDirty={user.email !== email}
        navbarTitle={t('PROFILE:FIELDS:EMAIL')}
        updateFailedMessage={t('PROFILE:ERRORS:EMAIL_UPDATE_FAILED_MESSAGE')}
        onPressCancel={this._onPressCancel}
        onPressBack={this._onPressBack}
        onPressSave={this._onPressSave}
        form={
          <UpdateEmail
            email={email}
            onChangeEmail={this._onChangeEmail}
            formErrors={settingsStore.updateUserState.formErrors}
          />
        }
      />
    );
  }
}

UpdateEmailScreen.propTypes = {
  rootStore: PropTypes.object,
  navigation: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(withAlert(UpdateEmailScreen));
