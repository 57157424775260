import { Platform } from 'react-native';
import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';

export default class AuthWebBrowser {
  // plug in some kind of handling to submit the URL to RootStore as if it came from a link
  static onIosAuthSessionSuccess = () => {};

  static async openAuthSession(url, callbackUrls) {
    if (Platform.OS === 'android') {
      // I don't think auth session does anything for us here.
      // It doesn't seem to trigger any special handling, and it can pop an error about redirects
      // So we just open browser and handle our own redirects.
      return await WebBrowser.openBrowserAsync(url);
    }
    // iOS 11+
    if (Platform.OS === 'ios' && parseInt(Platform.Version, 10) >= 11) {
      const authResult = await WebBrowser.openAuthSessionAsync(url, callbackUrls.ios);
      if (authResult && authResult.type === 'success') {
        AuthWebBrowser.onIosAuthSessionSuccess(authResult.url);
        return;
      }
    } else {
      // iOS 10 - open in Safari
      return await Linking.openURL(url);
    }
  }
}
