import { types } from 'mobx-state-tree';
import { take, sortBy } from 'lodash';
import { dateStringToJsDate, jsDateToDateString } from 'nudge-client-common/lib/dates';
import { DateTimeV4 } from '/common/stores/types';

const Leader = types
  .model('Leader', {
    id: types.identifierNumber,
    lastName: types.maybeNull(types.string),
    firstName: types.maybeNull(types.string),
    photoUrl: types.maybeNull(types.string),
    rank: types.number,
    total: types.number,
  })
  .views(self => ({
    get effectiveTotal() {
      // removed up to the minute stats from coach view, will have to put back later
      return self.total;
    },
  }));

const LeaderboardDate = types
  .model('LeaderboardDate', {
    date: DateTimeV4,
    timezone: types.string,
    timezoneType: types.number,
  })
  .views(self => ({
    get dateWithoutTime() {
      return jsDateToDateString(dateStringToJsDate(self.date));
    },
  }));

const LeaderboardMetricType = types.model('LeaderboardMetricType', {
  unit: types.string,
  type: types.string,
});

const Leaderboard = types
  .model('Leaderboard', {
    id: types.identifierNumber,
    durationType: types.string,
    description: types.string,
    startsAt: types.maybeNull(LeaderboardDate),
    endsAt: types.maybeNull(LeaderboardDate),
    resetsAt: types.maybeNull(LeaderboardDate),
    metricType: LeaderboardMetricType,
    leaders: types.array(Leader),
    position: types.maybeNull(Leader),
    customTrackerId: types.maybeNull(types.number),
  })
  .views(self => ({
    get leadersSorted() {
      return sortBy(self.leaders, l => l.effectiveTotal).reverse();
    },
    get firstThreeLeadersSorted() {
      return take(this.leadersSorted, 3);
    },
    /*get usesUpToMinuteStats() {
      if (
        self.description === 'Active Time' ||
        self.description === 'Total Steps' ||
        self.customTrackerId
      ) {
        return true;
      }
      return false;
    },
    // resolves props needed to calculate up to the minute stats
    get upToTheMinuteTrackerDetails() {
      if (self.customTrackerId) {
        return {
          type: 'custom-counter',
          id: self.customTrackerId,
          requiresDeduplication: false,
          deduplicationRule: null,
          primaryField: 'quantity',
        };
      }
      // these ID's only work in prod. There's no connection between these and the tracker ID's
      if (self.metricType.uom === 'steps') {
        return {
          type: 'pedometer',
          id: 8028,
          requiresDeduplication: true,
          deduplicationRule: 'activitiesAndSteps',
          primaryField: 'quantity',
        };
      } else {
        return {
          type: 'cardio',
          id: 8026,
          requiresDeduplication: true,
          deduplicationRule: 'activitiesAndSteps',
          primaryField: 'hiDuration',
        };
      }
    },*/
    get isUserInTop3() {
      if (!self.position) {
        return false;
      }
      return self.firstThreeLeadersSorted.find(l => l.id === self.position.id);
    },
  }));

export default Leaderboard;
