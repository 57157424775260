import React, { useContext, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { reaction } from 'mobx';
import { observer, inject } from 'mobx-react';
import { EnterInviteId } from '../../components/sign-up';
import { SimpleNavWrapper, HeaderIconButton } from '../../components/navigation';
import { LoadingShadeWrapper } from '../../components/common';
import { containerStyles } from '../../config/common-styles';
import { BackIcon } from '../../config/common-icons';
import { withKeyboardAvoidance, ScreenError } from '../common';
import { BrandContext } from '../../config/branding';
import useNextSignupRoute from './useNextSignupRoute';

const EnterInviteIdScreen = inject('rootStore')(
  observer(function({ rootStore, navigation, route }) {
    const [inviteId, setInviteId] = useState();
    const nextRoute = useNextSignupRoute(route);
    const branding = useContext(BrandContext);

    useEffect(() => {
      reaction(
        () => rootStore.copiedInviteId,
        copiedInviteId => {
          setInviteId(copiedInviteId);
        },
        { fireImmediately: true }
      );
    }, []);

    const onPressJoin = useCallback(() => {
      async function doAsync() {
        await rootStore.validateInviteId(inviteId);
        if (rootStore.signupActionState.isDone) {
          navigation.navigate(nextRoute, {
            inviteId,
            ...route.params, // pass params from previous steps (like client ID)
          });
        }
      }
      doAsync();
    }, [inviteId, navigation, route, nextRoute]);

    const onPressNo = useCallback(() => {
      navigation.navigate(nextRoute, {
        inviteId: branding.signUp.fallbackInviteId ? branding.signUp.fallbackInviteId : null,
        ...route.params, // pass params from previous steps (like client ID)
      });
    }, [nextRoute, route, navigation, branding]);

    const onPressBack = useCallback(() => {
      navigation.goBack();
    }, [navigation]);

    return (
      <SimpleNavWrapper
        leftButton={
          <HeaderIconButton
            cavyId="EntserInviteIdScreen.Back"
            icon={<BackIcon />}
            onPress={onPressBack}
          />
        }>
        <LoadingShadeWrapper
          coversOnboardingScreen
          isLoading={rootStore.signupActionState.isPending}
          style={containerStyles.fillAvailableSpace}>
          <ScreenError state={rootStore.signupActionState} ignoreFormErrors />
          <EnterInviteId
            isImportedInviteId={!!rootStore.copiedInviteId}
            importedInviteId={rootStore.copiedInviteId}
            canSkipInviteId={!branding.signUp.inviteIdRequired}
            onPressContinue={onPressJoin}
            onPressNo={onPressNo}
            inviteId={inviteId}
            onChangeInviteId={value => setInviteId(value)}
            formErrors={rootStore.signupActionState.formErrors}
          />
        </LoadingShadeWrapper>
      </SimpleNavWrapper>
    );
  })
);

EnterInviteIdScreen.propTypes = {
  navigation: PropTypes.object,
};

export default withKeyboardAvoidance(EnterInviteIdScreen, {
  enabledForIos: false,
});
