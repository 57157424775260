import React from 'react';
import { StyleSheet, View } from 'react-native';
import { observer } from 'mobx-react/native';
import { PropTypes } from 'prop-types';
import { MaterialIndicator } from 'react-native-indicators';

@observer
export default class PendingOperationIndicator extends React.Component {
  render() {
    return (
      <View style={[styles.container, this.props.containerStyle]}>
        <View style={[styles.primary, this.props.primaryStyle]}>
          <MaterialIndicator color={this.props.color} size={this.props.size} />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  primary: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

PendingOperationIndicator.defaultProps = {
  containerStyle: {},
  primaryStyle: {},
  size: 36,
  color: 'blue',
};

PendingOperationIndicator.propTypes = {
  containerStyle: PropTypes.any,
  primaryStyle: PropTypes.any,
  size: PropTypes.number,
  color: PropTypes.string,
};
