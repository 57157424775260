import React from 'react';
import { Platform } from 'react-native';
import {
  ActionSheetProvider,
  useActionSheet as useExpoActionSheet,
} from '@expo/react-native-action-sheet';
import { sizes } from '/common/config/styles';

const extraActionSheetPropsForWeb = {
  containerStyle: {
    width: 300,
    alignSelf: 'center',
    marginBottom: '50vh',
    showSeparators: true,
    borderRadius: sizes.borderRadius,
  },
};

/**
 * Wrap useActionSheet in something that will apply web themes to make it look less horrible on web.
 */
function useActionSheet() {
  const { showActionSheetWithOptions } = useExpoActionSheet();
  const myExtraProps = Platform.OS === 'web' ? extraActionSheetPropsForWeb : null;
  const myShowActionSheetWithOptions = (props, onPress) =>
    showActionSheetWithOptions({ ...myExtraProps, ...props }, onPress);
  return { showActionSheetWithOptions: myShowActionSheetWithOptions };
}

const withActionSheet = function(Component) {
  function WrappedComponent(props) {
    const { showActionSheetWithOptions } = useActionSheet();
    return <Component {...props} showActionSheetWithOptions={showActionSheetWithOptions} />;
  }

  return WrappedComponent;
};

export { ActionSheetProvider, useActionSheet, withActionSheet };
