import { Platform } from 'react-native';
import URI from 'urijs';
import { flatMap, intersection } from 'lodash';
import {
  ValidicHealthKit,
  HKSampleTypes,
  SHealthDataTypes,
  ValidicSHealth,
  ValidicEventEmitter,
} from 'react-native-validic';
import { imageUrlToSource } from './utils';
import appIcons from '../../assets/icons/apps';

const HealthKitDataTypes = [
  HKSampleTypes.HKQuantityTypeIdentifierFlightsClimbed,
  HKSampleTypes.HKQuantityTypeIdentifierDistanceWalkingRunning,
  HKSampleTypes.HKQuantityTypeIdentifierStepCount,
  HKSampleTypes.HKQuantityTypeIdentifierDistanceWheelchair,
  HKSampleTypes.HKQuantityTypeIdentifierBloodGlucose,
  HKSampleTypes.HKQuantityTypeIdentifierBodyMass,
  HKSampleTypes.HKQuantityTypeIdentifierHeight,
  HKSampleTypes.HKQuantityTypeIdentifierBodyFatPercentage,
  HKSampleTypes.HKQuantityTypeIdentifierLeanBodyMass,
  HKSampleTypes.HKQuantityTypeIdentifierBodyMassIndex,
  HKSampleTypes.HKWorkoutTypeIdentifier,
  HKSampleTypes.HKQuantityTypeIdentifierDistanceCycling,
  HKSampleTypes.HKCategoryTypeIdentifierSleepAnalysis,
  HKSampleTypes.HKQuantityTypeIdentifierDietaryCarbohydrates,
  HKSampleTypes.HKQuantityTypeIdentifierDietaryFiber,
  HKSampleTypes.HKQuantityTypeIdentifierDietaryProtein,
  HKSampleTypes.HKQuantityTypeIdentifierDietaryFatSaturated,
  HKSampleTypes.HKQuantityTypeIdentifierDietarySodium,
  HKSampleTypes.HKQuantityTypeIdentifierDietarySugar,
  HKSampleTypes.HKQuantityTypeIdentifierDietaryFatTotal,
  HKSampleTypes.HKQuantityTypeIdentifierDietaryEnergyConsumed,
  HKSampleTypes.HKCorrelationTypeIdentifierFood,
  HKSampleTypes.HKQuantityTypeIdentifierDietaryWater,
  HKSampleTypes.HKCorrelationTypeIdentifierBloodPressure,
  HKSampleTypes.HKQuantityTypeIdentifierHeartRate,
  HKSampleTypes.HKQuantityTypeIdentifierAppleExerciseTime,
];

const SHealthDataTypesList = [
  SHealthDataTypes.DAILY_STEP_COUNT,
  SHealthDataTypes.BLOOD_PRESSURE,
  SHealthDataTypes.GLUCOSE,
  SHealthDataTypes.WEIGHT,
  SHealthDataTypes.FOOD_INFO,
  SHealthDataTypes.FOOD_INTAKE,
  SHealthDataTypes.WATER_INTAKE,
  SHealthDataTypes.EXERCISE,
  SHealthDataTypes.SLEEP,
];

export default class SyncRepository {
  _apiV4;
  _apiV3;
  _errorReporter;
  _getBranding;

  // created after init
  _validicEventEmitter;

  constructor({ apiV4, apiV3, errorReporter, getBranding }) {
    this._apiV4 = apiV4;
    this._apiV3 = apiV3;
    this._errorReporter = errorReporter;
    this._getBranding = getBranding;

    this._validicEventEmitter = new ValidicEventEmitter();
    this._validicEventEmitter.addListener(
      result => {
        console.log(result);
      },
      error => {
        if (error && error.payload && error.payload.error) {
          this._errorReporter.withScope(scope => {
            scope.setFingerprint(['{{ default }}', error.event]);
            this._errorReporter.captureException(new Error(error.payload.error));
          });
        }
        console.log(error);
      }
    );
  }

  // combined built-in and oauth apps, massaged to include correct grouping
  // so this isn't awful upstream

  getConnectedAppsSections = async () => {
    const oauthSections = await this.getOauthConnectedAppSections();
    // try to start session again in case it didn't start on app load
    // gives us a better chance showing accurate Apple Health status
    // TODO: fail to load or do something else in the case where we can't start a session
    // Not sure if we want to fail on any failure to start a session, because that validic 404 could
    // be around for a while
    try {
      await this.startSession();
    } catch (e) {}

    const builtInAppStatus = await this.getDeviceSyncStatus();

    // 1) construct built-in app sectiions
    const builtInAppSection = {
      id: 'builtInApps',
      title: 'Built-in Apps',
      apps: [],
    };
    if (builtInAppStatus.isHealthKitAvailable) {
      builtInAppSection.apps.push({
        type: 'appleHealth',
        title: 'Apple Health',
        description: 'Sync with data aggregated in the Apple Health app.',
        isEnabled: builtInAppStatus.isHealthKitEnabled,
        isMissingPermissions: builtInAppStatus.isMissingHealthKitDataTypes,
        ios: true,
        android: false,
        isBuiltIn: true,
        iconSource: require('../../assets/icons/apps/icon-ahealth.png'),
      });
    }
    if (builtInAppStatus.isSHealthAvailable) {
      builtInAppSection.apps.push({
        type: 'sHealth',
        title: 'Samsung Health',
        description: 'Sync with data aggregated in the Samsung Health app.',
        isEnabled: builtInAppStatus.isSHealthEnabled,
        ios: false,
        android: true,
        isBuiltIn: true,
        iconSource: appIcons.shealth,
      });
    }

    // 2) add to oauth sections
    oauthSections.push(builtInAppSection);
    const sections = oauthSections;

    // 3) filter out enabled apps, put them in their own section
    const enabledAppsSection = {
      id: 'enabledApps',
      title: 'Enabled Apps',
      apps: flatMap(sections, s => s.apps).filter(a => a.isEnabled),
    };
    // remove first from all other sections...
    sections.forEach(section => {
      section.apps = section.apps.filter(a => !a.isEnabled);
    });
    // then add enabled apps section
    sections.push(enabledAppsSection);

    // 4) filter out stuff not compatible with platform
    sections.forEach(section => {
      section.apps = section.apps.filter(
        a => (Platform.OS === 'ios' && a.ios) || (Platform.OS === 'android' && a.android)
      );
    });

    // 5) filter out empty sections
    return sections.filter(s => s.apps.length > 0);
  };

  enableSync = async ({ app, oauthResponseProps }) => {
    if (app.isBuiltIn) {
      await this.enableBuiltInAppSync();
    } else {
      await this.obtainApiTokenForConnectedApp({ type: app.type, oauthResponseProps });
      try {
        // catch up syncing
        await this._apiV3.get(`log/sync-backlog/${app.type}`);
      } catch (error) {
        // don't worry if this fails
      }
    }
  };

  disableSync = async app => {
    if (app.isBuiltIn) {
      await this.disableBuiltInAppSync();
    } else {
      await this.deauthConnectedApp(app);
    }
  };

  // built-in connected apps

  startSession = async () => {
    // this throws an unnecessary warning in ExpoKit for Android, should remove
    if (ValidicHealthKit) {
      const result = await ValidicHealthKit.isSessionActive();
      if (!result.isActive) {
        const validicDetails = await this.getValidicDetails();
        console.log('validic details');
        console.log(validicDetails);
        await ValidicHealthKit.startSession(
          validicDetails.externalUsersId,
          validicDetails.serviceClientId,
          validicDetails.accessToken
        );
        return;
      }
    }
    if (ValidicSHealth) {
      const result = await ValidicSHealth.isSessionActive();
      if (!result.isActive) {
        const validicDetails = await this.getValidicDetails();
        await ValidicSHealth.startSession(
          validicDetails.externalUsersId,
          validicDetails.serviceClientId,
          validicDetails.accessToken
        );
      }
    }

    if (!ValidicHealthKit && !ValidicSHealth) {
      console.warn(
        'WARNING: Not using react-native-validic! This is OK if you are running in the Expo client. Very bad if not!'
      );
    }
  };

  async endSession() {
    if (ValidicHealthKit) {
      await ValidicHealthKit.endSession();
    }
    if (ValidicSHealth) {
      await ValidicSHealth.endSession();
    }
  }

  async isHealthKitAvailable() {
    if (ValidicHealthKit) {
      const result = await ValidicHealthKit.isHealthKitAvailable();
      return result.isAvailable;
    }
    return false;
  }

  async isSHealthAvailable() {
    if (ValidicSHealth) {
      const result = await ValidicSHealth.isSHealthAvailable();
      return result.isAvailable;
    }
    return false;
  }

  enableBuiltInAppSync = async () => {
    if (ValidicHealthKit) {
      await ValidicHealthKit.setHealthKitSubscriptions(HealthKitDataTypes);
    }
    if (ValidicSHealth) {
      await ValidicSHealth.addSHealthSubscriptions(SHealthDataTypesList);
    }
  };

  disableBuiltInAppSync = async () => {
    if (ValidicHealthKit) {
      await ValidicHealthKit.setHealthKitSubscriptions([]);
    }
    if (ValidicSHealth) {
      await ValidicSHealth.removeSHealthSubscriptions(SHealthDataTypesList);
    }
  };

  getDeviceSyncStatus = async () => {
    if (await this.isHealthKitAvailable()) {
      const subscriptions = await ValidicHealthKit.getCurrentSubscriptions();
      return {
        isHealthKitAvailable: true,
        isHealthKitEnabled: !!subscriptions.length,
        // This doesn't seem to work if you later remove a permission
        // May work if you never subscribe in the first place
        isMissingHealthKitDataTypes:
          intersection(subscriptions, HealthKitDataTypes).length !== HealthKitDataTypes.length &&
          subscriptions.length > 0,
        isSHealthAvailable: false,
        isSHealthEnabled: false,
      };
    } else {
      return {
        isHealthKitAvailable: false,
        isHealthKitEnabled: false,
        areAllHealthKitDataTypesEnabled: false,
        isSHealthAvailable: false,
        isSHealthEnabled: false,
      };
    }
  };

  getValidicDetails = () => {
    return this._apiV4.get('users/me/integrations/validic').then(response => {
      const details = response.data;
      return {
        externalUsersId: details.external_users_id,
        serviceClientId: details.service_client_id,
        accessToken: details.access_token,
        isEnabled: details.enabled,
      };
    });
  };

  // oauth apps

  getOauthConnectedAppSections = () => {
    return this._apiV3.get('/sync/apps').then(response => {
      const branding = this._getBranding();
      let sections = [];
      if (branding.sync.fitnessAppsEnabled) {
        sections = response.data.filter(s => s.title !== 'Track Your Business');
      }
      if (branding.sync.businessAppsEnabled) {
        sections = [...sections, response.data.find(s => s.title === 'Track Your Business')];
      }

      sections.forEach(section => {
        section.apps.forEach(app => {
          app.authUrl = app.auth_url;
          app.deauthUrl = app.deauth_url;
          app.isEnabled = app.enabled;
          // hope this is reliable
          app.requiresTokenExchange = !app.auth_url.includes('validic');
          if (app.icon_url.startsWith('http')) {
            app.iconSource = imageUrlToSource(app.icon_url, this._apiV3);
          } else {
            // deal with icons that are local
            if (app.icon_url.includes('mapMyFitness')) {
              app.iconSource = appIcons.mapMyFitness;
            }
            if (app.icon_url.includes('fit')) {
              app.iconSource = appIcons.fit;
            }
            if (app.icon_url.includes('strava')) {
              app.iconSource = appIcons.strava;
            }
            if (app.icon_url.includes('runKeeper')) {
              app.iconSource = appIcons.runKeeper;
            }
            if (app.icon_url.includes('sleepAsAndroid')) {
              app.iconSource = appIcons.sleepAsAndroid;
            }
            if (app.icon_url.includes('walgreens')) {
              app.iconSource = appIcons.walgreens;
            }
            if (app.icon_url.includes('withings')) {
              app.iconSource = appIcons.withings;
            }
            if (app.icon_url.includes('quickbooks')) {
              app.iconSource = appIcons.quickBooks;
            }
          }
        });
      });
      return sections;
    });
  };

  obtainApiTokenForConnectedApp = ({ type, oauthResponseProps }) => {
    const uri = new URI(`/auth/request-access-token/${type}`);
    uri.search(function() {
      return { ...oauthResponseProps, type };
    });
    return this._apiV3.get(uri.toString()).then(response => {
      const something = response.data;
      return something;
    });
  };

  deauthConnectedApp = app => {
    return this._apiV3.get(app.deauthUrl).then(response => {
      const something = response.data;
      return something;
    });
  };
}