import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  fillAvailableSpace: {
    flex: 1,
  },
  horizontalChildrenMaxSpaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  childrenCentered: {
    justifyContent: 'center',
  },
  horizontalChildrenLeftJustified: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  horizontalChildrenRightJustified: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  horizontalChildrenAdjacentSameBaseline: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
  },
  horizontalChildrenCentered: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  verticalChildrenCentered: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  overlayAndStretchAcrossTop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
  },
  shadow: {
    shadowColor: '#000',
    shadowOffset: { width: 1, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 2,
  },
  deepShadow: {
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.15,
    shadowRadius: 10,
    elevation: 15,
  }
});
