import { ImagePicker } from '/common/screens';
import camelcaseKeys from 'camelcase-keys';
import { imageUrlToSource, imageDtoToSource, v3TimestampToJsDate } from './utils';
import { delay } from '/common/lib';

export default class CoachesRepository {
  _apiV3;
  _apiV5;

  constructor({ apiV3, apiV5 }) {
    this._apiV3 = apiV3;
    this._apiV5 = apiV5;
  }

  getCoaches = async () => {
    const response = await this._apiV5.get('/users/me/coaches?messages=HEAD&limit=1000000');
    const summaries = response.data.data;
    if (response.data && !response.data.data) {
      let errorMessage = 'Response does not have a data prop. It is a ' + typeof response.data;
      if (typeof response.data === 'string') {
        errorMessage = errorMessage + response.data;
      }
      throw new Error(errorMessage);
    }
    summaries.forEach(summary => {
      summary.id = summary.id;
      summary.coachId = summary.id;
      summary.firstName = summary.first_name;
      summary.lastName = summary.last_name;
      summary.photoSource = imageUrlToSource(summary.photo_url, this._apiV3);
      summary.lastMessage = summary.coach_messages.length ? summary.coach_messages[0] : null;
    });
    return summaries;
  };

  getCoach = async id => {
    const response = await this._apiV5.get(`/users/me/coaches/${id}`);
    const coach = response.data;
    coach.firstName = coach.firstname;
    coach.lastName = coach.lastname;
    return coach;
  };

  getMessages = async (coachId, offset, startId) => {
    const response = await this._apiV3.get('/messages', {
      params: {
        coach_id: coachId,
        offset,
        start_id: startId,
      },
    });
    const messages = camelcaseKeys(response.data, { deep: true });
    messages.forEach(message => {
      message.attachmentSource = imageDtoToSource(message.image, this._apiV3);
      message.createdAt = v3TimestampToJsDate(message.createdAt);
    });

    /*messages[0].meeting = {
      id: 1,
      created_at: '2020-04-23 11:12:30',
      updated_at: '2020-04-23 11:12:30',
      coach_id: 3,
      uuid: 2147483647,
      topic: 'Test Meeting',
      type: 1,
      instance_uuid: 'AD4uLZp8RKispkbcoPNiSg==',
      start_url:
        'https://zoom.us/s/94559992066?zak=eyJ6bV9za20iOiJ6bV9vMm0iLCJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJjbGllbnQiLCJ1aWQiOiJ2RVRINklnQVFIdVk3aWZhOFdZdE5nIiwiaXNzIjoid2ViIiwic3R5IjoxMDAsIndjZCI6ImF3MSIsImNsdCI6MCwic3RrIjoiNUZRU1dzdjhJbVktWkx2bHFZOTNlby1H',
      joinUrl: 'https://zoom.us/j/94559992066?pwd=TDgraWZaMkd6VS9MazJKQlZLZ3ptUT09',
      status: 'waiting',
      duration: null,
    };*/

    return messages;
  };

  postMessage = async ({ coachId, messageText, image }) => {
    const formData = new FormData();
    if (messageText && messageText.length > 0) {
      formData.append('message', messageText);
    }
    if (image) {
      const resizedImage = await ImagePicker.resizeImage(image);
      formData.append('image', resizedImage);
    }
    const response = await this._apiV5.post('/users/me/messages', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      params: { coaches_id: coachId },
    });
    const message = response.data;
    message.messageInstancesId = message.message_instances_id;
    message.attachmentSource = imageDtoToSource(message.image, this._apiV3);
    message.createdAt = v3TimestampToJsDate(message.created_at);
    return message;
  };

  deleteMessage = async messageId => {
    await this._apiV5.delete(`/users/me/messages/${messageId}`);
  };

  getNoCoachContent = async () => {
    const content = await this._apiV5.get(`users/me/coaches/no-coach-content`);
    return content.data;
  };
}
