import React, { useContext, useCallback } from 'react';
import { View, Pressable, Text } from 'react-native';
import { observer, inject } from 'mobx-react';
import { useNavigation } from '@react-navigation/native';
import { BrandContext } from '/common/config/branding';
import { colors, sizes } from '/common/config/styles';
import useTabBarConfig from './useTabBarConfig';

const BigScreenTab = inject('rootStore')(
  observer(function({ rootStore, tab }) {
    const branding = useContext(BrandContext);
    const navigation = useNavigation();
    const onPress = useCallback(() => {
      navigation.navigate(tab.name);
    }, [tab, navigation]);
    return (
      <Pressable onPress={onPress}>
        <View
          style={{
            paddingVertical: sizes.medium,
            paddingHorizontal: sizes.large * 1.7,
            borderBottomWidth: 2,
            borderBottomColor:
              rootStore.activeFrame === tab.frame ? branding.highlightedColor : colors.bg0,
          }}>
          <tab.options.tabBarIcon height={30} width={40} />
        </View>
      </Pressable>
    );
  })
);

const BigScreenTabBar = observer(function() {
  const tabs = useTabBarConfig();

  return (
    <View
      style={{
        width: '100%',
        backgroundColor: colors.bg0,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottomColor: colors.border1,
        borderBottomWidth: 1,
      }}>
      {tabs.map(tab => (
        <BigScreenTab key={tab.name} tab={tab} />
      ))}
      <Text
        style={{
          width: 36,
          height: 36,
          color: colors.lightText,
          position: 'absolute',
          right: 15,
          top: 22,
        }}>
        beta
      </Text>
    </View>
  );
});

export default BigScreenTabBar;
