import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useScreenBehaviors } from '/common/navigation';
import { NavbarWrapper } from '../../components/navigation';
import { BluetoothPeripheralsList } from '../../components/bluetooth';
import { ScreenFirstLoadWrapper } from '../common';
import { navigationRoutes } from '../../config/constants';

const EditConnectedPeripheralsModal = inject('rootStore')(
  observer(({ navigation, rootStore }) => {
    const { settingsStore } = rootStore;

    useScreenBehaviors({
      onFocus: settingsStore.loadBluetoothPeripherals,
    });

    const onPressPeripheral = useCallback(peripheral => {
      navigation.navigate(navigationRoutes.modals.syncBluetoothDevice, {
        peripheralId: peripheral.peripheralId,
      });
    });

    const { t } = useTranslation();

    return (
      <NavbarWrapper
        title={t('Connected Devices')}
        hasBackButton
        hasBenignConfirmButton
        navigation={navigation}>
        <ScreenFirstLoadWrapper
          state={settingsStore.loadBluetoothPeripheralsState}
          hasData={settingsStore.supportedBluetoothPeripherals.length}
          onPressRefresh={settingsStore.loadBluetoothPeripherals}>
          <BluetoothPeripheralsList
            bluetoothPeripherals={settingsStore.supportedBluetoothPeripherals}
            onPressPeripheral={onPressPeripheral}
          />
        </ScreenFirstLoadWrapper>
      </NavbarWrapper>
    );
  })
);

EditConnectedPeripheralsModal.propTypes = {
  rootStore: PropTypes.object,
};

export default EditConnectedPeripheralsModal;
