import { StyleSheet, Platform } from 'react-native';
import colors from './colors';
import sizes from './sizes';
import { textStyles } from './textStyles';

export default StyleSheet.create({
  // *** Entire table ***

  table: {
    backgroundColor: colors.bg1,
    flex: 1, // fill entire contents of container
  },
  // Message that appears in table when there's no items/ on error
  // not sure why I can't seem to center text vertically here
  noItemsMessage: {
    paddingTop: 50,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    paddingLeft: 40,
    paddingRight: 40,
  },
  noItemsMessageText: {
    color: colors.lightText,
    textAlign: 'center',
  },

  // *** Section Header ***
  // Trying to implement material design guidelines for section headers:
  // https://material.io/guidelines/components/subheaders.html#

  sectionHeader: {
    // Seems odd: we need this background color to keep cells from bleeding behind the header
    backgroundColor: Platform.OS === 'ios' ? colors.bg1 : colors.bg1,
    paddingTop: Platform.OS === 'ios' ? 15 : 15,
    // just a guess on the Android padding - it's large
    paddingBottom: Platform.OS === 'ios' ? 5 : 5,
    paddingLeft: sizes.medium,
  },
  sectionHeaderText: textStyles.small.light,
  emptySectionHeader: {
    backgroundColor: colors.bg1,
    paddingTop: 15,
  },

  // *** between cells/ sections ***

  itemSeparator: {
    borderBottomColor: colors.border1,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  // A cell with a standard look that is not fixed-height.
  // All cells in a table that use this style may still end up the
  // same height by virtue of how the content is displayed.
  variableHeightCell: {
    paddingVertical: sizes.small,
    paddingHorizontal: sizes.small,
    backgroundColor: colors.bg0,
  },
});
