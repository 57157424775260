import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash';
import { reaction } from 'mobx';
import { useIsFocused } from '@react-navigation/native';

// REPLACES COMMON MOBILE LIB COMPONENT OF THE SAME NAME!

/**
 * A component that can be added anywhere in the screen that will need to execute an action when
 * a) a watched value changes, and
 * b) the screen containing the component is focused.
 * This can be used to trigger refreshes only when a screen is focused. Very useful for contextually refreshing based
 * on a pusher message.
 */
class TriggerActionOnUpdateWhenFocused extends Component {
  componentDidMount() {
    const { getWatchValue } = this.props;
    this.onUpdateActionDisposer = reaction(getWatchValue, this._throttledTriggerAction);
  }

  componentWillUnmount() {
    if (this.onUpdateActionDisposer) {
      this.onUpdateActionDisposer();
    }
  }

  _triggerAction = watchPropNewValue => {
    const { triggerWhen, isFocused, onTrigger } = this.props;

    if (isFocused && triggerWhen(watchPropNewValue)) {
      onTrigger(watchPropNewValue);
    }
  };

  _throttledTriggerAction = throttle(this._triggerAction, this.props.throttleMs, {
    leading: true,
  });

  render() {
    return null;
  }
}

TriggerActionOnUpdateWhenFocused.propTypes = {
  // function that returns a mobx observable
  // when value changes and triggerWhen is true,
  // onTrigger is executed
  // prop cannot change after mounting or else trigger will not work
  getWatchValue: PropTypes.func.isRequired,
  isFocused: PropTypes.bool.isRequired,
  throttleMs: PropTypes.number,
  // function that accepts the updated value of the watchField and returns true if that value should result in the action
  triggerWhen: PropTypes.func,
  // action to execute if the screen is focused and triggerWhen() returns true
  onTrigger: PropTypes.func,
};

TriggerActionOnUpdateWhenFocused.defaultProps = {
  throttleMs: 500,
  onTrigger: () => {},
  triggerWhen: () => true,
};

// non-decorated version for testing
export { TriggerActionOnUpdateWhenFocused };

export default function(props) {
  const isFocused = useIsFocused();
  return <TriggerActionOnUpdateWhenFocused {...props} isFocused={isFocused} />;
}
