import React, { useContext } from 'react';
import {
  Text,
  View,
  TouchableWithoutFeedback as Touchable,
  StyleSheet,
  Platform,
  Image,
} from 'react-native';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { CircleThumbnail } from '/common/components';
import { take } from 'lodash';
import { BrandContext } from '/common/config/branding';
import { textStyles, sizes, colors, fonts, containerStyles } from '/common/config/styles';
import { fullName } from '/common/config/strings';
import LeaderboardValue from '../leaderboard/LeaderboardValue';

/**
 * The leader for the in-feed leaderboard.
 * Fonts and thumbnail sizes are smaller here compared to the main leaderboard.
 */
const Leader = observer(({ leader, type, uom, showName, badgeColor }) => (
  <View
    style={[
      containerStyles.horizontalChildrenCentered,
      {
        paddingVertical: 15,
        borderTopColor: colors.border1,
        borderTopWidth: showName ? StyleSheet.hairlineWidth : 0,
      },
    ]}>
    <View style={{ marginRight: 8 }}>
      <CircleThumbnail source={leader.photoUrl} diameter={26} altTitle={fullName(leader)} />
    </View>
    <LeaderboardValue leader={leader} uom={uom} valueStyle={[textStyles.small.bold]} isInFeed />
    {showName && (
      <View style={[containerStyles.horizontalChildrenCentered, { marginLeft: sizes.large }]}>
        <Text style={[textStyles.small.light, { marginRight: 5 }]}>Place</Text>
        <BrandContext.Consumer>
          {branding => (
            <Text style={[textStyles.small.bold, { color: branding.highlightedColor }]}>
              {leader.rank}
            </Text>
          )}
        </BrandContext.Consumer>
      </View>
    )}
  </View>
));

/**
 * Leaderboard that fits within the main group chat feed.
 */
const InFeedLeaderboard = observer(({ leaderboard, onPress, isLeaderboardJoined, t }) => {
  // there's conditions due to how this loads as a SectionList item where the leaderboard can end up being null due to being removed (maybe)
  // need to investigate more, but for now lets just not render if this happens
  if (!leaderboard) {
    return null;
  }
  const branding = useContext(BrandContext);
  const top3Leaders = take(leaderboard.leadersSorted, 3);

  // remove the user from the list if the user isn't joined to the leaderboard
  let filteredLeaders = top3Leaders;
  if (!isLeaderboardJoined) {
    filteredLeaders = filteredLeaders.filter(
      leader => !leaderboard.position || leader.id !== leaderboard.position.id
    );
  }

  return (
    <View style={{ flex: 1 }}>
      <Touchable style={{ flex: 1 }} onPress={onPress}>
        <View
          style={[
            containerStyles.shadow,
            {
              flex: 1,
              marginHorizontal: sizes.medium,
              marginVertical: sizes.small,
              padding: sizes.medium,
              paddingBottom: 0,
              borderRadius: sizes.medium,
              backgroundColor: colors.bg0,
              borderWidth: Platform.OS === 'ios' ? StyleSheet.hairlineWidth : 0,
              borderColor: 'rgba(0,0,0,.18)',
              minHeight: 54,
              elevation: 1,
            },
          ]}>
          <View style={[containerStyles.horizontalChildrenCentered, { marginBottom: sizes.small }]}>
            {/* <LeaderboardIcon /> */}
            <Text style={[textStyles.standard.small, { fontWeight: fonts.weights.medium }]}>
              {leaderboard.description} {t('COMMUNITY:LEADERBOARD:TITLE')}
            </Text>
            <Image
              source={require('/common/assets/icons/chevron-right.png')}
              style={{
                width: 15,
                height: 15,
                tintColor: colors.darkText,
                marginLeft: 5,
              }}
            />
          </View>
          {filteredLeaders.length === 0 && (
            <Text
              style={[
                textStyles.standard.small,
                {
                  textAlign: 'center',
                  paddingBottom: sizes.medium,
                  color: branding.highlightedColor,
                },
              ]}>
              {isLeaderboardJoined
                ? t('COMMUNITY:LEADERBOARD:CHECK_SOON_FOR_LEADERS')
                : t('COMMUNITY:LEADERBOARD:NO_LEADERS')}
            </Text>
          )}
          <View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
            {filteredLeaders.map(leader => (
              <Leader
                key={leader.id}
                leader={leader}
                uom={leaderboard.metricType.unit}
                type={leaderboard.metricType.type}
              />
            ))}
          </View>
          {isLeaderboardJoined && leaderboard.position && !leaderboard.isUserInTop3 ? (
            <View>
              <Leader
                key={leaderboard.position.id}
                leader={leaderboard.position}
                uom={leaderboard.metricType.unit}
                type={leaderboard.metricType.type}
                badgeColor={branding.highlightedColor}
                showName
              />
            </View>
          ) : null}
        </View>
      </Touchable>
    </View>
  );
});

InFeedLeaderboard.propTypes = {
  leaderboard: PropTypes.object,
  onPress: PropTypes.func.isRequired,
  isLeaderboardJoined: PropTypes.bool.isRequired,
  t: PropTypes.any.isRequired,
};

InFeedLeaderboard.defaultProps = {
  leaderboard: null,
};

export default withTranslation()(InFeedLeaderboard);
