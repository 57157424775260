import React from 'react';
import { View, TouchableWithoutFeedback as Touchable, Text } from 'react-native';
import PropTypes from 'prop-types';

/**
 * A table row that launches an action when pressed.
 */
const PickerItem = ({ onPress, style, disabled, icon, title, titleStyle }) => (
  <Touchable disabled={disabled} onPress={onPress}>
    <View
      style={[
        {
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flex: 1,
        },
        style,
      ]}>
      {icon}
      <Text style={titleStyle} ellipsizeMode="tail" numberOfLines={1}>
        {title}
      </Text>
    </View>
  </Touchable>
);

PickerItem.propTypes = {
  onPress: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  style: PropTypes.any,
  titleStyle: PropTypes.any,
  title: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
};

PickerItem.defaultProps = {
  disabled: false,
  style: null,
  titleStyle: null,
};

export default PickerItem;
