import Constants from 'expo-constants';
import { Platform } from 'react-native';

function delay(t, v) {
  return new Promise(function(resolve) {
    setTimeout(resolve.bind(null, v), t);
  });
}

const requestScreenshot = ({ brand, scene }) => {
  // don't make multiple lines- the regex doesn't pick it up yet
  // Maybe do this: https://stackoverflow.com/questions/28889767/javascript-regex-to-match-multiple-lines
  console.log(
    `<takescreen><device>${Constants.deviceName}</device><platform>${Platform.OS}</platform><brand>${brand}</brand><scene>${scene}</scene></takescreen>`
  );
};

export { delay, requestScreenshot };
