import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { View, Image, Platform } from 'react-native';
import { WebView } from 'react-native-webview';
import { StatusBar } from 'expo-status-bar';
import { withAlert } from '/common/navigation';
import { containerStyles } from '/common/config/styles';
import { BrandContext } from '../../config/branding';

// AHHHHHHH https://stackoverflow.com/a/7772724/3884863
function isString(s) {
  return typeof s === 'string' || s instanceof String;
}

const getFinalHtml = (body, brandColor) => {
  return `<html>
  <head>
    <style type="text/css">
    body, .btn, input {
      margin: 0;
      font-weight: normal;
      font-style: normal;
      font: 14px/normal "Helvetica Neue", Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased; }
      #content-no-coaches {
        top: 0px;
        width: 100%;
        text-align: center;
        background-size: 100%;
        background-color: transparent !important;
        z-index: 8; }
      .content-momentum-scrolling {
        padding-top: 0px;
        position: fixed;
        top: 61px;
        top: calc(41px + constant(safe-area-inset-top));
        top: calc(41px + env(safe-area-inset-top));
        bottom: 0;
        overflow-y: scroll;
        overflow-x: hidden; }
      .txt-white, a.txt-white:link, a.txt-white:visited, a.txt-white:hover, a.txt-white:active {
          color: white !important; }
      .padding-top-medium {
            padding-top: 14px; }
      .txt-large {
        font-weight: 300;
        line-height: 32px;
        font-size: 28px;
      }
      .txt-medium {
        font: 20px/normal HelveticaNeue-Light, "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: 300; }
      .spacing-top-medium {
        margin-top: 14px; }
      .horizontal-spacing-large {
        margin-left: 24px;
        margin-right: 24px; }
      .padding-medium {
        padding: 14px; }
      .padding-large {
        padding: 24px; }
      .padding-bottom-medium {
        padding-bottom: 14px; }
      .padding-bottom-large {
        padding-bottom: 24px;
      }
      .bg-black {
        background-color: #212121 !important; }
      .horizontal-spacing-medium {
        margin-left: 14px;
        margin-right: 14px; }
      .btn-large {
        padding-top: 12px;
        padding-bottom: 8px;
        height: 45px; }
      .btn {
        background-image: none;
        background-color: transparent;
        border: none;
        box-shadow: none;
        text-shadow: none;
        padding: 8px 10px 8px; }
      .btn-rounded {
        border-radius: 45px;
        padding: 2px 16px 2px; }
      .btn-stroke {
        border: 1px solid #D8D7D9; }
      .txt-bold {
        font-weight: bold; }
      .spacing-bottom-medium {
        margin-bottom: 14px; }
      .bg-highlighted, .btn-primary {
        background-color: ${brandColor} !important;
      }
      button {
        outline: none;
      }
      .margin-large {
        margin: 24px;
      }
      .txt-highlighted {
        color: ${brandColor}
      }
        
    </style>
    <meta name="viewport" content="width=device-width, height=device-height, maximum-scale=1.0, minimum-scale=1.0, initial-scale=1.0, user-scalable=no, viewport-fit=cover">
  </head>
  <body>${body.replace(/ng-click/g, 'onclick')}</body></html>`;
};

// https://github.com/react-native-web-community/react-native-web-webview/issues/55
// On react native for web react-native-web-webview (which we're alising to WebView in our webpack config)
// doesn't have a ReactNativeWebView. Rather, the WebVeiew component just sets at window.parent
// Add a fallback to window.postMessage if there's no parent but this most likely won't get intercepted by the onMessage prop
const injectedJavaScript = `
  const postMessage = function(payload) {
    const message = JSON.stringify(payload);
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(message);
    } else if (window.parent.postMessage) {
      window.parent.postMessage(message);
    } else {
      window.postMessage(message);
    }
  };

  window.openURL = function(url) {
    var payload = { action: 'openUrl', data: url};
    postMessage(payload);
  }
  window.openPromoCodeModal = function() {
    var payload = { action: 'openPromoCodeModal' };
    postMessage(payload);
  }

  window.moreInfoClicked = function() {
    var payload = { action: 'moreInfoClicked' };
    postMessage(payload);
  }

  window.phoneAlert = function(data) {
    var payload = { action: 'showAlert', data: data };
    postMessage(payload);
  }
  true;
`;

@observer
class NoCoach extends Component {
  /*componentDidMount() {
    // Workaround for https://github.com/react-native-community/react-native-webview/issues/735#issuecomment-
    // On iOS, the presence of this webview causes the statusbar to turn to light content whenever the keyboard goes up anywhere
    if (Platform.OS === 'ios') {
      Keyboard.addListener('keyboardWillShow', this.onKeyboardShow);
      StatusBar.setBarStyle('dark-content');
    }
  }

  componentWillUnmount() {
    if (Platform.OS === 'ios') {
      Keyboard.removeListener('keyboardWillShow', this.onKeyboardShow);
      StatusBar.setBarStyle('dark-content');
    }
  }

  onKeyboardShow = () => {
    StatusBar.setBarStyle('dark-content');
  };*/

  static contextType = BrandContext;

  _onMessageFromBrowser = event => {
    const branding = this.context;
    console.log(event.nativeEvent.data);
    if (!event.nativeEvent.data || !isString(event.nativeEvent.data)) {
      return;
    }
    const messageObj = JSON.parse(event.nativeEvent.data);
    console.log(messageObj);
    if (messageObj.action === 'openUrl') {
      if (messageObj.data.startsWith('http') || messageObj.data.startsWith('https')) {
        this.props.onPressLink(messageObj.data, {
          useInAppBrowser: branding.connectScreen.useInAppBrowserForLinks,
        });
      } else {
        this.props.onPressLink(messageObj.data);
      }
    }
    if (messageObj.action === 'openPromoCodeModal') {
      this.props.onPressEnterCode();
    }
    if (messageObj.action === 'moreInfoClicked') {
      this.props.onPressLink(branding.coach.linkUrl, {
        useInAppBrowser: branding.connectScreen.useInAppBrowserForLinks,
      });
    }
    if (messageObj.action === 'showAlert') {
      this.props.alert(messageObj.data.title, messageObj.data.message);
    }
  };

  render() {
    const { noCoachPageContent } = this.props;
    const branding = this.context;

    return (
      <View style={[containerStyles.fillAvailableSpace]}>
        {/* try to fix issue with inviteID modal changing status bar by adding status bar fixing code that we use in NavWrapper here. This is the only screen that doesn't have a NavbarWrapper (basically) */ Platform.OS ===
        'ios' ? (
          <StatusBar hidden={false} />
        ) : (
          <StatusBar hidden={false} translucent style="dark" />
        )}
        {branding.assets.coachBackground && (
          <Image
            style={[
              {
                flex: 1,
                //resizeMode: 'stretch',
                position: 'absolute',

                justifyContent: 'center',
              },
              branding.coachBackgroundAsLogo
                ? {
                    resizeMode: 'contain',
                    width: '80%',
                    alignSelf: 'center',
                  }
                : {
                    width: '100%',
                    height: '100%',
                  },
            ]}
            source={branding.assets.coachBackground}
          />
        )}
        {noCoachPageContent && (
          <WebView
            style={{
              backgroundColor: 'transparent',
            }}
            source={{
              html: getFinalHtml(noCoachPageContent, branding.highlightedColor),
              baseUrl: '' /* Galaxy S will not render page without this! */,
            }}
            originWhitelist={['*']}
            scrollEnabled={false}
            injectedJavaScript={injectedJavaScript}
            onMessage={this._onMessageFromBrowser}
          />
        )}
      </View>
    );
  }
}

NoCoach.propTypes = {
  onPressEnterCode: PropTypes.func.isRequired,
  noCoachPageContent: PropTypes.string,
  onPressLink: PropTypes.func.isRequired,
};

NoCoach.defaultProps = {
  noCoachPageContent: null,
};

export default withAlert(NoCoach);
