import React, { Component } from 'react';
import { View, Text, TouchableWithoutFeedback } from 'react-native';
import PropTypes from 'prop-types';
import Header from './Header';
import { textStyles, sizes, colors } from '../../config/styles';
import { BrandContext } from '../../config/branding';

class NavBarTab extends Component {
  static contextType = BrandContext;
  render() {
    const { title, onPress, isSelected } = this.props;
    const branding = this.context;
    return (
      <TouchableWithoutFeedback onPress={onPress}>
        <View
          style={[
            {
              flex: 1,
              justifyContent: 'center',
              height: sizes.navbarBottomControlHeight,
            },
            isSelected && {
              borderBottomColor: branding.highlightedColor,
              borderBottomWidth: 1,
            },
          ]}>
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Text
              style={[
                textStyles.small.dark,
                { color: isSelected ? colors.darkText : colors.lightText },
              ]}>
              {title ? title.toUpperCase() : ''}
            </Text>
          </View>
        </View>
      </TouchableWithoutFeedback>
    );
  }
}

export default class NavBarWithTabs extends Component {
  render() {
    const { tabs, selectedKey, ...otherProps } = this.props;

    return (
      <Header
        style={{ borderBottomWidth: 0 }}
        bottomControl={
          tabs.length > 1 ? (
            <View style={{ flexDirection: 'row' }}>
              {tabs.map(tab => (
                <NavBarTab
                  key={tab.key}
                  title={tab.title}
                  onPress={tab.onPress}
                  isSelected={selectedKey === tab.key}
                />
              ))}
            </View>
          ) : null
        }
        {...otherProps}
      />
    );
  }
}

NavBarWithTabs.propTypes = {
  title: PropTypes.string.isRequired,
  theme: PropTypes.object,
  navigation: PropTypes.any.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      onPress: PropTypes.func.isRequired,
      key: PropTypes.any.isRequired,
    })
  ),
  selectedKey: PropTypes.any.isRequired,
};

NavBarWithTabs.defaultProps = {
  theme: {},
};
