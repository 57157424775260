import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Text, Platform } from 'react-native';
import { observer } from 'mobx-react';

@observer
export default class BadgeIcon extends Component {
  render() {
    const {
      badgeText,
      badgeColor,
      badgeTextColor,
      icon,
      iconStyle,
      anchor,
      hideNumber,
    } = this.props;

    return (
      <View>
        <View style={[iconStyle]}>{icon}</View>
        <View
          style={{
            position: 'absolute',
            // Android doesn't let these buttons overlap the overlay container
            top: -12,
            right: anchor === 'right' ? -10 : undefined,
            left: anchor === 'left' ? -10 : undefined,
            width: 34, // allows the icon to expand wider
            flexDirection: 'row',
            justifyContent: anchor === 'right' ? 'flex-end' : 'flex-start',
          }}>
          {badgeText && badgeText > 0 ? (
            <View
              style={{
                backgroundColor: badgeColor,
                height: 20,
                minWidth: 20,
                borderRadius: 10,
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <Text
                allowFontScaling={false}
                style={{
                  fontSize: 12 /* manual font size, requires explicit scaling disable for android */,
                  color: badgeTextColor,
                  marginHorizontal: 4,
                  fontWeight: 'bold',
                  textAlign: 'center',
                  // centering stuff is an imperfect art
                  paddingBottom: Platform.OS === 'android' || hideNumber ? 1 : undefined,
                }}>
                {hideNumber ? '•' : badgeText}
              </Text>
            </View>
          ) : null}
        </View>
      </View>
    );
  }
}

BadgeIcon.propTypes = {
  badgeText: PropTypes.any,
  badgeColor: PropTypes.string,
  badgeTextColor: PropTypes.string,
  icon: PropTypes.any.isRequired,
  iconStyle: PropTypes.any,
  badgeDiameter: PropTypes.number,
  anchor: PropTypes.oneOf(['left', 'right']),
  // don't actually show the number, just show a dot
  hideNumber: PropTypes.bool,
};

BadgeIcon.defaultProps = {
  badgeText: null,
  badgeColor: 'red',
  badgeTextColor: 'white',
  badgeDiameter: 22,
  iconStyle: null,
  anchor: 'right',
  hideNumber: false,
};
