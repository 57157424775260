import React, { useContext } from 'react';
import { Text, View } from 'react-native';
import ParsedText from 'react-native-parsed-text';
import { observer } from 'mobx-react';
import { textStyles } from '/common/config/styles';
import { BrandContext } from '/common/config/branding';
import cardConstants from '../cardConstants';

function renderParsedText(matchingString) {
  if (!matchingString) {
    return '';
  }
  if (matchingString.length > 60) {
    return matchingString.substring(0, 57) + '...';
  }
  return matchingString;
}

const CardBulletComponent = observer(function({ onPressLink, component }) {
  const branding = useContext(BrandContext);
  const parseTypes = [
    {
      type: 'url',
      style: [textStyles.link, { color: branding.highlightedColor }],
      onPress: onPressLink,
      renderText: renderParsedText,
    },
  ];

  const bulletSize = 7;

  return (
    <View style={{ flexDirection: 'row' }}>
      <View
        style={{
          width: bulletSize,
          height: bulletSize,
          borderRadius: bulletSize,
          backgroundColor: branding.highlightedColor,
          marginTop: 9,
          marginRight: 10,
        }}
      />
      <Text
        key={component.id.toString()}
        ellipsizeMode={'tail'}
        style={[
          {
            marginBottom: cardConstants.elementSpacing,
            lineHeight: 22,
            marginRight: 10,
          },
        ]}>
        <ParsedText parse={parseTypes} style={textStyles.standard.light}>
          {component.body}
        </ParsedText>
      </Text>
    </View>
  );
});

export default CardBulletComponent;
