import * as ExpoSecureStore from 'expo-secure-store';
import { Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

// SecureStore wrapper that includes fallback to AsyncStorage for Android 5.
// We don't store actual credentials (just session tokens), so this should not be a big deal.
export default class SecureStore {
  static async getItemAsync(key) {
    if (
      (Platform.OS === 'android' && Platform.Version >= 21 && Platform.Version <= 22) ||
      Platform.OS === 'web'
    ) {
      return await AsyncStorage.getItem(key);
    }
    return await ExpoSecureStore.getItemAsync(key);
  }

  static async setItemAsync(key, value) {
    if (
      (Platform.OS === 'android' && Platform.Version >= 21 && Platform.Version <= 22) ||
      Platform.OS === 'web'
    ) {
      return await AsyncStorage.setItem(key, value);
    }
    return await ExpoSecureStore.setItemAsync(key, value);
  }
}
