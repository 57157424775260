// *** nudge colors ***

const error = '#FC423E';
const brandDarker = '#088cbb';

// Backgrounds
const bg0 = '#FFFFFF';
const bg1 = '#EDEDED';
const bg2 = '#D9D9D9'; // needed as a slightly darker gray for buttons with white text, etc.
const green = '#66BB66';
const red = '#EE4444';
const yellow = '#FEDF63';
const orange = '#FF7744';
const warning = yellow;
const lightText = '#666666';
const gray = '#888';
const border = '#D9D9D9';
const darkText = '#000';
const placeholderText = '#7A666666'; /* light text at 48% opacity */

//Outlines
const border0 = bg1; //'#F6F6F6'; // maybe same as bg1?
const border1 = '#C9C9C9'; // used as a darker multiple choice field border, a few other places.
const border2 = '#F6F6F6';
const border3 = '#727272';

export default {
  // *** colors from Nudge ***
  lightText,
  darkText,
  placeholderText,
  bg0,
  bg1,
  bg2,
  border0,
  border1,
  border2,
  border3,
  border,
  gray,
  brandDarker,
  error,
  green,
  yellow,
  orange,
  red,
  warning,
};
