import React, { useState, useImperativeHandle, forwardRef, useCallback } from 'react';
import { TouchableOpacity, View, Platform } from 'react-native';
import Modal from 'react-native-modal';
import { observer, inject } from 'mobx-react';
import { sizes } from '/common/config/styles';
import { CloseModalIcon } from '../config/icons';
import VideoPlayer from './VideoPlayer';

const VideoPlayerModal = inject('rootStore')(
  observer(
    forwardRef(function(props, ref) {
      const [isModalVisible, setModalVisible] = useState(false);
      const [videoInfo, setVideoInfo] = useState(null);

      useImperativeHandle(ref, () => ({
        // show the image and optionally pass metadata about the source
        // for group messaging, this will be the current message
        // and will be used for navigating to the individual topic
        show: videoInfo => {
          setVideoInfo(videoInfo);
          setModalVisible(true);
          if (Platform.OS === 'web') {
            // add window history when modal is open so back button works
            window.history.pushState({}, '', '/video');
          }
          props.rootStore.logErrorReportingBreadcrumb({
            category: 'videoUrlOpened',
            message: videoInfo.url,
          });
        },
      }));

      const onPressClose = useCallback(() => {
        setModalVisible(false);
        console.log('on press close');
      });

      // close the video modal when broswer back button is hit
      if (Platform.OS === 'web') {
        window.addEventListener('popstate', () => setModalVisible(false));
      }

      return (
        <Modal
          isVisible={isModalVisible}
          style={{
            margin: 0,
          }}
          animationIn="fadeIn"
          animationOut="fadeOut"
          hasBackdrop={false}
          hideModalContentWhileAnimating>
          {videoInfo ? (
            <VideoPlayer
              url={videoInfo.url}
              provider={videoInfo.provider}
              meta={videoInfo.meta}
              leftButton={
                <TouchableOpacity
                  onPress={onPressClose}
                  style={{
                    marginLeft: sizes.medium,
                    width: 48,
                    height: 48,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1,
                  }}>
                  <CloseModalIcon />
                </TouchableOpacity>
              }
            />
          ) : (
            <View />
          )}
        </Modal>
      );
    })
  )
);

export default VideoPlayerModal;
