import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Text, Image, Dimensions } from 'react-native';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { navigationRoutes } from '../../../../config/constants';
import { RoundButton } from '../../../../components/common';
import { containerStyles, textStyles } from '../../../../config/common-styles';
import { BrandContext } from '../../../../config/branding';

const { width } = Dimensions.get('window');

@observer
class NoGroupsView extends Component {
  static contextType = BrandContext;

  componentDidMount() {
    this._unsubscribeDidFocusListener = this.props.navigation.addListener('didFocus', () => {
      // attempt reload when coming back into focus after, say, adding a group
      this.props.rootStore.socialStore.loadClubs();
    });
  }

  componentWillUnmount() {
    if (this._unsubscribeDidFocusListener) {
      this._unsubscribeDidFocusListener();
    }
  }

  _onPressEnterGroup = () => {
    this.props.navigation.navigate(navigationRoutes.modals.enterPromoCode);
  };

  render() {
    const branding = this.context;

    return (
      <View
        style={[
          containerStyles.fillAvailableSpace,
          {
            backgroundColor: 'white',
            justifyContent: 'flex-start',
            alignItems: 'center',
          },
        ]}>
        <Image
          source={require('../../../../assets/no-group.jpg')}
          style={[containerStyles.fillAvailableSpace, { width }]}
        />
        <View style={{ position: 'absolute', top: '50%' }}>
          <Text style={[textStyles.bold.dark, { padding: 20 }]}>Access your communities</Text>
          <RoundButton
            title={this.props.t('SETTINGS:ENTER_AN_INVITE_ID', {
              inviteIdName: branding.inviteIdDisplay.name,
              article: branding.inviteIdDisplay.article,
            })}
            onPress={this._onPressEnterGroup}
          />
        </View>
      </View>
    );
  }
}

NoGroupsView.propTypes = {
  navigation: PropTypes.object,
  t: PropTypes.any.isRequired,
};

export default withTranslation()(NoGroupsView);
