/**
 * Wrapper for top-level navigator ref to make it behave like a basic navigator,
 * giving access to navigation in the RootStore and other places outside a navigator.
 * See https://reactnavigation.org/docs/en/4.x/navigating-without-navigation-prop.html
 */
import { CommonActions } from '@react-navigation/native';

let _navigator;

function setTopLevelNavigator(navigatorRef) {
  _navigator = navigatorRef;
}

function isNavigationAvailable() {
  return !!_navigator;
}

function navigate(routeName, params) {
  _navigator.dispatch(
    /*NavigationActions.navigate({
      routeName,
      params,
    })*/
    CommonActions.navigate({
      name: routeName,
      params,
    })
  );
}

function getState() {
  return _navigator.state;
}

function goBack() {
  // this is weird, but works for the SignUpWorkflow, where we use goBack a lot
  _navigator.goBack();
}

// add other navigation functions that you need and export them

export default {
  navigate,
  setTopLevelNavigator,
  isNavigationAvailable,
  getState,
  goBack,
};
