import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { when } from 'mobx';
import { OnboardingWrapUpInfo } from '../../components/sign-up';
import { SimpleNavWrapper } from '../../components/navigation';
import { LoadingShadeWrapper } from '../../components/common';
import { containerStyles } from '../../config/common-styles';
import { navigationRoutes } from '../../config/constants';

const WrapUpScreen = inject('rootStore')(
  observer(({ rootStore }) => {
    useEffect(() => {
      // the app starts checking for OTA updates immediately after login
      // When an update is ready (or there is an error), otaUpdateStatus will be populated
      // This when() will wait up to 7 seconds to see if it's populated, and attempt a conditional reload once it's done.
      return when(
        () => rootStore.otaUpdateStatus,
        async () => {
          await rootStore.reloadIfOtaUpdateAvailable();
          await rootStore.markOnboardingStepComplete(navigationRoutes.stacks.onboarding.wrapUp);
        },
        {
          fireImmediately: true,
          timeout: 7000,
          onError: () => {
            // this should be triggered after the 7 second timeout
            rootStore.markOnboardingStepComplete(navigationRoutes.stacks.onboarding.wrapUp);
          },
        }
      );
    }, []);

    return (
      <SimpleNavWrapper>
        <LoadingShadeWrapper
          coversOnboardingScreen
          isLoading={rootStore.onboardingActionState.isPending}
          style={containerStyles.fillAvailableSpace}>
          <OnboardingWrapUpInfo />
        </LoadingShadeWrapper>
      </SimpleNavWrapper>
    );
  })
);

WrapUpScreen.propTypes = {
  navigation: PropTypes.object,
};

export default WrapUpScreen;
