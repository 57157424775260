import React from 'react';
import { View, TouchableWithoutFeedback, Text } from 'react-native';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { sizes, colors, textStyles } from '/common/config/styles';
import { useTranslation } from 'react-i18next';

const SegmentedControlItem = observer(({ title, segmentStyle, onChooseOption, isSelected }) => {
  return (
    <TouchableWithoutFeedback
      style={{
        padding: sizes.small,
        borderRadius: sizes.small,
      }}
      onPress={onChooseOption}>
      <View
        style={[
          segmentStyle,
          {
            padding: sizes.small / 2,
            paddingHorizontal: sizes.medium,
            borderRadius: sizes.borderRadius,
            shadowColor: '#000',
            shadowOffset: { width: 1, height: 1 },
            shadowOpacity: isSelected ? 0.2 : 0,
            shadowRadius: 1,
            zIndex: isSelected ? 2 : 0,
            cursor: 'pointer',
          },
          isSelected ? { backgroundColor: colors.bg0 } : { backgroundColor: colors.bg1 },
        ]}>
        <Text allowFontScaling={false} style={[textStyles.standard.dark]}>
          {title}
        </Text>
      </View>
    </TouchableWithoutFeedback>
  );
});

const ProgramFeedSegmentedControl = observer(
  ({ selectedOption, onChooseOption, containerStyle }) => {
    const { t } = useTranslation();
    return (
      <View
        style={[
          {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: sizes.borderRadius,
            borderColor: colors.border,
            borderWidth: sizes.hairlineWidth * 2,
            backgroundColor: colors.bg1,
          },
          containerStyle,
        ]}>
        <SegmentedControlItem
          title={t('PROGRAMS:SEGMENTED_CONTROL:FEED')}
          onChooseOption={() => onChooseOption(0)}
          isSelected={selectedOption === 0}
        />
        <SegmentedControlItem
          title={t('PROGRAMS:SEGMENTED_CONTROL:TAGS')}
          onChooseOption={() => onChooseOption(1)}
          isSelected={selectedOption === 1}
        />
      </View>
    );
  }
);

ProgramFeedSegmentedControl.propTypes = {
  selectedOption: PropTypes.number,
  onChooseOption: PropTypes.func.isRequired,
  containerStyle: PropTypes.any,
};

ProgramFeedSegmentedControl.defaultProps = {
  containerStyle: null,
  selectedOption: null,
};

export default ProgramFeedSegmentedControl;
