import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Text, View } from 'react-native';
import FieldContainer from './FieldContainer';
import { textStyles, colors, sizes } from '/common/config/styles';

@observer
class ReadOnlyField extends Component {
  render() {
    const { title, style, value, textStyle } = this.props;
    // isRequired just puts the heading on the field. Should probably rename it
    return (
      <FieldContainer style={style} title={title} isRequired>
        <View
          style={{
            flexGrow: 1,
            flexDirection: 'row',
            alignItems: 'center',
            padding: 10,
            borderRadius: sizes.borderRadius,
            backgroundColor: colors.bg1,
          }}>
          <Text
            style={[
              textStyles.standard.dark,
              {
                flex: 1,
                textAlign: 'left',
              },
              textStyle,
            ]}>
            {value}
          </Text>
        </View>
      </FieldContainer>
    );
  }
}

ReadOnlyField.propTypes = {
  style: PropTypes.any,
  title: PropTypes.string,
  value: PropTypes.any,
};

ReadOnlyField.defaultProps = {
  style: null,
  title: null,
  value: null,
};

export default ReadOnlyField;
