import * as Notifications from 'expo-notifications';
import { NativeModules, Platform } from 'react-native';

function isNotificationPermissionGranted(status) {
  return (
    status.granted ||
    (status.ios &&
      (status.ios.status === Notifications.IosAuthorizationStatus.PROVISIONAL ||
        status.ios.status === Notifications.IosAuthorizationStatus.AUTHORIZED ||
        status.ios.status === Notifications.IosAuthorizationStatus.EPHEMERAL))
  );
}

export default class AuthenticationRepository {
  _apiV3;
  _apiV4;
  _apiV5;
  _apiTokenManager;

  constructor({ apiV3, apiV4, apiV5, apiTokenManager, supportApi }) {
    this._apiV3 = apiV3;
    this._apiV4 = apiV4;
    this._apiV5 = apiV5;
    this._supportApi = supportApi;
    this._apiTokenManager = apiTokenManager;
  }

  login = async (username, password) => {
    const response = await this._apiV3.post('/login', { email: username, password });
    await this._apiTokenManager.setApiToken(response.data.token);
    return response.data;
  };

  requestPasswordReset = async username => {
    await this._apiV5.post(
      'user/password/email',
      {
        email: username,
      },
      {
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      }
    );
  };

  logout = async () => {
    await this._apiV5.delete('/mobile/token/auth');
  };

  /**
   * Login with SSO params, set token, then return data indicating if this is a new user.
   */
  loginWithSso = async ({ service, clientId, code }) => {
    const response = await this._apiV4.post('/sso', { service, clientId, code });
    await this._apiTokenManager.setApiToken(response.data.token);
    return { isNewUser: response.data.is_new_user };
  };

  isPushNotificationPermissionGranted = async () => {
    const result = await Notifications.getPermissionsAsync();
    // this seems to be always returning false, at least on Expo 38, need to investigate
    // This is pretty innoculous for now, since it just means that the push notification always shows during signup onboarding.
    return isNotificationPermissionGranted(result);
  };

  registerPushNotificationToken = async (askIfNeeded = false) => {
    if (Platform.OS === 'web') {
      // suppress notification prompt on safari
      return;
    } else if (Platform.OS === 'android' && Platform.Version >= 33) {
      //On Android 33+ we need to explicitly set a notification channel
      await Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
      });
    }
    let currentStatus = await Notifications.getPermissionsAsync();
    // only ask if permissions have not already been determined, because
    // iOS won't necessarily prompt the user a second time.
    // At least on the Expo app, iOS will return 'undetermined' if push notifications have
    // been turned off in settings.
    if (!isNotificationPermissionGranted(currentStatus) && askIfNeeded) {;
      currentStatus = await Notifications.requestPermissionsAsync();
    }
    let pushToken;
    if (isNotificationPermissionGranted(currentStatus)) {
      // just looking to see if this is Expo client vs native app
      if (NativeModules.RNDeviceInfo) {
        try {
          pushToken = await Notifications.getDevicePushTokenAsync();
          console.log(pushToken);
        } catch (error) {
          console.log('error registering for push notifications');
          console.log(error);
        }
      } else {
        console.warn(
          'WARNING: Not registering for push notifications. This is OK if you are running in the Expo client. Very bad if not!'
        );
      }
    }
    //console.log(pushToken);
    if (pushToken) {
      try {
        await this._apiV4.post('/users/me/push-tokens', {
          platform: Platform.OS,
          token: pushToken.data,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  deRegisterPushNotificationToken = async () => {
    return;
    // This doesn't exist as an endpoint yet, it seems
  };
}
