import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { Platform, View } from 'react-native';
//import { spy } from 'mobx';
import { observer, inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { useScreenBehaviors } from '/common/navigation';
import { colors, sizes } from '/common/config/styles';
import { ProgramList, SyncHint } from '../../../../components/program';
import { ScreenFirstLoadWrapper, ScreenError } from '../../../common';
import { LoadMoreIndicator } from '/common/components';
import { BrandContext } from '/common/config/branding';
import { getTheme } from '../../../../lib/theming';
import { navigationRoutes } from '../../../../config/constants';

/*spy(event => {
  if (event.type === 'reaction') {
    console.log(`${event.name} with args: ${event.arguments}`);
  }
});*/

const outerViewStyle = {
  flex: 1,
  backgroundColor:
    Platform.OS === 'ios'
      ? colors.bg0
      : colors.bg1 /* I don't know why android is different here, but it had something about gray */,
};

const errorContainerStyle = { paddingTop: sizes.large };

/**
 * Tab that contains a header that allows navigating between the seven days of the week,
 * or can expand to show a calendar to navigate to any day/ week.
 * Under the header is a carousel that can swipe between the seven active days,
 * and can be reloaded to show a different week.
 */
const FeedSubTab = inject('rootStore')(
  observer(({ navigation, rootStore }) => {
    // let's prevent this from happening right away in case there's just not that many cards
    const throttledLoadAdditionalProgramEvents = useCallback(
      debounce(rootStore.logStore.loadAdditionalProgramEvents, 1000),
      [rootStore]
    );

    const onPressCard = useCallback(
      programEventId => {
        navigation.navigate(navigationRoutes.stacks.main.programCardDetail, {
          programEventId,
        });
      },
      [navigation]
    );

    const onCardVisibleForFirstTime = useCallback(programEvent => {
      console.log(
        `Card visible: ${programEvent.programCard.id}, ${programEvent.programCard.title}`
      );
      rootStore.logEvent('Card Viewed', () => ({
        cardId: programEvent.programCard.id,
        feedKey: programEvent.id,
        title: programEvent.programCard.title,
        numTrackers: programEvent.programCard.trackerComponents.length,
        // guess this is null if it's not daily or weekly?
        repeatingType: programEvent.share ? programEvent.share.repeatingType : 'UNKNOWN',
      }));
    }, []);

    const { t } = useTranslation();

    const branding = useContext(BrandContext);

    const { onPullToRefresh, isPullToRefreshInProgress, isOnScreen } = useScreenBehaviors({
      onFocus: () => {
        const { logStore } = rootStore;
        rootStore.setActiveFrame('programs');
        // experimenting on reloading only when the app becomes active (see RootStore)
        // except when there are no cards ... load more aggressively to get this populated
        // if (!logStore.programEvents.length && !logStore.loadProgramDataState.isPending) {
        //   logStore.loadNewestProgramEvents();
        // }
      },
      onManualRefresh: async () => {
        // secret way to remove any deleted cards
        // someone refreshing intentionally is looking for something
        await rootStore.logStore.loadNewestProgramEvents({ flush: true });
      },
    });

    // sync hint buttons
    const onPressSyncHintDismiss = useCallback(() => {
      rootStore.ackUserHint('sync');
    }, [rootStore]);

    const onPressSyncHintSyncNow = useCallback(() => {
      // we're going to avoid ack'ing this in case they pop over to connected apps, pop right back, and forget about it.
      //rootStore.ackUserHint('sync');
      navigation.navigate(navigationRoutes.modals.editConnectedApps);
    }, [rootStore]);

    const { logStore } = rootStore;
    const theme = getTheme({ rootStore, branding });

    const hasTags = rootStore.settingsStore.user
      ? rootStore.settingsStore.user.enabledData.collections
      : false;

    return (
      <View style={outerViewStyle}>
        <ScreenFirstLoadWrapper
          state={logStore.loadProgramDataState}
          hasData={logStore.programEvents.length}
          errorContainerStyle={errorContainerStyle}
          onPressRefresh={logStore.loadNewestProgramEvents}>
          <ScreenError
            state={
              logStore.loadProgramDataState /* tricky! - only happens on subsequent refreshes because it's inside first load wrapper */
            }
            title={t('PROGRAMS:ERRORS:REFRESH_FAILED')}
            type="messageBar"
          />
          <ProgramList
            theme={theme}
            trackers={logStore.trackersSorted}
            groupedProgramEvents={logStore.programFeedUniqueItemsGroupedByDate}
            onPressCard={onPressCard}
            onPullToRefresh={onPullToRefresh}
            isPullToRefreshInProgress={isPullToRefreshInProgress}
            onLoadAdditionalItems={
              logStore.canLoadAdditionalProgramEvents && logStore.stackCount >= 7 && !Platform.isPad
                ? throttledLoadAdditionalProgramEvents
                : null
            }
            isOnScreen={isOnScreen}
            onCardVisibleForFirstTime={onCardVisibleForFirstTime}
            overrideHeader={
              rootStore.activeUserHints.find(h => h === 'sync') ? (
                <SyncHint
                  onPressDismiss={onPressSyncHintDismiss}
                  onPressSyncNow={onPressSyncHintSyncNow}
                />
              ) : null
            }
            extendsToFullScreenHeight={!hasTags}
            LoadMoreComponent={
              <LoadMoreIndicator
                loadingState={logStore.loadProgramDataState}
                canLoadMore={logStore.canLoadAdditionalProgramEvents}
                onPressLoadMore={logStore.loadAdditionalProgramEvents}
                showButton
              />
            }
          />
        </ScreenFirstLoadWrapper>
      </View>
    );
  })
);

FeedSubTab.propTypes = {
  rootStore: PropTypes.object,
};

export default FeedSubTab;
