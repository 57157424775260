import { fonts, sizes } from '/common/config/styles';
import { Platform } from 'react-native';

const headlineFontSize = fonts.sizes.medium;
const margin = Platform.isPad ? sizes.large : sizes.small;
const corners = 27;
const imagePadding = 0;
const textPadding = 20;

const elementSpacing = 20;

const completionGraphDiameter = 40;
const completionGraphStrokeWidth = 3;

const getTopButtonTopOffset = statusBarHeight => statusBarHeight + sizes.small; // matches padding on section headers on program list
// Lining up the back button and the completion graph is a bit of a house of cards. The back button is 40px tall but
// the completion graph is 43.5 + the stroke width for a progress circle that may or may not be visible.

const cardConstants = {
  headlineFontSize,
  margin,
  corners,
  imagePadding,
  textPadding,
  getTopButtonTopOffset,
  elementSpacing,
  completionGraphDiameter,
  completionGraphStrokeWidth,
};

export default cardConstants;
