import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Keyboard } from 'react-native';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { withAlert } from '/common/navigation/';
import { UpdateDateOfBirth } from '../../../components/settings';
import UpdateSettingsScreenWrapper from './UpdateSettingsScreenWrapper';

@inject('rootStore')
@observer
class UpdateDateOfBirthScreen extends Component {
  constructor(props) {
    super(props);
    const { rootStore } = this.props;
    const { settingsStore } = rootStore;
    const { user } = settingsStore;
    this.state = {
      dateOfBirth: user.birthday,
    };
  }

  _onPressSave = async () => {
    Keyboard.dismiss();
    const { dateOfBirth } = this.state;
    const { rootStore, navigation } = this.props;
    const { settingsStore } = rootStore;
    await settingsStore.updateUserFields({ birthday: dateOfBirth });
    if (settingsStore.updateUserState.isDone) {
      navigation.pop();
    }
  };

  _onChangeDateOfBirth = dateOfBirth => {
    this.setState({ dateOfBirth });
  };

  _onPressBack = () => {
    Keyboard.dismiss();
    this.props.navigation.pop();
  };

  _onPressCancel = () => {
    const { alert, t } = this.props;

    alert(t('PROFILE:EDIT:UNSAVED_CHANGES'), t('PROFILE:EDIT:WHAT_WOULD_YOU_LIKE_TO_DO'), [
      {
        text: t('PROFILE:EDIT:SAVE_CHANGES'),
        onPress: () => {
          this._onPressSave();
        },
        style: 'default',
      },
      {
        text: t('PROFILE:EDIT:DISCARD_CHANGES'),
        onPress: () => {
          this._onPressBack();
        },
        style: 'destructive',
      },
    ]);
  };

  render() {
    const { rootStore, navigation, t } = this.props;
    const { settingsStore } = rootStore;
    const { user } = settingsStore;
    const { dateOfBirth } = this.state;
    return (
      <UpdateSettingsScreenWrapper
        navigation={navigation}
        isDirty={user.birthday !== dateOfBirth}
        navbarTitle={t('PROFILE:FIELDS:DATE_OF_BIRTH')}
        updateFailedMessage={t('PROFILE:ERRORS:UPDATE_FAILED_MESSAGE')}
        onPressCancel={this._onPressCancel}
        onPressBack={this._onPressBack}
        onPressSave={this._onPressSave}
        form={
          <UpdateDateOfBirth
            dateOfBirth={dateOfBirth}
            onChangeDateOfBirth={this._onChangeDateOfBirth}
          />
        }
      />
    );
  }
}

UpdateDateOfBirthScreen.propTypes = {
  rootStore: PropTypes.object,
  navigation: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(withAlert(UpdateDateOfBirthScreen));
