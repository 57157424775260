import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { View, Text, TouchableOpacity, StyleSheet, Platform } from 'react-native';
import Styles from './styles';
import { WebMessageContextMenuButton } from '/common/components/chat';
import { textStyles, sizes, colors } from '/common/config/styles';
import MessageBody from './MessageBody';
import RecentMessagesThumbnailList from './RecentMessagesThumbnailList';

/**
 * The original message for a topic.
 */
@observer
class Topic extends Component {
  _onPressTopic = () => {
    const { topic, onPress } = this.props;
    onPress(topic);
  };

  _onLongPress = () => {
    const { currentMessage, onLongPress } = this.props;
    if (onLongPress) {
      onLongPress(currentMessage);
    }
  };

  render() {
    const {
      topic,
      onPress,
      singleThreadMode,
      onPressLink,
      t,
      currentMessage,
      onPressAttachment,
      onPressImage,
    } = this.props;

    let OuterComponent;
    if (onPress) {
      OuterComponent = props => (
        <TouchableOpacity
          activeOpacity={1}
          onPress={this._onPressTopic}
          onLongPress={this._onLongPress}
          {...props}
        />
      );

      if (Platform.OS === 'web') {
        OuterComponent = props => (
          <WebMessageContextMenuButton
            position="right"
            onPress={this._onLongPress}
            buttonStyle={{ top: 0 }}>
            <TouchableOpacity
              activeOpacity={1}
              onPress={this._onPressTopic}
              onLongPress={this._onLongPress}
              {...props}
            />
          </WebMessageContextMenuButton>
        );
      }
    } else {
      OuterComponent = View;
    }

    const replyThumbnails = (
      <RecentMessagesThumbnailList messages={topic.repliesSorted.slice().reverse()} />
    );

    // This is the topic/ first comment in a multi-message thread, when looking at the entire thread
    // (and not the group feed).
    if (singleThreadMode) {
      return (
        <OuterComponent
          style={[
            Styles.clubTopicThreadMessage,
            { paddingLeft: sizes.small, paddingRight: sizes.medium },
          ]}>
          <MessageBody
            onPressLink={onPressLink}
            topicOrReply={topic}
            dateType="dateOrTime"
            currentMessage={currentMessage}
            onPressAttachment={onPressAttachment}
            onPressImage={onPressImage}
            onLongPressMessage={this._onLongPress}
          />
          <View
            style={{
              borderBottomWidth: StyleSheet.hairlineWidth,
              borderBottomColor: colors.border0,
              paddingHorizontal: sizes.medium,
              paddingVertical: sizes.small,
              justifyContent: 'center',
            }}>
            <Text style={[textStyles.small.light, { textAlign: 'center' }]}>{`${
              topic.replies.length
            } ${
              topic.replies.length !== 1
                ? t('COMMUNITY:GROUP_FEED:REPLIES').toUpperCase()
                : t('COMMUNITY:GROUP_FEED:REPLY').toUpperCase()
            }`}</Text>
          </View>
        </OuterComponent>
      );
    }

    return (
      <OuterComponent style={Styles.clubTopicThreadMessage}>
        <MessageBody
          onPressLink={onPressLink}
          topicOrReply={topic}
          currentMessage={currentMessage}
          dateType="time"
          onPressImage={onPressImage}
          onPressAttachment={onPressAttachment}
          onLongPressMessage={this._onLongPress}
        />
        {topic.replies && topic.replies.length ? (
          <View
            style={{
              marginLeft: sizes.thumbnail,
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              marginTop: sizes.small,
            }}>
            {replyThumbnails}
            <Text
              style={[
                textStyles.small.light,
                {
                  color: colors.lightText,
                  paddingLeft: sizes.small,
                  lineHeight: sizes.thumbnail / 2,
                },
              ]}>{`${topic.replies.length} ${
              topic.replies.length > 1 ? 'REPLIES' : 'REPLY'
            }`}</Text>
          </View>
        ) : null}
      </OuterComponent>
    );
  }
}

Topic.propTypes = {
  onPress: PropTypes.func,
  topic: PropTypes.object.isRequired,
  onPressLink: PropTypes.func.isRequired,
};

Topic.defaultProps = {
  onPress: null,
};

export default withTranslation()(Topic);
