import React from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import {
  sizes,
  colors,
  containerStyles,
  useMediaQueryInfo,
  useDeviceDimensions,
} from '../config/styles';

const AlertBar = function({ children }) {
  const { isWideScreen, innerWindowWidth } = useMediaQueryInfo();
  const { statusBarHeight } = useDeviceDimensions();
  return (
    <View
      style={{
        width: isWideScreen ? innerWindowWidth * 0.75 : '100%',
        padding: sizes.medium,
        // little extra so it's not right up against the status bar, with its different shade and all
        paddingTop: statusBarHeight + (Platform.OS === 'android' ? sizes.small : 0),
        flexDirection: 'row',
        alignSelf: isWideScreen ? 'center' : undefined,
      }}>
      <View
        style={[
          {
            flex: 1,
            backgroundColor: colors.bg0,
            paddingHorizontal: sizes.small * 1.8,
            paddingVertical: sizes.small * 1.8,
            borderWidth: StyleSheet.hairlineWidth,
            borderColor: colors.border0,
            borderRadius: sizes.borderRadius,
          },
          containerStyles.deepShadow,
        ]}>
        {children}
      </View>
    </View>
  );
};

export default AlertBar;
