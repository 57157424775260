import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { View, Text, StyleSheet, Image } from 'react-native';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import {
  RowCenteredButton,
  AestheticallyPleasingListFooter,
  NudgeFlatList as FlatList,
} from '/common/components';
import Leader from './Leader';
import { textStyles, sizes, containerStyles, colors } from '/common/config/styles';
import { fullDateTimeWithZone } from '/common/config/strings';
import LeaderboardValue from './LeaderboardValue';
import LeaderBoardSegmentedControl from './LeaderBoardSegmentedControl';

const CompetitionStartsSoonPlaceholder = ({ upToTheMinuteTotalForUser, uom }) => (
  <View style={{ alignItems: 'center' }}>
    <Image
      style={{ width: 30, height: 45 }}
      resizeMode="contain"
      defaultSource={require('/common/assets/icons/hour-glass.png')}
      source={require('/common/assets/icons/hour-glass.png')}
    />
    <Text style={[textStyles.bold.dark, { textAlign: 'center', paddingVertical: sizes.medium }]}>
      Competition starts soon!
    </Text>
    <Text style={[textStyles.standard.dark, { textAlign: 'center' }]}>
      <Text>Check back in a few hours to see where you stand. </Text>
      {upToTheMinuteTotalForUser ? (
        <Text>
          <Text>You've currently logged </Text>
          <LeaderboardValue
            leader={{ effectiveTotal: upToTheMinuteTotalForUser }}
            uom={uom}
            valueStyle={textStyles.standard.dark}
            unitsStyle={textStyles.standard.dark}
          />
          <Text>.</Text>
        </Text>
      ) : null}
    </Text>
  </View>
);

const JoiningPlaceholder = ({ upToTheMinuteTotalForUser, uom }) => (
  <View style={{ alignItems: 'center', padding: sizes.large * 2, backgroundColor: colors.bg0 }}>
    <Image
      style={{ width: 30, height: 45 }}
      resizeMode="contain"
      defaultSource={require('/common/assets/icons/hour-glass.png')}
      source={require('/common/assets/icons/hour-glass.png')}
    />
    <Text style={[textStyles.bold.dark, { textAlign: 'center', paddingVertical: sizes.medium }]}>
      Joining...
    </Text>
    <Text style={[textStyles.standard.dark, { textAlign: 'center' }]}>
      <Text>Check back in a few hours to see where you stand. </Text>
      {upToTheMinuteTotalForUser ? (
        <Text>
          <Text>You've currently logged </Text>
          <LeaderboardValue
            leader={{ effectiveTotal: upToTheMinuteTotalForUser }}
            uom={uom}
            valueStyle={textStyles.standard.dark}
            unitsStyle={textStyles.standard.dark}
          />
          <Text>.</Text>
        </Text>
      ) : null}
    </Text>
  </View>
);

const NoLeadersPlaceholder = ({ perspective, onPressJoinLeaderboard, t }) => (
  <View style={{ backgroundColor: colors.bg0 }}>
    {perspective === 'client' ? (
      <View>
        <Text style={[textStyles.bold.dark, { textAlign: 'center' }]}>
          Be the first one to join the leaderboard!
        </Text>
        <View
          style={{
            paddingHorizontal: sizes.medium,
            paddingVertical: sizes.small,
          }}>
          <RowCenteredButton
            title={t('COMMUNITY:LEADERBOARD:JOIN_LEADERBOARD')}
            onPress={onPressJoinLeaderboard}
          />
        </View>
      </View>
    ) : (
      <View>
        <Text style={[textStyles.standard.dark, { textAlign: 'center' }]}>
          Nobody has joined the leaderboard yet.
        </Text>
      </View>
    )}
  </View>
);

/**
 * Leaderboard that appears as a separate screen with top 10 and the user's position.
 */
const ExpandedLeaderboard = observer(
  ({
    leaderboard,
    archivedleaderboard,
    leaders,
    archivedleaders,
    you,
    metricType,
    isLeaderboardJoined,
    onPressJoinLeaderboard,
    upToTheMinuteTotalForUser,
    perspective,
    t,
  }) => {
    const [isActive, setActive] = useState(true);

    let currentUserAtBottom = null;
    let userID = you && you.id;

    // remove the user from the list if the user isn't joined to the leaderboard
    let filteredLeaders = leaders;
    if (!isLeaderboardJoined) {
      filteredLeaders = leaders.filter(leader => !you || leader.id !== you.id);
    }

    let listHeader;
    let listEmptyComponent;

    // no leaders - show either "competition starts soon" if joining is in progress
    // or no leaders placeholde with join button
    if (!filteredLeaders.length) {
      listEmptyComponent = (
        <View
          style={{
            flex: 1,
            paddingHorizontal: sizes.large * 2,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: colors.bg0,
          }}>
          {isLeaderboardJoined ? (
            <CompetitionStartsSoonPlaceholder
              upToTheMinuteTotalForUser={upToTheMinuteTotalForUser}
              uom={metricType.unit}
              perspective={perspective}
            />
          ) : (
            <NoLeadersPlaceholder
              perspective={perspective}
              onPressJoinLeaderboard={onPressJoinLeaderboard}
              t={t}
            />
          )}
        </View>
      );
    } else {
      listHeader =
        isLeaderboardJoined && !you ? (
          <JoiningPlaceholder
            perspective={perspective}
            upToTheMinuteTotalForUser={upToTheMinuteTotalForUser}
            uom={metricType.unit}
          />
        ) : isLeaderboardJoined ? (
          <View style={{ minHeight: 20 }}>
            {leaderboard.resetsAt && (
              <View
                style={{
                  padding: sizes.medium,
                  borderBottomWidth: StyleSheet.hairlineWidth,
                  borderBottomColor: colors.border0,
                }}>
                {leaderboard.durationType === 'monthly' ? (
                  <Text style={[textStyles.small.light, { textAlign: 'center' }]}>
                    {t('COMMUNITY:LEADERBOARD:MONTHLY_TOTAL')}
                  </Text>
                ) : null}
                {leaderboard.durationType === 'weekly' ? (
                  <Text style={[textStyles.small.light, { textAlign: 'center' }]}>
                    {t('COMMUNITY:LEADERBOARD:WEEKLY_TOTAL')}
                  </Text>
                ) : null}
                <Text style={[textStyles.small.light, { textAlign: 'center' }]}>
                  {isActive
                    ? t('COMMUNITY:LEADERBOARD:LEADERBOARD_RESETS_AT', {
                        date: fullDateTimeWithZone(leaderboard.resetsAt.date),
                      })
                    : t('COMMUNITY:LEADERBOARD:RESULTS_RECORDED_ON', {
                        date: fullDateTimeWithZone(archivedleaderboard.endsAt.date),
                      })}
                </Text>
              </View>
            )}
          </View>
        ) : perspective === 'client' &&
          !isLeaderboardJoined &&
          leaders.length /* show only when there is at least one leader */ ? (
          <View
            style={{
              paddingHorizontal: sizes.medium,
              paddingVertical: sizes.small,
              borderBottomWidth: StyleSheet.hairlineWidth,
              borderBottomColor: colors.border0,
            }}>
            <RowCenteredButton
              title={t('COMMUNITY:LEADERBOARD:JOIN_LEADERBOARD')}
              onPress={onPressJoinLeaderboard}
            />
          </View>
        ) : null;
    }

    // other variants of leaders in the leaderboard- both if user is joined and not

    // If joined but user is not in leaderboard, show them
    if (isLeaderboardJoined && you && !leaders.find(leader => leader.id === you.id)) {
      currentUserAtBottom = (
        <View style={{ backgroundColor: colors.bg0 }}>
          <Text style={[textStyles.standard.light, { textAlign: 'center' }]}>...</Text>
          <Leader leader={you} uom={metricType.unit} type={metricType.type} />
        </View>
      );
    }
    return (
      <View style={containerStyles.fillAvailableSpace}>
        <FlatList
          ListHeaderComponent={
            <View>
              {archivedleaderboard && archivedleaderboard.leaders.length > 0 && (
                <View
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: colors.bg0,
                    paddingTop: 20,
                  }}>
                  <LeaderBoardSegmentedControl
                    onChooseOption={() => setActive(!isActive)}
                    selectedOption={isActive ? 0 : 1}
                  />
                </View>
              )}

              <View style={{ backgroundColor: colors.bg0 }}>{listHeader}</View>
            </View>
            /* show either the leaderboard details or the message indicating that you're joining soon but not there yet */
          }
          contentContainerStyle={{ flexGrow: 1 }}
          data={isActive ? filteredLeaders : archivedleaders}
          keyExtractor={item => item.id.toString()}
          renderItem={({ item, index }) => (
            <Leader
              leader={item}
              uom={leaderboard.metricType.unit}
              type={metricType.type}
              isUser={item.id === userID}
              rankOverride={index + 1}
            />
          )}
          ListEmptyComponent={listEmptyComponent}
          ListFooterComponent={
            <View>
              {currentUserAtBottom}
              <AestheticallyPleasingListFooter />
            </View>
          }
        />
      </View>
    );
  }
);

ExpandedLeaderboard.propTypes = {
  leaderboard: PropTypes.object.isRequired,
  leaders: PropTypes.arrayOf(PropTypes.object).isRequired,
  you: PropTypes.object,
  metricType: PropTypes.object.isRequired,
  isLeaderboardJoined: PropTypes.bool.isRequired,
  onPressJoinLeaderboard: PropTypes.func.isRequired,
  t: PropTypes.any,
};

ExpandedLeaderboard.defaultProps = {
  you: null,
};

export default withTranslation()(ExpandedLeaderboard);
