import React, { useContext } from 'react';
import { View, Text, Platform } from 'react-native';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { RoundButton } from '../common';
import { sizes, textStyles, useMediaQueryInfo } from '/common/config/styles';
import SignUpField from './SignUpField';
import { KeyboardAwareScrollView } from '/common/components';
import { BrandContext } from '../../config/branding';

const EnterInviteId = observer(
  ({
    inviteId,
    canSkipInviteId,
    isImportedInviteId,
    importedInviteId,
    onChangeInviteId,
    onPressContinue,
    formErrors,
    onPressNo,
  }) => {
    const branding = useContext(BrandContext);

    const { t } = useTranslation();

    const { innerWindowWidth } = useMediaQueryInfo();

    let validationErrorText = null;
    if (formErrors.find(e => e.field === 'inviteId' && e.type === 'invalid')) {
      validationErrorText = t('SIGN_UP:ERRORS:INVALID_INVITE_ID', {
        inviteIdName: branding.inviteIdDisplay.name,
      });
    } else if (
      formErrors.find(e => e.field === 'inviteId' && e.type === 'membershipLimitExceeded')
    ) {
      validationErrorText = t('SIGN_UP:ERRORS:INVALID_INVITE_ID_MEMBERSHIP_LIMIT_EXCEEDED', {
        inviteIdName: branding.inviteIdDisplay.name,
      });
    }

    return (
      <View style={{ flex: 1 }}>
        <KeyboardAwareScrollView
          scrollViewProps={{
            showsVerticalScrollIndicator: false,
            contentContainerStyle: {
              width: innerWindowWidth,
              alignSelf: 'center',
              flexGrow: 1,
            },
          }}
          textInputOffsetFromKeyboard={Platform.OS === 'android' ? 35 + 110 : 100}>
          <View
            style={{
              marginHorizontal: sizes.large,
              paddingTop: sizes.large * 2,
            }}>
            <Text
              style={[textStyles.centered, textStyles.bold.dark, { marginBottom: sizes.large }]}>
              {isImportedInviteId && importedInviteId === inviteId
                ? 'Continue sign-up with the ID copied from your invite'
                : canSkipInviteId
                ? branding.signUp.nonRequiredInviteIdPrompt
                : t('SIGN_UP:PLEASE_ENTER_INVITE_ID', {
                    inviteIdName: branding.inviteIdDisplay.name,
                  })}
            </Text>
            <SignUpField
              text={inviteId}
              onChangeText={onChangeInviteId}
              placeholder={branding.inviteIdDisplay.name}
              focus
              onSubmitEditing={onPressContinue}
              validationError={validationErrorText}
            />
            {canSkipInviteId && (!inviteId || inviteId.trim() === '') ? null : (
              <RoundButton
                buttonStyle={{ marginTop: sizes.large, marginBottom: sizes.large }}
                theme="lightOnDark"
                isDisabled={!inviteId || inviteId.trim().length === 0}
                onPress={onPressContinue}
                title={t('SIGN_UP:BUTTONS:CONTINUE').toLowerCase()}
              />
            )}
            {canSkipInviteId && (!inviteId || inviteId.trim() === '') ? (
              <RoundButton
                buttonStyle={{ marginTop: sizes.large, marginBottom: sizes.large }}
                onPress={onPressNo}
                title={
                  branding.signUp.continueWithoutInviteIdPrompt ||
                  t('SIGN_UP:BUTTONS:NO_CONTINUE_WITH_SIGN_UP')
                }
                hideBorder
              />
            ) : null}
          </View>
        </KeyboardAwareScrollView>
      </View>
    );
  }
);

EnterInviteId.propTypes = {
  onPressContinue: PropTypes.func.isRequired,
  onPressNo: PropTypes.func,
  onChangeInviteId: PropTypes.func.isRequired,
  inviteId: PropTypes.string,
  formErrors: PropTypes.array,
  // we don't read this directly from the brand because the invite ID could be skipped
  // because the brand is configured this way or because it is an SSO client
  canSkipInviteId: PropTypes.bool,
  isImportedInviteId: PropTypes.bool,
  importedInviteId: PropTypes.string,
};

EnterInviteId.defaultProps = {
  inviteId: '',
  onPressNo: () => {},
  formErrors: [],
  canSkipInviteId: false,
  isImportedInviteId: false,
};

export default EnterInviteId;
