import { when } from 'mobx';
import { navigationRoutes } from '../../config/constants';

async function navigateToCoach({
  navigation,
  coachCount,
  onInitConversation,
  isReadyToNavigateFn = () => true,
}) {
  await when(isReadyToNavigateFn);
  console.log('coachMessage');

  // android seems to appreciate this timeout, I don't know why
  setTimeout(() => {
    if (coachCount < 2) {
      console.log('go to coach tab');
      navigation.navigate(navigationRoutes.stacks.main.coachTab);
    } else {
      console.log('push coach');
      const conversation = onInitConversation();
      // if we're anywhere else in the app or there's multiple coaches, just push the conversation on screen
      navigation.navigate(navigationRoutes.stacks.main.conversation, {
        coachId: conversation.coachId,
      });
      // TODO: 403? when coach isn't found
    }
  });
}

async function navigateToGroup({
  navigation,
  groupCount,
  clubId,
  commentId,
  isReadyToNavigateFn = () => true,
}) {
  await when(isReadyToNavigateFn);
  // android seems to appreciate this timeout, I don't know why
  setTimeout(() => {
    // one group
    if (groupCount < 2) {
      // thread
      if (commentId) {
        navigation.navigate(navigationRoutes.stacks.main.topic, { clubId, commentId });
      } else {
        // main feed
        navigation.navigate(navigationRoutes.stacks.main.groupsTab);
      }
      // multiple groups
    } else {
      // thread
      if (commentId) {
        navigation.navigate(navigationRoutes.stacks.main.topic, { clubId, commentId });
      } else {
        // main feed
        navigation.navigate(navigationRoutes.stacks.main.group, {
          clubId,
        });
      }
      // TODO: 403? when group isn't found
    }
  });
}

async function navigateToProgramCard({
  navigation,
  groupCount,
  onInitTopicFeed,
  topicId,
  isReadyToNavigateFn = () => true,
}) {
  await when(isReadyToNavigateFn);
  const topicFeed = onInitTopicFeed();
  // android seems to appreciate this timeout, I don't know why
  setTimeout(() => {
    // one group
    if (groupCount < 2) {
      // thread
      if (topicId) {
        navigation.navigate(navigationRoutes.stacks.main.topic, { topicFeed, topicId });
      } else {
        // main feed
        navigation.navigate(navigationRoutes.stacks.main.groupsTab);
      }
      // multiple groups
    } else {
      // thread
      if (topicId) {
        navigation.navigate(navigationRoutes.stacks.main.topic, { topicFeed, topicId });
      } else {
        // main feed
        navigation.navigate(navigationRoutes.stacks.main.group, {
          topicFeed,
        });
      }
      // TODO: 403? when group isn't found
    }
  });
}

export { navigateToCoach, navigateToGroup };
