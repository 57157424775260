import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Platform, Keyboard, View } from 'react-native';
import { observer } from 'mobx-react';
import { HeaderIconButton } from '../../../../components/navigation';
import { sizes, containerStyles } from '../../../../config/common-styles';
import { KeyboardDismissIcon } from '../../../../config/common-icons';
import { ConversationScreenInner } from '../../conversation-screen';
import { BrandContext } from '../../../../config/branding';
import { getTheme } from '../../../../lib/theming';

@observer
class SingleCoachView extends Component {
  static contextType = BrandContext;

  constructor() {
    super();
    this.state = {
      showDismissKeyboardButton: false,
    };
  }

  componentDidMount() {
    // keyboard logic only needed when there is one coach, so we can show the keyboard dismiss button
    // Tho maybe we should show it everywhere
    // Right now this triggers a re-render whenever the keyboard appears
    // even if the tab isn't active
    if (Platform.OS === 'ios') {
      this.keyboardDidShowListener = Keyboard.addListener(
        'keyboardWillShow',
        this._keyboardDidShow
      );
      this.keyboardDidHideListener = Keyboard.addListener(
        'keyboardWillHide',
        this._keyboardDidHide
      );
    } else {
      this.keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', this._keyboardDidShow);
      this.keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', this._keyboardDidHide);
    }
  }

  componentWillUnmount() {
    this.keyboardDidShowListener.remove();
    this.keyboardDidHideListener.remove();
  }

  _keyboardDidShow = () => {
    this.setState({
      showDismissKeyboardButton: true,
    });
  };

  _keyboardDidHide = () => {
    this.setState({
      showDismissKeyboardButton: false,
    });
  };

  _onPressDismissKeyboard = () => {
    Keyboard.dismiss();
  };

  render() {
    const { rootStore, navigation, route } = this.props;
    const { conversationsStore } = rootStore;
    const branding = this.context;

    const theme = getTheme({ rootStore, branding });

    const coach = conversationsStore.onlyCoach;

    // shouldn't happen, but...
    if (!coach) {
      return null;
    }

    const conversation = conversationsStore.initConversation(coach.id);

    return (
      <View style={containerStyles.fillAvailableSpace}>
        <ConversationScreenInner
          key={conversation.coachId}
          navigation={navigation}
          route={route}
          conversation={conversation}
          hasBackButton={false}
          theme={theme}
          rightNavbarButton={
            this.state.showDismissKeyboardButton && Platform.OS === 'ios' ? (
              <HeaderIconButton
                icon={<KeyboardDismissIcon useDarkTheme={theme.useDarkTheme} />}
                onPress={this._onPressDismissKeyboard}
              />
            ) : null
          }
        />
      </View>
    );
  }
}

SingleCoachView.propTypes = {
  rootStore: PropTypes.object,
  navigation: PropTypes.object,
};

export default SingleCoachView;
